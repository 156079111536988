import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IServerError } from '@shared/interfaces/server-error';

import { FLAT_INPUT_THEME } from '@shared/constants/flat-input';


@Component({
  selector: 'bl-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordFormComponent implements OnChanges {
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  @Input() form: FormGroup;
  @Input() serverError: IServerError;

  ngOnChanges(changes: SimpleChanges): void {
    const errors: SimpleChange = changes.serverError;
    if (errors && errors.currentValue) {
      Object.entries(this.serverError.errors).forEach(([fieldName, messages]: [string, string[]]) => {
        this.form.get(fieldName).setErrors(messages);
      });
    }
  }

}
