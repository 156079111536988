import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';

import { fromEvent, merge, timer, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DocumentRef } from '@core/refs/document-ref.service';
import { WindowRef } from '@core/refs/window-ref.service';
import { LayoutService } from '@core/services/layout.service';

import { STICKY_TYPES } from '@shared/constants/sticky-type';

@Component({
  selector: 'bl-sticky-element',
  templateUrl: './sticky-element.component.html',
  styleUrls: ['./sticky-element.component.scss'],
  exportAs: 'stickyElement',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyElementComponent implements AfterViewInit, OnDestroy {
  private destroyer$: Subject<void> = new Subject();

  @ViewChild('contentEl', { static: true }) contentEl: ElementRef;
  @ViewChild('contentWrapperEl', { static: true }) contentWrapperEl: ElementRef;

  @Input() offsetTop: number = 0;
  @Input() type: STICKY_TYPES = STICKY_TYPES.Header;
  @Input() isAlignContent: boolean;
  @Input() isFirstHideContent: boolean;
  @Input() levelHeight: number;

  // will be added to first child element
  @Input() stickyClass: string;
  // should (shouldn't) fixed content move outside parent bounds (to bottom)
  @Input() fitParentBounds: boolean = false;

  @Output() isElementFixedChange: EventEmitter<boolean> = new EventEmitter();

  private _isElementFixed: boolean;
  private isElementStickedToParentBottom: boolean = false;

  isRootElementFixed: boolean;

  @Input()
  set isElementFixed(state: boolean) {
    this._isElementFixed = state;

    this.isElementFixedChange.emit(state);
  }

  get isElementFixed(): boolean {
    return this._isElementFixed;
  }

  get stickyElementClasses(): { [key: string]: boolean } {
    return {
      'fixed': this.isElementFixed && !this.isRootElementFixed,
      'fixed--top': this.isElementFixed && this.type === STICKY_TYPES.Header,
      'footer': this.type === STICKY_TYPES.Footer,
      'fixed--footer': this.isElementFixed && this.type === STICKY_TYPES.Footer,
      'fixed--segment-nav': this.isElementFixed && this.type === STICKY_TYPES.SegmentNav,
      'fixed--view-segment-nav': this.isElementFixed && this.type === STICKY_TYPES.ViewSegmentNav,
      'fixed--pricing-list-Info': this.isElementFixed && this.type === STICKY_TYPES.PricingListInfo,
      'fixed--ecomm-checkout': this.isElementFixed && this.type === STICKY_TYPES.EcommCheckout,
      'fixed--ecomm-checkout--without-data': this.isElementFixed && this.type === STICKY_TYPES.EcommCheckoutWithoutData,
      'fixed--pricing-page': this.isElementFixed && this.type === STICKY_TYPES.PricingPage,
      'fixed--alerts-subnav': this.isElementFixed && this.type === STICKY_TYPES.AlertsSubNav,
      'fixed--customize-nav': this.isElementFixed && this.type === STICKY_TYPES.CustomizeNav,
    };
  }

  constructor(private renderer: Renderer2,
              private layoutService: LayoutService,
              private chf: ChangeDetectorRef,
              private document: DocumentRef,
              private window: WindowRef,
              private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    const onScrollEvent: Observable<Event> = fromEvent(this.window.nativeElement, 'scroll');
    const onClickEvent: Observable<Event> = fromEvent(this.window.nativeElement, 'click');
    const onDocumentScrollEvent: Observable<Event> = fromEvent(this.document.nativeElement.body, 'scroll');

    merge(onScrollEvent, onClickEvent, onDocumentScrollEvent)
      .pipe(
        takeUntil(this.destroyer$)
      ).subscribe(() => {
      this.setStickyPosition();
    });

    timer(0).subscribe(() => {
      this.setStickyPosition();
    });

    this.layoutService
      .isRootElementFixed
      .pipe(
        takeUntil(this.destroyer$)
      ).subscribe((value: boolean) => {
      this.isRootElementFixed = value;
    });

  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  setStickyPosition(): void {
    if (this.contentWrapperEl && !this.isRootElementFixed) {
      const { height, width }: ClientRect = this.contentEl.nativeElement.getBoundingClientRect();
      const {
        top: wrapperTop,
        bottom: wrapperBottom
      }: DOMRect = this.contentWrapperEl.nativeElement.getBoundingClientRect();

      if ((this.type === STICKY_TYPES.Footer && wrapperBottom > this.window.nativeElement.innerHeight)
        || (wrapperTop < this.offsetTop && this.type !== STICKY_TYPES.Footer)) {

        this.setFixPosition(height, width);
      } else {
        this.setFixPosition();
      }
      if (!this.chf['destroyed']) {
        this.chf.detectChanges();
      }
    }

    this.layoutService.triggerLayoutChange();
  }

  setFixPosition(height?: number, width?: number): void {
    this.isElementFixed = !!height && !!width;

    this.toggleStickyClass();
    this.checkFitParentBounds();

    this.renderer.setStyle(this.contentWrapperEl.nativeElement, 'height', this.isElementFixed ? `${ height }px` : '');
    this.renderer.setStyle(this.contentWrapperEl.nativeElement, 'width', this.isElementFixed ? `${ width }px` : '');
  }

  // add sticky class to first child element of ng-content
  toggleStickyClass(): void {
    if (this.stickyClass && this.contentEl.nativeElement && this.contentEl.nativeElement.firstElementChild) {
      if (this.isElementFixed) {
        this.contentEl.nativeElement.firstElementChild.classList.add(this.stickyClass);
      } else {
        this.contentEl.nativeElement.firstElementChild.classList.remove(this.stickyClass);
      }
    }
  }

  // set fixed top point to lowest parent point of need to fit parent bounds
  checkFitParentBounds(): void {
    if (this.fitParentBounds && this.isElementFixed) {
      const { top: parentTop }: ClientRect = this.elementRef.nativeElement.parentElement.getBoundingClientRect();
      const { offsetHeight: parentHeight }: HTMLElement = this.elementRef.nativeElement.parentElement;
      const parentLowestPoint: number = parentTop + parentHeight;

      const { top: currentFixedElemTop }: ClientRect = this.contentEl.nativeElement.getBoundingClientRect();
      const { offsetHeight: currentFixedElemHeight }: HTMLElement = this.contentEl.nativeElement;
      const currentFixedElemLowestPoint: number = currentFixedElemTop + currentFixedElemHeight;

      if (this.isElementStickedToParentBottom && currentFixedElemTop > this.offsetTop) {
        this.renderer.setStyle(this.contentEl.nativeElement, 'top', '');
        this.isElementStickedToParentBottom = false;
      } else if (!this.isElementStickedToParentBottom && currentFixedElemLowestPoint > parentLowestPoint) {
        this.renderer.setStyle(this.contentEl.nativeElement, 'top', `${ parentLowestPoint - currentFixedElemHeight }px`);
        this.isElementStickedToParentBottom = true;
      } else if (this.isElementStickedToParentBottom) {
        this.renderer.setStyle(this.contentEl.nativeElement, 'top', `${ parentLowestPoint - currentFixedElemHeight }px`);
      }
    }
  }
}
