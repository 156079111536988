<form [formGroup]="form"
      (ngSubmit)="submit()">

  <div class="form form-content">

    <div *ngIf="isShowTitle"
         class="form-header">
      <p class="form-title">Create your free account</p>
      <p class="form-description">No credit card required, start today</p>
    </div>

    <div class="wrapper-input">
      <bl-flat-input (submit)="submit()"
                     formControlName="firstName"
                     [errors]="form.get('firstName').errors"
                     type="text"
                     [theme]="theme"
                     placeholder="First Name*">
      </bl-flat-input>
    </div>

    <div class="wrapper-input">
      <bl-flat-input (submit)="submit()"
                     formControlName="lastName"
                     [errors]="form.get('lastName').errors"
                     type="text"
                     [theme]="theme"
                     placeholder="Last Name*">
      </bl-flat-input>
    </div>

    <div class="wrapper-input">
      <bl-flat-input (submit)="submit()"
                     formControlName="company"
                     [errors]="form.get('company').errors"
                     type="text"
                     [theme]="theme"
                     placeholder="Company*">
      </bl-flat-input>
    </div>

    <bl-flat-input (submit)="submit()"
                   formControlName="email"
                   [errors]="form.get('email').errors"
                   type="email"
                   [theme]="theme"
                   placeholder="Email*">
    </bl-flat-input>

    <div class="wrapper-input">
      <bl-flat-input (submit)="submit()"
                     [blTooltip]="passwordTip"
                     [events]="['focus', 'blur']"
                     [position]="'right'"
                     [leftOffset]="50"
                     [isDataPrivate]="true"
                     formControlName="password"
                     [errors]="form.get('password').errors"
                     type="password"
                     [theme]="theme"
                     placeholder="Password*">
      </bl-flat-input>
    </div>

    <div class="wrapper-input">
      <bl-flat-input (submit)="submit()"
                     formControlName="confirmPassword"
                     [isDataPrivate]="true"
                     [errors]="form.get('confirmPassword').errors"
                     type="password"
                     [theme]="theme"
                     placeholder="Confirm Password*">
      </bl-flat-input>
    </div>

    <div *ngIf="isShowFooter">

      <re-captcha *ngIf="isReCaptcha"
                  formControlName="gRecaptchaResponse"
                  class="captcha--align">
      </re-captcha>

      <div class="form-footer">
        <button [blBtnSpinner]="loading"
                [disabled]="form.invalid"
                class="btn btn--primary btn--lg"
                #signUpBtn>
          Create Account
        </button>
      </div>

      <div class="additional-info">
        <p>
          By signing up, you agree to our
          <a [routerLink]="['/', corePaths.COMMON, corePaths.PRIVACY_POLICY]" class="link">Privacy Policy</a> and
          <a [routerLink]="['/', corePaths.COMMON, corePaths.TERMS_OF_USE]" class="link">Terms of Service</a>
        </p>
      </div>
    </div>

    <div *ngIf="!isShowFooter && isReCaptcha">
      <re-captcha formControlName="gRecaptchaResponse"
                  class="captcha--align">
      </re-captcha>
    </div>

  </div>

  <ng-content></ng-content>

</form>
