<header class="header">
  <div class="header-left">

    <!--TODO need svg icon-->
    <a [routerLink]="isLoggedIn ? ['/',  corePaths.DASHBOARD] : ['/', corePaths.AUTH]">
      <img class="header__logo"
           src="/assets/img/logo_mdr.png"
           alt="logo">
    </a>

    <a class="header__phone-num" [href]="'tel:' + phoneNumber">{{ phoneNumber | phone: 'brackets' }}</a>

  </div>

  <div class="header-right" #onBoardLink>

    <ng-container *blIfLoggedIn>
      <button *blHidePoint="[windowPoints.DESKTOP, windowPoints.DESKTOP_LARGE]"
              matRipple
              type="button"
              class="header-right__icon-mobile"
              (click)="onOpenMenuMobile.emit(); $event.stopPropagation();">

        <i class="icon-menu-mobile"></i>
      </button>
    </ng-container>

    <ng-container *blHidePoint="[windowPoints.PHONE, windowPoints.TABLET]">
      <bl-drop-down #dropDown
                    *blIfLoggedIn
                    bodyPosition="right">

        <ng-template blDropDownTarget>
          <span class="header-right__user-name"
                e2e="user-name">
            {{ user?.firstName }}&nbsp;{{ user?.lastName }}
            <i class="icon-wrapper icon-arrow-subcontainer{{dropDown.isOpen ? '--up' : '--down' }}"></i>
          </span>
        </ng-template>

        <ng-template blDropDownContent>
          <bl-account-links [isShowBillingDetails]="isShowBillingDetails"
                            [isOwnerOfAdminsAccount]="isOwnerOfAdminsAccount"
                            (logout)="logout()">
          </bl-account-links>
        </ng-template>

      </bl-drop-down>

    </ng-container>
  </div>
</header>

<div class="container">
  <div class="row">

    <p class="message--is-admin"
       *blIfAdmin>
      <span class="label">Note:</span>&nbsp;You are logged in as {{ user?.email }}
      <a class="link--logout"
         matRipple
         (click)="goToAdminPanel()">Click here to log out</a>
    </p>
    <ng-container *ngIf="isTokenExpired">
      <p class="message--is-admin"
         *blIfAdmin>
        Customer hasn’t logged in for 14 days. Product entitlements have not been updated. Historic data only.
      </p>
    </ng-container>

  </div>
</div>

<bl-server-errors-common [isShowError]="!!globalError"
                         [message]="globalError">
</bl-server-errors-common>
