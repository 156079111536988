import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { ISignInFormData, ISignUpFormData } from '../../../auth/interfaces/formsActionsData';

enum ACTION_TYPES {
  E_COMMERCE_SIGN_IN = '[E-COMMERCE ACCOUNT] Sign In',
  E_COMMERCE_SIGN_IN_SUCCESS = '[E-COMMERCE ACCOUNT] Sign In success',

  E_COMMERCE_SIGN_UP = '[E-COMMERCE ACCOUNT] Sign Up',
  E_COMMERCE_SIGN_UP_SUCCESS = '[E-COMMERCE ACCOUNT] Sign Up success',
}

export const eCommerceSignInAction: CreateActionType<ACTION_TYPES.E_COMMERCE_SIGN_IN, ISignInFormData> = createAction(ACTION_TYPES.E_COMMERCE_SIGN_IN, createPayload<ISignInFormData>());
export const eCommerceSignInSuccessAction: ActionCreator<ACTION_TYPES.E_COMMERCE_SIGN_IN_SUCCESS, () => TypedAction<ACTION_TYPES.E_COMMERCE_SIGN_IN_SUCCESS>> = createAction(ACTION_TYPES.E_COMMERCE_SIGN_IN_SUCCESS);
export const eCommerceSignUpAction: CreateActionType<ACTION_TYPES.E_COMMERCE_SIGN_UP, ISignUpFormData> = createAction(ACTION_TYPES.E_COMMERCE_SIGN_UP, createPayload<ISignUpFormData>());
export const eCommerceSignUpSuccessAction: ActionCreator<ACTION_TYPES.E_COMMERCE_SIGN_UP_SUCCESS, () => TypedAction<ACTION_TYPES.E_COMMERCE_SIGN_UP_SUCCESS>> = createAction(ACTION_TYPES.E_COMMERCE_SIGN_UP_SUCCESS);
