import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/sign-up-by-invite.action';

import { IServerError } from '@shared/interfaces/server-error';

export interface ISignUpByInviteState {
  pending: boolean;
  error: IServerError | null;
}

export const initialState: ISignUpByInviteState = {
  pending: false,
  error: null
};

const signUpByInvite: OnReducer<ISignUpByInviteState, ActionType<any>> = (state: ISignUpByInviteState) => ({
  ...state,
  pending: true,
  error: null
});

const signUpByInviteError: OnReducer<ISignUpByInviteState, ActionType<Payload<IServerError>>> = (state: ISignUpByInviteState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  pending: false
});

const signUpByInviteSuccess: OnReducer<ISignUpByInviteState, ActionType<any>> = (state: ISignUpByInviteState) => ({
  ...state,
  pending: false
});

const resetSignUpByInviteState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<ISignUpByInviteState> = createReducer<ISignUpByInviteState>(
  initialState,

  on(actions.signUpByInviteAction, signUpByInvite),
  on(actions.signUpByInviteErrorAction, signUpByInviteError),
  on(actions.signUpByInviteSuccessAction, signUpByInviteSuccess),
  on(actions.resetSignUpByInviteStateAction, resetSignUpByInviteState)
);

export const pending: GetFromState<boolean, ISignUpByInviteState> = (state: ISignUpByInviteState): boolean => state.pending;
export const signUpError: GetFromState<IServerError, ISignUpByInviteState> = (state: ISignUpByInviteState): IServerError => state.error;

export function signUpByInviteReducer(state: ISignUpByInviteState,
                                      action: Action): ISignUpByInviteState {
  return reducer(state, action);
}
