<bl-pop-up-base>
  <div class="icon">
    <i class="icon icon-delete"></i>
  </div>

  <h5 class="title">{{ data.title }}</h5>

  <div class="body">
    {{ data.body }}
  </div>

  <div class="buttons">
    <button class="btn btn--secondary btn--white"
            (click)="onClose()">
      Take me to my Dashboard
    </button>
  </div>
</bl-pop-up-base>
