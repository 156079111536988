import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { defer, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import * as authActions from '@modules/auth/store/actions/auth.action';
import * as profileActions from '@modules/profile/store/actions/profile.action';
import { ActionWithPayload } from '@shared/interfaces/store';
import * as tooltipActions from '../actions/tooltips.action';

import { StorageService } from '@core/services/storage.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { ISuccessLoginActionData } from '@modules/auth/interfaces/formsActionsData';
import { IUserData } from '@modules/auth/interfaces/user';

@Injectable({ providedIn: 'root' })
export class TooltipsEffects {

  constructor(private actions$: Actions) {
  }

  changeTooltipsStateAfterGetProfileSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(profileActions.getProfileSuccessAction),
      map(({ payload }: ActionWithPayload<IUserData>) => tooltipActions.setShowFirstTimeSegmentPageTooltipAction(!payload.hasLists)),
      catchErrorWithErrorType
    ));

  changeTooltipsStateAfterSignUpSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signInSuccessAction),
      map(({ payload }: ActionWithPayload<ISuccessLoginActionData>) =>
        tooltipActions.setShowFirstTimeSegmentPageTooltipAction(!payload.response.hasLists)),
      catchErrorWithErrorType
    ));

  afterSuccessSignOut$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signOutSuccessAction),
      map(() => tooltipActions.resetTooltipsStateAction())
    ));

  geoSuggestTooltip$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(tooltipActions.setDoNotShowGeoSuggestTooltipStateAction),
      tap(({ payload }: ActionWithPayload<boolean>) => StorageService.doNotShowGeoSuggestTooltip = payload),
      catchErrorWithErrorType
    ), { dispatch: false });

  resetSegmentsTooltips$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(tooltipActions.resetSegmentTooltipsAction),
      tap(() => StorageService.hasLists = true),
      switchMap(() => [
        tooltipActions.setShowFirstTimeSegmentPageTooltipAction(false),
        tooltipActions.setShowCalculatorSegmentPageTooltipAction(false)
      ]),
      catchErrorWithErrorType
    ));

  setRefreshCreditsTooltipState$: Observable<Action> = createEffect(() => defer(() => this.actions$
    .pipe(
      ofType(tooltipActions.setRefreshCreditsTooltipStateAction),
      filter(({ payload }: ActionWithPayload<boolean>) => payload),
      tap(({ payload }: ActionWithPayload<boolean>) => StorageService.refreshCreditsTooltipWasShowed = payload),
      catchErrorWithErrorType
    )), { dispatch: false });
}
