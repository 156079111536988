<input type="text"
       class="field"
       [(ngModel)]="value"
       [blAutofocus]="isAutoFocus"
       [placeholder]="placeholder"
       (keydown.enter)="onByEnter()"
       (input)="onByInput();"
       (keydown)="onKeyboardEvents($event)"
       (focus)="onInputFocus($event)"
       (focusout)="onInputFocusOut($event)"
       #input/>

<ul *ngIf="suggestions && suggestions.length"
    class="list"
    #list>

  <li *ngFor="let suggestion of suggestions; let i = index;"
      (click)="onByEnter(true)"
      (mouseenter)="selectSuggestionIndex($event, i)"
      [class.selected]="i === counter"
      class="list-item">

    <span class="item-text">{{ suggestion.text }}</span>
  </li>

</ul>
