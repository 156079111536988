import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges,
  OnDestroy, OnInit, Output, SimpleChange, SimpleChanges
} from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../../../auth/services/auth.service';

import { PhoneMaskPipe } from '@shared/modules/pipes/shared-pipes/pipes/phone-mask.pipe';

import { isDifferentProfileData } from '@shared/utils/is-different-profile-data';

import { IControlOption } from '@shared/interfaces/forms';
import { AddressError, IServerError } from '@shared/interfaces/server-error';
import { INewUser, IUser, IUserAddress, IUserTransformed } from '../../../auth/interfaces/user';
import { ICountryCode } from '../../../countries/interfaces';

import { FLAT_INPUT_THEME } from '@shared/constants/flat-input';
import { STICKY_TYPES } from '@shared/constants/sticky-type';
import { FORM_VALIDATION_ERRORS } from '@shared/constants/validators/forms-validations-errors';
import { RolesKeys } from '../../constants/roles';

import { Validators } from '@shared/validators/validators';

@Component({
  selector: 'bl-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
  providers: [PhoneMaskPipe],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProfileFormComponent implements OnInit, OnChanges, OnDestroy {
  readonly stickyTypes: typeof STICKY_TYPES = STICKY_TYPES;
  readonly rolesKeys: typeof RolesKeys = RolesKeys;
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  @Input() user: IUserTransformed;
  @Input() role: string;
  @Input() loading: boolean;
  @Input() serverError: IServerError;
  @Input() serverAddressError: AddressError;

  @Input() countries: IControlOption[];
  @Input() countryStates: IControlOption[];

  @Input() shouldShowPasswordForm: boolean;
  @Input() changePasswordLoading: boolean;
  @Input() changePasswordDisabled: boolean;

  @Output() countryChanged: EventEmitter<ICountryCode> = new EventEmitter<ICountryCode>();
  @Output() onChangeAddress: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<IUser> = new EventEmitter();

  @Output() onChangePassword: EventEmitter<any> = new EventEmitter();
  @Output() onCloseChangePasswordForm: EventEmitter<any> = new EventEmitter();

  private _destroyer$: Subject<void> = new Subject();

  form: FormGroup;

  get address(): FormGroup {
    return this.form.get('address') as FormGroup;
  }

  get countryCode(): AbstractControl {
    return this.address.get('countryCode');
  }

  get shouldShowSave(): boolean {
    if (!this.form || !this.user) {
      return true;
    }

    return isDifferentProfileData(this.user, this.form.value);
  }

  constructor(private service: AuthService,
              public phoneMask: PhoneMaskPipe) {
  }

  ngOnInit(): void {
    this.generateForm();

    this.form.get('address').get('countryCode').valueChanges
      .pipe(
        takeUntil(this._destroyer$)
      )
      .subscribe((countryCode: string) => {
        if (countryCode === 'US') {
          this.form.get('address').get('postalCode')
            .setValidators([Validators.required(FORM_VALIDATION_ERRORS.zipPostalCode.required), Validators.postalCode()]);
        } else {
          this.form.get('address').get('postalCode')
            .setValidators([Validators.required(FORM_VALIDATION_ERRORS.zipPostalCode.required)]);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.user) {
      this.form.patchValue(changes.user.currentValue);
    }

    const errors: SimpleChange = changes.serverError;
    const serverAddressError: SimpleChange = changes.serverAddressError;

    if (serverAddressError && serverAddressError.currentValue) {
      const { errorMessages, suspectAddress }: AddressError = serverAddressError.currentValue;

      if (errorMessages && suspectAddress && this.form) {
        this.generateForm(this.form.value, suspectAddress);
      }

      if (errorMessages && !suspectAddress && this.form) {
        this.address.setErrors({
          'general': serverAddressError.currentValue.errorMessages.join('. ')
        } as ValidationErrors);
      }

    }

    if (errors && errors.currentValue) {
      Object
        .entries(this.serverError.errors)
        .forEach(([fieldName, messages]: [string, string[]]) => {
          const control: AbstractControl = this.form.get(fieldName);

          if (control) {
            control.setErrors(messages as ValidationErrors);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  generateForm(currentValue?: IUserAddress, suspectAddress?: IUserAddress): void {
    const { address, ...user }: IUserTransformed = this.user;

    if (!currentValue && !suspectAddress) {
      this.form = this.service.profileForm(false, false, this.user
        ? { ...address, ...user } as IUser
        : null);

      return;
    }

    this.form = this.service.profileForm(false, false, this.user
      ? { ...address, ...user, ...currentValue, ...suspectAddress } as IUser
      : null);
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    const { address, ...form }: any = this.form.value;

    this.onSubmit.emit({ ...form, ...address } as INewUser);
  }

  resetForm(): void {
    this.form.reset(this.user);
  }

  onCountryChange(): void {
    this.countryChanged.emit(this.countryCode.value);
  }

  changePassword(): void {
    this.onChangePassword.emit();
  }

  closeChangePasswordForm(): void {
    this.onCloseChangePasswordForm.emit();
  }
}
