import { Params } from '@angular/router';
import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/pricing.action';

import { IDataListSummary } from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';
import { IPrice } from '../../interfaces/price';

import { PAYMENT_TYPES } from '../../constants/payment';

export interface IPricingState {
  pricingData: IPrice[] | null;
  pricingDataLoading: boolean;
  pricingDataLoaded: boolean;
  pricingDataError: IServerError | null;
}

const initialState: IPricingState = {
  pricingData: null,
  pricingDataLoading: false,
  pricingDataLoaded: false,
  pricingDataError: null
};

const getPricingData: OnReducer<IPricingState, ActionType<any>> = (state: IPricingState) => ({
  ...state,
  pricingDataLoading: true,
  pricingDataError: null,
  pricingDataLoaded: false,
});

const getPricingDataError: OnReducer<IPricingState, ActionType<Payload<IServerError>>> = (state: IPricingState, { payload }: Payload<IServerError>) => ({
  ...state,
  pricingDataLoading: false,
  pricingDataError: { ...payload },
  pricingDataLoaded: false,
});

const getPricingDataSuccess: OnReducer<IPricingState, ActionType<Payload<any>>> = (state: IPricingState, { payload }: Payload<any>) => ({
  ...state,
  pricingDataLoading: false,
  pricingDataLoaded: true,
  pricingDataError: null,
  pricingData: [...payload],
});

const reducer: ActionReducer<IPricingState> = createReducer<IPricingState>(
  initialState,

  on(actions.getPricingDataAction, getPricingData),
  on(actions.getPricingDataErrorAction, getPricingDataError),
  on(actions.getPricingDataSuccessAction, getPricingDataSuccess)
);

export function pricingReducer(state: IPricingState, action: Action): IPricingState {
  return reducer(state, action);
}

export const pricingData: GetFromState<IPrice[] | null, IPricingState> = (state: IPricingState): IPrice[] | null => state.pricingData;
export const pricingDataLoaded: GetFromState<boolean, IPricingState> = (state: IPricingState): boolean => state.pricingDataLoaded;

export const isEmailPayment: GetFromState<boolean, Params> = (_queryParams: Params): boolean =>
  _queryParams && _queryParams.type && _queryParams.type === PAYMENT_TYPES.EMAIL;

export const isListDataForPayment: GetFromState<boolean, Params, IDataListSummary> = (_queryParams: Params, _listSummaryOrRefreshData: IDataListSummary): boolean => {
  return _queryParams && _queryParams.listId && !!_listSummaryOrRefreshData;
};
