export enum AUTH_PATHS {
  LOGIN = 'login',
  SIGN_UP = 'sign-up',
  SIGN_UP_BY_INVITE = 'sign-up-by-invite',
  SIGN_UP_IN_FRAME = 'sign-up-in-frame',
  SIGN_UP_IN_FRAME_SUCCESS = 'sign-up-in-frame-success',
  RESET_PASSWORD = 'reset-password',
  FORGOT_PASSWORD = 'forgot-password',
  FIRST_LOGIN = 'first-login',
  LOGIN_AS_ADMIN = 'login-as-admin',
  LOGIN_AS_MYSELF = 'login-as-myself'
}
