import { InjectionToken } from '@angular/core';

import { IOverlayTargetParams } from '../interfaces';

export const DEFAULT_OVERLAY_TARGET_PARAMS: IOverlayTargetParams = {
  padding: [0, 0, 0, 0],
  targetBg: '#FFFFFF',
  targetAvailable: true
};

export const OVERLAY_TARGET: InjectionToken<IOverlayTargetParams> = new InjectionToken<IOverlayTargetParams>('OVERLAY_TARGET');
export const OVERLAY_TARGET_PARAMS: InjectionToken<IOverlayTargetParams> =
  new InjectionToken<IOverlayTargetParams>('OVERLAY_TARGET_PARAMS');
