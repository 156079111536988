import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IChildInstitutionOnly,
  IParentInstitutionOnly
} from '@ui/pop-up/interfaces';
import {
  IAddPidData,
  IAddPidRequestData,
  IPidsChildAndParentPopUpData,
  IPidSuggestionRequest,
  IPIDSuggestion,
  IRemovePidPayload,
  IRemovePIDChildrenPayload,
  ISelectMultiPidPayload
} from '../../interfaces/pids';

enum ACTION_TYPES {
  LOAD_SUGGESTIONS = '[Pid targeting] pid load suggestions',
  LOAD_SUGGESTIONS_ERROR = '[Pid targeting] pid load suggestions error',
  LOAD_SUGGESTIONS_SUCCESS = '[Pid targeting] pid load suggestions success',
  LOAD_SUGGESTIONS_RESET = '[Pid targeting] pid load suggestions reset',

  ADD_PID = '[Pid targeting] Add PID',
  ADD_PID_REQUEST = '[Pid targeting] add PID request',
  ADD_PID_ERROR = '[Pid targeting] Add PID request error',
  RESET_ADD_PID_ERROR = '[Pid targeting] reset add pid error ',
  ADD_PID_SUCCESS = '[Pid targeting] Add PID request success',

  REMOVE_PID = '[Pid targeting] Remove PID',
  REMOVE_PID_ERROR = '[Pid targeting] Remove PID error',
  REMOVE_PID_SUCCESS = '[Pid targeting] Remove PID success',

  REMOVE_PID_CHILDREN = '[Pid targeting] Remove PID children',
  REMOVE_PID_CHILDREN_REQUEST = '[Pid targeting] Remove PID children request',

  UPDATE_PID = '[Pid targeting] Update PID',
  UPDATE_PID_ERROR = '[Pid targeting] Update PID error',
  UPDATE_PID_SUCCESS = '[Pid targeting] Update PID success',

  SET_PIDS_ONLY = '[Pid targeting] Set PIDs only',
  SET_PIDS_ONLY_ERROR = '[Pid targeting] Set PIDs only error',
  SET_PIDS_ONLY_SUCCESS = '[Pid targeting] Set PIDs only success',

  ON_SELECT_MULTI = '[Pid targeting] on select multi codes',
  ON_SELECT_MULTI_UPLOAD = '[Pid targeting] on select multi codes upload',

  OPEN_PARENT_INSTITUTION_ONLY_POP_UP = '[Pid targeting] open parent institution only pop up',
  OPEN_CHILD_INSTITUTION_ONLY_POP_UP = '[Pid targeting] open child institution only pop up',
  OPEN_REMOVE_PARENT_CHILDREN_POP_UP = '[Pid targeting] open remove parent PID children pop up',
  PIDS_SAVE_POP_UP_SELECTED_DATA = '[Pid targeting] save selected data in pid parent and child pop ups',
  PIDS_RESET_POP_UP_SELECTED_DATA = '[Pid targeting] reset selected data in pid parent and child pop ups',

  TOGGLE_INCLUDE_PID = '[Pid targeting] toggle PID include',

  PIDS_RESET = '[Pid targeting] PIDS reset'
}


export const loadSuggestionsAction: CreateActionType<ACTION_TYPES.LOAD_SUGGESTIONS, IPidSuggestionRequest> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS, createPayload<IPidSuggestionRequest>());
export const loadSuggestionsErrorAction: CreateActionType<ACTION_TYPES.LOAD_SUGGESTIONS_ERROR, IServerError> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS_ERROR, createPayload<IServerError>());
export const loadSuggestionsSuccessAction: CreateActionType<ACTION_TYPES.LOAD_SUGGESTIONS_SUCCESS, IPIDSuggestion[]> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS_SUCCESS, createPayload<IPIDSuggestion[]>());
export const loadSuggestionsResetAction: ActionCreator<ACTION_TYPES.LOAD_SUGGESTIONS_RESET, () => TypedAction<ACTION_TYPES.LOAD_SUGGESTIONS_RESET>> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS_RESET);

export const addPIDAction: CreateActionType<ACTION_TYPES.ADD_PID, IAddPidData> = createAction(ACTION_TYPES.ADD_PID, createPayload<IAddPidData>());
export const addPIDRequestAction: CreateActionType<ACTION_TYPES.ADD_PID_REQUEST, IAddPidRequestData> = createAction(ACTION_TYPES.ADD_PID_REQUEST, createPayload<IAddPidRequestData>());
export const addPIDErrorAction: CreateActionType<ACTION_TYPES.ADD_PID_ERROR, IServerError> = createAction(ACTION_TYPES.ADD_PID_ERROR, createPayload<IServerError>());
export const addPIDSuccessAction: CreateActionType<ACTION_TYPES.ADD_PID_SUCCESS, any> = createAction(ACTION_TYPES.ADD_PID_SUCCESS, createPayload<any>());

export const removePIDAction: CreateActionType<ACTION_TYPES.REMOVE_PID, IRemovePidPayload> = createAction(ACTION_TYPES.REMOVE_PID, createPayload<IRemovePidPayload>());
export const removePIDErrorAction: CreateActionType<ACTION_TYPES.REMOVE_PID_ERROR, IServerError> = createAction(ACTION_TYPES.REMOVE_PID_ERROR, createPayload<IServerError>());
export const removePIDSuccessAction: CreateActionType<ACTION_TYPES.REMOVE_PID_SUCCESS, any> = createAction(ACTION_TYPES.REMOVE_PID_SUCCESS, createPayload<any>());

export const removePIDChildrenAction: CreateActionType<ACTION_TYPES.REMOVE_PID_CHILDREN, IRemovePIDChildrenPayload> = createAction(ACTION_TYPES.REMOVE_PID_CHILDREN, createPayload<IRemovePIDChildrenPayload>());
export const removePIDChildrenRequestAction: CreateActionType<ACTION_TYPES.REMOVE_PID_CHILDREN_REQUEST, IRemovePIDChildrenPayload> = createAction(
  ACTION_TYPES.REMOVE_PID_CHILDREN_REQUEST,
  createPayload<IRemovePIDChildrenPayload>()
);

export const updatePIDAction: CreateActionType<ACTION_TYPES.UPDATE_PID, IRemovePIDChildrenPayload> = createAction(ACTION_TYPES.UPDATE_PID, createPayload<IRemovePIDChildrenPayload>());
export const updatePIDErrorAction: CreateActionType<ACTION_TYPES.UPDATE_PID_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_PID_ERROR, createPayload<IServerError>());
export const resetPIDErrorAction: ActionCreator<ACTION_TYPES.RESET_ADD_PID_ERROR, () => TypedAction<ACTION_TYPES.RESET_ADD_PID_ERROR>> = createAction(ACTION_TYPES.RESET_ADD_PID_ERROR);
export const updatePIDSuccessAction: CreateActionType<ACTION_TYPES.UPDATE_PID_SUCCESS, any> = createAction(ACTION_TYPES.UPDATE_PID_SUCCESS, createPayload<any>());

export const setPIDsOnlyAction: CreateActionType<ACTION_TYPES.SET_PIDS_ONLY, { checked: boolean, segmentCriteriaId: number }> = createAction(ACTION_TYPES.SET_PIDS_ONLY, createPayload<{ checked: boolean, segmentCriteriaId: number }>());
export const setPIDsOnlyErrorAction: CreateActionType<ACTION_TYPES.SET_PIDS_ONLY_ERROR, IServerError> = createAction(ACTION_TYPES.SET_PIDS_ONLY_ERROR, createPayload<IServerError>());
export const setPIDsOnlySuccessAction: CreateActionType<ACTION_TYPES.SET_PIDS_ONLY_SUCCESS, any> = createAction(ACTION_TYPES.SET_PIDS_ONLY_SUCCESS,
  createPayload<any>());

export const onSelectMultiPidCodesAction: CreateActionType<ACTION_TYPES.ON_SELECT_MULTI, ISelectMultiPidPayload> = createAction(ACTION_TYPES.ON_SELECT_MULTI, createPayload<ISelectMultiPidPayload>());
export const onSelectMultiPidCodesUploadAction: CreateActionType<ACTION_TYPES.ON_SELECT_MULTI_UPLOAD, ISelectMultiPidPayload> = createAction(ACTION_TYPES.ON_SELECT_MULTI_UPLOAD, createPayload<ISelectMultiPidPayload>());

export const openParentInstitutionOnlyPopUpAction: CreateActionType<ACTION_TYPES.OPEN_PARENT_INSTITUTION_ONLY_POP_UP, IParentInstitutionOnly> = createAction(
  ACTION_TYPES.OPEN_PARENT_INSTITUTION_ONLY_POP_UP, createPayload<IParentInstitutionOnly>());
export const openChildInstitutionOnlyPopUpAction: CreateActionType<ACTION_TYPES.OPEN_CHILD_INSTITUTION_ONLY_POP_UP, IChildInstitutionOnly> = createAction(
  ACTION_TYPES.OPEN_CHILD_INSTITUTION_ONLY_POP_UP, createPayload<IChildInstitutionOnly>());
export const openRemoveParentPidChildrenPopUpAction: CreateActionType<ACTION_TYPES.OPEN_REMOVE_PARENT_CHILDREN_POP_UP, IParentInstitutionOnly> = createAction(
  ACTION_TYPES.OPEN_REMOVE_PARENT_CHILDREN_POP_UP, createPayload<IParentInstitutionOnly>());
export const savePidPopUpSelectedDataAction: CreateActionType<ACTION_TYPES.PIDS_SAVE_POP_UP_SELECTED_DATA, IPidsChildAndParentPopUpData> = createAction(
  ACTION_TYPES.PIDS_SAVE_POP_UP_SELECTED_DATA, createPayload<IPidsChildAndParentPopUpData>());
export const resetPidPopUpSelectedDataAction: ActionCreator<ACTION_TYPES.PIDS_RESET_POP_UP_SELECTED_DATA, () => TypedAction<ACTION_TYPES.PIDS_RESET_POP_UP_SELECTED_DATA>> = createAction(ACTION_TYPES.PIDS_RESET_POP_UP_SELECTED_DATA);

export const toggleIncludePidAction: CreateActionType<ACTION_TYPES.TOGGLE_INCLUDE_PID, boolean | void> = createAction(ACTION_TYPES.TOGGLE_INCLUDE_PID, createPayload<boolean | void>());

export const resetPidAction: ActionCreator<ACTION_TYPES.PIDS_RESET, () => TypedAction<ACTION_TYPES.PIDS_RESET>> = createAction(ACTION_TYPES.PIDS_RESET);
