import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/jobs-browse.action';

import { IServerError } from '@shared/interfaces/server-error';
import { IBrowseEntities } from '../../interfaces/browse';

export interface JobsBrowseState {
  institutionTypesForCategory: IBrowseEntities;
  categoriesByInstitutionTypes: IBrowseEntities;

  jobsByCategory: IBrowseEntities;

  subjectsByCategory: IBrowseEntities;
  jobsBySubject: IBrowseEntities;

  institutionTypesForAlphabet: IBrowseEntities;
  alphabetByInstitutionType: IBrowseEntities;
  jobsByAlphabet: IBrowseEntities;

  institutionTypesForCategoryLoading: boolean;
  categoriesByInstitutionTypesLoading: boolean;

  jobsByCategoryLoading: boolean;

  subjectsByCategoryLoading: boolean;
  jobsBySubjectLoading: boolean;

  institutionTypesForAlphabetLoading: boolean;
  alphabetByInstitutionTypeLoading: boolean;
  jobsByAlphabetLoading: boolean;

  isOpenedJobBrowse: boolean;
  openedInstitutionTypeForCategoryName: string;
  openedCategoryByInstitutionTypesId: number;
  openedSubjectByCategoryId: number;
  openedInstitutionTypeForAlphabetName: string;
  openedAlphabetName: string;

  jobsBrowseLoadingError: IServerError;

  institutionTypeScrolledElementId: string | null;
  categoryScrolledElementId: string | null;
  disciplineScrolledElementId: string | null;
  subjectScrolledElementId: string | null;
}

const initialState: JobsBrowseState = {
  institutionTypesForCategory: null,
  categoriesByInstitutionTypes: null,

  jobsByCategory: null,

  subjectsByCategory: null,
  jobsBySubject: null,

  institutionTypesForAlphabet: null,
  alphabetByInstitutionType: null,
  jobsByAlphabet: null,

  institutionTypesForCategoryLoading: false,
  categoriesByInstitutionTypesLoading: false,

  jobsByCategoryLoading: false,

  subjectsByCategoryLoading: false,
  jobsBySubjectLoading: false,

  institutionTypesForAlphabetLoading: false,
  alphabetByInstitutionTypeLoading: false,
  jobsByAlphabetLoading: false,

  isOpenedJobBrowse: false,
  openedInstitutionTypeForCategoryName: null,
  openedCategoryByInstitutionTypesId: null,
  openedSubjectByCategoryId: null,
  openedInstitutionTypeForAlphabetName: null,
  openedAlphabetName: null,

  jobsBrowseLoadingError: null,

  subjectScrolledElementId: null,
  disciplineScrolledElementId: null,
  categoryScrolledElementId: null,
  institutionTypeScrolledElementId: null,
};

const loadInstitutionTypesForCategory: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  institutionTypesForCategory: null,
  categoriesByInstitutionTypes: null,
  jobsByCategory: null,
  subjectsByCategory: null,
  jobsBySubject: null,
  openedInstitutionTypeForCategoryName: null,
  openedCategoryByInstitutionTypesId: null,
  openedSubjectByCategoryId: null,
  institutionTypesForCategoryLoading: true,
  jobsBrowseLoadingError: null,
});

const loadInstitutionTypesForCategorySuccess: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => ({
  ...state,
  institutionTypesForCategoryLoading: false,
  institutionTypesForCategory: [...payload],
});

const loadCategoriesByInstitutionType: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => payload.reset
  ? {
    ...state,
    categoriesByInstitutionTypesLoading: true,
    jobsBrowseLoadingError: null,
    openedInstitutionTypeForCategoryName: payload.institutionType,
    categoriesByInstitutionTypes: null,
    jobsByCategory: null,
    subjectsByCategory: null,
    jobsBySubject: null,
    openedCategoryByInstitutionTypesId: null,
    openedSubjectByCategoryId: null,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  }
  : {
    ...state,
    categoriesByInstitutionTypesLoading: true,
    jobsBrowseLoadingError: null,
    openedInstitutionTypeForCategoryName: payload.institutionType,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  };

const loadCategoriesByInstitutionTypeSuccess: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload: { data, requestParams } }: Payload<any>) => ({
  ...state,
  categoriesByInstitutionTypesLoading: false,
  categoriesByInstitutionTypes: [...data],
  institutionTypeScrolledElementId: typeof requestParams.elementId === 'string'
    ? requestParams.elementId
    : null
});

const resetCategoriesByInstitutionType: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  categoriesByInstitutionTypes: null,
  jobsByCategory: null,
  subjectsByCategory: null,
  jobsBySubject: null,
  openedCategoryByInstitutionTypesId: null,
  openedSubjectByCategoryId: null,
  openedInstitutionTypeForCategoryName: null,
  categoriesByInstitutionTypesLoading: null,
  subjectScrolledElementId: null,
  disciplineScrolledElementId: null,
  categoryScrolledElementId: null,
  institutionTypeScrolledElementId: null,
});

const loadJobsByCategory: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => payload.reset
  ? {
    ...state,
    jobsByCategoryLoading: true,
    jobsBrowseLoadingError: null,
    openedCategoryByInstitutionTypesId: payload.categoryId,
    jobsByCategory: null,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  } : {
    ...state,
    jobsByCategoryLoading: true,
    jobsBrowseLoadingError: null,
    openedCategoryByInstitutionTypesId: payload.categoryId,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  };

const loadJobsByCategorySuccess: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload: { data, requestParams } }: Payload<any>) => ({
  ...state,
  jobsByCategoryLoading: false,
  jobsByCategory: [...data],
  categoryScrolledElementId: typeof requestParams.elementId === 'string'
    ? requestParams.elementId
    : null
});

const resetJobsByCategory: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  openedCategoryByInstitutionTypesId: null,
  jobsByCategory: null,
  jobsByCategoryLoading: false,
  subjectScrolledElementId: null,
  disciplineScrolledElementId: null,
  categoryScrolledElementId: null,
  institutionTypeScrolledElementId: null,
});

const loadSubjectsByDiscipline: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => payload.reset
  ? {
    ...state,
    subjectsByCategoryLoading: true,
    openedCategoryByInstitutionTypesId: payload.disciplineId,
    jobsBrowseLoadingError: null,
    subjectsByCategory: null,
    jobsBySubject: null,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  } : {
    ...state,
    subjectsByCategoryLoading: true,
    openedCategoryByInstitutionTypesId: payload.disciplineId,
    jobsBrowseLoadingError: null,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  };

const loadSubjectsByDisciplineSuccess: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload: { data, requestParams } }: Payload<any>) => ({
  ...state,
  subjectsByCategoryLoading: false,
  subjectsByCategory: [...data],
  disciplineScrolledElementId: typeof requestParams.elementId === 'string'
    ? requestParams.elementId
    : null
});

const resetSubjectsByCategory: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  subjectsByCategory: null,
  jobsBySubject: null,
  subjectsByCategoryLoading: false,
  openedCategoryByInstitutionTypesId: null,
  subjectScrolledElementId: null,
  disciplineScrolledElementId: null,
  categoryScrolledElementId: null,
  institutionTypeScrolledElementId: null,
});

const loadJobsBySubject: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => payload.reset
  ? {
    ...state,
    jobsBySubjectLoading: true,
    openedSubjectByCategoryId: payload.subjectId,
    jobsBrowseLoadingError: null, jobsBySubject: null,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  } : {
    ...state,
    jobsBySubjectLoading: true,
    openedSubjectByCategoryId: payload.subjectId,
    jobsBrowseLoadingError: null,
    subjectScrolledElementId: null,
    disciplineScrolledElementId: null,
    categoryScrolledElementId: null,
    institutionTypeScrolledElementId: null,
  };

const loadJobsBySubjectSuccess: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload: { data, requestParams } }: Payload<any>) => ({
  ...state,
  jobsBySubjectLoading: false,
  jobsBySubject: [...data],
  subjectScrolledElementId: typeof requestParams.elementId === 'string'
    ? requestParams.elementId
    : null
});

const resetJobsBySubject: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  jobsBySubject: null,
  jobsBySubjectLoading: true,
  openedSubjectByCategoryId: null,
  subjectScrolledElementId: null,
  disciplineScrolledElementId: null,
  categoryScrolledElementId: null,
  institutionTypeScrolledElementId: null,
});

const loadInstitutionTypesForAlphabet: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  institutionTypesForAlphabet: null,
  alphabetByInstitutionType: null,
  jobsByAlphabet: null,
  openedInstitutionTypeForAlphabetName: null,
  openedAlphabetName: null,
  institutionTypesForAlphabetLoading: true,
  jobsBrowseLoadingError: null,
});

const loadInstitutionTypesForAlphabetSuccess: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => ({
  ...state,
  institutionTypesForAlphabetLoading: false,
  institutionTypesForAlphabet: [...payload]
});

const loadAlphabetByInstitutionType: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => ({
  ...state,
  alphabetByInstitutionTypeLoading: true,
  alphabetByInstitutionType: null,
  jobsByAlphabet: null,
  openedAlphabetName: null,
  openedInstitutionTypeForAlphabetName: payload.institutionType,
  jobsBrowseLoadingError: null,
});

const loadAlphabetByInstitutionTypeSuccessAction: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => ({
  ...state,
  alphabetByInstitutionTypeLoading: false,
  alphabetByInstitutionType: payload,
});

const resetAlphabetByInstitutionType: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  alphabetByInstitutionTypeLoading: false,
  alphabetByInstitutionType: null,
  jobsByAlphabet: null,
  openedAlphabetName: null,
  openedInstitutionTypeForAlphabetName: null,
});

const loadJobsByAlphabet: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => payload.reset
  ? {
    ...state,
    jobsByAlphabetLoading: true,
    openedAlphabetName: payload.query,
    jobsBrowseLoadingError: null, jobsByAlphabet: null
  } : {
    ...state,
    jobsByAlphabetLoading: true,
    openedAlphabetName: payload.query,
    jobsBrowseLoadingError: null
  };

const loadJobsByAlphabetSuccess: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => ({
  ...state,
  jobsByAlphabetLoading: false,
  jobsByAlphabet: [...payload],
});

const resetJobsByAlphabet: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...state,
  jobsByAlphabet: null,
  jobsByAlphabetLoading: true,
  openedAlphabetName: null,
  jobsBrowseLoadingError: null,
});

const _jobsBrowseLoadingError: OnReducer<JobsBrowseState, ActionType<Payload<IServerError>>> = (state: JobsBrowseState, { payload }: Payload<IServerError>) => ({
  ...state,
  institutionTypesForCategoryLoading: false,
  categoriesByInstitutionTypesLoading: false,
  jobsByCategoryLoading: false,
  subjectsByCategoryLoading: false,
  jobsBySubjectLoading: false,
  institutionTypesForAlphabetLoading: false,
  alphabetByInstitutionTypeLoading: false,
  jobsByAlphabetLoading: false,
  jobsBrowseLoadingError: { ...payload },
});

const toggleJobsBrowse: OnReducer<JobsBrowseState, ActionType<Payload<any>>> = (state: JobsBrowseState, { payload }: Payload<any>) => ({
  ...state,
  isOpenedJobBrowse: payload
});

const resetInstitutionTypes: OnReducer<JobsBrowseState, ActionType<any>> = (state: JobsBrowseState) => ({
  ...initialState,
  isOpenedJobBrowse: state.isOpenedJobBrowse
});

const reducer: ActionReducer<JobsBrowseState> = createReducer<JobsBrowseState>(
  initialState,

  on(actions.loadInstitutionTypesForCategoryAction, loadInstitutionTypesForCategory),
  on(actions.loadInstitutionTypesForCategorySuccessAction, loadInstitutionTypesForCategorySuccess),

  on(actions.loadCategoriesByInstitutionTypeAction, loadCategoriesByInstitutionType),
  on(actions.loadCategoriesByInstitutionTypeSuccessAction, loadCategoriesByInstitutionTypeSuccess),
  on(actions.resetCategoriesByInstitutionTypeAction, resetCategoriesByInstitutionType),

  on(actions.loadJobsByCategoryAction, loadJobsByCategory),
  on(actions.loadJobsByCategorySuccessAction, loadJobsByCategorySuccess),
  on(actions.resetJobsByCategoryAction, resetJobsByCategory),

  on(actions.loadSubjectsByDisciplineAction, loadSubjectsByDiscipline),
  on(actions.loadSubjectsByDisciplineSuccessAction, loadSubjectsByDisciplineSuccess),
  on(actions.resetSubjectsByDisciplineAction, resetSubjectsByCategory),

  on(actions.loadJobsBySubjectAction, loadJobsBySubject),
  on(actions.loadJobsBySubjectSuccessAction, loadJobsBySubjectSuccess),
  on(actions.resetJobsBySubjectAction, resetJobsBySubject),

  on(actions.loadInstitutionTypesForAlphabetAction, loadInstitutionTypesForAlphabet),
  on(actions.loadInstitutionTypesForAlphabetSuccessAction, loadInstitutionTypesForAlphabetSuccess),

  on(actions.loadAlphabetByInstitutionTypeAction, loadAlphabetByInstitutionType),
  on(actions.loadAlphabetByInstitutionTypeSuccessAction, loadAlphabetByInstitutionTypeSuccessAction),
  on(actions.resetAlphabetByInstitutionTypeAction, resetAlphabetByInstitutionType),

  on(actions.loadJobsByAlphabetAction, loadJobsByAlphabet),
  on(actions.loadJobsByAlphabetSuccessAction, loadJobsByAlphabetSuccess),
  on(actions.resetJobsByAlphabetAction, resetJobsByAlphabet),
  on(actions.jobsBrowseLoadingErrorAction, _jobsBrowseLoadingError),

  on(actions.toggleJobsBrowseAction, toggleJobsBrowse),

  on(actions.resetInstitutionTypesForCategoryAction, resetInstitutionTypes),
  on(actions.resetInstitutionTypesForAlphabetAction, resetInstitutionTypes),
  on(actions.resetJobsBrowseStateAction, resetInstitutionTypes)
);

export function jobsBrowseReducer(state: JobsBrowseState,
                                  action: Action): JobsBrowseState {
  return reducer(state, action);
}


export const institutionTypesForCategory: GetFromState<IBrowseEntities, JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities => state.institutionTypesForCategory;
export const categoriesByInstitutionTypes: GetFromState<IBrowseEntities , JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities  => state.categoriesByInstitutionTypes;
export const jobsByCategory: GetFromState<IBrowseEntities, JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities => state.jobsByCategory;
export const subjectsByCategory: GetFromState<IBrowseEntities, JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities => state.subjectsByCategory;
export const jobsBySubject: GetFromState<IBrowseEntities, JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities => state.jobsBySubject;
export const institutionTypesForAlphabet: GetFromState<IBrowseEntities, JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities => state.institutionTypesForAlphabet;
export const alphabetByInstitutionType: GetFromState<IBrowseEntities, JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities => state.alphabetByInstitutionType;
export const jobsByAlphabet: GetFromState<IBrowseEntities, JobsBrowseState> = (state: JobsBrowseState): IBrowseEntities => state.jobsByAlphabet;

export const institutionTypesForCategoryLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.institutionTypesForCategoryLoading;
export const categoriesByInstitutionTypesLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.categoriesByInstitutionTypesLoading;
export const jobsByCategoryLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.jobsByCategoryLoading;
export const subjectsByCategoryLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.subjectsByCategoryLoading;
export const jobsBySubjectLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.jobsBySubjectLoading;
export const institutionTypesForAlphabetLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.institutionTypesForAlphabetLoading;
export const alphabetByInstitutionTypeLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.alphabetByInstitutionTypeLoading;
export const jobsByAlphabetLoading: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.jobsByAlphabetLoading;

export const openedInstitutionTypeForCategoryName: GetFromState<string, JobsBrowseState> = (state: JobsBrowseState): string => state.openedInstitutionTypeForCategoryName;
export const openedCategoryByInstitutionTypesId: GetFromState<number, JobsBrowseState> = (state: JobsBrowseState): number => state.openedCategoryByInstitutionTypesId;
export const openedSubjectByCategoryId: GetFromState<number, JobsBrowseState> = (state: JobsBrowseState): number => state.openedSubjectByCategoryId;
export const openedInstitutionTypeForAlphabetName: GetFromState<string, JobsBrowseState> = (state: JobsBrowseState): string => state.openedInstitutionTypeForAlphabetName;
export const openedAlphabetName: GetFromState<string, JobsBrowseState> = (state: JobsBrowseState): string => state.openedAlphabetName;

export const jobsBrowseLoadingError: GetFromState<IServerError | null, JobsBrowseState> = (state: JobsBrowseState): IServerError | null => state.jobsBrowseLoadingError;

export const openedJobBrowse: GetFromState<boolean, JobsBrowseState> = (state: JobsBrowseState): boolean => state.isOpenedJobBrowse;

export const institutionTypeScrolledElementId: GetFromState<string, JobsBrowseState> = (state: JobsBrowseState): string => state.institutionTypeScrolledElementId;
export const categoryScrolledElementId: GetFromState<string, JobsBrowseState> = (state: JobsBrowseState): string => state.categoryScrolledElementId;
export const disciplineScrolledElementId: GetFromState<string, JobsBrowseState> = (state: JobsBrowseState): string => state.disciplineScrolledElementId;
export const subjectScrolledElementId: GetFromState<string, JobsBrowseState> = (state: JobsBrowseState): string => state.subjectScrolledElementId;

export const scrolledElementId: GetFromState<string, string, string, string, string> = (_institutionTypeScrolledElementId: string | null,
                                  _categoryScrolledElementId: string | null,
                                  _disciplineScrolledElementId: string | null,
                                  _subjectScrolledElementId: string | null): string => _institutionTypeScrolledElementId
  || _categoryScrolledElementId || _disciplineScrolledElementId || _subjectScrolledElementId;
