import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/credits.action';

import { StorageService } from '@core/services/storage.service';

import { IServerError } from '@shared/interfaces/server-error';
import { ICreditsResponse, IUpdateICreditsResponse } from '../../../auth/interfaces/user';

import { DEFAULT_CREDITS_DATA } from '../../../profile/constants/credits';

export interface ICreditsState {
  credits: ICreditsResponse;
  pending: boolean;
  error: IServerError | null;
  isSkipUpdateCredits: boolean;
}

const initialState: ICreditsState = {
  credits: StorageService.credits
    ? { ...DEFAULT_CREDITS_DATA, ...StorageService.credits }
    : { ...DEFAULT_CREDITS_DATA },
  pending: false,
  error: null,
  isSkipUpdateCredits: false
};

const getCredits: OnReducer<ICreditsState, ActionType<any>> = (state: ICreditsState) => ({
  ...state,
  pending: true
});

const getCreditsError: OnReducer<ICreditsState, ActionType<any>> = (state: ICreditsState) => ({
  ...state,
  pending: false
});

const getCreditsSuccess: OnReducer<ICreditsState, ActionType<Payload<any>>> = (state: ICreditsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  credits: typeof payload.credits === 'number'
    ? { ...state.credits, ...payload }
    : { ...state.credits },
});

const updateCredits: OnReducer<ICreditsState, ActionType<Payload<any>>> = (state: ICreditsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  credits: typeof payload.credits === 'number'
    ? { ...state.credits, ...payload }
    : { ...state.credits }
});

const setSkipUpdateCredits: OnReducer<ICreditsState, ActionType<Payload<any>>> = (state: ICreditsState, { payload }: Payload<any>) => ({
  ...state,
  isSkipUpdateCredits: payload
});

const reducer: ActionReducer<ICreditsState> = createReducer<ICreditsState>(
  initialState,

  on(actions.getCreditsAction, getCredits),
  on(actions.getCreditsErrorAction, getCreditsError),
  on(actions.getCreditsSuccessAction, getCreditsSuccess),

  on(actions.updateCreditsAction, updateCredits),

  on(actions.setSkipUpdateCreditsAction, setSkipUpdateCredits),
);

export function creditsReducer(state: ICreditsState, action: Action): ICreditsState {
  return reducer(state, action);
}

export const creditsPending: GetFromState<boolean, ICreditsState> = (state: ICreditsState): boolean => state && state.pending;
export const credits: GetFromState<number, ICreditsResponse | IUpdateICreditsResponse> = (state: ICreditsResponse | IUpdateICreditsResponse): number => state && state.credits;
export const creditsFullInfo: GetFromState<ICreditsResponse | IUpdateICreditsResponse, ICreditsState> = (state: ICreditsState): ICreditsResponse | IUpdateICreditsResponse => state.credits;


export const isSkipUpdateCredits: GetFromState<boolean, ICreditsState> = (state: ICreditsState): boolean => state && state.isSkipUpdateCredits;
