import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/grants.action';

import { IServerError } from '@shared/interfaces/server-error';

import { MIN_LENGTH_FOR_SEARCH } from '../../constants/autocomplete';

export interface IGrantsState {
  keywords: string[];
  keywordsError: IServerError;
  include: boolean;
  shouldReset: boolean;
}

const initialState: IGrantsState = {
  keywords: [],
  keywordsError: null,
  include: true,
  shouldReset: false
};

const getGrantsKeywords: OnReducer<IGrantsState, ActionType<Payload<any>>> = (state: IGrantsState, { payload }: Payload<any>) => ({
  ...state,
  error: null,
  keywords: payload.length >= MIN_LENGTH_FOR_SEARCH ? [...state.keywords] : [],
  shouldReset: payload.length < MIN_LENGTH_FOR_SEARCH
});

const getGrantsKeywordsError: OnReducer<IGrantsState, ActionType<Payload<IServerError>>> = (state: IGrantsState, { payload }: Payload<IServerError>) => ({
  ...state,
  keywordsError: { ...payload },
  shouldReset: false
});

const getGrantsKeywordsSuccess: OnReducer<IGrantsState, ActionType<Payload<any>>> = (state: IGrantsState, { payload }: Payload<any>) => ({
  ...state,
  keywords: state.shouldReset
    ? []
    : [...payload],
  shouldReset: false
});

const resetGrantsKeywords: OnReducer<IGrantsState, ActionType<any>> = (state: IGrantsState) => ({
  ...state,
  keywords: [],
  keywordsError: null,
  shouldReset: true
});

const toggleIncludeGrants: OnReducer<IGrantsState, ActionType<any>> = (state: IGrantsState) => ({
  ...state,
  include: !state.include
});

const grantsReset: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<IGrantsState> = createReducer<IGrantsState>(
  initialState,

  on(actions.getGrantsKeywordsAction, getGrantsKeywords),
  on(actions.getGrantsKeywordsErrorAction, getGrantsKeywordsError),
  on(actions.getGrantsKeywordsSuccessAction, getGrantsKeywordsSuccess),

  on(actions.resetGrantsKeywordsAction, resetGrantsKeywords),
  on(actions.toggleIncludeGrantsAction, toggleIncludeGrants),

  on(actions.grantsResetAction, grantsReset)
);

export function grantsReducer(state: IGrantsState, action: Action): IGrantsState {
  return reducer(state, action);
}

export const keywords: GetFromState<string[], IGrantsState> = (state: IGrantsState): string[] => state.keywords;
export const include: GetFromState<boolean, IGrantsState> = (state: IGrantsState): boolean => state.include;
