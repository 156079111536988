import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CORE_PATHS } from '@core/constants/core-paths';
import { E_COMMERCE_PATHS } from '../../../e-commerce/constants/e-commerce-paths';


@Component({
  selector: 'bl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly eCommercePaths: typeof E_COMMERCE_PATHS = E_COMMERCE_PATHS;

  @Input() isMobile: boolean;
  @Input() isBroker: boolean;
  @Input() isAdminsAccount: boolean;
  @Input() isLoggedIn: boolean;

  @Output() onRedirect: EventEmitter<any> = new EventEmitter();
}
