import { ElementRef, EventEmitter, QueryList } from '@angular/core';

import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { createKeyboardListeners } from '../utils/keyboard';

import { IControlOption, IControlOptions } from '../interfaces/forms';

export abstract class BaseSelect {

  abstract options: IControlOptions = [];
  abstract listEl: ElementRef;
  abstract listItems: QueryList<ElementRef>;
  abstract select: EventEmitter<any>;

  abstract filterKeyDown: () => boolean;
  abstract close: ($event?: Event) => void;

  highlightedOptionIndex: number = null;
  selectedOption: IControlOption = null;
  alternativeOptions?: IControlOptions = [];

  protected destroyer$: Subject<void> = new Subject();

  protected propagateChange: (value: string) => void;
  protected propagateTouch: () => void;

  constructor(private _document: any) {
  }

  listenKeyboard(): void {
    const [moveSelection$, onEnter$]: any = this.createKeyboardListeners();

    moveSelection$.subscribe(this.moveSelection);
    onEnter$.subscribe(() => this.selectOption(null, this.options[this.highlightedOptionIndex]));
  }

  createKeyboardListeners(): Array<Observable<any>> {
    const listenKeyDown$: Observable<any> = fromEvent(this._document, 'keydown')
      .pipe(
        takeUntil(this.destroyer$),
        filter(() => this.filterKeyDown())
      );

    const [moveSelection$, onEnter$]: any = createKeyboardListeners(listenKeyDown$);

    return [moveSelection$, onEnter$.pipe(filter(() => this.highlightedOptionIndex !== null))];
  }

  moveSelection = (state: 1 | -1) => {
    let index: number = this.highlightedOptionIndex;

    if (index === null) {
      index = 0;
    } else {
      index++;
    }

    index += state;

    if (index > this.options.length) {
      return;
    }

    if (index < 1) {
      return;
    }

    this.highlightedOptionIndex = index - 1;
    this.scrollToItem();
  }

  scrollToItem(): void {
    const item: ElementRef = this.listItems.toArray()[this.highlightedOptionIndex];
    const { bottom, height, top }: ClientRect = item.nativeElement.getBoundingClientRect();

    const { bottom: listBottom, top: listTop }: ClientRect = this.listEl.nativeElement.getBoundingClientRect();
    const scrollTop: number = this.listEl.nativeElement.scrollTop;

    if (top < listTop) {
      this.listEl.nativeElement.scrollTop = scrollTop - height;
    }

    if (listBottom - bottom < height) {
      this.listEl.nativeElement.scrollTop = scrollTop + height;
    }
  }

  selectOption(event: Event, option: IControlOption): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.selectedOption = option;
    this.propagateChange(this.selectedOption.value);
    this.select.emit(this.selectedOption.value);

    this.close();
  }

  writeValue(value: any): void {
    if (value !== void (0)) {
      if (value === null) {
        this.selectedOption = null;
        return;
      }

      if (typeof (value) === 'object' && value.label && value.value) {
        this.selectedOption = value;

      } else {
        const option: IControlOption = this.options.find((_option: IControlOption) => _option.value === value)
          || this.alternativeOptions.find((_option: IControlOption) => _option.value === value);

        this.selectedOption = option || null;
      }
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }
}
