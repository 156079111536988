import { Pipe, PipeTransform } from '@angular/core';

import { SEATS } from '../interfaces/marketview';

import { SEAT_NAMES } from '../constants/products';

@Pipe({
  name: 'seatsName'
})
export class SeatsNamePipe implements PipeTransform {

  transform(value: string, type: SEATS): string {
    return SEAT_NAMES[type] ? SEAT_NAMES[type] : value;
  }

}
