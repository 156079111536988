<form [formGroup]="form"
      (submit)="submit()"
      class="row reset-password__panel panel">

  <span class="message--success" *ngIf="successMessage">{{ successMessage }}</span>

  <div class="message--error">
    <bl-server-errors [error]="serverError?.message"></bl-server-errors>
  </div>

  <div class="col-12">

    <div class="wrapper__input">
      <bl-flat-input
        (submit)="submit()"
        [formControlName]="'email'"
        [errors]="form.get('email').errors"
        type="email"
        placeholder="Email Address">
      </bl-flat-input>
    </div>

    <div class="wrapper__input">
      <bl-flat-input
        (submit)="submit()"
        [formControlName]="'oldPassword'"
        [errors]="form.get('oldPassword').errors"
        [isDataPrivate]="true"
        type="password"
        placeholder="Temporary Password">
      </bl-flat-input>
    </div>

    <div class="wrapper__input">
      <bl-flat-input
        (submit)="submit()"
        [formControlName]="'newPassword'"
        [errors]="form.get('newPassword').errors"
        [isDataPrivate]="true"
        type="password"
        placeholder="New Password">
      </bl-flat-input>
    </div>

    <div class="wrapper__input">
      <bl-flat-input
        (submit)="submit()"
        [formControlName]="'confirmPassword'"
        [errors]="form.get('confirmPassword').errors"
        [isDataPrivate]="true"
        type="password"
        placeholder="Confirm New Password">
      </bl-flat-input>

      <div *ngIf="isShowForgotPasswordLink"
           class="forgot-password__section">
        <a [routerLink]="['/', corePaths.AUTH, authPaths.FORGOT_PASSWORD]"
           class="forgot-password__link">
          Forgot Password?
        </a>
      </div>
    </div>


  </div>

  <div class="col-12 reset-password__footer">

    <button type="submit"
            [blBtnSpinner]="loading"
            [disabled]="!form.valid"
            class="btn btn--primary btn--lg">
      Set New Password
    </button>

  </div>

</form>
