<!-- header -->
<div class="row">
  <div class="col-6 col-md-3">
    <bl-product-header [class.active]="isShowProducts"
                       (click)="isMobile ? changeActiveTab(true) : null">
    </bl-product-header>
  </div>

  <div class="col-6 col-md-9">
    <bl-users-header (onChangeInput)="handlerSearch($event)"
                     (onClickAddUser)="openAddUserPopUp()"
                     [class.active]="!isShowProducts"
                     (click)="isMobile ? changeActiveTab(false) : null">
    </bl-users-header>
  </div>
</div><!-- / header -->

<!-- content desktop -->
<ng-container *ngIf="!isMobile">
  <div class="row content">
    <div class="col-md-3 products-section" data-testid="products-side-bar">
      <bl-products-container [seats]="accountSeats$ | async"></bl-products-container>
    </div>

    <div class="col-md-9" data-testid="acc-users-container">
      <bl-account-users [users]="getMapAccountUsersAddOns$ | async"
                        [tableSearch]="tableSearch"
                        [searchableKeys]="SEARCHABLE_KEYS"
                        (onEditUser)="openEditUserPopUp($event)">
      </bl-account-users>
    </div>
  </div>
</ng-container><!-- / content desktop -->

<!-- content mobile -->
<ng-container *ngIf="isMobile">
  <div class="row content"
       [class.no-gutters]="!isShowProducts">

    <div class="col-12">
      <bl-products-container *ngIf="isShowProducts; else mobileUserTable"
                             [seats]="accountSeats$ | async"
                             data-testid="products-side-bar">
      </bl-products-container>
    </div>
  </div>
</ng-container><!-- / content mobile -->

<ng-template #mobileUserTable>
  <bl-users-search-input (onChangeInput)="handlerSearch($event)"></bl-users-search-input>

  <bl-mobile-table [data]="getMapAccountUsersAddOns$ | async"
                   [rowTemplate]="mobileRow"
                   [searchBy]="tableSearch"
                   [searchableKeys]="SEARCHABLE_KEYS"
                   theme="account-users">
  </bl-mobile-table>
</ng-template>

<ng-template #mobileRow
             let-user="row">

  <div class="mobile-row"
       (click)="openEditUserFromMobile(user)"
       matRipple>

    <p *ngIf="user?.status === 0 || user?.isInvited"
       class="user-invite-sent">
      (Invite sent)
    </p>

    <p *ngIf="user?.status !== 0 && !user?.isInvited"
       class="user-name nowrap">
      {{ user.firstName + ' ' + user.lastName | lineSlice: 16 }}

      <span *ngIf="user?.role?.key === ROLE_KEYS.Owner"
            class="user-role">
        ({{ user.role.name }})
      </span>
    </p>

    <p class="user-email">{{ user?.email }}</p>
  </div>

</ng-template>
