import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IStateOptions } from '@shared/interfaces/forms';
import { IServerError } from '@shared/interfaces/server-error';
import { ICreditCardsInfo } from '../../../e-commerce/interfaces/e-commerce';
import { IAccountSeats, IEmails } from '../../interfaces/marketview';

enum ACTION_TYPES {
  GET_EMAILS = '[ACCOUNT]: get emails',
  GET_EMAILS_ERROR = '[ACCOUNT]: get emails error',
  GET_EMAILS_SUCCESS = '[ACCOUNT]: get emails success',
  GET_EMAILS_RESET = '[ACCOUNT]: emails reset',

  GET_CREDITS_CARDS_ERROR = '[ACCOUNT] Get credits cards error',
  GET_CREDITS_CARDS_SUCCESS = '[ACCOUNT] Get credits cards success',

  GET_ACCOUNT_SEATS = '[ACCOUNT]: Get Account Seats',
  GET_ACCOUNT_SEATS_ERROR = '[ACCOUNT]: Get Account Seats Error',
  GET_ACCOUNT_SEATS_SUCCESS = '[ACCOUNT]: Get Account Seats Success',

  RESET_ACCOUNT_SEATS = '[ACCOUNT]: Reset Account Seats',

  GET_STATES = '[ACCOUNT]: Get states',
  GET_STATES_ERROR = '[ACCOUNT]: Get states error',
  GET_STATES_SUCCESS = '[ACCOUNT]: Get states success',

  RESET_STATE = '[ACCOUNT]: Reset state'
}

export const getEmailsAction: CreateActionType<ACTION_TYPES.GET_EMAILS, string> = createAction(ACTION_TYPES.GET_EMAILS, createPayload<string>());
export const getEmailsErrorAction: CreateActionType<ACTION_TYPES.GET_EMAILS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_EMAILS_ERROR, createPayload<IServerError>());
export const getEmailsSuccessAction: CreateActionType<ACTION_TYPES.GET_EMAILS_SUCCESS, IEmails> = createAction(ACTION_TYPES.GET_EMAILS_SUCCESS, createPayload<IEmails>());
export const getEmailsResetAction: ActionCreator<ACTION_TYPES.GET_EMAILS_RESET, () => TypedAction<ACTION_TYPES.GET_EMAILS_RESET>> = createAction(ACTION_TYPES.GET_EMAILS_RESET);

export const getCreditsCardsErrorAction: CreateActionType<ACTION_TYPES.GET_CREDITS_CARDS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_CREDITS_CARDS_ERROR, createPayload<IServerError>());
export const getCreditsCardsSuccessAction: CreateActionType<ACTION_TYPES.GET_CREDITS_CARDS_SUCCESS, ICreditCardsInfo> = createAction(ACTION_TYPES.GET_CREDITS_CARDS_SUCCESS, createPayload<ICreditCardsInfo>());

export const getAccountSeatsAction: ActionCreator<ACTION_TYPES.GET_ACCOUNT_SEATS, () => TypedAction<ACTION_TYPES.GET_ACCOUNT_SEATS>> = createAction(ACTION_TYPES.GET_ACCOUNT_SEATS);
export const getAccountSeatsErrorAction: CreateActionType<ACTION_TYPES.GET_ACCOUNT_SEATS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_ACCOUNT_SEATS_ERROR, createPayload<IServerError>());
export const getAccountSeatsSuccessAction: CreateActionType<ACTION_TYPES.GET_ACCOUNT_SEATS_SUCCESS, IAccountSeats> = createAction(ACTION_TYPES.GET_ACCOUNT_SEATS_SUCCESS, createPayload<IAccountSeats>());

export const resetAccountSeatsAction: ActionCreator<ACTION_TYPES.RESET_ACCOUNT_SEATS, () => TypedAction<ACTION_TYPES.RESET_ACCOUNT_SEATS>> = createAction(ACTION_TYPES.RESET_ACCOUNT_SEATS);

export const getAccountStatesAction: ActionCreator<ACTION_TYPES.GET_STATES, () => TypedAction<ACTION_TYPES.GET_STATES>> = createAction(ACTION_TYPES.GET_STATES);

export const getAccountStatesErrorAction: CreateActionType<ACTION_TYPES.GET_STATES_ERROR, IServerError> = createAction(ACTION_TYPES.GET_STATES_ERROR, createPayload<IServerError>());

export const getAccountStatesSuccessAction: CreateActionType<ACTION_TYPES.GET_STATES_SUCCESS, IStateOptions> = createAction(ACTION_TYPES.GET_STATES_SUCCESS, createPayload<IStateOptions>());

export const resetAccountStateAction: ActionCreator<ACTION_TYPES.RESET_STATE, () => TypedAction<ACTION_TYPES.RESET_STATE>> = createAction(ACTION_TYPES.RESET_STATE);
