<div class="header">
  <div class="header-text">{{ data.header }}</div>
</div>

<div class="content">
  <h4 class="title">{{ data.title }}</h4>

  <div class="body">
    <ul>
      <li *ngFor="let item of data.body; let i = index">{{ i + 1 + ') ' }} {{ item }}</li>
    </ul>
  </div>

  <div class="row flex-column justify-content-center">
    <button type="button"
            class="btn btn--primary"
            (click)="onConfirm()">
      {{ data.buttonConfirm }}
    </button>
    <br/>
    <button class="btn--link"
            (click)="onCancel()">
      {{ data.buttonCancel }}
    </button>
  </div>
</div>
