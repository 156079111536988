import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IAccountUsers,
  IAssignDataFromToUser,
  IRemoveUserAfterAssignedData,
  IRemoveUserFromAccount
} from '../../interfaces/marketview';

enum ACTION_TYPES {
  GET_ACCOUNT_USERS = '[ACCOUNT-USERS] Get account users',
  GET_ACCOUNT_USERS_ERROR = '[ACCOUNT-USERS] Get account users error',
  GET_ACCOUNT_USERS_SUCCESS = '[ACCOUNT-USERS] Get account users success',

  REMOVE_USER = '[ACCOUNT-USERS] Remove user',
  REMOVE_USER_ERROR = '[ACCOUNT-USERS] Remove user error',
  REMOVE_USER_SUCCESS = '[ACCOUNT-USERS] Remove user success',

  ASSIGN_DATA = '[ACCOUNT-USERS] Assign data',
  ASSIGN_DATA_ERROR = '[ACCOUNT-USERS] Assign data error',
  ASSIGN_DATA_SUCCESS = '[ACCOUNT-USERS] Assign data success',

  ASSIGN_DATA_AND_REMOVE_USER = '[ACCOUNT-USERS] Assign data and remove user',

  REMOVE_USER_AFTER_ASSIGN_DATA = '[ACCOUNT-USERS] Remove user after assigned data',
  REMOVE_USER_AFTER_ASSIGN_DATA_SUCCESS = '[ACCOUNT-USERS] Remove user after assigned data success',

  ASSIGN_OWNER = '[ACCOUNT-USERS] Assign owner',
  ASSIGN_OWNER_ERROR = '[ACCOUNT-USERS] Assign owner error',
  ASSIGN_OWNER_SUCCESS = '[ACCOUNT-USERS] Assign owner success',

  RESET_ACCOUNT_USERS = '[ACCOUNT-USERS] Reset account users'
}

export const getAccountUsersAction: ActionCreator<ACTION_TYPES.GET_ACCOUNT_USERS, () => TypedAction<ACTION_TYPES.GET_ACCOUNT_USERS>> = createAction(ACTION_TYPES.GET_ACCOUNT_USERS);
export const getAccountUsersErrorAction: CreateActionType<ACTION_TYPES.GET_ACCOUNT_USERS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_ACCOUNT_USERS_ERROR, createPayload<IServerError>());
export const getAccountUsersSuccessAction: CreateActionType<ACTION_TYPES.GET_ACCOUNT_USERS_SUCCESS, IAccountUsers> = createAction(ACTION_TYPES.GET_ACCOUNT_USERS_SUCCESS, createPayload<IAccountUsers>());

export const removeUserAction: CreateActionType<ACTION_TYPES.REMOVE_USER, IRemoveUserFromAccount> = createAction(ACTION_TYPES.REMOVE_USER, createPayload<IRemoveUserFromAccount>());
export const removeUserErrorAction: CreateActionType<ACTION_TYPES.REMOVE_USER_ERROR, IServerError> = createAction(ACTION_TYPES.REMOVE_USER_ERROR, createPayload<IServerError>());
export const removeUserSuccessAction: CreateActionType<ACTION_TYPES.REMOVE_USER_SUCCESS, boolean> = createAction(ACTION_TYPES.REMOVE_USER_SUCCESS, createPayload<boolean>()); // isInvited

export const assignDataAction: CreateActionType<ACTION_TYPES.ASSIGN_DATA, IAssignDataFromToUser> = createAction(ACTION_TYPES.ASSIGN_DATA, createPayload<IAssignDataFromToUser>());
export const assignDataErrorAction: CreateActionType<ACTION_TYPES.ASSIGN_DATA_ERROR, IServerError> = createAction(ACTION_TYPES.ASSIGN_DATA_ERROR, createPayload<IServerError>());
export const assignDataSuccessAction: ActionCreator<ACTION_TYPES.ASSIGN_DATA_SUCCESS, () => TypedAction<ACTION_TYPES.ASSIGN_DATA_SUCCESS>> = createAction(ACTION_TYPES.ASSIGN_DATA_SUCCESS);

export const assignDataAndRemoveUserAction: CreateActionType<ACTION_TYPES.ASSIGN_DATA_AND_REMOVE_USER, IAssignDataFromToUser> = createAction(ACTION_TYPES.ASSIGN_DATA_AND_REMOVE_USER, createPayload<IAssignDataFromToUser>());

export const removeUserAfterAssignedDataAction: CreateActionType<ACTION_TYPES.REMOVE_USER_AFTER_ASSIGN_DATA, IRemoveUserAfterAssignedData> = createAction(ACTION_TYPES.REMOVE_USER_AFTER_ASSIGN_DATA,
  createPayload<IRemoveUserAfterAssignedData>());
export const removeUserAfterAssignedDataSuccessAction: ActionCreator<ACTION_TYPES.REMOVE_USER_AFTER_ASSIGN_DATA_SUCCESS, () => TypedAction<ACTION_TYPES.REMOVE_USER_AFTER_ASSIGN_DATA_SUCCESS>> = createAction(ACTION_TYPES.REMOVE_USER_AFTER_ASSIGN_DATA_SUCCESS);

export const assignOwnerAction: CreateActionType<ACTION_TYPES.ASSIGN_OWNER, number> = createAction(ACTION_TYPES.ASSIGN_OWNER, createPayload<number>());
export const assignOwnerErrorAction: CreateActionType<ACTION_TYPES.ASSIGN_OWNER_ERROR, IServerError> = createAction(ACTION_TYPES.ASSIGN_OWNER_ERROR, createPayload<IServerError>());
export const assignOwnerSuccessAction: ActionCreator<ACTION_TYPES.ASSIGN_OWNER_SUCCESS, () => TypedAction<ACTION_TYPES.ASSIGN_OWNER_SUCCESS>> = createAction(ACTION_TYPES.ASSIGN_OWNER_SUCCESS);

export const resetAccountUsersAction: ActionCreator<ACTION_TYPES.RESET_ACCOUNT_USERS, () => TypedAction<ACTION_TYPES.RESET_ACCOUNT_USERS>> = createAction(ACTION_TYPES.RESET_ACCOUNT_USERS);
