import { Pipe, PipeTransform } from '@angular/core';

import { placeTypes } from '@shared/constants/data/place-types';

@Pipe({
  name: 'placeType'
})
export class PlaceTypePipe implements PipeTransform {

  transform(placeId: number): any {
    if (placeId === 5) {
      return '';
    }

    return placeTypes[placeId];
  }

}
