import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import { notifications } from '../reducers/notification.reducer';

import { INotification } from '@core/interfaces/notifications';

import { selectNotificationState } from './index';

export const getNotifications: MemoizedSelector<CoreState, INotification[]> = createSelector(selectNotificationState, notifications);
