<div class="wrapper">
  <div class="header">
    <button (click)="onClose()"
            type="button"
            class="btn--close">
      <i class="icon-x-medium"></i>
    </button>
  </div>

  <ng-container *ngIf="isShowHelpContent">
    <div [innerHtml]="heldContent"
         class="cloud-sync-auth-pop-up">
    </div>
  </ng-container>

  <ng-container *ngIf="!isShowHelpContent">
    <span class="title">{{ data?.title }}</span>
    <span class="description">{{ data?.subTitle }}</span>

    <form [formGroup]="form"
          (ngSubmit)="submit()"
          class="auth__profile">

      <div class="tabs--wrapper">
        <bl-radio *ngFor="let item of platformEnvironment"
                  formControlName="sandbox"
                  [label]="item.label"
                  [value]="item.value"
                  theme="radio-tab">
        </bl-radio>
      </div>

      <br/>

      <bl-server-errors [error]="(serverError$ | async)?.message || (authError$ | async)?.message"></bl-server-errors>

      <ng-container *ngFor="let item of data?.fields">
        <div class="input-wrapper">
          <bl-flat-input [formControlName]="item?.name"
                         [placeholder]="item?.label + '*'"
                         [errors]="form.get(item?.name).errors"
                         [type]="item?.type">
          </bl-flat-input>

          <i *ngIf="item?.instructions"
             class="icon-help"
             (click)="onShowHelpContent(item?.instructions)">
          </i>

          <i *ngIf="item?.info"
             [blTooltip]="item.info"
             class="icon-tooltip">
          </i>
        </div>
      </ng-container>

      <div class="footer">
        <button [blBtnSpinner]="authLoading$ | async"
                [disabled]="form.invalid"
                type="submit"
                class="btn btn--primary">
          {{ data?.submitText }}
        </button>
      </div>
    </form>
  </ng-container>

</div>
