<div class="wrapper them--{{ theme }}">
  <div class="input-wrapper"
       [class.focused]="isFocused">

    <label class="label"
           (click)="triggerFocus()"
           [class.active]="isLabelActive"
           [class.focused]="isFocused"
           #label>
      {{ placeholder }}
    </label>

    <input [type]="type"
           [autocomplete]="autocomplete"
           [readonly]="readonly"
           [attr.name]="formControlName || name"
           [attr.data-private]="isDataPrivate ? securityType.REDACT : null"
           [placeholder]="placeHolder"
           [class.input-error]="customErrors || error"
           [class.focused]="isFocused"
           [blAutofocus]="isAutoFocus"
           (blur)="handleBlur()"
           (focus)="handleFocus()"
           (input)="handleChange(input?.value)"
           (keyup)="onKeyUp($event)"
           (keydown)="handlerKeyDown($event)"
           class="input"
           #input>

    <i class="icon-page-close"
       *ngIf="resetBtn"
       (click)="resetValue()">
    </i>

  </div>

  <div class="error"
       [class.error--hide]="shouldHideErrorBox"
       [class.error--show]="!shouldHideErrorBox || customErrors || error">
    <span class="error-message">{{ customErrors || error }}</span>
  </div>
</div>
