import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CoreState } from '../../store/reducers';
import { getDownloadUrl } from '../../store/selectors/download.selector';

import { IDownloadByLink } from '../../interfaces/download-by-link';

@Component({
  selector: 'bl-download-link-container',
  templateUrl: './download-link-container.component.html',
  styleUrls: ['./download-link-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadLinkContainerComponent {

  getDownloadUrl$: Observable<IDownloadByLink> = this._store.pipe(select(getDownloadUrl));

  constructor(private _store: Store<CoreState>) {
  }
}
