export enum LIST_PATHS {
  NEW = 'new',
  FROM_SELECTED = 'from-selected',
  FROM_VIEW = 'from-view',
  VIEW_ID = ':viewId',
  LIST_ID = ':listId',
  REFINE = 'refine',
  DOWNLOAD = 'download',
  APPEND = 'append',
  SUMMARY = 'summary',
  PURCHASE = 'purchase',
  REFRESH = 'refresh',
  REPURCHASE = 'repurchase',
  SEGMENT_ID = ':segmentId',
  GEOGRAPHY = 'geography',
  PERSONNEL = 'personnel',
  NAME_AND_PID = 'institutions'
}
