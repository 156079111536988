import { AbstractControl, ValidatorFn } from '@angular/forms';

import { VALIDATION_REG_EXP } from '../constants/validators/validation-reg-exp';

export default function country(msg?: string): ValidatorFn {

  return (control: AbstractControl) => {
    const message: string = msg || 'Country is not valid';

    return VALIDATION_REG_EXP.country.test(control.value)
      ? null
      : { country: message };
  };
}
