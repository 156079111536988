import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { CREDITS_MODULE_FEATURE_NAME } from '../selectors';

import { creditsReducer, ICreditsState } from './credits.reducer';

export interface ICreditsModuleSTate {
  credits: ICreditsState;
}

export const creditsModuleState: ActionReducerMap<ICreditsModuleSTate> = {
  credits: creditsReducer
};

export const creditsModuleStateToken: InjectionToken<ActionReducerMap<ICreditsModuleSTate>> = new InjectionToken<ActionReducerMap<ICreditsModuleSTate>>(CREDITS_MODULE_FEATURE_NAME);
