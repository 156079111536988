import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Action, Store } from '@ngrx/store';

import { environment } from '@env/environment';

import { defer, of, Observable } from 'rxjs';
import { catchError, debounceTime, map, mapTo, mergeMap, switchMap, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';

import * as tosActions from '@core/store/actions/tos.action';
import { setToUBrokerStatusAction } from '@core/store/actions/tos.action';
import { CoreState } from '@core/store/reducers';
import { getIsIdentifyUserByLogRocket } from '@modules/profile/store/selectors/profile.selector';
import { ActionWithPayload } from '@shared/interfaces/store';
import * as authActions from '../../../auth/store/actions/auth.action';
import { getBillingAction } from '../../../e-commerce/store/actions/payment.action';
import * as profileActions from '../actions/profile.action';

import { ProfileService } from '@core/services/profile.service';
import { StorageService } from '@core/services/storage.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { IServerError } from '@shared/interfaces/server-error';
import { IChangePasswordData, ISuccessLoginActionData } from '../../../auth/interfaces/formsActionsData';
import { IRole, ISuccessMessageResponse, IUser, IUserData } from '../../../auth/interfaces/user';
import { IUpdateProfilePermissionsPayload } from '../../interfaces/profile';
import { IProfileSetting, IProfileSettings } from '../../interfaces/profile-settings';

import * as LogRocket from 'logrocket';

@Injectable()
export class ProfileEffects {

  constructor(private _actions$: Actions,
              private _store: Store<CoreState>,
              private _profileService: ProfileService) {
  }

  getProfile$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.getProfileAction),
      debounceTime(1000),
      switchMap(() => this._profileService.loadUser()
        .pipe(
          tap((data: IUserData) => ProfileService.setUserData(data)),
          map((data: IUserData) => profileActions.getProfileSuccessAction(data)),
          catchError((error: IServerError) => of(profileActions.getProfileErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  getProfileSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.getProfileSuccessAction),
      withLatestFrom(this._store.pipe(select(getIsIdentifyUserByLogRocket))),
      tap(([{ payload: { user } }, isIdentifyUserByLogRocket]: [ActionWithPayload<IUserData>, boolean]) => {
        if (environment.enableLogRocket && !isIdentifyUserByLogRocket) {

          LogRocket.identify(`${ user.id }`, {
            name: `${ user.firstName } ${ user.lastName }`,
            email: user.email,
            isAdminsAccount: StorageService.isAdminsAccount
          });
          this._store.dispatch(profileActions.setIdentifyUserByLocRocketAction(true));
        }
      }),
      mapTo(authActions.checkShowTrialMsgAction()),
      catchErrorWithErrorType
    ));

  updateProfile$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.updateProfileAction),
      switchMap(({ payload: user }: ActionWithPayload<IUser>) => this._profileService.updateUser(user)
        .pipe(
          tap((data: IUserData) => ProfileService.setUserData(data)),
          map((data: IUserData) => profileActions.updateProfileSuccessAction(data)),
          catchError((error: IServerError) => of(profileActions.updateProfileErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  updateProfileAndTax$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.updateProfileAndBillingInfoAction),
      switchMap(({ payload: user }: ActionWithPayload<IUser>) => this._profileService.updateUser(user)
        .pipe(
          tap((data: IUserData) => ProfileService.setUserData(data)),
          mergeMap((data: IUserData) => [
            profileActions.updateProfileAndBillingInfoSuccessAction(data),
            getBillingAction(),
          ]),
          catchError((error: IServerError) => of(profileActions.updateProfileAndBillingInfoErrorAction(error)))
        )),
      // catchError(error => throwError(error))
    ));

  updateUserProfileData$: Observable<Action> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(
        profileActions.getProfileSuccessAction,
        profileActions.updateProfileSuccessAction,
        profileActions.updateProfileAndBillingInfoSuccessAction
      ),
      tap(({ payload: user }: ActionWithPayload<IUserData>) => {
        this._store.dispatch(setToUBrokerStatusAction(user.isBrokerLicenseAccepted));
      }),
      catchErrorWithErrorType
    )), { dispatch: false });

  signOut$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(authActions.signOutAction),
      map(() => profileActions.clearProfileAction()),
      catchErrorWithErrorType
    ));

  loadDataForAdmin$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(authActions.signInSuccessAction),
      map(({ payload }: ActionWithPayload<ISuccessLoginActionData>) => {
        if (payload.asAdmin) {
          return profileActions.getProfileAction();
        }

        ProfileService.setUserData(payload.response as IUserData);
        return profileActions.setProfileAction(payload.response);
      }),
      catchErrorWithErrorType
    ));

  changePassword$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.changePasswordAction),
      switchMap(({ payload: formData }: ActionWithPayload<IChangePasswordData>) => this._profileService.changePassword(formData)
        .pipe(
          map((data: ISuccessMessageResponse) => profileActions.changePasswordSuccessAction(data)),
          catchError((error: IServerError) => of(profileActions.changePasswordErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  getProfileSettings$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.getProfileSettingsAction),
      switchMapTo(this._profileService.getProfileSettings()
        .pipe(
          map((settings: IProfileSettings) => profileActions.getProfileSettingsSuccessAction(settings)),
          catchError((error: IServerError) => of(profileActions.getProfileSettingsErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  updateProfileSettings$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.updateProfileSettingsAction),
      switchMap(({ payload }: ActionWithPayload<IProfileSetting>) => this._profileService.updateProfileSettings(payload.id, payload.value)
        .pipe(
          map(() => profileActions.updateProfileSettingsSuccessAction(payload)),
          catchError((error: IServerError) => of(profileActions.updateProfileSettingsErrorAction(error)))
        ),
      ),
      catchErrorWithErrorType
    ));

  updateProfileSettingsSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.updateProfileSettingsSuccessAction),
      mapTo(profileActions.getProfileSettingsAction()),
      catchErrorWithErrorType
    )
  );

  updateProfilePermission$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(profileActions.changeProfilePermissionAction),
      tap(({
             payload: {
               permissions,
               accountAvailablePermissions
             }
           }: ActionWithPayload<IUpdateProfilePermissionsPayload>) => {
        StorageService.permissions = [...permissions];
        StorageService.accountAvailablePermissions = [...accountAvailablePermissions];
      }),
      mergeMap(() => [
        tosActions.getTermsOfUseAction(),
        profileActions.getProfileAction()
      ]),
      catchErrorWithErrorType
    ));

  updateProfileRole$: Observable<Action> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(profileActions.updateProfileRoleAction),
      tap(({ payload }: ActionWithPayload<IRole>) => StorageService.role = { ...payload }),
      catchErrorWithErrorType
    )), { dispatch: false });

  isBroker$: Observable<Action> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(profileActions.setIsBrokerAction),
      tap(({ payload }: ActionWithPayload<boolean>) => StorageService.isBroker = payload),
      catchErrorWithErrorType
    )), { dispatch: false });
}
