import { Directive } from '@angular/core';

@Directive({
  selector: '[blPaginationContent]'
})
export class PaginationContentDirective {

  constructor() {
  }

}
