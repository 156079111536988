import { AbstractControl, ValidatorFn } from '@angular/forms';

export default function maxLength(msg: string, _maxLength: number, includeEmpty: boolean = true): ValidatorFn {

  return (control: AbstractControl) => {
    const message: string = msg || `Length should be less then ${ _maxLength }`;

    if (!control.value && includeEmpty) {
      return { maxLength: message };
    }

    return control.value.length <= _maxLength
      ? null
      : { maxLength: message };
  };
}
