<button *ngIf="currentPage > 1"
        (click)="onPageDecrement()"
        [disabled]="loading || (currentPage < 2)"
        type="button"
        class="btn-pagination btn-pagination--left"
        matRipple>
  <i class="icon-carrot-left"></i>
</button>

<span class="page-counters">Page {{ currentPage }} of {{ pagesCounter }}</span>

<button (click)="onPageIncrement()"
        [disabled]="loading || (pagesCounter <= currentPage)"
        type="button"
        class="btn-pagination btn-pagination--right"
        matRipple>
  <i class="icon-carrot-right"></i>
</button>
