import { ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getIsLoggedIn } from '@modules/auth/store/selectors/auth.selector';

@Directive({
  selector: '[blIfLoggedIn]'
})
export class IfLoggedInDirective implements OnInit, OnDestroy {

  private _destroyer$: Subject<void> = new Subject();

  constructor(private _templateRef: TemplateRef<any>,
              private _viewContainerRef: ViewContainerRef,
              private _changeDetectorRef: ChangeDetectorRef,
              private _store: Store<CoreState>) {
  }

  ngOnInit(): void {
    this._store
      .pipe(
        select(getIsLoggedIn),
        takeUntil(this._destroyer$),
        tap(() => {
          this._viewContainerRef.clear();
          this._changeDetectorRef.detectChanges();
        }),
        filter((isLoggedIn: boolean) => isLoggedIn),
        tap(() => {
          this._viewContainerRef.createEmbeddedView(this._templateRef);
          this._changeDetectorRef.detectChanges();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }
}
