<div class="credit-meter"
     [class.theme-mobile]="isMobile"
     data-testid="credit-meter-elem">

  <div class="credit-meter__info">

    <span *ngIf="!isUnlimitedUser; else unlimitedMsg"
          (click)="isMobile ? goTo() : null"
          class="credit-meter__counter"
          data-testid="credit-meter__counter">
      {{ (userCredits || 0) | number }}
    </span>&nbsp;

    <span class="credit-meter__label"
          (click)="isMobile ? goTo() : null">
      Credits Remaining
    </span>

    <div *ngIf="!isMobile"
         class="credit-meter__info-tooltip">

      <i [blTooltip]="tooltipText"
         [isOpen]="isOpenedFist"
         class="credit-meter__icon icon-tooltip tooltip-target">
      </i>
    </div>

  </div>

  &nbsp;<button class="credit-meter__btn"
                *ngIf="!isUnlimitedUser"
                [blTooltip]="refreshBtnTooltipText"
                [isOpen]="showTooltipOnInit"
                [disabled]="pending"
                (click)="refresh()"
                data-testid="refresh-credits-btn"
                matRipple>

  <i class="icon-refresh--circle pending-icon"
     [class.pending]="pending">
  </i>

</button>

  <ng-container *ngIf="!isBroker">
    &nbsp;<a *ngIf="!isMobile"
             [routerLink]="['/', corePaths.ECOMM, eCommercePaths.PRICING]"
             class="credit-meter__btn"
             data-testid="buy-more-btn" matRipple>
    Buy More
  </a>
  </ng-container>

</div>

<ng-template #unlimitedMsg>
  <span class="credit-meter__counter"
        (click)="isMobile ? goTo() : null">
    {{ unlimitedCreditsMsg }}
  </span>
</ng-template>
