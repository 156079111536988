import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { creditCardsReducer, ICreditCardsState } from './payment-cards.reducer';
import { paymentReducer, IPaymentState } from './payment.reducer';
import { pricingReducer, IPricingState } from './pricing.reducer';

export interface ECommerceState {
  eCommercePayment: IPaymentState;
  eCommercePaymentCards: ICreditCardsState;
  pricing: IPricingState;
}

const eCommerceReducer: ActionReducerMap<ECommerceState> = {
  eCommercePayment: paymentReducer,
  eCommercePaymentCards: creditCardsReducer,
  pricing: pricingReducer,
};

// tslint:disable-next-line:typedef
export const E_COMMERCE_STATE_NAME = 'eCommerce';
export const E_COMMERCE_STATE: InjectionToken<ActionReducerMap<ECommerceState>> = new InjectionToken<ActionReducerMap<ECommerceState>>(E_COMMERCE_STATE_NAME, {
  factory: () => eCommerceReducer
});
