import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { filter, switchMapTo, takeUntil, tap } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getIsLoggedIn } from '@modules/auth/store/selectors/auth.selector';
import { getIsAdmin } from '@modules/profile/store/selectors/profile.selector';

@Directive({
  selector: '[blIfAdmin]'
})
export class IfAdminDirective implements OnInit, OnDestroy {

  private destroyer$: Subject<void> = new Subject();

  constructor(private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef,
              private store: Store<CoreState>) {
  }

  ngOnInit(): void {
    this.viewContainerRef.clear();

    this.store
      .pipe(
        select(getIsLoggedIn),
        takeUntil(this.destroyer$),
        filter((isLoggedIn: boolean) => isLoggedIn),
        switchMapTo(this.store.pipe(select(getIsAdmin))),
        tap(() => this.viewContainerRef.clear()),
        filter((isAdmin: boolean) => isAdmin)
      )
      .subscribe(() => this.viewContainerRef.createEmbeddedView(this.templateRef));
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
