import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, filter, mapTo, take, tap } from 'rxjs/operators';

import { getPrivacyPolicyDataAction } from '../store/actions/common-pages.action';
import { CoreState } from '../store/reducers';
import { getPrivacyPolicyDataLoaded } from '../store/selectors/common-pages.selector';

@Injectable()
export class PrivacyPolicyDataGuard implements CanActivate {

  constructor(private store: Store<CoreState>) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getPrivacyPolicyDataLoaded),
      tap((loaded: boolean) => {
        if (!loaded) {
          this.store.dispatch(getPrivacyPolicyDataAction());
        }
      }),
      filter((loaded: boolean) => loaded),
      take(1),
      mapTo(true),
      catchError(() => of(false))
    );
  }
}
