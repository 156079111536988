import { createAction } from '@ngrx/store';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IDownloadByLink, ILinks } from '../../interfaces/download-by-link';

enum ACTION_TYPES {
  DOWNLOAD_FILE_BY_LINK = '[DOWNLOAD]: Download file by link',
  DOWNLOAD_FILE_BY_LINKS = '[DOWNLOAD]: Download List by links',

  DOWNLOAD_ORDER_SUMMARY_COUNTS = '[CDL ORDER SUMMARY] Download order summary counts',
}

export const downloadFileByLinkAction: CreateActionType<ACTION_TYPES.DOWNLOAD_FILE_BY_LINK, IDownloadByLink> = createAction(ACTION_TYPES.DOWNLOAD_FILE_BY_LINK, createPayload<IDownloadByLink>());
export const downloadFileByLinksAction: CreateActionType<ACTION_TYPES.DOWNLOAD_FILE_BY_LINKS, ILinks> = createAction(ACTION_TYPES.DOWNLOAD_FILE_BY_LINKS, createPayload<ILinks>());

export const downloadOrderSummaryCountsAction: CreateActionType<ACTION_TYPES.DOWNLOAD_ORDER_SUMMARY_COUNTS, number> = createAction(ACTION_TYPES.DOWNLOAD_ORDER_SUMMARY_COUNTS, createPayload<number>()); // listId
