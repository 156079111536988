import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from '@env/environment';

import { ICloudSyncPlatform } from '@core/interfaces/cloud-sync';

@Component({
  selector: 'bl-sync-to',
  templateUrl: './sync-to.component.html',
  styleUrls: ['./sync-to.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyncToComponent {

  @Input() isSinglePlatform: boolean;
  @Input() platforms: ICloudSyncPlatform[] = [];
  @Input() disabled: boolean;

  @Output() onSelectPlatform: EventEmitter<ICloudSyncPlatform> = new EventEmitter();

  readonly phoneNumber: string = environment.contactPhoneNumber;

  private selectedPlatform: ICloudSyncPlatform;

  onPlatformSelect(platform: ICloudSyncPlatform): void {
    this.selectedPlatform = platform;
    this.onSelectPlatform.emit(platform);
  }
}
