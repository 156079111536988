<bl-server-errors [error]="(serverError$ | async)?.message"></bl-server-errors>

<div *ngIf="shouldShowUpdateSuccessMessage"
     class="message--success">
  {{ updateSuccessMessage$ | async }}
</div>

<bl-profile-form [user]="user$ | async"
                 [role]="roleKey$ | async"
                 [serverError]="serverError$ | async"
                 [serverAddressError]="serverAddressError$ | async"
                 [loading]="loading$ | async"
                 [countries]="countriesOptions$ | async"
                 [countryStates]="countryStatesOptions$ | async"
                 [shouldShowPasswordForm]="shouldShowPasswordForm"
                 [changePasswordLoading]="changePasswordLoading$ | async"
                 [changePasswordDisabled]="form.invalid"
                 (countryChanged)="onCountryChange($event)"
                 (onSubmit)="submitProfile($event)"
                 (onChangePassword)="changePassword()"
                 (onCloseChangePasswordForm)="closeChangePasswordForm()">

  <div *ngIf="!shouldShowPasswordForm"
       (click)="openPasswordForm()"
       class="mb-5">

    <bl-flat-input type="password"
                   placeholder="Password*"
                   [isDataPrivate]="true"
                   [ngModel]="1111111"
                   [ngModelOptions]="{ standalone: true }"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>

  <!-- reset password form-->
  <p *ngIf="shouldShowSuccessMessage"
     class="message--success">
    {{ changePasswordSuccessMessage$ | async }}
  </p>

  <div *ngIf="shouldShowPasswordForm"
       class="sub-form-wrapper">

    <span class="sub-form-wrapper__close"
          (click)="closeChangePasswordForm()">
      <i class="icon-page-close"></i>
    </span>

    <bl-change-password-form [form]="form"
                             [serverError]="changePasswordError$ | async">
    </bl-change-password-form>
  </div><!-- / reset password form-->

</bl-profile-form>
