import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IPerPages, PER_PAGES } from '@shared/constants/pagination';

@Component({
  selector: 'bl-mobile-table-per-page',
  templateUrl: './mobile-table-per-page.component.html',
  styleUrls: ['./mobile-table-per-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTablePerPageComponent {
  @Input() limit: number = PER_PAGES[0];
  @Input() limits: IPerPages = PER_PAGES;

  @Output() onChangeLimit: EventEmitter<number> = new EventEmitter<number>();
}
