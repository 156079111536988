import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CoreState } from '@core/store/reducers';
import { closeAssignAddOnInfoPopUp, closeAssignStateInfoPopUp } from '../../store/actions/account-pop-ups.action';
import {
  getIsOpenAssignAddOnInfoPopUp,
  getIsOpenAssignStateInfoPopUp
} from '../../store/selectors/account-pop-ups.selector';

import { StorageService } from '@core/services/storage.service';

@Component({
  selector: 'bl-assign-state-pop-up-container',
  templateUrl: './assign-state-pop-up-container.component.html',
  styleUrls: ['./assign-state-pop-up-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AssignStatePopUpContainerComponent {

  isOpenAssignStateInfoPopUp$: Observable<boolean> = this._store.pipe(select(getIsOpenAssignStateInfoPopUp));
  isOpenAssignAddOnInfoPopUp$: Observable<boolean> = this._store.pipe(select(getIsOpenAssignAddOnInfoPopUp));

  constructor(private _store: Store<CoreState>) {
  }

  confirmStatePopUp(): void {
    this._store.dispatch(closeAssignStateInfoPopUp());
    StorageService.doNotShowAssignStatePopUp = true;
  }

  closeStatePopUp(): void {
    this._store.dispatch(closeAssignStateInfoPopUp());
  }

  confirmAddOnPopUp(): void {
    this._store.dispatch(closeAssignAddOnInfoPopUp());
    StorageService.doNotShowAssignAddOnPopUp = true;
  }

  closeAddOnPopUp(): void {
    this._store.dispatch(closeAssignAddOnInfoPopUp());
  }
}
