import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LayoutService } from '@core/services/layout.service';

import { PopUpConfig } from '../../models/pop-up-config';
import { PopUpRef } from '../../models/pop-up-ref';

import { POP_UP_CONFIG, POP_UP_CONTENT_COMPONENT } from '../../injection-tokens';

@Component({
  selector: 'bl-pop-up-portal',
  templateUrl: './pop-up-portal.component.html',
  styleUrls: ['./pop-up-portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopUpPortalComponent<T> implements AfterViewInit, OnDestroy, OnInit, AfterViewChecked {

  @ViewChild('container', { read: ViewContainerRef, static: true }) viewContainer: ViewContainerRef;
  @ViewChild('overlay', { static: true }) overlay: ElementRef;
  @ViewChild('popupContent', { static: true }) popupContent: ElementRef;

  private destroyer$: Subject<void> = new Subject();

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private changeDetectorRef: ChangeDetectorRef,
              private popUpRef: PopUpRef<T>,
              private layoutService: LayoutService,
              private renderer: Renderer2,
              @Inject(POP_UP_CONFIG) private config: PopUpConfig,
              @Inject(POP_UP_CONTENT_COMPONENT) private component: Type<T>) {
  }

  ngOnInit(): void {
    if (this.config.withOverlayBackground) {
      this.renderer.addClass(this.overlay.nativeElement, 'overlay__background');
      this.changeDetectorRef.detectChanges();
    }

    this.layoutService.isPopupHeightLessThenScreen
      .pipe(takeUntil(this.destroyer$))
      .subscribe((state: boolean) => {
        if (state) {
          this.renderer.addClass(this.overlay.nativeElement, this.config.isVerticalCentered ?
            'overlay__align-items-center' : 'overlay__align-items');
        } else {
          this.renderer.removeClass(this.overlay.nativeElement, this.config.isVerticalCentered ?
            'overlay__align-items-center' : 'overlay__align-items');
        }
        this.changeDetectorRef.markForCheck();
      });

    this.layoutService.popupContentElement = this.popupContent;
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    const componentFactory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(this.component);
    const componentRef: ComponentRef<T> = this.viewContainer.createComponent(componentFactory);
    this.popUpRef.setComponentInstance(componentRef);
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.layoutService.checkPopupHeight();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  onScrollOverlay($event: Event): void {
    this.layoutService.setIsScrollOverlay(true);
    this.changeDetectorRef.detectChanges();
  }

  onOverlay(event: MouseEvent): void {
    event.stopPropagation();
    this.layoutService.triggerDocumentClick();

    if (this.config.closeOnOverlay) {
      this.popUpRef.close();
    }
  }

  onContent(event: MouseEvent): void {
    event.stopPropagation();
    this.layoutService.triggerDocumentClick();
  }
}
