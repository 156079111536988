import { NgModule } from '@angular/core';

import { OverlayInfoModule } from '../overlay-info/overlay-info.module';
import { OverlayTargetModule } from '../overlay-target/overlay-target.module';

import { OnBoardProvider } from './providers/on-board-provider.service';

@NgModule({
  imports: [
    OverlayInfoModule,
    OverlayTargetModule
  ],
  providers: [
    OnBoardProvider
  ]
})
export class OnBoardModule {
}
