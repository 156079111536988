import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { go } from '@core/store/actions/router-history.action';
import { CoreState } from '@core/store/reducers';

import { PopUpText } from '../../models/pop-up-data';
import { PopUpRef } from '../../models/pop-up-ref';

import { CORE_PATHS } from '@core/constants/core-paths';

import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-re-download-pop-up-content',
  templateUrl: './re-download-pop-up-content.component.html',
  styleUrls: ['./re-download-pop-up-content.component.scss']
})
export class ReDownloadPopUpContentComponent {

  constructor(private store: Store<CoreState>,
              private popUpRef: PopUpRef,
              @Inject(POP_UP_DATA) public data: PopUpText) {
  }

  onClose(): void {
    this.popUpRef.close();
    this.store.dispatch(go(['/', CORE_PATHS.DASHBOARD]));
  }
}
