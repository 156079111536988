import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { pluckAndCatch } from '@shared/utils/response-formater';

@Injectable({
  providedIn: 'root'
})
export class PaymentOperationsService {

  private readonly _showEmailUrl: string = environment.api.marketView.showEmail;

  constructor(private http: HttpClient) {
  }

  showEmail(personId: number): Observable<{ email: string }> {
    return this.http.post(this._showEmailUrl, { personId })
      .pipe(pluckAndCatch);
  }
}
