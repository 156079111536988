<bl-tabs>
  <bl-tab [label]="(roleConfig$ | async)?.profileTabTitle"
          [url]="['./', profileRouterPaths.PRODUCTS]"
          theme="account"
          data-testid="users-tab">
  </bl-tab>
  <bl-tab *ngIf="!(isAdminsAccount$ | async)"
          label="account info"
          [url]="['./', profileRouterPaths.DETAILS]"
          theme="account"
          data-testid="account-tab">
  </bl-tab>
  <bl-tab *ngIf="!(isBroker$ | async) && !(isAdminsAccount$ | async) && (userIsHasAddress$ | async)"
          label="billing info"
          [url]="['./', profileRouterPaths.BILLING]"
          theme="account"
          data-testid="billing-tab">
  </bl-tab>
  <bl-tab *ngIf="!(isAdminsAccount$ | async)"
          label="settings"
          [url]="['./', profileRouterPaths.SETTINGS]"
          theme="account"
          data-testid="settings-tab">
  </bl-tab>
</bl-tabs>
