import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IGeoPlace,
  IGeoPlaceId,
  IGeoPlaceIdToReload,
  IGeoPlaceToReload,
  IGeoSelectAllByTypeWithActions,
  IGeoSuggestionBackEnd,
  IRemovePlaceAndReloadByType
} from '../../interfaces/geography';

import { IChangeTargetingCriteria, IOnSegmentUpdateDetailedDataControls } from '@shared/modules/targeting-criteria-controls/data';

enum ACTION_TYPES {
  LOAD_GEO_SUGGESTIONS = '[Geo targeting] geo load places',
  LOAD_GEO_SUGGESTIONS_ERROR = '[Geo targeting] geo load places error',
  LOAD_GEO_SUGGESTIONS_SUCCESS = '[Geo targeting] geo load places success',
  LOAD_GEO_SUGGESTIONS_RESET = '[Geo targeting] geo load places reset',

  ADD_PLACE = '[Geo targeting] Add Place',
  ADD_PLACE_RANGE = '[Geo targeting] Add Place Range',
  ADD_PLACE_AND_RELOAD = '[Geo targeting] Add Place And Reload',
  ADD_PLACE_ERROR = '[Geo targeting] Add Place error',
  ADD_PLACE_SUCCESS = '[Geo targeting] Add Place success',

  REMOVE_PLACE = '[Geo targeting] Remove Place',
  REMOVE_PLACE_AND_RELOAD = '[Geo targeting] Remove Place And Reload',
  REMOVE_PLACE_AND_RELOAD_BY_TYPE = '[Geo targeting] Remove place and reload by type',
  REMOVE_PLACE_ERROR = '[Geo targeting] Remove Place error',
  RESET_ADD_PLACE_ERROR = '[Geo Targeting] Reset Add Place error',
  REMOVE_PLACE_SUCCESS = '[Geo targeting] Remove Place success',

  UPDATE_PLACE = '[Geo targeting] Update Place',
  UPDATE_PLACE_AND_RELOAD = '[Geo targeting] Update Place and reload',
  UPDATE_PLACE_AND_RELOAD_BY_TYPE = '[Geo targeting] Update place and reload by type',
  UPDATE_PLACE_ERROR = '[Geo targeting] Update Place error',
  UPDATE_PLACE_SUCCESS = '[Geo targeting] Update Place success',

  ON_UPDATE_GEO_DETAILED = '[Geo targeting] On Update Geo Detailed Data',
  UPDATE_GEO_DETAILED = '[Geo targeting] Update Geo Detailed Data',
  UPDATE_GEO_DETAILED_ERROR = '[Geo targeting] Update Geo Detailed error',
  UPDATE_GEO_DETAILED_SUCCESS = '[Geo targeting] Update Geo Detailed success',

  SELECT_ALL_GEO_BY_TYPE = '[Segment] Select All Geo By Type',
  SELECT_ALL_GEO_BY_TYPE_SUCCESS = '[Segment] Select All Geo By Type Success',
  SELECT_ALL_GEO_BY_TYPE_ERROR = '[Segment] Select All Geo By Type Error',

  DESELECT_ALL_GEO_BY_TYPE = '[Segment] Deselect All Geo By Type',
  DESELECT_ALL_GEO_BY_TYPE_SUCCESS = '[Segment] Deselect All Geo By Type Success',
  DESELECT_ALL_GEO_BY_TYPE_ERROR = '[Segment] Deselect All Geo By Type Error',

  TOGGLE_INCLUDE_GEO = '[Geo targeting] toggle include Geo',

  RESET = '[Geo targeting] Geo reset state'
}

export const loadGeoSuggestionsAction: CreateActionType<ACTION_TYPES.LOAD_GEO_SUGGESTIONS, string> = createAction(ACTION_TYPES.LOAD_GEO_SUGGESTIONS, createPayload<string>());
export const loadGeoSuggestionsErrorAction: CreateActionType<ACTION_TYPES.LOAD_GEO_SUGGESTIONS_ERROR, IServerError> = createAction(ACTION_TYPES.LOAD_GEO_SUGGESTIONS_ERROR, createPayload<IServerError>());
export const loadGeoSuggestionsSuccessAction: CreateActionType<ACTION_TYPES.LOAD_GEO_SUGGESTIONS_SUCCESS, IGeoSuggestionBackEnd[]> = createAction(ACTION_TYPES.LOAD_GEO_SUGGESTIONS_SUCCESS,
  createPayload<IGeoSuggestionBackEnd[]>());
export const loadGeoSuggestionsResetAction: ActionCreator<ACTION_TYPES.LOAD_GEO_SUGGESTIONS_RESET, () => TypedAction<ACTION_TYPES.LOAD_GEO_SUGGESTIONS_RESET>> = createAction(ACTION_TYPES.LOAD_GEO_SUGGESTIONS_RESET);

export const addPlaceAction: CreateActionType<ACTION_TYPES.ADD_PLACE, IGeoPlace> = createAction(ACTION_TYPES.ADD_PLACE, createPayload<IGeoPlace>());
export const addPlaceRangeAction: CreateActionType<ACTION_TYPES.ADD_PLACE_RANGE, IGeoPlace> = createAction(ACTION_TYPES.ADD_PLACE_RANGE, createPayload<IGeoPlace>());
export const addPlaceAndReloadAction: CreateActionType<ACTION_TYPES.ADD_PLACE_AND_RELOAD, IGeoPlaceToReload> = createAction(ACTION_TYPES.ADD_PLACE_AND_RELOAD,
  createPayload<IGeoPlaceToReload>());
export const addPlaceErrorAction: CreateActionType<ACTION_TYPES.ADD_PLACE_ERROR, IServerError> = createAction(ACTION_TYPES.ADD_PLACE_ERROR, createPayload<IServerError>());
export const resetAddPlaceErrorAction: ActionCreator<ACTION_TYPES.RESET_ADD_PLACE_ERROR, () => TypedAction<ACTION_TYPES.RESET_ADD_PLACE_ERROR>> = createAction(ACTION_TYPES.RESET_ADD_PLACE_ERROR);
export const addPlaceSuccessAction: ActionCreator<ACTION_TYPES.ADD_PLACE_SUCCESS, () => TypedAction<ACTION_TYPES.ADD_PLACE_SUCCESS>> = createAction(ACTION_TYPES.ADD_PLACE_SUCCESS);

export const removePlaceAction: CreateActionType<ACTION_TYPES.REMOVE_PLACE, IGeoPlaceId> = createAction(ACTION_TYPES.REMOVE_PLACE, createPayload<IGeoPlaceId>());
export const removePlaceAndReloadAction: CreateActionType<ACTION_TYPES.REMOVE_PLACE_AND_RELOAD, IGeoPlaceIdToReload> = createAction(ACTION_TYPES.REMOVE_PLACE_AND_RELOAD,
  createPayload<IGeoPlaceIdToReload>());
export const removePlaceAndReloadByTypeAction: CreateActionType<ACTION_TYPES.REMOVE_PLACE_AND_RELOAD_BY_TYPE, IRemovePlaceAndReloadByType> = createAction(ACTION_TYPES.REMOVE_PLACE_AND_RELOAD_BY_TYPE,
  createPayload<IRemovePlaceAndReloadByType>()
);
export const removePlaceErrorAction: CreateActionType<ACTION_TYPES.REMOVE_PLACE_ERROR, IServerError> = createAction(ACTION_TYPES.REMOVE_PLACE_ERROR, createPayload<IServerError>());
export const removePlaceSuccessAction: ActionCreator<ACTION_TYPES.REMOVE_PLACE_SUCCESS, () => TypedAction<ACTION_TYPES.REMOVE_PLACE_SUCCESS>> = createAction(ACTION_TYPES.REMOVE_PLACE_SUCCESS);

export const updatePlaceAction: CreateActionType<ACTION_TYPES.UPDATE_PLACE, IGeoPlace> = createAction(ACTION_TYPES.UPDATE_PLACE, createPayload<IGeoPlace>());
export const updatePlaceAndReloadAction: CreateActionType<ACTION_TYPES.UPDATE_PLACE_AND_RELOAD, IGeoPlaceToReload> = createAction(ACTION_TYPES.UPDATE_PLACE_AND_RELOAD,
  createPayload<IGeoPlaceToReload>()
);
export const updatePlaceAndReloadByTypeAction: CreateActionType<ACTION_TYPES.UPDATE_PLACE_AND_RELOAD_BY_TYPE, IGeoPlace> = createAction(ACTION_TYPES.UPDATE_PLACE_AND_RELOAD_BY_TYPE,
  createPayload<IGeoPlace>()
);
export const updatePlaceErrorAction: CreateActionType<ACTION_TYPES.UPDATE_PLACE_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_PLACE_ERROR, createPayload<IServerError>());
export const updatePlaceSuccessAction: ActionCreator<ACTION_TYPES.UPDATE_PLACE_SUCCESS, () => TypedAction<ACTION_TYPES.UPDATE_PLACE_SUCCESS>> = createAction(ACTION_TYPES.UPDATE_PLACE_SUCCESS);

export const onUpdateGeoDetailedDataAction: CreateActionType<ACTION_TYPES.ON_UPDATE_GEO_DETAILED, IOnSegmentUpdateDetailedDataControls> = createAction(ACTION_TYPES.ON_UPDATE_GEO_DETAILED,
  createPayload<IOnSegmentUpdateDetailedDataControls>());

export const updateGeoDetailedDataAction: CreateActionType<ACTION_TYPES.UPDATE_GEO_DETAILED, IChangeTargetingCriteria> = createAction(ACTION_TYPES.UPDATE_GEO_DETAILED,
  createPayload<IChangeTargetingCriteria>());
export const updateGeoDetailedDataErrorAction: CreateActionType<ACTION_TYPES.UPDATE_GEO_DETAILED_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_GEO_DETAILED_ERROR, createPayload<IServerError>());
export const updateGeoDetailedDataSuccessAction: ActionCreator<ACTION_TYPES.UPDATE_GEO_DETAILED_SUCCESS, () => TypedAction<ACTION_TYPES.UPDATE_GEO_DETAILED_SUCCESS>> = createAction(ACTION_TYPES.UPDATE_GEO_DETAILED_SUCCESS);

export const selectAllGeographyByTypeAction: CreateActionType<ACTION_TYPES.SELECT_ALL_GEO_BY_TYPE, IGeoSelectAllByTypeWithActions> = createAction(
  ACTION_TYPES.SELECT_ALL_GEO_BY_TYPE,
  createPayload<IGeoSelectAllByTypeWithActions>()
);
export const selectAllGeographyByTypeSuccessAction: ActionCreator<ACTION_TYPES.SELECT_ALL_GEO_BY_TYPE_SUCCESS, () => TypedAction<ACTION_TYPES.SELECT_ALL_GEO_BY_TYPE_SUCCESS>> = createAction(ACTION_TYPES.SELECT_ALL_GEO_BY_TYPE_SUCCESS);
export const selectAllGeographyByTypeErrorAction: CreateActionType<ACTION_TYPES.SELECT_ALL_GEO_BY_TYPE_ERROR, IServerError> = createAction(
  ACTION_TYPES.SELECT_ALL_GEO_BY_TYPE_ERROR,
  createPayload<IServerError>()
);

export const deselectAllGeographyByTypeAction: CreateActionType<ACTION_TYPES.DESELECT_ALL_GEO_BY_TYPE, IGeoSelectAllByTypeWithActions> = createAction(
  ACTION_TYPES.DESELECT_ALL_GEO_BY_TYPE,
  createPayload<IGeoSelectAllByTypeWithActions>()
);
export const deselectAllGeographyByTypeSuccessAction: ActionCreator<ACTION_TYPES.DESELECT_ALL_GEO_BY_TYPE_SUCCESS, () => TypedAction<ACTION_TYPES.DESELECT_ALL_GEO_BY_TYPE_SUCCESS>> = createAction(ACTION_TYPES.DESELECT_ALL_GEO_BY_TYPE_SUCCESS);
export const deselectAllGeographyByTypeErrorAction: CreateActionType<ACTION_TYPES.DESELECT_ALL_GEO_BY_TYPE_ERROR, IServerError> = createAction(
  ACTION_TYPES.DESELECT_ALL_GEO_BY_TYPE_ERROR,
  createPayload<IServerError>()
);

export const toggleIncludeGeoAction: ActionCreator<ACTION_TYPES.TOGGLE_INCLUDE_GEO, () => TypedAction<ACTION_TYPES.TOGGLE_INCLUDE_GEO>> = createAction(ACTION_TYPES.TOGGLE_INCLUDE_GEO);

export const geoResetState: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
