<div class="horizontal-scroller_wrapper" #hsWrapper
     [ngClass]="{
        'horizontal-scroller_wrapper--active': isInlineListWidthBigger,
        'horizontal-scroller_wrapper--divided': isInlineListWidthBigger && showHorizontalDivider
     }"
     [style.height.px]="height"
     [style.overflow-y]="isHovered ? 'visible' : 'hidden'">
  <div class="horizontal-scroller_scroller" #hsScroller>
    <!-- height + 4 for additional spacing for scrollbar -->
    <div class="horizontal-scroller_content" #hsContent
         [style.padding-left.px]="paddingLeft"
         [style.height.px]="height + 4">
      <ng-content></ng-content>
    </div>
  </div>
</div>
