<div #contentWrapperEl class="wrapper">

  <div [ngClass]="stickyElementClasses"
       [style.z-index]="levelHeight ? levelHeight : ''"
       [class.align-content]="isAlignContent"
       [class.hide-content]="isFirstHideContent"
       #contentEl>

    <ng-content></ng-content>

  </div>
</div>
