import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ConfirmOrNotPopUpData } from '../../models/pop-up-data';
import { PopUpRef } from '../../models/pop-up-ref';
import { ConfirmOrNotPopUpResult } from '../../models/pop-up-result';

import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-confirm-or-not-pop-up',
  templateUrl: './confirm-or-not-pop-up.component.html',
  styleUrls: ['./confirm-or-not-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmOrNotPopUpComponent {

  doNotShowPopUpAgain: boolean = false;

  constructor(private popUpRef: PopUpRef<ConfirmOrNotPopUpComponent, ConfirmOrNotPopUpResult>,
              @Inject(POP_UP_DATA) public data: ConfirmOrNotPopUpData) {
  }

  onClose(): void {
    this.popUpRef.close({ answer: false, onDecline: false, doNotShowPopUpAgain: this.doNotShowPopUpAgain });
  }

  onConfirm(): void {
    this.popUpRef.close({ answer: true, onDecline: false, doNotShowPopUpAgain: this.doNotShowPopUpAgain });
  }

  onDecline(): void {
    this.popUpRef.close({ answer: false, onDecline: true, doNotShowPopUpAgain: this.doNotShowPopUpAgain });
  }
}
