import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ICloudSyncPlatform } from '@core/interfaces/cloud-sync';

import { CloudSyncToPopUpData, ICloudSyncToResult } from '../../models/pop-up-data';
import { PopUpRef } from '../../models/pop-up-ref';

import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-cloud-sync-to-pop-up',
  templateUrl: './cloud-sync-to-pop-up.component.html',
  styleUrls: ['./cloud-sync-to-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudSyncToPopUpComponent {
  constructor(private ref: PopUpRef<CloudSyncToPopUpComponent, ICloudSyncToResult>,
              @Inject(POP_UP_DATA) public data: CloudSyncToPopUpData) {
  }

  onCloseClick(): void {
    this.ref.close({ onCancel: true, platform: null });
  }

  selectPlatform(platform: ICloudSyncPlatform): void {
    this.ref.close({ onCancel: false, platform });
  }
}
