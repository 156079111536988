import { createAction } from '@ngrx/store';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IHideNotification, INotification } from '@core/interfaces/notifications';

enum ACTION_TYPES {
  SHOW_NOTIFICATION = '[NOTIFICATION] Show',
  HIDE_NOTIFICATION = '[NOTIFICATION] Hide',
}

export const showNotificationAction: CreateActionType<ACTION_TYPES.SHOW_NOTIFICATION, INotification> = createAction(ACTION_TYPES.SHOW_NOTIFICATION, createPayload<INotification>());
export const hideNotificationAction: CreateActionType<ACTION_TYPES.HIDE_NOTIFICATION, IHideNotification> = createAction(ACTION_TYPES.HIDE_NOTIFICATION, createPayload<IHideNotification>());
