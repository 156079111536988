import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { pluckAndCatch } from '@shared/utils/response-formater';

import { IOrderData } from '../interfaces/order-confirmation';


@Injectable()
export class OrderService {

  constructor(private http: HttpClient) {
  }

  loadOrderData(): Observable<IOrderData> {
    return this.http.get(environment.api.user.orderConfirmation)
      .pipe(pluckAndCatch);
  }
}
