<bl-credit-meter-container [isMobile]="true"
                           (goTo)="goToPricingPage()">
</bl-credit-meter-container>

<bl-account-links [theme]="'mobile'"
                  [isShowBillingDetails]="!(isBroker$ | async) && userIsHasAddress$ | async"
                  (onRedirect)="closeMenuMobile()"
                  (logout)="logout()">
</bl-account-links>

<bl-footer [isMobile]="true"
           [isBroker]="isBroker$ | async"
           [isAdminsAccount]="isAdminsAccount$ | async"
           [isLoggedIn]="isLoggedIn$ | async"
           (onRedirect)="closeMenuMobile()">
</bl-footer>
