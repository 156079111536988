import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { sortByFieldAsync } from '@shared/modules/cloud-sync-components/utils/sort-by-field-async';
import { errorHandler, pluckAndCatch } from '@shared/utils/response-formater';

import {
  ICheckAuthResponse,
  ICloudSyncAuthField,
  ICloudSyncAuthFields,
  ICloudSyncAuthResponse,
  ICloudSyncPlatform,
  ICloudSyncPlatforms,
  ICloudSyncPlatformAuthPayload,
  IPlatformAssignResponse,
} from '../interfaces/cloud-sync';

import { Validators } from '@shared/validators/validators';


@Injectable({
  providedIn: 'root'
})
export class CloudSyncAuthService {

  private _baseUrl: string = environment.api.cloudSync.main;
  private _platformUrl: string = environment.api.cloudSync.platforms;
  private _authUrl: string = environment.api.cloudSync.auth;
  private _authCheckUrl: string = environment.api.cloudSync.check;

  constructor(private http: HttpClient,
              private fb: FormBuilder) {
  }

  getPlatforms(): Observable<ICloudSyncPlatforms> {
    return this.http.get(`${ this._baseUrl }/${ this._platformUrl }`)
      .pipe(
        pluck('data'),
        sortByFieldAsync<ICloudSyncPlatform>('connectionId'),
        errorHandler
      );
  }

  getPlatformFields(platformId: number): Observable<ICloudSyncAuthFields> {
    return this.http.get(`${ this._baseUrl }/${ this._authUrl }/${ platformId }`)
      .pipe(pluckAndCatch);
  }

  platformAssignToAccount(platformId: number, type: number): Observable<IPlatformAssignResponse> {
    return this.http.post(`${ this._baseUrl }/${ this._platformUrl }/${ platformId }`, { type })
      .pipe(pluck('data'));
  }

  platformSignIn(platformId: number, data: ICloudSyncPlatformAuthPayload, connectionId: number): Observable<ICloudSyncAuthResponse> {
    return this.http.post(`${ this._baseUrl }/${ this._authUrl }/${ platformId }`, { ...data, connectionId })
      .pipe(pluckAndCatch);
  }

  checkAuth(connectionId: number, sandbox: boolean): Observable<ICheckAuthResponse> {
    return this.http.post(`${ this._baseUrl }/${ this._authUrl }/${ this._authCheckUrl }`, { connectionId, sandbox })
      .pipe(pluckAndCatch);
  }

  createAuthForm(_fields: ICloudSyncAuthFields, sandbox: boolean = true): FormGroup {
    const formControls: { sandbox: boolean[] } = {
      sandbox: [sandbox]
    };

    _fields.forEach((item: ICloudSyncAuthField) => {
      formControls[item.name] = ['', Validators.required(`${ item.label } is required`)];
    });

    return this.fb.group(formControls as { [key: string]: any });
  }
}
