<ng-container *ngIf="form">

  <div class="rename-list-wrapper {{ page }}"
       [class.renaming]="isRenaming"
       [blTooltip]="shouldShowTooltip ? getTooltipText() : null"
       [textSize]="errorMsg ? 12 : 18"
       [isOpen]="isTooltipOpen"
       (click)="startEdit()"
       #topElemRef
       data-testid="rename-list-form">

    <span class="static-name"
          *ngIf="!isRenaming"
          [class.list__name--error]="!!validationNameErrors || !!errorMsg">

      {{ displayValue | lineSlice: maxLengthName }}
    </span>

    <form class="rename-list"
          *ngIf="isRenaming"
          [formGroup]="form">

      <bl-autofocus-input [ngClass]="{ 'list__name--error': !!validationNameErrors || !!errorMsg }"
                          [formControlName]="'name'"
                          (blur)="onBlur($event)"
                          (keyup)="onKeyUp($event)"
                          (keydown.enter)="onEnterPress($event)"
                          class="{{ page }}">
      </bl-autofocus-input>

    </form>
  </div>

</ng-container>
