import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { getCanCheckTOS } from '@modules/profile/store/selectors/profile.selector';
import { redirectToTosAction } from '../store/actions/tos.action';
import { CoreState } from '../store/reducers';
import { getTOSStatus } from '../store/selectors/tos.selector';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';
import { getUrlFromActivatedRoute } from '@shared/utils/get-url-grom-activated-route';

@Injectable()
export class TosGuard implements CanActivate {

  constructor(private store: Store<CoreState>) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    return this.checkTOS(next);
  }

  private checkTOS(activatedRoute?: ActivatedRouteSnapshot): Observable<boolean> {

    const url: string = getUrlFromActivatedRoute(activatedRoute);
    const { queryParams }: ActivatedRouteSnapshot = activatedRoute;

    return this.store
      .pipe(
        select(getCanCheckTOS),
        take(1),
        withLatestFrom(this.store.pipe(select(getTOSStatus))),
        map(([canCheck, status]: [boolean, boolean]) => {
          if (canCheck && !status) {
            this.store.dispatch(redirectToTosAction({ url, queryParams }));
            return status;
          }

          return true;
        }),
        catchErrorWithErrorType
      );
  }
}
