import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AccountBillingExistGuard } from './guards/account-billing-exist.guard';
import { AdminAccountRedirectGuard } from './guards/admin-account-redirect-guard';

import { PROFILE_ROUTES } from './constants/profile-routes';

// constants

// guards


@NgModule({
  imports: [
    RouterModule.forChild(PROFILE_ROUTES)
  ],
  exports: [RouterModule],
  providers: [AdminAccountRedirectGuard, AccountBillingExistGuard]
})
export class ProfileRoutingModule {
}
