import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SToMSDatePipe'
})
export class SToMSDatePipe implements PipeTransform {

  transform(value: number, dimension?: string): Date {
    let timestamp: number = value;

    switch (dimension) {
      case ('ms'):
        timestamp *= 1000;
        break;
    }

    return value ? new Date(timestamp) : null;
  }

}
