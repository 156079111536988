import { AbstractControl, ValidatorFn } from '@angular/forms';

export default function max(msg: string, maxCount: number): ValidatorFn {

  return (control: AbstractControl) => {
    const message: string = msg || `Max available entities is ${ maxCount }`;

    return control.value <= maxCount
      ? null
      : { max: message };
  };
}
