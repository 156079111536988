<div class="pop-up"
     (click)="$event.stopPropagation()">

  <div class="pop-up-content">
    <button (click)="onCloseBtn($event)"
            type="button"
            class="btn-close">
      <i class="icon-x-medium"></i>
    </button>

    <h5 class="title">Quick Note</h5>

    <div class="body">
      <ng-content></ng-content>
    </div>

    <div class="buttons">
      <button class="btn btn--secondary btn--white"
              (click)="onConfirm($event)">
        Got it
      </button>
    </div>
  </div>
</div>
