<p *ngIf="message"
   [innerHtml]="message"
   [tooltipClass]="'tooltip--square'"
   [iconClass]="'tooltip--view'"
   [textSize]="14"
   [callLink]="true"
   [blTooltip]="messageToolTip">
</p>

<button *ngIf="belowButton"
        class="link"
        type="button"
        (click)="(belowButton.fn)()">
  {{ belowButton.name }}
</button>

<a *ngIf="belowLink && !belowButton"
   [routerLink]="belowLink.src">
  {{ belowLink.name }}
</a>

<div class="btn-wrapper">
  <button *ngIf="canClose"
          matRipple
          type="button"
          class="btn--mobile"
          (click)="onClose.emit(id)">
    DISMISS
  </button>

  <a *ngIf="canCall"
     class="btn--mobile"
     matRipple
     href="tel:+{{ phoneNumber }}">
    CALL
  </a>
</div>
