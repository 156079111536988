import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { checkDownloadListByTokenAction } from '@core/store/actions/list-large-download.actions';
import { CoreState } from '@core/store/reducers';

import { PopUpService } from '@ui/pop-up/services/pop-up/pop-up.service';

import { MISSING_LIST_TOKEN_FOR_DOWNLOAD } from '@shared/constants/error-message';

@Component({
  selector: 'bl-list-large-download-page',
  templateUrl: './list-large-download-page.component.html',
  styleUrls: ['./list-large-download-page.component.scss']
})
export class ListLargeDownloadPageComponent implements OnInit {

  errorMessage: string;

  constructor(private popUpService: PopUpService,
              private aRoute: ActivatedRoute,
              private store: Store<CoreState>) {
  }

  ngOnInit(): void {
    const token: string = this.aRoute.snapshot.queryParams['token'];

    if (token) {
      this.store.dispatch(checkDownloadListByTokenAction({ token }));
    } else {
      this.errorMessage = MISSING_LIST_TOKEN_FOR_DOWNLOAD;
    }
  }

}
