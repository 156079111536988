import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
// store
import { select, Store } from '@ngrx/store';

import { timer, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { checkAddressAction } from '../../../auth/store/actions/auth.action';
import { getSignUpAddressError } from '../../../auth/store/selectors/auth.selector';
import { getStatesByCountryAction } from '../../../countries/store/actions/countries.action';
import {
  getCountriesAsControlOptions,
  getCountryStatesAsControlOptions
} from '../../../countries/store/selectors/countries.selector';
import {
  changePasswordAction,
  resetErrorsAction,
  resetPasswordStateAction,
  updateProfileAction
} from '../../store/actions/profile.action';
import {
  getChangePasswordError,
  getChangePasswordLoading,
  getChangePasswordSuccessMessage,
  getProfileUpdateSuccessMessage,
  getProfileUpdating,
  getRoleKey,
  getTransformedUser,
  getUpdatingProfileError
} from '../../store/selectors/profile.selector';

import { AccountInfoService } from '../../services/account-info.service';

import { autoscroll } from '@shared/utils/autoscroll';

import { IControlOptions } from '@shared/interfaces/forms';
import { AddressError, IServerError } from '@shared/interfaces/server-error';
// interfaces
import { IChangePasswordData } from '../../../auth/interfaces/formsActionsData';
import { IUser, IUserTransformed } from '../../../auth/interfaces/user';
import { ICountryCode } from '../../../countries/interfaces';

import { FLAT_INPUT_THEME } from '@shared/constants/flat-input';
import { SHOW_MESSAGE_TIME } from '../../constants/profile-details';

// utils

@Component({
  selector: 'bl-profile-details-container',
  templateUrl: './profile-details-container.component.html',
  styleUrls: ['./profile-details-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProfileDetailsContainerComponent implements OnInit, OnDestroy {
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;
  readonly showMessageTime: number = SHOW_MESSAGE_TIME;

  private _destroyer$: Subject<void> = new Subject();

  user$: Observable<IUserTransformed> = this._store.pipe(select(getTransformedUser));
  roleKey$: Observable<string> = this._store.pipe(select(getRoleKey));
  loading$: Observable<boolean> = this._store.pipe(select(getProfileUpdating));
  updateSuccessMessage$: Observable<string> = this._store.pipe(select(getProfileUpdateSuccessMessage));
  serverError$: Observable<IServerError> = this._store.pipe(select(getUpdatingProfileError));
  changePasswordSuccessMessage$: Observable<string> = this._store.pipe(select(getChangePasswordSuccessMessage));
  changePasswordError$: Observable<IServerError> = this._store.pipe(select(getChangePasswordError));
  changePasswordLoading$: Observable<boolean> = this._store.pipe(select(getChangePasswordLoading));
  serverAddressError$: Observable<AddressError> = this._store.pipe(select(getSignUpAddressError));
  countriesOptions$: Observable<IControlOptions> = this._store.pipe(select(getCountriesAsControlOptions));
  countryStatesOptions$: Observable<IControlOptions> = this._store.pipe(select(getCountryStatesAsControlOptions));

  form: FormGroup;
  shouldShowPasswordForm: boolean;
  shouldShowSuccessMessage: boolean;
  shouldShowUpdateSuccessMessage: boolean;

  constructor(private _store: Store<CoreState>,
              private _changeDetectorRef: ChangeDetectorRef,
              private _accountInfoService: AccountInfoService) {
  }

  ngOnInit(): void {
    this.form = this._accountInfoService.createPasswordForm();
    this.subscribeToStore();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();

    this._store.dispatch(resetErrorsAction());
  }

  submitProfile(formData: IUser): void {
    const { addressFirst, addressSecond, city, stateCode, countryCode, postalCode }: IUser = formData;

    this._store.dispatch(checkAddressAction({
      addressValidateData: { addressFirst, addressSecond, city, stateCode, countryCode, postalCode },
      successAction: updateProfileAction(formData)
    }));
  }

  changePassword(): void {
    if (!this.form.valid) {
      return;
    }

    this._store.dispatch(changePasswordAction(this.form.value as IChangePasswordData));
  }

  closeChangePasswordForm(): void {
    this.shouldShowPasswordForm = false;
    this.form.reset();
  }

  openPasswordForm(): void {
    this.shouldShowPasswordForm = true;
    this._changeDetectorRef.markForCheck();
    this._store.dispatch(resetPasswordStateAction());
  }

  private subscribeToStore(): void {
    this.changePasswordSuccessMessage$
      .pipe(
        takeUntil(this._destroyer$),
        skip(1),
        distinctUntilChanged(),
        filter((message: string) => !!message),
        tap(() => {
          this.shouldShowSuccessMessage = true;
          this.shouldShowPasswordForm = false;
          this._changeDetectorRef.markForCheck();
        }),
        switchMap(() => timer(this.showMessageTime))
      )
      .subscribe(() => {
        this.shouldShowSuccessMessage = false;
        this._changeDetectorRef.markForCheck();
      });

    this.updateSuccessMessage$
      .pipe(
        takeUntil(this._destroyer$),
        skip(1),
        distinctUntilChanged(),
        filter((message: string) => !!message),
        tap(() => {
          this.shouldShowUpdateSuccessMessage = true;
          autoscroll();
          this._changeDetectorRef.markForCheck();
        }),
        switchMap(() => timer(this.showMessageTime))
      )
      .subscribe(() => {
        this.shouldShowUpdateSuccessMessage = false;
        this._changeDetectorRef.markForCheck();
      });
  }

  onCountryChange(countryCode: ICountryCode): void {
    this._store.dispatch(getStatesByCountryAction(countryCode));
  }

}
