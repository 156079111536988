import { GeoEntitiesTypes } from '@modules/segment/constants/geography';
import { INSTITUTION_TYPE_NAMES } from '../constants/jobs';

import { ICoordinates } from './geography';

export interface IBrowseEntity {
  id: number;
  name: INSTITUTION_TYPE_NAMES | string;
  disabled?: boolean;
  selected?: boolean;
  description?: string;
  location?: ICoordinates;
  geographyId?: number;
  jobFunctionId?: number;
  historicAlphaCode?: string;
  bidCategoryId?: number;
  entityType?: ENTITY_TYPE;
  isSelectedAll?: boolean;
}

export enum ENTITY_TYPE {
  GROUP = 1,
  CATEGORY
}

export enum SEGMENT_BY_PERSONNEL_ENTITY_TYPE {
  DISCIPLINE = 1,
  SUBJECT = 2,
  CATEGORY = 3,
  JOB = 4,
}

export type IBrowseEntities = IBrowseEntity[];

export interface ILoadBrowseItemPayload {
  segmentCriteriaId: number;
  elementId?: string;
  reset?: boolean;
}

export interface ILoadBrowseItemSuccessPayload {
  data: IBrowseEntities;
}

export interface ILoadBrowseItemByInstitutionPayload extends ILoadBrowseItemPayload {
  institutionType: INSTITUTION_TYPE_NAMES | string;
}

export interface ILoadBrowseItemByCategoryPayload extends ILoadBrowseItemPayload {
  categoryId: number;
}

export interface ILoadBrowseItemByDisciplinePayload extends ILoadBrowseItemPayload {
  disciplineId: number;
}

export interface ILoadBrowseItemBySubjectPayload extends ILoadBrowseItemPayload {
  subjectId: number;
}

export interface ILoadBrowseItemByTypePayload extends ILoadBrowseItemPayload {
  type?: number;
}

export interface ILoadBrowseItemByStateIdPayload extends ILoadBrowseItemPayload {
  stateId: number;
  query?: string;
}

export interface ILoadBrowseItemByCityIdPayload extends ILoadBrowseItemPayload {
  cityId: number;
}

export interface ILoadBrowseItemByInstitutionSuccessPayload extends ILoadBrowseItemSuccessPayload {
  requestParams: ILoadBrowseItemByInstitutionPayload;
}

export interface ILoadBrowseItemByCategorySuccessPayload extends ILoadBrowseItemSuccessPayload {
  requestParams: ILoadBrowseItemByCategoryPayload;
}

export interface ILoadBrowseItemByDisciplineSuccessPayload extends ILoadBrowseItemSuccessPayload {
  requestParams: ILoadBrowseItemByDisciplinePayload;
}

export interface ILoadBrowseItemBySubjectSuccessPayload extends ILoadBrowseItemSuccessPayload {
  requestParams: ILoadBrowseItemBySubjectPayload;
}

export interface IBrowseReloadData {
  openedType: GeoEntitiesTypes;
  currentType: GeoEntitiesTypes;
  openedStateId: number;
  openedCityTitleName: string;
  openedCityForZipCodesId: number;
}
