<bl-server-errors [error]="serverError?.message"></bl-server-errors>

<form [formGroup]="form" (ngSubmit)="submit()" class="form panel">

  <div class="input-wrapper">
    <bl-flat-input
      (submit)="submit()"
      formControlName="email"
      [errors]="form.get('email').errors"
      type="email"
      placeholder="Email*"></bl-flat-input>
  </div>

  <div class="form-footer">
    <button type="submit"
            [blBtnSpinner]="loading"
            [disabled]="form.invalid"
            class="btn btn--primary btn--lg">
      Reset Your Password
    </button>
  </div>
</form>
