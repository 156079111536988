<div class="row flex-column justify-content-between profile-settings"
     *ngFor="let setting of settings; trackBy: trackById">

  <span class="name">{{ setting?.name }}</span>
  <span class="description">{{ setting?.description }}</span>

  <bl-custom-checkbox-switch [checked]="setting?.value"
                             [pending]="pending"
                             (change)="onChangeSetting($event, setting?.id)">
  </bl-custom-checkbox-switch>

</div>
