import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from '@core/core.module';
import { NavModule } from '@modules/nav/nav.module';
import { UtilsPipesModule } from '@shared/modules/pipes/utils-pipes/utils-pipes.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { ViewPointsModule } from '@ui/view-points/view-points.module';

import { LayoutService } from '@core/services/layout.service';
import { AppService } from './app.service';

import { AppComponent } from './app.component';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    ViewPointsModule,
    BrowserAnimationsModule,
    NavModule,
    TooltipModule,
    SpinnerModule,
    UtilsPipesModule
  ],
  providers: [
    AppService,
    LayoutService, {
      provide: APP_BASE_HREF,
      useValue: './'
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
