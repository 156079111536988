import { Component, Inject } from '@angular/core';

import { ICloudSyncPlatform } from '@core/interfaces/cloud-sync';
import { ICloudSyncAssignProductPopUpData } from '../../interfaces';

import { PopUpRef } from '../../models/pop-up-ref';

import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-cloud-sync-assign-product-pop-up',
  templateUrl: './cloud-sync-assign-product-pop-up.component.html',
  styleUrls: ['./cloud-sync-assign-product-pop-up.component.scss']
})
export class CloudSyncAssignProductPopUpComponent {
  private selectedPlatform: ICloudSyncPlatform;

  isSelectPlatformBtnDisabled: boolean = true;

  constructor(private ref: PopUpRef,
              @Inject(POP_UP_DATA) public data: ICloudSyncAssignProductPopUpData) {
  }

  onCloseClick(): void {
    this.ref.close();
  }

  onPlatformSelect(platform: ICloudSyncPlatform): void {
    this.selectedPlatform = platform;
    this.isSelectPlatformBtnDisabled = false;
  }

  onSelectPlatformClick(): void {
    this.ref.close(this.selectedPlatform);
  }
}
