<!-- table -->
<bl-table [data]="users"
          [searchBy]="tableSearch"
          [searchableKeys]="searchableKeys"
          [computedProperties]="computedProperties"
          [headers]="accountUsersTableHeaders"
          [sortDirection]="sortDirection"
          [rowTemplate]="rowTemplate"
          class="table account-users">
</bl-table><!-- / table -->

<!-- table row template -->
<ng-template #rowTemplate let-user="row">

  <tr class="table-row"
      (click)="editUser(user)">

    <td class="user">
      <span *ngIf="user?.status === 0 || user?.isInvited"
            class="user-invite-sent">
        (Invite sent)
      </span>
      <span *ngIf="user?.status !== 0 && !user?.isInvited"
            class="user-name nowrap">
        {{ user.firstName + ' ' + user.lastName | lineSlice: 16 }}
      </span>&nbsp;
    </td>

    <td class="table-td email">{{ user?.email }}</td>

    <td class="table-td">
      <span *ngFor="let seat of user?.seats">
        <span>{{ seatStatePro === seat.id ? seat?.stateName : seat?.name }}</span><br/>
      </span>
    </td>

    <td class="table-td align-center">
      <ng-container *ngFor="let seat of user?.seats; trackBy: trackByFn">
        <i *ngIf="isCheckedAddOn(user?.addOnsGrants, seat.stateId)"
           class="icon-checked">
        </i>
        <br/>
      </ng-container>
    </td>

    <td class="table-td align-center">
      <ng-container *ngFor="let seat of user?.seats; trackBy: trackByFn">
        <i *ngIf="isCheckedAddOn(user?.addOnsBids, seat.stateId)"
           class="icon-checked">
        </i>
        <br/>
      </ng-container>
    </td>

    <td class="align-center">
      <i class="icon-checked"
         *ngIf="user.role.key === 'account-admin'">
      </i>
      <span *ngIf="user.role.key === 'account-owner'"
            class="user-owner">
        {{ user.role.name }}
      </span>
    </td>
  </tr>

</ng-template><!-- / table row template -->
