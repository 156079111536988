import { SafeHtml } from '@angular/platform-browser';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { CoreState } from '../reducers';
import { pageData, IBrokersTOUSTate } from '../reducers/brokers-tou.reducer';

import { ITermsOfUsePageData } from '@core/interfaces/terms-of-servise';

export const getBrokersTermsOfUseState: GetFromStateInSelectors<CoreState, IBrokersTOUSTate> = (state: CoreState): IBrokersTOUSTate =>
  state.brokersTermOfUse;

export const getBrokersTermsOfUseData: MemoizedSelector<CoreState, ITermsOfUsePageData<SafeHtml>> = createSelector(
  getBrokersTermsOfUseState, pageData);
