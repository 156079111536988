import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import * as creditsActions from '../store/actions/credits.action';

import { CreditsService } from '../services/credits.service';

import { IServerError } from '@shared/interfaces/server-error';
import { ICreditsResponse } from '../../auth/interfaces/user';

@Injectable()
export class CreditsGuard implements CanActivate {

  constructor(private store: Store<CoreState>,
              private service: CreditsService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.service.getCredits()
      .pipe(
        map((credits: ICreditsResponse) => {
          this.store.dispatch(creditsActions.getCreditsSuccessAction(credits));
          return true;
        }),
        catchError((error: IServerError) => {
          this.store.dispatch(creditsActions.getCreditsErrorAction(error));
          return throwError(error);
        })
      );
  }

}
