export class PopUpConfig<D = any> {
  constructor(
    public data?: D,
    public showCloseBtn: boolean = true,
    public timer: number = null,
    public closeOnOverlay: boolean = false,
    public withOverlayBackground: boolean = true,
    public isVerticalCentered: boolean = false
  ) {
  }
}
