import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import { RouterStateUrl } from './reducers/router.reducer';

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url }: RouterStateSnapshot = routerState;
    const { queryParams }: Params = routerState.root;

    let lastRoute: ActivatedRouteSnapshot = routerState.root;
    let { params, data }: ActivatedRouteSnapshot = lastRoute;

    while (lastRoute.firstChild) {
      lastRoute = lastRoute.firstChild;
      params = { ...params, ...lastRoute.params };
      data = { ...data, ...lastRoute.data };
    }

    return { url, params, queryParams, data };
  }
}
