<div (click)="$event.stopPropagation()"
     class="wrapper">

  <div [attr.data-testid]="'slider' + (e2eLabel | e2e)"
       #slider
       [blTooltip]="tooltipText"
       [dismissable]="true"
       [position]="'top'"
       [closeDelay]="2000"
       [tooltipClass]="'tooltip--slider'"
       (onDismiss)="dismissTooltip()"
       class="slider-container">

    <div class="area unselected" [class.premium]="isPremium && premiumActiveState"></div>

    <div #selectedContainer class="selected-container">
      <div #selectedArea class="area selected" [class.premium]="isPremium && premiumActiveState"></div>
    </div>

    <div #minBar class="bar-group min" e2e="slider-min">
      <div *ngIf="type"
           [class.active]="activeBar === 'min'"
           [class.premium]="isPremium && premiumActiveState"
           class="bar">
      </div>
    </div>

    <span #labelMin
          class="label"
          [hidden]="hideMinLabel"
          [class.standalone]="!type">

      <span *ngIf="!functionalMax">{{ minLabel }}</span>
      <span *ngIf="!canEditMinValue && functionalMax"
            class="label-input label-input--min disabled">
        {{ currentMin }}
      </span>

      <input #minInput
             *ngIf="functionalMax && canEditMinValue"
             type="text"
             class="label-input label-input--min"
             [disabled]="disable"
             (input)="handleInputsChange($event)"
             (keyup.enter)="setValueDirectly($event, 'min')"
             (blur)="setValueDirectly($event, 'min')"
             [value]="minInput === document.nativeElement.activeElement ? currentMin : minLabel">
    </span>

    <div #maxBar class="bar-group max" e2e="slider-max">
      <div [class.active]="activeBar === 'max'"
           [class.premium]="isPremium && premiumActiveState"
           class="bar"></div>
    </div>

    <span #labelMax
          class="label">
      <span *ngIf="!functionalMax">{{ maxLabel }}</span>
      <input #maxInput
             *ngIf="functionalMax"
             type="text"
             class="label-input label-input--max"
             [disabled]="disable"
             (input)="handleInputsChange($event)"
             (keyup.enter)="setValueDirectly($event, 'max')"
             (blur)="setValueDirectly($event, 'max')"
             [value]="maxInput === document.nativeElement.activeElement ? currentMax : maxLabel">
    </span>
  </div>
</div>
