<button type="button"
        class="circle-btn"
        (click)="changeOrder()"
        *ngIf="order">
  <i class="icon-arrow--{{ order.toLowerCase() }}"></i>
</button>


<bl-drop-down class="sort"
              [zIndex]="3"
              bodyPosition="center"
              #dropDownRef>

  <ng-template blDropDownTarget>
    <ng-container [ngTemplateOutlet]="currentItemTemplate || defaultCurrentTemplateRef"
                  [ngTemplateOutletContext]="{ currentItem: currentItemTitle }">
    </ng-container>
    <i class="icon-current icon-arrow-subcontainer--{{ dropDownRef.isOpen ? 'up' : 'down' }}"></i>
  </ng-template>

  <ng-template blDropDownContent>
    <div (click)="onClose()">
      <ng-container *ngFor="let item of items"
                    [ngTemplateOutlet]="iItemTemplate || defaultItemTemplateRef"
                    [ngTemplateOutletContext]="{ item: item }">
      </ng-container>
    </div>
  </ng-template>

</bl-drop-down>

<!-- default templates -->
<ng-template blDropDownTarget
             #defaultCurrentTemplateRef
             let-currentItem="currentItem">
  <span class="default-item">{{ currentItem }}</span>
</ng-template>

<ng-template #defaultItemTemplateRef
             let-item="item">
  {{ item }}
</ng-template>

