import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

import { ISignUpFormData } from '@modules/auth/interfaces/formsActionsData';
import { INewUser } from '@modules/auth/interfaces/user';
import { IServerError } from '@shared/interfaces/server-error';

import { CORE_PATHS } from '@core/constants/core-paths';
import { FLAT_INPUT_THEME } from '@shared/constants/flat-input';
import { PASSWORD_TIP } from '@shared/constants/tooltips/tooltips-text';

@Component({
  selector: 'bl-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormComponent implements OnChanges {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;

  @ViewChild('signUpBtn') signUpBtn: ElementRef;

  @Input() isReCaptcha: boolean = false;
  @Input() form: FormGroup;
  @Input() loading: boolean = false;
  @Input() serverError: IServerError;
  @Input() isShowFooter: boolean = true;
  @Input() theme: FLAT_INPUT_THEME;
  @Input() isShowTitle: boolean = true;

  @Output() onSubmit: EventEmitter<ISignUpFormData> = new EventEmitter();

  remember: boolean = false;
  passwordTip: string = PASSWORD_TIP;

  ngOnChanges(changes: SimpleChanges): void {
    const errors: SimpleChange = changes.serverError;

    if (errors && errors.currentValue) {
      Object
        .entries(this.serverError.errors)
        .forEach(([fieldName, message]: [string, string[]]) => {
          const control: AbstractControl = this.form.get(fieldName);

          if (control) {
            control.setErrors(message as ValidationErrors);
          }
        });

      this._resetFieldRecaptcha();
    }
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    const newUser: INewUser = { ...this.form.value as INewUser };

    delete newUser.confirmPassword;
    this.onSubmit.emit({ newUser, remember: this.remember });

    // todo: REMOVE IT IF CLIENT DECIDE TO DELETE PIXEL
    // if (environment.envName === 'prod') {
    //   this.matchPixelService.addMathPixelScript({
    //     element: this.signUpBtn,
    //     url: environment.mathPixelSignUpButton
    //   });
    // }
  }

  private _resetFieldRecaptcha(): void {
    this.form.patchValue({ gRecaptchaResponse: '' });
  }
}
