import { Params } from '@angular/router';
import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import * as actions from '@core/store/actions/list-create-from-view.action';
import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';

import { fullCurrentTime } from '@shared/utils/converters';

import { IListFromViewPayload } from '@modules/list-create/interfaces/list-create';
import { IServerError } from '@shared/interfaces/server-error';


export interface IListCreateFromViewState {
  creating: boolean;
  creatingError: IServerError | null;
}

const initialState: IListCreateFromViewState = {
  creating: false,
  creatingError: null
};

const createListFromViewHandler: OnReducer<IListCreateFromViewState, ActionType<any>> = (state: IListCreateFromViewState) => ({
  ...state,
  creating: true,
  creatingError: null
});

const createListFromViewSuccessHandler: OnReducer<IListCreateFromViewState, ActionType<any>> = (state: IListCreateFromViewState) => ({
  ...state,
  creating: false,
  creatingError: null
});

const createListFromViewErrorHandler: OnReducer<IListCreateFromViewState, ActionType<Payload<any>>> = (state: IListCreateFromViewState, { payload }: Payload<any>) => ({
  ...state,
  creating: false,
  creatingError: { ...payload }
});

const resetCreateListFromView: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<IListCreateFromViewState> = createReducer(
  initialState,

  on(actions.listCreateFromViewAction, createListFromViewHandler),
  on(actions.createListFromViewSuccessAction, createListFromViewSuccessHandler),
  on(actions.createListFromViewErrorAction, createListFromViewErrorHandler),

  on(actions.resetCreateListFromViewAction, resetCreateListFromView)
);

export function listCreateFromViewReducer(state: IListCreateFromViewState,
                                          action: Action): IListCreateFromViewState {
  return reducer(state, action);
}

export const creating: GetFromState<boolean, IListCreateFromViewState> = (state: IListCreateFromViewState): boolean => state && state.creating;
export const creatingError: GetFromState<IServerError | null, IListCreateFromViewState> = (state: IListCreateFromViewState): IServerError | null => state && state.creatingError;

export const selectedListName: GetFromState<string, Params> = ({ name }: Params): string => name
  ? `${ name.slice(0, 55) } ${ fullCurrentTime(new Date()) }`
  : '';
export const selectedRecordsType: GetFromState<number, Params> = ({ recordsType }: Params): number => recordsType ? +recordsType : null;
export const selectedViewId: GetFromState<number, Params> = ({ viewId }: Params): number => viewId ? +viewId : null;
export const selectedFilterBy: GetFromState<string, Params> = ({ filterBy }: Params): string => filterBy || null;

export const createListParams: GetFromState<IListFromViewPayload, string, number, string> = (name: string, viewId: number, filterBy: string): IListFromViewPayload => {
  const params: Params = {
    name,
    viewId,
    filterBy
  };

  return Object
    .entries(params)
    .reduce((_params: any, [key, value]: any) => (value ? { ..._params, [key]: value } : _params), {}) as IListFromViewPayload;
};

