import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { IServerMessages } from '@shared/interfaces/server-error';

import { CORE_PATHS } from '@core/constants/core-paths';
import { AUTH_PATHS } from '@modules/auth/constants/auth-paths';

@Component({
  selector: 'bl-e-commerce-sign-in-form',
  templateUrl: './e-commerce-sign-in-form.component.html',
  styleUrls: ['./e-commerce-sign-in-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ECommerceSignInFormComponent implements OnChanges {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly authPaths: typeof AUTH_PATHS = AUTH_PATHS;

  @Input() formGroup: FormGroup;
  @Input() serverError: IServerMessages;

  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();

  get credentials(): FormGroup {
    return this.formGroup.controls['credentials'] as FormGroup;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errors: any = changes.serverError && changes.serverError.currentValue;
    if (errors) {
      Object.entries(errors).forEach(([fieldName, message]: [string, string[]]) => {
        const control: AbstractControl = this.credentials.get(fieldName);
        if (control) {
          control.setErrors(message);
        }
      });
    }
  }

  handleSubmit(event: string): void {
    this.onSubmit.emit(event);
  }
}
