import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CoreState } from '@core/store/reducers';
import { resetProfileSettingsAction, updateProfileSettingsAction } from '../../store/actions/profile.action';
import { getProfileSettings, getUpdatingProfileSetting } from '../../store/selectors/profile.selector';

import { IProfileSetting, IProfileSettings } from '../../interfaces/profile-settings';


@Component({
  selector: 'bl-profile-settings-page',
  templateUrl: './profile-settings-page.component.html',
  styleUrls: ['./profile-settings-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileSettingsPageComponent implements OnDestroy {

  settings$: Observable<IProfileSettings> = this._store.pipe(select(getProfileSettings));
  updatingSetting$: Observable<boolean> = this._store.pipe(select(getUpdatingProfileSetting));

  constructor(private _store: Store<CoreState>) {
  }

  ngOnDestroy(): void {
    this._store.dispatch(resetProfileSettingsAction());
  }

  changeSetting(setting: IProfileSetting): void {
    const { value, id }: IProfileSetting = setting;

    this._store.dispatch(updateProfileSettingsAction({ value, id }));
  }
}
