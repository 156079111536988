import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import {
  IChangeListRecordsType,
  IChangeListType,
  IDataListSummary,
  IListData,
  IListRename,
  IListRequest, ISummaryList
} from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';


enum ACTION_TYPES {
  GET_LIST = '[LIST]: Get list',
  GET_LIST_ERROR = '[LIST]: Get list error',
  GET_LIST_SUCCESS = '[LIST]: Get list success',

  DELETE_LIST = '[LIST]: Delete list',
  DELETE_LIST_ERROR = '[LIST]: Delete list error',
  DELETE_LIST_SUCCESS = '[LIST]: Delete list success',

  RENAME_LIST = '[LIST]: List rename list',
  RENAME_LIST_ERROR = '[LIST]: List rename list error',
  RENAME_LIST_SUCCESS = '[LIST]: List rename list success',
  RENAME_LIST_CANCEL = '[LIST]: List rename list cancel',

  CHANGE_LIST_TYPE = '[LIST]: Change list type',
  CHANGE_LIST_TYPE_ERROR = '[LIST]: Change list type error',
  CHANGE_LIST_TYPE_SUCCESS = '[LIST]: Change list type success',

  CHANGE_LIST_RECORDS_TYPE = '[LIST]: Change list records type',
  CHANGE_LIST_RECORDS_TYPE_ERROR = '[LIST]: Change list records type error',
  CHANGE_LIST_RECORDS_TYPE_SUCCESS = '[LIST]: Change list records type success',

  RELOAD_LIST = '[LIST]: Reload list',
  RELOAD_LIST_ERROR = '[LIST]: Reload list error',
  RELOAD_LIST_SUCCESS = '[LIST]: Reload list success',

  RELOAD_LIST_WITH_REDIRECT = '[LIST] Reload list with redirect to current segment',
  RELOAD_LIST_WITH_REDIRECT_SUCCESS = '[LIST] Reload list with redirect to current segment success',

  IS_SHOW_CALCULATIONS = '[LIST]: Is show calculations',

  CHANGES_START = '[LIST]: Changes start',
  CHANGES_END = '[LIST]: Changes end',

  UPDATE_LIST = '[LIST]: Update list state',

  START_UPDATE_LIST_INTERVAL = '[LIST] start update list interval',
  FINISH_UPDATE_LIST_INTERVAL = '[LIST] finish update list interval',

  CALCULATE_LIST = '[LIST] Calculate',
  CALCULATE_LIST_ERROR = '[LIST] Calculate Error',
  CALCULATE_LIST_SUCCESS = '[LIST] Calculate Success',

  SOCKET_LIST_COUNTS_REFRESHED = '[LIST]: Socket counts refreshed',

  RESET_STORE = '[LIST]: Reset list store'
}

export const getListAction: CreateActionType<ACTION_TYPES.GET_LIST, IListRequest> = createAction(ACTION_TYPES.GET_LIST, createPayload<IListRequest>());
export const getListErrorAction: CreateActionType<ACTION_TYPES.GET_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.GET_LIST_ERROR, createPayload<IServerError>());
export const getListSuccessAction: CreateActionType<ACTION_TYPES.GET_LIST_SUCCESS, IListData> = createAction(ACTION_TYPES.GET_LIST_SUCCESS, createPayload<IListData>());

export const deleteListAction: CreateActionType<ACTION_TYPES.DELETE_LIST, IListRequest> = createAction(ACTION_TYPES.DELETE_LIST, createPayload<IListRequest>());
export const deleteListErrorAction: CreateActionType<ACTION_TYPES.DELETE_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.DELETE_LIST_ERROR, createPayload<IServerError>());
export const deleteListSuccessAction: ActionCreator<ACTION_TYPES.DELETE_LIST_SUCCESS, () => TypedAction<ACTION_TYPES.DELETE_LIST_SUCCESS>> = createAction(ACTION_TYPES.DELETE_LIST_SUCCESS);

export const renameListAction: CreateActionType<ACTION_TYPES.RENAME_LIST, IListRename> = createAction(ACTION_TYPES.RENAME_LIST, createPayload<IListRename>());
export const renameListErrorAction: CreateActionType<ACTION_TYPES.RENAME_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.RENAME_LIST_ERROR, createPayload<IServerError>());
export const renameListSuccessAction: CreateActionType<ACTION_TYPES.RENAME_LIST_SUCCESS, IListData> = createAction(ACTION_TYPES.RENAME_LIST_SUCCESS, createPayload<IListData>());
export const renameListCancelAction: ActionCreator<ACTION_TYPES.RENAME_LIST_CANCEL, () => TypedAction<ACTION_TYPES.RENAME_LIST_CANCEL>> = createAction(ACTION_TYPES.RENAME_LIST_CANCEL);

export const changeListTypeAction: CreateActionType<ACTION_TYPES.CHANGE_LIST_TYPE, IChangeListType> = createAction(ACTION_TYPES.CHANGE_LIST_TYPE, createPayload<IChangeListType>());
export const changeListTypeErrorAction: CreateActionType<ACTION_TYPES.CHANGE_LIST_TYPE_ERROR, IServerError> = createAction(ACTION_TYPES.CHANGE_LIST_TYPE_ERROR, createPayload<IServerError>());
export const changeListTypeSuccessAction: CreateActionType<ACTION_TYPES.CHANGE_LIST_TYPE_SUCCESS, IListData> = createAction(ACTION_TYPES.CHANGE_LIST_TYPE_SUCCESS, createPayload<IListData>());

export const changeListRecordsTypeAction: CreateActionType<ACTION_TYPES.CHANGE_LIST_RECORDS_TYPE, IChangeListRecordsType> = createAction(ACTION_TYPES.CHANGE_LIST_RECORDS_TYPE, createPayload<IChangeListRecordsType>());
export const changeListRecordsTypeErrorAction: CreateActionType<ACTION_TYPES.CHANGE_LIST_RECORDS_TYPE_ERROR, IServerError> = createAction(ACTION_TYPES.CHANGE_LIST_RECORDS_TYPE_ERROR, createPayload<IServerError>());
export const changeListRecordsTypeSuccessAction: CreateActionType<ACTION_TYPES.CHANGE_LIST_RECORDS_TYPE_SUCCESS, IListData> = createAction(ACTION_TYPES.CHANGE_LIST_RECORDS_TYPE_SUCCESS, createPayload<IListData>());

export const reloadListAction: ActionCreator<ACTION_TYPES.RELOAD_LIST, () => TypedAction<ACTION_TYPES.RELOAD_LIST>> = createAction(ACTION_TYPES.RELOAD_LIST);
export const reloadListErrorAction: CreateActionType<ACTION_TYPES.RELOAD_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.RELOAD_LIST_ERROR, createPayload<IServerError>());
export const reloadListSuccessAction: CreateActionType<ACTION_TYPES.RELOAD_LIST_SUCCESS, IListData> = createAction(ACTION_TYPES.RELOAD_LIST_SUCCESS, createPayload<IListData>());

export const reloadListWithRedirectAction: ActionCreator<ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT, () => TypedAction<ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT>> = createAction(ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT);
export const reloadListWithRedirectSuccessAction: CreateActionType<ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_SUCCESS, IListData> = createAction(ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_SUCCESS, createPayload<IListData>());

export const isShowCalculations: CreateActionType<ACTION_TYPES.IS_SHOW_CALCULATIONS, boolean> = createAction(ACTION_TYPES.IS_SHOW_CALCULATIONS, createPayload<boolean>());

export const changesStartAction: ActionCreator<ACTION_TYPES.CHANGES_START, () => TypedAction<ACTION_TYPES.CHANGES_START>> = createAction(ACTION_TYPES.CHANGES_START);
export const changesEndAction: ActionCreator<ACTION_TYPES.CHANGES_END, () => TypedAction<ACTION_TYPES.CHANGES_END>> = createAction(ACTION_TYPES.CHANGES_END);
export const updateListStateAction: CreateActionType<ACTION_TYPES.UPDATE_LIST, Partial<IListData> | IListData | ISummaryList> = createAction(ACTION_TYPES.UPDATE_LIST, createPayload<Partial<IListData> | IListData | ISummaryList>());

export const startUpdateListIntervalAction: CreateActionType<ACTION_TYPES.START_UPDATE_LIST_INTERVAL, IDataListSummary> = createAction(ACTION_TYPES.START_UPDATE_LIST_INTERVAL, createPayload<IDataListSummary>());
export const finishUpdateListIntervalAction: ActionCreator<ACTION_TYPES.FINISH_UPDATE_LIST_INTERVAL, () => TypedAction<ACTION_TYPES.FINISH_UPDATE_LIST_INTERVAL>> = createAction(ACTION_TYPES.FINISH_UPDATE_LIST_INTERVAL);

export const calculateListAction: CreateActionType<ACTION_TYPES.CALCULATE_LIST, number> = createAction(ACTION_TYPES.CALCULATE_LIST, createPayload<number>());
export const calculateListErrorAction: CreateActionType<ACTION_TYPES.CALCULATE_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.CALCULATE_LIST_ERROR, createPayload<IServerError>());
export const calculateListSuccessAction: ActionCreator<ACTION_TYPES.CALCULATE_LIST_SUCCESS, () => TypedAction<ACTION_TYPES.CALCULATE_LIST_SUCCESS>> = createAction(ACTION_TYPES.CALCULATE_LIST_SUCCESS);

export const socketListCountsRefreshedAction: ActionCreator<ACTION_TYPES.SOCKET_LIST_COUNTS_REFRESHED, () => TypedAction<ACTION_TYPES.SOCKET_LIST_COUNTS_REFRESHED>> = createAction(ACTION_TYPES.SOCKET_LIST_COUNTS_REFRESHED);

export const resetListAction: ActionCreator<ACTION_TYPES.RESET_STORE, () => TypedAction<ACTION_TYPES.RESET_STORE>> = createAction(ACTION_TYPES.RESET_STORE);
