import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FiltersPipesModule } from '@shared/modules/pipes/filters-pipes/filters-pipes.module';
import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { CustomScrollsModule } from '../custom-scrolls/custom-scrolls.module';
import { MobileTableModule } from './mobile-table/mobile-table.module';

import { TableComponent } from './components/table/table.component';

const imports: any[] = [
  CommonModule,
  MobileTableModule,
  FiltersPipesModule,
  CustomControlsModule,
  CustomScrollsModule
];

const declarations: any[] = [
  TableComponent,
];

const exports: any[] = [
  MobileTableModule,
  ...declarations
];

@NgModule({
  imports,
  declarations,
  exports
})
export class TablesModule {
}
