<div class="row">
  <div class="col-md-12">

    <span class="table-name">USERS</span>
    <!-- table -->
    <bl-table *blHidePoint="[windowPoints.PHONE, windowPoints.TABLET]"
              [data]="accountUsers$ | async"
              [headers]="tableHeaders"
              [rowTemplate]="rowTemplate"
              [computedProperties]="computedProperties"
              class="table account-users"
              data-testid="users-table">
    </bl-table>


    <div *blHidePoint="[windowPoints.DESKTOP, windowPoints.DESKTOP_LARGE]"
         class="mobile-content">
      <bl-mobile-table [data]="accountUsers$ | async"
                       [rowTemplate]="mobileRow"
                       theme="account-users">
      </bl-mobile-table>
    </div>
  </div>
</div>

<!-- table row template -->
<ng-template #rowTemplate let-user="row">
  <tr class="table-row">

    <td class="user">
      <span *ngIf="user?.status === usersStatuses.NOT_EXIST_USER || user?.isInvited"
            class="user-invite-sent">
        (Invite sent)
      </span>
      <span *ngIf="user?.status !== usersStatuses.NOT_EXIST_USER && !user?.isInvited"
            class="user-name nowrap">
        {{ user.firstName + ' ' + user.lastName | lineSlice: 16 }}
      </span>&nbsp;
    </td>

    <td class="table-td email">{{ user?.email }}</td>

    <td class="table-td nowrap">
      <span *ngFor="let seat of user.seats">
        <span>{{ seatStatePro === seat.id ? seat?.stateName : seat?.name }}</span><br/>
      </span>
    </td>

    <td class="table-td align-center">
      <ng-container *ngFor="let seat of user?.seats; trackBy: trackByFn">
        <i *ngIf="isCheckedAddOn(user?.addOnsGrants, seat.stateId)"
           class="icon-checked">
        </i>
        <br/>
      </ng-container>
    </td>

    <td class="table-td align-center">
      <ng-container *ngFor="let seat of user?.seats; trackBy: trackByFn">
        <i *ngIf="isCheckedAddOn(user?.addOnsBids, seat.stateId)"
           class="icon-checked">
        </i>
        <br/>
      </ng-container>
    </td>

    <td class="align-center">
      <i class="icon-checked"
         *ngIf="user.role.key === rolesKeys.Admin">
      </i>
    </td>
  </tr>
</ng-template><!-- / table row template -->

<ng-template #mobileRow
             let-user="row">

  <div class="mobile-row">

    <p *ngIf="user?.status === usersStatuses.NOT_EXIST_USER || user?.isInvited"
       class="user-invite-sent">
      (Invite sent)
    </p>

    <p *ngIf="user?.status !== usersStatuses.NOT_EXIST_USER && !user?.isInvited"
       class="user-name nowrap">
      {{ user.firstName + ' ' + user.lastName | lineSlice: 16 }}

      <span *ngIf="user?.role?.key === rolesKeys.Admin"
            class="user-role">
        ({{ user.role.name }})
      </span>
    </p>

    <p class="user-email">{{ user?.email }}</p>
  </div>

</ng-template>
