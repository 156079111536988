export enum LOCAL_STORAGE_FIELDS {
  token = 'token',
  id = 'id',
  remember = 'remember',
  firstLogin = 'first-login',
  user = 'user-profile',
  isAdmin = 'is-admin',
  isBroker = 'is-broker',
  isBrokerLicenseAccepted = 'is-broker-license-accepted',
  isAdminsAccount = 'is-admin-account',
  isUnlimited = 'is-unlimited',
  credits = 'credits',
  popUpNotifications = 'pop-up-notifications',
  doNotShowDeleteSegmentPopUp = 'do-not-show-delete-segment-pop-up',
  doNotShowClearTargetingPopUp = 'do-not-show-clear-targeting-pop-up',
  doNotShowDeleteListPopUp = 'do-not-show-delete-list-pop-up',
  doNotShowDeleteCustomListPopUp = 'do-not-show-delete-custom-list-pop-up',
  doNotShowDeleteCDLListPopUp = 'do-not-show-delete-cdl-list-pop-up',
  doNotShowRefreshListPopUp = 'do-not-show-refresh-list-pop-up',
  doNotShowConfirmListPopUp = 'do-not-show-confirm-list-pop-up',
  doNotShowConfirmPricingCardPopUp = 'do-not-show-confirm-pricing-card-pop-up',
  doNotShowSegmentByConfirmationPopUp = 'do-not-show-segment-by-confirmation-pop-up',
  doNotShowDisabledTooltips = 'do-not-show-disabled-tooltips',
  doNotShowDisabledCategoriesTooltips = 'do-not-show-disabled-categories-tooltips',
  doNotShowDetailsTargetingWarningMsg = 'do-not-show-details-targeting-warning-msg',
  doNotShowSliderTooltip = 'do-not-show-slider-tooltip',
  doNotShowWelcomePopUp = 'do-not-show-welcome-pop-up',
  doNotShowPersonnelDaycarePopUp = 'do-not-show-personnel-daycare-pop-up',
  doNotShowAssignStatePopUp = 'do-not-show-assign-state-pop-up',
  doNotShowAssignAddOnPopUp = 'do-not-show-assign-add-on-pop-up',
  doNotShowEditSelfPopUp = 'do-not-show-edit-self-pop-up',
  doNotShowWelcomeWithMarketViewPopUp = 'do-not-show-welcome-with-market-view-pop-up',
  doNotShowUnsupportedBrowserPopUp = 'do-not-show-unsupported-browser-pop-up',
  doNotShowPremiumCheckboxPopUp = 'do-not-show-premium-checkbox-pop-up',
  doNotShowPremiumRadioPopUp = 'do-not-show-premium-radio-pop-up',
  doNotShowPremiumRadioButtonPopUp = 'do-not-show-premium-radio-button-pop-up',
  doNotShowPremiumScrollerPopUp = 'do-not-show-premium-scroller-pop-up',
  doNotShowPremiumCustomDropDownPopUp = 'do-not-show-premium-custom-dropdown-pop-up',
  doNotShowMVRenewalPopUp = 'do-not-show-m-v-renewal-pop-up',
  doNotShowIndefinitelyMVRenewalPopUp = 'do-not-show-indefinitely-m-v-renewal-pop-up',
  doNotShowViewResultsTablePopUp = 'do-not-show-view-results-table-pop-up',
  doNotShowPersonnelEntityPopUp = 'do-not-show-personnel-entity-pop-up',
  doNotShowDepartmentPersonnelPopUp = 'do-not-show-department-personnel-pop-up',
  doNotShowCollegePersonnelPopUp = 'do-not-show-college-personnel-pop-up',
  doNotShowCollegeOfficePersonnelPopUp = 'do-not-show-college-office-personnel-pop-up',
  doNotShowCmoPersonnelPopUp = 'do-not-show-cmo-personnel-pop-up',
  doNotShowBidPersonnelPopUp = 'do-not-show-bid-personnel-pop-up',
  doNotCustomizeResetDataPopUp = 'do-not-show-customize-reset-data-pop-up',
  hasLists = 'user-has-lists',
  eCommerceCart = 'e-commerce-cart',
  doNotShowGeoSuggestTooltip = 'do-not-show-geo-suggest-tooltip',
  role = 'role',
  doNotShowDeleteViewPopUp = 'do-not-show-delete-view-pop-up',
  permissions = 'permissions',
  accountAvailablePermissions = 'account-available-permissions',
  refreshCreditsTooltipWasShowed = 'refresh-credits-tooltip-was-showed',
  trialSeatExpirationDate = 'trial-seat-expirationDate',
  cdlLists = 'cdlLists',
  isFirstAssignAvailable = 'is-first-assign-available',
  activeDashboardTab = 'active-dashboard-tab',
  activeCDLTab = 'activeCDLTab',

  parentInstitutionIncludeChildren = 'parent-institution-include-children',
  parentInstitutionIncludeChildrenAll = 'parent-institution-include-children-all',
  childInstitutionIncludeParents = 'child-institution-include-parents',
  childInstitutionIncludeParentsAll = 'child-institution-include-parents-all',
  removeParentPIDChildren = 'remove-parent-institution-children',
  removeParentPIDChildrenAll = 'remove-parent-institution-children-all',

  cloudSyncForProducts = 'cloud-sync-for-products'
}
