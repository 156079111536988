<bl-sticky-element>
  <bl-sub-header title="My account"></bl-sub-header>

  <div class="tabs-wrap">
    <bl-profile-tabs-container></bl-profile-tabs-container>
  </div>
</bl-sticky-element>

<div class="wrapper">
  <router-outlet></router-outlet>
</div>

<bl-assign-state-pop-up-container></bl-assign-state-pop-up-container>
