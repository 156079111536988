<bl-pop-up-base (close)="onClose()" data-testid="pop-up">
  <form [formGroup]="form"
        (ngSubmit)="onConfirm()">

    <div class="icon">
      <i [class]="data?.iconClass"></i>
    </div>

    <h5 class="title">{{ data?.title }}</h5>

    <div class="body">
      {{ data?.body }}

      <div class="select-wrapper">
        <bl-rounded-select formControlName="userId"
                           unselectedLabel="Select a user..."
                           [errors]="form.get('userId').errors"
                           [options]="usersControlOptions"
                           data-testid="select-user-drop-down"
                           class="theme--assign-user">
        </bl-rounded-select>
      </div>
    </div>

    <div class="buttons">
      <button class="btn btn--secondary btn--white"
              [disabled]="!data?.users.length"
              data-testid="assign-data-btn">
        {{ data.acceptText }}
      </button>
    </div>
  </form>
</bl-pop-up-base>
