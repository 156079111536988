<ul class="actions-list">

  <ng-container *ngFor="let item of platforms; trackBy: trackByFn; index as i;">

    <ng-template [ngIf]="item.connectionId">
      <li class="action-item"
          [class.border--bold]="platforms[i]?.connectionId && platforms[i + 1] && platforms[i + 1]?.connectionId === null">
        <bl-submenu>
          <ng-container class="submenu-target">
            <button class="action action--submenu"
                    type="button">

              <img [src]="item?.logoUrl"
                   [alt]="item?.logoUrl"
                   class="icon action__img mob-hidden">
              <span class="platform-name">{{ item.name }}</span>
              <i class="caret-icon icon-arrow-subcontainer--right"></i>
            </button>
          </ng-container>

          <ng-container class="submenu-content">

            <ul class="actions-list">
              <li class="action-item" matRipple>
                <button type="button"
                        class="action action--submenu"
                        (click)="selectPlatform(item, true)">
                  Sandbox
                </button>
              </li>
              <li class="action-item" matRipple>
                <button type="button"
                        class="action action--submenu"
                        (click)="selectPlatform(item, false)">
                  Production
                </button>
              </li>
            </ul>

          </ng-container>
        </bl-submenu>
      </li>
    </ng-template>

    <ng-template [ngIf]="!item.connectionId">
      <li class="action-item"
          matRipple
          [class.border--bold]="platforms[i]?.connectionId && platforms[i + 1] && platforms[i + 1]?.connectionId === null">
        <button class="action action--submenu"
                type="button"
                (click)="selectPlatform(item)">

          <img [src]="item?.logoUrl"
               [alt]="item?.logoUrl"
               class="icon action__img mob-hidden">
          <span class="platform-name">{{ item.name }}</span>
        </button>
      </li>
    </ng-template>

  </ng-container>

  <li *ngIf="isShowMoreBtn"
      class="action-item"
      matRipple>
    <button type="button"
            class="action action--submenu"
            (click)="openSyncToPopUp()">
      More...
    </button>
  </li>

</ul>
