<div class="overlay"
     [class.active]="shouldShowOverlay$ | async"
     (click)="$event.stopPropagation()">
</div>

<bl-spinner-container></bl-spinner-container>
<bl-tooltip-container></bl-tooltip-container>
<bl-download-link-container></bl-download-link-container>

<!-- SIDENAV CONTAINER -->
<bl-nav-content-container>
  <!-- SIDENAV MENU -->
  <div class="sidenav">
    <bl-nav-mobile-container></bl-nav-mobile-container>
  </div><!-- SIDENAV MENU -->

  <!-- SIDENAV CONTENT -->
  <div class="sidenav-content page-wrapper"
       [class.root-fix]="isRootElementFixed"
       (click)="emptyClick()">

    <div *ngIf="!(routesToHideLayout | blShouldHideDefaultLayout)"
         class="header-wrapper">
      <bl-header-container></bl-header-container>
    </div>

    <bl-notification-container></bl-notification-container>

    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>

    <div
      *ngIf="!(routesToHideLayout | blShouldHideDefaultLayout) && !(mobileFooterRoutes | blShouldShowMobileFooterPipe); else showMobileFooter"
      class="footer-wrapper">
      <bl-footer *blHidePoint="[windowPoints.PHONE, windowPoints.TABLET]"
                 [isBroker]="isBroker$ | async"
                 [isAdminsAccount]="isAdminsAccount$ | async"
                 [isLoggedIn]="isLoggedIn$ | async">
      </bl-footer>
    </div>

  </div><!-- / SIDENAV CONTENT -->
</bl-nav-content-container><!-- SIDENAV CONTAINER -->

<ng-template #showMobileFooter>
  <bl-footer [isBroker]="isBroker$ | async"
             [isAdminsAccount]="isAdminsAccount$ | async"
             [isLoggedIn]="isLoggedIn$ | async">
  </bl-footer>
</ng-template>
