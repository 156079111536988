import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'bl-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
  exportAs: 'tabItem',
  changeDetection: ChangeDetectionStrategy.Default
})
export class TabItemComponent {

  @Input() title: string;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() isActive: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() width: number = 160;
  @Input() height: number = 40;
  @Input() fontSize: number = 14;
}
