<div class="sticky-popup" #popupWrapperEl>
  <div class="header-wrapper" #headerWrapperEl
       [style.height]="headerHeight + 'px'">
    <ng-content select="[popupHeader]"></ng-content>
  </div>
  <div class="content-wrapper" #contentWrapperEl
       [style.padding-top]="headerHeight + 'px'"
       [style.padding-bottom]="footerHeight + 'px'">
    <div class="content-inner">
      <ng-content select="[popupContent]"></ng-content>
    </div>
  </div>
  <div class="footer-wrapper" #footerWrapperEl
       [style.height]="footerHeight + 'px'">
    <ng-content select="[popupFooter]"></ng-content>
  </div>
</div>
