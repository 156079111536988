import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import { isOpenAssignAddOnInfoPopUp, isOpenAssignStateInfoPopUp } from '../reducers/account-pop-ups.reducer';

import { selectAccountPopUpsState } from './index';


export const getIsOpenAssignStateInfoPopUp: MemoizedSelector<CoreState, boolean> = createSelector(selectAccountPopUpsState,
  isOpenAssignStateInfoPopUp);
export const getIsOpenAssignAddOnInfoPopUp: MemoizedSelector<CoreState, boolean> = createSelector(selectAccountPopUpsState,
  isOpenAssignAddOnInfoPopUp);
