<i class="icon-view-quick-search--blue"></i>

<p>
  To purchase additional<br/>&nbsp;MarketView products call us at<br/>
  <a class="link"
     href="tel:{{ phoneNumber }}" target="_blank">
    {{ phoneNumber | phone: 'brackets' }}
  </a>
</p>

<br/>

<p>
  To purchase credits online&nbsp;<br/>
  <a class="link"
     [routerLink]="['/', corePaths.ECOMM, eCommercePaths.PRICING]">
    click here
  </a>
</p>
