import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { environment } from '@env/environment';

import { ISnackBarData } from '../../interfaces';

@Component({
  selector: 'bl-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarComponent {
  readonly phoneNumber: string = environment.contactPhoneNumber;

  constructor(private snackBarRef: MatSnackBarRef<SnackBarComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData) {
  }

  close(): void {
    this.snackBarRef.dismiss();
  }

}
