<ul class="actions-list menu-content theme--{{ theme }}">

  <ng-container *blIfTosPage="true">
    <li class="action-item" matRipple>
      <a [routerLink]="['/', appPaths.ACCOUNT, profilePaths.PRODUCTS]"
         (click)="onRedirect.emit()"
         class="action">
        Products & Users
      </a>
    </li>
    <li *ngIf="!isOwnerOfAdminsAccount" class="action-item" matRipple>
      <a [routerLink]="['/', appPaths.ACCOUNT, profilePaths.DETAILS]"
         (click)="onRedirect.emit()"
         class="action">
        Account Info
      </a>
    </li>
    <li *ngIf="isShowBillingDetails"
        class="action-item" matRipple>
      <a [routerLink]="['/', appPaths.ACCOUNT, profilePaths.BILLING]"
         (click)="onRedirect.emit()"
         class="action">
        Billing Info
      </a>
    </li>
    <li *ngIf="!isOwnerOfAdminsAccount" class="action-item" matRipple>
      <a [routerLink]="['/', appPaths.ACCOUNT, profilePaths.SETTINGS]"
         (click)="onRedirect.emit()"
         class="action">
        Settings
      </a>
    </li>
  </ng-container>

  <li class="action-item" matRipple>
    <button type="button"
            (click)="logout.emit()"
            class="action">
      Logout
    </button>
  </li>
</ul>
