import { animate, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

export const fadeInOut: AnimationTriggerMetadata = trigger('fadeInOut',
  [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(200, style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate(100, style({ opacity: 0 }))
    ])
  ]
);

