import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getIsTosPage } from '@core/store/selectors/tos.selector';

@Directive({
  selector: '[blIfTosPage]'
})
export class IfTosPageDirective implements OnInit, OnDestroy {

  @Input() blIfTosPage: boolean; // reverse

  private _destroyer$: Subject<void> = new Subject();

  constructor(private _templateRef: TemplateRef<any>,
              private _viewContainerRef: ViewContainerRef,
              private _store: Store<CoreState>) {
  }

  ngOnInit(): void {
    this._store
      .pipe(
        select(getIsTosPage),
        takeUntil(this._destroyer$),
        tap(() => this._viewContainerRef.clear()),
        map((blIfTosPage: boolean) => blIfTosPage ? !this.blIfTosPage : this.blIfTosPage),
        filter((blIfTosPage: boolean) => blIfTosPage)
      )
      .subscribe(() => this._viewContainerRef.createEmbeddedView(this._templateRef));
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

}
