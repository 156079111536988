import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IsBooleanPipe } from './pipes/is-boolean.pipe';
import { IsDescriptionPipe } from './pipes/is-description.pipe';
import { IsHiddenPipe } from './pipes/is-hidden.pipe';
import { IsNullPipe } from './pipes/is-null.pipe';
import { SholudHideDefaultLayoutPipe } from './pipes/sholud-hide-default-layout.pipe';
import { ShouldShowMobileFooterPipePipe } from './pipes/should-show-mobile-footer.pipe';

const _pipes: any = [
  IsBooleanPipe,
  IsHiddenPipe,
  IsNullPipe,
  IsDescriptionPipe,
  ShouldShowMobileFooterPipePipe,
  SholudHideDefaultLayoutPipe
];

@NgModule({
  declarations: [
    ..._pipes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ..._pipes
  ]
})
export class UtilsPipesModule {
}
