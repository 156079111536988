import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { IInvitationUser, IInvitationUserResponse, IUpdateInvitationUser } from '../../interfaces/invitation';
import { ISuccessMessageResponse } from '../../interfaces/user';

enum ACTION_TYPES {
  SET_INVITATION_TOKEN = '[INVITATION] Set invitation token existed user',
  UPDATE_INVITATION_TOKEN = '[INVITATION] Update invitation token existed user',
  REMOVE_INVITATION_TOKEN = '[INVITATION] Remove invitation token existed user',

  INVITATION = '[INVITATION] Invitation',
  INVITATION_SUCCESS = '[INVITATION] Invitation success',
  INVITATION_ERROR = '[INVITATION] Invitation error',

  INVITATION_WITHOUT_NOTIFICATION = '[INVITATION] Invitation without notification',
  INVITATION_WITHOUT_NOTIFICATION_SUCCESS = '[INVITATION] Invitation without notification success',
  INVITATION_WITHOUT_NOTIFICATION_ERROR = '[INVITATION] Invitation without notification error',

  INVITATION_AND_SHOW_INFO_POP_UP = '[INVITATION] Invitation and show info pop-up',
  INVITATION_AND_SHOW_INFO_POP_UP_SUCCESS = '[INVITATION] Invitation and show info pop-up success',
  INVITATION_AND_SHOW_INFO_POP_UP_ERROR = '[INVITATION] Invitation and show info pop-up error',

  INVITATION_REDIRECT_WHEN_CAN_NOT_USE_APP = '[INVITATION] Invitation Redirect When Can Not Use App',

  RESET_INVITATION_STATE = '[INVITATION] Reset invitation state',
}

export const setInvitationTokenAction: CreateActionType<ACTION_TYPES.SET_INVITATION_TOKEN, IInvitationUser> = createAction(ACTION_TYPES.SET_INVITATION_TOKEN, createPayload<IInvitationUser>());
export const updateInvitationTokenAction: CreateActionType<ACTION_TYPES.UPDATE_INVITATION_TOKEN, IUpdateInvitationUser> = createAction(ACTION_TYPES.UPDATE_INVITATION_TOKEN, createPayload<IUpdateInvitationUser>());
export const removeInvitationTokenAction: ActionCreator<ACTION_TYPES.REMOVE_INVITATION_TOKEN, () => TypedAction<ACTION_TYPES.REMOVE_INVITATION_TOKEN>> = createAction(ACTION_TYPES.REMOVE_INVITATION_TOKEN);

export const invitationAction: CreateActionType<ACTION_TYPES.INVITATION, IInvitationUser> = createAction(ACTION_TYPES.INVITATION, createPayload<IInvitationUser>());
export const invitationSuccessAction: CreateActionType<ACTION_TYPES.INVITATION_SUCCESS, ISuccessMessageResponse> = createAction(ACTION_TYPES.INVITATION_SUCCESS, createPayload<ISuccessMessageResponse>());
export const invitationErrorAction: CreateActionType<ACTION_TYPES.INVITATION_ERROR, IServerError> = createAction(ACTION_TYPES.INVITATION_ERROR, createPayload<IServerError>());

export const invitationWithoutNotificationAction: ActionCreator<ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION, () => TypedAction<ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION>> = createAction(ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION);
export const invitationWithoutNotificationSuccessAction: ActionCreator<ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION_SUCCESS, () => TypedAction<ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION_SUCCESS>> = createAction(ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION_SUCCESS);
export const invitationWithoutNotificationErrorAction: CreateActionType<ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION_ERROR, IServerError> = createAction(ACTION_TYPES.INVITATION_WITHOUT_NOTIFICATION_ERROR,
  createPayload<IServerError>());

export const invitationAndShowInfoPopUpAction: CreateActionType<ACTION_TYPES.INVITATION_AND_SHOW_INFO_POP_UP, IInvitationUser> = createAction(ACTION_TYPES.INVITATION_AND_SHOW_INFO_POP_UP,
  createPayload<IInvitationUser>());
export const invitationAndShowInfoPopUpErrorAction: CreateActionType<ACTION_TYPES.INVITATION_AND_SHOW_INFO_POP_UP_ERROR, IServerError> = createAction(ACTION_TYPES.INVITATION_AND_SHOW_INFO_POP_UP_ERROR,
  createPayload<IServerError>());
export const invitationAndShowInfoPopUpSuccessAction: ActionCreator<ACTION_TYPES.INVITATION_AND_SHOW_INFO_POP_UP_SUCCESS, () => TypedAction<ACTION_TYPES.INVITATION_AND_SHOW_INFO_POP_UP_SUCCESS>> = createAction(ACTION_TYPES.INVITATION_AND_SHOW_INFO_POP_UP_SUCCESS);

export const invitationRedirectWhenCanNotUseAppAction: CreateActionType<ACTION_TYPES.INVITATION_REDIRECT_WHEN_CAN_NOT_USE_APP, IInvitationUserResponse> = createAction(ACTION_TYPES.INVITATION_REDIRECT_WHEN_CAN_NOT_USE_APP,
  createPayload<IInvitationUserResponse>());

export const resetInvitationStateAction: ActionCreator<ACTION_TYPES.RESET_INVITATION_STATE, () => TypedAction<ACTION_TYPES.RESET_INVITATION_STATE>> = createAction(ACTION_TYPES.RESET_INVITATION_STATE);
