import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { SuccessPopUpData } from '../../models/pop-up-data';
import { PopUpRef } from '../../models/pop-up-ref';

import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-success-pop-up-content',
  templateUrl: './success-pop-up-content.component.html',
  styleUrls: ['./success-pop-up-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessPopUpContentComponent {

  constructor(private popUpRef: PopUpRef<SuccessPopUpContentComponent>,
              @Inject(POP_UP_DATA) public data: SuccessPopUpData) {
  }

  onClose(): void {
    this.popUpRef.close();
  }
}
