import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { PhoneMaskPipe } from '../../../pipes/shared-pipes/pipes/phone-mask.pipe';
import { ZipCodeMaskPipe } from '../../../pipes/shared-pipes/pipes/zip-code-mask.pipe';

import { IControlOption } from '@shared/interfaces/forms';

import { FLAT_INPUT_THEME } from '@shared/constants/flat-input';

@Component({
  selector: 'bl-payment-address',
  templateUrl: './payment-address.component.html',
  styleUrls: ['./payment-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PaymentAddressComponent {
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  @ViewChild('selectWrapperEl') selectWrapperEl: ElementRef;

  @Input() formGroup: FormGroup;
  @Input() editable: boolean;
  @Input() countries: IControlOption[];
  @Input() countryStates: IControlOption[];

  @Output() countryChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSwitchAddressForm: EventEmitter<any> = new EventEmitter();

  zipCodeMask: ZipCodeMaskPipe = new ZipCodeMaskPipe();
  phoneMask: PhoneMaskPipe = new PhoneMaskPipe();

  get addressError(): string {
    return this.formGroup.errors && this.formGroup.errors['address'] || null;
  }

  get selectWidth(): number {
    if (!this.selectWrapperEl) {
      return 0;
    }
    return this.selectWrapperEl.nativeElement.clientWidth;
  }

  switchAddressForm(state: boolean): void {
    this.onSwitchAddressForm.emit(state);

    if (state) {
      this.formGroup.markAsUntouched();
    }

  }

  onSelectCountry(): void {
    this.countryChanged.emit();
  }
}
