import { Pipe, PipeTransform } from '@angular/core';

import { VALUES_FOR_UPPERCASE } from '@shared/modules/institution-profiles/interfaces/intitution-profiles';

@Pipe({
  name: 'uppercaseByValues'
})
export class UppercaseByValuesPipe implements PipeTransform {

  transform(value: string, valuesForCheck: string[] = VALUES_FOR_UPPERCASE): string {
    if (!value || typeof value !== 'string') {
      return '';
    }

    if (valuesForCheck.includes(value)) {
      return value.toUpperCase();
    }

    return value;
  }

}
