import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import {
  platformAuthAction,
  resetCloudSyncAuthPopUpAction,
  resetPlatformAuthError
} from '@core/store/actions/cloud-sync-auth.action';
import { CoreState } from '@core/store/reducers';
import { getPlatformAuthError, getPlatformAuthLoading } from '@core/store/selectors/cloud-sync-auth.selector';

import { CloudSyncAuthService } from '@core/services/cloud-sync-auth.service';

import { ICloudSyncPlatformAuthPayload } from '@core/interfaces/cloud-sync';
import { IControlOption } from '@shared/interfaces/forms';
import { IServerError } from '@shared/interfaces/server-error';
import { ICloudSyncAuthPopUpData } from '../../interfaces';

import { PopUpRef } from '../../models/pop-up-ref';

import { CLOUD_SYNC_ENVIRONMENTS } from '../../constants/pop-up-data';

import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-cloud-sync-auth-pop-up',
  templateUrl: './cloud-sync-auth-pop-up.component.html',
  styleUrls: ['./cloud-sync-auth-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudSyncAuthPopUpComponent implements OnDestroy {
  environmentsData: IControlOption[] = CLOUD_SYNC_ENVIRONMENTS;
  readonly platformEnvironment: IControlOption[] = this.environmentsData;

  isShowHelpContent: boolean = false;
  heldContent: SafeHtml;

  form: FormGroup = this.cloudSyncAuthService.createAuthForm(this.data.fields, this.data.sandbox);

  serverError$: Observable<IServerError | null> = this.store.pipe(select(getPlatformAuthError));
  authError$: Observable<IServerError | null> = this.store.pipe(select(getPlatformAuthError));
  authLoading$: Observable<boolean> = this.store.pipe(select(getPlatformAuthLoading));

  constructor(@Inject(POP_UP_DATA) public data: ICloudSyncAuthPopUpData,
              private popUpRef: PopUpRef<CloudSyncAuthPopUpComponent>,
              private _fb: FormBuilder,
              private store: Store<CoreState>,
              private cloudSyncAuthService: CloudSyncAuthService) {
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetPlatformAuthError());
  }

  onShowHelpContent(content: SafeHtml): void {
    this.isShowHelpContent = true;
    this.heldContent = content;
  }

  onClose(): void {
    if (this.isShowHelpContent) {
      this.isShowHelpContent = false;
      return;
    }

    this.store.dispatch(resetCloudSyncAuthPopUpAction());
    this.popUpRef.close();
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const { sandbox, ...form }: ICloudSyncPlatformAuthPayload = this.form.value;
    this.store.dispatch(platformAuthAction({ sandbox, credentials: form }));
  }

}
