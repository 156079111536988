<bl-drop-down #dropDown
              (onOpen)="onOpen.emit()"
              (onClose)="onClose.emit()"
              [leftOffset]="offset"
              [isPreventClose]="disableState"
              [toggleOnHover]="toggleOnHover"
              [zIndex]="zIndex"
              bodyPosition="right"
              data-testid="list-sub-nav">

  <ng-template blDropDownTarget>
    <div [class.active]="dropDown.isOpen" class="dots-wrapper">
      <i class="icon-menu dots"></i>
    </div>
  </ng-template>

  <ng-template blDropDownContent>
    <ng-content></ng-content>
  </ng-template>
</bl-drop-down>
