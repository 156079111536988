import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import * as jobActions from '@modules/segment/store/actions/personnel.action';
import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/tooltips.action';

import { StorageService } from '@core/services/storage.service';

import { ITooltip, ITooltips } from '@core/interfaces/tooltip';

export interface ITooltipsState {
  tooltips: ITooltips;
  lastId: number;

  showFirstTimeSegmentPageInfo: boolean;
  showCalculatorSegmentPageInfo: boolean;
  doNotShowGeoSuggestTooltip: boolean;
  showRefreshCreditsTooltipOnInit: boolean;
  isChangedTag: boolean;
}

const initialState: ITooltipsState = {
  tooltips: [],
  lastId: 0,

  showFirstTimeSegmentPageInfo: StorageService.hasLists !== null ? !StorageService.hasLists : null,
  showCalculatorSegmentPageInfo: null,
  doNotShowGeoSuggestTooltip: StorageService.doNotShowGeoSuggestTooltip,
  showRefreshCreditsTooltipOnInit: false,
  isChangedTag: false
};

const addTooltip: OnReducer<ITooltipsState, ActionType<Payload<any>>> = (state: ITooltipsState, { payload }: Payload<any>) => ({
  ...state,
  tooltips: [...state.tooltips, { ...payload, id: ++state.lastId }]
});

const removeTooltip: OnReducer<ITooltipsState, ActionType<Payload<any>>> = (state: ITooltipsState, { payload }: Payload<any>) => ({
  ...state,
  tooltips: [...state.tooltips.filter((item: ITooltip) => item.id !== payload)]
});

const setShowFirstTimeSegmentPageTooltip: OnReducer<ITooltipsState, ActionType<Payload<any>>> = (state: ITooltipsState, { payload }: Payload<any>) => ({
  ...state,
  showFirstTimeSegmentPageInfo: payload,
  showCalculatorSegmentPageInfo: null,
});

const setShowCalculatorSegmentPageTooltip: OnReducer<ITooltipsState, ActionType<Payload<any>>> = (state: ITooltipsState, { payload }: Payload<any>) => ({
  ...state,
  showCalculatorSegmentPageInfo: payload
});

const setDoNotShowGeoSuggestTooltipState: OnReducer<ITooltipsState, ActionType<Payload<any>>> = (state: ITooltipsState, { payload }: Payload<any>) => ({
  ...state,
  doNotShowGeoSuggestTooltip: payload,
});

const resetTooltipsState: OnReducer<any, ActionType<any>> = () => ({
  tooltips: [],
  lastId: 0,
  showCalculatorSegmentPageInfo: null,
  showFirstTimeSegmentPageInfo: null,
  doNotShowGeoSuggestTooltip: false,
  showRefreshCreditsTooltipOnInit: false,
  isChangedTag: false,
});

const setRefreshCreditsTooltipState: OnReducer<ITooltipsState, ActionType<Payload<any>>> = (state: ITooltipsState, { payload }: Payload<any>) => ({
  ...state,
  showRefreshCreditsTooltipOnInit: payload
});

const resetJobTagChanges: OnReducer<ITooltipsState, ActionType<any>> = (state: ITooltipsState) => ({
  ...state,
  isChangedTag: false
});

const isChangeTag: OnReducer<ITooltipsState, ActionType<any>> = (state: ITooltipsState) => ({
  ...state,
  isChangedTag: true
});

const reducer: ActionReducer<ITooltipsState> = createReducer<ITooltipsState>(
  initialState,

  on(actions.addTooltipAction, addTooltip),
  on(actions.removeTooltipAction, removeTooltip),

  on(actions.setShowFirstTimeSegmentPageTooltipAction, setShowFirstTimeSegmentPageTooltip),
  on(actions.setShowCalculatorSegmentPageTooltipAction, setShowCalculatorSegmentPageTooltip),

  on(actions.setDoNotShowGeoSuggestTooltipStateAction, setDoNotShowGeoSuggestTooltipState),
  on(actions.setRefreshCreditsTooltipStateAction, setRefreshCreditsTooltipState),

  on(actions.resetTooltipsStateAction, resetTooltipsState),

  on(actions.resetJobTagChangesAction, resetJobTagChanges),
  on(jobActions.removeJobSuccessAction, resetJobTagChanges),

  on(jobActions.addJobSuccessAction, isChangeTag)
);

export const tooltips: GetFromState<ITooltips, ITooltipsState> = (state: ITooltipsState): ITooltips => state.tooltips;
export const lastIdTooltip: GetFromState<number, ITooltips> = (_tooltips: ITooltips): number => _tooltips && _tooltips.length && _tooltips[_tooltips.length - 1].id || 0;

export const showFirstTimeSegmentPageInfo: GetFromState<boolean, ITooltipsState> = (state: ITooltipsState): boolean => state.showFirstTimeSegmentPageInfo;
export const showCalculatorSegmentPageInfo: GetFromState<boolean, ITooltipsState> = (state: ITooltipsState): boolean => state.showCalculatorSegmentPageInfo;
export const showRefreshCreditsTooltipOnInit: GetFromState<boolean, ITooltipsState> = (state: ITooltipsState): boolean => state.showRefreshCreditsTooltipOnInit;
export const doNotShowGeoSuggestTooltip: GetFromState<boolean, ITooltipsState> = (state: ITooltipsState): boolean => state.doNotShowGeoSuggestTooltip;
export const isChangedJobTag: GetFromState<boolean, ITooltipsState> = (state: ITooltipsState): boolean => state.isChangedTag;

export function tooltipsReducer(state: ITooltipsState, action: Action): ITooltipsState {
  return reducer(state, action);
}
