import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/account.action';

import { sortArray } from '@shared/utils/sort-array';
import { calcSeatStates, calcUnassignedSeats, countOpenedAddOns, mapSeatItemsAndCompareAddons, MapSeat, } from '../../utils/products';

import { IControlOption, IControlOptions, IStateOption, IStateOptions } from '@shared/interfaces/forms';
import { IServerError } from '@shared/interfaces/server-error';
import { ICreditCardsInfo } from '../../../e-commerce/interfaces/e-commerce';
import {
  IAccountSeat,
  IAccountSeats,
  IAccountSeatState,
  IEmailsTransformed,
  IOpenSeatAddOns,
  IOpenSeatStates,
  SEATS
} from '../../interfaces/marketview';

import { EMAILS_MIN_LENGTH_FOR_SEARCH } from '../../constants/autocomplete';
import { SEAT_IDS } from '../../constants/products';
import { SORTED_FIELD, SORTED_FIELD_SEAT_STATES } from '../../constants/states';


export interface IAccountState {
  emails: string[];
  emailsError: IServerError | null;
  emailsShouldReset: boolean;

  creditsCards: ICreditCardsInfo;
  creditsCardsError: IServerError | null;

  seats: IAccountSeats | null;
  seatsPending: boolean;
  seatsError: IServerError | null;

  openStates: IOpenSeatStates;
  openAddOns: IOpenSeatAddOns;

  seatsMapped: IAccountSeats;

  states: IStateOptions;
  statesPending: boolean;
  statesError: IServerError | null;
}

const _initialState: IAccountState = {
  emails: [],
  emailsError: null,
  emailsShouldReset: false,

  creditsCards: [],
  creditsCardsError: null,

  seats: [],
  seatsPending: false,
  seatsError: null,

  openStates: {
    availableIds: [],
    unassigned: 0
  },
  openAddOns: {
    bidNational: 0,
    grantNational: 0,
    bidStateAvailable: [],
    grantStateAvailable: [],
    bidStateUnassigned: 0,
    grantStateUnassigned: 0
  },

  seatsMapped: [],

  states: [],
  statesPending: false,
  statesError: null
};


// EMAILS
const getEmailsHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  emailsError: null,
  emails: payload.length >= EMAILS_MIN_LENGTH_FOR_SEARCH ? state.emails : [],
  emailsShouldReset: payload.length < EMAILS_MIN_LENGTH_FOR_SEARCH
});

const getEmailsErrorHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  emailsError: { ...payload },
  emailsShouldReset: false
});

const getEmailsSuccessHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  emails: state.emailsShouldReset
    ? []
    : [...payload],
  emailsShouldReset: false
});

const getEmailsResetHandler: OnReducer<IAccountState, ActionType<any>> = (state: IAccountState) => ({
  ...state,
  emailsError: null,
  emails: [],
  emailsShouldReset: false
});

// credit cards
const getCreditsCardsErrorHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  creditsCardsError: { ...payload }
});

const getCreditsCardsSuccessHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  creditsCards: [...payload]
});

// GET SEATS
const getAccountSeatsHandler: OnReducer<IAccountState, ActionType<any>> = (state: IAccountState) => ({
  ...state,
  seatsPending: true,
  seatsError: null
});

const getAccountSeatsErrorHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  seatsPending: false,
  seatsError: { ...payload }
});

/**
 *  open state seat = total - assigned
 *  unassigned = total - assigned - sum count of seat.states
 *
 *  1. unassigned > 0 && !sum count of seat.states > 0 - all states without btn create
 *  2. unassigned > 0 && !!sum count of seat.states > 0 - available states with btn create
 *  3. unassigned === 0 && !!sum count of seat.states > 0 - available states without btn create
 */
const getAccountSeatsSuccessHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => {
  const found: IAccountSeat = payload.find((seat: IAccountSeat) => seat.id === SEATS.State);
  let _seatStates: any[] = [];

  const openAddOns: IOpenSeatAddOns = countOpenedAddOns(payload);

  if (found) {
    const unassigned: number = calcUnassignedSeats(found.total, found.assigned, found.states);

    if (unassigned >= 0 && !!calcSeatStates(found.states)) {
      _seatStates = sortArray(found.states, SORTED_FIELD_SEAT_STATES);
    }

    return {
      ...state,
      openAddOns,
      seatsPending: false,
      seats: [...payload],
      openStates: {
        availableIds: _seatStates.map((_seat: IAccountSeatState) => _seat.id),
        unassigned
      },
      seatsMapped: mapSeatItemsAndCompareAddons(payload),
    };
  }

  return {
    ...state,
    openAddOns,
    seatsPending: false,
    seats: [...payload],
    openStates: {
      availableIds: [],
      unassigned: 0
    },
    seatsMapped: mapSeatItemsAndCompareAddons(payload),
  };
};

const resetAccountSeatsHandler: OnReducer<IAccountState, ActionType<any>> = (state: IAccountState) => ({
  ...state,
  seats: [],
  seatsPending: false,
  seatsError: null
});

// GET STATES
const getStatesHandler: OnReducer<IAccountState, ActionType<any>> = (state: IAccountState) => ({
  ...state,
  statesPending: true,
  statesError: null
});

const getStatesErrorHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  statesPending: false,
  statesError: { ...payload }
});

const getStatesSuccessHandler: OnReducer<IAccountState, ActionType<Payload<any>>> = (state: IAccountState, { payload }: Payload<any>) => ({
  ...state,
  statesPending: false,
  statesError: null,
  states: [...sortArray(payload, SORTED_FIELD)]
});

// RESET STATE
const resetAccountStatesStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ..._initialState
});

const reducer: ActionReducer<IAccountState> = createReducer<IAccountState>(
  _initialState,

  on(actions.getEmailsAction, getEmailsHandler),
  on(actions.getEmailsErrorAction, getEmailsErrorHandler),
  on(actions.getEmailsSuccessAction, getEmailsSuccessHandler),
  on(actions.getEmailsResetAction, getEmailsResetHandler),

  on(actions.getCreditsCardsErrorAction, getCreditsCardsErrorHandler),
  on(actions.getCreditsCardsSuccessAction, getCreditsCardsSuccessHandler),

  on(actions.getAccountSeatsAction, getAccountSeatsHandler),
  on(actions.getAccountSeatsErrorAction, getAccountSeatsErrorHandler),
  on(actions.getAccountSeatsSuccessAction, getAccountSeatsSuccessHandler),
  on(actions.resetAccountSeatsAction, resetAccountSeatsHandler),

  on(actions.getAccountStatesAction, getStatesHandler),
  on(actions.getAccountStatesErrorAction, getStatesErrorHandler),
  on(actions.getAccountStatesSuccessAction, getStatesSuccessHandler),

  on(actions.resetAccountStateAction, resetAccountStatesStateHandler)
);

// SEATS
export const isHasProducts: GetFromState<boolean, IAccountState> = (_state: IAccountState): boolean => {
  const _found: IAccountSeat[] = _state.seats.filter((_item: IAccountSeat) => _item.id !== SEATS.Lite && _item.id !== SEATS.Trial && _item.total > 0);
  return _found && !!_found.length;
};

export const accountSeats: GetFromState<IAccountSeats, IAccountState> = (_state: IAccountState): IAccountSeats => {
  return _state.seats.filter((_item: IAccountSeat) => _item.total || _item.id === SEATS.Lite);
};

export const accountSeatsWithComparedAddOns: GetFromState<IAccountSeats, IAccountState> = (_state: IAccountState): IAccountSeats => {
  return _state.seatsMapped.filter((_item: IAccountSeat) => _item.total || _item.id === SEATS.Lite);
};

export const baseSeats: GetFromState<IAccountSeats, IAccountSeats> = (_seats: IAccountSeats = []): IAccountSeats => {
  return _seats.filter((_item: IAccountSeat) => SEAT_IDS.includes(_item.id));
};

export const baseSeatsOptions: GetFromState<IControlOptions, IAccountSeats> = (_baseSeats: IAccountSeats = []): IControlOptions => {
  return _baseSeats.map((_item: IAccountSeat) => new MapSeat(_item));
};

export const defaultSeatId: GetFromState<number, IAccountSeats> = (_seats: IAccountSeats = []): number | null => {
  const _allSeats: MapSeat[] = _seats.map((_item: IAccountSeat) => new MapSeat(_item));
  const _found: IControlOption = _allSeats.find((_item: IControlOption) => !_item.disabled);
  return _found ? _found.value : null;
};

export const openStates: GetFromState<IOpenSeatStates, IAccountState> = (_state: IAccountState): IOpenSeatStates => _state && _state.openStates;
export const openStateIdsAvailable: GetFromState<number[], IOpenSeatStates> = (_openStates: IOpenSeatStates): number[] => _openStates && _openStates.availableIds;

export const openedAddOns: GetFromState<IOpenSeatAddOns, IAccountState> = (_state: IAccountState): IOpenSeatAddOns => _state && _state.openAddOns;

// EMAILS
export const emails: GetFromState<string[], IAccountState> = (_state: IAccountState): string[] => _state.emails;
export const emailsTransformed: GetFromState<IEmailsTransformed, string[]> = (_emails: string[]): IEmailsTransformed => _emails.map((_email: string) => ({
  text: _email,
  value: _email
}));

export const accountCreditsCards: GetFromState<ICreditCardsInfo, IAccountState> = (_state: IAccountState): ICreditCardsInfo => _state && _state.creditsCards;

// STATES
export const states: GetFromState<unknown, IAccountState> = (_state: IAccountState): unknown => _state.states.map(({ id, fullName }: IStateOption) => ({
  value: id,
  label: fullName
}));

export const availableStates: GetFromState<unknown, IAccountState> = (_state: IAccountState): unknown => {
  const _found: IAccountSeat = _state.seats.find((_seat: IAccountSeat) => _seat.id === SEATS.State);
  const _states: { label: string; value: number }[] = _found.states.map(({ id, name }: { id: number, name: string}) => ({ value: id, label: name })) || [];
  return sortArray(_states, SORTED_FIELD_SEAT_STATES) || [];
};

export const isOpenStates: GetFromState<unknown, IAccountSeats> = (_states: IAccountSeats): unknown => {
  const _found: IAccountSeat = _states.find((_seat: IAccountSeat) => _seat.id === SEATS.State);
  return _found && !!((_found.total - _found.assigned));
};

export function accountReducer(_state: IAccountState, _action: Action): IAccountState {
  return reducer(_state, _action);
}
