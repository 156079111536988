import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import * as account from '../reducers/account.reducer';

import { ICreditCardsInfo } from '@modules/e-commerce/interfaces/e-commerce';
import { IAccountSeats, IEmailsTransformed, IOpenSeatAddOns, IOpenSeatStates } from '@modules/profile/interfaces/marketview';
import { IControlOptions } from '@shared/interfaces/forms';

import { selectAccountState } from './index';

// SEATS
export const getIsHasProducts: MemoizedSelector<CoreState, boolean> = createSelector(selectAccountState, account.isHasProducts);
export const getAccountSeats: MemoizedSelector<CoreState, IAccountSeats> = createSelector(selectAccountState, account.accountSeats);

export const getAccountSeatsWithComparedAddOns: MemoizedSelector<CoreState, IAccountSeats> = createSelector(selectAccountState,
  account.accountSeatsWithComparedAddOns);

export const getBaseSeats: MemoizedSelector<CoreState, IAccountSeats> = createSelector(getAccountSeats, account.baseSeats);
export const getBaseSeatsOptions: MemoizedSelector<CoreState, IControlOptions> = createSelector(getBaseSeats, account.baseSeatsOptions);

export const getOpenStates: MemoizedSelector<CoreState, IOpenSeatStates> = createSelector(selectAccountState, account.openStates);
export const getOpenStateIdsAvailable: MemoizedSelector<CoreState, number[]> = createSelector(getOpenStates, account.openStateIdsAvailable);

export const getOpenAddOns: MemoizedSelector<CoreState, IOpenSeatAddOns> = createSelector(selectAccountState, account.openedAddOns);

export const getDefaultSeatId: MemoizedSelector<CoreState, number> = createSelector(getAccountSeats, account.defaultSeatId);

// EMAILS
export const getEmails: MemoizedSelector<CoreState, string[]> = createSelector(selectAccountState, account.emails);
export const getEmailsTransformed: MemoizedSelector<CoreState, IEmailsTransformed> = createSelector(getEmails, account.emailsTransformed);

export const getAccountCreditsCards: MemoizedSelector<CoreState, ICreditCardsInfo> = createSelector(selectAccountState,
  account.accountCreditsCards);

// STATES
export const getStates: MemoizedSelector<CoreState, unknown> = createSelector(selectAccountState, account.states);
export const getAvailableStates: MemoizedSelector<CoreState, unknown> = createSelector(selectAccountState, account.availableStates);
export const getIsOpenStates: MemoizedSelector<CoreState, unknown> = createSelector(getAccountSeats, account.isOpenStates);
