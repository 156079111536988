import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IMultiCodesError } from '@shared/interfaces/server-error';

import { IAddMultiCodesData } from '../../models/codes';

enum ACTION_TYPES {
  ADD = '[PERSONNEL MULTI CODES] Add codes',
  ADD_ERROR = '[PERSONNEL MULTI CODES] Add codes Error',
  RESET_ERROR = '[PERSONNEL MULTI CODES] Reset codes Error',
  ADD_SUCCESS = '[PERSONNEL MULTI CODES] Add codes Success',

  RESET = '[PERSONNEL MULTI CODES] Reset'
}

export const addPersonnelMultiCodesAction: CreateActionType<ACTION_TYPES.ADD, IAddMultiCodesData> = createAction(ACTION_TYPES.ADD, createPayload<IAddMultiCodesData>());
export const addPersonnelMultiCodesErrorAction: CreateActionType<ACTION_TYPES.ADD_ERROR, IMultiCodesError> = createAction(ACTION_TYPES.ADD_ERROR, createPayload<IMultiCodesError>());
export const resetAddPersonnelMultiCodesErrorAction: ActionCreator<ACTION_TYPES.RESET_ERROR, () => TypedAction<ACTION_TYPES.RESET_ERROR>> = createAction(ACTION_TYPES.RESET_ERROR);
export const addPersonnelMultiCodesSuccessAction: ActionCreator<ACTION_TYPES.ADD_SUCCESS, () => TypedAction<ACTION_TYPES.ADD_SUCCESS>> = createAction(ACTION_TYPES.ADD_SUCCESS);
export const resetPersonnelMultiCodesAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
