import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CORE_PATHS } from '@core/constants/core-paths';
import {
  CREDIT_METER_TEXT,
  REFRESH_CREDITS_BTN_TOOLTIP_TEXT,
  UNLIMITED_CREDITS_MSG
} from '@shared/constants/tooltips/tooltips-text';
import { E_COMMERCE_PATHS } from '../../../e-commerce/constants/e-commerce-paths';

@Component({
  selector: 'bl-credit-meter',
  templateUrl: './credit-meter.component.html',
  styleUrls: ['./credit-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreditMeterComponent {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly eCommercePaths: typeof E_COMMERCE_PATHS = E_COMMERCE_PATHS;
  readonly tooltipText: string = CREDIT_METER_TEXT;
  readonly unlimitedCreditsMsg: string = UNLIMITED_CREDITS_MSG;
  readonly refreshBtnTooltipText: string = REFRESH_CREDITS_BTN_TOOLTIP_TEXT;

  @Input() isMobile: boolean;
  @Input() isOpenedFist: boolean;
  @Input() showTooltipOnInit: boolean;
  @Input() isUnlimitedUser: boolean;
  @Input() userCredits: number;
  @Input() pending: boolean;
  @Input() isBroker: boolean;

  @Output() onRefresh: EventEmitter<any> = new EventEmitter();
  @Output() onGoTo: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  refresh(): void {
    this.onRefresh.emit();
  }

  goTo(): void {
    this.onGoTo.emit();
  }
}
