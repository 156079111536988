export enum DATE_TYPE {
  FROM = 'From',
  TO = 'To'
}

export enum TARGETING_DATA_TYPES {
  MULTI = 'multi',
  RADIO_BUTTON = 'radioButton'
}

export enum TARGETING_DATA_CONTROL_TYPES {
  CHECKBOX = 'checkbox',
  SWITCH_CHECKBOX = 'switch-checkbox',
  RADIO = 'radio',
  RADIO_BUTTON = 'radioButton',
  SLIDER = 'slider',
  LABEL = 'label',
  DATE = 'date',
  CUSTOM_DROPDOWN = 'customDropdown'
}

export const UN_SELECTED_BY_ALL_TARGETING_CONTROLS_TYPES: TARGETING_DATA_CONTROL_TYPES[] = [
  TARGETING_DATA_CONTROL_TYPES.SLIDER,
  TARGETING_DATA_CONTROL_TYPES.LABEL,
  TARGETING_DATA_CONTROL_TYPES.DATE,
  TARGETING_DATA_CONTROL_TYPES.RADIO_BUTTON,
  TARGETING_DATA_CONTROL_TYPES.RADIO,
  TARGETING_DATA_CONTROL_TYPES.CUSTOM_DROPDOWN
];

export type ITargetingCriteria = ITargetingCriteriaItem[];

export interface ITargetingCriteriaItem {
  title: string;
  targetingControlId: number;
  description: string | null;
  data: ITargetingCriteriaControls;
  detailedTargetingData: ITargetingCriteriaItemDetailedData<ITargetingCriteriaItemDetailedItemAsMultiData
    | ITargetingCriteriaItemDetailedDataSimpleData>;
}

export interface ITargetingDataType {
  type?: TARGETING_DATA_TYPES;
}

export type ITargetingCriteriaItemDetailedData<T = any> = Array<T>;

export interface ITargetingCriteriaItemDetailedItemAsMultiData extends ITargetingDataType {
  title: string;
  description: string;
  data: Array<ITargetingCriteriaItemDetailedDataSimpleData | ITargetingCriteriaItemDetailedDataRadioData>;
}

export interface ITargetingCriteriaItemDetailedDataRadioData extends ITargetingDataType {
  title?: string;
  description?: string;
  data: ITargetingCriteriaItemDetailedDataRadioDataItem[];
}

export interface ITargetingCriteriaItemDetailedDataRadioDataItem {
  title: string;
  filterLogic: number;
  data: ITargetingCriteriaItemDetailedDataSimpleData[];
}

export interface ITargetingCriteriaItemDetailedDataSimpleData extends ITargetingDataType {
  detailedTargetingControlId: number;
  title: string;
  description: string | null;
  data: ITargetingCriteriaControls;
  isToggle?: boolean;
}

export interface ITargetingCriteriaDataItemCheckboxesDataItem
  extends ITitle, IStatus, ICurrentMinAndMax, IMinAndMax, ICurrent, IDisabledLabel,
    IDescription, IMinAndMaxOptions, IFunctionalMax, ISymbol, IMapValues {
  type: TARGETING_DATA_CONTROL_TYPES.CHECKBOX;
  checkboxId: number;
  disabled: boolean;
  isPremium: boolean;
  appendId: number | null;
}

export interface ITargetingCriteriaDataItemLabelDataItem
  extends ITitle, IStatus, ICurrentMinAndMax, IMinAndMax, ICurrent, IDisabledLabel,
    IDescription, IMinAndMaxOptions, IFunctionalMax, ISymbol, IMapValues {
  type: TARGETING_DATA_CONTROL_TYPES.LABEL;
  checkboxId: number;
  disabled: boolean;
  isPremium: boolean;
}

export interface ITargetingCriteriaDataItemSwitchCheckboxDataItem
  extends ITitle, IStatus, ICurrentMinAndMax, IMinAndMax, ICurrent, IDisabledLabel,
    IDescription, IMinAndMaxOptions, IFunctionalMax, ISymbol, IMapValues {
  type: TARGETING_DATA_CONTROL_TYPES.SWITCH_CHECKBOX;
  checkboxId: number;
  disabled: boolean;
  isPremium: boolean;
}

export interface ITargetingCriteriaDataItemRadioButtonsDataItem
  extends ITitle, IStatus, ICurrentMinAndMax, IMinAndMax, ICurrent, IDisabledLabel,
    IDescription, IMinAndMaxOptions, IFunctionalMax, ISymbol, IMapValues {
  type: TARGETING_DATA_CONTROL_TYPES.RADIO_BUTTON;
  radioButtonId: number;
  disabled: boolean;
  isPremium: boolean;
  appendId: number | null;
}

export interface ITargetingCriteriaDataItemRadioDataItem
  extends ITitle, IStatus, ICurrentMinAndMax, IMinAndMax, ICurrent, IDisabledLabel,
    IDescription, IMinAndMaxOptions, IFunctionalMax, ISymbol, IMapValues {
  type: TARGETING_DATA_CONTROL_TYPES.RADIO;
  checkboxId: number;
  disabled: boolean;
  isPremium: boolean;
}

export interface ITargetingCriteriaDataItemSlidersDataItem
  extends ITitle, IStatus, ICurrentMinAndMax, IMinAndMax, ICurrent, IDisabledLabel,
    IDescription, IMinAndMaxOptions, IFunctionalMax, ISymbol, IMapValues {
  type: TARGETING_DATA_CONTROL_TYPES.SLIDER;
  isPremium: boolean;
  appendId: number | null;
  disabled?: boolean;
}

export interface ITargetingCriteriaDataItemSlidersDataItemMapValues {
  [key: string]: string;
}

export interface ITargetingCriteriaDataItemDatesDataItem
  extends ITitle, IStatus, ICurrentMinAndMax, IMinAndMax, ICurrent,
    IDisabledLabel, IDescription, IMinAndMaxOptions, IFunctionalMax, ISymbol, IMapValues {
  type: TARGETING_DATA_CONTROL_TYPES.DATE;
  dateId: number;
  isPremium: boolean;
  disabled?: boolean;
}

export interface ISliderOptions {
  hasUnder?: boolean;
  label?: string;
}

export interface ISliderSymbol {
  value: string;
  isBefore: boolean;
}

interface ITitle {
  title?: string | DATE_TYPE;
}

interface IStatus {
  status?: boolean | null;
}

interface ICurrentMinAndMax {
  currentMin?: number;
  currentMax?: number;
}

interface IMinAndMax {
  min?: number;
  max?: number;
}

interface ICurrent {
  current?: number | null;
}

interface IDisabledLabel {
  disabledLabel?: string;
}

interface IDescription {
  description: string | null;
}

interface IMinAndMaxOptions {
  minOptions?: ISliderOptions;
  maxOptions?: ISliderOptions;
}

interface IFunctionalMax {
  functionalMax?: number | null;
}

interface ISymbol {
  symbol?: ISliderSymbol;
}

interface IMapValues {
  mapValues: ITargetingCriteriaDataItemSlidersDataItemMapValues;
}

export type ITargetingCriteriaDataItemDatesData = ITargetingCriteriaDataItemDatesDataItem[];
export type ITargetingCriteriaDataItemSwitchCheckboxData = ITargetingCriteriaDataItemSwitchCheckboxDataItem[];
export type ITargetingCriteriaDataItemCheckboxesData = ITargetingCriteriaDataItemCheckboxesDataItem[];
export type ITargetingCriteriaDataItemSlidersData = ITargetingCriteriaDataItemSlidersDataItem[];
export type ITargetingCriteriaDataItemRadioButtonsData = ITargetingCriteriaDataItemRadioButtonsDataItem[];

export type ITargetingCriteriaDataItemControlDataItem = ITargetingCriteriaDataItemDatesDataItem
  | ITargetingCriteriaDataItemCheckboxesDataItem
  | ITargetingCriteriaDataItemSlidersDataItem
  | ITargetingCriteriaDataItemRadioButtonsDataItem
  | ITargetingCriteriaDataItemRadioDataItem
  | ITargetingCriteriaDataItemLabelDataItem
  | ITargetingCriteriaDataItemSwitchCheckboxDataItem;

export type ITargetingCriteriaControls = ITargetingCriteriaDataItemControlDataItem[];
