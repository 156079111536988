import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { ISegmentData, IToggleTargeting } from '../../interfaces/segment';

import { TARGETING_TYPES_ID } from '@shared/constants/data/list-types';

import { TARGETING_DATA_CONTROL_TYPES } from '@shared/modules/targeting-criteria-controls/data';

enum ACTION_TYPES {
  GET_SEGMENT_TARGETING = '[SEGMENT]: get segment targeting data',
  GET_SEGMENT_TARGETING_ERROR = '[SEGMENT]: get segment targeting data error',
  GET_SEGMENT_TARGETING_SUCCESS = '[SEGMENT]: get segment targeting data success',

  RELOAD_SEGMENT_TARGETING = '[SEGMENT]: reload segment targeting data',
  RELOAD_SEGMENT_TARGETING_ERROR = '[SEGMENT]: reload segment targeting data error',
  RELOAD_SEGMENT_TARGETING_SUCCESS = '[SEGMENT]: reload segment targeting data success',

  CLEAR_TARGETING = '[SEGMENT]: Clear Targeting',
  CLEAR_TARGETING_ERROR = '[SEGMENT]: Clear Targeting Error',
  CLEAR_TARGETING_SUCCESS = '[SEGMENT]: Clear Targeting Success',

  TOGGLE_TARGETING = '[SEGMENT]: toggle targeting',
  RESET_OPENED_TARGETINGS = '[SEGMENT]: reset opened targetings',

  CHANGES_START = '[SEGMENT]: segment change start',
  CHANGES_END = '[SEGMENT]: segment change end',
  CHANGES_RESET = '[SEGMENT]: segment change reset',

  PREMIUM_DATA_SELECTED = '[SEGMENT]: premium data selected',

  UPDATE_OPENED_SEGMENT_ID = '[SEGMENT]: Update opened segment id',
  RESET_OPENED_SEGMENT_ID = '[SEGMENT]: Reset opened segment id',

  LISTEN_SOCKET = '[SEGMENT] Listen duplicate segments events',
}


export const getSegmentTargetingAction: CreateActionType<ACTION_TYPES.GET_SEGMENT_TARGETING, number> = createAction(ACTION_TYPES.GET_SEGMENT_TARGETING, createPayload<number>());
export const getSegmentTargetingErrorAction: CreateActionType<ACTION_TYPES.GET_SEGMENT_TARGETING_ERROR, IServerError> = createAction(ACTION_TYPES.GET_SEGMENT_TARGETING_ERROR, createPayload<IServerError>());
export const getSegmentTargetingSuccessAction: CreateActionType<ACTION_TYPES.GET_SEGMENT_TARGETING_SUCCESS, ISegmentData> = createAction(
  ACTION_TYPES.GET_SEGMENT_TARGETING_SUCCESS,
  createPayload<ISegmentData>()
);

export const reloadSegmentTargetingAction: CreateActionType<ACTION_TYPES.RELOAD_SEGMENT_TARGETING, number> = createAction(ACTION_TYPES.RELOAD_SEGMENT_TARGETING, createPayload<number>());
export const reloadSegmentTargetingErrorAction: CreateActionType<ACTION_TYPES.RELOAD_SEGMENT_TARGETING_ERROR, IServerError> = createAction(
  ACTION_TYPES.RELOAD_SEGMENT_TARGETING_ERROR,
  createPayload<IServerError>()
);
export const reloadSegmentTargetingSuccessAction: CreateActionType<ACTION_TYPES.RELOAD_SEGMENT_TARGETING_SUCCESS, ISegmentData> = createAction(
  ACTION_TYPES.RELOAD_SEGMENT_TARGETING_SUCCESS,
  createPayload<ISegmentData>()
);

export const clearTargetingAction: CreateActionType<ACTION_TYPES.CLEAR_TARGETING, { segmentCriteriaId: number; targetingId: TARGETING_TYPES_ID }> = createAction(ACTION_TYPES.CLEAR_TARGETING,
  createPayload<{ segmentCriteriaId: number; targetingId: TARGETING_TYPES_ID }>());
export const clearTargetingErrorAction: CreateActionType<ACTION_TYPES.CLEAR_TARGETING_ERROR, IServerError> = createAction(ACTION_TYPES.CLEAR_TARGETING_ERROR, createPayload<IServerError>());
export const clearTargetingSuccessAction: ActionCreator<ACTION_TYPES.CLEAR_TARGETING_SUCCESS, () => TypedAction<ACTION_TYPES.CLEAR_TARGETING_SUCCESS>> = createAction(ACTION_TYPES.CLEAR_TARGETING_SUCCESS);

export const toggleTargetingAction: CreateActionType<ACTION_TYPES.TOGGLE_TARGETING, IToggleTargeting> = createAction(ACTION_TYPES.TOGGLE_TARGETING, createPayload<IToggleTargeting>());

export const resetOpenedTargetingsAction: CreateActionType<ACTION_TYPES.RESET_OPENED_TARGETINGS, number> = createAction(ACTION_TYPES.RESET_OPENED_TARGETINGS, createPayload<number>());

export const segmentChangesStartAction: ActionCreator<ACTION_TYPES.CHANGES_START, () => TypedAction<ACTION_TYPES.CHANGES_START>> = createAction(ACTION_TYPES.CHANGES_START);
export const segmentChangesEndAction: ActionCreator<ACTION_TYPES.CHANGES_END, () => TypedAction<ACTION_TYPES.CHANGES_END>> = createAction(ACTION_TYPES.CHANGES_END);
export const segmentChangesResetAction: ActionCreator<ACTION_TYPES.CHANGES_RESET, () => TypedAction<ACTION_TYPES.CHANGES_RESET>> = createAction(ACTION_TYPES.CHANGES_RESET);

export const premiumDataSelected: CreateActionType<ACTION_TYPES.PREMIUM_DATA_SELECTED, { type: TARGETING_DATA_CONTROL_TYPES; callBacks?: Function[] }> = createAction(ACTION_TYPES.PREMIUM_DATA_SELECTED,
  createPayload<{ type: TARGETING_DATA_CONTROL_TYPES; callBacks?: Function[] }>());

export const updateOpenedSegmentIdAction: CreateActionType<ACTION_TYPES.UPDATE_OPENED_SEGMENT_ID, number> = createAction(ACTION_TYPES.UPDATE_OPENED_SEGMENT_ID, createPayload<number>());
export const resetOpenedSegmentIdAction: ActionCreator<ACTION_TYPES.RESET_OPENED_SEGMENT_ID, () => TypedAction<ACTION_TYPES.RESET_OPENED_SEGMENT_ID>> = createAction(ACTION_TYPES.RESET_OPENED_SEGMENT_ID);

export const listenDuplicateSegmentSocketAction: ActionCreator<ACTION_TYPES.LISTEN_SOCKET, () => TypedAction<ACTION_TYPES.LISTEN_SOCKET>> = createAction(ACTION_TYPES.LISTEN_SOCKET);
