<div class="cloud-sync-to-popup">
  <div class="sync-to-section">
    <bl-sync-to [isSinglePlatform]="true"
                [platforms]="data.platforms"
                (onSelectPlatform)="selectPlatform($event)">
    </bl-sync-to>
  </div>
  <div class="divider-sections">
    Or
  </div>
  <div class="sync-to-section">
    <bl-sync-to [isSinglePlatform]="false"
                [platforms]="data.platforms">
    </bl-sync-to>
  </div>
  <div class="close-popup" (click)="onCloseClick()">
    <i class="icon-page-close"></i>
  </div>
</div>
