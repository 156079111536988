import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberMask'
})
export class NumberMaskPipe implements PipeTransform {

  transform(value: string): string {
    if (!value || typeof value !== 'string') {
      return '';
    }

    return value;
  }

  parse(value: string): string {
    if (!value || typeof value !== 'string') {
      return '';
    }

    return value
      .trim()
      .replace(/\D+/g, '')
      .replace(/^([1-9]\d*)?$/, '$1');
  }
}
