<bl-assign-pop-up *ngIf="isOpenAssignStateInfoPopUp$ | async"
                  (close)="closeStatePopUp()"
                  (confirm)="confirmStatePopUp()">
  Once you assign a State Seat to your team the state remains the same for your subscription period.
</bl-assign-pop-up>

<bl-assign-pop-up *ngIf="isOpenAssignAddOnInfoPopUp$ | async"
                  (close)="closeAddOnPopUp()"
                  (confirm)="confirmAddOnPopUp()">
  Once you assign a State Add-on the state remains the same for the duration of your subscription period.
</bl-assign-pop-up>
