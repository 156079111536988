export class ServerErrorException {
  readonly status: number = 404;
  readonly message: string = '';
  readonly errors: { [key: string]: string[] } = {};

  constructor(message: string = 'Oops!',
              errors: { [key: string]: string[] } = {},
              status: number = 404) {
    this.status = status;
    this.message = message;
    this.errors = errors;
  }
}
