import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CustomControlsModule } from '@ui/custom-controls/custom-controls.module';
import { ErrorsModule } from '@ui/errors/errors.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { TooltipModule } from '@ui/tooltip/tooltip.module';
import { ReCaptchaModule } from '../re-captcha/re-captcha.module';

import { AddressFormComponent } from './components/address-form/address-form.component';
import { ECommerceSignInFormComponent } from './components/e-commerce-sign-in-form/e-commerce-sign-in-form.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { PaymentAddressComponent } from './components/payment-address/payment-address.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';
import { SignInFormComponent } from './components/sign-in-form/sign-in-form.component';
import { SignUpByInviteFormComponent } from './components/sign-up-by-invite-form/sign-up-by-invite-form.component';
import { SignUpFormComponent } from './components/sign-up-form/sign-up-form.component';

const imports: any[] = [
  FormsModule,
  ReactiveFormsModule,
  TooltipModule,
  SpinnerModule,
  CommonModule,
  ReCaptchaModule,
  CustomControlsModule,
  RouterModule,
  ErrorsModule,
];

const declarations: any[] = [
  SignUpFormComponent,
  AddressFormComponent,
  PaymentAddressComponent,
  ForgotPasswordFormComponent,
  ResetPasswordFormComponent,
  SignInFormComponent,
  SignUpByInviteFormComponent,
  ECommerceSignInFormComponent
];

const exports: any[] = [
  FormsModule,
  ReactiveFormsModule,
  ...declarations
];

@NgModule({
  imports,
  declarations,
  exports
})
export class UserFormsModule {
}
