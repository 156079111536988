import { ITableHeaders } from '@ui/tables/components/table/table.component';

import { sortByField, sortByFields } from '../utils/sort';

import { IControlOptions } from '@shared/interfaces/forms';
import { Roles, USER_STATUS } from '../interfaces/marketview';

import { SORT_DIRECTION } from '@shared/constants/sort';

interface IManageRoleOption {
  value: Roles;
  label: string;
}

interface IManageOptions {
  editOwner: boolean;
  editAdmin: boolean;
  editUser: boolean;
  roleOptions: IManageRoleOption[];
}

export const sortByFirstName: (data: unknown[], sortDirection?: SORT_DIRECTION) => unknown[]
  = (data: unknown[], sortDirection: SORT_DIRECTION = SORT_DIRECTION.ASC) => {
  const results: unknown[] = sortByField(data, 'firstName');

  if (sortDirection === SORT_DIRECTION.DESC) {
    results.reverse();
  }

  return results;
};

export const sortBySeats: (data: unknown[], sortDirection?: SORT_DIRECTION) => unknown[] =
  (data: unknown[], sortDirection: SORT_DIRECTION = SORT_DIRECTION.ASC) => {
    const results: unknown[] = sortByFields(data, 'seats', 'id');

    if (sortDirection === SORT_DIRECTION.DESC) {
      results.reverse();
    }

    return results;
  };

export const sortByAddOns: (data: unknown[], sortDirection?: SORT_DIRECTION) => unknown[] =
  (data: unknown[], sortDirection: SORT_DIRECTION = SORT_DIRECTION.ASC) => {
    const results: unknown[] = sortByFields(data, 'addOns', 'id');

    if (sortDirection === SORT_DIRECTION.DESC) {
      results.reverse();
    }

    return results;
  };


export const sortByBidAddOns: (data: unknown[], sortDirection?: SORT_DIRECTION) => unknown[] =
  (data: unknown[], sortDirection: SORT_DIRECTION = SORT_DIRECTION.ASC) => {
    const results: unknown[] = sortByFields(data, 'addOnsBids', 'id');

    if (sortDirection === SORT_DIRECTION.DESC) {
      results.reverse();
    }

    return results;
  };

export const sortByGrantAddOns: (data: unknown[], sortDirection?: SORT_DIRECTION) => unknown[] =
  (data: unknown[], sortDirection: SORT_DIRECTION = SORT_DIRECTION.ASC) => {
    const results: unknown[] = sortByFields(data, 'addOnsGrants', 'id');

    if (sortDirection === SORT_DIRECTION.DESC) {
      results.reverse();
    }

    return results;
  };

export const sortByRole: (data: unknown[], sortDirection?: SORT_DIRECTION) => unknown[] =
  (data: unknown[], sortDirection: SORT_DIRECTION = SORT_DIRECTION.ASC) => {
    const results: unknown[] = sortByField(data, 'role', 'name');

    if (sortDirection === SORT_DIRECTION.DESC) {
      results.reverse();
    }

    return results;
  };

export const ACCOUNT_USERS_TABLE_HEADERS: ITableHeaders = [{
  key: 'firstName',
  title: 'Name',
  sortable: true,
  sortingFunction: sortByFirstName
}, {
  key: 'email',
  title: 'Email'
}, {
  key: 'seats',
  title: 'Seat',
  sortable: true,
  sortingFunction: sortBySeats
}, {
  key: 'addOnsGrants',
  title: 'Grants',
  align: 'center',
  sortable: true,
  sortingFunction: sortByGrantAddOns
}, {
  key: 'addOnsBids',
  title: 'RFPs & IFBs',
  align: 'center',
  sortable: true,
  sortingFunction: sortByBidAddOns
}, {
  key: 'role',
  title: 'Admin',
  align: 'center',
  sortable: true,
  sortingFunction: sortByRole
}];

export const PROFILE_ACCOUNT_USER_TABLE_HEADERS: ITableHeaders = [{
  key: 'firstName',
  title: 'Name',
}, {
  key: 'email',
  title: 'Email'
}, {
  key: 'seats',
  title: 'Seat'
}, {
  key: 'addOnsGrants',
  title: 'Grants',
  align: 'center',
}, {
  key: 'addOnsBids',
  title: 'RFPs & IFBs',
  align: 'center',
}, {
  key: 'role',
  title: 'Admin',
  align: 'center',
}];

export const ACCOUNT_USERS_TABLE_COMPUTED_PROPERTIES: {
  fullName: ({
               firstName,
               lastName
             }: { firstName: string; lastName: string }) => string
} = {
  fullName: ({ firstName, lastName }: { firstName: string, lastName: string }) => `${ firstName } ${ lastName }`,
};

export const ROLES_OPTIONS: IControlOptions = [
  {
    value: Roles.User,
    label: 'None',
  },
  {
    value: Roles.Admin,
    label: 'Admin'
  }
];

export const CAN_MANAGE: { [key: string]: IManageOptions } = Object.freeze({
  [Roles.Owner]: {
    editOwner: true,
    editAdmin: true,
    editUser: true,
    roleOptions: [
      {
        value: Roles.Owner,
        label: 'Owner'
      },
      {
        value: Roles.User,
        label: 'None',
      },
      {
        value: Roles.Admin,
        label: 'Admin'
      }
    ]
  },
  [Roles.Admin]: {
    editOwner: false,
    editAdmin: true,
    editUser: true,
    roleOptions: [
      {
        value: Roles.User,
        label: 'None',
      },
      {
        value: Roles.Admin,
        label: 'Admin'
      }
    ]
  },
  [Roles.User]: {
    editOwner: false,
    editAdmin: false,
    editUser: false,
    roleOptions: []
  },
});

export const USER_STATUS_NOT_MERGE: (USER_STATUS.NOT_EXIST_USER | USER_STATUS.EXIST_USER)[] =
  [USER_STATUS.NOT_EXIST_USER, USER_STATUS.EXIST_USER];
export const USER_STATUS_IS_MERGE: number[] = [2, 3, 5];
