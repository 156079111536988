import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import { select, Store } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';

import { getGeneralTermsAction } from '../store/actions/tos.action';
import { CoreState } from '../store/reducers';
import { getGeneralTermsDataLoaded } from '../store/selectors/tos.selector';

@Injectable()
export class TermsOfServiceDataGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private store: Store<CoreState>) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkData();
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkData();
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkData();
  }

  private checkData(): Observable<boolean> {
    return this.store
      .pipe(
        select(getGeneralTermsDataLoaded),
        tap((loaded: boolean) => {
          if (!loaded) {
            this.store.dispatch(getGeneralTermsAction());
          }
        }),
        mapTo(true),
        catchError(() => of(false))
      );
  }
}
