import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IAppendFieldsWithPhones,
  IListAppend,
  IListAppendsResponse,
  IListAppendField,
  IToggleListAppendField
} from '../../../list-appends/interfaces/appends';

enum ACTION_TYPES {
  GET = '[LIST APPENDS] get',
  GET_ERROR = '[LIST APPENDS] get error',
  GET_SUCCESS = '[LIST APPENDS] get success',

  GET_APPENDS_ADDITIONAL = '[LIST APPENDS] get appends additional',
  GET_APPENDS_ADDITIONAL_ERROR = '[LIST APPENDS] get appends additional error',
  GET_APPENDS_ADDITIONAL_SUCCESS = '[LIST APPENDS] get appends additional success',

  GET_APPEND_AND_SUMMARY = '[LIST APPENDS] get append and summary',
  GET_APPEND_AND_SUMMARY_ERROR = '[LIST APPENDS] get append and summary error',
  GET_APPEND_AND_SUMMARY_SUCCESS = '[LIST APPENDS] get append and summary success',

  SET_SPINNER = '[LIST APPENDS] set spinner',

  SELECT_ALL = '[LIST APPENDS] select all appends',
  SELECT = '[LIST APPENDS] select append',

  TOGGLE = '[LIST APPENDS] toggle field',
  TOGGLE_ERROR = '[LIST APPENDS] toggle field error',
  TOGGLE_SUCCESS = '[LIST APPENDS] toggle field success',

  INCLUDE_FIELDS_WITH_PHONE = '[LIST APPENDS] Include fields with phones',
  INCLUDE_FIELDS_WITH_PHONE_ERROR = '[LIST APPENDS] Include fields with phones error',
  INCLUDE_FIELDS_WITH_PHONE_SUCCESS = '[LIST APPENDS] Include fields with phones success',

  SHOW_MESSAGE = '[LIST APPENDS] Show message',

  RESET_SELECTED_APPEND = '[LIST APPENDS] Reset selected category',

  CHECK_PHONE_APPENDS_POP_UP = '[LIST APPENDS] Check phone appends pop up',

  RESET = '[LIST APPENDS] reset'
}

export const getListAppendsAction: CreateActionType<ACTION_TYPES.GET, number> = createAction(ACTION_TYPES.GET, createPayload<number>());
export const getListAppendsErrorAction: CreateActionType<ACTION_TYPES.GET_ERROR, IServerError> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getListAppendsSuccessAction: CreateActionType<ACTION_TYPES.GET_SUCCESS, IListAppendsResponse> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IListAppendsResponse>());

export const getListAppendsAdditionalAction: CreateActionType<ACTION_TYPES.GET_APPENDS_ADDITIONAL, number> = createAction(ACTION_TYPES.GET_APPENDS_ADDITIONAL, createPayload<number>());
export const getListAppendsAdditionalErrorAction: CreateActionType<ACTION_TYPES.GET_APPENDS_ADDITIONAL_ERROR, IServerError> = createAction(ACTION_TYPES.GET_APPENDS_ADDITIONAL_ERROR, createPayload<IServerError>());
export const getListAppendsAdditionalSuccessAction: CreateActionType<ACTION_TYPES.GET_APPENDS_ADDITIONAL_SUCCESS, IAppendFieldsWithPhones> = createAction(
  ACTION_TYPES.GET_APPENDS_ADDITIONAL_SUCCESS,
  createPayload<IAppendFieldsWithPhones>()
);

export const getAppendsAndSummaryAction: CreateActionType<ACTION_TYPES.GET_APPEND_AND_SUMMARY, number> = createAction(ACTION_TYPES.GET_APPEND_AND_SUMMARY, createPayload<number>());
export const getAppendsAndSummaryErrorAction: CreateActionType<ACTION_TYPES.GET_APPEND_AND_SUMMARY_ERROR, IServerError> = createAction(ACTION_TYPES.GET_APPEND_AND_SUMMARY_ERROR, createPayload<IServerError>());
export const getAppendsAndSummarySuccessAction: CreateActionType<ACTION_TYPES.GET_APPEND_AND_SUMMARY_SUCCESS, IListAppendsResponse> = createAction(
  ACTION_TYPES.GET_APPEND_AND_SUMMARY_SUCCESS,
  createPayload<IListAppendsResponse>()
);

export const setSpinner: CreateActionType<ACTION_TYPES.SET_SPINNER, boolean> = createAction(ACTION_TYPES.SET_SPINNER, createPayload<boolean>());

export const selectAllAppendsAction: ActionCreator<ACTION_TYPES.SELECT_ALL, () => TypedAction<ACTION_TYPES.SELECT_ALL>> = createAction(ACTION_TYPES.SELECT_ALL);
export const selectListAppendsAction: CreateActionType<ACTION_TYPES.SELECT, IListAppend> = createAction(ACTION_TYPES.SELECT, createPayload<IListAppend>());

export const toggleListAppendFieldAction: CreateActionType<ACTION_TYPES.TOGGLE, IToggleListAppendField> = createAction(ACTION_TYPES.TOGGLE, createPayload<IToggleListAppendField>());
export const toggleListAppendFieldErrorAction: CreateActionType<ACTION_TYPES.TOGGLE_ERROR, IServerError> = createAction(ACTION_TYPES.TOGGLE_ERROR, createPayload<IServerError>());
export const toggleListAppendFieldSuccessAction: CreateActionType<ACTION_TYPES.TOGGLE_SUCCESS, IToggleListAppendField> = createAction(ACTION_TYPES.TOGGLE_SUCCESS, createPayload<IToggleListAppendField>());

export const includeListAppendFieldWithPhonesAction: CreateActionType<ACTION_TYPES.INCLUDE_FIELDS_WITH_PHONE, IListAppendField> = createAction(ACTION_TYPES.INCLUDE_FIELDS_WITH_PHONE,
  createPayload<IListAppendField>());
export const includeListAppendFieldWithPhonesErrorAction: CreateActionType<ACTION_TYPES.INCLUDE_FIELDS_WITH_PHONE_ERROR, IServerError> = createAction(ACTION_TYPES.INCLUDE_FIELDS_WITH_PHONE_ERROR,
  createPayload<IServerError>());
export const includeListAppendFieldWithPhonesSuccessAction: CreateActionType<ACTION_TYPES.INCLUDE_FIELDS_WITH_PHONE_SUCCESS, IListAppendField> = createAction(ACTION_TYPES.INCLUDE_FIELDS_WITH_PHONE_SUCCESS,
  createPayload<IListAppendField>());

export const showListAppendsMessageAction: ActionCreator<ACTION_TYPES.SHOW_MESSAGE, () => TypedAction<ACTION_TYPES.SHOW_MESSAGE>> = createAction(ACTION_TYPES.SHOW_MESSAGE);

export const resetSelectedAppendAction: ActionCreator<ACTION_TYPES.RESET_SELECTED_APPEND, () => TypedAction<ACTION_TYPES.RESET_SELECTED_APPEND>> = createAction(ACTION_TYPES.RESET_SELECTED_APPEND);

export const checkPhoneAppendsPopUpAction: ActionCreator<ACTION_TYPES.CHECK_PHONE_APPENDS_POP_UP, () => TypedAction<ACTION_TYPES.CHECK_PHONE_APPENDS_POP_UP>> = createAction(ACTION_TYPES.CHECK_PHONE_APPENDS_POP_UP);

export const resetListAppendsAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
