import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';


enum ACTION_TYPES {
  OPEN = '[DASHBOARD VIEWS]: Open search',
  CLOSE = '[DASHBOARD VIEWS]: Close search',

  UPDATE = '[DASHBOARD VIEWS]: update search',
  UPDATE_SUCCESS = '[DASHBOARD VIEWS]: update search success',

  RESET = '[DASHBOARD VIEWS]: reset query',

  RESET_STATE_WITHOUT_RELOAD = '[DASHBOARD VIEWS]: reset views state without reload'
}

export const openDashboardViewsSearchAction: ActionCreator<ACTION_TYPES.OPEN, () => TypedAction<ACTION_TYPES.OPEN>> = createAction(ACTION_TYPES.OPEN);
export const closeDashboardViewsSearchAction: ActionCreator<ACTION_TYPES.CLOSE, () => TypedAction<ACTION_TYPES.CLOSE>> = createAction(ACTION_TYPES.CLOSE);

export const updateDashboardViewsSearchAction: CreateActionType<ACTION_TYPES.UPDATE, string> = createAction(ACTION_TYPES.UPDATE, createPayload<string>());
export const updateDashboardViewSearchSuccessAction: ActionCreator<ACTION_TYPES.UPDATE_SUCCESS, () => TypedAction<ACTION_TYPES.UPDATE_SUCCESS>> = createAction(ACTION_TYPES.UPDATE_SUCCESS);

export const resetDashboardViewsSearchAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);

export const resetDashboardViewsSearchWithoutReloadAction: ActionCreator<ACTION_TYPES.RESET_STATE_WITHOUT_RELOAD, () => TypedAction<ACTION_TYPES.RESET_STATE_WITHOUT_RELOAD>> = createAction(ACTION_TYPES.RESET_STATE_WITHOUT_RELOAD);
