import { ElementRef, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { DocumentRef } from '../refs/document-ref.service';
import { WindowRef } from '../refs/window-ref.service';

import { SCROLL_EVENTS } from '@shared/constants/scroll';

@Injectable({ providedIn: 'root' })
export class LayoutService {

  private _isPopupHeightLessThenScreen$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private _layoutChanged$: Subject<any> = new Subject();
  private _isRootElementFixed: Subject<boolean> = new Subject();
  private _isScrollOverlay$: Subject<boolean> = new Subject();
  private _shouldShowOverlay$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _clickOnDocumentWithStopPropagate: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _popupContent: ElementRef = null;
  private _rememberedScrollOffset: number = 0;

  globalScrollDisabled: boolean = false;

  get layoutHasBeenChanged(): Observable<boolean> {
    return this._layoutChanged$.asObservable();
  }

  get isRootElementFixed(): Observable<boolean> {
    return this._isRootElementFixed.asObservable();
  }

  get isScrollOverlay(): Observable<boolean> {
    return this._isScrollOverlay$.asObservable();
  }

  get isPopupHeightLessThenScreen(): Observable<boolean> {
    return this._isPopupHeightLessThenScreen$.asObservable();
  }

  get isClickOnDocumentWithStopPropagate(): Observable<boolean> {
    return this._clickOnDocumentWithStopPropagate.asObservable();
  }

  set popupContentElement(el: ElementRef) {
    this._popupContent = el;
  }

  get popupContentElement(): ElementRef {
    return this._popupContent;
  }

  get shouldShowOverlay(): Observable<boolean> {
    return this._shouldShowOverlay$.asObservable();
  }

  constructor(private document: DocumentRef,
              private window: WindowRef) {
  }

  setIsScrollOverlay(isScroll: boolean): void {
    this._isScrollOverlay$.next(isScroll);
  }

  triggerLayoutChange(): void {
    this._layoutChanged$.next();
  }

  setIsRootElementFixed(status: boolean): void {
    this._isRootElementFixed.next(status);
  }

  getScrollTop(): number {
    const element: Element = this.document.nativeElement.scrollingElement || this.document.nativeElement.documentElement;
    return element.scrollTop;
  }

  disableGlobalScroll(): void {
    this.globalScrollDisabled = true;
    const overflow: SCROLL_EVENTS = this.document.nativeElement.body.scrollHeight > this.document.nativeElement.body.clientHeight
      ? SCROLL_EVENTS.SCROLL
      : SCROLL_EVENTS.AUTO;
    this._rememberedScrollOffset = this.getScrollTop();

    this.document.nativeElement.body.style.top = `-${ this._rememberedScrollOffset }px`;
    this.document.nativeElement.body.style.overflowY = overflow;
    this.document.nativeElement.body.classList.add('opened');
  }

  enableGlobalScroll(): void {
    this.globalScrollDisabled = false;
    this.document.nativeElement.body.classList.remove('opened');

    if (this._rememberedScrollOffset) {
      this.document.nativeElement.documentElement.scrollTop = this._rememberedScrollOffset || 0;
      this.document.nativeElement.body.scrollTop = this._rememberedScrollOffset || 0;
    }

    this.document.nativeElement.body.style.top = '';
    this.document.nativeElement.body.style.overflowY = '';
    this._rememberedScrollOffset = 0;
  }

  checkPopupHeight(state?: boolean): void {
    if (typeof state !== 'undefined') {
      this._isPopupHeightLessThenScreen$.next(state);
    } else {

      if (!this.popupContentElement) {
        return;
      }

      const { height }: ClientRect = this.popupContentElement.nativeElement.getBoundingClientRect();

      this._isPopupHeightLessThenScreen$.next(height < this.window.nativeElement.innerHeight);
    }
  }

  toggleOverlay(state: boolean): void {
    this._shouldShowOverlay$.next(state);
  }

  onClickDocumentWithStopPropagate(state: boolean): void {
    this._clickOnDocumentWithStopPropagate.next(state);
  }

  triggerDocumentClick(): void {
    const event: MouseEvent = this.document.nativeElement.createEvent('MouseEvent');
    event.initEvent('click', true, true);

    this.document.nativeElement.documentElement.dispatchEvent(event);
  }
}
