import {
  forwardRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { autoscroll } from '@shared/utils/autoscroll';
import { matchKey } from '@shared/utils/keyboard';

import { KEY_CODES } from '@shared/interfaces/keyboard';
import { Mask } from '@shared/interfaces/mask';

import { FLAT_INPUT_THEME, INPUT_AUTOCOMPLETE } from '@shared/constants/flat-input';
import { KEYS } from '@shared/constants/keyboard';
import { SECURITY_TYPE } from '@shared/constants/log-rocket-config';

@Component({
  selector: 'bl-flat-input',
  templateUrl: './flat-input.component.html',
  styleUrls: ['./flat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatInputComponent),
      multi: true,
    }
  ]
})
export class FlatInputComponent implements ControlValueAccessor, OnChanges, AfterViewInit {

  @HostBinding('class.validation-on-dirty')
  get isKeyUpValidation(): boolean {
    return this.validationOnDirty;
  }

  @Input() formControlName: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() autocomplete: INPUT_AUTOCOMPLETE = INPUT_AUTOCOMPLETE.ON;
  @Input() readonly: boolean = false;
  @Input() type: 'text' | 'email' | 'password' = 'text';
  @Input() errors: { [key: string]: any };
  @Input() resetBtn: boolean;
  @Input() mask: Mask;
  @Input() dispatchMaskedValue: boolean = false;
  @Input() customErrors: string;
  @Input() inputLabel: string;
  @Input() theme: FLAT_INPUT_THEME = FLAT_INPUT_THEME.DEFAULT;
  @Input() isAutoFocus: boolean = false;
  @Input() validationOnDirty: boolean = false;
  @Input() shouldHideErrorBox: boolean = false;
  @Input() isDataPrivate: boolean; // by ticket CCP-2558 https://docs.logrocket.com/docs/privacy

  @Output() onInput: EventEmitter<string> = new EventEmitter();
  @Output() focus: EventEmitter<string> = new EventEmitter();
  @Output() blur: EventEmitter<string> = new EventEmitter();
  @Output() submit: EventEmitter<string> = new EventEmitter();
  @Output() onResetValue: EventEmitter<any> = new EventEmitter();
  @Output() onArrowUp: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() onArrowDown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @ViewChild('input', { static: true }) inputEl: ElementRef;
  @ViewChild('label', { static: true }) label: ElementRef;

  isFocused: boolean = false;
  error: string = '';

  get isLabelActive(): boolean {
    return this.isFocused || !!this.maskedValue;
  }

  get valueForDispatch(): string {
    return this.dispatchMaskedValue ? this.maskedValue : this.rawValue;
  }

  get placeHolder(): string {
    if (this.isFocused && this.inputLabel && !!this.inputLabel.length) {
      return this.inputLabel;
    }

    if (this.theme === this.inputTheme.SIMPLE && !!this.placeholder.length) {
      return this.placeholder;
    }

    return '';
  }

  readonly inputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;
  readonly securityType: typeof SECURITY_TYPE = SECURITY_TYPE;

  private rawValue: string = '';
  private maskedValue: string = '';

  private shouldScrollToError: boolean = false;

  private propagateChange: Function;
  private propagateTouch: Function;

  constructor(private renderer: Renderer2,
              private cdr: ChangeDetectorRef,
              private elemRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errors) {
      const errors: any = changes.errors.currentValue;

      if (errors) {
        this.error = errors.required || Object.values(errors)[0];

        if (this.shouldScrollToError) {
          this.scrollToError();
        }

      } else {
        this.error = null;

        if (!changes.errors.firstChange) {
          this.shouldScrollToError = true;
        }
      }

      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }

    if (changes.customErrors) {
      changes.customErrors.currentValue
        ? this.renderer.addClass(this.elemRef.nativeElement, 'has-custom-errors')
        : this.renderer.removeClass(this.elemRef.nativeElement, 'has-custom-errors');
    }
  }

  ngAfterViewInit(): void {
    this.inputEl.nativeElement.addEventListener('animationstart', (e: AnimationEvent) => {
      switch (e.animationName) {
        case 'onAutoFillStart':
          return this.renderer.addClass(this.label.nativeElement, 'autofill');
        case 'onAutoFillCancel':
          return this.renderer.removeClass(this.label.nativeElement, 'autofill');
      }
    });
  }

  // event handlers
  handleBlur(): void {
    if (!this.readonly) {
      this.isFocused = false;
      this.shouldScrollToError = true;

      this.blur.emit(this.valueForDispatch);
      this.propagateTouch();

      this.customErrors = null;
      this.renderer.removeClass(this.elemRef.nativeElement, 'has-custom-errors');
    }
  }

  handleFocus(): void {
    if (!this.readonly) {
      this.isFocused = true;
      this.focus.emit(this.valueForDispatch);
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }
  }

  handleChange(value: string): void {
    this.rawValue = this.mask ? this.mask.parse(value, this.rawValue) : value;
    this.maskedValue = this.mask ? this.mask.transform(this.rawValue) : this.rawValue;

    this.renderer.setProperty(this.inputEl.nativeElement, 'value', this.maskedValue);

    this.propagateChange(this.valueForDispatch);
    this.onInput.emit(this.valueForDispatch);
  }

  onKeyUp(event: KeyboardEvent): void {
    if (matchKey(event, KEYS.ENTER)) {
      this.submit.emit(this.valueForDispatch);
    }
  }

  handlerKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case KEY_CODES.ArrowUp:
        this._handlerArrowUp(event);
        break;
      case KEY_CODES.ArrowDown:
        this._handlerArrowDown(event);
        break;
    }
  }

  resetValue(): void {
    this.onResetValue.emit();
  }

  // value accessors methods
  writeValue(value: any): void {
    if (value !== void (0) && value !== null) {
      this.rawValue = value;

      this.maskedValue = this.mask ? this.mask.transform(this.rawValue) : this.rawValue;
      this.renderer.setProperty(this.inputEl.nativeElement, 'value', this.maskedValue);

      // for isLabelActive be correct on prepopulated password fields
      this.cdr.markForCheck();
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.inputEl.nativeElement, 'disabled', isDisabled);
  }

  triggerFocus(): void {
    (this.inputEl.nativeElement as HTMLElement).focus();
  }

  triggerBlur(): void {
    (this.inputEl.nativeElement as HTMLElement).blur();
  }

  // utils
  private scrollToError(): void {
    const { top }: ClientRect = this.inputEl.nativeElement.getBoundingClientRect();

    if (top < 0) {
      autoscroll(top);
    }

    this.shouldScrollToError = false;
  }

  private _handlerArrowUp(event: KeyboardEvent): void {
    this.onArrowUp.emit(event);
  }

  private _handlerArrowDown(event: KeyboardEvent): void {
    this.onArrowDown.emit(event);
  }
}
