<a class="link"
   (click)="!disabled ? onNavigate.emit() : null"
   [routerLink]="!disabled ? url : null"
   [queryParams]="!disabled ? queryParams : null"
   routerLinkActive="active disable"
   [routerLinkActiveOptions]="{ exact: activeOptionsExact }"
   [class.link--small]="small"
   [class.disabled-link]="disabled"
   [class.active-link]="isActive">
  <span class="label">{{ label }}</span>

  <span *ngIf="!!description && showDescription"
        class="description">
    {{ description }}
  </span>
</a>
