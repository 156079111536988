import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bl-users-search-input',
  templateUrl: './users-search-input.component.html',
  styleUrls: ['./users-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersSearchInputComponent {
  @Input() placeholder: string = 'Search users...';

  @Output() onChangeInput: EventEmitter<string> = new EventEmitter();

  handleInput(target: any): void {
    this.onChangeInput.emit(target.value);
  }

}
