import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { setInvitationTokenAction } from '@modules/auth/store/actions/invitation.action';
import { go } from '../store/actions/router-history.action';
import { CoreState } from '../store/reducers';

import { InvitationService } from '@modules/auth/services/invitation.service';
import { StorageService } from './storage.service';

import { queryParamsToObject } from '@shared/utils/query-params-to-object';

import { IInvitationUser } from '@modules/auth/interfaces/invitation';

import { INVITATION_ACTION } from '@modules/auth/constants/invitation';
import { PROFILE_PATHS } from '@modules/profile/constants/profile-route-paths';
import { CORE_PATHS } from '../constants/core-paths';


@Injectable()
export class AppLoadService {

  constructor(private store: Store<CoreState>,
              private invitationService: InvitationService) {
  }

  initApp(): Promise<any> {
    return new Promise((resolve: Function) => {
      const invitation: any = queryParamsToObject(location.search);

      if (StorageService.token) {

        // if user has invitation token
        if (this._isValidUserAndTokenData(invitation)) {
          const { token, action, email, accountName }: any = invitation;

          if (INVITATION_ACTION.OWNER_KEEP_SEPARATE.toString() === invitation.action.toString()) {
            this.store.dispatch(setInvitationTokenAction({ token, action, email, accountName }));
            this.store.dispatch(go(['/', CORE_PATHS.ACCOUNT, PROFILE_PATHS.PRODUCTS]));
            return resolve();

          } else if (INVITATION_ACTION.OWNER_MERGE.toString() === invitation.action.toString()) {
            this.store.dispatch(setInvitationTokenAction({ email }));
            this.invitationService.sendInvitationWithoutNotification(token, action, resolve);
          } else {
            // if decline or accept or product merge
            this.store.dispatch(setInvitationTokenAction({ email }));
            this.invitationService.sendInvitationWithNotification(token, action, resolve);
          }

        } else {
          return resolve();
        }

      } else {

        if (invitation && invitation.token && invitation.email) {
          const { token, action, email, accountName }: any = invitation;
          this.store.dispatch(setInvitationTokenAction({ token, action, email, accountName }));
        }

        return resolve();
      }
    });
  }

  private _isValidUserAndTokenData(invitation: IInvitationUser): boolean {
    return invitation && invitation.token && invitation.email && StorageService.user && invitation.email === StorageService.user.email;
  }

}
