<ng-container *ngIf="form">
  <form [formGroup]="form"
        (ngSubmit)="submit()"
        class="auth__profile">

    <div class="form__container">

      <bl-flat-input formControlName="email"
                     type="email"
                     placeholder="Email*"
                     class="mb-5"
                     [readonly]="true"
                     [errors]="form.get('email').errors"
                     [theme]="flatInputTheme.MAT_LIKE"
                     (submit)="submit()">
      </bl-flat-input>

      <ng-content></ng-content>

      <bl-flat-input formControlName="firstName"
                     placeholder="First Name*"
                     class="mt-5 mb-5"
                     [errors]="form.get('firstName').errors"
                     [theme]="flatInputTheme.MAT_LIKE"
                     (submit)="submit()">
      </bl-flat-input>

      <bl-flat-input formControlName="lastName"
                     placeholder="Last Name*"
                     class="mb-5"
                     [errors]="form.get('lastName').errors"
                     [theme]="flatInputTheme.MAT_LIKE"
                     (submit)="submit()">
      </bl-flat-input>

      <bl-flat-input formControlName="company"
                     placeholder="Company*"
                     class="mb-5"
                     [readonly]="role !== rolesKeys.Owner"
                     [errors]="form.get('company').errors"
                     [theme]="flatInputTheme.MAT_LIKE"
                     (submit)="submit()">
      </bl-flat-input>

      <!-- address section start -->
      <bl-address-form [formGroup]="address"
                       [countries]="countries"
                       [countryStates]="countryStates"
                       (countryChanged)="onCountryChange()">
      </bl-address-form><!-- address section finish -->

      <bl-flat-input formControlName="phone"
                     placeholder="Telephone Number*"
                     class="mb-5"
                     [isDataPrivate]="true"
                     [errors]="form.get('phone').errors"
                     [mask]="phoneMask"
                     [theme]="flatInputTheme.MAT_LIKE"
                     (submit)="submit()">
      </bl-flat-input>

    </div>

  </form>
</ng-container>

<div class="footer">
  <!-- sticky footer -->
  <bl-sticky-element [type]=stickyTypes.Footer>

    <div *ngIf="shouldShowPasswordForm; else userFormTemplate"
         class="footer__content"
         e2e="btn-group">
      <!-- change password footer -->
      <button type="button"
              class="btn btn--delete"
              (click)="closeChangePasswordForm()"
              matRipple>
        Cancel
      </button>

      <button type="button"
              [blBtnSpinner]="changePasswordLoading"
              [disabled]="changePasswordDisabled"
              (click)="changePassword()"
              class="btn btn--primary"
              data-testid="change-pass-btn"
              matRipple>
        Save
      </button><!-- change password footer -->
    </div>

  </bl-sticky-element><!-- / sticky footer -->
</div>

<!-- user footer -->
<ng-template #userFormTemplate>
  <div class="footer__content"
       e2e="btn-group">
    <button type="button"
            class="btn btn--delete"
            (click)="resetForm()"
            matRipple>
      Cancel
    </button>

    <button type="button"
            [blBtnSpinner]="loading"
            [disabled]="form.invalid || !shouldShowSave"
            (click)="submit()"
            class="btn btn--primary"
            data-testid="change-pass-btn"
            matRipple>
      Save
    </button>
  </div>
</ng-template><!-- user footer -->
