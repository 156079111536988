import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import { getCategoriesAction, getGroupsAction } from '../actions/bid-categories.action';
import * as actions from '../actions/segment.action';

import { createSegmentByDataItem, getSegmentByItemsCountBySimpleData, getSegmentByItemsFromDetailedData } from '@shared/utils/segment-by';

import { IListData } from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';
import { IViewData } from '@shared/interfaces/view';
import { IGetBidEntityPayload } from '../../interfaces/bid-categories';
import { ENTITY_TYPE, SEGMENT_BY_PERSONNEL_ENTITY_TYPE } from '../../interfaces/browse';
import {
  IGeographyTargeting,
  IInstitutionTargeting,
  IPersonnelTargeting,
  IRFPAndIFBTargeting,
  ISegmentByParams,
  ISegmentData,
  ISegmentTargetingCriteria,
  ITargeting
} from '../../interfaces/segment';
import { IPIDCustomLists } from '../../interfaces/targeting-forms';

import { ISegmentByItems, SegmentByItemsCollection } from '../../models/segment-by';

import { SEGMENT_BY_ITEMS_NAME, TARGETING_TYPES_DESCRIPTION, TARGETING_TYPES_ID } from '@shared/constants/data/list-types';

import { ITargetingCriteriaItem, } from '@shared/modules/targeting-criteria-controls/data';

export interface ISegmentState {
  segment: ISegmentData | null;
  segmentLoading: boolean;

  lastOpenedSegmentId: number | null;
  openedTargetingCriteriaIds: number[];
  segmentPending: boolean;
  segmentError: IServerError | null;
}

const initialState: ISegmentState = {
  segment: null,
  segmentLoading: false,

  lastOpenedSegmentId: null,
  openedTargetingCriteriaIds: [],
  segmentPending: false,
  segmentError: null,
};

export const getSegmentTargeting: OnReducer<ISegmentState, ActionType<any>> = (state: ISegmentState) => ({
  ...state,
  segmentLoading: true,
  segmentError: null
});

export const getSegmentTargetingError: OnReducer<ISegmentState, ActionType<Payload<IServerError>>> = (state: ISegmentState, { payload }: Payload<IServerError>) => ({
  ...state,
  segmentLoading: false,
  segmentError: { ...payload }
});

export const getSegmentTargetingSuccess: OnReducer<ISegmentState, ActionType<Payload<any>>> = (state: ISegmentState, { payload }: Payload<any>) => ({
  ...state,
  segmentLoading: false,
  segment: { ...payload }
});

export const reloadSegmentTargeting: OnReducer<ISegmentState, ActionType<any>> = (state: ISegmentState) => ({
  ...state,
  segmentPending: true,
  segmentError: null
});

export const reloadSegmentTargetingError: OnReducer<ISegmentState, ActionType<Payload<IServerError>>> = (state: ISegmentState, { payload }: Payload<IServerError>) => ({
  ...state,
  segmentPending: false,
  segmentError: { ...payload }
});

export const reloadSegmentTargetingSuccess: OnReducer<ISegmentState, ActionType<Payload<any>>> = (state: ISegmentState, { payload }: Payload<any>) => ({
  ...state,
  segmentPending: false,
  segment: { ...payload }
});

const clearTargeting: OnReducer<ISegmentState, ActionType<any>> = (state: ISegmentState) => ({
  ...state,
  segmentPending: true
});

const clearTargetingError: OnReducer<ISegmentState, ActionType<Payload<IServerError>>> = (state: ISegmentState, { payload }: Payload<IServerError>) => ({
  ...state,
  segmentPending: false,
  segmentError: { ...payload },
});

const clearTargetingSuccess: OnReducer<ISegmentState, ActionType<any>> = (state: ISegmentState) => ({
  ...state,
  segmentPending: false,
  segmentError: null,
});

const toggleTargeting: OnReducer<ISegmentState, ActionType<Payload<any>>> = (state: ISegmentState, { payload }: Payload<any>) => ({
  ...state,
  openedTargetingCriteriaIds: state.openedTargetingCriteriaIds.includes(payload.targetingId)
    ? state.openedTargetingCriteriaIds.filter((_segmentCriteriaId: number) => _segmentCriteriaId !== payload.targetingId)
    : [...state.openedTargetingCriteriaIds, payload.targetingId]
});

const resetOpenedTargetings: OnReducer<ISegmentState, ActionType<Payload<any>>> = (state: ISegmentState, { payload }: Payload<any>) => ({
  ...state,
  openedTargetingCriteriaIds: payload ?
    state.openedTargetingCriteriaIds.filter((_segmentCriteriaId: number) => _segmentCriteriaId === payload)
    : []
});

const segmentChangesStart: OnReducer<ISegmentState, ActionType<any>> = (state: ISegmentState) => ({
  ...state,
  segmentPending: true
});

const segmentChangesEnd: OnReducer<ISegmentState, ActionType<any>> = (state: ISegmentState) => ({
  ...state,
  segmentPending: false
});

const updateOpenedSegmentId: OnReducer<ISegmentState, ActionType<Payload<any>>> = (state: ISegmentState, { payload }: Payload<any>) => ({
  ...state,
  lastOpenedSegmentId: payload
});

const resetOpenedSegmentId: OnReducer<ISegmentState, ActionType<any>> = (state: ISegmentState) => ({
  ...state,
  lastOpenedSegmentId: null
});

const reducer: ActionReducer<ISegmentState> = createReducer<ISegmentState>(
  initialState,

  on(actions.getSegmentTargetingAction, getSegmentTargeting),
  on(actions.getSegmentTargetingErrorAction, getSegmentTargetingError),
  on(actions.getSegmentTargetingSuccessAction, getSegmentTargetingSuccess),

  on(actions.reloadSegmentTargetingAction, reloadSegmentTargeting),
  on(actions.reloadSegmentTargetingErrorAction, reloadSegmentTargetingError),
  on(actions.reloadSegmentTargetingSuccessAction, reloadSegmentTargetingSuccess),

  on(actions.clearTargetingAction, clearTargeting),
  on(actions.clearTargetingErrorAction, clearTargetingError),
  on(actions.clearTargetingSuccessAction, clearTargetingSuccess),

  on(actions.toggleTargetingAction, toggleTargeting),

  on(actions.resetOpenedTargetingsAction, resetOpenedTargetings),

  on(actions.segmentChangesStartAction, segmentChangesStart),
  on(actions.segmentChangesEndAction, segmentChangesEnd),
  on(actions.segmentChangesResetAction, segmentChangesEnd),

  on(actions.updateOpenedSegmentIdAction, updateOpenedSegmentId),
  on(actions.resetOpenedSegmentIdAction, resetOpenedSegmentId)
);

export function segmentReducer(state: ISegmentState,
                               action: Action): ISegmentState {
  return reducer(state, action);
}

export const segmentLoading: GetFromState<boolean, ISegmentState> = (state: ISegmentState): boolean => state && state.segmentLoading;
export const segmentPending: GetFromState<boolean, ISegmentState> = (state: ISegmentState): boolean => state && state.segmentPending;

export const openedTargetingIds: GetFromState<number[], ISegmentState, number[]> = (state: ISegmentState, _disabledIds: number[]): number[] => state.openedTargetingCriteriaIds
  .filter((id: number) => !_disabledIds.includes(id));

export const activeSegmentId: GetFromState<number, ISegmentState, { segmentId: number }> = (state: ISegmentState, { segmentId }: { segmentId: number }): number | null => state
  && +state.lastOpenedSegmentId || +segmentId || null;

export const targetingCriteria: GetFromState<ISegmentTargetingCriteria | null, ISegmentData> = (data: ISegmentData): ISegmentTargetingCriteria | null => {
  return data ? data.targetingCriteria : null;
};

export const entitySegmentData: GetFromState<ISegmentData, ISegmentState, IListData | IViewData, number> = (state: ISegmentState,
                                  entity: IListData | IViewData | null,
                                  segmentId: number | null): ISegmentData | null => {
  let segment: ISegmentData;

  if (entity && state && state.segment && state.segment.targetingCriteria) {
    segment = segmentId ? entity.segments.find((_segment: ISegmentData) => _segment.id === +segmentId) : entity.segments[0] || null;

    if (segment) {
      return { ...segment, targetingCriteria: state.segment.targetingCriteria };
    }
  }

  return null;
};

export const activeSegment: GetFromState<ISegmentData, IListData | IViewData, number> = (entity: IListData | IViewData | null,
                              segmentId: number | null): ISegmentData | null => {
  if (entity) {
    return segmentId ? entity.segments.find((_segment: ISegmentData) => _segment.id === +segmentId) : entity.segments[0] || null;
  }
};

export const bidTargetingCriteria: GetFromState<IRFPAndIFBTargeting, ISegmentTargetingCriteria> = (_targetingCriteria: ISegmentTargetingCriteria): IRFPAndIFBTargeting => {
  return _targetingCriteria
    && _targetingCriteria.find((item: ITargeting) => item.data.targetingId === TARGETING_TYPES_ID.RFP_IFB) as IRFPAndIFBTargeting;
};

export const geographyTargetingCriteria: GetFromState<IGeographyTargeting, ISegmentTargetingCriteria> = (
  _targetingCriteria: ISegmentTargetingCriteria
): IGeographyTargeting => _targetingCriteria
  && _targetingCriteria.find((item: ITargeting) => item.data.targetingId === TARGETING_TYPES_ID.GEOGRAPHY) as IGeographyTargeting;

export const institutionTargeting: GetFromState<IInstitutionTargeting, ISegmentTargetingCriteria> = (_targetingCriteria: ISegmentTargetingCriteria): IInstitutionTargeting => _targetingCriteria
  && _targetingCriteria.find((item: ITargeting) =>
    item.data.targetingId === TARGETING_TYPES_ID.INSTITUTION) as IInstitutionTargeting;

export const personnelTargeting: GetFromState<IPersonnelTargeting, ISegmentTargetingCriteria> = (_targetingCriteria: ISegmentTargetingCriteria): IPersonnelTargeting => _targetingCriteria
  && _targetingCriteria.find((item: ITargeting) => item.data.targetingId === TARGETING_TYPES_ID.PERSONNEL) as IPersonnelTargeting;

export const pidsTargetingCriteria: GetFromState<IPersonnelTargeting, ISegmentTargetingCriteria> = (
  _targetingCriteria: ISegmentTargetingCriteria): IPersonnelTargeting => {
  return _targetingCriteria
    && _targetingCriteria.find((item: ITargeting) => item.data.targetingId === TARGETING_TYPES_ID.PIDs) as any;
};

export const pidTargetingDataCustomLists: GetFromState<IPIDCustomLists, any> = (pidTargetingCriteria: any): IPIDCustomLists => {
  return pidTargetingCriteria && pidTargetingCriteria.data && pidTargetingCriteria.data.data && pidTargetingCriteria.data.data.customLists;
};

export const bidTargetingSegmentCriteriaId: GetFromState<number, IRFPAndIFBTargeting> = (_targetingCriteria: IRFPAndIFBTargeting): number => {
  return _targetingCriteria && _targetingCriteria.segmentCriteriaId;
};

export const actionsForLoadBidCategory: GetFromState<IGetBidEntityPayload, number, number>
  = (_segmentCriteriaId: number, openedGroupId: number): IGetBidEntityPayload | null => {
  if (typeof _segmentCriteriaId !== 'number') {
    return null;
  }

  let _actions: IGetBidEntityPayload = [
    getGroupsAction({
      segmentCriteriaId: _segmentCriteriaId,
      entityType: ENTITY_TYPE.GROUP,
      reset: false
    })
  ];

  if (typeof openedGroupId === 'number') {
    _actions = [..._actions, getCategoriesAction({
      segmentCriteriaId: _segmentCriteriaId,
      groupId: openedGroupId,
      reset: false
    })];
  }

  return _actions;
};

export const isGeographyTargetingRadiusAvailable: GetFromState<boolean, boolean, IGeographyTargeting, IGeographyTargeting> = (isView: boolean, listGeoTargeting: IGeographyTargeting, viewGeoTargeting: IGeographyTargeting): boolean => isView
  ? viewGeoTargeting && viewGeoTargeting.data && viewGeoTargeting.data.isRadiusAvailable
  : listGeoTargeting && listGeoTargeting.data && listGeoTargeting.data.isRadiusAvailable;

export const segmentByFromInstitutionTargeting: GetFromState<ISegmentByItems, IInstitutionTargeting> = (targeting: IInstitutionTargeting): ISegmentByItems => {
  if (!targeting) {
    return undefined;
  }

  const { data: { data: _targetingCriteria } }: IInstitutionTargeting = targeting;
  let items: ISegmentByItems = [];

  _targetingCriteria.forEach((_targetingCriteriaItem: ITargetingCriteriaItem) => {

    const selectedCount: number = getSegmentByItemsCountBySimpleData(_targetingCriteriaItem.data);

    if (selectedCount > 1) {
      items = [
        ...items,
        createSegmentByDataItem(TARGETING_TYPES_ID.INSTITUTION,
          selectedCount, _targetingCriteriaItem)
      ];
    }

    if (_targetingCriteriaItem.detailedTargetingData) {
      items = [
        ...items,
        ...getSegmentByItemsFromDetailedData(
          TARGETING_TYPES_ID.INSTITUTION,
          _targetingCriteriaItem.targetingControlId,
          _targetingCriteriaItem.detailedTargetingData
        )];
    }
  });

  return items;
};

export const segmentByFromPersonnelTargeting: GetFromState<ISegmentByItems, IPersonnelTargeting> = (targeting: IPersonnelTargeting): ISegmentByItems => {
  if (!targeting) {
    return undefined;
  }

  const {
    data: {
      data: {
        total,
        data,
        detailedData,
        segmentByParams
      }
    }
  }: IPersonnelTargeting = targeting;

  let items: ISegmentByItems = [];

  if (segmentByParams) {

    const {
      segmentByCategoryAvailable,
      segmentByDisciplineAvailable,
      segmentBySubjectAvailable
    }: ISegmentByParams = segmentByParams;

    if (segmentByDisciplineAvailable) {
      items = [
        ...items,
        createSegmentByDataItem(
          TARGETING_TYPES_ID.PERSONNEL,
          total,
          { title: SEGMENT_BY_ITEMS_NAME[SEGMENT_BY_PERSONNEL_ENTITY_TYPE.DISCIPLINE] },
          SEGMENT_BY_PERSONNEL_ENTITY_TYPE.DISCIPLINE
        )
      ];
    }

    if (segmentByCategoryAvailable) {
      items = [
        ...items,
        createSegmentByDataItem(
          TARGETING_TYPES_ID.PERSONNEL,
          total,
          { title: SEGMENT_BY_ITEMS_NAME[SEGMENT_BY_PERSONNEL_ENTITY_TYPE.CATEGORY] },
          SEGMENT_BY_PERSONNEL_ENTITY_TYPE.CATEGORY
        )
      ];
    }

    if (segmentBySubjectAvailable) {
      items = [
        ...items,
        createSegmentByDataItem(
          TARGETING_TYPES_ID.PERSONNEL,
          total,
          { title: SEGMENT_BY_ITEMS_NAME[SEGMENT_BY_PERSONNEL_ENTITY_TYPE.SUBJECT] },
          SEGMENT_BY_PERSONNEL_ENTITY_TYPE.SUBJECT
        )
      ];
    }
  }

  // TODO remove as any and change type - bug on API side should be SEGMENT_BY_PERSONNEL_ENTITY_TYPE.JOB
  if (total > 1 || (total === 1 && (data[0].type as any) !== SEGMENT_BY_PERSONNEL_ENTITY_TYPE.DISCIPLINE)) {
    items = [
      ...items,
      createSegmentByDataItem(
        TARGETING_TYPES_ID.PERSONNEL,
        total,
        { title: SEGMENT_BY_ITEMS_NAME[SEGMENT_BY_PERSONNEL_ENTITY_TYPE.JOB] },
        SEGMENT_BY_PERSONNEL_ENTITY_TYPE.JOB
      )
    ];
  }

  detailedData.forEach(({ targetingControlId, detailedTargetingData }: ITargetingCriteriaItem) => {
    if (detailedTargetingData) {
      items = [
        ...items,
        ...getSegmentByItemsFromDetailedData(TARGETING_TYPES_ID.PERSONNEL, targetingControlId, detailedTargetingData)];
    }
  });

  return items;
};


export const segmentByItemsFromGeographyTargeting: GetFromState<ISegmentByItems, IGeographyTargeting> = (_targeting: IGeographyTargeting): ISegmentByItems => {
  if (!_targeting) {
    return undefined;
  }

  const { data: { data: { total, detailedData: _targetingCriteria } } }: IGeographyTargeting = _targeting;
  let items: ISegmentByItems = [];


  if (total > 1) {
    items = [
      ...items,
      createSegmentByDataItem(
        TARGETING_TYPES_ID.GEOGRAPHY,
        total,
        { title: SEGMENT_BY_ITEMS_NAME.GEOGRAPHY }
      )
    ];
  }

  _targetingCriteria.forEach(({ targetingControlId, detailedTargetingData }: ITargetingCriteriaItem) => {
    if (detailedTargetingData) {
      items = [
        ...items,
        ...getSegmentByItemsFromDetailedData(TARGETING_TYPES_ID.GEOGRAPHY, targetingControlId, detailedTargetingData)];
    }
  });

  return items;
};

export const segmentByFromAllAvailableTargeting: GetFromState<SegmentByItemsCollection, ISegmentByItems, ISegmentByItems, ISegmentByItems> = (segmentByItemsFromInstitution: ISegmentByItems = [],
                                                   segmentByItemsFromPersonnel: ISegmentByItems = [],
                                                   segmentByItemsFromGeography: ISegmentByItems = []): SegmentByItemsCollection => {
  return {
    [TARGETING_TYPES_DESCRIPTION.INSTITUTION]: segmentByItemsFromInstitution,
    [TARGETING_TYPES_DESCRIPTION.PERSONNEL]: segmentByItemsFromPersonnel,
    [TARGETING_TYPES_DESCRIPTION.GEOGRAPHY]: segmentByItemsFromGeography
  };
};

export const isViewOrCustomListPage: GetFromState<boolean, boolean, boolean> = (isViewPage: boolean, isCustomListPage: boolean): boolean => (isViewPage || isCustomListPage);
