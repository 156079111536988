import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FOOTER_COPYRIGHT_TEXT } from '../../constants/copyright';


@Component({
  selector: 'bl-footer-copyright',
  templateUrl: './footer-copyright.component.html',
  styleUrls: ['./footer-copyright.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterCopyrightComponent {
  public readonly footerCopyrightText: string = FOOTER_COPYRIGHT_TEXT;
}
