import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { hideNotificationAction } from '../../store/actions/notification.action';
import { getNotifications } from '../../store/selectors/notifications.selector';

import { ViewPointsService } from '@ui/view-points/services/view-points.service';

import { IHideNotification, INotification, INotifications } from '@core/interfaces/notifications';

import { WINDOW_POINTS } from '@ui/view-points/constants/view-points';

@Component({
  selector: 'bl-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class NotificationContainerComponent implements OnInit, OnDestroy {

  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;

  private _destroyer$: Subject<any> = new Subject();

  notifications$: Observable<INotifications> = this._store.pipe(select(getNotifications));

  isMobile: boolean = false;

  constructor(private _store: Store<CoreState>,
              private _viewPointsService: ViewPointsService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this._subscribeWindowResize();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  hideNotification(notificationId: IHideNotification): void {
    this._store.dispatch(hideNotificationAction(notificationId));
  }

  trackById(index: number, value: INotification): IHideNotification {
    return value.id;
  }

  private _subscribeWindowResize(): void {
    this._viewPointsService.listenerOnHideByPoints(this.windowPoints.PHONE, this.windowPoints.TABLET)
      .pipe(takeUntil(this._destroyer$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
        this._cdr.markForCheck();
      });
  }
}
