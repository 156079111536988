import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/account-pop-ups.action';


export interface IAccountPopUpsState {
  isOpenAssignStateInfo: boolean;
  isOpenAssignAddOnsInfo: boolean;
}

const initialState: IAccountPopUpsState = {
  isOpenAssignStateInfo: false,
  isOpenAssignAddOnsInfo: false
};

const openAssignStateInfoPopUpHandler: OnReducer<IAccountPopUpsState, ActionType<any>> = (state: IAccountPopUpsState) => ({
  ...state,
  isOpenAssignStateInfo: true
});

const closeAssignStateInfoPopUpHandler: OnReducer<IAccountPopUpsState, ActionType<any>> = (state: IAccountPopUpsState) => ({
  ...state,
  isOpenAssignStateInfo: false
});

const openAssignAddOnInfoPopUpHandler: OnReducer<IAccountPopUpsState, ActionType<any>> = (state: IAccountPopUpsState) => ({
  ...state,
  isOpenAssignAddOnsInfo: true
});

const closeAssignAddOnInfoPopUpHandler: OnReducer<IAccountPopUpsState, ActionType<any>> = (state: IAccountPopUpsState) => ({
  ...state,
  isOpenAssignAddOnsInfo: false
});

const resetStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<IAccountPopUpsState> = createReducer<IAccountPopUpsState>(
  initialState,

  on(actions.openAssignStateInfoPopUp, openAssignStateInfoPopUpHandler),
  on(actions.closeAssignStateInfoPopUp, closeAssignStateInfoPopUpHandler),

  on(actions.openAssignAddOnInfoPopUp, openAssignAddOnInfoPopUpHandler),
  on(actions.closeAssignAddOnInfoPopUp, closeAssignAddOnInfoPopUpHandler),

  on(actions.resetAccountPopUpsState, resetStateHandler)
);

export const isOpenAssignStateInfoPopUp: GetFromState<boolean, IAccountPopUpsState> = (state: IAccountPopUpsState): boolean => state.isOpenAssignStateInfo;
export const isOpenAssignAddOnInfoPopUp: GetFromState<boolean, IAccountPopUpsState> = (state: IAccountPopUpsState): boolean => state.isOpenAssignAddOnsInfo;


export function accountPopUpsReducer(state: IAccountPopUpsState, action: Action): IAccountPopUpsState {
  return reducer(state, action);
}

