import { AbstractControl, ValidatorFn } from '@angular/forms';

import { VALIDATION_REG_EXP } from '../constants/validators/validation-reg-exp';

export default function password(msg?: string): ValidatorFn {

  return (control: AbstractControl) => {
    const message: string = msg || 'Password is not valid';

    return VALIDATION_REG_EXP.password.test(control.value)
      ? null
      : { password: message };
  };
}
