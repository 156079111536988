// For Inject template to component;
import { ComponentRef, InjectionToken } from '@angular/core';

import { MobilePortalComponent } from '../components/mobile-protal/mobile-portal.component';

export abstract class AnimationState {
}

export const ANIMATION_START: InjectionToken<() => void> = new InjectionToken<() => void>('ANIMATION_START');
export const ANIMATION_END: InjectionToken<() => void> = new InjectionToken<() => void>('ANIMATION_END');
export const MOBILE_DROPDOWN_TEMPLATE_TOKEN: InjectionToken<string> = new InjectionToken<string>('MOBILE_DROPDOWN_TEMPLATE_TOKEN');

// params
export enum CONTENT_POSITION {
  INITIAL = 'initial',

  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

export class MobileDropDownParams {
  position?: CONTENT_POSITION[];
  animationPosition?: CONTENT_POSITION;

  fullSizeHeight?: boolean;
  fullSizeWidth?: boolean;

  shadow?: boolean;
  withOverlay?: boolean;

  onAnimationStart?: () => void;
  onAnimationEnd?: () => void;

  scrollOnToggle?: boolean;
}

// default params
export const DEFAULT_MOBILE_DROP_DOWN_PARAMS: MobileDropDownParams = {
  position: [CONTENT_POSITION.BOTTOM, CONTENT_POSITION.LEFT],
  animationPosition: CONTENT_POSITION.BOTTOM,

  fullSizeHeight: false,
  fullSizeWidth: true,

  shadow: true,

  withOverlay: true,

  onAnimationStart: () => null,
  onAnimationEnd: () => null,

  scrollOnToggle: false
};

// for styles
export enum MOBILE_DROP_DOWN_CLASSES {
  SHADOW = 'mobile-drop-down--shadow',

  POSITION_TOP = 'mobile-drop-down--position-top',
  POSITION_RIGHT = 'mobile-drop-down--position-right',
  POSITION_BOTTOM = 'mobile-drop-down--position-bottom',
  POSITION_LEFT = 'mobile-drop-down--position-left',

  FULL_SIZE_HEIGHT = 'mobile-drop-down--full-size-height',
  FULL_SIZE_WIDTH = 'mobile-drop-down--full-size-width'
}

// menu types
export enum MOBILE_DROPDOWN_MENU_TYPES {
  SYNC_MENU = 'syncMenu'
}

export const PARAMS_CLASSES: any = {
  shadow: {
    true: MOBILE_DROP_DOWN_CLASSES.SHADOW
  },

  position: {
    [CONTENT_POSITION.TOP]: MOBILE_DROP_DOWN_CLASSES.POSITION_TOP,
    [CONTENT_POSITION.RIGHT]: MOBILE_DROP_DOWN_CLASSES.POSITION_RIGHT,
    [CONTENT_POSITION.BOTTOM]: MOBILE_DROP_DOWN_CLASSES.POSITION_BOTTOM,
    [CONTENT_POSITION.LEFT]: MOBILE_DROP_DOWN_CLASSES.POSITION_LEFT,
  },

  fullSizeHeight: {
    true: MOBILE_DROP_DOWN_CLASSES.FULL_SIZE_HEIGHT
  },
  fullSizeWidth: {
    true: MOBILE_DROP_DOWN_CLASSES.FULL_SIZE_WIDTH
  }
};

export type IMobileDropDownPortalClasses = string[];

export interface IDetachMobileContent {
  componentRef: ComponentRef<MobilePortalComponent>;
  detach: () => void;
}
