<bl-side-nav-container [class.container--fixed]="isOpen">
  <bl-side-nav [isOpen]="isOpen"
               (close)="closeMenuMobile()">
    <ng-content select=".sidenav"></ng-content>
  </bl-side-nav>

  <bl-side-nav-content>
    <ng-content select=".sidenav-content"></ng-content>
  </bl-side-nav-content>
</bl-side-nav-container>

