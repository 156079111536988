<div class="align-self-center">
  <span class="users__title">USERS</span>

  <button type="button"
          (click)="openAddUserPopUp()"
          class="btn--link"
          data-testid="add-user-btn">
    <i class="icon-circle-plus"></i>
    <span class="btn--link-name">Add</span>
  </button>
</div>

<div class="align-self-center users__search-wrap">
  <bl-users-search-input (onChangeInput)="handleInput($event)"></bl-users-search-input>
</div>
