import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface ICustomEvent<T> {
  name: string;
  data?: T;
}

@Injectable({ providedIn: 'root' })
export class CustomEmitter {
  private _emitter$: BehaviorSubject<ICustomEvent<unknown>> = new BehaviorSubject(null);

  public get emitter(): Observable<ICustomEvent<unknown>> {
    return this._emitter$.asObservable();
  }

  public on<T>(...emitNames: string[]): Observable<ICustomEvent<T>> {
    return this.emitter.pipe(
      filter((event: ICustomEvent<unknown>) => !!event),
      filter(({ name }: ICustomEvent<T>) => emitNames.includes(name))
    );
  }

  public emit<T>(name: string, data?: T): void {
    this._emitter$.next({ name, data } as ICustomEvent<T>);
  }
}
