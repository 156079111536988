import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Action, Store } from '@ngrx/store';

import { defer, of, Observable } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { ActionWithPayload } from '@shared/interfaces/store';
import { getCreditsAction } from '../../../credits/store/actions';
import { getAccountUsersAction } from '../actions/account-users.action';
import { getAccountSeatsAction } from '../actions/account.action';
import * as editUserAccountActions from '../actions/edit-user-account.action';
import { getProfileAction } from '../actions/profile.action';
import { getEditUserPopUpPayload } from '../selectors/edit-user-account.selector';

import { EditUserAccountPopUpComponent } from '@ui/pop-up/components/edit-user-account-pop-up/edit-user-account-pop-up.component';

import { PopUpService } from '@ui/pop-up/services/pop-up/pop-up.service';
import { AccountUsersService } from '../../services/account-users.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { IServerError } from '@shared/interfaces/server-error';
import { IEditUserAccountPopUpData } from '@ui/pop-up/interfaces';
import { IEditAccountUser, IEditUserFormForRequest, IEditUserPopUpPayload } from '../../interfaces/marketview';


@Injectable()
export class EditUserAccountEffect {

  constructor(private _actions$: Actions,
              private _accountUsersService: AccountUsersService,
              private _popUpService: PopUpService,
              private _store: Store<CoreState>) {
  }

  editAccountUser$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(editUserAccountActions.editAccountUserAction),
      switchMap(({ payload: newAccountUser }: ActionWithPayload<IEditUserFormForRequest>) => this._accountUsersService
        .editUser(newAccountUser)
        .pipe(
          map(() => editUserAccountActions.editAccountUserSuccessAction()),
          catchError((error: IServerError) => of(editUserAccountActions.editAccountUserErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  editAddedSuccessfully$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(editUserAccountActions.editAccountUserSuccessAction),
      switchMap(() => [
        getAccountUsersAction(),
        getAccountSeatsAction(),
        editUserAccountActions.closeEditUserPopUpAction(),
        getCreditsAction()
      ])
    ));

  editSelfUserAccount$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(editUserAccountActions.editSelfUserAccountAction),
      switchMap(({ payload: newAccountUser }: ActionWithPayload<IEditUserFormForRequest>) => this._accountUsersService
        .editUser(newAccountUser)
        .pipe(
          map(() => editUserAccountActions.editSelfUserAccountSuccessAction()),
          catchError((error: IServerError) => of(editUserAccountActions.editSelfUserAccountErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  editSelfUserSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(editUserAccountActions.editSelfUserAccountSuccessAction),
      switchMap(() => [
        getProfileAction(),
        getAccountSeatsAction(),
        getAccountUsersAction(),
        editUserAccountActions.closeEditUserPopUpAction()
      ])
    ));

  resendInvitation$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(editUserAccountActions.resendInvitationAction),
      switchMap(({ payload: id }: ActionWithPayload<number>) => this._accountUsersService
        .resendInvitation(id)
        .pipe(
          map(() => editUserAccountActions.resendInvitationSuccessAction()),
          catchError((error: IServerError) => of(editUserAccountActions.resendInvitationErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  openEditUserPopUp$: Observable<unknown> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(editUserAccountActions.openEditUserPopUpAction),
      withLatestFrom(this._store.pipe(select(getEditUserPopUpPayload))),
      tap(([{ payload }, editUserPopUpPayload]: [ActionWithPayload<IEditAccountUser>, IEditUserPopUpPayload]) =>
        this._popUpService.open<EditUserAccountPopUpComponent, IEditUserAccountPopUpData>(EditUserAccountPopUpComponent, {
          data: {
            title: 'Edit User',
            user: { ...payload },
            ...editUserPopUpPayload
          },
          withOverlayBackground: true,
        })
      )
    )), { dispatch: false });

  closePopUps$: Observable<Action> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(editUserAccountActions.closeEditUserPopUpAction),
      tap(() => this._popUpService.close()),
    )), { dispatch: false });

}
