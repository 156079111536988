import { createAction } from '@ngrx/store';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IPidTargetingTab } from '../../interfaces/pids';

enum ACTION_TYPES {
  SELECT = '[PIDs TARGETING TABS] Select tab'
}

export const selectPidTargetingTabAction: CreateActionType<ACTION_TYPES.SELECT, IPidTargetingTab> = createAction(ACTION_TYPES.SELECT, createPayload<IPidTargetingTab>());
