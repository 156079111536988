import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { autoscroll as scrollToTop } from '@shared/utils/autoscroll';

@Component({
  selector: 'bl-mobile-table-pagination',
  templateUrl: './mobile-table-pagination.component.html',
  styleUrls: ['./mobile-table-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTablePaginationComponent {
  @Input() loading: boolean = false;
  @Input() scrolled: boolean = false;
  @Input() currentPage: number;
  @Input() count: number;
  @Input() pageCounter: number;

  @Output() changePage: EventEmitter<number> = new EventEmitter<number>();

  get pagesCounter(): number {
    return Math.ceil(+this.count / +this.pageCounter) || 1;
  }

  onPageIncrement(): void {
    this.changePage.emit(+this.currentPage + 1);
    this.scrolledCheck();
  }

  onPageDecrement(): void {
    this.changePage.emit(this.currentPage - 1);
    this.scrolledCheck();
  }

  scrolledCheck(): void {
    if (this.scrolled) {
      scrollToTop(0, 'smooth');
    }
  }
}
