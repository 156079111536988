<div [ngClass]="containerClasses()"
     (click)="$event.stopPropagation()"
     #container>

  <ng-container [ngSwitch]="theme">
    <bl-flat-input *ngSwitchCase="'profile'"
                   [name]="name"
                   [placeholder]="placeholder"
                   [(ngModel)]="inputValue"
                   (ngModelChange)='modelChange($event)'
                   (focus)="openMenu()"
                   [required]="!!shouldSaveValue"
                   [errors]="shouldSaveValue"
                   [customErrors]="customError"
                   [readonly]="readonly"
                   [resetBtn]="resetBtn"
                   (onResetValue)="resetVal()"
                   (onArrowUp)="onKeyboardEvents($event)"
                   (onArrowDown)="onKeyboardEvents($event)"
                   (submit)="onByEnter()"
                   [autocomplete]="autoCompleteParams.OFF"
                   type="text">
    </bl-flat-input>

    <input *ngSwitchCase="'targeting'"
           class="input"
           [class.with-search-icon]="withSearchIcon"
           [blAutofocus]="isAutoFocus"
           [placeholder]="placeholder"
           [(ngModel)]="inputValue"
           (ngModelChange)='modelChange($event)'
           [name]="name"
           [attr.data-testid]="e2eLabel"
           (focus)="onInputFocus($event)"
           (focusout)="onInputFocusOut($event)"
           (keydown.enter)="onByEnter()"
           (keydown)="onKeyboardEvents($event)"
           data-testid="quick-search-input"/>

    <input *ngSwitchCase="'quick-search'"
           class="input"
           [class.with-search-icon]="withSearchIcon"
           [blAutofocus]="isAutoFocus"
           [placeholder]="placeholder"
           [(ngModel)]="inputValue"
           (ngModelChange)='modelChange($event)'
           [name]="name"
           [attr.data-testid]="e2eLabel"
           (focus)="openMenu()"
           (keydown.enter)="onByEnter()"
           (keydown)="onKeyboardEvents($event)"
           data-testid="quick-search-input"/>

    <i *ngIf="withSearchIcon"
       (click)="onByEnter()"
       class="icon-search"
       [class.disabled]="isSearchIconDisabled">
    </i>
  </ng-container>

  <ul *ngIf="shouldShowMenu && suggestions && suggestions.length"
      [class.scroll]="isScroll"
      class="menu"
      data-testid="drop-down"
      #ul>

    <li *ngFor="let suggestion of suggestionsView || suggestions; let i = index;"
        (click)="onClickSuggestion()"
        (mouseenter)="setSuggestionIndex($event, i)"
        [class.selected]="i === selectedSuggestionIndex"
        [class.bordered]="isSuggestionWithBorders"
        class="menu__item">

      <ng-container *ngTemplateOutlet="suggestionTemplate || defaultTemplate;context:{ suggestion: suggestion }">
      </ng-container>

    </li>

  </ul>
</div>

<ng-template let-suggestion="suggestion" #defaultTemplate>

  <span class="suggestion">
    <span class="text">
      <span [innerHTML]="suggestion?.text | isFindWord : inputValue"></span>
      <span *ngIf="suggestion?.preTitle"> - {{ suggestion.preTitle }}</span>
      <span class="title" *ngIf="suggestion?.markAs"> ({{ suggestion.markAs }})</span>
      <span class="title" *ngIf="!suggestion?.markAs && suggestion?.title"> ({{ suggestion.title }})</span>
    </span>

    <span class="description">{{ suggestion?.description }}</span>
  </span>

</ng-template>
