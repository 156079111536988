export interface IKey {
  code: string[];
  key: string[];
}

export interface IKeysMap {
  [key: string]: IKey;
}

export enum KEY_CODES {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}
