import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';

import { ActionWithPayload } from '@shared/interfaces/store';
import { getCreditsAction } from '../../../credits/store/actions';
import { showNotificationAction } from '../../../notifications/store/actions/notification.action';
import { closeAssignDataPopUpAction, closeAssignOwnerPopUpAction } from '../actions/account-pop-ups.action';
import * as actions from '../actions/account-users.action';
import { getAccountSeatsAction } from '../actions/account.action';
import { getProfileAction } from '../actions/profile.action';

import { SnackBarComponent } from '@ui/snack-bar/components/snack-bar/snack-bar.component';

import { AccountUsersService } from '../../services/account-users.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IAccountUser,
  IAssignDataFromToUser,
  IRemoveUserAfterAssignedData,
  IRemoveUserFromAccount
} from '../../interfaces/marketview';

import { NOTIFICATION_TYPES } from '@core/constants/notifications';
import { SNACK_BAR_CONFIG } from '@ui/snack-bar/constants';
import { ACCOUNT_MESSAGES } from '../../constants/messages';

@Injectable()
export class AccountUsersEffect {

  constructor(private actions$: Actions,
              private accountUsersService: AccountUsersService,
              private snackBar: MatSnackBar) {
  }

  getAccountUsers$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.getAccountUsersAction),
      switchMap(() => this.accountUsersService
        .getUsers()
        .pipe(
          map((users: IAccountUser[]) => actions.getAccountUsersSuccessAction(users)),
          catchError((error: IServerError) => of(actions.getAccountUsersErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  removeUserFromAccount$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.removeUserAction),
      switchMap(({
                   payload: {
                     userId,
                     isInvited
                   }
                 }: ActionWithPayload<IRemoveUserFromAccount>) => this.accountUsersService
        .removeUser(userId)
        .pipe(
          map(() => actions.removeUserSuccessAction(isInvited)),
          catchError((error: IServerError) => {
            if (error.errors.hasOwnProperty('userRoleId')) {
              return of(showNotificationAction({
                message: error.errors.userRoleId[0],
                type: NOTIFICATION_TYPES.ERROR,
                canClose: true
              }));
            }
            return of(actions.removeUserErrorAction(error));
          })
        )
      ),
      catchErrorWithErrorType
    ));

  removeUserFromAccountSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.removeUserSuccessAction),
      tap(({ payload }: ActionWithPayload<boolean>) => {
        this.snackBar.openFromComponent(SnackBarComponent, {
          data: {
            message: payload // if isInvited
              ? ACCOUNT_MESSAGES.INVITED_USER_REMOVED
              : ACCOUNT_MESSAGES.USER_REMOVED,
            canCall: false
          },
          ...SNACK_BAR_CONFIG
        });
      }),
      switchMap(() => [
        actions.getAccountUsersAction(),
        getAccountSeatsAction(),
        getCreditsAction()
      ]),
      catchErrorWithErrorType
    ));

  assignDataFromToUser$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.assignDataAction),
      switchMap(({
                   payload: {
                     fromUserId,
                     toUserId
                   }
                 }: ActionWithPayload<IAssignDataFromToUser>) => this.accountUsersService
        .assignUserData(fromUserId, toUserId)
        .pipe(
          map(() => actions.assignDataSuccessAction()),
          catchError((error: IServerError) => of(actions.assignDataErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  assignDataFromToUserSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.assignDataSuccessAction),
      switchMap(() => [
        actions.getAccountUsersAction(),
        closeAssignDataPopUpAction()
      ]),
      catchErrorWithErrorType
    ));

  assignDataAndRemoveUser$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.assignDataAndRemoveUserAction),
      switchMap(({
                   payload: {
                     fromUserId,
                     toUserId
                   }
                 }: ActionWithPayload<IAssignDataFromToUser>) => this.accountUsersService
        .assignUserData(fromUserId, toUserId)
        .pipe(
          mapTo(actions.removeUserAfterAssignedDataAction({ fromUserId })),
          catchError((error: IServerError) => of(actions.assignDataErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  removeUserAfterAssignedData$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.removeUserAfterAssignedDataAction),
      switchMap(({ payload: { fromUserId } }: ActionWithPayload<IRemoveUserAfterAssignedData>) => this.accountUsersService
        .removeUser(fromUserId)
        .pipe(
          map(() => actions.removeUserAfterAssignedDataSuccessAction()),
          catchError((error: IServerError) => of(actions.removeUserErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  removeUserAfterAssignedDataSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.removeUserAfterAssignedDataSuccessAction),
      switchMap(() => [
        actions.getAccountUsersAction(),
        showNotificationAction({
          message: ACCOUNT_MESSAGES.USER_REMOVED_AND_DATA_ASSIGNED,
          type: NOTIFICATION_TYPES.SUCCESS,
          timeout: 3000
        }),
        getCreditsAction(),
        closeAssignDataPopUpAction()
      ]),
      catchErrorWithErrorType
    ));

  assignOwner$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.assignOwnerAction),
      switchMap(({ payload }: ActionWithPayload<number>) => this.accountUsersService
        .assignOwner(payload)
        .pipe(
          map(() => actions.assignOwnerSuccessAction()),
          catchError((error: IServerError) => of(actions.assignOwnerErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  assignOwnerSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.assignOwnerSuccessAction),
      switchMap(() => [
        getProfileAction(),
        closeAssignOwnerPopUpAction(),
        getCreditsAction(),
        actions.getAccountUsersAction()
      ]),
      catchErrorWithErrorType
    ));
}
