import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MobileTabsNavModule } from './mobile-tabs-nav/mobile-tabs-nav.module';

import { TabItemComponent } from './components/tab-item/tab-item.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsListComponent } from './components/tabs-list/tabs-list.component';
import { TabsComponent } from './components/tabs/tabs.component';

const declarations: any[] = [
  TabsListComponent,
  TabItemComponent,
  TabsComponent,
  TabComponent,
];

@NgModule({
  declarations: [
    ...declarations
  ],
  imports: [
    CommonModule,
    RouterModule,
    MobileTabsNavModule
  ],
  exports: [
    MobileTabsNavModule,
    ...declarations
  ]
})
export class TabsModule {
}
