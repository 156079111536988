<div class="side-nav--background"
     [class.side-nav--background-open]="isOpen">
</div>

<div #content
     class="side-nav"
     [class.side-nav--open]="isOpen">

  <ng-content></ng-content>
</div>
