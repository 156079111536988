export enum CORE_PATHS {
  EMPTY = '',
  FULL = 'full',
  OTHER = '**',
  DASHBOARD = 'dashboard',
  AUTH = 'auth',
  ACCOUNT = 'account',
  LIST_CREATE = 'list-create',
  LIST = 'list',
  CUSTOM_LIST = 'custom-list',
  CDL_LIST = 'cdl-list',
  VIEW = 'view',
  QUICK_SEARCH = 'quick-search',
  SCHOOL = 'school',
  DISTRICT = 'district',
  COLLEGE = 'college',
  COLLEGE_OFFICE = 'college-office',
  CMO = 'cmo',
  STATE_DEPARTMENT = 'state-department',
  GRANT = 'grant',
  BID = 'bid',
  ECOMM = 'ecomm',
  COMMON = 'common',
  FAQS = 'faqs',
  HELP = 'help',
  CONTACT_US = 'contact-us',
  PRIVACY_POLICY = 'privacy-policy',
  TOS = 'tos',
  DECLINE_TOS = 'decline-tos',
  TERMS_OF_USE = 'terms-of-use',
  DECLINE_TERMS_OF_USE = 'decline-terms-of-use',
  ORDER_CONFIRMATION = 'order-confirmation',
  BROKERS = 'brokers',
  BAD_GATEWAY = 'bad-gateway',
  SERVICE_UNAVAILABLE = 'service-unavailable',
  DOWNLOAD_FROM_EMAIL = 'download-from-email',
  NOT_FOUND = 'not-found',
}
