import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bl-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductHeaderComponent {

}
