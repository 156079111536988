<!-- form fields -->
<ng-container [formGroup]="formGroup">
  <div class="row">

    <!-- address switch checkbox -->
    <ng-container *ngIf="formGroup.controls['usAccountAddress']">

      <div class="col-12 use-account-data">
        <bl-custom-checkbox [formControlName]="'usAccountAddress'"
                            (change)="switchAddressForm($event)"
                            data-testid="use-profile-address-checkbox">
          Use my account address
        </bl-custom-checkbox>
      </div>

    </ng-container>
    <!-- / address switch checkbox -->

    <!-- show error form errors -->
    <div class="col-12">
      <bl-server-errors [error]="addressError"></bl-server-errors>
    </div><!-- show error form errors -->


    <!-- show as un-editable -->
    <div class="col-12 payment-address--un-editable" *ngIf="!editable">
      <div class="un-editable-info">
        <span>{{ formGroup.get('bill_to_address_line1').value }}</span><br/>
        <span>{{ formGroup.get('bill_to_address_city').value }}, </span>
        <span>{{ formGroup.get('bill_to_address_state').value }} </span>
        <span>{{ formGroup.get('bill_to_address_zip').value }}, </span>
        <span>{{ formGroup.get('bill_to_address_country').value }}</span><br/>
      </div>
    </div><!-- / show as un-editable -->

    <!-- show as editable -->
    <ng-template [ngIf]="editable">

      <div class="col-12 mb-5">
        <div class="select-wrapper" #selectWrapperEl>
          <bl-flat-select formControlName="bill_to_address_country"
                          placeholder="Country*"
                          [options]="countries"
                          [errors]="formGroup.get('bill_to_address_country').errors"
                          [dropdownWidth]="selectWidth"
                          [theme]="flatInputTheme.MAT_LIKE"
                          (select)="onSelectCountry()">
          </bl-flat-select>
        </div>
      </div>

      <div class="col-12 mb-5" *ngIf="countryStates?.length">
        <div class="select-wrapper">
          <bl-flat-select formControlName="bill_to_address_state"
                          placeholder="State/Region*"
                          [options]="countryStates"
                          [errors]="formGroup.get('bill_to_address_state').errors"
                          [dropdownWidth]="selectWidth"
                          [theme]="flatInputTheme.MAT_LIKE">
          </bl-flat-select>
        </div>
      </div>

      <div class="col-12 mb-5" *ngIf="!countryStates?.length">
        <bl-flat-input formControlName="bill_to_address_state"
                       placeholder="State/Region*"
                       [errors]="formGroup.get('bill_to_address_state').errors"
                       [theme]="flatInputTheme.MAT_LIKE">
        </bl-flat-input>
      </div>

      <div class="col-12 mb-5">
        <bl-flat-input formControlName="bill_to_address_zip"
                       placeholder="Zip Code*"
                       [errors]="formGroup.get('bill_to_address_zip').errors"
                       [mask]="zipCodeMask"
                       [theme]="flatInputTheme.MAT_LIKE">
        </bl-flat-input>
      </div>

      <div class="col-12 mb-5">
        <bl-flat-input formControlName="bill_to_address_city"
                       placeholder="City*"
                       [errors]="formGroup.get('bill_to_address_city').errors"
                       [theme]="flatInputTheme.MAT_LIKE">
        </bl-flat-input>
      </div>

      <div class="col-12 mb-5">
        <bl-flat-input formControlName="bill_to_address_line1"
                       placeholder="Address 1*"
                       [errors]="formGroup.get('bill_to_address_line1').errors"
                       [theme]="flatInputTheme.MAT_LIKE">
        </bl-flat-input>
      </div>

      <div class="col-12 mb-5">
        <bl-flat-input formControlName="bill_to_address_line2"
                       placeholder="Address 2"
                       [errors]="formGroup.get('bill_to_address_line2').errors"
                       [theme]="flatInputTheme.MAT_LIKE">
        </bl-flat-input>
      </div>

      <div class="col-12 mb-5">
        <bl-flat-input formControlName="bill_to_address_phone"
                       placeholder="Phone Number"
                       [errors]="formGroup.get('bill_to_address_phone').errors"
                       [mask]="phoneMask"
                       [theme]="flatInputTheme.MAT_LIKE">
        </bl-flat-input>
      </div>

    </ng-template><!-- / show as editable -->

  </div>

</ng-container><!-- form fields -->
