import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getCreditsFullInfo } from '@modules/credits/store/selectors/credits.selector';
import { openAssignAddOnInfoPopUp, openAssignStateInfoPopUp } from '@modules/profile/store/actions/account-pop-ups.action';
import * as addUserAccountActions from '@modules/profile/store/actions/add-user-account.action';
import { getBaseSeatsOptions, getDefaultSeatId, } from '@modules/profile/store/selectors/account.selector';
import {
  getCanCreateNewState,
  getError,
  getIsHasStates,
  getIsShouldShowAvailableStates,
  getIsShowAddState,
  getIsShowAvailableStates,
  getPending,
  getSeatId,
  getSelectedAddOnsIds,
  getUncheckedStatesOrSeatStates,
} from '@modules/profile/store/selectors/add-user-account.selector';

import { StorageService } from '@core/services/storage.service';
import { AddOnsService } from '@modules/profile/services/add-ons.service';
import { ProfileFormsService } from '@modules/profile/services/profile-forms.service';

import { parseAddUserFormForSend } from '@modules/profile/utils/map-forms';

import { ICreditsResponse, IUpdateICreditsResponse } from '@modules/auth/interfaces/user';
import {
  ICreateAccountUser,
  ICreateAccountUserForRequest,
  ISeatState,
  ISeatStates,
  ISelectedAddOnIds,
  Roles,
  SEATS
} from '@modules/profile/interfaces/marketview';
import { IControlOptions } from '@shared/interfaces/forms';
import { IAccessAccountCDLLists, IManageAccountLists } from '@shared/interfaces/manage-account-lists';
import { IServerError } from '@shared/interfaces/server-error';
import { IAddUserAccountPopUpData } from '../../interfaces';

import { PopUpRef } from '../../models/pop-up-ref';

import { getCanNotManageBidsDescription, getCanNotManageGrantsDescription } from '@core/constants/permissions';
import { ADD_ON_FIELD } from '@modules/profile/constants/add-ons';
import { ROLES_OPTIONS } from '@modules/profile/constants/marketview';
import { ACCOUNT_MESSAGES } from '@modules/profile/constants/messages';
import { CHECK_BOX_BID_NAME, CHECK_BOX_GRANT_NAME } from '@modules/profile/constants/products';
import {
  ACCESS_CDL_LISTS,
  MANAGE_ACCOUNT_LISTS,
  SWITCH_ACCESS_CDL_LISTS,
  SWITCH_ACCOUNT_LISTS
} from '@shared/constants/data/manage-account-lists';
import { FLAT_INPUT_THEME } from '@shared/constants/flat-input';
import { BID_ICON, DEFAULT_FIGURE_CLASS, GRANT_ICON } from '@shared/constants/tooltips/default-values';
import { FORM_VALIDATION_ERRORS } from '@shared/constants/validators/forms-validations-errors';

import { Validators } from '@shared/validators/validators';
import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-add-user-account-pop-up',
  templateUrl: './add-user-account-pop-up.component.html',
  styleUrls: ['./add-user-account-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddUserAccountPopUpComponent implements OnInit, OnDestroy {

  private destroyer$: Subject<void> = new Subject();
  private _selectedAddOnsIds: ISelectedAddOnIds;

  readonly accountListsFields: IManageAccountLists = SWITCH_ACCOUNT_LISTS;
  readonly oneSeatMsg: string = ACCOUNT_MESSAGES.ONE_STATE_MSG;
  readonly accessCDLLists: IAccessAccountCDLLists = SWITCH_ACCESS_CDL_LISTS;

  readonly checkBoxBidName: string = CHECK_BOX_BID_NAME;
  readonly checkBoxGrantName: string = CHECK_BOX_GRANT_NAME;

  readonly bidIcon: typeof BID_ICON = BID_ICON;
  readonly grantIcon: typeof GRANT_ICON = GRANT_ICON;
  readonly tooltipFigure: string = DEFAULT_FIGURE_CLASS;
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  readonly canNotManageGrantsText: string = getCanNotManageGrantsDescription();
  readonly canNotManageBidsText: string = getCanNotManageBidsDescription();

  form: FormGroup;

  seatId: SEATS;
  selectedSeatId: number;
  rolesOptions: IControlOptions = ROLES_OPTIONS;

  isShowAvailable: boolean;

  isShowAvailableState$: Observable<boolean> = this._store.pipe(select(getIsShowAvailableStates));

  defaultSeatId$: Observable<number> = this._store.pipe(select(getDefaultSeatId));

  seatId$: Observable<SEATS> = this._store.pipe(select(getSeatId));

  baseSeats$: Observable<IControlOptions> = this._store.pipe(select(getBaseSeatsOptions));
  uncheckedStates$: Observable<IControlOptions> = this._store.pipe(select(getUncheckedStatesOrSeatStates));
  canCreateNewState$: Observable<boolean> = this._store.pipe(select(getCanCreateNewState));

  selectedAddOnsIds$: Observable<ISelectedAddOnIds> = this._store.pipe(select(getSelectedAddOnsIds));

  isShowAddState$: Observable<boolean> = this._store.pipe(select(getIsShowAddState));
  isShowAvailableSeatStates$: Observable<boolean> = this._store.pipe(select(getIsShouldShowAvailableStates));

  isHasStates$: Observable<boolean> = this._store.pipe(select(getIsHasStates));

  error$: Observable<IServerError> = this._store.pipe(select(getError));
  pending$: Observable<boolean> = this._store.pipe(select(getPending));

  creditsInfo$: Observable<ICreditsResponse | IUpdateICreditsResponse> = this._store.pipe(select(getCreditsFullInfo));
  creditsInfo: ICreditsResponse;

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get states(): FormArray {
    return this.form.get('stateIds') as FormArray;
  }

  get stateIsCreated(): boolean {
    return this.states && this.states.controls && !!this.states.controls.length;
  }

  get isSelectedOneState(): boolean {
    return this.states && this.states.controls && this.states.controls.length === 1;
  }

  get isShowRemoveStateBtn(): boolean {
    return this.states && this.states.controls && this.states.controls.length > 1;
  }

  get nationalAddOns(): FormGroup {
    return this.form.get('nationalAddOns') as FormGroup;
  }

  get isNationalGrantAddOn(): AbstractControl {
    return this.nationalAddOns && this.nationalAddOns.get('isNationalGrantAddOn');
  }

  get isNationalBidAddOn(): AbstractControl {
    return this.nationalAddOns && this.nationalAddOns.get('isNationalBidAddOn');
  }

  get isCreatedNationalAddOns(): boolean {
    return this.nationalAddOns && !!this.nationalAddOns.controls;
  }

  get shouldShowAddState(): boolean {
    return this.states && this.states.value.every((value: any) => value && typeof value.stateId === 'number');
  }

  get isNationalSeat(): boolean {
    return this.selectedSeatId === SEATS.National;
  }

  get isAllocated(): boolean {
    return this.form.controls['isAllocated'].value;
  }

  constructor(@Inject(POP_UP_DATA) public data: IAddUserAccountPopUpData,
              private _popUpRef: PopUpRef,
              private _fb: FormBuilder,
              private _store: Store<CoreState>,
              private _formsService: ProfileFormsService,
              private _addOnsService: AddOnsService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.defaultSeatId$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((seatId: number) => {
        this.selectedSeatId = seatId;
        this._cdr.markForCheck();
      });

    this.isShowAvailableSeatStates$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((isShowAvailable: boolean) => {
        this.isShowAvailable = isShowAvailable;
        this._cdr.markForCheck();
      });

    this.seatId$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((_seatId: SEATS) => {
        this.seatId = _seatId;
        this._cdr.markForCheck();
      });

    this.creditsInfo$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((data: ICreditsResponse) => {
        this.creditsInfo = data;
        this._cdr.markForCheck();
      });

    this.selectedAddOnsIds$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((_selectedAddOnsIds: ISelectedAddOnIds) => {
        this._selectedAddOnsIds = _selectedAddOnsIds;
        this._cdr.markForCheck();
      });

    this.form = this._createForm();

    this.error$
      .pipe(
        takeUntil(this.destroyer$),
        tap((error: IServerError) => this._setErrors(error))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  selectSeat(selectedSeat: number): void {
    this.selectedSeatId = selectedSeat;

    this._store.dispatch(addUserAccountActions.changeSeatTypeAction(this.selectedSeatId));

    if (this.selectedSeatId !== SEATS.State) {
      this.form.removeControl('stateIds');
    }

    if (this.selectedSeatId !== SEATS.National) {
      this.form.removeControl('nationalAddOns');
    }

    if (this.selectedSeatId === SEATS.State && !this.stateIsCreated) {
      this.form.addControl('stateIds', this._fb.array([
        this._formsService.createStateFormGroup(
          null,
          this.data.openAddOns,
          this._selectedAddOnsIds.bidAddOnIds,
          this._selectedAddOnsIds.grantAddOnIds,
          true
        )
      ]));
    }

    if (this.selectedSeatId === SEATS.National && !this.isCreatedNationalAddOns) {
      this.form.addControl('nationalAddOns', this._formsService.createNationalAddOnGroup([], this.data.openAddOns));
    }

    this.form.updateValueAndValidity();
  }

  selectCurrentState(index: number = 0, selectedStateId?: number): void {
    const stateControl: AbstractControl = this.states.at(index);
    const bidStateAddOnControl: AbstractControl = stateControl && stateControl.get(ADD_ON_FIELD.BID);
    const grantStateAddOnControl: AbstractControl = stateControl && stateControl.get(ADD_ON_FIELD.GRANT);

    if (typeof selectedStateId === 'number') {
      bidStateAddOnControl.reset();
      grantStateAddOnControl.reset();
    }

    this._changeSelectedStates();

    if (typeof selectedStateId === 'number') {

      this._addOnsService.checkAvailableAddOnsControl(
        this.data.openAddOns,
        [],
        [],
        this._selectedAddOnsIds,
        selectedStateId,
        bidStateAddOnControl,
        grantStateAddOnControl
      );

      this._updateAvailableAddOns(false);
    }

    if (!StorageService.doNotShowAssignStatePopUp) {
      this._store.dispatch(openAssignStateInfoPopUp());
    }
  }

  selectStateAddOn(isCheck: boolean): void {
    this._changeSelectedStates();
    this._updateAvailableAddOns(isCheck);
    this._isShowAssignAddOnPopUp(isCheck);
  }

  handleSubmit(): void {
    if (this.form.valid) {
      const sendValue: ICreateAccountUserForRequest = parseAddUserFormForSend({ ...this.form.value } as ICreateAccountUser);
      this._store.dispatch(addUserAccountActions.addAccountUserAction(sendValue));
    }
  }

  closeSelf(): void {
    this._store.dispatch(addUserAccountActions.closeUserPopUpAction());
  }

  addState(): void {
    this._store.dispatch(addUserAccountActions.setShowAvailableStatesAction(true));

    const control: FormArray = this.states;

    if (!control) {
      return;
    }

    const newControl: FormGroup = this._formsService.createStateFormGroup(
      null,
      this.data.openAddOns,
      this._selectedAddOnsIds.bidAddOnIds,
      this._selectedAddOnsIds.grantAddOnIds,
      true
    );

    control.push(newControl);
  }

  removeState(index: number): void {
    const control: FormArray = this.states;

    if (control) {
      control.removeAt(index);

      const states: ISeatStates = control.value.map((item: ISeatState) => item);
      const { openStates, openAddOns }: IAddUserAccountPopUpData = this.data;
      this._store.dispatch(addUserAccountActions.changeSelectedStateAction({ states, openStates, openAddOns }));

      this._updateAvailableAddOns(false);
    }
  }

  onSetShowAvailableStates(): void {
    this._store.dispatch(addUserAccountActions.setShowAvailableStatesAction(false));
  }

  trackByIndex(index: number): number {
    return index;
  }

  private _createForm(): FormGroup {
    return this._fb.group({
      email: ['', [
        Validators.email(FORM_VALIDATION_ERRORS.email.invalid),
        Validators.required(FORM_VALIDATION_ERRORS.email.required)]
      ],
      seatId: [this.selectedSeatId, [
        Validators.required(FORM_VALIDATION_ERRORS.seat.required)]
      ],
      roleId: [Roles.User],
      showAccountLists: MANAGE_ACCOUNT_LISTS.MY_AND_ACTIVE_PRODUCTS,
      accessCDLLists: ACCESS_CDL_LISTS.NONE,
      isAllocated: [false],
      allocatedCredits: [this.creditsInfo.accountCredits]
    });
  }

  private _changeSelectedStates(): void {
    const { openStates, openAddOns }: IAddUserAccountPopUpData = this.data;
    const states: ISeatStates = this.states && this.states.value || [];
    this._store.dispatch(addUserAccountActions.changeSelectedStateAction({ states, openStates, openAddOns }));
  }

  private _updateAvailableAddOns(isChecked: boolean): void {
    const _states: FormArray = this.states;

    this._addOnsService.updateAvailableAddOnsControls(
      _states.controls,
      isChecked,
      this.data.openAddOns,
      [],
      [],
      this._selectedAddOnsIds
    );
  }

  private _isShowAssignAddOnPopUp(isCheck: boolean): void {
    if (isCheck && !StorageService.doNotShowAssignAddOnPopUp) {
      this._store.dispatch(openAssignAddOnInfoPopUp());
    }
  }

  private _setErrors(error: IServerError): void {
    if (error) {
      Object.entries(error.errors).forEach(([key, value]: [string, string[]]) => {
        const control: AbstractControl = this.form.controls[key];

        if (control) {
          control.setErrors({ serverError: value });
        }
      });
    }
  }
}
