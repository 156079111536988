import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { getIsBroker } from '@modules/profile/store/selectors/profile.selector';
import { go } from '../store/actions/router-history.action';
import { CoreState } from '../store/reducers';

import { CORE_PATHS } from '../constants/core-paths';

@Injectable()
export class IsNotBrokerGuard implements CanActivate {
  constructor(private _store: Store<CoreState>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._store
      .pipe(
        select(getIsBroker),
        take(1),
        map((isBroker: boolean) => {
          if (isBroker) {
            this._store.dispatch(go(['/', CORE_PATHS.DASHBOARD]));
          }

          return !isBroker;
        })
      );
  }

}
