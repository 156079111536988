<bl-sticky-popup>
  <div class="modal-header" data-testid="add-user-modal" popupHeader>

    <h3 class="title" data-testid="pop-up-title">
      {{ data.title }}
    </h3>
    <button class="btn"
            matRipple
            (click)="closeSelf()"
            type="button"
            data-testid="close-add-user-btn">
      <i class="icon-page-close"></i>
    </button>

  </div>

  <div class="modal-content" popupContent>
    <bl-server-errors [error]="(error$ | async)?.message"></bl-server-errors>

    <form *ngIf="form"
          [formGroup]="form">

      <!-- EMAIL -->
      <bl-flat-input formControlName="email"
                     placeholder="Email*"
                     [errors]="email.errors"
                     [theme]="flatInputTheme.MAT_LIKE"
                     data-testid="email-input">
      </bl-flat-input><!-- / EMAIL -->

      <!-- SEAT -->
      <bl-rounded-select formControlName="seatId"
                         [options]="baseSeats$ | async"
                         (select)="seatId !== $event ? selectSeat($event) : null"
                         placeholder="Seat"
                         data-testid="seat-drop-down"
                         class="select-wrapper select-seat">
      </bl-rounded-select><!-- / SEAT -->

      <!--  NATIONAL ADD ONS  -->
      <div *ngIf="isNationalSeat"
           formGroupName="nationalAddOns"
           class="checkbox-wrapper">

        <div [blTooltip]="isNationalGrantAddOn?.disabled ? canNotManageGrantsText : null"
             [positionFixedAndCalc]="true"
             [tooltipClass]="tooltipFigure"
             [topOffset]="-6"
             [iconClass]="bidIcon"
             [textSize]="14"
             [callLink]="true"
             class="checkbox">

          <bl-custom-checkbox formControlName="isNationalGrantAddOn">
            <span class="checkbox-label">{{ checkBoxGrantName }}</span>
          </bl-custom-checkbox>
        </div>

        <div [blTooltip]="isNationalBidAddOn?.disabled ? canNotManageBidsText : null"
             [positionFixedAndCalc]="true"
             [tooltipClass]="tooltipFigure"
             [topOffset]="-6"
             [iconClass]="grantIcon"
             [textSize]="14"
             [callLink]="true"
             class="checkbox">

          <bl-custom-checkbox formControlName="isNationalBidAddOn">
            <span class="checkbox-label">{{ checkBoxBidName }}</span>
          </bl-custom-checkbox>
        </div>
      </div><!--  / NATIONAL ADD ONS  -->

      <!-- STATES -->
      <div *ngIf="!!states"
           formArrayName="stateIds"
           data-testid="select-state-btn">

        <div *ngFor="let state of states.controls; let i = index;"
             class="select-item"
             data-testid="states-drop-down">

          <div [formGroupName]="i"
               class="select-wrapper">

            <div class="select-state-wrapper row">
              <bl-rounded-select formControlName="stateId"
                                 unselectedLabel="Select one..."
                                 [errors]="state.errors"
                                 [options]="uncheckedStates$ | async"
                                 [alternativeOptions]="data?.allStates"
                                 [isShowAdditionalListItems]="isShowAvailableState$ | async"
                                 [isCreateNewItems]="canCreateNewState$ | async"
                                 [checkSelectedOneOption]="!isShowAvailable"
                                 [class.disabled]="!(isHasStates$ | async) && isSelectedOneState"
                                 [blTooltip]="!(isHasStates$ | async) && isSelectedOneState ? oneSeatMsg : null"
                                 [positionFixedAndCalc]="true"
                                 placeholder="State"
                                 (select)="selectCurrentState(i, $event)"
                                 (onClickCreateBtn)="onSetShowAvailableStates()"
                                 class="theme--state-seat col-11">
              </bl-rounded-select>

              <button *ngIf="isShowRemoveStateBtn"
                      type="button"
                      class="btn-icon-close col-1"
                      (click)="removeState(i)">
                <i class="icon-page-close"></i>
              </button>
            </div>

            <!-- BID STATE ADD ON -->
            <div class="checkbox-wrapper">
              <div [blTooltip]="state.get('isBidStateId')?.disabled ? canNotManageBidsText : null"
                   [positionFixedAndCalc]="true"
                   [tooltipClass]="tooltipFigure"
                   [topOffset]="-6"
                   [iconClass]="bidIcon"
                   [textSize]="14"
                   [callLink]="true"
                   class="checkbox">

                <bl-custom-checkbox formControlName="isBidStateId"
                                    (change)="selectStateAddOn($event)">
                  <span class="checkbox-label">{{ checkBoxBidName }}</span>
                </bl-custom-checkbox>
              </div><!-- / BID STATE ADD ON -->

              <!-- GRANT STATE ADD ON -->
              <div [blTooltip]="state.get('isGrantStateId')?.disabled ? canNotManageGrantsText : null"
                   [positionFixedAndCalc]="true"
                   [tooltipClass]="tooltipFigure"
                   [topOffset]="-6"
                   [iconClass]="grantIcon"
                   [textSize]="14"
                   [callLink]="true"
                   class="checkbox">

                <bl-custom-checkbox formControlName="isGrantStateId"
                                    (change)="selectStateAddOn($event)">
                  <span class="checkbox-label">{{ checkBoxGrantName }}</span>
                </bl-custom-checkbox>
              </div><!-- / GRANT STATE ADD ON -->
            </div>
          </div>

        </div>

        <!-- ADD STATE -->
        <button *ngIf="shouldShowAddState && isShowAddState$ | async"
                type="button"
                class="btn--add-state"
                (click)="addState()"
                data-testid="add-plus-state-btn">
          + Add another state
        </button><!-- / ADD STATE -->
      </div><!-- STATES -->

      <!-- PERMISSIONS -->
      <div class="select-wrapper" data-testid="permission">
        <bl-rounded-select formControlName="roleId"
                           [options]="rolesOptions"
                           placeholder="Admin Permissions">
        </bl-rounded-select><!-- / PERMISSIONS -->
      </div>

      <br/>

      <span class="label">List Access</span>

      <bl-radio *ngFor="let item of accountListsFields"
                formControlName="showAccountLists"
                [label]="item.name"
                [value]="item.value"
                className="user-account">
      </bl-radio>

      <span class="label">Credits Access</span>

      <bl-radio formControlName="isAllocated"
                label="Access to all unallocated credits"
                className="user-account"
                [value]="false">
      </bl-radio>

      <bl-radio formControlName="isAllocated"
                label="Access to allocated credits only"
                className="user-account"
                [value]="true">
      </bl-radio>

      <bl-slider *ngIf="isAllocated"
                 formControlName="allocatedCredits"
                 [functionalMax]="creditsInfo.accountCredits - 1"
                 [hideMinLabel]="true"
                 [value]="creditsInfo.credits"
                 [min]="0"
                 [max]="creditsInfo?.accountCredits"
                 class="slider-credits">
      </bl-slider>

      <ng-container *ngIf="data?.asAdmin && data?.cdlLists?.hasActiveLists">
        <span class="label">Strategic Data Access</span>

        <bl-radio *ngFor="let item of accessCDLLists; trackBy: trackByIndex"
                  formControlName="accessCDLLists"
                  [label]="item.name"
                  [value]="item.value"
                  className="user-account">
        </bl-radio>
      </ng-container>

    </form>
  </div>

  <div class="modal-footer" popupFooter>
    <button type="button"
            [blBtnSpinner]="pending$ | async"
            [disabled]="form.invalid"
            (click)="handleSubmit()"
            class="btn btn--primary"
            data-testid="send-invitation-btn">
      Send Invitation
    </button>
  </div>

</bl-sticky-popup>
