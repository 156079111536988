<label [blTooltip]="onHoverTooltipText"
       [attr.data-testid]="label | e2e"
       (click)="$event.stopPropagation()"
       class="custom-value-checkbox"
       matRipple
       [ngClass]="{
          default: !isPremium,
          premium: isPremium,
          targeting: 'targeting' === theme,
          filter: 'filter' === theme,
          checked: checked,
          disabled: disabled
    }">

  <input type="checkbox"
         [disabled]="disabled"
         [checked]="checked"
         (change)="onChange($event)"
         class="input"/>

  <span class="label"
        blTargetHeight
        [targetHeightTitleText]="label"
        [blTooltip]="label"
        [innerHTML]="label"
        [isTargetingChecked]="checked">

      </span>
  <i *ngIf="description"
     [blTooltip]="description"
     class="icon-tooltip tooltip-target entity-description"></i>
</label>
