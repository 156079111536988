import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';

import { CloudSyncComponentsModule } from '@shared/modules/cloud-sync-components/cloud-sync-components.module';
import { DirectivesModule } from '@shared/modules/directives/directives.module';
import { SharedPipesModule } from '@shared/modules/pipes/shared-pipes/shared-pipes.module';
import { UserFormsModule } from '@shared/modules/user-forms/user-forms.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { DropDownModule } from '../drop-down/drop-down.module';
import { ErrorsModule } from '../errors/errors.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TooltipModule } from '../tooltip/tooltip.module';

import { ParentInstitutionOnlyComponent } from './components/add-parent-institution-only/parent-institution-only.component';
import { AddUserAccountPopUpComponent } from './components/add-user-account-pop-up/add-user-account-pop-up.component';
import {
  AssignUserDataPopUpContentComponent
} from './components/assign-user-data-pop-up-content/assign-user-data-pop-up-content.component';
import { ChildInstitutionOnlyComponent } from './components/child-institution-only/child-institution-only.component';
import {
  CloudSyncAssignProductPopUpComponent
} from './components/cloud-sync-assign-product-pop-up/cloud-sync-assign-product-pop-up.component';
import { CloudSyncAuthPopUpComponent } from './components/cloud-sync-auth-pop-up/cloud-sync-auth-pop-up.component';
import { CloudSyncToPopUpComponent } from './components/cloud-sync-to-pop-up/cloud-sync-to-pop-up.component';
import { ConfirmOrNotPopUpComponent } from './components/confirm-or-not-pop-up/confirm-or-not-pop-up.component';
import { ConfirmPopUpContentComponent } from './components/confirm-pop-up-content/confirm-pop-up-content.component';
import { ConfirmRefreshPopUpContentComponent } from './components/confirm-refresh-pop-up-content/confirm-refresh-pop-up-content.component';
import {
  DefaultPaymentMethodPopUpComponentComponent
} from './components/default-payment-method-pop-up-component/default-payment-method-pop-up-component.component';
import { DoNotShowPopUpAgainComponent } from './components/do-not-show-pop-up-again/do-not-show-pop-up-again.component';
import {
  DownloadStartedPopUpContentComponent } from './components/download-started-pop-up-content/download-started-pop-up-content.component';
import {
  ECommerceEditUserPopUpContentComponent
} from './components/e-commerce-edit-user-pop-up-content/e-commerce-edit-user-pop-up-content.component';
import { EditInfoPopUpComponent } from './components/edit-info-pop-up/edit-info-pop-up.component';
import { EditUserAccountPopUpComponent } from './components/edit-user-account-pop-up/edit-user-account-pop-up.component';
import { FirstSignUpPopUpContentComponent } from './components/first-sign-up-pop-up-content/first-sign-up-pop-up-content.component';
import { InfoPopUpContentComponent } from './components/info-pop-up-content/info-pop-up-content.component';
import { InfoPopupComponent } from './components/info-popup/info-popup.component';
import { ListSharePopUpComponent } from './components/list-share-pop-up/list-share-pop-up.component';
import { ListTypesPopUpContentComponent } from './components/list-types-pop-up-content/list-types-pop-up-content.component';
import { MoreThenAnyAppendsComponent } from './components/more-then-any-appends/more-then-any-appends.component';
import { MultiDownloadPopUpContentComponent } from './components/multi-download-pop-up-content/multi-download-pop-up-content.component';
import { MvRenewalPopUpComponent } from './components/mv-renewal-pop-up/mv-renewal-pop-up.component';
import {
  NewPaymentMethodPopUpContentComponent
} from './components/new-payment-method-pop-up-content/new-payment-method-pop-up-content.component';
import { OmsAuthPopUpComponent } from './components/oms-auth-pop-up/oms-auth-pop-up.component';
import { OmsAuthRedirectPopUpComponent } from './components/oms-auth-redirect-pop-up/oms-auth-redirect-pop-up.component';
import { PopUpBaseComponent } from './components/pop-up-base/pop-up-base.component';
import { PopUpPortalComponent } from './components/pop-up-portal/pop-up-portal.component';
import { PremiumDataPopUpComponent } from './components/premium-data-pop-up/premium-data-pop-up.component';
import { ReDownloadPopUpContentComponent } from './components/re-download-pop-up-content/re-download-pop-up-content.component';
import { StickyPopupComponent } from './components/sticky-popup/sticky-popup.component';
import { SuccessPopUpContentComponent } from './components/success-pop-up-content/success-pop-up-content.component';
import { UserCreditsInfoPopUpComponent } from './components/user-credits-info-pop-up/user-credits-info-pop-up.component';
import { WaitForListPopUpContentComponent } from './components/wait-for-list-pop-up-content/wait-for-list-pop-up-content.component';
import {
  WelcomeWithMarketViewPopUpContentComponent
} from './components/welcome-with-market-view-pop-up-content/welcome-with-market-view-pop-up-content.component';

import { PopUpService } from './services/pop-up/pop-up.service';

@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
    FormsModule,
    ReactiveFormsModule,
    CloudSyncComponentsModule,
    DirectivesModule,
    SharedPipesModule,
    ErrorsModule,
    CustomControlsModule,
    UserFormsModule,
    SpinnerModule,
    AutocompleteModule,
    TooltipModule,
    DropDownModule
  ],
  declarations: [
    PopUpPortalComponent,
    PopUpBaseComponent,
    DoNotShowPopUpAgainComponent,
    MoreThenAnyAppendsComponent,
    ConfirmPopUpContentComponent,
    WaitForListPopUpContentComponent,
    ConfirmRefreshPopUpContentComponent,
    FirstSignUpPopUpContentComponent,
    DownloadStartedPopUpContentComponent,
    ReDownloadPopUpContentComponent,
    MultiDownloadPopUpContentComponent,
    InfoPopUpContentComponent,
    ListTypesPopUpContentComponent,
    ECommerceEditUserPopUpContentComponent,
    NewPaymentMethodPopUpContentComponent,
    SuccessPopUpContentComponent,
    DefaultPaymentMethodPopUpComponentComponent,
    ListSharePopUpComponent,
    EditUserAccountPopUpComponent,
    AddUserAccountPopUpComponent,
    ConfirmOrNotPopUpComponent,
    AssignUserDataPopUpContentComponent,
    UserCreditsInfoPopUpComponent,
    WelcomeWithMarketViewPopUpContentComponent,
    ParentInstitutionOnlyComponent,
    ChildInstitutionOnlyComponent,
    PremiumDataPopUpComponent,
    CloudSyncAuthPopUpComponent,
    CloudSyncToPopUpComponent,
    CloudSyncAssignProductPopUpComponent,
    StickyPopupComponent,
    MvRenewalPopUpComponent,
    InfoPopupComponent,
    OmsAuthPopUpComponent,
    OmsAuthRedirectPopUpComponent,
    EditInfoPopUpComponent
  ]
})
export class PopUpModule {
  static forRoot(): ModuleWithProviders<PopUpModule> {
    return {
      ngModule: PopUpModule,
      providers: [
        PopUpService
      ]
    };
  }
}
