import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { pluckAndCatch } from '@shared/utils/response-formater';

import { IStateOption } from '@shared/interfaces/forms';
import { IAccountSeat } from '../interfaces/marketview';

@Injectable()
export class AccountService {

  constructor(private http: HttpClient) {
  }

  getEmailsOfUsers(value: string): Observable<string[]> {
    const params: HttpParams = new HttpParams().set('email', value);

    return this.http.get(`${ environment.api.user.predictions }`, { params })
      .pipe(pluckAndCatch);
  }

  getAccountSeats(): Observable<IAccountSeat[]> {
    return this.http.get(environment.api.marketView.account.seats)
      .pipe(pluckAndCatch);
  }

  getStates(): Observable<IStateOption[]> {
    return this.http.get(environment.api.general.states)
      .pipe(pluckAndCatch);
  }
}
