<p *ngIf="message"
   [innerHtml]="message"
   [tooltipClass]="'tooltip--square'"
   [iconClass]="'tooltip--view'"
   [textSize]="14"
   [callLink]="true"
   [blTooltip]="messageToolTip">
</p>

<button *ngIf="belowButton"
        class="link"
        type="button"
        (click)="(belowButton.fn)()">
  {{ belowButton.name }}
</button>

<a *ngIf="belowLink && !belowButton"
   [routerLink]="belowLink.src">
  {{ belowLink.name }}
</a>

<ng-content></ng-content>

<button *ngIf="canClose"
        class="close-btn"
        (click)="onClose.emit(id)">

  <i class="icon-page-close"></i>

</button>
