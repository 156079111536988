import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';

import { of, BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, filter, skip, switchMap, tap } from 'rxjs/operators';

import { IServerError } from '@shared/interfaces/server-error';

export class UploadRef<R> {

  private readonly _error$: BehaviorSubject<IServerError> = new BehaviorSubject<IServerError>(null);
  private readonly _progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly _result$: BehaviorSubject<R> = new BehaviorSubject<R>(null);

  private _loader$: Subject<any> = new Subject<any>();
  private _data: FormData;
  private _url: string;

  get error$(): Observable<IServerError> {
    return this._error$.asObservable()
      .pipe(
        skip(1), // skip null value
        filter((error: IServerError) => !!error),
      );
  }

  get progress$(): Observable<number> {
    return this._progress$
      .pipe(
        skip(1), // skip null value
        filter((progress: number) => typeof progress === 'number')
      );
  }

  get result$(): Observable<R> {
    return this._result$.asObservable()
      .pipe(
        skip(1), // skip null value
      );
  }

  constructor(private _uploader: (data: FormData, url: string) => Observable<HttpEvent<Object> | IServerError>) {
    this._createUploader();
  }

  private _createUploader(): void {
    this._loader$.pipe(
      switchMap(() => this._uploader(this._data, this._url)),
      tap((event: HttpEvent<Object>) => {
        if (event.type === HttpEventType.UploadProgress) {
          this._progress$.next(Math.round(100 * event.loaded / event.total));
        }
      }),
      filter((event: HttpEvent<Object>) => event.type === HttpEventType.Response),
      catchError((error: HttpErrorResponse) => {
        this._error$.next(error.error as IServerError);
        return of(null);
      })
    ).subscribe((event: HttpResponse<R>) => {
      if (!event) {
        return;
      }
      this._result$.next(event.body);
    });
  }

  upload(data: FormData, url?: string): void {
    this._error$.next(null);
    this._progress$.next(0);

    if (typeof url === 'string') {
      this._url = url;
    }

    this._data = data;

    this._loader$.next();
  }

  complete(): void {
    this._error$.next(null);
    this._progress$.next(0);
    this._result$.next(null);

    this._error$.complete();
    this._progress$.complete();
    this._result$.complete();

    this._loader$.complete();
  }
}
