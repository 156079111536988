import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/show-email.action';

import { IServerError } from '@shared/interfaces/server-error';

export interface IShowEmailState {
  personId: number | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IShowEmailState = {
  personId: null,
  pending: false,
  error: null
};

const getPaymentEmailByPersonnelIdI: OnReducer<IShowEmailState, ActionType<Payload<any>>> = (state: IShowEmailState, { payload }: Payload<any>) => ({
  ...state,
  personId: payload,
  pending: true,
  error: null
});

const getPaymentEmailByPersonnelIdError: OnReducer<IShowEmailState, ActionType<Payload<IServerError>>> = (state: IShowEmailState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload }
});

const getPaymentEmailByPersonnelIdSuccess: OnReducer<IShowEmailState, ActionType<any>> = (state: IShowEmailState) => ({
  ...state,
  personId: null,
  pending: false
});

const reducer: ActionReducer<IShowEmailState> = createReducer<IShowEmailState>(
  initialState,

  on(actions.getPaymentEmailByPersonnelIdAction, getPaymentEmailByPersonnelIdI),
  on(actions.getPaymentEmailByPersonnelIdErrorAction, getPaymentEmailByPersonnelIdError),
  on(actions.getPaymentEmailByPersonnelIdSuccessAction, getPaymentEmailByPersonnelIdSuccess)
);

export const personId: GetFromState<number, IShowEmailState> = (state: IShowEmailState): number => state.personId;
export const pending: GetFromState<boolean, IShowEmailState> = (state: IShowEmailState): boolean => state.pending;
export const showEmailError: GetFromState<IServerError, IShowEmailState> = (state: IShowEmailState): IServerError => state.error;

export function showEmailReducer(state: IShowEmailState,
                                 action: Action): IShowEmailState {
  return reducer(state, action);
}
