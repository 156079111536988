import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IPID } from '@modules/segment/interfaces/pids';
import { IServerError } from '@shared/interfaces/server-error';

import { IChangeTargetingCriteria, IOnSegmentUpdateDataControls } from '@shared/modules/targeting-criteria-controls/data';


enum ACTION_TYPES {
  ON_UPDATE_GRANTS_TARGETING_GROUP = '[GRANTS]: On Update Grants Targeting',

  UPDATE_GRANTS_TARGETING_GROUP = '[GRANTS]: Update Grants Targeting',
  UPDATE_GRANTS_TARGETING_GROUP_ERROR = '[GRANTS]: Update Grants Targeting error',
  UPDATE_GRANTS_TARGETING_GROUP_SUCCESS = '[GRANTS]: Update Grants Targeting success',

  ADD_GRANTS_KEYWORD = '[GRANTS]: Add keyword',
  ADD_GRANTS_KEYWORD_ERROR = '[GRANTS]: Add keyword error',
  ADD_GRANTS_KEYWORD_SUCCESS = '[GRANTS]: Add keyword success',

  GET_GRANTS_KEYWORDS = '[GRANTS] Get keywords',
  GET_GRANTS_KEYWORDS_ERROR = '[GRANTS] Get keywords error',
  GET_GRANTS_KEYWORDS_SUCCESS = '[GRANTS] Get keywords success',

  UPDATE_GRANTS_KEYWORD = '[GRANTS]: Update keyword',
  UPDATE_GRANTS_KEYWORD_ERROR = '[GRANTS]: Update keyword error',
  UPDATE_GRANTS_KEYWORD_SUCCESS = '[GRANTS]: Update keyword success',

  REMOVE_GRANTS_KEYWORD = '[GRANTS]: Remove keyword',
  REMOVE_GRANTS_KEYWORD_ERROR = '[GRANTS]: Remove keyword error',
  REMOVE_GRANTS_KEYWORD_SUCCESS = '[GRANTS]: Remove keyword success',

  GRANTS_RESET_KEYWORDS = '[GRANTS]: Reset Grants keywords',

  GRANTS_TOGGLE_IS_ALL = '[GRANTS]: Grants Toggle Is All',
  GRANTS_TOGGLE_IS_ALL_ERROR = '[GRANTS]: Grants Toggle Is All error',
  GRANTS_TOGGLE_IS_ALL_SUCCESS = '[GRANTS]: Grants Toggle Is All success',

  TOGGLE_INCLUDE_GRANTS = '[Segment] Grants Toggle Include',

  GRANTS_RESET = '[GRANTS]: Grants reset',
}

export const onUpdateGrantsTargetingGroupAction: CreateActionType<ACTION_TYPES.ON_UPDATE_GRANTS_TARGETING_GROUP, IOnSegmentUpdateDataControls> = createAction(ACTION_TYPES.ON_UPDATE_GRANTS_TARGETING_GROUP,
  createPayload<IOnSegmentUpdateDataControls>());

export const updateGrantsTargetingGroupAction: CreateActionType<ACTION_TYPES.UPDATE_GRANTS_TARGETING_GROUP, IChangeTargetingCriteria> = createAction(ACTION_TYPES.UPDATE_GRANTS_TARGETING_GROUP,
  createPayload<IChangeTargetingCriteria>());
export const updateGrantsTargetingGroupErrorAction: CreateActionType<ACTION_TYPES.UPDATE_GRANTS_TARGETING_GROUP_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_GRANTS_TARGETING_GROUP_ERROR,
  createPayload<IServerError>());
export const updateGrantsTargetingGroupSuccessAction: CreateActionType<ACTION_TYPES.UPDATE_GRANTS_TARGETING_GROUP_SUCCESS, any> = createAction(ACTION_TYPES.UPDATE_GRANTS_TARGETING_GROUP_SUCCESS,
  createPayload<any>());

export const addGrantsKeywordAction: CreateActionType<ACTION_TYPES.ADD_GRANTS_KEYWORD, { segmentCriteriaId: number, keyword: IPID }> = createAction(ACTION_TYPES.ADD_GRANTS_KEYWORD,
  createPayload<{ segmentCriteriaId: number, keyword: IPID }>());
export const addGrantsKeywordSuccessAction: ActionCreator<ACTION_TYPES.ADD_GRANTS_KEYWORD_SUCCESS, () => TypedAction<ACTION_TYPES.ADD_GRANTS_KEYWORD_SUCCESS>> = createAction(ACTION_TYPES.ADD_GRANTS_KEYWORD_SUCCESS);
export const addGrantsKeywordErrorAction: CreateActionType<ACTION_TYPES.ADD_GRANTS_KEYWORD_ERROR, IServerError> = createAction(ACTION_TYPES.ADD_GRANTS_KEYWORD_ERROR, createPayload<IServerError>());

export const getGrantsKeywordsAction: CreateActionType<ACTION_TYPES.GET_GRANTS_KEYWORDS, string> = createAction(ACTION_TYPES.GET_GRANTS_KEYWORDS, createPayload<string>());
export const getGrantsKeywordsErrorAction: CreateActionType<ACTION_TYPES.GET_GRANTS_KEYWORDS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_GRANTS_KEYWORDS_ERROR, createPayload<IServerError>());
export const getGrantsKeywordsSuccessAction: CreateActionType<ACTION_TYPES.GET_GRANTS_KEYWORDS_SUCCESS, string[]> = createAction(ACTION_TYPES.GET_GRANTS_KEYWORDS_SUCCESS, createPayload<string[]>());

export const updateGrantsKeywordAction: CreateActionType<ACTION_TYPES.UPDATE_GRANTS_KEYWORD, { keyword: IPID }> = createAction(ACTION_TYPES.UPDATE_GRANTS_KEYWORD, createPayload<{ keyword: IPID }>());
export const updateGrantsKeywordSuccessAction: ActionCreator<ACTION_TYPES.UPDATE_GRANTS_KEYWORD_SUCCESS, () => TypedAction<ACTION_TYPES.UPDATE_GRANTS_KEYWORD_SUCCESS>> = createAction(ACTION_TYPES.UPDATE_GRANTS_KEYWORD_SUCCESS);
export const updateGrantsKeywordErrorAction: CreateActionType<ACTION_TYPES.UPDATE_GRANTS_KEYWORD_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_GRANTS_KEYWORD_ERROR, createPayload<IServerError>());

export const removeGrantsKeywordAction: CreateActionType<ACTION_TYPES.REMOVE_GRANTS_KEYWORD, { keywordId: number }> = createAction(ACTION_TYPES.REMOVE_GRANTS_KEYWORD, createPayload<{ keywordId: number }>());
export const removeGrantsKeywordSuccessAction: ActionCreator<ACTION_TYPES.REMOVE_GRANTS_KEYWORD_SUCCESS, () => TypedAction<ACTION_TYPES.REMOVE_GRANTS_KEYWORD_SUCCESS>> = createAction(ACTION_TYPES.REMOVE_GRANTS_KEYWORD_SUCCESS);
export const removeGrantsKeywordErrorAction: CreateActionType<ACTION_TYPES.REMOVE_GRANTS_KEYWORD_ERROR, IServerError> = createAction(ACTION_TYPES.REMOVE_GRANTS_KEYWORD_ERROR, createPayload<IServerError>());

export const resetGrantsKeywordsAction: ActionCreator<ACTION_TYPES.GRANTS_RESET_KEYWORDS, () => TypedAction<ACTION_TYPES.GRANTS_RESET_KEYWORDS>> = createAction(ACTION_TYPES.GRANTS_RESET_KEYWORDS);

export const grantsToggleIsAllAction: CreateActionType<ACTION_TYPES.GRANTS_TOGGLE_IS_ALL, { segmentCriteriaId: number, checked: boolean }> = createAction(ACTION_TYPES.GRANTS_TOGGLE_IS_ALL,
  createPayload<{ segmentCriteriaId: number, checked: boolean }>());
export const grantsToggleIsAllSuccessAction: CreateActionType<ACTION_TYPES.GRANTS_TOGGLE_IS_ALL_SUCCESS, any> = createAction(ACTION_TYPES.GRANTS_TOGGLE_IS_ALL_SUCCESS,
  createPayload<any>());
export const grantsToggleIsAllErrorAction: CreateActionType<ACTION_TYPES.GRANTS_TOGGLE_IS_ALL_ERROR, IServerError> = createAction(ACTION_TYPES.GRANTS_TOGGLE_IS_ALL_ERROR, createPayload<IServerError>());

export const toggleIncludeGrantsAction: ActionCreator<ACTION_TYPES.TOGGLE_INCLUDE_GRANTS, () => TypedAction<ACTION_TYPES.TOGGLE_INCLUDE_GRANTS>> = createAction(ACTION_TYPES.TOGGLE_INCLUDE_GRANTS);

export const grantsResetAction: ActionCreator<ACTION_TYPES.GRANTS_RESET, () => TypedAction<ACTION_TYPES.GRANTS_RESET>> = createAction(ACTION_TYPES.GRANTS_RESET);
