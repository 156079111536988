import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IInvitationUser } from '../../../auth/interfaces/invitation';
import { IAssignOwner } from '../../interfaces/invitation';
import { IEditAccountUser, IRemoveUserFromAccount } from '../../interfaces/marketview';
import { IRemoveCreditInfo } from '../../interfaces/pop-ups';

enum ACTION_TYPES {
  OPEN_REMOVE_USER_POP_UP = '[ACCOUNT-POP-UPS] Open remove user pop-up',
  CLOSE_REMOVE_USER_POP_UP = '[ACCOUNT-POP-UPS] Close remove user pop-up',

  OPEN_REMOVE_USER_WITH_DATA_POP_UP = '[ACCOUNT-POP-UPS] Open remove user with data pop-up',
  CLOSE_REMOVE_USER_WITH_DATA_POP_UP = '[ACCOUNT-POP-UPS] Close remove user with data pop-up',

  OPEN_REMOVE_AND_ASSIGN_POP_UP = '[ACCOUNT-POP-UPS] Open remove and assign pop-up',
  CLOSE_REMOVE_AND_ASSIGN_POP_UP = '[ACCOUNT-POP-UPS] Close remove and assign pop-up',

  OPEN_ASSIGN_DATA_POP_UP = '[ACCOUNT-POP-UPS] Open assign user data pop-up',
  CLOSE_ASSIGN_DATA_POP_UP = '[ACCOUNT-POP-UPS] Close assign user data pop-up',

  OPEN_CREDITS_INFO_POP_UP = '[ACCOUNT-POP-UPS] Open credits info pop-up',
  CLOSE_CREDITS_INFO_POP_UP = '[ACCOUNT-POP-UPS] Close credits info pop-up',

  OPEN_ASSIGN_OWNER_POP_UP = '[ACCOUNT-POP-UPS] Open assign owner data pop-up',
  CLOSE_ASSIGN_OWNER_POP_UP = '[ACCOUNT-POP-UPS] Close assign owner data pop-up',

  OPEN_ASSIGN_OWNER_BY_INVITATION_POP_UP = '[ACCOUNT-POP-UPS] Open assign owner by invitation pop-up',
  CLOSE_ASSIGN_OWNER_BY_INVITATION_POP_UP = '[ACCOUNT-POP-UPS] Close assign owner by invitation pop-up',

  OPEN_ASSIGN_OWNER_INFO_POP_UP = '[ACCOUNT-POP-UPS] Open assign owner info pop-up',
  CLOSE_ASSIGN_OWNER_INFO_POP_UP = '[ACCOUNT-POP-UPS] Close assign owner info pop-up',

  OPEN_ASSIGN_OWNER_MERGE_POP_UP = '[ACCOUNT-POP-UPS] Open assign owner merge pop-up',
  CLOSE_ASSIGN_OWNER_MERGE_POP_UP = '[ACCOUNT-POP-UPS] Close assign owner merge pop-up',

  OPEN_ASSIGN_STATE_INFO_POP_UP = '[ACCOUNT-POP-UPS] Open assign state info pop-up',
  CLOSE_ASSIGN_STATE_INFO_POP_UP = '[ACCOUNT-POP-UPS] Close assign state info pop-up',

  OPEN_ASSIGN_ADDON_INFO_POP_UP = '[ACCOUNT-POP-UPS] Open assign add on info pop-up',
  CLOSE_ASSIGN_ADDON_INFO_POP_UP = '[ACCOUNT-POP-UPS] Close assign add on info pop-up',

  RESET_ACCOUNT_POP_UPS_STATE = '[ACCOUNT-POP-UPS] Reset account popups store'
}


export const openRemoveUserPopUpAction: CreateActionType<ACTION_TYPES.OPEN_REMOVE_USER_POP_UP, IRemoveUserFromAccount> = createAction(ACTION_TYPES.OPEN_REMOVE_USER_POP_UP, createPayload<IRemoveUserFromAccount>());
export const closeRemoveUserPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_REMOVE_USER_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_REMOVE_USER_POP_UP>> = createAction(ACTION_TYPES.CLOSE_REMOVE_USER_POP_UP);

export const openRemoveUserWithDataPopUpAction: CreateActionType<ACTION_TYPES.OPEN_REMOVE_USER_WITH_DATA_POP_UP, IEditAccountUser> = createAction(ACTION_TYPES.OPEN_REMOVE_USER_WITH_DATA_POP_UP,
  createPayload<IEditAccountUser>());

export const closeRemoveUserWithDataPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_REMOVE_USER_WITH_DATA_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_REMOVE_USER_WITH_DATA_POP_UP>> = createAction(ACTION_TYPES.CLOSE_REMOVE_USER_WITH_DATA_POP_UP);

export const openRemoveAndAssignPopUpAction: CreateActionType<ACTION_TYPES.OPEN_REMOVE_AND_ASSIGN_POP_UP, IEditAccountUser> = createAction(ACTION_TYPES.OPEN_REMOVE_AND_ASSIGN_POP_UP, createPayload<IEditAccountUser>());
export const closeRemoveAndAssignPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_REMOVE_AND_ASSIGN_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_REMOVE_AND_ASSIGN_POP_UP>> = createAction(ACTION_TYPES.CLOSE_REMOVE_AND_ASSIGN_POP_UP);

export const openAssignDataPopUpAction: CreateActionType<ACTION_TYPES.OPEN_ASSIGN_DATA_POP_UP, number> = createAction(ACTION_TYPES.OPEN_ASSIGN_DATA_POP_UP, createPayload<number>());
export const closeAssignDataPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_ASSIGN_DATA_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ASSIGN_DATA_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ASSIGN_DATA_POP_UP);

export const openCreditsInfoPopUpAction: CreateActionType<ACTION_TYPES.OPEN_CREDITS_INFO_POP_UP, IRemoveCreditInfo> = createAction(ACTION_TYPES.OPEN_CREDITS_INFO_POP_UP, createPayload<IRemoveCreditInfo>());
export const closeCreditsInfoPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_CREDITS_INFO_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_CREDITS_INFO_POP_UP>> = createAction(ACTION_TYPES.CLOSE_CREDITS_INFO_POP_UP);

export const openAssignOwnerPopUpAction: CreateActionType<ACTION_TYPES.OPEN_ASSIGN_OWNER_POP_UP, number> = createAction(ACTION_TYPES.OPEN_ASSIGN_OWNER_POP_UP, createPayload<number>());
export const closeAssignOwnerPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_ASSIGN_OWNER_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ASSIGN_OWNER_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ASSIGN_OWNER_POP_UP);

export const openAssignOwnerByInvitationPopUpAction: CreateActionType<ACTION_TYPES.OPEN_ASSIGN_OWNER_BY_INVITATION_POP_UP, IInvitationUser> = createAction(ACTION_TYPES.OPEN_ASSIGN_OWNER_BY_INVITATION_POP_UP,
  createPayload<IInvitationUser>());

export const closeAssignOwnerByInvitationPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_ASSIGN_OWNER_BY_INVITATION_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ASSIGN_OWNER_BY_INVITATION_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ASSIGN_OWNER_BY_INVITATION_POP_UP);

export const openAssignOwnerInfoPopUpAction: ActionCreator<ACTION_TYPES.OPEN_ASSIGN_OWNER_INFO_POP_UP, () => TypedAction<ACTION_TYPES.OPEN_ASSIGN_OWNER_INFO_POP_UP>> = createAction(ACTION_TYPES.OPEN_ASSIGN_OWNER_INFO_POP_UP);
export const closeAssignOwnerInfoPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_ASSIGN_OWNER_INFO_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ASSIGN_OWNER_INFO_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ASSIGN_OWNER_INFO_POP_UP);

export const openAssignOwnerMergePopUpAction: CreateActionType<ACTION_TYPES.OPEN_ASSIGN_OWNER_MERGE_POP_UP, IAssignOwner> = createAction(ACTION_TYPES.OPEN_ASSIGN_OWNER_MERGE_POP_UP, createPayload<IAssignOwner>());
export const closeAssignOwnerMergePopUpAction: ActionCreator<ACTION_TYPES.CLOSE_ASSIGN_OWNER_MERGE_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ASSIGN_OWNER_MERGE_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ASSIGN_OWNER_MERGE_POP_UP);

export const openAssignStateInfoPopUp: ActionCreator<ACTION_TYPES.OPEN_ASSIGN_STATE_INFO_POP_UP, () => TypedAction<ACTION_TYPES.OPEN_ASSIGN_STATE_INFO_POP_UP>> = createAction(ACTION_TYPES.OPEN_ASSIGN_STATE_INFO_POP_UP);
export const closeAssignStateInfoPopUp: ActionCreator<ACTION_TYPES.CLOSE_ASSIGN_STATE_INFO_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ASSIGN_STATE_INFO_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ASSIGN_STATE_INFO_POP_UP);

export const openAssignAddOnInfoPopUp: ActionCreator<ACTION_TYPES.OPEN_ASSIGN_ADDON_INFO_POP_UP, () => TypedAction<ACTION_TYPES.OPEN_ASSIGN_ADDON_INFO_POP_UP>> = createAction(ACTION_TYPES.OPEN_ASSIGN_ADDON_INFO_POP_UP);
export const closeAssignAddOnInfoPopUp: ActionCreator<ACTION_TYPES.CLOSE_ASSIGN_ADDON_INFO_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ASSIGN_ADDON_INFO_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ASSIGN_ADDON_INFO_POP_UP);

export const resetAccountPopUpsState: ActionCreator<ACTION_TYPES.RESET_ACCOUNT_POP_UPS_STATE, () => TypedAction<ACTION_TYPES.RESET_ACCOUNT_POP_UPS_STATE>> = createAction(ACTION_TYPES.RESET_ACCOUNT_POP_UPS_STATE);
