import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { DropDownContentDirective } from '../../directives/drop-down-content.directive';
import { DropDownTargetDirective } from '../../directives/drop-down-target.directive';

import { DropDownService } from '../../services/drop-down.service';

import { IDropDownParams } from '../../interfaces/drop-down';

import { DropDownRef } from '../../models/drop-down-ref';

import { DEFAULT_DROP_DOWN_PARAMS } from '../../constants/drop-down';

@Component({
  selector: 'bl-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropDownComponent implements OnDestroy, OnChanges, AfterViewInit {

  @ViewChild('root', { static: true }) rootEl: ElementRef;

  @ContentChild(DropDownTargetDirective, { read: TemplateRef }) targetEl: TemplateRef<any>;
  @ContentChild(DropDownContentDirective, { read: TemplateRef }) contentTemplate: TemplateRef<any>;

  // Inputs
  @Input() isDisabled: boolean = false;
  @Input() shouldBeOpenedOnInit: boolean = false;
  @Input() isFullScreen: boolean;

  get isOpen(): boolean {
    return !!this._dropDownRef;
  }

  @Input()
  set theme(theme: string) {
    this.params = { ...this.params, theme };
  }

  get theme(): string {
    return this.params.theme;
  }

  @Input()
  set bodyPosition(bodyPosition: 'left' | 'right' | 'center') {
    this.params = { ...this.params, bodyPosition: bodyPosition || 'left' };
  }

  @Input()
  set leftOffset(leftOffset: number) {
    this.params = { ...this.params, leftOffset: leftOffset || 0 };
  }

  @Input()
  set topOffset(topOffset: number) {
    this.params = { ...this.params, topOffset: topOffset || 0 };
  }

  @Input()
  set rightOffset(rightOffset: number) {
    this.params = { ...this.params, rightOffset: rightOffset || 0 };
  }

  @Input()
  set rightSpacing(rightSpacing: number) {
    this.params = { ...this.params, rightSpacing: rightSpacing || 5 };
  }

  @Input()
  set width(width: number) {
    if (!width) {
      return;
    }
    this.params = { ...this.params, width };
  }

  @Input()
  set zIndex(zIndex: number) {
    this.params = { ...this.params, zIndex };
  }

  @Input()
  set isBigArrow(isBigArrow: boolean) {
    this.params = { ...this.params, isBigArrow };
  }

  @Input()
  set isPreventClose(isPreventClose: boolean) {
    this.params = { ...this.params, isPreventClose };
  }

  @Input()
  set withoutArrow(withoutArrow: boolean) {
    this.params = { ...this.params, withoutArrow };
  }

  @Input()
  get params(): IDropDownParams {
    return this._params;
  }

  set params(params: IDropDownParams) {
    this._params = { ...this.params, ...params };
  }

  @Input()
  get toggleOnHover(): boolean {
    return this._toggleOnHover;
  }

  set toggleOnHover(state: boolean) {
    this._toggleOnHover = state;
    this._params = { ...this._params, toggleOnHover: state };
  }

  @Input()
  set fullScreen(fullScreen: boolean) {
    this.params = { ...this.params, fullScreen };
  }

  @Input() fullWidth: boolean;

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  private _params: IDropDownParams = DEFAULT_DROP_DOWN_PARAMS;
  private _toggleOnHover: boolean = false;
  private _dropDownRef: DropDownRef = null;

  constructor(private _dropDownService: DropDownService,
              private _chr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    if (this.shouldBeOpenedOnInit) {
      // TODO find current place for changeDetectRef
      setTimeout(() => this.open(), 0);
    }
  }

  ngOnChanges(): void {
    if (this._dropDownRef) {
      this._dropDownRef.updateParams(this.params);
    }
  }

  ngOnDestroy(): void {
    // TODO find current place for changeDetectRef
    setTimeout(() => this.close(), 0);
  }

  open(): void {
    if (this.isDisabled) {
      return;
    }
    const dropDownRef: DropDownRef = new DropDownRef(this.params, this.rootEl, this.contentTemplate);

    // TODO find current place for changeDetectRef
    setTimeout(() => this._dropDownService.open(dropDownRef), 0);

    dropDownRef.afterClose
      .subscribe(() => {
        this.onClose.emit();
        this._dropDownRef = null;
        if (!this._chr['destroyed']) {
          this._chr.detectChanges();
        }
      });

    this._dropDownRef = dropDownRef;
    this.onOpen.emit();
  }

  close(): void {
    if (this.isDisabled) {
      return;
    }
    if (this._dropDownRef) {
      this._dropDownRef.close();
    }
  }

  toggle(event: MouseEvent): void {
    event.stopPropagation();
    if (this.isDisabled) {
      return;
    }

    this.isOpen ? this.close() : this.open();
  }
}
