<!-- scrollable wrap -->
<div class="scroll-wrap" #scrollWrapper>

  <!-- table -->
  <table #tableTpl>

    <!-- table header -->
    <thead #tableHeaderTpl>

    <tr #tableHeaderRowTpl>
      <ng-container *ngFor="let header of headers; trackBy: trackByKey"
                    [ngTemplateOutlet]="headerTemplate || defaultHeader"
                    [ngTemplateOutletContext]="{ header: header }">
      </ng-container>
    </tr>

    <tr>
      <td class="header-line"
          [attr.colspan]="headers.length">
      </td>
    </tr>

    </thead><!-- / table header -->

    <!-- table body -->
    <tbody #tableBodyTpl>

    <ng-container *ngIf="firstRowTemplate"
                  [ngTemplateOutlet]="firstRowTemplate">
    </ng-container>

    <ng-container *ngIf="!manageFiltersExternally">
      <ng-container *ngFor="let row of _data | filterByKeys : searchableKeys : searchBy"
                    [ngTemplateOutlet]="rowTemplate"
                    [ngTemplateOutletContext]="{ row: row }">
      </ng-container>
    </ng-container>

    <ng-container *ngIf="manageFiltersExternally">
      <ng-container *ngFor="let row of _data; let index = index"
                    [ngTemplateOutlet]="rowTemplate"
                    [ngTemplateOutletContext]="{ row: row, index: index }">
      </ng-container>
    </ng-container>

    <tr *ngIf="infinityScroll"
        #tableHeaderShadowTpl class="shadow-tr">
      <ng-container *ngFor="let header of headers"
                    [ngTemplateOutlet]="headerTemplate || defaultHeader"
                    [ngTemplateOutletContext]="{ header: header }">
      </ng-container>
    </tr>

    </tbody><!-- / table body -->

  </table><!-- / table -->

</div><!-- / scrollable wrap -->

<!-- default header template -->
<ng-template let-header="header"
             #defaultHeader>

  <th class="table-th"
      [class.table-th--sortable]="header.sortable && !pending"
      [style.backgroundColor]="header.backgroundColor ? header.backgroundColor : null"
      (click)="header.sortable ? sort(header) : null">

    <div class="th__content align--{{ header.align || 'left' }}">

      <bl-custom-checkbox (change)="changeCheckbox($event)"
                          [checked]="isAllChecked"
                          [disabled]="header?.disabled"
                          [tooltipText]="header?.tooltipText"
                          *ngIf="header.checkbox">
      </bl-custom-checkbox>

      <span class="th__name">{{ header.title }}</span>

      <i class="icon icon-arrow--{{ _sortDirection }}"
         [hidden]="!header.sortable || header.key !== _sortBy">
      </i>
    </div>

  </th>

</ng-template><!-- / default header template -->

<div blScrollMeasure (scrollWidth)="scrollWidth = $event"></div>
