import { IBrowseEntities } from '../interfaces/browse';
import { IGeoTargets } from '../interfaces/geography';

export enum GeoEntitiesTypes {
  City = 1,
  County,
  State,
  Country,
  ZipCode,
  SCFCode,
  ZipRange,
  SCFRange,
  DMACode,
}

export const DEFAULT_COUNTRIES: IBrowseEntities = [
  { id: 1, name: 'USA' }
];

export const GEO_TARGETS: IGeoTargets = {
  STATES: { id: 2, name: 'by State', type: GeoEntitiesTypes.State, selected: false },
  COUNTIES: { id: 3, name: 'by County', type: GeoEntitiesTypes.County },
  CITIES: { id: 4, name: 'by City', type: GeoEntitiesTypes.City },
  ZIP_CODES: { id: 5, name: 'by Zip Code', type: GeoEntitiesTypes.ZipCode },
  DMA_CODES: { id: 6, name: 'by Designated Market Area (DMA)', type: GeoEntitiesTypes.DMACode, selected: false },
  SCF_CODES: { id: 7, name: 'by SCF Code', type: GeoEntitiesTypes.SCFCode }
};

export const PLACE_TYPES_FOR_HIDE_ON_MAP: GeoEntitiesTypes[] = [
  GeoEntitiesTypes.SCFCode,
  GeoEntitiesTypes.ZipRange,
  GeoEntitiesTypes.SCFRange,
  GeoEntitiesTypes.DMACode
];

export const PLACE_TYPES_TO_EXPAND_ZIP_CODES: number[] = [7];

export const MAP_COLORS: { include: string; exclude: string } = {
  include: '#009ECE',
  exclude: '#7B0210'
};

export const DEFAULT_AREA_RADIUS: number = 25;
export const MAX_AREA_RADIUS: number = 250;
export const MIN_AREA_RADIUS: number = 1;

export const MAX_MAP_ZOOM: number = 8;

export const STATE_ABBREVIATIONS: { [key: string]: string } = {
  'Alabama': 'AL',
  'Alaska': 'AK',
  'American Samoa': 'AS',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Guam': 'GU',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Marshall Islands': 'MH',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Palau': 'PW',
  'Pennsylvania': 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virgin Islands': 'VI',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY'
};

export const STATE_NAMES: { [key: string]: string } = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AS': 'American Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  'FM': 'Federated States Of Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'MP': 'Northern Mariana Islands',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
};

export enum ACTIVE_TAB {
  SEARCH,
  BROWSE
}

export const GEO_TAG_LOCATION_NAMES: { UNITED_STATES: string } = {
  UNITED_STATES: 'United States'
};
