import { IPidTargetingTabs, IPIDS_TARGETING_TABS, IPIDS_TARGETING_TABS_NAMES } from '../interfaces/pids';

import { PRODUCT_ENTITY_TYPES } from '@shared/constants/data/entity';
import { LIST_RECORDS_TYPES, LIST_RELATE_TO_PERSONNEL_TYPE } from '@shared/constants/data/list-types';

export const PIDS_TARGETING_TABS: IPidTargetingTabs = [{
  id: IPIDS_TARGETING_TABS.SEARCH,
  name: IPIDS_TARGETING_TABS_NAMES.SEARCH,
  selected: true
}, {
  id: IPIDS_TARGETING_TABS.UPLOAD,
  name: IPIDS_TARGETING_TABS_NAMES.UPLOAD,
  selected: false
}];

export const MULTI_PID_ERRORS: { [key: string]: (pids: Array<number>) => string } = Object.freeze({
  invalid: (pids: Array<number>) => `The following PIDs are invalid or do not exist: ${ pids.join(', ') }`,
  duplicated: (pids: Array<number>) =>
    `The following PIDs have already been added to your targeting and will not be duplicated: ${ pids.join(', ') }`,
  wrongSeat: (pids: number[]) => `The following PIDs are invalid or do not exist: ${ pids.join(', ') }`,
  includedAsParent: (pids: number[]) =>
    `The following institutions were not processed because they are already included as part of your parent PIDs: ${ pids.join(', ') }`,
  excludedAsParent: (pids: number[]) =>
    `The following institutions were not processed because they are already excluded as part of your parent PIDs: ${ pids.join(', ') }`,
});

export const FILE_NAME_PIDS: string = 'pidsFile';
export const FILE_NAME_PID_NID: string = 'pidNids';

export const getFileName: (entityType: PRODUCT_ENTITY_TYPES, recordsType: LIST_RECORDS_TYPES) => string =
  (entityType: PRODUCT_ENTITY_TYPES, recordsType: LIST_RECORDS_TYPES): string =>
  entityType === PRODUCT_ENTITY_TYPES.CUSTOM_LIST && LIST_RELATE_TO_PERSONNEL_TYPE.includes(recordsType)
    ? FILE_NAME_PID_NID
    : FILE_NAME_PIDS;
