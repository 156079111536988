import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IAccountSeat, SEATS } from '../../interfaces/marketview';

@Component({
  selector: 'bl-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductItemComponent {

  @Input() isOpened: boolean;
  @Input() data: IAccountSeat;

  get isSeatLite(): boolean {
    return this.data && this.data.id === SEATS.Lite;
  }

  get isSeatState(): boolean {
    return this.data && this.data.id === SEATS.State;
  }

  get isAddOnComparedStates(): boolean {
    return this.data && typeof this.data.typeNational === 'number';
  }

  get isStates(): number {
    return this.data && this.data.states && this.data.states.length;
  }

  get isStatesNational(): number {
    return this.data && this.data.statesNational && this.data.statesNational.length;
  }

  get showCalcProducts(): string {
    return this.data ? `${ this.data.total - this.data.assigned } open (of ${ this.data.total })` : '';
  }

  get isToggle(): number | boolean {
    return !this.isSeatLite && (this.isStates || this.isStatesNational || this.isAddOnComparedStates || this.isSeatState);
  }

}
