import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'bl-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavComponent {
  @ViewChild('content') contentEl: ElementRef;

  @Input() isOpen: boolean;

  @Output() close: EventEmitter<any> = new EventEmitter();

  @HostListener('document:touchend', ['$event'])
  onOutsideClick(event: MouseEvent): void {
    if (this.isOpen && !this.contentEl.nativeElement.contains(event.target)) {
      this.close.emit();
      event.preventDefault();
    }
  }

}
