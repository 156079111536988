<span class="count">{{ count | shortNumber }}</span>
<span class="title">{{ title }}</span>

<div class="nav-tooltip-wrapper" #navTooltipWrapperRef
     [class.nav-tooltip-wrapper--visible]="tooltipTemplate && tooltipIsOpen">
  <div class="nav-tooltip" #navTooltipRef>
    <ng-container [ngTemplateOutlet]="tooltipTemplate"></ng-container>
  </div>
</div>

