<label class="custom-checkbox"
       [class.disabled]="disabled"
       [blTooltip]="tooltipText ? tooltipText : null"
       [positionFixedAndCalc]="tooltipIsCalPosition">

  <input type="checkbox"
         [disabled]="disabled"
         [checked]="checked"
         (change)="onChange($event)">

  <span class="fake-checkbox">
    <i class="icon-checked"></i>
  </span>

  <span class="content">
    <ng-content></ng-content>
  </span>

</label>
