import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { IDownloadByLink } from '../../interfaces/download-by-link';

@Component({
  selector: 'bl-download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadLinkComponent implements OnChanges {

  readonly defaultAttributeTarget: string = '_self';

  @ViewChild('link') linkEl: ElementRef;

  @Input() url: IDownloadByLink;

  constructor(private renderer: Renderer2,
              private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.url && changes.url && changes.url.currentValue) {
      const { link, target }: IDownloadByLink = this.url;

      this.renderer.setAttribute(this.linkEl.nativeElement, 'target', target ? target : this.defaultAttributeTarget);
      this.linkEl.nativeElement.href = link;
      this.linkEl.nativeElement.click();
      this.linkEl.nativeElement.href = '';

      this.cdr.detectChanges();
    }
  }
}
