import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';

import { IControlOption, IControlOptions } from '@shared/interfaces/forms';


@Component({
  selector: 'bl-flat-drop-down',
  templateUrl: './flat-drop-down.component.html',
  styleUrls: ['./flat-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatDropDownComponent {
  @Input() items: IControlOptions;
  @Input() placeholder: string = 'Refresh Schedule';
  @Input() dropDownTargetTemplate: TemplateRef<any>;
  @Input() menuItemsTemplate: TemplateRef<any>;

  @Output() onSelectItem: EventEmitter<IControlOption> = new EventEmitter<IControlOption>();

  selectedOption: IControlOption = null;

  constructor(private _cdr: ChangeDetectorRef) {
  }

  trackByFn(index: number, item: IControlOption): number {
    return item.id;
  }

  selectItem(item: IControlOption, value: boolean): void {
    this.selectedOption = value ? item : null;
    this._cdr.detectChanges();
    this.onSelectItem.emit({ ...item, value });
  }

}
