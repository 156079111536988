import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { WindowRef } from '@core/refs/window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyInterceptor implements HttpInterceptor {
  constructor(private windowRef: WindowRef) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {

          if (error.status === 403 && error.error && error.error.data) {
            const { errorType, surveyUrl }: any = error.error.data;

            if (errorType === 'SURVEY' && surveyUrl) {
              this.windowRef.nativeElement.location.href = surveyUrl;
            }
          }

          return throwError(error);
        })
      );
  }
}
