import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CoreState } from '@core/store/reducers';
import { getMapAccountUsersAddOns } from '../../store/selectors/account-users.selector';

import { ITableHeader } from '@ui/tables/components/table/table.component';

import {
  IAccountUser,
  IAccountUserAddOn,
  IAccountUserAddOns,
  IAccountUserSeat,
  SEATS,
  USER_STATUS
} from '../../interfaces/marketview';

import { WINDOW_POINTS } from '@ui/view-points/constants/view-points';
import {
  ACCOUNT_USERS_TABLE_COMPUTED_PROPERTIES,
  PROFILE_ACCOUNT_USER_TABLE_HEADERS
} from '../../constants/marketview';
import { RolesKeys } from '../../constants/roles';


@Component({
  selector: 'bl-products-simple',
  templateUrl: './products-simple.component.html',
  styleUrls: ['./products-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsSimpleComponent {
  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;
  readonly tableHeaders: ITableHeader[] = PROFILE_ACCOUNT_USER_TABLE_HEADERS;
  readonly seatStatePro: SEATS.State = SEATS.State;
  readonly computedProperties: typeof ACCOUNT_USERS_TABLE_COMPUTED_PROPERTIES = ACCOUNT_USERS_TABLE_COMPUTED_PROPERTIES;
  readonly rolesKeys: typeof RolesKeys = RolesKeys;
  readonly usersStatuses: typeof USER_STATUS = USER_STATUS;

  isMobile: boolean;

  accountUsers$: Observable<IAccountUser[]> = this.store.pipe(select(getMapAccountUsersAddOns));

  constructor(private store: Store<CoreState>) {
  }

  isCheckedAddOn(_addOns: IAccountUserAddOns = [], _stateId: number): boolean {
    const found: IAccountUserAddOn = _addOns.find((item: IAccountUserAddOn) => item.stateId === _stateId);
    return !!found;
  }

  trackByFn(index: number, seat: IAccountUserSeat): number {
    return seat.id;
  }
}
