import { IRemoveCreditInfo } from '@modules/profile/interfaces/pop-ups';

export enum INFO_DATA {
  TITLE = 'This user has data.',
  TITLE_WITH_CREDITS = 'This user has credits.',
  TITLE_WITH_EMAILS = 'This user has emails.',
  TITLE_WITH_CREDITS_AND_EMAILS = 'This user has credits and emails.',
  BODY = 'To delete this user and retain the data they have remaining, please call (800) 333-8802 to speak with a representative.',
  HEADER = '!',
  BUTTON_TEXT = 'Call (800) 333-8802'
}

export const getTextWithCredits: (credits: number) => string = (credits: number): string =>
  `To delete this user and retain the ${ credits } credits they have remaining, please call (800) 333-8802 to speak with a representative.`;

export const getTextWithEmails: (emails: number) => string = (emails: number): string =>
  `To delete this user and retain the ${ emails } emails they have remaining, please call (800) 333-8802 to speak with a representative.`;

export const getTextWithCreditsAndEmails: (infoData: IRemoveCreditInfo) => string = (infoData: IRemoveCreditInfo): string =>
  `To delete this user and retain the ${ infoData.credits } credits and ${ infoData.emails } emails they have remaining,
   please call (800) 333-8802 to speak with a representative.`;
