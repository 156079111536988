<section class="section"
         [attr.data-private]="securityType.REDACT">
  <h5 class="section__headline">
    My Personal Cards
  </h5>

  <p class="section__description">
    Only you can see and use these cards to purchase credits.
    Credits purchased with a Personal card can be used by all users in the company.
  </p>

  <div class="cards" data-testid="cards-list">
    <bl-credit-card-details *ngFor="let cardInfo of creditCards$ | async"
                            [cardInfo]="cardInfo">
    </bl-credit-card-details>
  </div>
</section>
