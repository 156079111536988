import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ActionWithPayload } from '@shared/interfaces/store';
import { showNotificationAction } from '../../../notifications/store/actions/notification.action';
import * as authActions from '../actions/auth.action';
import * as inviteActions from '../actions/sign-up-by-invite.action';

import { ProfileService } from '@core/services/profile.service';
import { AuthService } from '../../services/auth.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { IServerError } from '@shared/interfaces/server-error';
import { ISignUpByInvitePayload, ISignUpByInviteSuccessPayload } from '../../interfaces/formsActionsData';
import { ILoginResponse, INewUser, IUserData } from '../../interfaces/user';

import { NOTIFICATION_TYPES } from '@core/constants/notifications';

@Injectable()
export class SignUpByInviteEffect {

  constructor(private actions$: Actions,
              private service: AuthService) {
  }

  signUp$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(inviteActions.signUpByInviteAction),
      switchMap(({ payload: { remember, ...payload } }: ActionWithPayload<ISignUpByInvitePayload>) =>
        this.service.signUp(payload as INewUser)
          .pipe(
            map((data: ILoginResponse) => inviteActions.signUpByInviteSuccessAction({ data, remember })),
            catchError((error: IServerError) => {
              if (error.errors.hasOwnProperty('email')) {
                return of(showNotificationAction({
                  message: error.errors.email[0],
                  type: NOTIFICATION_TYPES.ERROR,
                  canClose: true
                }));
              }

              return of(inviteActions.signUpByInviteErrorAction(error));
            })
          )
      ),
      catchErrorWithErrorType
    ));

  signUpSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(inviteActions.signUpByInviteSuccessAction),
      tap(({ payload: { data, remember } }: ActionWithPayload<ISignUpByInviteSuccessPayload>) => {
        this.service.onSignIn(data, remember, true);
        ProfileService.setUserData(data as IUserData);
      }),
      map(({ payload: { data, remember } }: ActionWithPayload<ISignUpByInviteSuccessPayload>) =>
        authActions.signInSuccessAction({ response: data, remember, firstLogin: true })),
      catchError((error: IServerError) => of(authActions.signUpErrorAction(error)))
    ));
}
