export enum GA_CATEGORIES {
  PURCHASE = 'Purchase',
  SIGN_UP = 'Sign-up',

  FIRST_SIGN_UP = 'Sign-up',

  SHOW_EMAIL = 'Show Email',
  NEW_USER = 'New User',
  SET = 'set',
  SEND = 'send'
}

export enum GA_ACTIONS {
  CLICK = 'click',
  PAGE = 'page',
  PAGE_VIEW = 'pageview',

  SUBMIT_PHX_FORM = 'Submit-PHX-form',

  CLICK_PURCHASE = 'Click-purchase',
  CLICK_MAIN = 'Click-main',
  CLICK_ECOMM = 'Click-ecomm',
  NEW_USER_SIGN_UP = 'New user through sign-up'
}

export enum GA_LABEL {
  PURCHASE = 'Purchase',
  CLICKED = 'clicked',
  SUCCESS = 'Success',
  SIGN_UP = 'Sign-up',
  SIGN_UP_CHECKOUT = 'Sign-up+checkout',

  SET_NEW_PASSWORD = 'Set-new-password'
}
