<div class="show-again" [class.no-margin]="withoutMargin">

  <input (change)="onChange($event)"
         [ngModel]="doNotShowPopUp"
         class="show-again__input"
         type="checkbox"
         id="show-delete-again">

  <label class="show-again__label"
         for="show-delete-again"> {{ text }}
  </label>

</div>
