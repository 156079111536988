import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

export type IMobileTableData<T> = T[];

@Component({
  selector: 'bl-mobile-table',
  templateUrl: './mobile-table.component.html',
  styleUrls: ['./mobile-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTableComponent {
  @Input() data: IMobileTableData<any>;
  @Input() rowTemplate: TemplateRef<any>;

  @Input() theme: string;

  @Input() searchBy: string = '';
  @Input() searchableKeys: string[];
}
