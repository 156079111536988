<span class="title">products</span>

<bl-drop-down [bodyPosition]="'center'"
              [withoutArrow]="true"
              [topOffset]="-48">

  <ng-template blDropDownTarget>

    <span class="link">
      <i class="icon-circle-plus"></i>
      <span class="link--name">Add</span>
    </span>

  </ng-template>

  <ng-template blDropDownContent>

    <bl-choose-purchase></bl-choose-purchase>

  </ng-template>

</bl-drop-down>
