import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/pids-tabs.actions';

import { IPidTargetingTab, IPidTargetingTabs } from '../../interfaces/pids';

import { PIDS_TARGETING_TABS } from '../../constants/pids';

export interface IPidTargetingState {
  tabs: IPidTargetingTabs;
}

const initialState: IPidTargetingState = {
  tabs: [...PIDS_TARGETING_TABS]
};

const selectPidTargetingTab: OnReducer<IPidTargetingState, ActionType<Payload<any>>> = (state: IPidTargetingState, { payload }: Payload<any>) => ({
  ...state,
  tabs: state.tabs.map((tab: IPidTargetingTab) => ({
    ...tab,
    selected: tab.id === payload.id
  }))
});

export const reducer: ActionReducer<IPidTargetingState> = createReducer<IPidTargetingState>(
  initialState,

  on(actions.selectPidTargetingTabAction, selectPidTargetingTab)
);

export function pidsTabsReducer(state: IPidTargetingState, action: Action): IPidTargetingState {
  return reducer(state, action);
}

export const tabs: GetFromState<IPidTargetingTabs, IPidTargetingState> = (state: IPidTargetingState): IPidTargetingTabs => state.tabs;
export const selectedTab: GetFromState<IPidTargetingTab, IPidTargetingTabs> = (_tabs: IPidTargetingTabs): IPidTargetingTab => _tabs.find((tab: IPidTargetingTab) => tab.selected);
