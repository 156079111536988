<label class="label theme--{{ theme }}">

  <input type="radio"
         [name]="_name"
         [disabled]="isDisabled"
         [value]="value"
         [checked]="initialChecked"
         (change)="onChange($event)"
         class="card-input-element"/>

  <span class="card card-body">
    <ng-content select=".content-icon"></ng-content>

    <span class="name">{{ label }}</span>
    <span class="description">{{ description }}</span>

    <ng-content select=".content-description"></ng-content>

    <span *ngIf="subControlName">
      <bl-custom-checkbox
        [checked]="subControlValue"
        (change)="onSubControlChange.emit({name: subControlName, checked: $event})">
      </bl-custom-checkbox>
      <span class="description">{{ subControlDescription }}</span>
    </span>
  </span>

</label>
