import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';

import { fromEvent, merge, timer, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { PaginationContentDirective } from '@shared/modules/directives/directives/pagination-content-directive.directive';

import { WindowRef } from '@core/refs/window-ref.service';

import { DEFAULT_PAGINATION } from '@modules/dashboard/constants/default-pagination';

@Directive({
  selector: '[blInfinityScrollPagination]'
})
export class InfinityScrollPaginationDirective implements AfterContentInit, OnDestroy {

  private destroyer$: Subject<void> = new Subject();
  private _scrollTarget: any; // scroll target: self or window element;
  private readonly _minLimit: number = DEFAULT_PAGINATION.limit; // min limit for next page

  @Input() isScrollOnSelf: boolean; // if should listen scroll on self element;
  @Input() freeze: boolean; // freeze flag set to true if data is loading for example;
  // emit when should load next page;
  @Output() onNextPage: EventEmitter<number> = new EventEmitter<number>();
  // @ContentChildren for get { height, width } of one  pagination element;
  // "blPaginationContent" should be added to template;
  @ContentChildren(PaginationContentDirective, { read: ElementRef }) listItems: QueryList<ElementRef>;

  constructor(private elementRef: ElementRef,
              private window: WindowRef) {
  }

  ngAfterContentInit(): void {
    this.init();
  }

  init(): void {
    if (!this.listItems) {
      throw new Error(
        'You should add "blPaginationContent" directive to pagination element for get element height'
      );
    } else {
      this.createScrollTarget();
      this.createSubscriber();
    }
  }

  createScrollTarget(): void {
    this._scrollTarget = this.isScrollOnSelf
      ? this.elementRef.nativeElement
      : this.window.nativeElement;
  }

  createSubscriber(): void {
    const timer$: Observable<number> = timer(0);
    const scroll$: Observable<unknown> = fromEvent(this._scrollTarget, 'scroll');
    const resize$: Observable<unknown> = fromEvent(this.window.nativeElement, 'resize');

    merge(timer$, scroll$, resize$)
      .pipe(
        takeUntil(this.destroyer$),
        filter(() => !this.freeze && this.shouldLoad()),
        map(() => this.getLimit())
      )
      .subscribe((limit: number) => this.onNextPage.emit(limit));
  }

  shouldLoad(): boolean {
    const currentPageOffset: number = this._scrollTarget.pageYOffset;
    const heightProperty: string = this.isScrollOnSelf
      ? 'clientHeight'
      : 'innerHeight';

    return currentPageOffset >= (this.elementRef.nativeElement.clientHeight - this._scrollTarget[heightProperty]);
  }

  getLimit(): number {
    const { outerHeight, outerWidth }: any = // item params;
      this.listItems.first.nativeElement.getBoundingClientRect();
    const { width }: ClientRect = // pagination target params;
      this.elementRef.nativeElement.getBoundingClientRect();
    const itemsInOweRow: number = // items in one row;
      Math.floor(width / outerWidth);

    const limit: number = Math.floor(this.window.nativeElement.innerHeight / outerHeight * itemsInOweRow);
    return limit > this._minLimit ? limit : this._minLimit;
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
