import { DOCUMENT } from '@angular/common';
import {
  forwardRef,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';

import { LayoutService } from '@core/services/layout.service';

import { IControlOption, IControlOptions } from '@shared/interfaces/forms';

import { BaseSelect } from '@shared/models/base-select';

@Component({
  selector: 'bl-rounded-select',
  templateUrl: './rounded-select.component.html',
  styleUrls: ['./rounded-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RoundedSelectComponent),
    multi: true,
  }]
})
export class RoundedSelectComponent extends BaseSelect implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {

  @ViewChild('list') listEl: ElementRef;
  @ViewChild('wrapper') wrapperEl: ElementRef;
  @ViewChildren('listItem') listItems: QueryList<ElementRef>;

  @Input() options: IControlOptions;
  @Input() alternativeOptions: IControlOptions;
  @Input() placeholder: string;
  @Input() formControlName: string;
  @Input() name: string;
  @Input() unselectedLabel: string;
  @Input() errors: { [key: string]: any };
  @Input() checkSelectedOneOption: boolean = false;
  @Input() defaultLabel: string = 'None';
  @Input() isShowAdditionalListItems: boolean = false;
  @Input() isCreateNewItems: boolean = false;

  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() onClickCreateBtn: EventEmitter<void> = new EventEmitter();

  isOpen: boolean = false;

  constructor(@Inject(DOCUMENT) _document: any,
              private layoutService: LayoutService) {
    super(_document);
  }

  ngOnInit(): void {
    this.listenKeyboard();
  }

  ngAfterViewInit(): void {
    if (this.checkSelectedOneOption && this.options.length === 1 && !this.options[0].disabled) {
      this.selectOption(null, this.options[0]);
    }

    this.layoutService.isClickOnDocumentWithStopPropagate
      .pipe(
        takeUntil(this.destroyer$)
      ).subscribe((state: boolean) => {
      if (state) {
        this.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent): void {
    if (!this.wrapperEl.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  filterKeyDown = () => this.isOpen && !!this.options.length;

  open = () => {
    this.isOpen = true;
    this.propagateTouch();
  }
  close = () => {
    this.isOpen = false;
    this.propagateTouch();
  }
  toggle = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isOpen) {
      this.layoutService.onClickDocumentWithStopPropagate(true);
    }

    this.isOpen ? this.close() : this.open();
  }

  handledOnClickCreateBtn(event: MouseEvent): void {
    event.stopPropagation();
    this.onClickCreateBtn.emit();
  }

  trackByFn(index: number, option: IControlOption): any {
    return option.value;
  }
}
