import { SEGMENT_TYPES } from '@modules/cdl/cdl-list/constants';
import { CDL_LIST_PATHS } from '@modules/cdl/cdl-list/constants/cdl-list-paths';

export enum CDL_LIST_PAGES {
  SEGMENT_BUILDER_INSTITUTIONS = 1,
  SEGMENT_BUILDER_PERSONNEL,
  ADD_ONS,
  ORDER_REVIEW_INSTITUTIONS,
  ORDER_REVIEW_PERSONNEL,
  ORDER_REVIEW_SUMMARY_COUNTS,
  ORDER_REVIEW_SETTINGS,
  SUMMARY_INSTITUTIONS,
  SUMMARY_PERSONNEL,
  SUMMARY_COUNTS,
  SUMMARY_SETTINGS,
  SUMMARY_HISTORY,
  ORDER_REVIEW_HISTORY
}

export const CDL_LIST_ROUTER_PATH_BY_ID: Readonly<{ [key: number]: CDL_LIST_PATHS }> = Object.freeze({
  [CDL_LIST_PAGES.SEGMENT_BUILDER_INSTITUTIONS]: CDL_LIST_PATHS.INSTITUTIONS,
  [CDL_LIST_PAGES.SEGMENT_BUILDER_PERSONNEL]: CDL_LIST_PATHS.PERSONNEL
});

const ORDER_SUMMARY_COUNTS: string = `${ CDL_LIST_PATHS.ORDER_SUMMARY }/${ CDL_LIST_PATHS.ORDER_SUMMARY_COUNTS }`;

const URLS_FOR_SFL_CDL_LIST: { [key: number]: number | string } = {
  [CDL_LIST_PAGES.SEGMENT_BUILDER_INSTITUTIONS]: SEGMENT_TYPES.INSTITUTIONS,
  [CDL_LIST_PAGES.SEGMENT_BUILDER_PERSONNEL]: SEGMENT_TYPES.PERSONNEL,
  [CDL_LIST_PAGES.ADD_ONS]: CDL_LIST_PATHS.APPEND,
  [CDL_LIST_PAGES.ORDER_REVIEW_INSTITUTIONS]: `${ CDL_LIST_PATHS.PURCHASE }/${ CDL_LIST_PATHS.INSTITUTIONS }`,
  [CDL_LIST_PAGES.ORDER_REVIEW_PERSONNEL]: `${ CDL_LIST_PATHS.PURCHASE }/${ CDL_LIST_PATHS.PERSONNEL }`,
  [CDL_LIST_PAGES.ORDER_REVIEW_SUMMARY_COUNTS]: `${ CDL_LIST_PATHS.PURCHASE }/${ ORDER_SUMMARY_COUNTS }`,
  [CDL_LIST_PAGES.ORDER_REVIEW_SETTINGS]: `${ CDL_LIST_PATHS.PURCHASE }/${ CDL_LIST_PATHS.SETTINGS }`,
  [CDL_LIST_PAGES.ORDER_REVIEW_HISTORY]: `${ CDL_LIST_PATHS.PURCHASE }/${ CDL_LIST_PATHS.HISTORY }`,
};

const URLS_FOR_ACTIVE_CDL_LIST: { [key: number]: string } = {
  [CDL_LIST_PAGES.SUMMARY_INSTITUTIONS]: `${ CDL_LIST_PATHS.SUMMARY }/${ CDL_LIST_PATHS.INSTITUTIONS }`,
  [CDL_LIST_PAGES.SUMMARY_PERSONNEL]: `${ CDL_LIST_PATHS.SUMMARY }/${ CDL_LIST_PATHS.PERSONNEL }`,
  [CDL_LIST_PAGES.SUMMARY_COUNTS]: `${ CDL_LIST_PATHS.SUMMARY }/${ ORDER_SUMMARY_COUNTS }`,
  [CDL_LIST_PAGES.SUMMARY_SETTINGS]: `${ CDL_LIST_PATHS.SUMMARY }/${ CDL_LIST_PATHS.SETTINGS }`,
  [CDL_LIST_PAGES.SUMMARY_HISTORY]: `${ CDL_LIST_PATHS.SUMMARY }/${ CDL_LIST_PATHS.HISTORY }`,
};

export const CDL_LIST_PAGE_URLS: Readonly<{ [key: number]: number | string | SEGMENT_TYPES }> =
  Object.freeze({ ...URLS_FOR_SFL_CDL_LIST, ...URLS_FOR_ACTIVE_CDL_LIST });

export const SKIP_CDL_LIST_PAGE_URLS: CDL_LIST_PAGES[] =
  [CDL_LIST_PAGES.SEGMENT_BUILDER_INSTITUTIONS, CDL_LIST_PAGES.SEGMENT_BUILDER_PERSONNEL];

export const IS_INCLUDE_SEGMENT_ID: CDL_LIST_PAGES[] = [
  CDL_LIST_PAGES.ADD_ONS,
  CDL_LIST_PAGES.ORDER_REVIEW_INSTITUTIONS,
  CDL_LIST_PAGES.ORDER_REVIEW_PERSONNEL,
  CDL_LIST_PAGES.SUMMARY_INSTITUTIONS,
  CDL_LIST_PAGES.SUMMARY_PERSONNEL
];
