import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Action, Store } from '@ngrx/store';

import { defer, of, Observable } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { ActionWithPayload } from '@shared/interfaces/store';
import { getCreditsAction } from '../../../credits/store/actions';
import { getAccountUsersAction } from '../actions/account-users.action';
import { getAccountSeatsAction } from '../actions/account.action';
import * as addUserAccountActions from '../actions/add-user-account.action';
import { getAddUserPopUpPayload } from '../selectors/add-user-account.selector';

import { AddUserAccountPopUpComponent } from '@ui/pop-up/components/add-user-account-pop-up/add-user-account-pop-up.component';

import { PopUpService } from '@ui/pop-up/services/pop-up/pop-up.service';
import { AccountUsersService } from '../../services/account-users.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { IServerError } from '@shared/interfaces/server-error';
import { IAddUserAccountPopUpData } from '@ui/pop-up/interfaces';
import { IAddUserPopUpPayload, ICreateAccountUserForRequest } from '../../interfaces/marketview';


@Injectable()
export class AddUserAccountEffect {

  constructor(private _actions$: Actions,
              private _accountUsersService: AccountUsersService,
              private _popUpService: PopUpService,
              private _store: Store<CoreState>) {
  }

  addAccountUser$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(addUserAccountActions.addAccountUserAction),
      switchMap(({ payload: newAccountUser }: ActionWithPayload<ICreateAccountUserForRequest>) => this._accountUsersService
        .addUser(newAccountUser)
        .pipe(
          map(() => addUserAccountActions.addAccountUserSuccessAction()),
          catchError((error: IServerError) => of(addUserAccountActions.addAccountUserErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  userAddedSuccessfully$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(addUserAccountActions.addAccountUserSuccessAction),
      switchMap(() => [
        getAccountUsersAction(),
        getAccountSeatsAction(),
        addUserAccountActions.closeUserPopUpAction(),
        getCreditsAction()
      ])
    ));

  closePopUp$: Observable<Action> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(addUserAccountActions.closeUserPopUpAction),
      tap(() => this._popUpService.close())
    )), { dispatch: false });


  openAddUserPopUp$: Observable<unknown> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(addUserAccountActions.openAddUserPopUpAction),
      withLatestFrom(this._store.pipe(select(getAddUserPopUpPayload))),
      tap(([action, addUserPopUpPayload]: [Action, IAddUserPopUpPayload]) => this._popUpService
        .open<AddUserAccountPopUpComponent, IAddUserAccountPopUpData>(AddUserAccountPopUpComponent, {
          data: {
            title: 'Add a User',
            ...addUserPopUpPayload
          },
          withOverlayBackground: true,
        })),
    )), { dispatch: false });

}
