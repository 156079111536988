import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';
import { objectToQueryParams } from '@shared/utils/object-to-query-params';
import { errorHandler, pluckAndCatch } from '@shared/utils/response-formater';

import { IListAssign } from '@modules/dashboard/interfaces';
import { IListCreateData } from '@modules/list-create/interfaces/list-create';
import { ISegmentData } from '@modules/segment/interfaces/segment';
import { IDataListSummary, IListData, IListIdWithSelectedRecordType, ISummaryList } from '@shared/interfaces/list';

import { LIST_RECORDS_TYPES, LIST_TYPES } from '@shared/constants/data/list-types';
import { QUERY_PARAMS_BOOLEAN } from '@shared/constants/query-params';


@Injectable()
export class ListService {

  constructor(private _http: HttpClient) {
  }

  getList(listId: number): Observable<IListData> {
    return this._http.get(`${ environment.api.list.lists }/${ listId }`)
      .pipe(pluckAndCatch);
  }

  getListSegmentData(segmentId: number, withRemovedFilters: boolean = false): Observable<ISegmentData> {
    const params: HttpParams = new HttpParams()
      .set('withRemovedFilters', `${ withRemovedFilters ? QUERY_PARAMS_BOOLEAN.TRUE : QUERY_PARAMS_BOOLEAN.FALSE }`);

    return this._http.get(`${ environment.api.segment.main }/${ segmentId }`, { params })
      .pipe(pluckAndCatch);
  }

  create(data: IListCreateData): Observable<IListData> {
    return this._http.post(environment.api.list.lists, data)
      .pipe(pluckAndCatch);
  }

  duplicate(listId: number): Observable<void> {
    return this._http.post(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.duplicate }`, null)
      .pipe(errorHandler);
  }

  createCustomList(listIdWithSelectedRecordType: IListIdWithSelectedRecordType): Observable<void> {
    return this._http.post(`${ environment.api.customList.main }/${ environment.api.customList.createFromList }/${ listIdWithSelectedRecordType.listId }`, { recordsType: listIdWithSelectedRecordType.selectedListRecordType })
      .pipe(errorHandler);
  }

  delete(listId: number): Observable<void> {
    return this._http.delete(`${ environment.api.list.lists }/${ listId }`)
      .pipe(errorHandler);
  }

  rename(listId: number, name: string): Observable<IListData> {
    return this._http.patch(`${ environment.api.list.lists }/${ listId }`, { name })
      .pipe(pluckAndCatch);
  }

  changeType(listId: number, type: LIST_TYPES): Observable<IListData> {
    return this._http.patch(`${ environment.api.list.lists }/${ listId }`, { type })
      .pipe(pluckAndCatch);
  }

  changeRecordsType(listId: number, recordsType: LIST_RECORDS_TYPES): Observable<IListData> {
    let body: Params = { recordsType };

    if (recordsType === LIST_RECORDS_TYPES.BUILDINGS_ONLY) {
      body = { ...body, type: LIST_TYPES.DIRECT_MAIL };
    }

    return this._http.patch(`${ environment.api.list.lists }/${ listId }`, body)
      .pipe(pluckAndCatch);
  }

  isDownloadAvailableByToken(token: string): Observable<{ fileAvailable: boolean }> {
    return this._http.get(`${ environment.api.list.isAvailable }?token=${ token }`)
      .pipe(pluckAndCatch);
  }

  getListSummaryData(listId: number): Observable<IDataListSummary> {
    return this._http.get(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.summary }`).pipe(
      pluckAndCatch,
      map((summary: IDataListSummary) => {
        summary.list.lastModified *= 1000;
        summary.list.expiredAt *= 1000;

        return summary;
      }),
      catchErrorWithErrorType
    );
  }

  getRefreshData(listId: number, withAdditional: boolean = false): Observable<IDataListSummary> {
    return this._http.get(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.refresh }`,
      { params: objectToQueryParams({ withAdditional: withAdditional ? 1 : 0 }) })
      .pipe(
        pluck('data'),
        map((summary: IDataListSummary) => {
          summary.list.lastModified *= 1000;
          summary.list.expiredAt *= 1000;

          return summary;
        }),
        errorHandler
      );
  }

  purchaseList(listId: number): Observable<void> {
    return this._http.post(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.purchase }`, null)
      .pipe(errorHandler);
  }

  rePurchaseList(listId: number): Observable<ISummaryList> {
    return this._http.post(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.repurchase }`, null)
      .pipe(pluckAndCatch);
  }

  refreshList(listId: number, withAdditional: boolean = false): Observable<void> {
    const url: string = `${ environment.api.list.lists }/${ listId }/${ environment.api.list.refresh }`;
    const params: HttpParams = new HttpParams()
      .set('withAdditional', `${ withAdditional ? 1 : 0 }`);

    return this._http.post(url, null, { params })
      .pipe(errorHandler);
  }

  assignList(assignList: IListAssign): Observable<void> {
    const { id, email }: IListAssign = assignList;
    return this._http.patch(`${ environment.api.list.lists }/${ id }/${ environment.api.list.assign }`, { email })
      .pipe(errorHandler);
  }

  calculate(listId: number): Observable<void> {
    return this._http.post(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.calculate }`, null)
      .pipe(errorHandler);
  }

  calculateActiveListAppends(listId: number): Observable<void> {
    return this._http.post(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.calculateAppend }`, null)
      .pipe(errorHandler);
  }

  exportList(listId: number): Observable<void> {
    return this._http.post(`${ environment.api.list.lists }/${ listId }/${ environment.api.list.export }`, null)
      .pipe(errorHandler);
  }
}
