import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { ISegmentModuleState } from '../reducers';
import {
  alphabetByInstitutionType,
  alphabetByInstitutionTypeLoading,
  categoriesByInstitutionTypes,
  categoriesByInstitutionTypesLoading,
  categoryScrolledElementId,
  disciplineScrolledElementId,
  institutionTypesForAlphabet,
  institutionTypesForAlphabetLoading,
  institutionTypesForCategory,
  institutionTypesForCategoryLoading,
  institutionTypeScrolledElementId,
  jobsBrowseLoadingError,
  jobsByAlphabet,
  jobsByAlphabetLoading,
  jobsByCategory,
  jobsByCategoryLoading,
  jobsBySubject,
  jobsBySubjectLoading,
  openedAlphabetName,
  openedCategoryByInstitutionTypesId,
  openedInstitutionTypeForAlphabetName,
  openedInstitutionTypeForCategoryName,
  openedJobBrowse,
  openedSubjectByCategoryId,
  scrolledElementId,
  subjectsByCategory,
  subjectsByCategoryLoading,
  subjectScrolledElementId, JobsBrowseState
} from '../reducers/jobs-browse.reducer';

import { IBrowseEntities } from '@modules/segment/interfaces/browse';
import { IServerError } from '@shared/interfaces/server-error';

import { getSegmentModuleState } from './';

export const getJobsBrowseState: GetFromStateInSelectors<CoreState, JobsBrowseState> = createSelector(getSegmentModuleState,
  (state: ISegmentModuleState) => state.jobsBrowse);

export const getInstitutionTypesForCategory: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState,
  institutionTypesForCategory);
export const getCategoriesByInstitutionTypes: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState,
  categoriesByInstitutionTypes);
export const getJobsByCategory: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState, jobsByCategory);
export const getSubjectsByCategory: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState, subjectsByCategory);
export const getJobsBySubject: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState, jobsBySubject);
export const getInstitutionTypesForAlphabet: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState,
  institutionTypesForAlphabet);
export const getAlphabetByInstitutionType: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState,
  alphabetByInstitutionType);
export const getJobsByAlphabet: MemoizedSelector<CoreState, IBrowseEntities> = createSelector(getJobsBrowseState, jobsByAlphabet);

export const getInstitutionTypesForCategoryLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState,
  institutionTypesForCategoryLoading);
export const getCategoriesByInstitutionTypesLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState,
  categoriesByInstitutionTypesLoading);
export const getJobsByCategoryLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState, jobsByCategoryLoading);
export const getSubjectsByCategoryLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState,
  subjectsByCategoryLoading);
export const getJobsBySubjectLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState, jobsBySubjectLoading);
export const getInstitutionTypesForAlphabetLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState,
  institutionTypesForAlphabetLoading);
export const getAlphabetByInstitutionTypeLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState,
  alphabetByInstitutionTypeLoading);
export const getJobsByAlphabetLoading: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState, jobsByAlphabetLoading);

export const getOpenedInstitutionTypeForCategoryName: MemoizedSelector<CoreState, string> = createSelector(getJobsBrowseState,
  openedInstitutionTypeForCategoryName);
export const getOpenedCategoryByInstitutionTypesId: MemoizedSelector<CoreState, number> = createSelector(getJobsBrowseState,
  openedCategoryByInstitutionTypesId);
export const getOpenedSubjectByCategoryId: MemoizedSelector<CoreState, number> = createSelector(getJobsBrowseState,
  openedSubjectByCategoryId);
export const getOpenedInstitutionTypeForAlphabetName: MemoizedSelector<CoreState, string> = createSelector(getJobsBrowseState,
  openedInstitutionTypeForAlphabetName);
export const getOpenedAlphabetName: MemoizedSelector<CoreState, string> = createSelector(getJobsBrowseState, openedAlphabetName);

export const getJobsBrowseLoadingError: MemoizedSelector<CoreState, IServerError> = createSelector(getJobsBrowseState,
  jobsBrowseLoadingError);

export const getOpenedJobBrowse: MemoizedSelector<CoreState, boolean> = createSelector(getJobsBrowseState, openedJobBrowse);

export const getInstitutionTypeScrolledElementId: MemoizedSelector<CoreState, string> = createSelector(getJobsBrowseState,
  institutionTypeScrolledElementId);
export const getCategoryScrolledElementId: MemoizedSelector<CoreState, string> = createSelector(getJobsBrowseState,
  categoryScrolledElementId);
export const getDisciplineScrolledElementId: MemoizedSelector<CoreState, string> = createSelector(getJobsBrowseState,
  disciplineScrolledElementId);
export const getSubjectScrolledElementId: MemoizedSelector<CoreState, string> = createSelector(getJobsBrowseState,
  subjectScrolledElementId);

export const getJobBrowseScrolledElementId: MemoizedSelector<CoreState, string> = createSelector(getInstitutionTypeScrolledElementId,
  getCategoryScrolledElementId, getDisciplineScrolledElementId, getSubjectScrolledElementId, scrolledElementId);
