import { ElementRef, TemplateRef } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';

import { IDropDownParams } from '../interfaces/drop-down';

export class DropDownRef {

  private afterClose$: Subject<any> = new Subject<any>();
  private paramsUpdated$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private detach: Function;

  get afterClose(): Observable<any> {
    return this.afterClose$.asObservable();
  }

  get paramsUpdated(): Observable<any> {
    return this.paramsUpdated$.asObservable();
  }

  constructor(public params: IDropDownParams,
              public targetEl: ElementRef,
              public contentTemplate: TemplateRef<any>) {
  }

  close(): void {
    if (typeof this.detach === 'function') {
      this.detach();
    }


    this.afterClose$.next();
    this.afterClose$.complete();
    this.paramsUpdated$.complete();
  }

  setDetachFunction(fn: Function): void {
    if (this.detach) {
      throw new Error('DropDown detach function already set');
    }

    this.detach = fn;
  }

  updateParams(params: IDropDownParams): void {
    this.params = params;
    this.paramsUpdated$.next(null);
  }
}
