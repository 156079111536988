import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { PopUpConfig } from '../../models/pop-up-config';

import { POP_UP_CONFIG } from '../../injection-tokens';

@Component({
  selector: 'bl-pop-up-base',
  templateUrl: './pop-up-base.component.html',
  styleUrls: ['./pop-up-base.component.scss']
})
export class PopUpBaseComponent {

  @Input() maxWidth: number = null;
  @Output() close: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(@Inject(POP_UP_CONFIG) public config: PopUpConfig) {
  }

  onCloseBtn(event: Event): void {
    this.close.emit(event);
  }
}
