import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FORM_VALIDATION_ERRORS } from '@shared/constants/validators/forms-validations-errors';

import { Validators } from '@shared/validators/validators';

@Injectable()
export class AccountInfoService {

  constructor(private fb: FormBuilder) {
  }

  createPasswordForm(): FormGroup {
    const { confirmNewPassword, currentPassword, newPassword }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    return this.fb.group({
      oldPassword: ['', [
        Validators.required(currentPassword.required)
      ]],
      newPassword: ['', [
        Validators.password(newPassword.invalid),
        Validators.required(newPassword.required),
        Validators.validateDependent('confirmPassword')
      ]],
      confirmPassword: ['', [
        Validators.password(confirmNewPassword.invalid),
        Validators.required(confirmNewPassword.required),
        Validators.confirm(confirmNewPassword.invalid, ['newPassword'])]
      ],
    });
  }

}
