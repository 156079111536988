import { Params } from '@angular/router';
import { createAction, Action } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { INavigateActionPayload } from '../../interfaces/navigation';

enum ACTION_TYPES {
  NAVIGATE = '[Core] Navigate',
  NAVIGATE_BACK = '[Core] Navigate Back',
  NAVIGATE_BACK_SUCCESS = '[Core] Navigate Back Success',
  NAVIGATE_BY_URL = '[Core] Navigate By Url',

  CUSTOM = '@ngrx/router-store/navigation'
}

export const navigateAction: CreateActionType<ACTION_TYPES.NAVIGATE, INavigateActionPayload> = createAction(ACTION_TYPES.NAVIGATE, createPayload<INavigateActionPayload>());
export const navigateBackAction: ActionCreator<ACTION_TYPES.NAVIGATE_BACK, () => TypedAction<ACTION_TYPES.NAVIGATE_BACK>> = createAction(ACTION_TYPES.NAVIGATE_BACK);
export const navigateBackSuccessAction: ActionCreator<ACTION_TYPES.NAVIGATE_BACK_SUCCESS, () => TypedAction<ACTION_TYPES.NAVIGATE_BACK_SUCCESS>> = createAction(ACTION_TYPES.NAVIGATE_BACK_SUCCESS);

export const customNavigateAction: ActionCreator<ACTION_TYPES.CUSTOM, () => TypedAction<ACTION_TYPES.CUSTOM>> = createAction(ACTION_TYPES.CUSTOM);

export const navigateByUrlAction: CreateActionType<ACTION_TYPES.NAVIGATE_BY_URL, string> = createAction(ACTION_TYPES.NAVIGATE_BY_URL, createPayload<string>());


export function go(url: Array<string | number> | string, queryParams?: Params, extras?: any): Action {
  if (typeof url === 'string') {
    return navigateByUrlAction(url);
  }
  return navigateAction({ url, queryParams, extras });
}
