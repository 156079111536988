<div class="wrapper">

  <div class="select-wrapper"
       [class.opened]="isOpen"
       #wrapper>

    <div class="select"
         (click)="toggle($event);">

      <div *ngIf="placeholder"
           class="label">
        {{ placeholder }}
      </div>

      {{ selectedOption?.label || unselectedLabel || defaultLabel }}

      <i class="caret-icon icon icon-arrow-subcontainer--down"></i>
    </div>

    <ul class="list" #list>

      <li class="list-item list-item--title"
          [class.opened]="isOpen && isShowAdditionalListItems">
        <span>AVAILABLE STATE SEATS</span>
      </li>

      <ng-container *ngFor="let option of options; let i = index; trackBy: trackByFn">
        <li [class.selected]="i === highlightedOptionIndex"
            [class.disabled]="option?.disabled"
            [blTooltip]="option?.disabled ? option?.description : null"
            (click)="!option?.disabled ? selectOption($event, option) : null"
            class="list-item"
            #listItem>

          <span>{{ option.label }}</span>
          <i *ngIf="selectedOption && option.value === selectedOption?.value"
             class="list-item__icon icon-checked">
          </i>
        </li>
      </ng-container>

      <li class="list-item list-item--footer"
          [class.opened]="isOpen && isShowAdditionalListItems">
        <button type="button"
                [disabled]="!isCreateNewItems"
                (click)="handledOnClickCreateBtn($event); list.scrollTop = 0;"
                class="list-item--footer-btn">
          + Create a New State Seat
        </button>
      </li>

    </ul>

  </div>

  <input (focus)="open()"
         (blur)="close()"
         [name]="formControlName || name"
         class="hidden-select">

</div>
