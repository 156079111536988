import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import {
  ICreditCardsInfo,
  ICreditCardInfo,
  IDeclineTransactionData,
  IEditCreditCardPayload,
  IEditNewCreditCardPayload,
  INewCreditCartInfo
} from '../../interfaces/e-commerce';

enum ACTION_TYPES {
  GET_DEFAULT_CREDIT_CARDS_SUCCESS = '[E-COMMERCE] Get Default Credit cards success',

  ADD_NEW_CREDIT_CARD = '[E-COMMERCE] Add Credit New Card',
  ADD_NEW_CREDIT_CARD_CANCEL = '[E-COMMERCE] Add Credit New Card Cancel',
  ADD_NEW_CREDIT_CARD_SUCCESS = '[E-COMMERCE] Add Credit New Card Success',

  SELECT_DEFAULT_CREDIT_CARD = '[E-COMMERCE] Select Default Credit Card',
  SELECT_NEW_CREDIT_CARD = '[E-COMMERCE] Select New Credit Card',

  EDIT_CREDIT_CARD = '[E-COMMERCE] Edit credit card',
  EDIT_CREDIT_CARD_CANCEL = '[E-COMMERCE] Edit credit card cancel',
  EDIT_CREDIT_CARD_SUCCESS = '[E-COMMERCE] Edit credit card success',

  EDIT_NEW_CREDIT_CARD = '[E-COMMERCE] Edit new credit card',
  EDIT_NEW_CREDIT_CARD_CANCEL = '[E-COMMERCE] Edit new credit card cancel',
  EDIT_NEW_CREDIT_CARD_SUCCESS = '[E-COMMERCE] Edit new credit card success',

  DECLINE_CREDIT_CARD_TRANSACTION = '[E-COMMERCE] transaction by default credit card was declined',

  RESET_STATE = '[E-COMMERCE] Reset Credit Cards state'
}

export const addNewCreditCardAction: ActionCreator<ACTION_TYPES.ADD_NEW_CREDIT_CARD, () => TypedAction<ACTION_TYPES.ADD_NEW_CREDIT_CARD>> = createAction(ACTION_TYPES.ADD_NEW_CREDIT_CARD);
export const addNewCreditCardCancelAction: ActionCreator<ACTION_TYPES.ADD_NEW_CREDIT_CARD_CANCEL, () => TypedAction<ACTION_TYPES.ADD_NEW_CREDIT_CARD_CANCEL>> = createAction(ACTION_TYPES.ADD_NEW_CREDIT_CARD_CANCEL);
export const addNewCreditCardSuccessAction: CreateActionType<ACTION_TYPES.ADD_NEW_CREDIT_CARD_SUCCESS, INewCreditCartInfo> = createAction(ACTION_TYPES.ADD_NEW_CREDIT_CARD_SUCCESS, createPayload<INewCreditCartInfo>());

export const getCreditCardsSuccessAction: CreateActionType<ACTION_TYPES.GET_DEFAULT_CREDIT_CARDS_SUCCESS, ICreditCardsInfo> = createAction(ACTION_TYPES.GET_DEFAULT_CREDIT_CARDS_SUCCESS, createPayload<ICreditCardsInfo>());

export const editCreditCardAction: CreateActionType<ACTION_TYPES.EDIT_CREDIT_CARD, IEditCreditCardPayload> = createAction(ACTION_TYPES.EDIT_CREDIT_CARD, createPayload<IEditCreditCardPayload>());
export const editCreditCardCancelAction: ActionCreator<ACTION_TYPES.EDIT_CREDIT_CARD_CANCEL, () => TypedAction<ACTION_TYPES.EDIT_CREDIT_CARD_CANCEL>> = createAction(ACTION_TYPES.EDIT_CREDIT_CARD_CANCEL);
export const editCreditCardSuccessAction: CreateActionType<ACTION_TYPES.EDIT_CREDIT_CARD_SUCCESS, ICreditCardInfo> = createAction(ACTION_TYPES.EDIT_CREDIT_CARD_SUCCESS, createPayload<ICreditCardInfo>());

export const editNewCreditCardAction: CreateActionType<ACTION_TYPES.EDIT_NEW_CREDIT_CARD, IEditNewCreditCardPayload> = createAction(ACTION_TYPES.EDIT_NEW_CREDIT_CARD, createPayload<IEditNewCreditCardPayload>());
export const editNewCreditCardCancelAction: ActionCreator<ACTION_TYPES.EDIT_NEW_CREDIT_CARD_CANCEL, () => TypedAction<ACTION_TYPES.EDIT_NEW_CREDIT_CARD_CANCEL>> = createAction(ACTION_TYPES.EDIT_NEW_CREDIT_CARD_CANCEL);
export const editNewCreditCardSuccessAction: CreateActionType<ACTION_TYPES.EDIT_NEW_CREDIT_CARD_SUCCESS, INewCreditCartInfo> = createAction(ACTION_TYPES.EDIT_NEW_CREDIT_CARD_SUCCESS, createPayload<INewCreditCartInfo>());

export const declineCreditCardTransactionAction: CreateActionType<ACTION_TYPES.DECLINE_CREDIT_CARD_TRANSACTION, IDeclineTransactionData> = createAction(ACTION_TYPES.DECLINE_CREDIT_CARD_TRANSACTION,
  createPayload<IDeclineTransactionData>());

export const selectDefaultCreditCardAction: CreateActionType<ACTION_TYPES.SELECT_DEFAULT_CREDIT_CARD, ICreditCardInfo> = createAction(ACTION_TYPES.SELECT_DEFAULT_CREDIT_CARD, createPayload<ICreditCardInfo>());

export const selectNewCreditCardAction: CreateActionType<ACTION_TYPES.SELECT_NEW_CREDIT_CARD, INewCreditCartInfo> = createAction(ACTION_TYPES.SELECT_NEW_CREDIT_CARD, createPayload<INewCreditCartInfo>());

export const resetCreditCardsAction: ActionCreator<ACTION_TYPES.RESET_STATE, () => TypedAction<ACTION_TYPES.RESET_STATE>> = createAction(ACTION_TYPES.RESET_STATE);
