<bl-pop-up-base>
  <div class="wrapper">

    <h5 class="title">Download Started!</h5>

    <button class="ok-btn" (click)="onClose()">
      <i class="icon-success"></i>
    </button>

  </div>
</bl-pop-up-base>
