import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[blClickOutside]'
})
export class ClickOutsideDirective {

  constructor(private _elementRef: ElementRef) {
  }

  @Output() blClickOutside: EventEmitter<Event> = new EventEmitter<Event>();

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    this.checkEventAndEmit(event);
  }

  @HostListener('document:touchend', ['$event'])
  onTouchEnd(event: Event): void {
    this.checkEventAndEmit(event);
  }

  checkEventAndEmit(event: Event): void {
    const clickedInside: unknown = this._elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.blClickOutside.emit(event);
    }
  }
}
