import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { countriesAndStatesReducer, ICountriesAndStatesState } from './countries.reducer';

export interface ICountriesModuleState {
  countriesAndStates: ICountriesAndStatesState;
}

const countriesModuleState: ActionReducerMap<ICountriesModuleState> = {
  countriesAndStates: countriesAndStatesReducer
};

// tslint:disable-next-line:typedef
export const COUNTRIES_STATE_NAME = 'countriesModule';
export const COUNTRIES_STATE: InjectionToken<ActionReducerMap<ICountriesModuleState>> = new InjectionToken<ActionReducerMap<ICountriesModuleState>>(COUNTRIES_STATE_NAME, {
  factory: () => countriesModuleState
});
