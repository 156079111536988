<footer class="footer"
        [class.theme--mobile]="isMobile">

  <div class="footer-top"></div>

  <div class="footer-nav">
    <a [routerLink]="['/', corePaths.COMMON, corePaths.HELP]"
       (click)="onRedirect.emit()"
       class="footer-nav__item">
      Help
    </a>
    <a [routerLink]="['/', corePaths.COMMON, corePaths.FAQS]"
       (click)="onRedirect.emit()"
       class="footer-nav__item">
      FAQS
    </a>
    <a [routerLink]="['/', corePaths.ECOMM, eCommercePaths.PRICING]"
       *ngIf="!isBroker && !isAdminsAccount && isLoggedIn"
       (click)="onRedirect.emit()"
       class="footer-nav__item">
      Pricing
    </a>

    <a [routerLink]="['/', corePaths.COMMON, corePaths.CONTACT_US]"
       (click)="onRedirect.emit()"
       class="footer-nav__item">
      Contact us
    </a>
    <a href="https://mdreducation.com/?utm_source=ConnectED%20Data&utm_medium=webfooter"
       class="footer-nav__item">
      About MDR
    </a>
  </div>

  <div *ngIf="isMobile" class="copyright">
    <a [routerLink]="['/', corePaths.COMMON, corePaths.TERMS_OF_USE]"
       (click)="onRedirect.emit()"
       class="copyright__link">
      Terms of Service
    </a>
    <a href="https://mdreducation.com/privacy-policy/"
       (click)="onRedirect.emit()"
       class="copyright__link">
      Privacy Policy
    </a>
    <bl-footer-copyright></bl-footer-copyright>
  </div>

  <div *ngIf="!isMobile" class="copyright">
    <bl-footer-copyright></bl-footer-copyright>
    <a [routerLink]="['/', corePaths.COMMON, corePaths.TERMS_OF_USE]"
       (click)="onRedirect.emit()"
       class="copyright__link">
      Terms of Service
    </a>
    <a href="https://mdreducation.com/privacy-policy/"
       (click)="onRedirect.emit()"
       class="copyright__link">
      Privacy Policy
    </a>
  </div>
</footer>
