import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Action, Store } from '@ngrx/store';

import { defer, of, Observable } from 'rxjs';
import { map, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';

import { ActionWithPayload } from '@shared/interfaces/store';
import * as actions from '../actions/router-history.action';
import { CoreState } from '../reducers';
import { getRouterHistory } from '../selectors/router-history.selector';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { INavigateActionPayload } from '../../interfaces/navigation';

import { CORE_PATHS } from '../../constants/core-paths';

@Injectable()
export class RouterHistoryEffects {

  constructor(private _actions$: Actions,
              private _store: Store<CoreState>,
              private _router: Router) {
  }

  navigate$: Observable<Action> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(actions.navigateAction),
      tap(({ payload: { url, queryParams, extras } }: ActionWithPayload<INavigateActionPayload>) =>
        this._router.navigate(url, { queryParams, ...extras })),
      catchErrorWithErrorType
    )), { dispatch: false });

  navigateBack$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(actions.navigateBackAction),
      withLatestFrom(this._store.pipe(select(getRouterHistory))),
      map(([action, history]: [Action, string[]]) => history),
      map((history: string[]) => history[history.length - 2] || `/${ CORE_PATHS.DASHBOARD }`),
      tap((previousUrl: string) => this._router.navigateByUrl(previousUrl)),
      map(() => actions.navigateBackSuccessAction()),
      catchErrorWithErrorType
    ));

  navigateByUrl$: Observable<Action> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(actions.navigateByUrlAction),
      tap(({ payload }: ActionWithPayload<string>) => this._router.navigateByUrl(payload)),
      switchMapTo(of(null)),
      catchErrorWithErrorType
    )), { dispatch: false });
}
