import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/geo-multi-codes.action';

import { IMultiCodesError } from '@shared/interfaces/server-error';

export interface IGeoMultiCodesState {
  pending: boolean;
  error: IMultiCodesError | null;
}

const initialState: IGeoMultiCodesState = {
  pending: false,
  error: null
};

const addGeoMultiCodes: OnReducer<IGeoMultiCodesState, ActionType<any>> = (state: IGeoMultiCodesState) => ({
  ...state,
  pending: true
});

const addGeoMultiCodesError: OnReducer<IGeoMultiCodesState, ActionType<Payload<IMultiCodesError>>> = (state: IGeoMultiCodesState, { payload }: Payload<IMultiCodesError>) => ({
  ...state,
  pending: false,
  error: { ...payload }
});

const resetAddGeoMultiCodesError: OnReducer<IGeoMultiCodesState, ActionType<any>> = (state: IGeoMultiCodesState) => ({
  ...state,
  error: null
});

const addGeoMultiCodesSuccess: OnReducer<IGeoMultiCodesState, ActionType<any>> = (state: IGeoMultiCodesState) => ({
  ...state,
  pending: false
});

const resetGeoMultiCodes: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<IGeoMultiCodesState> = createReducer<IGeoMultiCodesState>(
  initialState,

  on(actions.addGeoMultiCodesAction, addGeoMultiCodes),
  on(actions.addGeoMultiCodesErrorAction, addGeoMultiCodesError),
  on(actions.resetAddGeoMultiCodesErrorAction, resetAddGeoMultiCodesError),
  on(actions.addGeoMultiCodesSuccessAction, addGeoMultiCodesSuccess),

  on(actions.resetGeoMultiCodesAction, resetGeoMultiCodes)
);

export function geoMultiCodesReducer(state: IGeoMultiCodesState, action: Action): IGeoMultiCodesState {
  return reducer(state, action);
}

export const geoMultiCodesError: GetFromState<IMultiCodesError, IGeoMultiCodesState> = (state: IGeoMultiCodesState): IMultiCodesError => state.error;
