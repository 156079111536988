import { Params } from '@angular/router';
import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import * as actions from '@core/store/actions/list-purchase.actions';
import {
  getDashboardListsNextPageSuccessAction,
  getDashboardListsSuccessAction,
  reloadDashboardListsSuccessAction
} from '@modules/dashboard-lists/store/actions/dashboard-lists.action';
import { IListAppendsState } from '@modules/list-common-store/store/reducers/list-appends.reducer';
import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';

import { IListMainTypes } from '@modules/list-shared/interfaces';
import { IListCreditInfo } from '@modules/list/interfaces/generate-list-params';
import { ISegmentData, ISegmentTargetingCriteria } from '@modules/segment/interfaces/segment';
import { IDataListSummary, IListCredits, ISummaryList } from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';

import { PRODUCT_ENTITY_TYPES } from '@shared/constants/data/entity';
import { LIST_STATUS_ID } from '@shared/constants/data/list-statuses';
import { LIST_RECORDS_TYPES, LIST_RELATE_TO_PERSONNEL_TYPE } from '@shared/constants/data/list-types';

export interface IListPurchaseState {
  listSummaryData: IDataListSummary | null;
  listSummarySegmentData: ISegmentData | null;

  listCreditsInfo: IListCredits | null;

  purchasing: boolean;
  purchaseError: IServerError | null;

  rePurchasing: boolean;
  rePurchaseError: IServerError | null;

  refreshing: boolean;
  refreshError: IServerError | null;
  withAdditional: boolean;

  listRefreshData: IDataListSummary | null;
  fetching: boolean;
  fetchDataError: IServerError | null;

  refreshSegment: ISegmentData | null;

  exportingLists: { [key: string]: number };
}

const initialState: IListPurchaseState = {
  listSummaryData: null,
  listSummarySegmentData: null,

  listCreditsInfo: null,

  purchasing: false,
  purchaseError: null,

  rePurchasing: false,
  rePurchaseError: null,

  refreshing: false,
  refreshError: null,
  withAdditional: false,

  listRefreshData: null,
  fetching: false,
  fetchDataError: null,

  refreshSegment: null,

  exportingLists: {}
};

const purchaseList: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  purchasing: true,
  purchaseError: null,
});

const purchaseListError: OnReducer<IListPurchaseState, ActionType<Payload<IServerError>>> = (state: IListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  purchasing: false,
  purchaseError: { ...payload },
});

const purchaseListSuccess: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  purchasing: false,
  purchaseError: null,
});

const rePurchaseList: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  rePurchasing: true,
  rePurchaseError: null,
});

const rePurchaseListError: OnReducer<IListPurchaseState, ActionType<Payload<IServerError>>> = (state: IListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  rePurchasing: false,
  rePurchaseError: { ...payload },
});

const rePurchaseListSuccess: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  rePurchasing: false,
  rePurchaseError: null,
});

const refreshList: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  refreshing: true,
  refreshError: null,
});

const refreshListError: OnReducer<IListPurchaseState, ActionType<Payload<IServerError>>> = (state: IListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  refreshing: false,
  refreshError: { ...payload },
});

const refreshListSuccess: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  refreshing: false,
  refreshError: null,
});

const setRefreshParams: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload }: Payload<any>) => ({
  ...state,
  withAdditional: payload,
});

const fetchListData: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  fetching: true,
  fetchDataError: null,
});

const fetchListPurchaseDataSuccess: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload }: Payload<any>) => ({
  ...state,
  fetching: false,
  listSummaryData: { ...payload },
});

// fetch purchase list segment data
export const fetchListSegmentPurchaseData: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...state,
  fetching: true,
  fetchDataError: null
});

export const fetchListSegmentPurchaseDataError: OnReducer<IListPurchaseState, ActionType<Payload<IServerError>>> = (state: IListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  fetching: false,
  fetchDataError: { ...payload }
});

export const fetchListSegmentPurchaseDataSuccess: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload }: Payload<any>) => ({
  ...state,
  fetching: false,
  listSummarySegmentData: { ...payload }
});

const fetchRefreshDataSuccess: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload }: Payload<any>) => ({
  ...state,
  fetching: false,
  listRefreshData: { ...payload },
});

// fetch refresh list segment data
export const fetchRefreshSegmentData: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload }: Payload<any>) => ({
  ...state,
  fetching: true,
  fetchDataError: null
});

export const fetchRefreshSegmentDataError: OnReducer<IListPurchaseState, ActionType<Payload<IServerError>>> = (state: IListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  fetching: false,
  fetchDataError: { ...payload }
});

export const fetchRefreshSegmentDataSuccess: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload }: Payload<any>) => ({
  ...state,
  fetching: false,
  refreshSegment: { ...payload }
});

const exportListSuccess: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload }: Payload<any>) => ({
  ...state,
  exportingLists: {
    ...state.exportingLists,
    [payload]: 0
  }
});

const calculateListExport: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload: { listingId, process } }: Payload<any>) => ({
  ...state,
  exportingLists: {
    ...state.exportingLists,
    [listingId]: process
  }
});

const calculateListExportSuccess: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload: { listingId } }: Payload<any>) => {
  const _exportingLists: { [key: string]: number } = { ...state.exportingLists };
  delete _exportingLists[listingId];

  return {
    ...state,
    exportingLists: { ..._exportingLists }
  };
};

const removeExportedListsThatHaveExportedFile: OnReducer<IListPurchaseState, ActionType<Payload<any>>> = (state: IListPurchaseState, { payload: { lists } }: Payload<any>) => {
  const _exportingLists: { [key: string]: number } = { ...state.exportingLists };

  lists.forEach((list: any) => {
    if (_exportingLists.hasOwnProperty(list.id) && list.hasExportedFile) {
      delete _exportingLists[list.id];
    }
  });

  return {
    ...state,
    exportingLists: { ..._exportingLists }
  };
};


const resetListPurchase: OnReducer<IListPurchaseState, ActionType<any>> = (state: IListPurchaseState) => ({
  ...initialState,
  exportingLists: state.exportingLists
});

const reducer: ActionReducer<IListPurchaseState> = createReducer<IListPurchaseState>(
  initialState,

  on(actions.purchaseListAction, purchaseList),
  on(actions.purchaseListErrorAction, purchaseListError),
  on(actions.purchaseListSuccessAction, purchaseListSuccess),

  on(actions.rePurchaseListAction, rePurchaseList),
  on(actions.rePurchaseListErrorAction, rePurchaseListError),
  on(actions.rePurchaseListSuccessAction, rePurchaseListSuccess),

  on(actions.refreshListAction, refreshList),
  on(actions.refreshListErrorAction, refreshListError),
  on(actions.refreshListSuccessAction, refreshListSuccess),

  on(actions.setRefreshParamsAction, setRefreshParams),

  on(actions.fetchListPurchaseDataAction, fetchListData),
  on(actions.fetchRefreshDataAction, fetchListData),

  on(actions.fetchListPurchaseDataSuccessAction, fetchListPurchaseDataSuccess),
  on(actions.fetchRefreshDataSuccessAction, fetchRefreshDataSuccess),

  on(actions.fetchListSegmentPurchaseDataAction, fetchListSegmentPurchaseData),
  on(actions.fetchListSegmentPurchaseDataErrorAction, fetchListSegmentPurchaseDataError),
  on(actions.fetchListSegmentPurchaseDataSuccessAction, fetchListSegmentPurchaseDataSuccess),

  on(actions.fetchRefreshSegmentDataAction, fetchRefreshSegmentData),
  on(actions.fetchRefreshSegmentDataErrorAction, fetchRefreshSegmentDataError),
  on(actions.fetchRefreshSegmentDataSuccessAction, fetchRefreshSegmentDataSuccess),

  on(actions.exportListSuccessAction, exportListSuccess),

  on(actions.calculateListExportAction, calculateListExport),
  on(actions.calculateListExportSuccessAction, calculateListExportSuccess),

  on(actions.resetListPurchaseAction, resetListPurchase),

  on(getDashboardListsSuccessAction, getDashboardListsNextPageSuccessAction, reloadDashboardListsSuccessAction,
    removeExportedListsThatHaveExportedFile),
);

export function listPurchaseReducer(state: IListPurchaseState,
                                    action: Action): IListPurchaseState {
  return reducer(state, action);
}


export const listSummaryData: GetFromState<IDataListSummary | null, IListPurchaseState> = (state: IListPurchaseState): IDataListSummary | null => state && state.listSummaryData;
export const listSummaryDataList: GetFromState<ISummaryList, IDataListSummary> = (state: IDataListSummary): ISummaryList => state && state.list;
export const listSummarySegmentTargeting: GetFromState<ISegmentTargetingCriteria, IListPurchaseState> = (state: IListPurchaseState): ISegmentTargetingCriteria =>
  state && state.listSummarySegmentData && state.listSummarySegmentData.targetingCriteria;
export const listMainTypes: GetFromState<IListMainTypes, ISummaryList, PRODUCT_ENTITY_TYPES> = (list: ISummaryList, entityType: PRODUCT_ENTITY_TYPES = PRODUCT_ENTITY_TYPES.LIST): IListMainTypes => {
  if (list) {
    const { name, recordsType, type }: ISummaryList = list;
    return { name, recordType: recordsType.id, listType: type.id, entityType };
  }

  return null;
};
export const listSummaryDataListPremiumCredits: GetFromState<number, ISummaryList> = (list: ISummaryList): number => list && list.premiumCredits;

export const listRefreshData: GetFromState<IDataListSummary, IListPurchaseState> = (state: IListPurchaseState): IDataListSummary => state && state.listRefreshData;
export const listRefreshDataList: GetFromState<ISummaryList, IDataListSummary> = (refreshData: IDataListSummary): ISummaryList => refreshData && refreshData.list;

export const refreshSegmentTargeting: GetFromState<ISegmentTargetingCriteria, IListPurchaseState> = (state: IListPurchaseState): ISegmentTargetingCriteria =>
  state && state.refreshSegment && state.refreshSegment.targetingCriteria;
export const fetching: GetFromState<boolean, IListPurchaseState> = (state: IListPurchaseState): boolean => state && state.fetching;

export const listRefreshOrSummaryActiveSegmentId: GetFromState<number, ISummaryList, { segmentId: number }> = (list: ISummaryList, { segmentId }: { segmentId: number }): number | null =>
  +segmentId || list && list.segments && list.segments[0] && list.segments[0].id || null;

export const purchasing: GetFromState<boolean, IListPurchaseState> = (state: IListPurchaseState): boolean => state && state.purchasing;

export const rePurchasing: GetFromState<boolean, IListPurchaseState> = (state: IListPurchaseState): boolean => state && state.rePurchasing;

export const refreshing: GetFromState<boolean, IListPurchaseState> = (state: IListPurchaseState): boolean => state && state.refreshing;
export const withAdditional: GetFromState<boolean, IListPurchaseState> = (state: IListPurchaseState): boolean => state && state.withAdditional;

export const canConfirm: GetFromState<boolean, boolean, IDataListSummary, number> = (isUnlimited: boolean, listData: IDataListSummary, userCredits: number): boolean => {
  if (!listData) {
    return false;
  }

  const { status, credits, appendsCredits, premiumCredits, activeAppendCredits }: ISummaryList = listData.list;
  const creditsNeeded: number = status === LIST_STATUS_ID.ACTIVE
    ? activeAppendCredits.credits + activeAppendCredits.appendsCredits + activeAppendCredits.premiumCredits
    : credits + appendsCredits + premiumCredits;

  return isUnlimited || typeof userCredits === 'number' && userCredits >= creditsNeeded;
};

export const isForRefreshed: GetFromState<boolean, Params> = (_queryParams: Params): boolean => {
  try {
    return JSON.parse(_queryParams['forRefresh']);
  } catch (e) {
    return false;
  }
};

export const summaryOrRefreshData: GetFromState<IDataListSummary, IDataListSummary, IDataListSummary, boolean> = (_summaryData: IDataListSummary,
                                     _refreshData: IDataListSummary,
                                     _isRefresh: boolean): IDataListSummary => _isRefresh ? _refreshData : _summaryData;

export const listSummaryOrRefreshCredits: GetFromState<number, IDataListSummary> = (_summaryData: IDataListSummary): number =>
  _summaryData && _summaryData.list && _summaryData.list.credits;

export const listCostForRefresh: GetFromState<number, boolean, boolean, number> = (_isRefresh: boolean, _withAdditional: boolean, _credits: number): number => {
  return !_isRefresh ? _credits : _withAdditional ? _credits : 0;
};

export const listSummaryOrRefreshPremium: GetFromState<number, IDataListSummary> = (_summaryData: IDataListSummary): number =>
  _summaryData && _summaryData.list && _summaryData.list.premiumCredits || 0;

export const listSummaryOrRefreshAppends: GetFromState<number, IDataListSummary> = (_summaryData: IDataListSummary): number =>
  _summaryData && _summaryData.list && _summaryData.list.appendsCredits || 0;

export const listSummaryOrRefreshCreditsInfo: GetFromState<IListCreditInfo, number, number, number> = (listCost: number = 0,
                                                appendCost: number = 0,
                                                listPremiumCost: number = 0): IListCreditInfo => ({
  listCost,
  appendCost,
  listPremiumCost
});

export const isActivateBtnDisabled: GetFromState<boolean, boolean, IDataListSummary> = (isUnlimited: boolean, listData: IDataListSummary): boolean => {
  return listData && listData.list
    && ((LIST_RELATE_TO_PERSONNEL_TYPE.includes(listData.list.recordsType.id) && listData.list.personsCount === 0)
      || (listData.list.recordsType.id === LIST_RECORDS_TYPES.BUILDINGS_ONLY && listData.list.institutionsCount === 0));
};

export const purchaseListCost: GetFromState<number, IDataListSummary, boolean> = (list: IDataListSummary, isRefresh: boolean): number =>
  list && list.list && (list.list.status === LIST_STATUS_ID.ACTIVE && !isRefresh
  ? list.list.activeAppendCredits.credits
  : list.list.credits) || 0;
export const purchaseListPremiumCost: GetFromState<number, IDataListSummary, boolean> = (list: IDataListSummary, isRefresh: boolean): number =>
  list && list.list && (list.list.status === LIST_STATUS_ID.ACTIVE && !isRefresh
  ? list.list.activeAppendCredits.premiumCredits
  : list.list.premiumCredits) || 0;
export const purchaseListAppendsCost: GetFromState<number, IDataListSummary, boolean> = (list: IDataListSummary, isRefresh: boolean): number =>
  list && list.list && (list.list.status === LIST_STATUS_ID.ACTIVE && !isRefresh
  ? list.list.activeAppendCredits.appendsCredits
  : list.list.appendsCredits) || 0;
export const purchaseListAppendsPageAppendsCost: GetFromState<number, IDataListSummary, boolean, IListAppendsState> = (list: IDataListSummary, isRefresh: boolean, state: IListAppendsState): number =>
  list && list.list && list.list.status === LIST_STATUS_ID.ACTIVE && !isRefresh
    ? (list.list.activeAppendCredits.appendsCredits || 0)
    : (state.appendsCredits || 0);
export const isActiveListPurchaseBtnDisabled: GetFromState<boolean, IDataListSummary> = (list: IDataListSummary): boolean => list && list.list &&
list.list.status === LIST_STATUS_ID.ACTIVE
  ? !list.list.hasNotBoughtAppends
  : false;

export const exportingLists: GetFromState<{ [key: string]: number }, IListPurchaseState> = (state: IListPurchaseState): { [key: string]: number } => state.exportingLists;
