import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';

import { FORM_VALIDATION_ERRORS } from '@shared/constants/validators/forms-validations-errors';

import { Validators } from '@shared/validators/validators';


@Component({
  selector: 'bl-rename-list',
  templateUrl: './rename-list.component.html',
  styleUrls: ['./rename-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameListComponent implements OnInit, OnChanges {

  @Input() isRenaming: boolean = false;
  @Input() page: string;
  @Input() name: string;
  @Input() errorMsg: string;
  @Input() maxLengthName: number = 50;
  @Input() formValidationErrors: ValidationErrors;
  @Input() shouldShowTooltip: boolean = true;

  @Output() onStartRename: EventEmitter<any> = new EventEmitter();
  @Output() onCancelRename: EventEmitter<any> = new EventEmitter();
  @Output() onChangeName: EventEmitter<string> = new EventEmitter();

  @ViewChild('topElemRef', { read: ElementRef }) topElemRef: ElementRef;

  readonly maxListNameLength: number = 75;

  private formErrors: ValidationErrors = FORM_VALIDATION_ERRORS.listName;

  form: FormGroup;
  isTooltipOpen: boolean = false;

  get fieldName(): AbstractControl {
    return this.form.controls['name'];
  }

  get validationNameErrors(): string | null {
    return this.fieldName.errors
      ? Object.values(this.fieldName.errors).join('\n')
      : null;
  }

  get displayValue(): string {
    return this.fieldName.value && this.fieldName.value.length ? this.fieldName.value : this.name;
  }

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.formErrors = this.formValidationErrors ? this.formValidationErrors : FORM_VALIDATION_ERRORS.listName;
    this.form = this._createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.errorMsg && changes.errorMsg.currentValue) {
      this.startEdit();
    }
  }

  startEdit(): void {
    this.onStartRename.emit();
  }

  stopEdit(): void {
    if (this.errorMsg || this.validationNameErrors) {
      this._setPreviousNameIfEmpty();
    } else {
      this.submitForm();
    }
    this.onCancelRename.emit();
  }

  onKeyUp(event: any): void {
    this.isTooltipOpen = false;
    this.fieldName.setValue(event.target.value);
    if (event.target.value.length > this.maxListNameLength) {
      this.isTooltipOpen = true;
    }
  }

  onBlur(event: string): void {
    this.stopEdit();
  }

  onEnterPress(event: Event): void {
    this.stopEdit();
  }

  submitForm(): void {
    if (this.fieldName.value === '') {
      this._setPreviousNameIfEmpty();
    }

    if (this._isFieldValid()) {
      this.onChangeName.emit(this.fieldName.value);
    }
  }

  getTooltipText(): string {
    let tooltipText: string = 'Rename';
    if (this.validationNameErrors || this.errorMsg) {
      tooltipText = this.validationNameErrors || this.errorMsg;
    }

    return tooltipText;
  }

  private _createForm(): FormGroup {
    return this.fb.group({
      name: [this.name, [
        Validators.minLength(this.formErrors.invalid, 1),
        Validators.maxLength(this.formErrors.maxLength, this.maxListNameLength)
      ]]
    });
  }

  private _setPreviousNameIfEmpty(): void {
    this.form.controls['name'].setValue(this.name);
  }

  private _isFieldValid(): boolean {
    return this.fieldName.valid && this.name !== this.fieldName.value;
  }

}
