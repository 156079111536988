import { NgModule } from '@angular/core';

import { IsSegmentByAvailablePipe } from './pipes/is-segment-by-available.pipe';
import { ItemsCountPipe } from './pipes/items-count.pipe';
import { ListConfigPipe } from './pipes/list-config.pipe';
import { ListEntityTypePipe } from './pipes/list-entity-type.pipe';
import { ListRecordTypePipe } from './pipes/list-record-type.pipe';
import { ListStatusNamePipe } from './pipes/list-status-name.pipe';
import { ListTypePipe } from './pipes/list-type.pipe';
import { ProductEntityTypeNamePipe } from './pipes/product-entity-type-name.pipe';

const pipes: any[] = [
  ListStatusNamePipe,
  IsSegmentByAvailablePipe,
  ListConfigPipe,
  ItemsCountPipe,
  ProductEntityTypeNamePipe,
  ListTypePipe,
  ListEntityTypePipe,
  ListRecordTypePipe
];

@NgModule({
  declarations: [
    ...pipes
  ],
  exports: [
    ...pipes
  ]
})
export class ListPipesModule {
}
