<bl-pop-up-base (close)="onClose()">
  <div class="icon">
    <i [class]="data.iconClass || 'icon-delete'"></i>
  </div>

  <h5 class="title">{{ data.title }}</h5>

  <div class="body">
    {{ data.body }}

    <div *ngIf="data?.additionalText">
      <br/>
      <p>{{ data.additionalText }}</p>
    </div>
  </div>

  <div class="buttons">

    <button class="btn btn--secondary btn--white"
            matRipple
            (click)="onDecline()">
      {{ data.declineText }}
    </button>

    <button class="btn btn--secondary btn--white"
            matRipple
            [attr.data-testid]="data?.dataTestIdForAcceptButton"
            (click)="onConfirm()">
      {{ data.acceptText }}
    </button>

  </div>

  <div class="do-not-show-again" *ngIf="data?.showDoNotShowPopUpCheckbox">
    <bl-do-not-show-pop-up-again [(ngModel)]="doNotShowPopUpAgain"></bl-do-not-show-pop-up-again>
  </div>
</bl-pop-up-base>
