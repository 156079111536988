import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IListAppendsCalculated } from '@modules/list-appends/interfaces/appends';
import { ISummaryOrDashboardList } from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';

enum ACTION_TYPES {
  START_ACTIVE_LIST_APPEND_DATA = '[ACTIVE LIST APPEND] Start active list appends process',

  SHOW_ACTIVE_LIST_APPEND_DATA_WITH_SET_CAP_POPUP = '[ACTIVE LIST APPEND] Show active list append pop up with set cap',
  SHOW_ACTIVE_LIST_APPEND_DATA_WITHOUT_SET_CAP_POPUP = '[ACTIVE LIST APPEND] Show active list append pop up without set cap',

  CALCULATE_ACTIVE_LIST_APPENDS = '[ACTIVE LIST APPEND] Send calculate active list appends request',
  CALCULATE_ACTIVE_LIST_APPENDS_SUCCESS = '[ACTIVE LIST APPEND] Send calculate active list appends request success',
  CALCULATE_ACTIVE_LIST_APPENDS_ERROR = '[ACTIVE LIST APPEND] Send calculate active list appends request error',

  LISTEN_ACTIVE_LIST_APPENDS_CALCULATED_SOCKET = '[ACTIVE LIST APPEND] Listen active list appends socket',
  ACTIVE_LIST_APPENDS_CALCULATED = '[ACTIVE LIST APPEND] Active list appends calculated'
}

export const startActiveListAppendDataAction: CreateActionType<ACTION_TYPES.START_ACTIVE_LIST_APPEND_DATA, ISummaryOrDashboardList> = createAction(
  ACTION_TYPES.START_ACTIVE_LIST_APPEND_DATA, createPayload<ISummaryOrDashboardList>());
export const showActiveListAppendDataWithSetCapPopUpAction: CreateActionType<ACTION_TYPES.SHOW_ACTIVE_LIST_APPEND_DATA_WITH_SET_CAP_POPUP, ISummaryOrDashboardList> = createAction(
  ACTION_TYPES.SHOW_ACTIVE_LIST_APPEND_DATA_WITH_SET_CAP_POPUP, createPayload<ISummaryOrDashboardList>());
export const showActiveListAppendDataWithoutSetCapPopUpAction: CreateActionType<ACTION_TYPES.SHOW_ACTIVE_LIST_APPEND_DATA_WITHOUT_SET_CAP_POPUP, ISummaryOrDashboardList> = createAction(
  ACTION_TYPES.SHOW_ACTIVE_LIST_APPEND_DATA_WITHOUT_SET_CAP_POPUP, createPayload<ISummaryOrDashboardList>());

export const calculateActiveListAppendsAction: CreateActionType<ACTION_TYPES.CALCULATE_ACTIVE_LIST_APPENDS, ISummaryOrDashboardList> = createAction(
  ACTION_TYPES.CALCULATE_ACTIVE_LIST_APPENDS, createPayload<ISummaryOrDashboardList>());
export const calculateActiveListAppendsSuccessAction: ActionCreator<ACTION_TYPES.CALCULATE_ACTIVE_LIST_APPENDS_SUCCESS, () => TypedAction<ACTION_TYPES.CALCULATE_ACTIVE_LIST_APPENDS_SUCCESS>> = createAction(
  ACTION_TYPES.CALCULATE_ACTIVE_LIST_APPENDS_SUCCESS);
export const calculateActiveListAppendsErrorAction: CreateActionType<ACTION_TYPES.CALCULATE_ACTIVE_LIST_APPENDS_ERROR, IServerError> = createAction(
  ACTION_TYPES.CALCULATE_ACTIVE_LIST_APPENDS_ERROR, createPayload<IServerError>());


export const listenActiveListAppendsCalculatedSocketAction: ActionCreator<ACTION_TYPES.LISTEN_ACTIVE_LIST_APPENDS_CALCULATED_SOCKET, () => TypedAction<ACTION_TYPES.LISTEN_ACTIVE_LIST_APPENDS_CALCULATED_SOCKET>> = createAction(
  ACTION_TYPES.LISTEN_ACTIVE_LIST_APPENDS_CALCULATED_SOCKET);
export const activeListAppendsCalculatedAction: CreateActionType<ACTION_TYPES.ACTIVE_LIST_APPENDS_CALCULATED, IListAppendsCalculated> = createAction(
  ACTION_TYPES.ACTIVE_LIST_APPENDS_CALCULATED, createPayload<IListAppendsCalculated>());
