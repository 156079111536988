import { SafeHtml } from '@angular/platform-browser';
import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { IOrderData } from '../../interfaces/order-confirmation';
import { IHtmlPageData } from '../../interfaces/terms-of-servise';

enum ACTION_TYPES {
  GET_PRIVACY_POLICY_DATA = '[Core] Get Privacy Policy Data',
  GET_PRIVACY_POLICY_DATA_SUCCESS = '[Core] Get Privacy Policy Data Success',
  GET_PRIVACY_POLICY_DATA_ERROR = '[Core] Get Privacy Policy Data Error',

  GET_ORDER_DATA = '[Core] Get Order Data',
  GET_ORDER_DATA_SUCCESS = '[Core] Get Order Data Success',
  GET_ORDER_DATA_ERROR = '[Core] Get Order Data Error',

  RESET_ORDER_DATA = '[Core] Reset Order data',

  OPEN_MENU_MOBILE = '[Core] Open menu mobile',
  CLOSE_MENU_MOBILE = '[Core] Close menu mobile',
}

export const getPrivacyPolicyDataAction: ActionCreator<ACTION_TYPES.GET_PRIVACY_POLICY_DATA, () => TypedAction<ACTION_TYPES.GET_PRIVACY_POLICY_DATA>> = createAction(ACTION_TYPES.GET_PRIVACY_POLICY_DATA);
export const getPrivacyPolicyDataErrorAction: CreateActionType<ACTION_TYPES.GET_PRIVACY_POLICY_DATA_ERROR, IServerError> = createAction(
  ACTION_TYPES.GET_PRIVACY_POLICY_DATA_ERROR,
  createPayload<IServerError>()
);
export const getPrivacyPolicyDataSuccessAction: CreateActionType<ACTION_TYPES.GET_PRIVACY_POLICY_DATA_SUCCESS, IHtmlPageData<SafeHtml>> = createAction(
  ACTION_TYPES.GET_PRIVACY_POLICY_DATA_SUCCESS,
  createPayload<IHtmlPageData<SafeHtml>>()
);

export const getOrderDataAction: ActionCreator<ACTION_TYPES.GET_ORDER_DATA, () => TypedAction<ACTION_TYPES.GET_ORDER_DATA>> = createAction(ACTION_TYPES.GET_ORDER_DATA);
export const getOrderDataErrorAction: CreateActionType<ACTION_TYPES.GET_ORDER_DATA_ERROR, IServerError> = createAction(ACTION_TYPES.GET_ORDER_DATA_ERROR, createPayload<IServerError>());
export const getOrderDataSuccessAction: CreateActionType<ACTION_TYPES.GET_ORDER_DATA_SUCCESS, IOrderData> = createAction(ACTION_TYPES.GET_ORDER_DATA_SUCCESS, createPayload<IOrderData>());
export const resetOrderDataAction: ActionCreator<ACTION_TYPES.RESET_ORDER_DATA, () => TypedAction<ACTION_TYPES.RESET_ORDER_DATA>> = createAction(ACTION_TYPES.RESET_ORDER_DATA);

export const openMenuMobileAction: ActionCreator<ACTION_TYPES.OPEN_MENU_MOBILE, () => TypedAction<ACTION_TYPES.OPEN_MENU_MOBILE>> = createAction(ACTION_TYPES.OPEN_MENU_MOBILE);
export const closeMenuMobileAction: ActionCreator<ACTION_TYPES.CLOSE_MENU_MOBILE, () => TypedAction<ACTION_TYPES.CLOSE_MENU_MOBILE>> = createAction(ACTION_TYPES.CLOSE_MENU_MOBILE);
