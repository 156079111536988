import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { of, throwError, Observable } from 'rxjs';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { getIsLoggedIn } from '@modules/auth/store/selectors/auth.selector';
import { getInvitationExistedUser } from '@modules/auth/store/selectors/invitation.selector';
import { getProfileErrorAction, getProfileSuccessAction } from '@modules/profile/store/actions/profile.action';
import { CoreState } from '../store/reducers';

import { ProfileService } from '../services/profile.service';

import { IInvitationUser } from '@modules/auth/interfaces/invitation';
import { IUserData } from '@modules/auth/interfaces/user';
import { IServerError } from '@shared/interfaces/server-error';


@Injectable({
  providedIn: 'root'
})
export class UserDataExistGuard implements CanActivate {
  constructor(private _store: Store<CoreState>,
              private _profileService: ProfileService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._store
      .pipe(
        select(getIsLoggedIn),
        take(1),
        withLatestFrom(this._store.pipe(select(getInvitationExistedUser))),
        switchMap(([isLoggedIn, invitation]: [boolean, IInvitationUser]) => {
          if (isLoggedIn && !invitation) {
            return this._profileService.loadUser()
              .pipe(
                map((_user: IUserData) => {
                  this._store.dispatch(getProfileSuccessAction(_user));
                  return true;
                }),
                catchError((error: IServerError) => {
                  this._store.dispatch(getProfileErrorAction(error));
                  return throwError(error);
                })
              );
          }
          return of(isLoggedIn);
        }),
        catchError(() => of(false))
      );
  }
}
