import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild, } from '@angular/core';

import { ITableHeader } from '@ui/tables/components/table/table.component';

import { SORT_DIRECTION } from '@shared/constants/sort';

import { DropDownComponent } from '../drop-down/drop-down.component';

export type ISortItems = any[];

@Component({
  selector: 'bl-mobile-sorting',
  templateUrl: './mobile-sorting.component.html',
  styleUrls: ['./mobile-sorting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileSortingComponent {
  @ViewChild('dropDownRef') dropDownRef: DropDownComponent;

  @Input() currentItem: any;
  @Input() items: ISortItems;
  @Input() order: SORT_DIRECTION;

  @Input() currentItemTemplate: TemplateRef<any>;
  @Input() iItemTemplate: TemplateRef<any>;

  @Output() onChangeOrder: EventEmitter<SORT_DIRECTION> = new EventEmitter<SORT_DIRECTION>();

  get currentItemTitle(): string {
    const currentItem: ITableHeader = this.items.find((item: ITableHeader) => item.key === this.currentItem);
    return currentItem ? currentItem.title : this.currentItem;
  }

  changeOrder(): void {
    const newOrder: SORT_DIRECTION = this.order === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
    this.onChangeOrder.emit(newOrder);
  }

  onClose(): void {
    this.dropDownRef.close();
  }
}
