import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IDefaultBillingInfo,
  IDefaultTransactionData,
  INewCardTransactionData,
  ISalesOrderData,
  IUserBillingInfoData
} from '../../interfaces/e-commerce';

enum ACTION_TYPES {
  GET_BILLING_INFO = '[E-COMMERCE] Get billing info',
  GET_BILLING_INFO_ERROR = '[E-COMMERCE] Get billing info error',
  GET_BILLING_INFO_SUCCESS = '[E-COMMERCE] Get billing info success',

  GET_ALL_BILLING_INFO_SUCCESS = '[E-COMMERCE] Get All billing info success ',

  GET_SALES_ORDER_DATA = '[E-COMMERCE] Get Sales order data',
  GET_SALES_ORDER_DATA_ERROR = '[E-COMMERCE] Get Sales order data error',
  GET_SALES_ORDER_DATA_SUCCESS = '[E-COMMERCE] Get Sales order data success',

  GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA = '[E-COMMERCE] Get Default Card Sales order signature data',
  GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA_ERROR = '[E-COMMERCE] Get Default Card Sales order signature data error',
  GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA_SUCCESS = '[E-COMMERCE] Get Default Card Sales order signature data success',

  GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA = '[E-COMMERCE] Get New Card Sales order signature data',
  GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA_ERROR = '[E-COMMERCE] Get New Card Sales order signature data error',
  GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA_SUCCESS = '[E-COMMERCE] Get New Card Sales order signature data success',

  TOGGLE_CONFIRM_TOS = '[E-COMMERCE] Toggle confirm terms',

  RESET = '[E-COMMERCE] Reset payment methods state'
}

export const getBillingAction: ActionCreator<ACTION_TYPES.GET_BILLING_INFO, () => TypedAction<ACTION_TYPES.GET_BILLING_INFO>> = createAction(ACTION_TYPES.GET_BILLING_INFO);
export const getBillingErrorAction: CreateActionType<ACTION_TYPES.GET_BILLING_INFO_ERROR, IServerError> = createAction(ACTION_TYPES.GET_BILLING_INFO_ERROR, createPayload<IServerError>());
export const getBillingSuccessAction: CreateActionType<ACTION_TYPES.GET_BILLING_INFO_SUCCESS, IDefaultBillingInfo> = createAction(ACTION_TYPES.GET_BILLING_INFO_SUCCESS, createPayload<IDefaultBillingInfo>());
export const getAllBillingInfoSuccessAction: CreateActionType<ACTION_TYPES.GET_ALL_BILLING_INFO_SUCCESS, IUserBillingInfoData> = createAction(ACTION_TYPES.GET_ALL_BILLING_INFO_SUCCESS,
  createPayload<IUserBillingInfoData>());

export const getSalesOrderAction: ActionCreator<ACTION_TYPES.GET_SALES_ORDER_DATA, () => TypedAction<ACTION_TYPES.GET_SALES_ORDER_DATA>> = createAction(ACTION_TYPES.GET_SALES_ORDER_DATA);
export const getSalesOrderErrorAction: CreateActionType<ACTION_TYPES.GET_SALES_ORDER_DATA_ERROR, IServerError> = createAction(ACTION_TYPES.GET_SALES_ORDER_DATA_ERROR, createPayload<IServerError>());
export const getSalesOrderSuccessAction: CreateActionType<ACTION_TYPES.GET_SALES_ORDER_DATA_SUCCESS, ISalesOrderData> = createAction(ACTION_TYPES.GET_SALES_ORDER_DATA_SUCCESS, createPayload<ISalesOrderData>());

export const getDefaultCardSignatureAction: ActionCreator<ACTION_TYPES.GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA, () => TypedAction<ACTION_TYPES.GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA>> = createAction(ACTION_TYPES.GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA);
export const getDefaultCardSignatureErrorAction: CreateActionType<ACTION_TYPES.GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA_ERROR, IServerError> = createAction(ACTION_TYPES.GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA_ERROR,
  createPayload<IServerError>());
export const getDefaultCardSignatureSuccessAction: CreateActionType<ACTION_TYPES.GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA_SUCCESS, IDefaultTransactionData> = createAction(ACTION_TYPES.GET_DEFAULT_CARD_SALES_ORDER_SIGNATURE_DATA_SUCCESS,
  createPayload<IDefaultTransactionData>());
export const getNewCardSalesOrderSignatureAction: ActionCreator<ACTION_TYPES.GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA, () => TypedAction<ACTION_TYPES.GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA>> = createAction(ACTION_TYPES.GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA);
export const getNewCardSalesOrderSignatureErrorAction: CreateActionType<ACTION_TYPES.GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA_ERROR, IServerError> = createAction(ACTION_TYPES.GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA_ERROR,
  createPayload<IServerError>());

export const getNewCardSalesOrderSignatureSuccessAction: CreateActionType<ACTION_TYPES.GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA_SUCCESS, INewCardTransactionData> = createAction(ACTION_TYPES.GET_NEW_CARD_SALES_ORDER_SIGNATURE_DATA_SUCCESS,
  createPayload<INewCardTransactionData>());

export const toggleConfirmTermsAction: CreateActionType<ACTION_TYPES.TOGGLE_CONFIRM_TOS, boolean> = createAction(ACTION_TYPES.TOGGLE_CONFIRM_TOS, createPayload<boolean>());

export const resetPaymentAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
