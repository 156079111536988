import { forwardRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { MobileDropDownDirective } from '@ui/drop-down/mobile/mobile-drop-down/directives/mobile-drop-down.directive';

import { CustomEmitter } from '@core/services/custom-emitter';

import { dateToMyDate } from '@shared/utils/converters';

import { DEFAULT_MOBILE_DROP_DOWN_PARAMS, MobileDropDownParams } from '@ui/drop-down/mobile/mobile-drop-down/models';

import { SEGMENT_CUSTOM_EMITTER_EVENTS } from '@modules/segment/constants/targeting-forms';
import { DAY_MILLISECONDS, MONTHS } from '@shared/constants/date-and-time';
import { WINDOW_POINTS } from '@ui/view-points/constants/view-points';

import { DATE_TYPE } from '@shared/modules/targeting-criteria-controls/data';
import { IMyCalendarViewChanged, IMyDate, IMyDateModel, IMyDpOptions, MyDatePicker } from 'mydatepicker';

@Component({
  selector: 'bl-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateControlComponent),
      multi: true
    }
  ]
})
export class DateControlComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

  @ViewChild('datePicker') datePicker: MyDatePicker;
  @ViewChild('mobileDropDown') mobileDropDown: MobileDropDownDirective;

  @Input() set initialDate(value: number) {
    this.writeValue(value);
  }

  @Output() onChangeValue: EventEmitter<number> = new EventEmitter<number>();
  @Input() type: string;
  @Input() minDate: number;
  @Input() maxDate: number;
  @Input() mobileDatepickerParams: MobileDropDownParams = DEFAULT_MOBILE_DROP_DOWN_PARAMS;

  @Input() isAbsolutePosition: boolean = true;
  @Input() position: { top: number, left: number } = { top: 0, left: 0 };
  @Input() isOpen: boolean = false;
  @Input() readonly: boolean = false;

  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;
  readonly dateTypes: typeof DATE_TYPE = DATE_TYPE;

  private _selectedDate: Date = new Date();
  private _destroyer$: Subject<void> = new Subject();

  datePickerOptions: IMyDpOptions;

  datePickerDate: IMyDate;
  formattedDate: string;

  shouldHighlightMonth: boolean = false;
  shouldHighlightYear: boolean = false;

  get shouldShowGoToDaysBtn(): boolean {
    return this.isOpen && !!this.datePicker && (this.datePicker.selectMonth || this.datePicker.selectYear);
  }

  constructor(private _customEmitter: CustomEmitter) {
  }

  ngOnInit(): void {
    this._customEmitter.on(SEGMENT_CUSTOM_EMITTER_EVENTS.CLOSE_DATE_PICKERS)
      .pipe(
        takeUntil(this._destroyer$),
        tap(() => this.closeDatePicker())
      ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minDate || changes.maxDate) {
      this.setDatePickerOptions();
    }
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  setDatePickerOptions(): void {
    this.datePickerOptions = {
      inline: true,
      selectorWidth: '391px',
      selectorHeight: '348px',
      showTodayBtn: false,
    };

    if (this.minDate) {
      this.datePickerOptions.disableUntil = dateToMyDate(this.minDate - DAY_MILLISECONDS);
    }

    if (this.maxDate) {
      this.datePickerOptions.disableSince = dateToMyDate(this.maxDate + DAY_MILLISECONDS);
    }
  }

  mobileDPStyles(): IMyDpOptions {
    this.datePickerOptions.selectorWidth = '100%';
    this.datePickerOptions.selectorHeight = '357px';
    return this.datePickerOptions;
  }

  propagateChange(value: any): void {
  }

  propagateTouch(): void {
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  onDateChange(dateModel: IMyDateModel): void {
    const { month, day, year }: IMyDate = dateModel.date;

    if (this.datePickerDate.day === day
      && this.datePickerDate.year === year
      && this.datePickerDate.month === month) {
      this.propagateTouch();
      this.closeDatePicker();

      return;
    }

    this.setDate(new Date(year, month - 1, day));

    const dateToEmit: number = this.type === this.dateTypes.FROM
      ? this._selectedDate.getTime()
      : this._selectedDate.getTime() + DAY_MILLISECONDS - 1000;

    this.propagateTouch();
    this.propagateChange(dateToEmit);
    this.onChangeValue.emit(dateToEmit);

    this.closeDatePicker();
  }

  onViewChange(event?: IMyCalendarViewChanged): void {
    if (event && this.datePickerDate) {
      const { year, month }: IMyDate = this.datePickerDate;
      this.shouldHighlightYear = year === event.year;
      this.shouldHighlightMonth = month === event.month;
    }
  }

  setDate(date: Date): void {
    this._selectedDate = date;
    this.datePickerDate = dateToMyDate(date);

    const { month, day, year }: IMyDate = this.datePickerDate;
    this.formattedDate = `${ MONTHS[month - 1] } ${ day }, ${ year }`;
  }

  toggleDatePicker(): void {
    if (this.readonly) {
      return;
    }
    !this.isOpen
      ? this.openDatePicker()
      : this.closeDatePicker();
  }

  closeDatePicker(): void {
    if (this.mobileDropDown) {
      this.mobileDropDown.close();
    }
    this.toggle.emit(false);
  }

  openDatePicker(): void {
    this._customEmitter.emit(SEGMENT_CUSTOM_EMITTER_EVENTS.CLOSE_DATE_PICKERS);
    this.toggle.emit(true);
  }

  goToDays(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.datePicker.selectYear = false;
    this.datePicker.selectMonth = false;
  }

  writeValue(value: number): void {
    if (value !== void (0)) {
      this.setDate(new Date(value));
    }
  }

  onClickOutside(): void {
    if (this.isOpen) {
      this.closeDatePicker();
    }
  }
}
