import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IError } from '../../interfaces';

import { COMMON_ERROR } from '@shared/constants/error-message';

enum ACTION_TYPES {
  SET_COMMON_ERROR = '[COMMON ERRORS]: set error',
  SET_PERMISSIONS_ERROR = '[COMMON ERRORS]: handler permissions errors',
  RESET_STATE = '[COMMON ERRORS]: reset state'
}

export const setCommonErrorAction: CreateActionType<ACTION_TYPES.SET_COMMON_ERROR, string> = createAction(ACTION_TYPES.SET_COMMON_ERROR, createPayload<string>(COMMON_ERROR));
export const setPermissionsErrorAction: CreateActionType<ACTION_TYPES.SET_PERMISSIONS_ERROR, IError> = createAction(ACTION_TYPES.SET_PERMISSIONS_ERROR, createPayload<IError>());
export const resetCommonErrorsAction: ActionCreator<ACTION_TYPES.RESET_STATE, () => TypedAction<ACTION_TYPES.RESET_STATE>> = createAction(ACTION_TYPES.RESET_STATE);
