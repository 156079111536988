import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { ISocketRefineListPidsUpdated } from '@modules/list-create/interfaces/list-create';

import { CORE_PATHS } from '@core/constants/core-paths';

@Component({
  selector: 'bl-refine-list-pids-updated',
  templateUrl: './refine-list-pids-updated.component.html',
  styleUrls: ['./refine-list-pids-updated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefineListPidsUpdatedComponent {

  constructor(public snackBarRef: MatSnackBarRef<RefineListPidsUpdatedComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: ISocketRefineListPidsUpdated) {
  }

  get redirectUrl(): string[] {
    return ['/', CORE_PATHS.LIST, this.data.listId.toString(), this.data.segmentId.toString()];
  }
}
