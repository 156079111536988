import { ComponentRef } from '@angular/core';

import { Observable, Subject } from 'rxjs';

export class PopUpRef<T = any, R = any> {

  private afterOpen$: Subject<any> = new Subject<any>();
  private afterClose$: Subject<R> = new Subject();
  private detach: Function;

  componentInstance: T;

  get afterOpen(): Observable<any> {
    return this.afterOpen$.asObservable();
  }

  get afterClose(): Observable<R> {
    return this.afterClose$.asObservable();
  }

  close(result?: R): void {
    this.detach();

    this.afterClose$.next(result);
    this.afterClose$.complete();
  }

  setDetachFunction(fn: Function): void {
    if (this.detach) {
      throw new Error('Pop Up detach function already set');
    }

    this.detach = fn;
  }

  setComponentInstance(componentRef: ComponentRef<T>): void {
    if (this.componentInstance) {
      throw new Error('Pop Up component instance already set');
    }

    this.componentInstance = componentRef.instance;

    this.afterOpen$.next();
    this.afterOpen$.complete();
  }
}
