import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { go } from '@core/store/actions/router-history.action';
import { CoreState } from '@core/store/reducers';
import { getIsAdminsAccount } from '../store/selectors/profile.selector';

import { CORE_PATHS } from '@core/constants/core-paths';
import { PROFILE_PATHS } from '../constants/profile-route-paths';

@Injectable({
  providedIn: 'root'
})
export class AdminAccountRedirectGuard implements CanActivate {

  constructor(private _store: Store<CoreState>) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._store
      .pipe(
        select(getIsAdminsAccount),
        map((isAdminsAccount: boolean) => {
          if (isAdminsAccount) {
            const redirectUrl: string[] = [
              '/',
              CORE_PATHS.ACCOUNT,
              PROFILE_PATHS.PRODUCTS,
            ];

            this._store.dispatch(go(redirectUrl));
          }

          return !isAdminsAccount;
        })
      );
  }
}
