import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import { environment } from '@env/environment';

import { throwError, Observable } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';

import { go } from '@core/store/actions/router-history.action';
import { CoreState } from '@core/store/reducers';
import {
  resetDashboardListsCheckedFilterAction,
  resetDashboardListsStateAction
} from '../../dashboard-lists/store/actions/dashboard-lists-filter.action';
import { resetDashboardViewsStateAction } from '../../dashboard-views/store/actions/dashboard-views-filter.action';
import { resetAccountUsersAction } from '../../profile/store/actions/account-users.action';
import { resetAccountSeatsAction } from '../../profile/store/actions/account.action';

import { SessionStorageService } from '@core/services/session-storage.service';
import { StorageService } from '@core/services/storage.service';
import { GaService } from '../../google-analytics/services/ga.service';

import { IObjectKeysStringAny } from '@shared/interfaces';
import { IServerError } from '@shared/interfaces/server-error';
import { ICredentials, IFirstLoginFormData, IForgotPasswordData } from '../interfaces/formsActionsData';
import {
  IForgotPasswordResponse,
  ILoginResponse,
  INewUser,
  IRefreshTokenResponse,
  ISuccessMessageResponse,
  IUser
} from '../interfaces/user';

import { CORE_PATHS } from '@core/constants/core-paths';
import { GA_ACTIONS, GA_CATEGORIES, GA_LABEL } from '@core/constants/ga';
import { LOCAL_STORAGE_FIELDS } from '@shared/constants/local-storage-fields';
import { FORM_VALIDATION_ERRORS } from '@shared/constants/validators/forms-validations-errors';
import { AUTH_PATHS } from '../constants/auth-paths';

import { Validators } from '@shared/validators/validators';


@Injectable()
export class AuthService {

  constructor(private _store: Store<CoreState>,
              private _fb: FormBuilder,
              private _http: HttpClient,
              private _ga: GaService) {
  }

  // Redirect outside
  static toAdminPanel(): void {
    window.location.href = `${ environment.adminAppUrl }`;
  }


  // HTTP requests
  login(credentials: ICredentials): Observable<ILoginResponse> {
    return this._http.post(environment.api.auth.login, credentials)
      .pipe(
        pluck('data'),
        map((response: ILoginResponse) => ({ ...response, token: `Bearer ${ response.token }` })),
        catchError((error: HttpErrorResponse) => throwError(error.error as IServerError))
      );
  }

  signUp(newUser: INewUser): Observable<ILoginResponse> {
    return this._http.post(environment.api.auth.signUp, newUser)
      .pipe(
        pluck('data'),
        map((response: ILoginResponse) => ({ ...response, token: `Bearer ${ response.token }` })),
        catchError((error: HttpErrorResponse) => {
          const serverError: IServerError = error.error;
          const { general, ...rest }: { [key: string]: string[] } = serverError.errors;
          const _error: IServerError = {
            message: general ? general[0] : serverError.message,
            errors: rest
          };

          return throwError(_error as IServerError);
        })
      );
  }

  firstLogin(data: IFirstLoginFormData): Observable<ILoginResponse> {
    return this._http.post(`${ environment.api.auth.firstLogin }`, data)
      .pipe(
        pluck('data'),
        map((response: ILoginResponse) => ({ ...response, token: `Bearer ${ response.token }` })),
        catchError((error: HttpErrorResponse) => throwError(error.error as IServerError))
      );
  }

  refreshToken(): Observable<IRefreshTokenResponse> {
    return this._http.post(environment.api.auth.refreshToken, null)
      .pipe(
        pluck('data'),
        map(({ token }: { token: string }) => ({ token: `Bearer ${ token }` })),
        catchError((error: HttpErrorResponse) => throwError(error.error as IServerError))
      );
  }

  resetPassword(resetData: IForgotPasswordData): Observable<IForgotPasswordResponse> {
    return this._http.post(`${ environment.api.auth.resetPassword }`, resetData)
      .pipe(
        pluck<{ data: IForgotPasswordResponse }, IForgotPasswordResponse>('data'),
        catchError((error: HttpErrorResponse) => throwError(error.error as IServerError))
      );
  }

  changeTemporaryPassword(changePasswordData: IFirstLoginFormData): Observable<ISuccessMessageResponse> {
    return this._http.post(`${ environment.api.auth.changePasswordAfterReset }`, changePasswordData)
      .pipe(
        pluck<{ data: ISuccessMessageResponse }, ISuccessMessageResponse>('data'),
        catchError((error: HttpErrorResponse) => throwError(error.error as IServerError))
      );
  }

  // Handle success actions
  onSignIn(data: ILoginResponse, remember: boolean = false, firstLogin: boolean = false, withoutRedirect: boolean = false): void {
    StorageService.token = data.token;
    StorageService.remember = remember;
    StorageService.isAdminsAccount = !!data.isAdminsAccount;

    if (data.user && StorageService.id !== data.user.id) {
      StorageService.doNotShowWelcomeWithMarketViewPopUp = false;
      StorageService.doNotShowEditSelfPopUp = false;
      StorageService.id = data.user.id;
    }

    if (firstLogin) {
      StorageService.firstSignUp = true;
      if (!withoutRedirect) {
        this._store.dispatch(go(['/', CORE_PATHS.DASHBOARD], { isFirstLogin: true }));
      }

    } else {
      StorageService.firstSignUp = false;
      if (!withoutRedirect) {
        this._store.dispatch(go(['/', CORE_PATHS.DASHBOARD]));
      }
    }
  }

  onSignOut(withoutNavigate?: boolean): void {
    StorageService.clearStorage([
      LOCAL_STORAGE_FIELDS.doNotShowAssignStatePopUp,
      LOCAL_STORAGE_FIELDS.doNotShowAssignAddOnPopUp,
      LOCAL_STORAGE_FIELDS.doNotShowEditSelfPopUp,
      LOCAL_STORAGE_FIELDS.doNotShowWelcomeWithMarketViewPopUp,
      LOCAL_STORAGE_FIELDS.id,
      LOCAL_STORAGE_FIELDS.doNotShowIndefinitelyMVRenewalPopUp
    ]);

    SessionStorageService.clear();

    this._store.dispatch(resetAccountSeatsAction());
    this._store.dispatch(resetAccountUsersAction());
    this._store.dispatch(resetDashboardListsCheckedFilterAction());
    this._store.dispatch(resetDashboardListsStateAction());
    this._store.dispatch(resetDashboardViewsStateAction());

    if (!withoutNavigate) {
      this._store.dispatch(go(['/', CORE_PATHS.AUTH, AUTH_PATHS.LOGIN]));
    }
  }

  onResetTemporaryPassword(): void {
    this._store.dispatch(go(['/', CORE_PATHS.AUTH, AUTH_PATHS.LOGIN]));
  }

  sigInForm(email?: string): FormGroup {
    return this._fb.group({
      credentials: this._fb.group({
        email: [email || '', [
          Validators.required(FORM_VALIDATION_ERRORS.email.required),
          Validators.email(FORM_VALIDATION_ERRORS.email.invalid)]
        ],
        password: ['', [Validators.required(FORM_VALIDATION_ERRORS.password.required)]],
      }),
      remember: [false]
    });
  }

  profileForm(withPassword: boolean = false, withRecaptcha: boolean = false, profile?: IUser): FormGroup {
    const {
      email,
      password,
      confirmNewPassword,
      firstName,
      lastName,
      telephoneNumber,
      address1,
      city,
      stateProvince,
      country,
      company,
      prefix,
      zipPostalCode
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    const passwordControls: IObjectKeysStringAny = {
      password: ['',
        [
          Validators.password(password.invalid),
          Validators.validateDependent(''),
          Validators.required(password.required),
          Validators.validateDependent('confirmPassword')
        ]],
      confirmPassword: ['', [
        Validators.required(confirmNewPassword.required),
        Validators.confirm(confirmNewPassword.invalid, ['password'])
      ]],
    };

    const gRecaptchaControl: IObjectKeysStringAny = {
      gRecaptchaResponse: ['', Validators.required()]
    };

    const defaultControls: IObjectKeysStringAny = {
      email: [(profile && profile.email) || '', [Validators.email(email.invalid), Validators.required(email.required)]],
      prefix: ['Ms'],
      firstName: [(profile && profile.firstName) || '', [
        Validators.required(firstName.required),
        Validators.maxLength(firstName.invalid, 32)
      ]],
      lastName: [(profile && profile.lastName) || '', [
        Validators.required(lastName.required),
        Validators.maxLength(lastName.invalid, 32)
      ]],
      company: [(profile && profile.company) || '', Validators.required(company.required)],
      phone: [(profile && profile.phone) || '', [
        Validators.required(telephoneNumber.required),
        Validators.minLength(telephoneNumber.invalid, 10),
        Validators.maxLength(telephoneNumber.invalid, 10),
      ]],
      address: this._fb.group({
        addressFirst: [(profile && profile.addressFirst) || '', Validators.required(address1.required)],
        addressSecond: [(profile && profile.addressSecond) || ''],
        city: [(profile && profile.city) || '', [
          Validators.required(city.required),
          Validators.maxLength(city.invalid, 64)
        ]],
        stateCode: [(profile && profile.stateCode) || '', [
          Validators.required(stateProvince.required)
        ]],
        countryCode: [(profile && profile.countryCode) || '', [
          Validators.required(country.required)
        ]],
        postalCode: [(profile && profile.postalCode) || '', [
          Validators.required(zipPostalCode.required),
          (profile && profile.countryCode === 'US') ? Validators.postalCode() : null,
        ]]
      })
    };

    let finaleFormConfig: IObjectKeysStringAny = {
      ...defaultControls
    };

    if (withRecaptcha) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...gRecaptchaControl
      };
    }

    if (withPassword) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...passwordControls
      };
    }

    return this._fb.group(finaleFormConfig as IObjectKeysStringAny);
  }

  signUpForm(withPassword: boolean = false, withRecaptcha: boolean = false, profile?: IUser): FormGroup {
    const {
      email,
      password,
      confirmNewPassword,
      firstName,
      lastName,
      country,
      company,
      prefix,
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    const passwordControls: IObjectKeysStringAny = {
      password: ['',
        [
          Validators.password(password.invalid),
          Validators.validateDependent(''),
          Validators.required(password.required),
          Validators.validateDependent('confirmPassword')
        ]],
      confirmPassword: ['', [
        Validators.required(confirmNewPassword.required),
        Validators.confirm(confirmNewPassword.invalid, ['password'])
      ]],
    };

    const gRecaptchaControl: IObjectKeysStringAny = {
      gRecaptchaResponse: ['', Validators.required()]
    };

    const defaultControls: IObjectKeysStringAny = {
      email: [(profile && profile.email) || '', [Validators.email(email.invalid), Validators.required(email.required)]],
      prefix: ['Ms', Validators.required(prefix.required)],
      firstName: [(profile && profile.firstName) || '', [
        Validators.required(firstName.required),
        Validators.maxLength(firstName.invalid, 32)
      ]],
      lastName: [(profile && profile.lastName) || '', [
        Validators.required(lastName.required),
        Validators.maxLength(lastName.invalid, 32)
      ]],
      company: [(profile && profile.company) || '', Validators.required(company.required)],
      countryCode: ['US', Validators.required(country.required)],
    };

    let finaleFormConfig: { [key: string]: any } = {
      ...defaultControls
    };

    if (withRecaptcha) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...gRecaptchaControl
      };
    }

    if (withPassword) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...passwordControls
      };
    }

    return this._fb.group(finaleFormConfig as { [key: string]: any });
  }

  getInviteSignUpForm(userEmail: string, token: string, withRecaptcha: boolean = false): FormGroup {
    const {
      email,
      password,
      confirmNewPassword,
      firstName,
      lastName,
      prefix,
      country
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    const defaultControls: IObjectKeysStringAny = {
      token: [token || '', Validators.required()],
      email: [userEmail || '', [Validators.email(email.invalid), Validators.required(email.required)]],
      password: ['',
        [
          Validators.password(password.invalid),
          Validators.validateDependent(''),
          Validators.required(password.required),
          Validators.validateDependent('confirmPassword')
        ]],
      confirmPassword: ['', [
        Validators.required(confirmNewPassword.required),
        Validators.confirm(confirmNewPassword.invalid, ['password'])
      ]],
      prefix: ['Ms', Validators.required(prefix.required)],
      firstName: ['', [
        Validators.required(firstName.required),
        Validators.maxLength(firstName.invalid, 32)
      ]],
      lastName: ['', [
        Validators.required(lastName.required),
        Validators.maxLength(lastName.invalid, 32)
      ]],
      remember: [false],
      countryCode: ['US', Validators.required(country.required)],
    };

    const gRecaptchaControl: IObjectKeysStringAny = {
      gRecaptchaResponse: ['', Validators.required()]
    };

    let finaleFormConfig: { [key: string]: any } = {
      ...defaultControls
    };

    if (withRecaptcha) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...gRecaptchaControl
      };
    }

    return this._fb.group(finaleFormConfig as IObjectKeysStringAny);
  }

  emitGAFirstLogin(): void {
    if (environment.enableGA) {
      this._ga.emit(GA_CATEGORIES.FIRST_SIGN_UP, GA_ACTIONS.SUBMIT_PHX_FORM, GA_LABEL.SET_NEW_PASSWORD);
    }
  }
}
