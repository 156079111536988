import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/notification.action';

import { INotification } from '@core/interfaces/notifications';

export interface INotificationState {
  notifications: INotification[];
}

const initialState: INotificationState = {
  notifications: []
};

const showNotification: OnReducer<INotificationState, ActionType<Payload<any>>> = (state: INotificationState, { payload }: Payload<any>) => ({
  ...state,
  notifications: !!state.notifications
    .find((_notification: INotification) => payload.id === _notification.id)
    ? [...state.notifications]
    : checkAndAddNotification(state, payload)
});

const hideNotification: OnReducer<INotificationState, ActionType<Payload<any>>> = (state: INotificationState, { payload }: Payload<any>) => ({
  notifications: state.notifications.filter((notification: INotification) => notification.id !== payload)
});

const reducer: ActionReducer<INotificationState> = createReducer<INotificationState>(
  initialState,

  on(actions.showNotificationAction, showNotification),
  on(actions.hideNotificationAction, hideNotification)
);

export const notifications: GetFromState<INotification[], INotificationState> = (state: INotificationState): INotification[] => state.notifications;

export function notificationReducer(state: INotificationState,
                                    action: Action): INotificationState {
  return reducer(state, action);
}

function checkAndAddNotification(state: INotificationState, payload: INotification): INotification[] {
  if (sameNotificationExist(state, payload)) {
    return [...state.notifications];
  } else {
    return [
      ...state.notifications,
      {
        ...payload,
        id: payload.id || new Date().getTime(),
      }
    ];
  }
}

function sameNotificationExist(state: INotificationState, payload: INotification): boolean {
  return state.notifications
    .map((notification: INotification) => notification.message)
    .filter((message: string) => message === payload.message)
    .length > 0;
}
