<!-- selected option -->
<ng-container [ngTemplateOutlet]="selectedOptionTpl"
              [ngTemplateOutletContext]="{ selectedOption: selectedOption }">
</ng-container><!-- / selected option -->

<!-- list of options -->
<div class="body align--{{ align }}"
     *ngIf="isOpen" data-testid="drop-down-body">

  <ng-container [ngTemplateOutlet]="optionTpl"
                [ngTemplateOutletContext]="{ option: option }"
                *ngFor="let option of options">
  </ng-container>

</div><!-- / list of options -->
