import { StorageService } from '@core/services/storage.service';

import {
  DATE_TYPE,
  ISwitchingCheckbox,
  ITargetingCriteriaControls,
  ITargetingCriteriaDataItemCheckboxesDataItem,
  ITargetingCriteriaDataItemControlDataItem,
  ITargetingCriteriaDataItemDatesDataItem,
  ITargetingCriteriaDataItemRadioButtonsDataItem,
  ITargetingCriteriaDataItemRadioDataItem,
  ITargetingCriteriaDataItemSlidersDataItem,
  ITargetingCriteriaDataItemSwitchCheckboxDataItem,
  ITargetingCriteriaItemDetailedData,
  ITargetingCriteriaItemDetailedDataSimpleData,
  ITargetingCriteriaItemDetailedItemAsMultiData,
  TARGETING_DATA_CONTROL_TYPES,
  TARGETING_DATA_TYPES,
  UN_SELECTED_BY_ALL_TARGETING_CONTROLS_TYPES
} from '../../targeting-criteria-controls/data';

export const isStatusControlSelected: (control: (ITargetingCriteriaDataItemCheckboxesDataItem
  | ITargetingCriteriaDataItemRadioButtonsDataItem | ITargetingCriteriaDataItemRadioDataItem)) => boolean =
  (control: ITargetingCriteriaDataItemCheckboxesDataItem
  | ITargetingCriteriaDataItemRadioButtonsDataItem
  | ITargetingCriteriaDataItemRadioDataItem): boolean => control.status;

export const isCheckboxSelected: (control: ITargetingCriteriaDataItemCheckboxesDataItem) => boolean =
  (control: ITargetingCriteriaDataItemCheckboxesDataItem): boolean => isStatusControlSelected(control);
export const isRadioButtonSelected: (control: ITargetingCriteriaDataItemRadioButtonsDataItem) => boolean =
  (control: ITargetingCriteriaDataItemRadioButtonsDataItem): boolean => isStatusControlSelected(control);
export const isRadioSelected: (control: ITargetingCriteriaDataItemRadioDataItem) => boolean =
  (control: ITargetingCriteriaDataItemRadioDataItem): boolean => isStatusControlSelected(control);
export const isSwitchBoxSelected: (control: ITargetingCriteriaDataItemSwitchCheckboxDataItem) => boolean =
  (control: ITargetingCriteriaDataItemSwitchCheckboxDataItem): boolean => typeof control.status === 'boolean';
export const isSliderSelected: (control: ITargetingCriteriaDataItemSlidersDataItem) => boolean =
  (control: ITargetingCriteriaDataItemSlidersDataItem): boolean => control.min !== control.currentMin
  || control.max !== control.currentMax;
export const isDateSelected: (control: ITargetingCriteriaDataItemDatesDataItem) => boolean =
  (control: ITargetingCriteriaDataItemDatesDataItem): boolean => control
  .current !== (control.title === DATE_TYPE.FROM
  ? control.min
  : control.max);

export const isControlSelected: (control: ITargetingCriteriaDataItemControlDataItem) => boolean =
  (control: ITargetingCriteriaDataItemControlDataItem): boolean => {
  if (!control) {
    return false;
  }

  if (control.type === TARGETING_DATA_CONTROL_TYPES.CHECKBOX
    || control.type === TARGETING_DATA_CONTROL_TYPES.RADIO_BUTTON
    || control.type === TARGETING_DATA_CONTROL_TYPES.RADIO) {
    return isStatusControlSelected(control);
  }

  if (control.type === TARGETING_DATA_CONTROL_TYPES.SWITCH_CHECKBOX) {
    return isSwitchBoxSelected(control);
  }

  if (control.type === TARGETING_DATA_CONTROL_TYPES.SLIDER) {
    return isSliderSelected(control);
  }

  if (control.type === TARGETING_DATA_CONTROL_TYPES.DATE) {
    return isDateSelected(control);
  }

  return false;
};

export const isControlShouldShouldShowPremiumMsg: (control: ITargetingCriteriaDataItemControlDataItem) => boolean =
  (control: ITargetingCriteriaDataItemControlDataItem): boolean => {
  if (!control) {
    return false;
  }

  if (control.type === TARGETING_DATA_CONTROL_TYPES.CHECKBOX) {
    return !StorageService.doNotShowPremiumCheckboxPopUp;
  }
  if (control.type === TARGETING_DATA_CONTROL_TYPES.RADIO_BUTTON) {
    return !StorageService.doNotShowPremiumRadioButtonPopUp;
  }

  if (control.type === TARGETING_DATA_CONTROL_TYPES.RADIO) {
    return !StorageService.doNotShowPremiumRadioPopUp;
  }

  if (control.type === TARGETING_DATA_CONTROL_TYPES.SLIDER) {
    return !StorageService.doNotShowPremiumScrollerPopUp;
  }

  return false;
};

export const getOnlySelectedTargetingControls: (value: ITargetingCriteriaControls,
                                                withDisabled?: boolean,
                                                ...skipControlTypes: TARGETING_DATA_CONTROL_TYPES[]) => ITargetingCriteriaControls =
  (value: ITargetingCriteriaControls,
  withDisabled: boolean = true,
  ...skipControlTypes: TARGETING_DATA_CONTROL_TYPES[]): ITargetingCriteriaControls => {
  if (!value || value.length < 1) {
    return [];
  }

  return value
    .filter((control: ITargetingCriteriaDataItemControlDataItem) => !skipControlTypes.includes(control.type)
      && isControlSelected(control)
      && (withDisabled ? true : !control.disabled));
};

export const getOnlyUnSelectedTargetingControls: (value: ITargetingCriteriaControls,
                                                  withDisabled?: boolean) => ITargetingCriteriaControls =
  (value: ITargetingCriteriaControls,
  withDisabled: boolean = true): ITargetingCriteriaControls => {
  if (!value || value.length < 1) {
    return [];
  }

  return value
    .filter((control: ITargetingCriteriaDataItemControlDataItem) => !isControlSelected(control)
      && (withDisabled ? true : !control.disabled));
};

export const shouldSowSelectedAllBtn: (data: ITargetingCriteriaControls) => boolean = (data: ITargetingCriteriaControls): boolean => {
  if (!data || !data.length) {
    return false;
  }

  return !UN_SELECTED_BY_ALL_TARGETING_CONTROLS_TYPES.includes(data[0].type)
    && ((getOnlySelectedTargetingControls(data, false).length > 1)
      || (getOnlyUnSelectedTargetingControls(data, false).length > 1));
};

export const isTargetingDetailedDataSelected: (detailedTargetingData: ITargetingCriteriaItemDetailedData) => boolean =
  (detailedTargetingData: ITargetingCriteriaItemDetailedData): boolean => {
  let isSelected: boolean = false;

  // TODO made it more simple;
  detailedTargetingData.forEach((data: ITargetingCriteriaItemDetailedItemAsMultiData | ITargetingCriteriaItemDetailedDataSimpleData) => {
    if ((data as ITargetingCriteriaItemDetailedItemAsMultiData).type === TARGETING_DATA_TYPES.MULTI) {
      data.data.forEach((item: any) => {
        if (item.type === TARGETING_DATA_TYPES.RADIO_BUTTON) {
          item.data.forEach((RadioItem: any) => {
            RadioItem.data
              .forEach((RadioItemDataItem: any) =>
                isSelected = isSelected || !!getOnlySelectedTargetingControls(RadioItemDataItem.data, false).length);
          });
        } else {
          isSelected = isSelected || !!getOnlySelectedTargetingControls(item.data, false).length;
        }
      });
    } else {
      isSelected = isSelected || !!getOnlySelectedTargetingControls((data as ITargetingCriteriaItemDetailedDataSimpleData).data).length;
    }
  });

  return isSelected;
};

export const isPremiumPresentInData: (data: ITargetingCriteriaControls) => boolean = (data: ITargetingCriteriaControls): boolean => {
  return !!data.find((item: ITargetingCriteriaDataItemControlDataItem) => item.isPremium);
};

export const switchCheckboxToCriteria: ({
                                          checkboxId,
                                          status
                                        }: ITargetingCriteriaDataItemSwitchCheckboxDataItem,
                                        shouldChecked?: boolean) => ISwitchingCheckbox =
  ({ checkboxId, status }: ITargetingCriteriaDataItemSwitchCheckboxDataItem,
  shouldChecked: boolean = false): ISwitchingCheckbox => ({
  id: checkboxId,
  include: status || shouldChecked ? 1 : 0
});
