import { DocumentRef } from '@core/refs/document-ref.service';

export type IScriptParamsTypes = string | boolean;

export const appendExternalScriptToBody: (document: DocumentRef,
                                          params: Iterable<readonly [string, IScriptParamsTypes]>,
                                          elementType?: keyof HTMLElementTagNameMap
) => Element = (document: DocumentRef,
             params: Iterable<readonly [string, IScriptParamsTypes]>,
             elementTagName: keyof HTMLElementTagNameMap = 'script'
) => {
  const scriptParams: Map<string, IScriptParamsTypes> = new Map(params);

  const externalScript: Element = document.nativeElement.createElement(elementTagName);

  scriptParams.forEach((value: IScriptParamsTypes, key: string) => {
    externalScript[key] = value;
  });

  document.nativeElement.body.appendChild(externalScript);
  return externalScript;
};
