import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { profileEffects } from './store/effects';
import { profileModulesStateToken, profileModuleState } from './store/reducers';
import { PROFILE_MODULE_FEATURE_NAME } from './store/selectors';

import { DirectivesModule } from '@shared/modules/directives/directives.module';
import { SharedPipesModule } from '@shared/modules/pipes/shared-pipes/shared-pipes.module';
import { StringPipesModule } from '@shared/modules/pipes/string-pipes/string-pipes.module';
import { UserFormsModule } from '@shared/modules/user-forms/user-forms.module';
import { CustomControlsModule } from '@ui/custom-controls/custom-controls.module';
import { DropDownModule } from '@ui/drop-down/drop-down.module';
import { ErrorsModule } from '@ui/errors/errors.module';
import { HeadersModule } from '@ui/headers/headers.module';
import { SpinnerModule } from '@ui/spinner/spinner.module';
import { StickyModule } from '@ui/sticky/sticky.module';
import { TablesModule } from '@ui/tables/tables.module';
import { TabsModule } from '@ui/tabs/tabs.module';
import { ViewPointsModule } from '@ui/view-points/view-points.module';
import { ProfileRoutingModule } from './profile-routing.module';

import { BillingDetailsPageComponent } from './pages/billing-details-page/billing-details-page.component';
import { ProductsAndUsersPageComponent } from './pages/products-and-users-page/products-and-users-page.component';
import { ProfileDetailsPageComponent } from './pages/profile-details-page/profile-details-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ProfileSettingsPageComponent } from './pages/profile-settings-page/profile-settings-page.component';

import { AssignStatePopUpContainerComponent } from './containers/assign-state-pop-up-container/assign-state-pop-up-container.component';
import { ProductsAndUsersContainerComponent } from './containers/products-and-users-container/products-and-users-container.component';
import { ProductsContainerComponent } from './containers/products-container/products-container.component';
import { ProfileDetailsContainerComponent } from './containers/profile-details-container/profile-details-container.component';
import { ProfileTabsContainerComponent } from './containers/profile-tabs-container/profile-tabs-container.component';

import { AccountUsersComponent } from './components/account-users/account-users.component';
import { AssignPopUpComponent } from './components/assign-pop-up/assign-pop-up.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { ChoosePurchaseComponent } from './components/choose-purchase/choose-purchase.component';
import { CreditCardDetailsComponent } from './components/credit-card-details/credit-card-details.component';
import { ProductHeaderComponent } from './components/product-header/product-header.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ProductsSimpleComponent } from './components/products-simple/products-simple.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { ProfileSettingsFormComponent } from './components/profile-settings-form/profile-settings-form.component';
import { UsersHeaderComponent } from './components/users-header/users-header.component';
import { UsersSearchInputComponent } from './components/users-search-input/users-search-input.component';

import { AccountInfoService } from './services/account-info.service';
import { AccountService } from './services/account.service';
import { ProfileFormsService } from './services/profile-forms.service';

import { SeatsNamePipe } from './pipes/seats-name.pipe';

// TODO Refactor this module
@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
    StoreModule.forFeature(PROFILE_MODULE_FEATURE_NAME, profileModulesStateToken),
    EffectsModule.forFeature(profileEffects),
    ProfileRoutingModule,
    HeadersModule,
    ViewPointsModule,
    TablesModule,
    DirectivesModule,
    StringPipesModule,
    StickyModule,
    SharedPipesModule,
    UserFormsModule,
    CustomControlsModule,
    SpinnerModule,
    ErrorsModule,
    DropDownModule,
    TabsModule
  ],
  declarations: [
    ProfilePageComponent,
    ProfileDetailsPageComponent,
    ProfileFormComponent,
    ChangePasswordFormComponent,
    ProfileSettingsPageComponent,
    BillingDetailsPageComponent,
    CreditCardDetailsComponent,
    ProfileSettingsFormComponent,
    AccountUsersComponent,
    ProductsAndUsersContainerComponent,
    ProductItemComponent,
    ProductHeaderComponent,
    ProductsContainerComponent,
    AccountUsersComponent,
    ProductsAndUsersPageComponent,
    ProductsSimpleComponent,
    AssignPopUpComponent,
    AssignStatePopUpContainerComponent,
    ChoosePurchaseComponent,

    SeatsNamePipe,

    ProfileDetailsContainerComponent,
    ProfileTabsContainerComponent,
    UsersHeaderComponent,
    UsersSearchInputComponent
  ],
  providers: [
    {
      provide: profileModulesStateToken,
      useValue: profileModuleState
    },
    AccountService,
    ProfileFormsService,
    AccountInfoService
  ]
})
export class ProfileModule {
}
