import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectValues'
})
export class ObjectValuesPipe implements PipeTransform {

  transform(object: Object): Array<unknown> {
    if (object) {
      return Object.keys(object).map((key: string) => ({ key, value: object[key] }));
    }
  }

}
