import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { InvitationService } from '@modules/auth/services/invitation.service';
import { AuthInterceptor } from './services/auth-interceptor';
import { AuthService } from './services/auth.service';
import { SurveyInterceptor } from './services/survey-interceptor';

import { AuthGuard } from '@modules/auth/guards/auth.guard';

@NgModule({
  imports: [
    HttpClientModule,
    HttpClientJsonpModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: SurveyInterceptor,
    multi: true
  },
    AuthService,
    AuthGuard,
    InvitationService]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthGuard,
        InvitationService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SurveyInterceptor,
          multi: true
        }
      ],
    };
  }
}
