import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CoreState } from '@core/store/reducers';
import { getIsShowSpinner } from '../../store/selectors/spinner.selector';


@Component({
  selector: 'bl-spinner-container',
  templateUrl: './spinner-container.component.html',
  styleUrls: ['./spinner-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerContainerComponent {
  isShowSpinner$: Observable<boolean> = this._store.pipe(select(getIsShowSpinner));

  constructor(private _store: Store<CoreState>) {
  }

}
