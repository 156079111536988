export const appendZero: (digit: number) => (string | number) = (digit: number): string | number => {
  return (digit < 10)
    ? '0' + digit
    : digit;
};

export const getUTCDate: () => string = (): string => {
  const now: Date = new Date();

  return now.getUTCFullYear()
    + '-' + appendZero(now.getUTCMonth() + 1)
    + '-' + appendZero(now.getUTCDate())
    + 'T' + appendZero(now.getUTCHours())
    + ':' + appendZero(now.getUTCMinutes())
    + ':' + appendZero(now.getUTCSeconds())
    + 'Z';
};

export const formatCartDate: (value: string) => string = (value: string): string => {
  return value
    .substr(0, 7)
    .split('-')
    .reverse()
    .join('-');
};
