import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { ICountries, ICountryCode, ICountryStates } from '../../interfaces';


enum ACTION_TYPES {
  GET_COUNTRIES = '[COUNTRIES]: get all countries',
  GET_COUNTRIES_SUCCESS = '[COUNTRIES]: get all countries success',
  GET_COUNTRIES_ERROR = '[COUNTRIES]: get all countries error',

  GET_STATES_BY_COUNTRY = '[COUNTRIES]: get states by country',
  GET_STATES_BY_COUNTRY_SUCCESS = '[COUNTRIES]: get states by country success',
  GET_STATES_BY_COUNTRY_ERROR = '[COUNTRIES]: get states by country error',

  RESET_COUNTRIES_STATE = '[COUNTRIES]: reset countries state'
}

export const getCountriesAction: ActionCreator<ACTION_TYPES.GET_COUNTRIES, () => TypedAction<ACTION_TYPES.GET_COUNTRIES>> = createAction(ACTION_TYPES.GET_COUNTRIES);
export const getCountriesSuccessAction: CreateActionType<ACTION_TYPES.GET_COUNTRIES_SUCCESS, ICountries> = createAction(ACTION_TYPES.GET_COUNTRIES_SUCCESS, createPayload<ICountries>());
export const getCountriesErrorAction: CreateActionType<ACTION_TYPES.GET_COUNTRIES_ERROR, IServerError> = createAction(ACTION_TYPES.GET_COUNTRIES_ERROR, createPayload<IServerError>());

export const getStatesByCountryAction: CreateActionType<ACTION_TYPES.GET_STATES_BY_COUNTRY, ICountryCode> = createAction(ACTION_TYPES.GET_STATES_BY_COUNTRY, createPayload<ICountryCode>());
export const getStatesByCountrySuccessAction: CreateActionType<ACTION_TYPES.GET_STATES_BY_COUNTRY_SUCCESS, ICountryStates> = createAction(ACTION_TYPES.GET_STATES_BY_COUNTRY_SUCCESS, createPayload<ICountryStates>());
export const getStatesByCountryErrorAction: CreateActionType<ACTION_TYPES.GET_STATES_BY_COUNTRY_ERROR, IServerError> = createAction(ACTION_TYPES.GET_STATES_BY_COUNTRY_ERROR, createPayload<IServerError>());

export const resetCountriesStateAction: ActionCreator<ACTION_TYPES.RESET_COUNTRIES_STATE, () => TypedAction<ACTION_TYPES.RESET_COUNTRIES_STATE>> = createAction(ACTION_TYPES.RESET_COUNTRIES_STATE);
