import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  ICreateViewData,
  ICreateViewSuccess,
  IRenameViewData,
  IViewData,
  IViewId
} from '@shared/interfaces/view';

enum ACTION_TYPES {
  CREATE_VIEW = '[VIEW] Create new view',
  CREATE_VIEW_ERROR = '[VIEW] Create new view error',
  CREATE_VIEW_SUCCESS = '[View] Create new view success',

  DELETE_VIEW = '[VIEW] Delete View',
  DELETE_VIEW_ERROR = '[VIEW] Delete View error',
  DELETE_VIEW_SUCCESS = '[VIEW] Delete View success',
  DELETE_VIEW_CANCEL = '[VIEW] Delete View cancel',

  VIEW_CHANGES_START = '[VIEW] View changes start',
  VIEW_CHANGES_END = '[VIEW] View changes end',
  VIEW_CHANGES_RESET = '[VIEW] View changes reset',
  VIEW_CHANGES_RESET_WITH_CACHE = '[VIEW] View changes reset with cache',

  RELOAD_VIEW_WITH_REDIRECT = '[VIEW] Reload view with redirect',
  RELOAD_VIEW_WITH_REDIRECT_ERROR = '[VIEW] Reload view with redirect error',
  RELOAD_VIEW_WITH_REDIRECT_SUCCESS = '[VIEW] Reload view with redirect success',

  RENAME_VIEW = '[VIEW]: Rename view',
  RENAME_VIEW_ERROR = '[VIEW]: Rename view error',
  RENAME_VIEW_SUCCESS = '[VIEW]: Rename view success',
  RENAME_VIEW_CANCEL = '[VIEW]: Rename view cancel',

  UPDATE_VIEW = '[VIEW]: Update view state',

  RESET_VIEW_STORE = '[VIEW]: Reset view store'
}

export const createViewAction: CreateActionType<ACTION_TYPES.CREATE_VIEW, ICreateViewData> = createAction(ACTION_TYPES.CREATE_VIEW, createPayload<ICreateViewData>());
export const createViewErrorAction: CreateActionType<ACTION_TYPES.CREATE_VIEW_ERROR, IServerError> = createAction(ACTION_TYPES.CREATE_VIEW_ERROR, createPayload<IServerError>(null));
export const createViewSuccessAction: CreateActionType<ACTION_TYPES.CREATE_VIEW_SUCCESS, ICreateViewSuccess> = createAction(ACTION_TYPES.CREATE_VIEW_SUCCESS, createPayload<ICreateViewSuccess>());

export const deleteViewAction: CreateActionType<ACTION_TYPES.DELETE_VIEW, IViewId> = createAction(ACTION_TYPES.DELETE_VIEW, createPayload<IViewId>());
export const deleteViewErrorAction: CreateActionType<ACTION_TYPES.DELETE_VIEW_ERROR, IServerError> = createAction(ACTION_TYPES.DELETE_VIEW_ERROR, createPayload<IServerError>());
export const deleteViewSuccessAction: ActionCreator<ACTION_TYPES.DELETE_VIEW_SUCCESS, () => TypedAction<ACTION_TYPES.DELETE_VIEW_SUCCESS>> = createAction(ACTION_TYPES.DELETE_VIEW_SUCCESS);
export const deleteViewCancelAction: ActionCreator<ACTION_TYPES.DELETE_VIEW_CANCEL, () => TypedAction<ACTION_TYPES.DELETE_VIEW_CANCEL>> = createAction(ACTION_TYPES.DELETE_VIEW_CANCEL);

export const viewChangesStartAction: ActionCreator<ACTION_TYPES.VIEW_CHANGES_START, () => TypedAction<ACTION_TYPES.VIEW_CHANGES_START>> = createAction(ACTION_TYPES.VIEW_CHANGES_START);
export const viewChangesEndAction: ActionCreator<ACTION_TYPES.VIEW_CHANGES_END, () => TypedAction<ACTION_TYPES.VIEW_CHANGES_END>> = createAction(ACTION_TYPES.VIEW_CHANGES_END);
export const viewChangesResetAction: ActionCreator<ACTION_TYPES.VIEW_CHANGES_RESET, () => TypedAction<ACTION_TYPES.VIEW_CHANGES_RESET>> = createAction(ACTION_TYPES.VIEW_CHANGES_RESET);
export const viewChangesResetWithCache: ActionCreator<ACTION_TYPES.VIEW_CHANGES_RESET_WITH_CACHE, () => TypedAction<ACTION_TYPES.VIEW_CHANGES_RESET_WITH_CACHE>> = createAction(ACTION_TYPES.VIEW_CHANGES_RESET_WITH_CACHE);

export const reloadViewWithRedirectAction: ActionCreator<ACTION_TYPES.RELOAD_VIEW_WITH_REDIRECT, () => TypedAction<ACTION_TYPES.RELOAD_VIEW_WITH_REDIRECT>> = createAction(ACTION_TYPES.RELOAD_VIEW_WITH_REDIRECT);
export const reloadViewWithRedirectErrorAction: CreateActionType<ACTION_TYPES.RELOAD_VIEW_WITH_REDIRECT_ERROR, IServerError> = createAction(ACTION_TYPES.RELOAD_VIEW_WITH_REDIRECT_ERROR, createPayload<IServerError>());
export const reloadViewWithRedirectSuccessAction: CreateActionType<ACTION_TYPES.RELOAD_VIEW_WITH_REDIRECT_SUCCESS, IViewData> = createAction(ACTION_TYPES.RELOAD_VIEW_WITH_REDIRECT_SUCCESS, createPayload<IViewData>());

export const renameViewAction: CreateActionType<ACTION_TYPES.RENAME_VIEW, IRenameViewData> = createAction(ACTION_TYPES.RENAME_VIEW, createPayload<IRenameViewData>());
export const renameViewErrorAction: CreateActionType<ACTION_TYPES.RENAME_VIEW_ERROR, IServerError> = createAction(ACTION_TYPES.RENAME_VIEW_ERROR, createPayload<IServerError>());
export const renameViewSuccessAction: CreateActionType<ACTION_TYPES.RENAME_VIEW_SUCCESS, IViewData> = createAction(ACTION_TYPES.RENAME_VIEW_SUCCESS, createPayload<IViewData>());
export const renameViewCancelAction: ActionCreator<ACTION_TYPES.RENAME_VIEW_CANCEL, () => TypedAction<ACTION_TYPES.RENAME_VIEW_CANCEL>> = createAction(ACTION_TYPES.RENAME_VIEW_CANCEL);

export const updateViewStateAction: CreateActionType<ACTION_TYPES.UPDATE_VIEW, Partial<IViewData> | IViewData> = createAction(ACTION_TYPES.UPDATE_VIEW, createPayload<Partial<IViewData> | IViewData>());

export const resetViewStoreAction: ActionCreator<ACTION_TYPES.RESET_VIEW_STORE, () => TypedAction<ACTION_TYPES.RESET_VIEW_STORE>> = createAction(ACTION_TYPES.RESET_VIEW_STORE);
