import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beautyDate'
})
export class BeautyDatePipe implements PipeTransform {
  // value - initial value, which can be transformed to valid Date object
  // todayYesterday - do we need to transform today and yesterday Date to 'Today' and 'Yesterday' string
  transform(value: string | number, todayYesterday: boolean): Date | string {

    const todayDate: Date = new Date();
    const currentYear: number = todayDate.getFullYear();
    const todayFormattedDate: string = formatDate(todayDate, 'MMM d, yyyy', 'en');
    const yesterdayFormattedDate: string = formatDate(todayDate.setDate(todayDate.getDate() - 1), 'MMM d, yyyy', 'en');

    const titleDate: Date = new Date(value);
    const formattedDate: string = formatDate(value, 'MMM d, yyyy', 'en');

    let transformedText: Date | string;

    if (formattedDate === todayFormattedDate && todayYesterday) {
      transformedText = 'Today';
    } else if (formattedDate === yesterdayFormattedDate && todayYesterday) {
      transformedText = 'Yesterday';
    } else {
      if (titleDate.getFullYear() === currentYear) {
        transformedText = `${ formatDate(value, 'MMM', 'en') } ` +
          addSuffix('' + titleDate.getDate());
      } else {
        transformedText = formattedDate;
      }
    }

    function addSuffix(day: string): string {
      let suffix: string = 'th';

      if (day === '1' || day === '21' || day === '31') {
        suffix = 'st';
      }
      if (day === '2' || day === '22') {
        suffix = 'nd';
      }
      if (day === '3' || day === '23') {
        suffix = 'rd';
      }

      return day + suffix;
    }

    return transformedText;
  }

}
