import { Pipe, PipeTransform } from '@angular/core';

import { IControlOption } from '@shared/interfaces/forms';

import { LIST_DELIVERY_TYPE_OPTIONS } from '@shared/constants/data/list-types';

@Pipe({
  name: 'listEntityType'
})
export class ListEntityTypePipe implements PipeTransform {

  transform(id: number): string {
    const found: IControlOption = LIST_DELIVERY_TYPE_OPTIONS.find((type: IControlOption) => type.value === id);
    return found && found.label || '';
  }

}
