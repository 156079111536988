import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bl-side-nav-container',
  templateUrl: './side-nav-container.component.html',
  styleUrls: ['./side-nav-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideNavContainerComponent {
}
