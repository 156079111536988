import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, map, skip, take } from 'rxjs/operators';

import { getProfileSettingsAction } from '@modules/profile/store/actions/profile.action';
import { getProfileSettings } from '@modules/profile/store/selectors/profile.selector';
import { CoreState } from '../store/reducers';

import { IProfileSetting } from '@modules/profile/interfaces/profile-settings';

@Injectable()
export class ProfileSettingsExistGuard implements CanActivate {

  constructor(private store: Store<CoreState>) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {

    this.store.dispatch(getProfileSettingsAction());

    return this.store
      .pipe(
        select(getProfileSettings),
        skip(1),
        take(1),
        map((settings: IProfileSetting[]) => !!settings),
        catchError(() => of(false))
      );
  }

}
