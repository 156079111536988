import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[blAnchorScroll]'
})
export class AnchorScrollDirective implements OnChanges, OnDestroy {

  @Input() blAnchorScroll: string;
  @Input() shouldScroll: boolean = false;

  private destroyer$: Subject<void> = new Subject();
  private _subscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldScroll) {
      this.shouldScroll
        ? this.subscribeToRouter()
        : this.unsubscribeFromRouter();
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  private subscribeToRouter(): void {
    this._subscription = this.activatedRoute.fragment
      .pipe(
        takeUntil(this.destroyer$),
        filter((fragment: string) => this.blAnchorScroll === fragment)
      )
      .subscribe(() => this.scroll());
  }

  private unsubscribeFromRouter(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private scroll(): void {
    this.elementRef.nativeElement.scrollIntoView({ block: 'start' });
  }
}
