<div class="select-wrapper {{ theme }}"
     [class.disabled]="disabled">
  <div class="select-box" (click)="!disabled ? onBoxClick($event) : null" [class.select-box--opened]="showDropdown">
    <ng-template [ngIf]="selectedOption">
      <ng-container
        *ngTemplateOutlet="selectedTemplate ? selectedTemplate : template; context:getContext()"></ng-container>
    </ng-template>
    <ng-template [ngIf]="!selectedOption">
      <div class="select-box-placeholder">
        {{ this.placeholder }}
      </div>
    </ng-template>
    <i class="select-box-caret-icon caret-icon"
       [class.icon-arrow-subcontainer--down]="!showDropdown"
       [class.icon-arrow-subcontainer--up]="showDropdown">
    </i>
  </div>
  <div class="select-dropdown-wrapper">
    <div class="select-dropdown" *ngIf="showDropdown">
      <ng-container *ngFor="let option of options">
        <div class="select-dropdown-item" (click)="onDropdownItemClick($event, option)"
             [class.selected]="selectedOption && trackBy ? option[trackBy] === selectedOption[trackBy] : false">
          <ng-container *ngTemplateOutlet="template; context:option"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
