import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { billingInfoMp, pluckAndCatch } from '@shared/utils/response-formater';

import {
  IDefaultTransactionData,
  INewCardTransactionData,
  ISalesOrderData,
  ISalesOrderParams,
  IUserBillingInfoData
} from '../interfaces/e-commerce';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) {
  }

  getSignature(signedFields: IDefaultTransactionData
    | INewCardTransactionData): Observable<IDefaultTransactionData | INewCardTransactionData> {
    return this.http.post(environment.api.eCommerce.sign, { signedFields })
      .pipe(pluckAndCatch);
  }

  // addCart(addCardData): Observable<any> {
  //   return this.http.post(environment.api.cyberSource.addCart, addCardData)
  //     .pipe(pluckAndCatch);
  // }

  userBillingInfo(): Observable<IUserBillingInfoData> {
    return this.http.get(environment.api.eCommerce.userBillingInfo)
      .pipe(billingInfoMp);
  }

  salesOrder(salesOrderParams: ISalesOrderParams): Observable<ISalesOrderData> {
    return this.http.post(environment.api.eCommerce.salesOrder, { data: salesOrderParams })
      .pipe(pluckAndCatch);
  }
}
