import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bl-fake-radio',
  templateUrl: './fake-radio.component.html',
  styleUrls: ['./fake-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FakeRadioComponent {

  @Input() checked: boolean;
  @Input() label: string;
  @Input() description: string;
  @Input() name: string;
  @Input() theme: string;

  @Output() change: EventEmitter<any> = new EventEmitter();

  switch($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.checked = !this.checked;
    this.change.emit(this.checked);
  }

}
