import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { environment } from '@env/environment';

import { Observable } from 'rxjs';

import { errorHandler, pluckAndCatch } from '@shared/utils/response-formater';

import { IHtmlPageData, ITermsOfUsePageData } from '../interfaces/terms-of-servise';

@Injectable()
export class TosService {

  constructor(private _http: HttpClient) {
  }

  loadGeneralTerms(): Observable<IHtmlPageData<string>> {
    return this._http.get(environment.api.generalTerms.data)
      .pipe(pluckAndCatch);
  }

  loadTermsOfUseData(): Observable<ITermsOfUsePageData<string>> {
    return this._http.get(environment.api.termsOfUse.data)
      .pipe(pluckAndCatch);
  }

  loadBrokersTermsOfUseData(): Observable<ITermsOfUsePageData<SafeHtml>> {
    return this._http.get(environment.api.termsOfUse.brokers)
      .pipe(pluckAndCatch);
  }

  acceptTOS(): Observable<any> {
    return this._http.post(environment.api.user.acceptTOS, { accepted: true })
      .pipe(errorHandler);
  }

  acceptBrokerTos(): Observable<any> {
    return this._http.post(`${ environment.api.broker.main }/${ environment.api.broker.accept }`, { accepted: true })
      .pipe(errorHandler);
  }
}
