import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Action, Store } from '@ngrx/store';

import { environment } from '@env/environment';

import { defer, of, throwError, Observable } from 'rxjs';
import { catchError, delay, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { go } from '@core/store/actions/router-history.action';
import { resetTosAction } from '@core/store/actions/tos.action';
import { CoreState } from '@core/store/reducers';
import { resetDashboardListsSearchWithoutReloadAction } from '@modules/dashboard-lists/store/actions/dashboard-lists-search.action';
import { resetDashboardViewsSearchWithoutReloadAction } from '@modules/dashboard-views/store/actions/dashboard-views-search.action';
import { ActionWithPayload } from '@shared/interfaces/store';
import { resetCountriesStateAction } from '../../../countries/store/actions/countries.action';
import * as eCommerceAuthActions from '../../../e-commerce/store/actions/account.action';
import { resetPaymentAction } from '../../../e-commerce/store/actions/payment.action';
import * as notificationActions from '../../../notifications/store/actions/notification.action';
import * as profileActions from '../../../profile/store/actions/profile.action';
import * as authActions from '../actions/auth.action';
import * as invitationActions from '../actions/invitation.action';
import { getIsLoggedIn } from '../selectors/auth.selector';
import { getInvitationExistedUser } from '../selectors/invitation.selector';

import { SnackBarComponent } from '@ui/snack-bar/components/snack-bar/snack-bar.component';

import { AsyncValidatorService } from '@core/services/async-validator.service';
import { ProfileService } from '@core/services/profile.service';
import { StorageService } from '@core/services/storage.service';
import { GaService } from '../../../google-analytics/services/ga.service';
import { AuthService } from '../../services/auth.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';
import { leftDays } from '@shared/utils/left-days';

import { AddressError, IServerError } from '@shared/interfaces/server-error';
import {
  ICheckAddressSignUpData, IFirstLoginFormData, IForgotPasswordData,
  ISignInFormData,
  ISignOutData, ISignUpFormData,
  ISuccessLoginActionData
} from '../../interfaces/formsActionsData';
import { IInvitationUser } from '../../interfaces/invitation';
import {
  ICheckAddressResponse,
  IForgotPasswordResponse,
  ILoginResponse,
  ISuccessMessageResponse
} from '../../interfaces/user';

import { CORE_PATHS } from '@core/constants/core-paths';
import { GA_ACTIONS, GA_CATEGORIES, GA_LABEL } from '@core/constants/ga';
import { NOTIFICATION_TYPES } from '@core/constants/notifications';
import {
  marketViewNotSubscription,
  PERMISSIONS_MSG
} from '@core/constants/permissions';
import { SNACK_BAR_CONFIG } from '@ui/snack-bar/constants';
import { ACCOUNT_MESSAGES } from '../../../profile/constants/messages';
import { PROFILE_PATHS } from '../../../profile/constants/profile-route-paths';
import { AUTH_PATHS } from '../../constants/auth-paths';
import { INVITATION_ACTION } from '../../constants/invitation';
import { RESET_PASSWORD_REDIRECT_TIMER } from '../../constants/timers';

import { WebSocketsProvider } from '../../../websockets';

@Injectable()
export class AuthEffects {

  constructor(private actions$: Actions,
              private validateService: AsyncValidatorService,
              private authService: AuthService,
              private store: Store<CoreState>,
              private ga: GaService,
              private ws: WebSocketsProvider,
              private snackBar: MatSnackBar) {
  }

  signIn$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signInAction),
      withLatestFrom(this.store.pipe(select(getInvitationExistedUser))),
      switchMap(([{ payload: { credentials, remember } },
                   invitation]: [ActionWithPayload<ISignInFormData>, IInvitationUser]) => this.authService.login(credentials)
        .pipe(
          tap((response: ILoginResponse) => {
            const withoutRedirect: boolean = invitation && invitation.token && invitation.email && invitation.email === response.user.email;
            this.authService.onSignIn(response, remember, false, withoutRedirect);
            ProfileService.setUserData(response as any);
          }),
          map((response: ILoginResponse) => authActions.signInSuccessAction({ response, remember, firstLogin: false })),
          catchError((error: IServerError) => of(authActions.signInErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  signIpSuccess$: Observable<unknown> = createEffect(() => defer(() => this.actions$
    .pipe(
      ofType(authActions.signInSuccessAction),
      withLatestFrom(this.store.pipe(select(getInvitationExistedUser))),
      tap(([{ payload: { response } }, invitation]: [ActionWithPayload<ISuccessLoginActionData>, IInvitationUser]) => {

        this.store.dispatch(authActions.checkShowTrialMsgAction());

        if (typeof response.isFirstAssignAvailable !== 'undefined') {
          this.store.dispatch(profileActions.updateIsFirstAssignAvailableAction(response.isFirstAssignAvailable));
        }

        if (invitation && invitation.token
          && invitation.action && StorageService.user && invitation.email === StorageService.user.email) {

          if (INVITATION_ACTION.OWNER_KEEP_SEPARATE.toString() === invitation.action.toString()) {
            this.store.dispatch(go(['/', CORE_PATHS.ACCOUNT, PROFILE_PATHS.PRODUCTS]));
          } else if (INVITATION_ACTION.OWNER_MERGE.toString() === invitation.action.toString()) {
            this.store.dispatch(invitationActions.invitationWithoutNotificationAction());
          } else {
            this.store.dispatch(invitationActions.invitationAction(invitation));
          }

        } else {
          this.store.dispatch(invitationActions.resetInvitationStateAction());
        }
      }),
      catchErrorWithErrorType
    )), { dispatch: false });

  tokenSignIn$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.tokenSignInAction),
      tap((({ payload: { response } }: ActionWithPayload<ISuccessLoginActionData>) => this.authService.onSignIn(response))),
      map(({ payload }: ActionWithPayload<ISuccessLoginActionData>) => authActions.signInSuccessAction(payload)),
      catchErrorWithErrorType
    ));

  signOut$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signOutAction),
      tap(({ payload: { withoutNavigate } }: ActionWithPayload<ISignOutData>) => this.authService.onSignOut(withoutNavigate)),
      switchMap(({ payload }: ActionWithPayload<ISignOutData>) => [
        resetPaymentAction(),
        authActions.signOutSuccessAction(payload),
        authActions.hideTrialMsgAction(),
        resetTosAction(),
        resetCountriesStateAction(),
        resetDashboardListsSearchWithoutReloadAction(),
        resetDashboardViewsSearchWithoutReloadAction()
      ]),
      catchErrorWithErrorType
    ));


  signOutAndRedirectToAdminPanel$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signOutAndGoToAdminPanelAction),
      map(() => authActions.signOutAction({ withoutNavigate: true })),
      tap(() => AuthService.toAdminPanel()),
      catchErrorWithErrorType
    ));

  checkAddress$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.checkAddressAction),
      switchMap(({ payload: { addressValidateData, successAction } }: ActionWithPayload<ICheckAddressSignUpData>) =>
        this.validateService.address(addressValidateData)
          .pipe(
            map(({ valid, errorMessages, suspectAddress }: ICheckAddressResponse | null) => {
              if (valid) {
                return authActions.checkAddressSuccessAction(successAction);
              } else {
                if (errorMessages && suspectAddress) {
                  this.snackBar.openFromComponent(SnackBarComponent, {
                    data: { message: ACCOUNT_MESSAGES.ADDRESS_WAS_CHANGED, canCall: false },
                    ...SNACK_BAR_CONFIG
                  });
                }
                return authActions.checkAddressErrorAction(new AddressError(errorMessages, suspectAddress));
              }
            })
          )
      ),
      catchError((error: IServerError) => throwError(error))
    ));

  actionValidateAddressSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.checkAddressSuccessAction),
      filter(({ payload }: ActionWithPayload<Action | null>) => !!payload),
      map(({ payload }: ActionWithPayload<Action | null>) => payload),
    ));

  signUp$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signUpAction),
      switchMap(({
                   payload: {
                     newUser,
                     remember
                   }
                 }: ActionWithPayload<ISignUpFormData>) => this.authService.signUp(newUser)
        .pipe(
          tap((response: ILoginResponse) => {
            this.authService.onSignIn(response, remember, true);
            ProfileService.setUserData(response as any); // TODO: remove any when back-end will be ready
          }),
          switchMap((response: ILoginResponse) => [
            authActions.signUpSuccessAction(),
            authActions.signInSuccessAction({ response, remember, firstLogin: true })
          ]),
          catchError((error: IServerError) => of(authActions.signUpErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  signUpByFrame$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signUpByFrameAction),
      switchMap(({
                   payload: {
                     newUser,
                     remember
                   }
                 }: ActionWithPayload<ISignUpFormData>) => this.authService.signUp(newUser)
        .pipe(
          tap((response: ILoginResponse) => {
            this.authService.onSignIn(response, remember, true);
            ProfileService.setUserData(response as any); // TODO: remove any when back-end will be ready
          }),
          map((response: ILoginResponse) => authActions.signUpByFrameSuccessAction({
            response,
            remember,
            firstLogin: true
          })),
          catchError((error: IServerError) => of(authActions.signUpErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  redirectAfterSignUpFormFrameSuccess$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.signUpByFrameSuccessAction),
      mapTo(go(['/', CORE_PATHS.AUTH, AUTH_PATHS.SIGN_UP_IN_FRAME_SUCCESS])),
      catchErrorWithErrorType
    ));

  sendCreateUserEventToGa$: Observable<Action> = createEffect(() => defer(() => this.actions$
    .pipe(
      ofType(
        authActions.signUpByFrameSuccessAction,
        authActions.signUpSuccessAction,
        eCommerceAuthActions.eCommerceSignUpSuccessAction
      ),
      filter(() => environment.enableGA),
      tap(() => this.ga.emit(GA_CATEGORIES.NEW_USER, GA_ACTIONS.NEW_USER_SIGN_UP, GA_LABEL.SUCCESS)),
      catchErrorWithErrorType
    )), { dispatch: false });

  firstLogin$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.firstLoginAction),
      switchMap(({ payload }: ActionWithPayload<IFirstLoginFormData>) => this.authService.firstLogin(payload)
        .pipe(
          tap((response: ILoginResponse) => {
            this.authService.onSignIn(response, false, true);
            this.authService.emitGAFirstLogin();
            ProfileService.setUserData(response as any); // TODO: remove any when back-end will be ready
          }),
          map((response: ILoginResponse) => authActions.signInSuccessAction({
            response,
            remember: false,
            firstLogin: true
          })),
          catchError((error: IServerError) => of(authActions.signInErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  checkShowTrialMsg$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.checkShowTrialMsgAction),
      withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
      map(([action, isLoggedIn]: [Action, boolean]) => {
        if (StorageService.trialSeatExpirationDate <= new Date().getTime()) {
          StorageService.trialSeatExpirationDate = null;
        }

        return isLoggedIn && StorageService.trialSeatExpirationDate
          ? authActions.showTrialMsgAction(StorageService.trialSeatExpirationDate)
          : authActions.hideTrialMsgAction();
      }),
      catchErrorWithErrorType
    ));

  showTrialMsg$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.showTrialMsgAction),
      filter(({ payload }: ActionWithPayload<number>) => !!payload),
      map(({ payload: trialSeatExpirationDate }: ActionWithPayload<number>) => notificationActions.showNotificationAction({
          id: 'trial_msg',
          type: NOTIFICATION_TYPES.SUCCESS,
          messageToolTip: marketViewNotSubscription(),
          message: PERMISSIONS_MSG.TRIAL_DATE(leftDays(new Date().getTime(), trialSeatExpirationDate)),
          canCall: true
        })
      ),
      catchErrorWithErrorType
    ));

  hideTrialMsg$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.hideTrialMsgAction),
      map(() => notificationActions.hideNotificationAction('trial_msg')),
      catchErrorWithErrorType
    ));

  resetPassword$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.resetPasswordAction),
      switchMap(({ payload }: ActionWithPayload<IFirstLoginFormData>) => this.authService.changeTemporaryPassword(payload)
        .pipe(
          map((data: ISuccessMessageResponse) => authActions.resetPasswordSuccessAction(data)),
          catchError((error: IServerError) => of(authActions.resetPasswordErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  resetPasswordSuccess$: Observable<Action> = createEffect(() => defer(() => this.actions$
    .pipe(
      ofType(authActions.resetPasswordSuccessAction),
      delay(RESET_PASSWORD_REDIRECT_TIMER),
      tap(() => this.authService.onResetTemporaryPassword()),
      catchErrorWithErrorType
    )), { dispatch: false });

  forgotPassword$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(authActions.forgotPasswordAction),
      switchMap(({ payload }: ActionWithPayload<IForgotPasswordData>) => this.authService.resetPassword(payload)
        .pipe(
          map((data: IForgotPasswordResponse) => authActions.forgotPasswordSuccessAction(data)),
          catchError((error: IServerError) => of(authActions.forgotPasswordErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  disconnectSocket$: Observable<Action> = createEffect(() => defer(() => this.actions$
    .pipe(
      ofType(authActions.signOutSuccessAction),
      tap(() => this.ws.disconnect()),
      catchErrorWithErrorType
    )), { dispatch: false });
}
