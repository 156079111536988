export enum PROFILE_TYPES {
  SCHOOL = 'school',
  DISTRICT = 'district',
  CMO = 'cmo',
  STATE_DEPARTMENT = 'state-department',
  COLLEGE = 'college',
  COLLEGE_OFFICE = 'college-office',
  BID = 'bid'
}

export const DISTRICT_TYPES: string[] = [
  PROFILE_TYPES.DISTRICT,
  PROFILE_TYPES.CMO,
  PROFILE_TYPES.STATE_DEPARTMENT
];
export const SCHOOL_TYPES: string[] = [PROFILE_TYPES.SCHOOL];
export const COLLEGE_TYPES: string[] = [
  PROFILE_TYPES.COLLEGE,
  PROFILE_TYPES.COLLEGE_OFFICE
];

export enum INSTITUTION_TYPE_DESCRIPTION {
  School = 'School',
  District = 'District',
  College = 'College',
  CMO = 'CMO',
  StateDepartment = 'State Dept'
}

export const VALUES_FOR_UPPERCASE: string[] = ['cmo'];

export const INSTITUTION_PROFILE_TYPES: string[] = ['state-department'];
