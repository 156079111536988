import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  ICheckAuthPayload,
  ICheckAuthResponse,
  ICloudSyncAuthFields,
  ICloudSyncAuthResponse,
  ICloudSyncListInfo,
  ICloudSyncPlatforms,
  ICloudSyncPlatformAuthPayload,
  IPlatformAssignPayload,
  IPlatformAssignResponse,
  IShowCloudSyncPopUpPayload
} from '../../interfaces/cloud-sync';

enum ACTION_TYPES {
  GET_PLATFORMS = '[CLOUD SYNC AUTH] Get platforms',
  GET_PLATFORMS_ERROR = '[CLOUD SYNC AUTH] Get platforms error',
  GET_PLATFORMS_SUCCESS = '[CLOUD SYNC AUTH] Get platforms success',

  SET_CURRENT_PLATFORM = '[CLOUD SYNC AUTH] set current platform',
  CLEAR_SELECTED_PLATFORM = '[CLOUD SYNC AUTH] Clear selected platform',

  SET_LIST_ID = '[CLOUD SYNC AUTH] set list id',
  CLEAR_LIST_ID = '[CLOUD SYNC AUTH] clear list id',

  SHOW_AUTH_OR_SYNC_TO_POP_UP = '[CLOUD SYNC AUTH] Show auth or sync to pop up',
  SHOW_CLOUD_SYNC_TO_POP_UP = '[CLOUD SYNC POP UP] Show cloud sync to pop up',

  PLATFORM_ASSIGN_TO_ACCOUNT = '[CLOUD SYNC AUTH] platform assign to account',
  PLATFORM_ASSIGN_TO_ACCOUNT_ERROR = '[CLOUD SYNC AUTH] platform assign to account error',
  PLATFORM_ASSIGN_TO_ACCOUNT_SUCCESS = '[CLOUD SYNC AUTH] platform assign to account success',

  PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS = '[CLOUD SYNC AUTH] platform assign to account by credits',
  PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS_ERROR = '[CLOUD SYNC AUTH] platform assign to account by credits error',
  PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS_SUCCESS = '[CLOUD SYNC AUTH] platform assign to account by credits success',

  GET_PLATFORM_FIELDS = '[CLOUD SYNC AUTH] Get platform fields',
  GET_PLATFORM_FIELDS_ERROR = '[CLOUD SYNC AUTH] Get platform fields error',
  GET_PLATFORM_FIELDS_SUCCESS = '[CLOUD SYNC AUTH] Get platform fields success',

  PLATFORM_AUTH = '[CLOUD SYNC AUTH] platform auth',
  PLATFORM_AUTH_ERROR = '[CLOUD SYNC AUTH] platform auth error',
  PLATFORM_AUTH_SUCCESS = '[CLOUD SYNC AUTH] platform auth success',
  RESET_PLATFORM_AUTH_ERROR = '[CLOUD SYNC AUTH] reset platform auth error',

  CHECK_AUTH = '[CLOUD SYNC AUTH] check auth',
  CHECK_AUTH_ERROR = '[CLOUD SYNC AUTH] check auth error',
  CHECK_AUTH_SUCCESS = '[CLOUD SYNC AUTH] check auth success',

  OPEN_CLOUD_SYNC_AUTH_POP_UP = '[CLOUD SYNC AUTH] Open cloud sync auth pop-up',
  RESET_CLOUD_SYNC_AUTH_POP_UP = '[CLOUD SYNC AUTH] Reset cloud sync auth pop-up data',

  OPEN_CLOUD_SYNC_OWNER_ASSIGN_PRODUCT_POP_UP = '[CLOUD SYNC AUTH] Open owner assign product pop-up',

  SYNC_LIMITED_ERROR = '[LIST CLOUD AUTH] sync limited error',
  CLEAR_SYNC_LIMITED_ERROR = '[LIST CLOUD AUTH] clear sync limited error',

  RESET_STATE = '[CLOUD SYNC AUTH]: reset store'
}

export const getPlatformsAction: ActionCreator<ACTION_TYPES.GET_PLATFORMS, () => TypedAction<ACTION_TYPES.GET_PLATFORMS>> = createAction(ACTION_TYPES.GET_PLATFORMS);
export const getPlatformsErrorAction: CreateActionType<ACTION_TYPES.GET_PLATFORMS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_PLATFORMS_ERROR, createPayload<IServerError>());
export const getPlatformsSuccessAction: CreateActionType<ACTION_TYPES.GET_PLATFORMS_SUCCESS, ICloudSyncPlatforms> = createAction(ACTION_TYPES.GET_PLATFORMS_SUCCESS, createPayload<ICloudSyncPlatforms>());

export const setCurrentPlatformAction: CreateActionType<ACTION_TYPES.SET_CURRENT_PLATFORM, number> = createAction(ACTION_TYPES.SET_CURRENT_PLATFORM, createPayload<number>());
export const clearSelectedPlatformAction: ActionCreator<ACTION_TYPES.CLEAR_SELECTED_PLATFORM, () => TypedAction<ACTION_TYPES.CLEAR_SELECTED_PLATFORM>> = createAction(ACTION_TYPES.CLEAR_SELECTED_PLATFORM);

export const setListIdAction: CreateActionType<ACTION_TYPES.SET_LIST_ID, number> = createAction(ACTION_TYPES.SET_LIST_ID, createPayload<number>());
export const clearListIdAction: ActionCreator<ACTION_TYPES.CLEAR_LIST_ID, () => TypedAction<ACTION_TYPES.CLEAR_LIST_ID>> = createAction(ACTION_TYPES.CLEAR_LIST_ID);

export const showAuthOrSyncToPopUpAction: CreateActionType<ACTION_TYPES.SHOW_AUTH_OR_SYNC_TO_POP_UP, IShowCloudSyncPopUpPayload> = createAction(
  ACTION_TYPES.SHOW_AUTH_OR_SYNC_TO_POP_UP,
  createPayload<IShowCloudSyncPopUpPayload>()
);
export const showCloudSyncToPopUp: CreateActionType<ACTION_TYPES.SHOW_CLOUD_SYNC_TO_POP_UP, ICloudSyncListInfo> = createAction(ACTION_TYPES.SHOW_CLOUD_SYNC_TO_POP_UP, createPayload<ICloudSyncListInfo>());

export const platformAssignToAccountAction: CreateActionType<ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT, IPlatformAssignPayload> = createAction(ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT, createPayload<IPlatformAssignPayload>());
export const platformAssignToAccountErrorAction: CreateActionType<ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_ERROR, IServerError> = createAction(
  ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_ERROR,
  createPayload<IServerError>()
);
export const platformAssignToAccountSuccessAction: CreateActionType<ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_SUCCESS, IPlatformAssignResponse> = createAction(
  ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_SUCCESS,
  createPayload<IPlatformAssignResponse>()
);

// by credits
export const platformAssignByCreditsAction: CreateActionType<ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS, IPlatformAssignPayload> = createAction(
  ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS,
  createPayload<IPlatformAssignPayload>()
);
export const platformAssignByCreditsByCreditsErrorAction: CreateActionType<ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS_ERROR, IServerError> = createAction(
  ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS_ERROR,
  createPayload<IServerError>()
);
export const platformAssignByCreditsSuccessAction: CreateActionType<ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS_SUCCESS, IPlatformAssignResponse> = createAction(
  ACTION_TYPES.PLATFORM_ASSIGN_TO_ACCOUNT_BY_CREDITS_SUCCESS,
  createPayload<IPlatformAssignResponse>()
);

export const getPlatformFieldsAction: CreateActionType<ACTION_TYPES.GET_PLATFORM_FIELDS, number> = createAction(ACTION_TYPES.GET_PLATFORM_FIELDS, createPayload<number>());
export const getPlatformFieldsErrorAction: CreateActionType<ACTION_TYPES.GET_PLATFORM_FIELDS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_PLATFORM_FIELDS_ERROR, createPayload<IServerError>());
export const getPlatformFieldsSuccessAction: CreateActionType<ACTION_TYPES.GET_PLATFORM_FIELDS_SUCCESS, ICloudSyncAuthFields> = createAction(ACTION_TYPES.GET_PLATFORM_FIELDS_SUCCESS, createPayload<ICloudSyncAuthFields>());
export const resetPlatformAuthError: ActionCreator<ACTION_TYPES.RESET_PLATFORM_AUTH_ERROR, () => TypedAction<ACTION_TYPES.RESET_PLATFORM_AUTH_ERROR>> = createAction(ACTION_TYPES.RESET_PLATFORM_AUTH_ERROR);

export const platformAuthAction: CreateActionType<ACTION_TYPES.PLATFORM_AUTH, ICloudSyncPlatformAuthPayload> = createAction(ACTION_TYPES.PLATFORM_AUTH, createPayload<ICloudSyncPlatformAuthPayload>());
export const platformAuthErrorAction: CreateActionType<ACTION_TYPES.PLATFORM_AUTH_ERROR, IServerError> = createAction(ACTION_TYPES.PLATFORM_AUTH_ERROR, createPayload<IServerError>());
export const platformAuthSuccessAction: CreateActionType<ACTION_TYPES.PLATFORM_AUTH_SUCCESS, ICloudSyncAuthResponse> = createAction(
  ACTION_TYPES.PLATFORM_AUTH_SUCCESS,
  createPayload<ICloudSyncAuthResponse>()
);

export const checkAuthAction: CreateActionType<ACTION_TYPES.CHECK_AUTH, ICheckAuthPayload> = createAction(ACTION_TYPES.CHECK_AUTH, createPayload<ICheckAuthPayload>());
export const checkAuthErrorAction: CreateActionType<ACTION_TYPES.CHECK_AUTH_ERROR, IServerError> = createAction(ACTION_TYPES.CHECK_AUTH_ERROR, createPayload<IServerError>());
export const checkAuthSuccessAction: CreateActionType<ACTION_TYPES.CHECK_AUTH_SUCCESS, ICheckAuthResponse> = createAction(ACTION_TYPES.CHECK_AUTH_SUCCESS, createPayload<ICheckAuthResponse>());

export const openCloudSyncAuthPopUpAction: ActionCreator<ACTION_TYPES.OPEN_CLOUD_SYNC_AUTH_POP_UP, () => TypedAction<ACTION_TYPES.OPEN_CLOUD_SYNC_AUTH_POP_UP>> = createAction(ACTION_TYPES.OPEN_CLOUD_SYNC_AUTH_POP_UP);
export const resetCloudSyncAuthPopUpAction: ActionCreator<ACTION_TYPES.RESET_CLOUD_SYNC_AUTH_POP_UP, () => TypedAction<ACTION_TYPES.RESET_CLOUD_SYNC_AUTH_POP_UP>> = createAction(ACTION_TYPES.RESET_CLOUD_SYNC_AUTH_POP_UP);

export const openCloudSyncOwnerAssignProductPopUp: ActionCreator<ACTION_TYPES.OPEN_CLOUD_SYNC_OWNER_ASSIGN_PRODUCT_POP_UP, () => TypedAction<ACTION_TYPES.OPEN_CLOUD_SYNC_OWNER_ASSIGN_PRODUCT_POP_UP>> = createAction(ACTION_TYPES.OPEN_CLOUD_SYNC_OWNER_ASSIGN_PRODUCT_POP_UP);

export const syncLimitedErrorAction: CreateActionType<ACTION_TYPES.SYNC_LIMITED_ERROR, string> = createAction(ACTION_TYPES.SYNC_LIMITED_ERROR, createPayload<string>());
export const clearSyncLimitedErrorAction: ActionCreator<ACTION_TYPES.CLEAR_SYNC_LIMITED_ERROR, () => TypedAction<ACTION_TYPES.CLEAR_SYNC_LIMITED_ERROR>> = createAction(ACTION_TYPES.CLEAR_SYNC_LIMITED_ERROR);

export const resetStateAction: ActionCreator<ACTION_TYPES.RESET_STATE, () => TypedAction<ACTION_TYPES.RESET_STATE>> = createAction(ACTION_TYPES.RESET_STATE);
