import { PopUpText } from '@ui/pop-up/models/pop-up-data';

import { MAX_APPEND_FIELDS_MESSAGE } from './list-purchase';

export const DETAIL_TARGETING_TITLES_TO_REPLACE: string[] = ['Age Range'];

export const HUGE_LIST_SIZE: number = 10000;

export const MAX_APPENDS_MESSAGE: PopUpText = {
  title: `More than ${ MAX_APPEND_FIELDS_MESSAGE } appends?`,
  body: 'Our Enterprise solutions may be more useful and cost effective. Call to learn more.'
};

export const CALCULATED_COUNTS_MESSAGE: string = 'Calculating counts, we will notify you once your upload is complete';
