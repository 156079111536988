import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { CircleLinkComponent } from './components/circle-link/circle-link.component';
import { GoBackComponent } from './components/go-back/go-back.component';

const imports: any[] = [
  RouterModule,
  CommonModule,
  MatRippleModule
];

const declarations: any[] = [
  GoBackComponent,
  CircleLinkComponent,
];
const exports: any[] = [
  ...declarations
];

@NgModule({
  imports,
  declarations,
  exports
})
export class NavigationsModule {
}
