import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { UploadService } from './upload.service';

import { UploadRef } from '../provider/upload-ref';

@Injectable({
  providedIn: 'root'
})
export class UploadResolver {
  constructor(private _uploadService: UploadService) {
  }

  createUploadData(file: File,
                   fileFieldName: string = 'file',
                   fields?: Params,
                   fileName: string = new Date().getTime().toString() + '.csv'): FormData {

    const formData: FormData = new FormData();

    formData.append(fileFieldName, file, file.name || fileName);

    if (fields) {
      Object.entries(fields)
        .forEach(([key, value]: [string, any]) => {
          formData.append(key, typeof value === 'boolean' ? +value : value);
        });
    }

    return formData;
  }

  createRef<T>(defaultUrl?: string): UploadRef<T> {
    return new UploadRef<T>((data: FormData, _url: string = defaultUrl) => this._uploadService.upload(data, _url));
  }
}
