import { forwardRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'bl-custom-checkbox-switch',
  templateUrl: './custom-checkbox-switch.component.html',
  styleUrls: ['./custom-checkbox-switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomCheckboxSwitchComponent),
    multi: true
  }]
})
export class CustomCheckboxSwitchComponent implements ControlValueAccessor {
  @Input() theme: string = 'default';
  @Input() disabled: boolean;
  @Input() pending: boolean;
  @Input() checked: boolean;
  @Input() name: string;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

  propagateChange: any = () => {
  }
  propagateTouch: any = () => {
  }

  writeValue(state: boolean): void {
    if (!this.pending) {
      this.checked = state;
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(event: any): void {
    event.stopPropagation();

    if (!this.pending) {
      this.checked = event.target.checked;
      this.propagateChange(event.target.checked);
      this.propagateTouch(event.target.checked);
      this.change.emit(event.target.checked);
    }
  }
}
