<h3 *ngIf="data?.title">{{ data?.title }}</h3>
<h4 *ngIf="data?.subtitle">{{ data?.subtitle }}</h4>
<p *ngIf="data?.message">{{ data?.message }}</p>

<div class="row">
  <div class="col justify-content-start">

    <button class="btn-back"
            (click)="actions?.prev()"
            matRipple
            [innerText]="data?.prevBtnName || '<   Back'"
            *ngIf="actions?.prev">
    </button>

  </div>
  <div class="col justify-content-end">

    <button class="btn-next"
            (click)="actions?.next()"
            matRipple
            [innerHTML]="data?.nextBtnName || 'Next   >'"
            *ngIf="actions?.next">
    </button>

  </div>
</div>

<button class="btn-close"
        (click)="actions?.close()"
        matRipple
        *ngIf="actions?.close">
  <i class="icon-page-close"></i>
</button>
