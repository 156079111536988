import { Injectable } from '@angular/core';

import { GA_ACTIONS, GA_CATEGORIES, GA_LABEL } from '@core/constants/ga';

declare const ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GaService {

  sendPage(url: string): void {
    ga('set', 'page', url);
    ga('send', 'pageview');
  }

  emit(eventCategory: GA_CATEGORIES,
       eventAction: GA_ACTIONS,
       eventLabel: GA_LABEL | string = null,
       eventValue: number | string = null): void {
    ga('send', 'event', {
      eventCategory,
      eventLabel,
      eventAction,
      eventValue
    });
  }

}
