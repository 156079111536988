import { IKeysMap } from '../interfaces/keyboard';

export const KEYS: IKeysMap = {
  ARROW_UP: {
    code: ['ArrowUp'],
    key: ['ArrowUp', 'Up']
  },
  ARROW_DOWN: {
    code: ['ArrowDown'],
    key: ['ArrowDown', 'Down']
  },
  ENTER: {
    code: ['Enter'],
    key: ['Enter']
  },
  ESCAPE: {
    code: ['Escape'],
    key: ['Escape', 'Esc']
  },
  TAB: {
    code: ['Tab'],
    key: ['Tab']
  }
};
