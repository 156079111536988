import { forwardRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'bl-custom-value-checkbox',
  templateUrl: './custom-value-checkbox.component.html',
  styleUrls: ['./custom-value-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomValueCheckboxComponent),
    multi: true
  }]
})
export class CustomValueCheckboxComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() disabledLabel: string;
  @Input() description: string;
  @Input() checkedValue: any;
  @Input() radioBtn: boolean;
  @Input() theme: 'targeting' | 'filter' = 'targeting';
  @Input() isPremium: boolean = false;

  @Output() premiumDataChanged: EventEmitter<any> = new EventEmitter();

  checked: boolean;
  disabled: boolean;
  isAll: boolean;

  private _value: any;

  constructor() {
  }

  propagateChange: any = () => {
  }

  writeValue(value: any): void {
    this.checked = this.radioBtn ? this.checkedValue === value : !(!value && value !== 0 && typeof value !== 'string');
    this._value = value;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: () => void): void {
  }

  onChange(event: any): void {
    event.stopPropagation();
    this.checked = event.target.checked;

    if (this.checked && this.isPremium) {
      this.premiumDataChanged.emit();
    }

    if (this.checkedValue || this.checkedValue === 0) {
      this.propagateChange(this.checked ? this.checkedValue : null);
    } else {
      this.propagateChange(this.checked);
    }
  }

  get onHoverTooltipText(): string | null {
    if (this.disabled && this.disabledLabel) {
      return this.disabledLabel;
    }

    return null;
  }
}
