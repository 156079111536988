<bl-sticky-element [levelHeight]="5">

  <ng-container *ngFor="let notification of notifications$ | async; trackBy: trackById">

    <bl-mobile-notification *ngIf="isMobile"
                            [id]="notification.id"
                            [type]="notification?.type"
                            [message]="notification.message"
                            [messageToolTip]="notification.messageToolTip"
                            [belowLink]="notification?.belowLink"
                            [belowButton]="notification?.belowButton"
                            [timeout]="notification?.timeout"
                            [canClose]="notification?.canClose"
                            [canCall]="notification?.canCall"
                            (onClose)="hideNotification($event)">
    </bl-mobile-notification>

    <bl-notification *ngIf="!isMobile"
                     [id]="notification.id"
                     [type]="notification?.type"
                     [message]="notification.message"
                     [messageToolTip]="notification.messageToolTip"
                     [belowLink]="notification?.belowLink"
                     [belowButton]="notification?.belowButton"
                     [timeout]="notification?.timeout"
                     [canClose]="notification?.canClose"
                     (onClose)="hideNotification($event)">
    </bl-notification>

  </ng-container>

</bl-sticky-element>
