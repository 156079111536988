import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from '@angular/core';

import { ICloudSyncPlatform, ICloudSyncPlatforms, ISelectPlatformEmit } from '@core/interfaces/cloud-sync';


@Component({
  selector: 'bl-sync-platforms-list',
  templateUrl: './sync-platforms-list.component.html',
  styleUrls: ['./sync-platforms-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SyncPlatformsListComponent {

  @Input() platforms: ICloudSyncPlatforms = [];
  @Input() isShowMoreBtn: boolean = false;

  @Output() onSelectPlatform: EventEmitter<ISelectPlatformEmit> = new EventEmitter<ISelectPlatformEmit>();
  @Output() onOpenSyncToPopUp: EventEmitter<void> = new EventEmitter<void>();

  selectPlatform(platform: ICloudSyncPlatform, sandbox: boolean = true): void {
    this.onSelectPlatform.emit({
      platform,
      sandbox
    });
  }

  openSyncToPopUp(): void {
    this.onOpenSyncToPopUp.emit();
  }

  trackByFn: TrackByFunction<ICloudSyncPlatform> = (index: number, platform: ICloudSyncPlatform) => platform.id;
}
