import { ISeatState, ISeatStates } from '../interfaces/marketview';

import { ADD_ON_FIELD } from '../constants/add-ons';

export const checkedAddOns: (_states?: ISeatStates,
                             _userAddOnIds?: number[],
                             _availableAddOnIds?: number[],
                             _field?: ADD_ON_FIELD) => number[] =
  (_states: ISeatStates = [],
   _userAddOnIds: number[] = [],
   _availableAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): number[] => {
    return _states.filter((_item: ISeatState) => _item[_field]
      && !_userAddOnIds.includes(_item.stateId)
      && !_availableAddOnIds.includes(_item.stateId)
    ).map((_item: ISeatState) => _item.stateId);
  };

export const checkedAvailableAddOns: (_states?: ISeatStates,
                                      _availableAddOnIds?: number[],
                                      _field?: ADD_ON_FIELD) => number[] =
  (_states: ISeatStates = [],
   _availableAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): number[] => {
    return _states.filter((_item: ISeatState) =>
      _item[_field] && _availableAddOnIds.includes(_item.stateId)
    ).map((_item: ISeatState) => _item.stateId);
  };

export const checkedAddOnsOnUser: (_states?: ISeatStates,
                                   _userAddOnIds?: number[],
                                   _availableAddOnIds?: number[],
                                   _field?: ADD_ON_FIELD) => number[] =
  (_states: ISeatStates = [],
   _userAddOnIds: number[] = [],
   _availableAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): number[] => {
    return _states.filter((_item: ISeatState) =>
      _item[_field] && _userAddOnIds.includes(_item.stateId) && !_availableAddOnIds.includes(_item.stateId)
    ).map((_item: ISeatState) => _item.stateId);
  };
