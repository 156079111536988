import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { ICustomObject, ISearchCustomObjectErrorPayload } from '../../../list-cloud-sync/interfaces';


enum ACTION_TYPES {
  SEARCH_CUSTOM_OBJECT = '[LIST CLOUD SYNC] search custom object',
  SEARCH_CUSTOM_OBJECT_ERROR = '[LIST CLOUD SYNC] search custom object error',
  SEARCH_CUSTOM_OBJECT_GENERAL_ERROR = '[LIST CLOUD SYNC] search custom object general error',
  SEARCH_CUSTOM_OBJECT_SUCCESS = '[LIST CLOUD SYNC] search custom object success',
  SEARCH_RESET = '[LIST CLOUD SYNC] search reset',

  SET_SEARCH_CUSTOM_OBJECT_WITHOUT_SEARCH_REQUEST = '[LIST CLOUD SYNC] set search custom object without search request',

  CREATE_SOLUTION = '[LIST CLOUD SYNC] create solution',
  CREATE_SOLUTION_ERROR = '[LIST CLOUD SYNC] create solution error',
  CREATE_SOLUTION_SUCCESS = '[LIST CLOUD SYNC] create solution success',

  REFRESH_CUSTOM_OBJECT_OPEN_CONFIRMATION_POPUP = '[LIST CLOUD SYNC] refresh custom object open confirmation popup',
  REFRESH_CUSTOM_OBJECT_START_PROCESS = '[LIST CLOUD SYNC] refresh custom object start process',
  REFRESH_CUSTOM_OBJECT_START_PROCESS_SUCCESS = '[LIST CLOUD SYNC] refresh custom object start process success',

  LISTEN_SOCKET = '[LIST CLOUD SYNC] listen refresh custom object events',

  RESET = '[LIST CLOUD SYNC] reset state',
}

export const searchCustomObjectAction: CreateActionType<ACTION_TYPES.SEARCH_CUSTOM_OBJECT, string> = createAction(ACTION_TYPES.SEARCH_CUSTOM_OBJECT, createPayload<string>());
export const searchCustomObjectErrorAction: CreateActionType<ACTION_TYPES.SEARCH_CUSTOM_OBJECT_ERROR, ISearchCustomObjectErrorPayload>
  = createAction(ACTION_TYPES.SEARCH_CUSTOM_OBJECT_ERROR, createPayload<ISearchCustomObjectErrorPayload>());
export const searchCustomObjectGeneralErrorAction: CreateActionType<ACTION_TYPES.SEARCH_CUSTOM_OBJECT_GENERAL_ERROR, string> = createAction(
  ACTION_TYPES.SEARCH_CUSTOM_OBJECT_GENERAL_ERROR,
  createPayload<string>()
);
export const searchCustomObjectSuccessAction: CreateActionType<ACTION_TYPES.SEARCH_CUSTOM_OBJECT_SUCCESS, ICustomObject> = createAction(
  ACTION_TYPES.SEARCH_CUSTOM_OBJECT_SUCCESS,
  createPayload<ICustomObject>()
);

export const setSearchCustomObjectWithoutSearchRequestAction: CreateActionType<ACTION_TYPES.SET_SEARCH_CUSTOM_OBJECT_WITHOUT_SEARCH_REQUEST, string> =
  createAction(ACTION_TYPES.SET_SEARCH_CUSTOM_OBJECT_WITHOUT_SEARCH_REQUEST, createPayload<string>());

export const searchResetAction: ActionCreator<ACTION_TYPES.SEARCH_RESET, () => TypedAction<ACTION_TYPES.SEARCH_RESET>> = createAction(ACTION_TYPES.SEARCH_RESET);

export const createSolutionAction: CreateActionType<ACTION_TYPES.CREATE_SOLUTION, number> = createAction(ACTION_TYPES.CREATE_SOLUTION, createPayload<number>()); // listId
export const createSolutionErrorAction: CreateActionType<ACTION_TYPES.CREATE_SOLUTION_ERROR, IServerError> = createAction(ACTION_TYPES.CREATE_SOLUTION_ERROR, createPayload<IServerError>());
export const createSolutionSuccessAction: ActionCreator<ACTION_TYPES.CREATE_SOLUTION_SUCCESS, () => TypedAction<ACTION_TYPES.CREATE_SOLUTION_SUCCESS>> = createAction(ACTION_TYPES.CREATE_SOLUTION_SUCCESS);

export const refreshCustomObjectOpenConfirmationPopUpAction: ActionCreator<ACTION_TYPES.REFRESH_CUSTOM_OBJECT_OPEN_CONFIRMATION_POPUP, () => TypedAction<ACTION_TYPES.REFRESH_CUSTOM_OBJECT_OPEN_CONFIRMATION_POPUP>> = createAction(ACTION_TYPES.REFRESH_CUSTOM_OBJECT_OPEN_CONFIRMATION_POPUP);
export const refreshCustomObjectStartProcessAction: ActionCreator<ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS, () => TypedAction<ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS>> = createAction(ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS);
export const refreshCustomObjectStartProcessErrorAction: CreateActionType<ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS, IServerError> = createAction(ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS,
  createPayload<IServerError>());
export const refreshCustomObjectStartProcessSuccessAction: ActionCreator<ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS_SUCCESS, () => TypedAction<ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS_SUCCESS>> = createAction(ACTION_TYPES.REFRESH_CUSTOM_OBJECT_START_PROCESS_SUCCESS);

export const listenRefreshCustomObjectAction: ActionCreator<ACTION_TYPES.LISTEN_SOCKET, () => TypedAction<ACTION_TYPES.LISTEN_SOCKET>> = createAction(ACTION_TYPES.LISTEN_SOCKET);

export const resetListCloudSyncStateAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
