import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { getCanManageQuickSearch } from '@modules/profile/store/selectors/profile.selector';
import { go } from '../store/actions/router-history.action';
import { CoreState } from '../store/reducers';

@Injectable()
export class QuickSearchPermissionsGuard implements CanActivate, CanLoad {

  private _next(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store
      .pipe(
        select(getCanManageQuickSearch),
        take(1),
        tap((canManageQuickSearch: boolean) => {
          if (!canManageQuickSearch) {
            this.store.dispatch(go('/'));
          }
        })
      );
  }

  constructor(private store: Store<CoreState>) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._next();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this._next();
  }
}
