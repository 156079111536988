import { IEntityRecordsType, IEntityType } from '@shared/interfaces/entity';

import { PRODUCT_ENTITY_TYPES } from '@shared/constants/data/entity';


export interface INewPID {
  pid?: string;
  include?: boolean;
  segmentCriteriaId?: number;
  childrenCount?: number;
  hasUltimateParent?: boolean;
}

export interface IPIDSuggestion extends INewPID {
  name?: string;
  type?: string;
  city?: string;
  state?: string;
  description?: string;
  gradeMin?: string;
  gradeMax?: string;
  county?: string;
  childrenCount?: number | null;
  includeChildren?: boolean;
  includeChildrenAll?: boolean;
  pidNid?: string;
}

export type IPIDSuggestions = IPIDSuggestion[];

export interface IPID extends IPIDSuggestion {
  id?: number;
}

export type IPIDs = IPID[];

export interface ISelectItemSuggestion {
  value: string;
  include: boolean;
}

export interface ISelectSuggestion {
  include: boolean;
  suggestion: any;
}

export interface IRemovePIDChildrenPayload extends IPID, IEntityType, IEntityRecordsType {
}

export interface IPidSuggestionRequest extends IEntityType, IEntityRecordsType {
  query: string;
  targetingId?: number;
}

export interface IAddPidData extends IEntityType, IEntityRecordsType {
  pid: IPIDSuggestion;
  segmentCriteriaId: number;
}

export interface IRemovePidPayload extends IEntityType, IEntityRecordsType {
  pidId: number;
}

export interface IAddPidRequestData {
  pidData: IAddPidData;
  flags?: IPidsChildAndParentPopUpData;
}

export enum IPIDS_TARGETING_TABS {
  SEARCH,
  UPLOAD
}

export enum IPIDS_TARGETING_TABS_NAMES {
  SEARCH = 'SEARCH',
  UPLOAD = 'UPLOAD'
}

export interface IPidTargetingTab {
  id: IPIDS_TARGETING_TABS;
  name: IPIDS_TARGETING_TABS_NAMES;
  selected: boolean;
}

export type IPidTargetingTabs = IPidTargetingTab[];

export interface IMultiPIDResponseError {
  segmentCriteriaId: number;
  data: {
    errors: IMultiPIDResponseErrors;
  };
}

export interface IMultiPIDResponseErrors {
  invalid: number[];
  duplicated: number[];
  wrongSeat: number[];
  includedAsParent: number[];
  excludedAsParent: number[];
}

export type IMultiPIDResponse = null | IMultiPIDResponseError;

export interface IUploadPidFileEvent {
  uploadedPercent: number;
  fileName: string;
  isStopped: boolean;
  listingId: number;
  segmentId: number;
  segmentCriteriaId: number;
  entityType: PRODUCT_ENTITY_TYPES;
  errors?: IMultiPIDResponseErrors | null;
}

export interface ISelectPidFilePayload extends IEntityType, IEntityRecordsType {
  listingId: number;
  segmentCriteriaId: number;
  file: File;
  include?: boolean;
}

export interface ISelectMultiPidPayload extends IEntityRecordsType {
  listingId: number;
  segmentCriteriaId: number;
  entityType: PRODUCT_ENTITY_TYPES;
  value: string;
  include?: boolean;
}

export interface IPidsChildAndParentPopUpData {
  includeChildren?: boolean;
  includeChildrenAll?: boolean;
  includeOnlyParent?: boolean;
  includeFullParent?: boolean;
  includeParentAll?: boolean;
}

