<div class="checkbox-wrapper theme--{{ theme }}">
  <label class="checkbox-switch">

    <input type="checkbox"
           [class.pending]="pending"
           [disabled]="disabled"
           [checked]="checked"
           (change)="onChange($event)"/>

    <span class="checkbox-switch__slider"></span>
  </label>

  <span *ngIf="name"
        class="checkbox-switch__name">
    {{ name }}
  </span>
</div>
