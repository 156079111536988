import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import * as reducer from '../reducers/add-user-account.reducer';

import { IAddUserPopUpPayload, ISelectedAddOnIds } from '@modules/profile/interfaces/marketview';
import { IControlOptions } from '@shared/interfaces/forms';
import { IServerError } from '@shared/interfaces/server-error';

import {
  getAvailableStates,
  getOpenAddOns,
  getOpenStates,
  getOpenStateIdsAvailable,
  getStates
} from './account.selector';
import { selectAddUserState, } from './index';
import { getCDLListPermissions, getIsAdmin } from './profile.selector';


export const getPending: MemoizedSelector<CoreState, boolean> = createSelector(selectAddUserState, reducer.pending);
export const getError: MemoizedSelector<CoreState, IServerError> = createSelector(selectAddUserState, reducer.error);

export const getSelectedAllStateIds: MemoizedSelector<CoreState, number[]> = createSelector(selectAddUserState,
  reducer.selectedAllStateIds);
export const getSelectedAddOnsIds: MemoizedSelector<CoreState, ISelectedAddOnIds> = createSelector(selectAddUserState,
  reducer.selectedAddOnsIds);

export const getIsShouldShowAvailableStates: MemoizedSelector<CoreState, boolean> = createSelector(selectAddUserState,
  reducer.isShouldShowAvailableStates);
export const getSeatId: MemoizedSelector<CoreState, number> = createSelector(selectAddUserState, reducer.seatId);

export const getUncheckedStatesOrSeatStates: MemoizedSelector<CoreState, IControlOptions> = createSelector(
  selectAddUserState,
  getStates,
  getAvailableStates,
  getOpenStates,
  getSelectedAllStateIds,
  reducer.availableUncheckedStates
);

export const getIsHasStates: MemoizedSelector<CoreState, boolean> = createSelector(getStates, reducer.isHasStates);
export const getIsShowAvailableStates: MemoizedSelector<CoreState, boolean> = createSelector(
  selectAddUserState,
  getOpenStateIdsAvailable,
  reducer.isShowAvailableStates
);

export const getCanCreateNewState: MemoizedSelector<CoreState, boolean> = createSelector(selectAddUserState, getOpenStates,
  reducer.canCreateNewState);
export const getIsShowAddState: MemoizedSelector<CoreState, boolean> = createSelector(
  selectAddUserState,
  getOpenStates,
  getSelectedAllStateIds,
  reducer.isShowAddState
);

export const getAddUserPopUpPayload: MemoizedSelector<CoreState, IAddUserPopUpPayload> = createSelector(
  getStates,
  getOpenStates,
  getOpenAddOns,
  getCDLListPermissions,
  getIsAdmin,
  reducer.addUserPopUpPayload
);
