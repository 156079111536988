import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { timer } from 'rxjs';

import { DocumentRef } from '@core/refs/document-ref.service';

@Directive({
  selector: '[blAutofocus]'
})
export class AutofocusDirective implements AfterViewInit, OnChanges {

  @Input() blAutofocus: boolean;

  constructor(private el: ElementRef,
              private _document: DocumentRef) {
  }

  ngAfterViewInit(): void {
    if (this.blAutofocus && !this._isFocusElement()) {
      this.setFocus();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.blAutofocus && changes.blAutofocus.currentValue && !this._isFocusElement()) {
      this.setFocus();
    }
  }

  setFocus(): void {
    timer(0).subscribe(() => {
      this.el.nativeElement.focus();
    });
  }

  private _isFocusElement(): boolean {
    return this._document.nativeElement.activeElement === this.el.nativeElement;
  }
}
