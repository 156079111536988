<div #root
     class="drop-down drop-down__theme--{{ theme || 'default' }}"
     (mouseenter)="toggleOnHover ? open() : null"
     (click)="$event.stopPropagation()"
     [class.full-width]="fullWidth"
     [class.opened]="isOpen"
     data-testid="sub-nav">

  <div class="drop-down__target"
       (click)="!toggleOnHover ? toggle($event) : null">
    <ng-container *ngTemplateOutlet="targetEl"></ng-container>
  </div>

</div>
