import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  ICreateAccountUserForRequest,
  ISelectedStatesPayload,
  SEATS
} from '../../interfaces/marketview';

enum ACTION_TYPES {
  OPEN_ADD_USER_POP_UP = '[ADD USER ACCOUNT] Open add user pop-up',
  CLOSE_ADD_USER_POP_UP = '[ADD USER ACCOUNT] Close add user pop-up',

  SET_SHOW_AVAILABLE_STATES = '[ADD USER ACCOUNT] set show available states',

  ADD_ACCOUNT_USER = '[ADD USER ACCOUNT] Add account user',
  ADD_ACCOUNT_USER_ERROR = '[ADD USER ACCOUNT] Add account user error',
  ADD_ACCOUNT_USER_SUCCESS = '[ADD USER ACCOUNT] Add account user success',

  CHANGE_SELECTED_STATE = '[ADD USER ACCOUNT] Change current state',

  CHANGE_SEAT_TYPE = '[ADD USER ACCOUNT] Change seat type',

  RESET_STATE = '[ADD USER ACCOUNT] Reset state'
}

export const openAddUserPopUpAction: ActionCreator<ACTION_TYPES.OPEN_ADD_USER_POP_UP, () => TypedAction<ACTION_TYPES.OPEN_ADD_USER_POP_UP>> = createAction(ACTION_TYPES.OPEN_ADD_USER_POP_UP);
export const closeUserPopUpAction: ActionCreator<ACTION_TYPES.CLOSE_ADD_USER_POP_UP, () => TypedAction<ACTION_TYPES.CLOSE_ADD_USER_POP_UP>> = createAction(ACTION_TYPES.CLOSE_ADD_USER_POP_UP);

export const setShowAvailableStatesAction: CreateActionType<ACTION_TYPES.SET_SHOW_AVAILABLE_STATES, boolean> = createAction(ACTION_TYPES.SET_SHOW_AVAILABLE_STATES, createPayload<boolean>());

export const addAccountUserAction: CreateActionType<ACTION_TYPES.ADD_ACCOUNT_USER, ICreateAccountUserForRequest> = createAction(ACTION_TYPES.ADD_ACCOUNT_USER, createPayload<ICreateAccountUserForRequest>());
export const addAccountUserErrorAction: CreateActionType<ACTION_TYPES.ADD_ACCOUNT_USER_ERROR, IServerError> = createAction(ACTION_TYPES.ADD_ACCOUNT_USER_ERROR, createPayload<IServerError>());
export const addAccountUserSuccessAction: ActionCreator<ACTION_TYPES.ADD_ACCOUNT_USER_SUCCESS, () => TypedAction<ACTION_TYPES.ADD_ACCOUNT_USER_SUCCESS>> = createAction(ACTION_TYPES.ADD_ACCOUNT_USER_SUCCESS);

export const changeSelectedStateAction: CreateActionType<ACTION_TYPES.CHANGE_SELECTED_STATE, ISelectedStatesPayload> = createAction(ACTION_TYPES.CHANGE_SELECTED_STATE, createPayload<ISelectedStatesPayload>());

export const changeSeatTypeAction: CreateActionType<ACTION_TYPES.CHANGE_SEAT_TYPE, SEATS> = createAction(ACTION_TYPES.CHANGE_SEAT_TYPE, createPayload<SEATS>());

export const resetStateAction: ActionCreator<ACTION_TYPES.RESET_STATE, () => TypedAction<ACTION_TYPES.RESET_STATE>> = createAction(ACTION_TYPES.RESET_STATE);
