<label class="custom-radio"
       [ngClass]="radioClass"
       [class.premium]="isPremium">

  <input type="radio"
         [name]="_name || name"
         (change)="onChange($event)"
         [disabled]="isDisabled"
         [checked]="_initialChecked || checked">

  <span class="fake-input"></span>
  <span class="label"
        blTargetHeight
        [targetHeightTitleText]="label"
        [innerHtml]="label"
        [isTargetingChecked]="_initialChecked || checked"
        [blTooltip]="label">
  </span>

  <i *ngIf="description"
     class="icon-tooltip"
     [blTooltip]="description">
  </i>

</label>
