import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { countriesModuleEffects } from './store/effects';
import { COUNTRIES_STATE, COUNTRIES_STATE_NAME } from './store/reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(COUNTRIES_STATE_NAME, COUNTRIES_STATE),
    EffectsModule.forFeature(countriesModuleEffects),
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class CountriesModule {
}
