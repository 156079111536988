import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { ISignUpByInvitePayload, ISignUpByInviteSuccessPayload } from '../../interfaces/formsActionsData';

enum ACTION_TYPES {
  SIGN_UP = '[AUTH BY INVITE] sign up',
  SIGN_UP_ERROR = '[AUTH BY INVITE] sign up error',
  SIGN_UP_SUCCESS = '[AUTH BY INVITE] sign up success',

  RESET = '[AUTH BY INVITE] reset state'
}

export const signUpByInviteAction: CreateActionType<ACTION_TYPES.SIGN_UP, ISignUpByInvitePayload> = createAction(ACTION_TYPES.SIGN_UP, createPayload<ISignUpByInvitePayload>());
export const signUpByInviteErrorAction: CreateActionType<ACTION_TYPES.SIGN_UP_ERROR, IServerError> = createAction(ACTION_TYPES.SIGN_UP_ERROR, createPayload<IServerError>());
export const signUpByInviteSuccessAction: CreateActionType<ACTION_TYPES.SIGN_UP_SUCCESS, ISignUpByInviteSuccessPayload> = createAction(ACTION_TYPES.SIGN_UP_SUCCESS, createPayload<ISignUpByInviteSuccessPayload>());

export const resetSignUpByInviteStateAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
