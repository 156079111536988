import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { ISegmentData } from '@modules/segment/interfaces/segment';
import {
  IDataListSummary,
  IExportPercentPayload,
  IFetchRefreshListData,
  IRefreshListData,
  ISummaryList
} from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';

enum ACTION_TYPES {
  FETCH_LIST_SUMMARY_DATA = '[LIST PURCHASE]: Fetch list purchase data',
  FETCH_LIST_SUMMARY_DATA_SUCCESS = '[LIST PURCHASE]: Fetch list purchase data success',

  FETCH_REFRESH_DATA = '[LIST PURCHASE]: Fetch refresh data',
  FETCH_REFRESH_DATA_SUCCESS = '[LIST PURCHASE]: Fetch refresh data success',

  FETCH_LIST_SUMMARY_SEGMENT_DATA = '[LIST PURCHASE] fetch list purchase segment data',
  FETCH_LIST_SUMMARY_SEGMENT_DATA_ERROR = '[LIST PURCHASE] fetch list purchase segment data error',
  FETCH_LIST_SUMMARY_SEGMENT_DATA_SUCCESS = '[LIST PURCHASE] fetch list purchase segment data success',

  FETCH_REFRESH_SEGMENT_DATA = '[LIST PURCHASE] fetch refresh segment data',
  FETCH_REFRESH_SEGMENT_DATA_ERROR = '[LIST PURCHASE] fetch refresh segment data error',
  FETCH_REFRESH_SEGMENT_DATA_SUCCESS = '[LIST PURCHASE] fetch refresh segment data success',

  FETCH_DATA_ERROR = '[LIST PURCHASE]: Fetch data error',

  PURCHASE_LIST = '[LIST PURCHASE]: Purchase list',
  PURCHASE_LIST_ERROR = '[LIST PURCHASE]: Purchase list error',
  PURCHASE_LIST_SUCCESS = '[LIST PURCHASE]: Purchase list success',

  CONFIRM_PURCHASE_LIST = '[LIST PURCHASE]: Confirm purchase list',

  RE_PURCHASE_LIST = '[LIST PURCHASE]: Re-Purchase list',
  RE_PURCHASE_LIST_ERROR = '[LIST PURCHASE]: Re-Purchase list error',
  RE_PURCHASE_LIST_SUCCESS = '[LIST PURCHASE]: Re-Purchase list success',

  REFRESH_LIST = '[LIST PURCHASE]: Refresh list',
  REFRESH_LIST_ERROR = '[LIST PURCHASE]: Refresh list error',
  REFRESH_LIST_SUCCESS = '[LIST PURCHASE]: Refresh list success',

  CONFIRM_REFRESH_LIST = '[LIST PURCHASE]: Confirm refresh list',

  SET_REFRESH_PARAMS = '[LIST PURCHASE]: Set Refresh Params',

  SHOW_ALLOCATED_CREDITS_MESSAGE = '[LIST PURCHASE] show allocated message',

  LISTEN_SOCKET = '[LIST PURCHASE] listen list socket events',

  RESET = '[LIST PURCHASE]: Reset purchase',

  EXPORT_LIST = '[LIST PURCHASE] Export list',
  EXPORT_LIST_ERROR = '[LIST PURCHASE] Export list error',
  EXPORT_LIST_SUCCESS = '[LIST PURCHASE] Export list success',

  CALCULATE_EXPORT = '[LIST PURCHASE] Calculate export list',
  CALCULATE_EXPORT_SUCCESS = '[LIST PURCHASE] Calculate export list Success',
}

export const purchaseListAction: CreateActionType<ACTION_TYPES.PURCHASE_LIST, ISummaryList> = createAction(ACTION_TYPES.PURCHASE_LIST, createPayload<ISummaryList>());
export const purchaseListErrorAction: CreateActionType<ACTION_TYPES.PURCHASE_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.PURCHASE_LIST_ERROR, createPayload<IServerError>());
export const purchaseListSuccessAction: CreateActionType<ACTION_TYPES.PURCHASE_LIST_SUCCESS, ISummaryList> = createAction(ACTION_TYPES.PURCHASE_LIST_SUCCESS, createPayload<ISummaryList>());

export const confirmPurchaseListAction: CreateActionType<ACTION_TYPES.CONFIRM_PURCHASE_LIST, IRefreshListData> = createAction(ACTION_TYPES.CONFIRM_PURCHASE_LIST, createPayload<IRefreshListData>());

export const rePurchaseListAction: CreateActionType<ACTION_TYPES.RE_PURCHASE_LIST, ISummaryList> = createAction(ACTION_TYPES.RE_PURCHASE_LIST, createPayload<ISummaryList>());
export const rePurchaseListErrorAction: CreateActionType<ACTION_TYPES.RE_PURCHASE_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.RE_PURCHASE_LIST_ERROR, createPayload<IServerError>());
export const rePurchaseListSuccessAction: CreateActionType<ACTION_TYPES.RE_PURCHASE_LIST_SUCCESS, ISummaryList> = createAction(ACTION_TYPES.RE_PURCHASE_LIST_SUCCESS, createPayload<ISummaryList>());

export const refreshListAction: CreateActionType<ACTION_TYPES.REFRESH_LIST, IRefreshListData> = createAction(ACTION_TYPES.REFRESH_LIST, createPayload<IRefreshListData>());
export const refreshListErrorAction: CreateActionType<ACTION_TYPES.REFRESH_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.REFRESH_LIST_ERROR, createPayload<IServerError>());
export const refreshListSuccessAction: CreateActionType<ACTION_TYPES.REFRESH_LIST_SUCCESS, ISummaryList> = createAction(ACTION_TYPES.REFRESH_LIST_SUCCESS, createPayload<ISummaryList>());

export const confirmRefreshListAction: CreateActionType<ACTION_TYPES.CONFIRM_REFRESH_LIST, IRefreshListData> = createAction(ACTION_TYPES.CONFIRM_REFRESH_LIST, createPayload<IRefreshListData>());

export const setRefreshParamsAction: CreateActionType<ACTION_TYPES.SET_REFRESH_PARAMS, boolean> = createAction(ACTION_TYPES.SET_REFRESH_PARAMS, createPayload<boolean>());

export const fetchListPurchaseDataAction: CreateActionType<ACTION_TYPES.FETCH_LIST_SUMMARY_DATA, number> = createAction(ACTION_TYPES.FETCH_LIST_SUMMARY_DATA, createPayload<number>());
export const fetchListPurchaseDataSuccessAction: CreateActionType<ACTION_TYPES.FETCH_LIST_SUMMARY_DATA_SUCCESS, IDataListSummary> = createAction(ACTION_TYPES.FETCH_LIST_SUMMARY_DATA_SUCCESS,
  createPayload<IDataListSummary>());

export const showAllocatedCreditsMessageAction: ActionCreator<ACTION_TYPES.SHOW_ALLOCATED_CREDITS_MESSAGE, () => TypedAction<ACTION_TYPES.SHOW_ALLOCATED_CREDITS_MESSAGE>> = createAction(ACTION_TYPES.SHOW_ALLOCATED_CREDITS_MESSAGE);

export const fetchRefreshDataAction: CreateActionType<ACTION_TYPES.FETCH_REFRESH_DATA, IFetchRefreshListData> = createAction(ACTION_TYPES.FETCH_REFRESH_DATA, createPayload<IFetchRefreshListData>());
export const fetchRefreshDataSuccessAction: CreateActionType<ACTION_TYPES.FETCH_REFRESH_DATA_SUCCESS, IDataListSummary> = createAction(ACTION_TYPES.FETCH_REFRESH_DATA_SUCCESS, createPayload<IDataListSummary>());

export const fetchListSegmentPurchaseDataAction: CreateActionType<ACTION_TYPES.FETCH_LIST_SUMMARY_SEGMENT_DATA, number> = createAction(ACTION_TYPES.FETCH_LIST_SUMMARY_SEGMENT_DATA, createPayload<number>());
export const fetchListSegmentPurchaseDataErrorAction: CreateActionType<ACTION_TYPES.FETCH_LIST_SUMMARY_SEGMENT_DATA_ERROR, IServerError> = createAction(
  ACTION_TYPES.FETCH_LIST_SUMMARY_SEGMENT_DATA_ERROR,
  createPayload<IServerError>()
);
export const fetchListSegmentPurchaseDataSuccessAction: CreateActionType<ACTION_TYPES.FETCH_LIST_SUMMARY_SEGMENT_DATA_SUCCESS, ISegmentData> = createAction(
  ACTION_TYPES.FETCH_LIST_SUMMARY_SEGMENT_DATA_SUCCESS,
  createPayload<ISegmentData>()
);

export const fetchRefreshSegmentDataAction: CreateActionType<ACTION_TYPES.FETCH_REFRESH_SEGMENT_DATA, number> = createAction(ACTION_TYPES.FETCH_REFRESH_SEGMENT_DATA, createPayload<number>());
export const fetchRefreshSegmentDataErrorAction: CreateActionType<ACTION_TYPES.FETCH_REFRESH_SEGMENT_DATA_ERROR, IServerError> = createAction(
  ACTION_TYPES.FETCH_REFRESH_SEGMENT_DATA_ERROR,
  createPayload<IServerError>()
);
export const fetchRefreshSegmentDataSuccessAction: CreateActionType<ACTION_TYPES.FETCH_REFRESH_SEGMENT_DATA_SUCCESS, ISegmentData> = createAction(
  ACTION_TYPES.FETCH_REFRESH_SEGMENT_DATA_SUCCESS,
  createPayload<ISegmentData>()
);

export const fetchPurchaseDataErrorAction: CreateActionType<ACTION_TYPES.FETCH_DATA_ERROR, IServerError> = createAction(ACTION_TYPES.FETCH_DATA_ERROR, createPayload<IServerError>());

export const listenExportListSocketAction: ActionCreator<ACTION_TYPES.LISTEN_SOCKET, () => TypedAction<ACTION_TYPES.LISTEN_SOCKET>> = createAction(ACTION_TYPES.LISTEN_SOCKET);

export const resetListPurchaseAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);

export const exportListAction: CreateActionType<ACTION_TYPES.EXPORT_LIST, number> = createAction(ACTION_TYPES.EXPORT_LIST, createPayload<number>()); // listId;
export const exportListErrorAction: CreateActionType<ACTION_TYPES.EXPORT_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.EXPORT_LIST_ERROR, createPayload<IServerError>());
export const exportListSuccessAction: CreateActionType<ACTION_TYPES.EXPORT_LIST_SUCCESS, number> = createAction(ACTION_TYPES.EXPORT_LIST_SUCCESS, createPayload<number>());

export const calculateListExportAction: CreateActionType<ACTION_TYPES.CALCULATE_EXPORT, IExportPercentPayload> = createAction(ACTION_TYPES.CALCULATE_EXPORT, createPayload<IExportPercentPayload>());
export const calculateListExportSuccessAction: CreateActionType<ACTION_TYPES.CALCULATE_EXPORT_SUCCESS, IExportPercentPayload> = createAction(ACTION_TYPES.CALCULATE_EXPORT_SUCCESS,
  createPayload<IExportPercentPayload>());
