import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CoreState } from '@core/store/reducers';
import { getAccountCreditsCards } from '../../store/selectors/account.selector';

import { ICreditCardsInfo } from '../../../e-commerce/interfaces/e-commerce';

import { SECURITY_TYPE } from '@shared/constants/log-rocket-config';


@Component({
  selector: 'bl-billing-details-page',
  templateUrl: './billing-details-page.component.html',
  styleUrls: ['./billing-details-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BillingDetailsPageComponent {
  readonly securityType: typeof SECURITY_TYPE = SECURITY_TYPE;

  creditCards$: Observable<ICreditCardsInfo> = this._store.pipe(select(getAccountCreditsCards));

  constructor(private _store: Store<CoreState>) {
  }
}
