import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IPromotionParams,
  IPromoCodeDetailsData,
  IPromoCodeError,
  IValidatedCartItems
} from '../../interfaces/e-commerce';
import { IPrice, ITaxData, ITaxDataResponse } from '../../interfaces/price';

enum ACTION_TYPES {
  ADD_ITEM = '[E-COMMERCE CART] :Add item',
  REMOVE_ITEM = '[E-COMMERCE CART]: Remove item',
  REMOVE_TAXED_ITEM = '[E-COMMERCE CART] Remove item and calculate new tax',

  UPDATE_PROMO_CODE = '[E-COMMERCE CART]: Update Promo Code',
  UPDATE_PROMO_CODE_ERROR = '[E-COMMERCE CART]: Update Promo Code Error',
  UPDATE_PROMO_CODE_SUCCESS = '[E-COMMERCE CART]: Update Promo Code Success',

  RESET_PROMO_CODE_ERROR = '[E-COMMERCE CART] Reset Promo Code Error',

  VALIDATE_CART = '[E-COMMERCE CART] Validate Cart ',
  VALIDATE_CART_ERROR = '[E-COMMERCE CART] Validate Cart Error ',
  VALIDATE_CART_SUCCESS = '[E-COMMERCE CART] Validate Cart Success ',
  RESET_STATUS_VALIDATE_CART = '[E-COMMERCE CART] Reset Status Validate Cart  ',

  GET_TAX = '[E-COMMERCE]: Get tax ',
  GET_TAX_ERROR = '[E-COMMERCE]: Get tax error ',
  GET_TAX_SUCCESS = '[E-COMMERCE]: Get tax success ',
  RESET_TAX = '[E-COMMERCE]: Reset tax',

  RESET_CART = '[E-COMMERCE CART] Reset cart'
}

export const addItemToCartAction: CreateActionType<ACTION_TYPES.ADD_ITEM, IPrice> = createAction(ACTION_TYPES.ADD_ITEM, createPayload<IPrice>());
export const removeItemFromCartAction: CreateActionType<ACTION_TYPES.REMOVE_ITEM, number> = createAction(ACTION_TYPES.REMOVE_ITEM, createPayload<number>());
export const removeTaxedItemFromCardAction: CreateActionType<ACTION_TYPES.REMOVE_TAXED_ITEM, number> = createAction(ACTION_TYPES.REMOVE_TAXED_ITEM, createPayload<number>());

export const updatePromoCodeAction: CreateActionType<ACTION_TYPES.UPDATE_PROMO_CODE, IPromotionParams> = createAction(ACTION_TYPES.UPDATE_PROMO_CODE, createPayload<IPromotionParams>());
export const updatePromoCodeErrorAction: CreateActionType<ACTION_TYPES.UPDATE_PROMO_CODE_ERROR, IPromoCodeError> = createAction(ACTION_TYPES.UPDATE_PROMO_CODE_ERROR, createPayload<IPromoCodeError>());
export const updatePromoCodeSuccessAction: CreateActionType<ACTION_TYPES.UPDATE_PROMO_CODE_SUCCESS, IPromoCodeDetailsData> = createAction(ACTION_TYPES.UPDATE_PROMO_CODE_SUCCESS, createPayload<IPromoCodeDetailsData>());

export const resetProductPromoCodeErrorAction: CreateActionType<ACTION_TYPES.RESET_PROMO_CODE_ERROR, number> = createAction(ACTION_TYPES.RESET_PROMO_CODE_ERROR, createPayload<number>());

export const validateCartAction: CreateActionType<ACTION_TYPES.VALIDATE_CART, IPrice> = createAction(ACTION_TYPES.VALIDATE_CART, createPayload<IPrice>());
export const validateCartErrorAction: CreateActionType<ACTION_TYPES.VALIDATE_CART_ERROR, IServerError> = createAction(ACTION_TYPES.VALIDATE_CART_ERROR, createPayload<IServerError>());
export const validateCartSuccessAction: CreateActionType<ACTION_TYPES.VALIDATE_CART_SUCCESS, IValidatedCartItems> = createAction(ACTION_TYPES.VALIDATE_CART_SUCCESS, createPayload<IValidatedCartItems>());

export const resetStatusValidateCartAction: ActionCreator<ACTION_TYPES.RESET_STATUS_VALIDATE_CART, () => TypedAction<ACTION_TYPES.RESET_STATUS_VALIDATE_CART>> = createAction(ACTION_TYPES.RESET_STATUS_VALIDATE_CART);

export const getTaxAction: CreateActionType<ACTION_TYPES.GET_TAX, ITaxDataResponse> = createAction(ACTION_TYPES.GET_TAX, createPayload<ITaxDataResponse>());
export const getTaxErrorAction: CreateActionType<ACTION_TYPES.GET_TAX_ERROR, IServerError> = createAction(ACTION_TYPES.GET_TAX_ERROR, createPayload<IServerError>());
export const getTaxSuccessAction: CreateActionType<ACTION_TYPES.GET_TAX_SUCCESS, ITaxData> = createAction(ACTION_TYPES.GET_TAX_SUCCESS, createPayload<ITaxData>());

export const resetTaxAction: ActionCreator<ACTION_TYPES.RESET_TAX, () => TypedAction<ACTION_TYPES.RESET_TAX>> = createAction(ACTION_TYPES.RESET_TAX);

export const resetECommerceCartAction: ActionCreator<ACTION_TYPES.RESET_CART, () => TypedAction<ACTION_TYPES.RESET_CART>> = createAction(ACTION_TYPES.RESET_CART);
