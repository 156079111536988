import { IBrowseEntity } from '../interfaces/browse';
import { IJobsSuggestionBackEnd } from '../interfaces/jobs';

export class JobSend {

  // TODO: maybe need to edit ids when BE will be ready
  static TARGETING_CRITERIA_BY_TYPE: { [key: string]: 1 | 2 | 3 | 4 } = {
    jobs: 1,
    categories: 2,
    disciplines: 3,
    subjects: 4,
  };

  static TYPES_NAMES: { [key: string]: string } = {
    jobs: 'Job',
    categories: 'Category',
    subjects: 'Subject',
    disciplines: 'Discipline',
  };

  id: number;
  include: boolean;
  targetingCriteriaId: 1 | 2 | 3 | 4;

  constructor(jobSuggestion: IJobsSuggestionBackEnd, include: boolean = true) {
    this.id = +jobSuggestion.id;
    this.include = include;
    this.targetingCriteriaId = JobSend.TARGETING_CRITERIA_BY_TYPE[jobSuggestion.type];
  }

  static fromBrowse(entity: IBrowseEntity, targetingCriteriaId: 1 | 2 | 3 | 4 = 1): JobSend {
    const newJob: Partial<JobSend> = {};

    newJob.id = entity.id;
    newJob.include = true;
    newJob.targetingCriteriaId = targetingCriteriaId;

    return newJob as JobSend;
  }
}
