<div class="pop-up"
     [style.maxWidth]="maxWidth + 'px'"
     (click)="$event.stopPropagation()">

  <div class="content">

    <button (click)="onCloseBtn($event)"
            type="button"
            *ngIf="config.showCloseBtn"
            class="btn-close">

      <i class="icon-x-medium"></i>

    </button>

    <div class="pop-up-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
