import { SORT_DIRECTION } from '@shared/constants/sort';


export const sortArray: (data: any[], fieldName: (string | number), orderBy?: SORT_DIRECTION) => any[] =
  (data: any[] = [], fieldName: string | number, orderBy: SORT_DIRECTION = SORT_DIRECTION.ASC): any[] => {
  const results: any[] = [...data];

  results.sort((a: any, b: any) => {
    if (a[fieldName] > b[fieldName]) {
      return 1;
    }

    if (a[fieldName] < b[fieldName]) {
      return -1;
    }

    return 0;
  });


  if (orderBy === SORT_DIRECTION.DESC) {
    results.reverse();
  }

  return results;
};
