import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { ActionCreator } from '@ngrx/store/src/models';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as eCommerceAuthActions from '../../../e-commerce/store/actions/account.action';
import * as authActions from '../actions/auth.action';

import { StorageService } from '@core/services/storage.service';

import { AddressError, IServerError } from '@shared/interfaces/server-error';

export interface AuthState {
  isLoggedIn: boolean;

  remember: boolean;
  signInLoading: boolean;
  signInError: IServerError | null;

  signUpLoading: boolean;
  signUpError: IServerError | null;

  signUpAddressError: AddressError | null;

  resetPasswordLoading: boolean;
  resetPasswordError: IServerError | null;
  resetPasswordSuccessMessage: string | null;

  forgotPasswordLoading: boolean;
  forgotPasswordError: IServerError | null;
  forgotPasswordSuccessStatus: boolean | null;
}

const initialState: AuthState = {
  isLoggedIn: !!StorageService.token,
  remember: StorageService.remember,
  signInLoading: false,
  signInError: null,

  signUpLoading: false,
  signUpError: null,

  signUpAddressError: null,

  resetPasswordLoading: false,
  resetPasswordError: null,
  resetPasswordSuccessMessage: null,

  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordSuccessStatus: null
};

const signIn: OnReducer<AuthState, [ActionCreator]> = (state: AuthState) => ({
  ...state,
  signInLoading: true,
  signInError: null
});

const _signInError: OnReducer<AuthState, ActionType<Payload<IServerError>>> = (state: AuthState, { payload }: Payload<IServerError>) => ({
  ...state,
  signInLoading: false,
  signInError: { ...payload },
  remember: false,
  isLoggedIn: false,
});

const signInSuccess: OnReducer<AuthState, ActionType<Payload<any>>> = (state: AuthState, { payload: { remember } }: Payload<any>) => ({
  ...state,
  signInLoading: false,
  signInError: null,
  signUpLoading: false,
  signUpError: null,
  remember: remember,
  isLoggedIn: true,
});

const signOutSuccess: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  remember: false,
  isLoggedIn: false
});

const signUp: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  signUpLoading: true,
  signUpError: null
});

const _signUpError: OnReducer<AuthState, ActionType<Payload<IServerError>>> = (state: AuthState, { payload }: Payload<IServerError>) => ({
  ...state,
  signUpLoading: false,
  signUpError: { ...payload }
});

const checkAddress: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  signUpAddressError: null,
  signUpLoading: true,
});

const checkAddressError: OnReducer<AuthState, ActionType<Payload<any>>> = (state: AuthState, { payload }: Payload<any>) => ({
  ...state,
  signUpLoading: false,
  signUpAddressError: { ...payload }
});

const checkAddressSuccess: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  signInLoading: false
});

const resetSignUp: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  signUpLoading: false,
  signUpError: null
});

const firstLogin: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  signInLoading: true,
  signInError: null
});

const resetPassword: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  resetPasswordLoading: true,
  resetPasswordError: null,
  resetPasswordSuccessMessage: null
});

const _resetPasswordError: OnReducer<AuthState, ActionType<Payload<IServerError>>> = (state: AuthState, { payload }: Payload<IServerError>) => ({
  ...state,
  resetPasswordLoading: false,
  resetPasswordSuccessMessage: null,
  resetPasswordError: { ...payload }
});

const resetPasswordSuccess: OnReducer<AuthState, ActionType<Payload<any>>> = (state: AuthState, { payload: { message } }: Payload<any>) => ({
  ...state,
  resetPasswordError: null,
  resetPasswordSuccessMessage: message
});

const _resetPasswordLoading: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  resetPasswordLoading: false,
  resetPasswordError: null
});

const forgotPassword: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  forgotPasswordLoading: true,
  forgotPasswordError: null,
  forgotPasswordSuccessStatus: null
});

const _forgotPasswordError: OnReducer<AuthState, ActionType<Payload<any>>> = (state: AuthState, { payload }: Payload<any>) => ({
  ...state,
  forgotPasswordLoading: false,
  forgotPasswordSuccessStatus: null,
  forgotPasswordError: { ...payload }
});

const forgotPasswordSuccess: OnReducer<AuthState, ActionType<Payload<any>>> = (state: AuthState, { payload: { success } }: Payload<any>) => ({
  ...state,
  forgotPasswordLoading: false,
  forgotPasswordError: null,
  forgotPasswordSuccessStatus: success
});

const resetForgotPasswordStatus: OnReducer<AuthState, ActionType<any>> = (state: AuthState) => ({
  ...state,
  forgotPasswordSuccessStatus: null
});

const resetAuthState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
  isLoggedIn: false,
  remember: false
});

const reducer: ActionReducer<AuthState> = createReducer<AuthState>(
  initialState,

  on<any>(authActions.signInAction, signIn),
  on(eCommerceAuthActions.eCommerceSignInAction, signIn),

  on(authActions.signInErrorAction, _signInError),
  on(authActions.signInSuccessAction, signInSuccess),
  on(authActions.signUpByFrameSuccessAction, signInSuccess),

  on(authActions.signOutSuccessAction, signOutSuccess),

  on(authActions.signUpAction, signUp),
  on(authActions.signUpByFrameAction, signUp),
  on(eCommerceAuthActions.eCommerceSignUpAction, signUp),

  on(authActions.signUpErrorAction, _signUpError),

  on(authActions.checkAddressAction, checkAddress),
  on(authActions.checkAddressErrorAction, checkAddressError),
  on(authActions.checkAddressSuccessAction, checkAddressSuccess),

  on(authActions.resetSignUpLoadingAction, resetSignUp),
  on(authActions.resetSignUpByFrameLoadingAction, resetSignUp),

  on(authActions.firstLoginAction, firstLogin),

  on(authActions.resetPasswordAction, resetPassword),
  on(authActions.resetPasswordErrorAction, _resetPasswordError),
  on(authActions.resetPasswordSuccessAction, resetPasswordSuccess),

  on(authActions.resetPasswordLoadingAction, _resetPasswordLoading),

  on(authActions.forgotPasswordAction, forgotPassword),
  on(authActions.forgotPasswordErrorAction, _forgotPasswordError),
  on(authActions.forgotPasswordSuccessAction, forgotPasswordSuccess),

  on(authActions.resetForgotPasswordStatusAction, resetForgotPasswordStatus),

  on(authActions.resetAuthStateAction, resetAuthState)
);

export function authReducer(state: AuthState, action: Action): AuthState {
  return reducer(state, action);
}

export const isLoggedIn: GetFromState<boolean, AuthState> = (state: AuthState) => state.isLoggedIn;
export const signInLoading: GetFromState<boolean, AuthState> = (state: AuthState) => state.signInLoading;
export const signInError: GetFromState<IServerError, AuthState> = (state: AuthState) => state.signInError;

export const signUpLoading: GetFromState<boolean, AuthState> = (state: AuthState) => state.signUpLoading;
export const signUpError: GetFromState<IServerError, AuthState> = (state: AuthState) => state.signUpError;

export const signUpAddressError: GetFromState<AddressError, AuthState> = (state: AuthState) => state.signUpAddressError;

export const resetPasswordLoading: GetFromState<boolean, AuthState> = (state: AuthState) => state.resetPasswordLoading;
export const resetPasswordError: GetFromState<IServerError, AuthState> = (state: AuthState) => state.resetPasswordError;
export const resetPasswordSuccessMessage: GetFromState<string, AuthState> = (state: AuthState) => state.resetPasswordSuccessMessage;

export const forgotPasswordLoading: GetFromState<boolean, AuthState> = (state: AuthState) => state.forgotPasswordLoading;
export const forgotPasswordError: GetFromState<IServerError, AuthState> = (state: AuthState) => state.forgotPasswordError;
export const forgotPasswordSuccessStatus: GetFromState<boolean, AuthState> = (state: AuthState) => state.forgotPasswordSuccessStatus;
