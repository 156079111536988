import { SafeHtml } from '@angular/platform-browser';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { CoreState } from '../reducers';
import {
  isOpenMenuMobile,
  orderData,
  orderDataLoaded,
  privacyPolicyData,
  privacyPolicyDataLoaded,
  ICommonPagesState
} from '../reducers/common-pages.reducer';

import { IOrderData } from '@core/interfaces/order-confirmation';
import { IHtmlPageData } from '@core/interfaces/terms-of-servise';

export const getCommonPagesState: GetFromStateInSelectors<CoreState, ICommonPagesState> = (state: CoreState) => state.commonPages;

export const getPrivacyPolicyData: MemoizedSelector<CoreState, IHtmlPageData<SafeHtml>> = createSelector(getCommonPagesState,
  privacyPolicyData);
export const getPrivacyPolicyDataLoaded: MemoizedSelector<CoreState, boolean> = createSelector(getCommonPagesState,
  privacyPolicyDataLoaded);

export const getOrderData: MemoizedSelector<CoreState, IOrderData> = createSelector(getCommonPagesState, orderData);
export const getOrderDataLoaded: MemoizedSelector<CoreState, boolean> = createSelector(getCommonPagesState, orderDataLoaded);

export const getIsOpenMenuMobile: MemoizedSelector<CoreState, boolean> = createSelector(getCommonPagesState, isOpenMenuMobile);
