import { PRODUCT_ENTITY_TYPES } from '@shared/constants/data/entity';


export enum ASSIGN_PLATFORM_TYPE {
  PRODUCT = 1,
  CREDITS
}

export enum PLATFORM_INSTANCE {
  SANDBOX = 'sandbox',
  PROD = 'prod'
}

export const CLOUD_SYNC_AUTH_ERROR: string = 'Credentials are not valid';

export class ICloudSyncAuthError {
  constructor(readonly message: string,
              readonly errors: { [key: string]: string[] }) {
  }
}

export enum CLOUD_SYNC_AUTH_MSG {
  NOT_AUTHORIZE_ERROR = 'Scribe unauthorize error!'
}

export const CLOUD_SYNC_AVAILABLE: PRODUCT_ENTITY_TYPES[] = [PRODUCT_ENTITY_TYPES.LIST, PRODUCT_ENTITY_TYPES.CDL];
