import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { of, throwError, Observable } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getCountriesErrorAction, getCountriesSuccessAction } from '../store/actions/countries.action';
import { getCountries } from '../store/selectors/countries.selector';

import { CountriesService } from '../services/countries.service';

import { IServerError } from '@shared/interfaces/server-error';
import { ICountries } from '../interfaces';


@Injectable({
  providedIn: 'root'
})
export class CountriesExistGuard implements CanActivate {
  constructor(private _store: Store<CoreState>,
              private _service: CountriesService) {
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this._store
      .pipe(
        select(getCountries),
        take(1),
        switchMap((country: ICountries) => {
          return country && country.length
            ? of(true)
            : this._service.getCountries()
              .pipe(
                map((countries: ICountries) => {
                  this._store.dispatch(getCountriesSuccessAction(countries));
                  return true;
                }),
                catchError((error: IServerError) => {
                  this._store.dispatch(getCountriesErrorAction(error));
                  return throwError(error);
                })
              );
        }),
        catchError(() => of(false))
      );
  }

}
