<bl-drop-down class="segment-arrow"
              [zIndex]="6"
              [withoutArrow]="true"
              [topOffset]="-80"
              [leftOffset]="-10"
              [isDisabled]="!items?.length"
              #dropDown>

  <ng-template blDropDownTarget>
    <ng-container *ngTemplateOutlet="dropDownTargetTemplate || defaultDropDownTarget"></ng-container>
  </ng-template>

  <ng-template blDropDownContent>
    <ng-container *ngTemplateOutlet="menuItemsTemplate || defaultMenuItems"></ng-container>
  </ng-template>
</bl-drop-down>

<ng-template #defaultDropDownTarget>
  <div class="target"
       [class.opened]="dropDown?.isOpen">

    <div class="label"
         [class.selected]="selectedOption"
         [class.opened]="dropDown?.isOpen">

      {{ placeholder }}

      <i *ngIf="!selectedOption"
         class="caret-icon icon icon-arrow-subcontainer--down">
      </i>
    </div>

    <span class="selected-option"
          [class.opened]="dropDown?.isOpen">
        {{ selectedOption?.label }}

      <ng-container *ngIf="selectedOption">
         <span *ngIf="items | itemsCount"
               class="selected-option__count">
           +({{ items | itemsCount }}) others
         </span>
         <i class="caret-icon icon icon-arrow-subcontainer--down"></i>
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template #defaultMenuItems>
  <ul class="actions-list">
    <li class="action-item"
        *ngFor="let item of items; trackBy: trackByFn">
      <span class="action">
        <bl-custom-checkbox [checked]="item?.value"
                            (change)="selectItem(item, $event)">
        </bl-custom-checkbox>
        <span class="title">{{ item?.label}}</span>
      </span>
    </li>
  </ul>
</ng-template>

