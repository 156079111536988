import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/common-errors.action';

export interface ICommonErrorsState {
  error: string | null;
}

const initialState: ICommonErrorsState = {
  error: null,
};

const setCommonError: OnReducer<ICommonErrorsState, ActionType<Payload<string>>> = (state: ICommonErrorsState, { payload }: Payload<string>) => ({
  ...state,
  error: payload
});

const resetCommonErrors: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});


const reducer: ActionReducer<ICommonErrorsState> = createReducer<ICommonErrorsState>(
  initialState,
  on(actions.setCommonErrorAction, setCommonError),
  on(actions.resetCommonErrorsAction, resetCommonErrors),
);

export const commonError: GetFromState<string, ICommonErrorsState> = (state: ICommonErrorsState): string =>  state.error;

export function commonErrorsReducer(state: ICommonErrorsState, action: Action): ICommonErrorsState {
  return reducer(state, action);
}

