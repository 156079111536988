import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2 } from '@angular/core';

import { fromEvent, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TooltipDirective } from '@ui/tooltip/directives/tooltip.directive';

import { WindowRef } from '@core/refs/window-ref.service';

import { isTouchDevice } from '../../../utils/deviceChecks';

@Directive({
  selector: '[blTargetHeight]'
})
export class TargetHeightDirective implements AfterViewInit, OnDestroy {

  @Output() addTooltip: EventEmitter<any> = new EventEmitter();

  @Input() targetHeightTitleText: string;
  @Input() isTargetingChecked: boolean = false;

  private heightFinallyChecked: boolean = false;
  private resizeSub: Subscription;

  private readonly maxBtnHeight: number = 20;
  private readonly isTouchDevice: any = isTouchDevice();

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private tooltipDirective: TooltipDirective,
              private window: WindowRef) {
  }

  ngAfterViewInit(): void {
    this.tooltipDirective.isTooltipDisabled = true;
    this.checkTargetHeight();
    setTimeout(() => this.checkTargetHeight());

    this.resizeSub = fromEvent(this.window.nativeElement, 'resize')
      .pipe(tap(() => this.checkTargetHeight()))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.killSub();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (!this.isTargetingChecked && this.heightFinallyChecked && this.isTouchDevice) {
      event.stopPropagation();
      event.preventDefault();

      this.tooltipDirective.isTooltipDisabled = false;
      this.isTargetingChecked = true;
    }
  }

  private checkTargetHeight(): void {
    const targetEl: HTMLElement = this.elementRef.nativeElement as HTMLElement;
    let height: number = targetEl.clientHeight;

    if (height > this.maxBtnHeight) {
      this.setSmallFz();

      height = targetEl.clientHeight;

      if (height > this.maxBtnHeight) {
        this.heightFinallyChecked = true;
        this.setOverflowEllipsis();
        this.killSub();
      }
    }
  }

  private setSmallFz(): void {
    const targetEl: HTMLElement = this.elementRef.nativeElement as HTMLElement;

    this.renderer.setStyle(targetEl, 'font-size', '14px');
    this.renderer.setStyle(targetEl, 'line-height', '16px');
  }

  private setOverflowEllipsis(): void {
    const targetEl: HTMLElement = this.elementRef.nativeElement as HTMLElement;

    this.renderer.setStyle(targetEl, 'white-space', 'nowrap');
    this.renderer.setStyle(targetEl, 'overflow', 'hidden');
    this.renderer.setStyle(targetEl, 'text-overflow', 'ellipsis');

    this.renderer.setAttribute(targetEl, 'title', this.targetHeightTitleText);
  }

  private killSub(): void {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }
  }
}
