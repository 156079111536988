export enum STICKY_TYPES {
  Header,
  Footer,
  ViewSegmentNav,
  SegmentNav,
  PricingListInfo,
  EcommCheckout,
  EcommCheckoutWithoutData,
  PricingPage,
  AlertsSubNav,
  CustomizeNav
}
