import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Action, Store } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { ActionWithPayload } from '@shared/interfaces/store';
import * as authActions from '../../../auth/store/actions/auth.action';
import { showNotificationAction } from '../../../notifications/store/actions/notification.action';
import { getIsUnlimited } from '../../../profile/store/selectors/profile.selector';
import * as creditsActions from '../actions/credits.action';

import { StorageService } from '@core/services/storage.service';
import { CreditsService } from '../../services/credits.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { IServerError } from '@shared/interfaces/server-error';
import { ICreditsRequest, ICreditsResponse } from '../../../auth/interfaces/user';

import { NOTIFICATION_TYPES } from '@core/constants/notifications';
import { DEFAULT_CREDITS_DATA } from '../../../profile/constants/credits';
import { ACCOUNT_MESSAGES } from '../../../profile/constants/messages';


@Injectable()
export class CreditsEffect {
  constructor(private _actions$: Actions,
              private _store: Store<CoreState>,
              private _service: CreditsService) {
  }

  getCredits$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(creditsActions.getCreditsAction),
      withLatestFrom(this._store.pipe(select(getIsUnlimited))),
      filter(([action, isUnlimited]: [ActionWithPayload<ICreditsRequest>, boolean]) => !isUnlimited),
      map(([{ payload }, isUnlimited]: [ActionWithPayload<ICreditsRequest>, boolean]) => payload),
      switchMap(({ showUpdateMsg }: ICreditsRequest) => this._service
        .getCredits()
        .pipe(
          tap((credits: ICreditsResponse) => StorageService.credits = credits),
          map(({ credits, accountCredits, isAllocated }: ICreditsResponse) => creditsActions
            .getCreditsSuccessAction({
              credits,
              accountCredits: accountCredits || DEFAULT_CREDITS_DATA.accountCredits,
              showUpdateMsg,
              isAllocated: isAllocated || DEFAULT_CREDITS_DATA.isAllocated
            })),
          catchError((error: IServerError) => of(creditsActions.getCreditsErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  showCreditsMessage$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(creditsActions.getCreditsSuccessAction),
      filter(({ payload: { showUpdateMsg } }: ActionWithPayload<ICreditsResponse>) => showUpdateMsg),
      map(() => showNotificationAction({
          message: ACCOUNT_MESSAGES.CREDITS_IS_UP_TO_DATE,
          type: NOTIFICATION_TYPES.SUCCESS,
          timeout: 3000,
          canClose: true
        })
      ),
      catchErrorWithErrorType
    ));

  resetState$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(authActions.signOutAction),
      map(() => creditsActions.resetCreditsStateAction())
    ));
}
