import {
  forwardRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { timer } from 'rxjs';

@Component({
  selector: 'bl-autofocus-input',
  templateUrl: './autofocus-input.component.html',
  styleUrls: ['./autofocus-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutofocusInputComponent),
      multi: true,
    }
  ]
})
export class AutofocusInputComponent implements AfterViewInit, OnChanges, ControlValueAccessor {

  @Input() isDisabled: boolean;
  @Output() blur: EventEmitter<string> = new EventEmitter();

  @ViewChild('input') inputEl: ElementRef;

  value: string;

  constructor() {
  }

  private propagateChange = (value: string) => {
  }
  private propagateTouch = () => {
  }

  ngAfterViewInit(): void {
    if (!this.isDisabled) {
      setTimeout(() => {
        this.inputEl.nativeElement.focus();
        this.inputEl.nativeElement.scrollLeft = this.inputEl.nativeElement.scrollWidth;
      }, 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled && !changes.isDisabled.currentValue) {
      timer(0).subscribe(() => {
        this.inputEl.nativeElement.focus();
      });
    }
  }

  writeValue(value: any): void {
    if (value !== void (0)) {
      this.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleChange(): void {
    this.propagateChange(this.value);
  }

  handleBlur(): void {
    this.propagateTouch();
    this.blur.emit(this.value);
  }
}
