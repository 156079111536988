import { Injectable } from '@angular/core';

import { OverlayInfoProvider } from '../../overlay-info/providers/overlay-info-provider.service';
import { OverlayTargetProvider } from '../../overlay-target/providers/overlay-target-provider.service';

import { OverlayInfoRef } from '../../overlay-info/providers/overlay-info-ref';
import { OverlayTargetRef } from '../../overlay-target/providers/overlay-targets-ref';

import { IOnBoardData } from '../interfaces';

import { OnBoardRef } from './on-board-ref';

@Injectable({ providedIn: 'root' })
export class OnBoardProvider {
  constructor(private _targetProvider: OverlayTargetProvider,
              private _infoProvider: OverlayInfoProvider) {
  }

  createOnBoardRef<C, D, A>(onBoardData: IOnBoardData<C, D, A>, destroyOnHide: boolean = true): OnBoardRef {
    const { target, info }: IOnBoardData<C, D, A> = onBoardData;

    const targetRef: OverlayTargetRef = this._targetProvider.createOverlayTargetRef(target, destroyOnHide);
    const infoRef: OverlayInfoRef<C, D, A> = info ? this._infoProvider.createOverlayInfo<C, D, A>(
      target.target, info, destroyOnHide) : null;

    return new OnBoardRef(() => {
      targetRef.show();
      if (infoRef) {
        infoRef.show();
      }
    }, () => {
      targetRef.hide();
      if (infoRef) {
        infoRef.hide();
      }
    }, () => {
      targetRef.hide();
      targetRef.componentRef.destroy();
      if (infoRef && !destroyOnHide) {
        infoRef.hide();
        infoRef.componentRef.destroy();
      }
    });
  }
}
