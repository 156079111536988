<ng-container
  *ngIf="formGroup"
  [formGroup]="formGroup">

  <ng-container [formGroupName]="'credentials'">

    <bl-flat-input [formControlName]="'email'"
                   [errors]="credentials.get('email').errors"
                   (submit)="handleSubmit($event)"
                   type="email"
                   placeholder="Email Address">
    </bl-flat-input>

    <div class="forgot-password__link-wrap">
      <bl-flat-input [formControlName]="'password'"
                     [errors]="credentials.get('password').errors"
                     (submit)="handleSubmit($event)"
                     type="password"
                     placeholder="Password">
      </bl-flat-input>

      <a class="forgot-password__link" [routerLink]="['/', corePaths.AUTH, authPaths.FORGOT_PASSWORD]">Forgot
        Password?</a>
    </div>

  </ng-container>

  <!--  <bl-custom-checkbox [formControlName]="'remember'">&nbsp;Keep me logged In</bl-custom-checkbox>-->

  <ng-content></ng-content>

</ng-container>
