import { IControlOptions } from '@shared/interfaces/forms';
import { IAccessAccountCDLLists, ICDLLists, IManageAccountLists } from '@shared/interfaces/manage-account-lists';
import { ICreditsResponse } from '../../auth/interfaces/user';

import { ACCESS_CDL_LISTS, MANAGE_ACCOUNT_LISTS } from '@shared/constants/data/manage-account-lists';

export interface IAccountUserRole {
  key: string;
  name: string;
}

export interface IAccountSeatState {
  name: string;
  count: number;
  id: number;
}

export type IAccountSeatStates = IAccountSeatState[];

export interface IAccountSeat {
  assigned: number;
  name: string;
  total: number;
  id: SEATS;
  states: IAccountSeatStates;
  statesNational?: IAccountSeatStates;
  bidsNationalAddOns?: IAccountSeat;
  bidsStateAddOns?: IAccountSeat;
  grantsNationalAddOns?: IAccountSeat;
  grantStateAddOns?: IAccountSeat;
  unassigned?: number;
  openNational?: number;
  typeNational: SEATS;
}

export type IAccountSeats = IAccountSeat[];
export type IEmails = string[];

export interface IAccountUser {
  firstName: string;
  lastName: string;
  id: number;
  email: string;
  seats: IAccountUserSeats;
  role: IAccountUserRole;
  isInvited: boolean;
  status: number;
  dataCount: IAccountUserDataCount;
  addOns: IAccountUserAddOns;
  accessLists: IManageAccountLists;
  accessCDLLists: IAccessAccountCDLLists;
  addOnsBids?: IAccountUserAddOns;
  addOnsGrants?: IAccountUserAddOns;
  allocatedCredits?: number;
}

export type IAccountUsers = IAccountUser[];

export interface IAccountUserSeat {
  id: SEATS;
  name?: string;
  stateId?: number;
  stateName?: string;
}

export type IAccountUserSeats = IAccountUserSeat[];

export interface IAccountUserAddOn {
  id: number;
  name?: string;
  stateId?: number;
  stateName?: string;
}

export type IAccountUserAddOns = IAccountUserAddOn[];

export interface IAccountUserDataCount {
  credits: number;
  emails: number;
  lists: number;
  views: number;
}

export interface ICreateAccountUser {
  email: string;
  stateIds: ISeatStates;
  seatId: SEATS;
  roleId: Roles;
  showAccountLists: MANAGE_ACCOUNT_LISTS;
  accessCDLLists: ACCESS_CDL_LISTS;
  addOns?: SEATS[];
  nationalAddOns?: INationalAddOns;
  isAllocated?: boolean;
  allocatedCredits?: number;
}

export interface ICreateAccountUserForRequest {
  email: string;
  stateIds: number[];
  addOns?: SEATS[];
  grantStateIds?: number[];
  bidStateIds?: number[];
  seatId: SEATS;
  roleId: Roles;
  showAccountLists: MANAGE_ACCOUNT_LISTS;
  accessCDLLists: ACCESS_CDL_LISTS;
  isAllocated?: boolean;
  allocatedCredits?: number;
}

export interface ISeatState {
  stateId: number;
  isBidStateId: boolean;
  isGrantStateId: boolean;
}

export type ISeatStates = ISeatState[];

export interface ISelectedStatesPayload {
  states: ISeatStates;
  openStates: IOpenSeatStates;
  openAddOns: IOpenSeatAddOns;
}

export interface ISelectedStateIds {
  unassigned: number[];
  available: number[];
  userHas?: number[];
}

export interface ISelectedAddOnIds {
  bidAddOnIds: number[];
  bidAvailableIds: number[];
  bidAddOnIdsOnUser: number[];
  grantAddOnIds: number[];
  grantAvailableIds: number[];
  grantAddOnIdsOnUser: number[];
}

export interface IEditUserForm {
  id: number;
  stateIds: ISeatStates;
  seatId: SEATS;
  roleId: Roles;
  showAccountLists: MANAGE_ACCOUNT_LISTS;
  accessCDLLists: ACCESS_CDL_LISTS;
  addOns?: SEATS[];
  isAllocated?: boolean;
  allocatedCredits?: number;
  nationalAddOns?: INationalAddOns;
}

export interface IEditUserFormForRequest {
  id: number;
  stateIds: number[];
  addOns?: SEATS[];
  grantStateIds?: number[];
  bidStateIds?: number[];
  seatId: SEATS;
  roleId: Roles;
  showAccountLists: MANAGE_ACCOUNT_LISTS;
  accessCDLLists: ACCESS_CDL_LISTS;
  isAllocated?: boolean;
  allocatedCredits?: number;
}

export interface IEditAccountUser {
  id?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  stateIds?: number[];
  bidStateIds?: number[];
  grantStateIds?: number[];
  addOns?: SEATS[];
  seatId: SEATS;
  roleId: Roles;
  isInvited: boolean;
  status: number;
  accessLists: IManageAccountLists;
  dataCount?: IUserDataCount;
  isDisabledUser?: boolean;
  allocatedCredits?: number;
  accessCDLLists: IAccessAccountCDLLists;
}

export interface IUserDataCount {
  credits: number;
  emails: number;
  lists: number;
  views: number;
}

export enum SEATS {
  Lite = 1,
  National = 2,
  State = 3,
  BidsAddOn = 4,
  GrantsAddOn = 5,
  BidsStateAddOn = 6,
  GrantsStateAddOn = 7,
  Trial = 8
}

export enum Roles {
  Admin = 1,
  User,
  Owner,
}

export enum USER_STATUS {
  NOT_EXIST_USER = 0,
  EXIST_USER = 1
}

export interface IAssignDataFromToUser {
  toUserId?: number;
  fromUserId: number;
}

export interface IRemoveUserFromAccount {
  userId: number;
  isInvited?: boolean;
}

export interface IRemoveUserAfterAssignedData {
  fromUserId: number;
}

export interface INationalAddOns {
  isNationalBidAddOn: boolean;
  isNationalGrantAddOn: boolean;
}

export interface IOpenSeatStates {
  availableIds: number[];
  unassigned: number;
}

export interface IOpenSeatAddOns {
  bidNational: number;
  grantNational: number;
  bidStateAvailable: number[];
  grantStateAvailable: number[];
  bidStateUnassigned: number;
  grantStateUnassigned: number;
}

export interface ISelectedUserData {
  openAddOns: IOpenSeatAddOns;
  selectedBidStateAddOnIds: number[];
  selectedGrantStateAddOnIds: number[];
  isDisabledUser: boolean;
  creditsInfo: ICreditsResponse;
}

export interface IAddUserPopUpPayload {
  allStates: IControlOptions;
  openStates: IOpenSeatStates;
  openAddOns: IOpenSeatAddOns;
  cdlLists: ICDLLists;
  asAdmin: boolean;
}

export interface IEditUserPopUpPayload extends IAddUserPopUpPayload {
  isEditSelf: boolean;
}

export interface IEmailTransformed {
  text: string;
  value: string;
}

export type IEmailsTransformed = IEmailTransformed[];
