<ng-container *blHidePoint="[windowPoints.PHONE]">

  <div class="wrapper" (blClickOutside)="onClickOutside()">

    <div class="header"
         [class.header--disabled]="readonly"
         (click)="toggleDatePicker()">

      <ng-container *ngTemplateOutlet="headerParams"></ng-container>

    </div>

    <div class="date-picker-wrapper">

      <button class="go-to-days-btn"
              (click)="goToDays($event)">

        <i *ngIf="shouldShowGoToDaysBtn"
           class="icon-page-close">
        </i>

      </button>

      <my-date-picker #datePicker
                      *ngIf="isOpen"
                      [selDate]="$any(datePickerDate)"
                      [class.highlight-month]="shouldHighlightMonth"
                      [class.highlight-year]="shouldHighlightYear"
                      [options]="datePickerOptions"
                      [disabled]="readonly"
                      (calendarViewChanged)="onViewChange($event)"
                      (dateChanged)="onDateChange($event)">
      </my-date-picker>
    </div>

  </div>

</ng-container>

<ng-container *blHidePoint="[windowPoints.DESKTOP_LARGE, windowPoints.DESKTOP, windowPoints.TABLET]">

  <bl-mobile-drop-down #mobileDropDown=mobileDropDown
                       [params]="mobileDatepickerParams">

    <div blMobileDropDownTarget>
      <div class="wrapper">
        <div class="header">
          <ng-container *ngTemplateOutlet="headerParams"></ng-container>
        </div>
      </div>
    </div>

    <ng-template blMobileDropDownContent>
      <div class="date-picker-wrapper" (click)="$event.stopPropagation()">
        <my-date-picker [selDate]="$any(datePickerDate)"
                        [class.highlight-month]="shouldHighlightMonth"
                        [class.highlight-year]="shouldHighlightYear"
                        [options]="mobileDPStyles()"
                        (calendarViewChanged)="onViewChange($event)"
                        (dateChanged)="onDateChange($event)">
        </my-date-picker>
      </div>
    </ng-template>
  </bl-mobile-drop-down>

</ng-container>

<!-- header datepicker params template -->
<ng-template #headerParams>
  <span class="label">{{ type }}</span>
  <span class="date">{{ formattedDate }}</span>

  <i class="icon icon-arrow-subcontainer--down caret-icon"></i>
</ng-template>
