import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bl-mobile-tabs-nav',
  templateUrl: './mobile-tabs-nav.component.html',
  styleUrls: ['./mobile-tabs-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTabsNavComponent {
}
