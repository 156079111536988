import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IListCreateData, ISocketRefineListPidsUpdated } from '@modules/list-create/interfaces/list-create';
import { IListData } from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';

enum ACTION_TYPES {
  CHANGE_QUERY_RECORDS_TYPE = '[CREATE LIST FROM VIEW] Change query records type',

  CREATE = '[CREATE LIST FROM VIEW] Create',
  CREATE_ERROR = '[CREATE LIST FROM VIEW] Create Error',
  CREATE_SUCCESS = '[CREATE LIST FROM VIEW] Create Success',

  OPEN_REFINE_YES_MODAL = '[CREATE LIST FROM VIEW] Open refine list modal',
  OPEN_REFINE_PARENT_MODAL = '[CREATE LIST FROM VIEW] Open refine list parent modal',
  OPEN_REFINE_CHILDREN_MODAL = '[CREATE LIST FROM VIEW] Open refine list children modal',

  REFINE_UPDATE_LIST_PIDS = '[CREATE LIST FROM VIEW] Refine update list pids',
  REFINE_UPDATE_LIST_PIDS_ERROR = '[CREATE LIST FROM VIEW] Refine update list pids error',
  REFINE_UPDATE_LIST_PIDS_SUCCESS = '[CREATE LIST FROM VIEW] Refine update list pids success',

  LISTEN_REFINE_UPDATE_LIST_PIDS_SOCKET = '[CREATE LIST FROM VIEW] Listen refine update list pids socket',
  REFINE_LIST_PIDS_UPDATED_SOCKET = '[CREATE LIST FROM VIEW] Refine list pids updated socket response',

  RESET = '[CREATE LIST FROM VIEW] Reset'
}


export const changeQueryRecordsTypeAction: CreateActionType<ACTION_TYPES.CHANGE_QUERY_RECORDS_TYPE, number> = createAction(ACTION_TYPES.CHANGE_QUERY_RECORDS_TYPE, createPayload<number>());

export const listCreateFromViewAction: CreateActionType<ACTION_TYPES.CREATE, IListCreateData> = createAction(ACTION_TYPES.CREATE, createPayload<IListCreateData>());
export const createListFromViewErrorAction: CreateActionType<ACTION_TYPES.CREATE_ERROR, IServerError> = createAction(ACTION_TYPES.CREATE_ERROR, createPayload<IServerError>());
export const createListFromViewSuccessAction: CreateActionType<ACTION_TYPES.CREATE_SUCCESS, IListData> = createAction(ACTION_TYPES.CREATE_SUCCESS, createPayload<IListData>());

export const openRefineYesModalAction: CreateActionType<ACTION_TYPES.OPEN_REFINE_YES_MODAL, IListData> = createAction(ACTION_TYPES.OPEN_REFINE_YES_MODAL, createPayload<IListData>());
export const openRefineParentModalAction: CreateActionType<ACTION_TYPES.OPEN_REFINE_PARENT_MODAL, IListData> = createAction(ACTION_TYPES.OPEN_REFINE_PARENT_MODAL, createPayload<IListData>());
export const openRefineChildrenModalAction: ActionCreator<ACTION_TYPES.OPEN_REFINE_CHILDREN_MODAL, () => TypedAction<ACTION_TYPES.OPEN_REFINE_CHILDREN_MODAL>> = createAction(ACTION_TYPES.OPEN_REFINE_CHILDREN_MODAL);

export const refineUpdateListPidsAction: ActionCreator<ACTION_TYPES.REFINE_UPDATE_LIST_PIDS, () => TypedAction<ACTION_TYPES.REFINE_UPDATE_LIST_PIDS>> = createAction(ACTION_TYPES.REFINE_UPDATE_LIST_PIDS);
export const refineUpdateListPidsErrorAction: CreateActionType<ACTION_TYPES.REFINE_UPDATE_LIST_PIDS_ERROR, IServerError> = createAction(ACTION_TYPES.REFINE_UPDATE_LIST_PIDS_ERROR, createPayload<IServerError>());
export const refineUpdateListPidsSuccessAction: ActionCreator<ACTION_TYPES.REFINE_UPDATE_LIST_PIDS_SUCCESS, () => TypedAction<ACTION_TYPES.REFINE_UPDATE_LIST_PIDS_SUCCESS>> = createAction(ACTION_TYPES.REFINE_UPDATE_LIST_PIDS_SUCCESS);

export const listenRefineUpdateListPidsSocketAction: ActionCreator<ACTION_TYPES.LISTEN_REFINE_UPDATE_LIST_PIDS_SOCKET, () => TypedAction<ACTION_TYPES.LISTEN_REFINE_UPDATE_LIST_PIDS_SOCKET>> = createAction(ACTION_TYPES.LISTEN_REFINE_UPDATE_LIST_PIDS_SOCKET);
export const refineListPidsUpdatedSocketAction: CreateActionType<ACTION_TYPES.REFINE_LIST_PIDS_UPDATED_SOCKET, ISocketRefineListPidsUpdated> = createAction(
  ACTION_TYPES.REFINE_LIST_PIDS_UPDATED_SOCKET, createPayload<ISocketRefineListPidsUpdated>());

export const resetCreateListFromViewAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
