import { ModuleWithProviders, NgModule } from '@angular/core';

import { WebSocketsProvider } from './providers/websockets-provider.service';

import { IWebSocketConfig, WEB_SOCKET_CONFIG_TOKEN } from './data';

@NgModule({
  providers: [WebSocketsProvider]
})
export class WebSocketsModule {
  static config(wsConfig: IWebSocketConfig): ModuleWithProviders<WebSocketsModule> {
    return {
      ngModule: WebSocketsModule,
      providers: [{
        provide: WEB_SOCKET_CONFIG_TOKEN,
        useValue: wsConfig
      }]
    };
  }
}
