import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';

@Component({
  selector: 'bl-sticky-popup',
  templateUrl: './sticky-popup.component.html',
  styleUrls: ['./sticky-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyPopupComponent implements AfterViewInit, OnDestroy {

  @ViewChild('popupWrapperEl') popupWrapperEl: ElementRef;
  @ViewChild('headerWrapperEl') headerWrapperEl: ElementRef;
  @ViewChild('contentWrapperEl') contentWrapperEl: ElementRef;
  @ViewChild('footerWrapperEl') footerWrapperEl: ElementRef;

  private destroyer$: Subject<void> = new Subject();

  private modalOriginalHeight: number;

  headerHeight: number;
  footerHeight: number;

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.modalOriginalHeight = this.modalOriginalHeight ? this.modalOriginalHeight : this.elementRef.nativeElement.clientHeight;
    this.headerHeight = this.headerWrapperEl.nativeElement.clientHeight;
    this.footerHeight = this.footerWrapperEl.nativeElement.clientHeight;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
