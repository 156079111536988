<div *ngIf="cardInfo"
     class="wrapper">

  <div class="header"
       [class.expanded]="isOpened"
       (click)="toggle()"
       matRipple>

    <div class="header__content">

      <span class="card-type-icon">
        <i class="icon-align icon-cc-{{ cardInfo.card_type_sub_code?.toLowerCase() }}"></i>
      </span>

      <span class="card-number">
        &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;{{ cardInfo.last_four_digits }}
      </span>

      <span class="exp-date">({{ expDate }})</span>

    </div>

    <div class="header__icon">
      <i [ngClass]="{ 'icon-carrot-up': isOpened, 'icon-carrot': !isOpened }">
      </i>
    </div>
  </div>

  <div *ngIf="isOpened" class="details">
    <p class="address-title">Billing Name & Address</p>

    <div class="card-address">
      <div class="card-address__info">
        <p>{{ cardInfo.first_name_on_card }} {{ cardInfo.last_name_on_card }}</p>

        <p>{{ address.address_line_1 }}</p>
        <p>{{ address.address_line_2 }}</p>

        <p>{{ address.city_name }} {{ address.state_code }} {{ address.zip_code }} {{ address.country_code }}</p>
      </div>
    </div>
  </div>
</div>
