import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import { Params } from '@angular/router';
import { Store } from '@ngrx/store';

import { go } from '@core/store/actions/router-history.action';
import { CoreState } from '@core/store/reducers';

@Component({
  selector: 'bl-circle-link',
  templateUrl: './circle-link.component.html',
  styleUrls: ['./circle-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircleLinkComponent {
  @Input() href: Array<string | number>;
  @Input() title: string;
  @Input() params: Params;

  @Input()
  @HostBinding('class.disabled')
  disabled: boolean = false;

  @HostListener('click')
  click(): void {
    if (!this.disabled) {
      this.store.dispatch(go(this.href, this.params));
    }
  }

  constructor(private store: Store<CoreState>) {
  }
}
