import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { Params } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { UploadResolver } from '../services/upload-resolver';

import { IServerError } from '@shared/interfaces/server-error';

import { UploadRef } from '../provider/upload-ref';

@Directive({
  selector: '[blUpload]',
  exportAs: 'upload'
})
export class UploadDirective implements OnDestroy {
  @Input() uploadUrl: string = '';
  @Input() params: Params = {};
  @Input() fileFieldName: string = 'file';
  @Input() availableFormats: string[] = [];
  @Input() uploadOnSelectFile: boolean = true;

  @Output() onFileSelect: EventEmitter<File> = new EventEmitter<File>();
  @Output() onLoadStart: EventEmitter<string> = new EventEmitter<string>();
  @Output() onLoadProgress: EventEmitter<number> = new EventEmitter<number>();
  @Output() onLoadSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoadError: EventEmitter<any> = new EventEmitter<any>();

  private _ref: UploadRef<any>;
  private _file: File;
  private _inputElement: HTMLInputElement;
  private _changeListenerFnDestroyer: () => void;
  private _destroyer$: Subject<void> = new Subject<void>();

  @HostListener('click') onClick(): void {
    // We need to recreate input each time to make possible for user select same file and it fired 'change' input event.
    // If user will select same file for same input, it will not emit 'change' and further logic ain't work if we rely on 'change' event.
    // See https://schooldatamdr.atlassian.net/browse/CCP-131
    this._removeElement();
    this._createElement();
  }

  constructor(private _renderer: Renderer2,
              private _elementRef: ElementRef,
              private _uploadResolver: UploadResolver) {
  }

  private _createElement(): void {
    // create input
    this._inputElement = this._renderer.createElement('input');
    this._renderer.setAttribute(this._inputElement, 'accept', this.availableFormats.join(','));
    this._renderer.setAttribute(this._inputElement, 'type', 'file');

    // add listener
    this._changeListenerFnDestroyer = this._renderer.listen(this._inputElement, 'change', (event: DragEvent) => {
      this._file = event.dataTransfer
        ? event.dataTransfer.files[0]
        : (event.target as HTMLInputElement).files[0];

      if (this._file) {
        this.onFileSelect.emit(this._file);
      }

      if (this.uploadOnSelectFile) {
        this.upload();
      }
    });

    // emit click
    this._inputElement.click();
  }

  private _removeElement(): void {
    if (!this._inputElement) {
      return;
    }

    // destroy listener
    if (this._changeListenerFnDestroyer) {
      this._changeListenerFnDestroyer();
    }
    // remove input
    this._renderer.removeChild(this._inputElement, this._elementRef);
    this._inputElement = null;
  }

  upload(params: Params = {}): void {
    if (this._file) {
      const formData: FormData = this._uploadResolver
        .createUploadData(this._file, this.fileFieldName, {
          ...this.params,
          ...params
        });

      this._ref = this._createRef();
      this._ref.upload(formData, this.uploadUrl);
    }
  }

  private _createRef<T>(): UploadRef<T> {
    const ref: UploadRef<T> = this._uploadResolver.createRef<T>();

    ref.progress$
      .pipe(
        takeUntil(this._destroyer$),
        tap((res: number) => {
          if (res === 0) {
            this.onLoadStart.emit(this._file.name);
          }
        })
      ).subscribe((res: number) => this.onLoadProgress.emit(res));

    ref.error$
      .pipe(takeUntil(this._destroyer$))
      .subscribe((error: IServerError) => this.onLoadError.emit(error));

    ref.result$
      .pipe(takeUntil(this._destroyer$))
      .subscribe((res: T) => this.onLoadSuccess.emit(res));

    return ref;
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }
}
