import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { WindowRef } from '@core/refs/window-ref.service';

@Component({
  selector: 'bl-server-errors',
  templateUrl: './server-errors.component.html',
  styleUrls: ['./server-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServerErrorsComponent implements OnChanges {
  @Input() error: string;
  @Input() noScroll: boolean = false;

  constructor(private window: WindowRef) {
  }

  ngOnChanges(): void {
    this.showError();
  }

  showError(): void {
    if (this.error && this.error !== 'Bad request' && !this.noScroll) {
      this.window.nativeElement.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}

