import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/personnel.action';

import { IServerError } from '@shared/interfaces/server-error';
import { IJobSuggestion } from '../../interfaces/jobs';

import { Suggestion } from '../../models/suggestion';

import { MIN_LENGTH_FOR_SEARCH } from '../../constants/autocomplete';

export interface IJobsState {
  suggestions: IJobSuggestion[];
  error: IServerError | null;
  include: boolean;
  shouldReset: boolean;
}

const initialState: IJobsState = {
  suggestions: [],
  error: null,
  include: true,
  shouldReset: false
};

const loadSuggestionsHandler: OnReducer<IJobsState, ActionType<Payload<any>>> = (state: IJobsState, { payload }: Payload<any>) => ({
  ...state,
  error: null,
  suggestions: payload.length >= MIN_LENGTH_FOR_SEARCH ? [...state.suggestions] : [],
  shouldReset: payload.length < MIN_LENGTH_FOR_SEARCH
});

const loadSuggestionsErrorHandler: OnReducer<IJobsState, ActionType<Payload<any>>> = (state: IJobsState, { payload }: Payload<any>) => ({
  ...state,
  error: { ...payload },
  shouldReset: false
});

const loadSuggestionsSuccessHandler: OnReducer<IJobsState, ActionType<Payload<any>>> = (state: IJobsState, { payload }: Payload<any>) => ({
  ...state,
  suggestions: state.shouldReset ? [] : [...payload],
  shouldReset: false
});

const loadSuggestionsResetHandler: OnReducer<IJobsState, ActionType<any>> = (state: IJobsState) => ({
  ...state,
  error: null,
  suggestions: [],
  shouldReset: false
});

const toggleJobsInclude: OnReducer<IJobsState, ActionType<any>> = (state: IJobsState) => ({
  ...state,
  include: !state.include
});

const jobsResetStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<IJobsState> = createReducer<IJobsState>(
  initialState,

  on(actions.loadSuggestionsAction, loadSuggestionsHandler),
  on(actions.loadSuggestionsErrorAction, loadSuggestionsErrorHandler),
  on(actions.loadSuggestionsSuccessAction, loadSuggestionsSuccessHandler),
  on(actions.loadSuggestionsResetAction, loadSuggestionsResetHandler),
  on(actions.toggleJobsIncludeAction, toggleJobsInclude),

  on(actions.jobsResetStateAction, jobsResetStateHandler)
);

export const suggestions: GetFromState<IJobSuggestion[], IJobsState> = (state: IJobsState): IJobSuggestion[] => state.suggestions;
export const suggestionsMap: GetFromState<Suggestion[], IJobSuggestion[]> = (_suggestions: IJobSuggestion[]): Suggestion[] =>
  _suggestions.map((item: IJobSuggestion) => Suggestion.fromJobSuggestion(item));

export const include: GetFromState<boolean, IJobsState> = (state: IJobsState): boolean => state.include;

export function personnelReducer(state: IJobsState,
                                 action: Action): IJobsState {
  return reducer(state, action);
}
