import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IAttachCustomListPayload,
  IDetachCustomListPayload,
  IPidCustomLists,
  IUpdateCustomListPayload
} from '../../interfaces/pid-custom-lists';


enum ACTION_TYPES {
  GET_CUSTOM_LISTS = '[Pid custom lists] pid load all custom lists',
  GET_CUSTOM_LISTS_ERROR = '[Pid custom lists] pid load all custom lists error',
  GET_CUSTOM_LISTS_SUCCESS = '[Pid custom lists] pid load all custom lists success',

  ATTACH_CUSTOM_LIST = '[Pid custom lists] attach custom list',
  ATTACH_CUSTOM_LIST_ERROR = '[Pid custom lists] attach custom list error',
  ATTACH_CUSTOM_LIST_SUCCESS = '[Pid custom lists] attach custom list success',

  DETACH_CUSTOM_LIST = '[Pid custom lists] detach custom list',
  DETACH_CUSTOM_LIST_ERROR = '[Pid custom lists] detach custom list error',
  DETACH_CUSTOM_LIST_SUCCESS = '[Pid custom lists] detach custom list success',

  UPDATE_CUSTOM_LIST = '[Pid custom lists] update custom list',
  UPDATE_CUSTOM_LIST_ERROR = '[Pid custom lists] update custom list error',
  UPDATE_CUSTOM_LIST_SUCCESS = '[Pid custom lists] update custom list success',

  PID_CUSTOM_LISTS_RESET = '[Pid custom lists] reset state'
}


export const getCustomListsAction: ActionCreator<ACTION_TYPES.GET_CUSTOM_LISTS, () => TypedAction<ACTION_TYPES.GET_CUSTOM_LISTS>> = createAction(ACTION_TYPES.GET_CUSTOM_LISTS);
export const getCustomListsErrorAction: CreateActionType<ACTION_TYPES.GET_CUSTOM_LISTS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_CUSTOM_LISTS_ERROR, createPayload<IServerError>());
export const getCustomListsSuccessAction: CreateActionType<ACTION_TYPES.GET_CUSTOM_LISTS_SUCCESS, IPidCustomLists> = createAction(ACTION_TYPES.GET_CUSTOM_LISTS_SUCCESS, createPayload<IPidCustomLists>());

export const attachCustomListAction: CreateActionType<ACTION_TYPES.ATTACH_CUSTOM_LIST, IAttachCustomListPayload> = createAction(ACTION_TYPES.ATTACH_CUSTOM_LIST, createPayload<IAttachCustomListPayload>());
export const attachCustomListErrorAction: CreateActionType<ACTION_TYPES.ATTACH_CUSTOM_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.ATTACH_CUSTOM_LIST_ERROR, createPayload<IServerError>());
export const attachCustomListSuccessAction: ActionCreator<ACTION_TYPES.ATTACH_CUSTOM_LIST_SUCCESS, () => TypedAction<ACTION_TYPES.ATTACH_CUSTOM_LIST_SUCCESS>> = createAction(ACTION_TYPES.ATTACH_CUSTOM_LIST_SUCCESS);

export const detachCustomListAction: CreateActionType<ACTION_TYPES.DETACH_CUSTOM_LIST, IDetachCustomListPayload> = createAction(ACTION_TYPES.DETACH_CUSTOM_LIST, createPayload<IDetachCustomListPayload>());
export const detachCustomListErrorAction: CreateActionType<ACTION_TYPES.DETACH_CUSTOM_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.DETACH_CUSTOM_LIST_ERROR, createPayload<IServerError>());
export const detachCustomListSuccessAction: ActionCreator<ACTION_TYPES.DETACH_CUSTOM_LIST_SUCCESS, () => TypedAction<ACTION_TYPES.DETACH_CUSTOM_LIST_SUCCESS>> = createAction(ACTION_TYPES.DETACH_CUSTOM_LIST_SUCCESS);

export const updateCustomListAction: CreateActionType<ACTION_TYPES.UPDATE_CUSTOM_LIST, IUpdateCustomListPayload> = createAction(ACTION_TYPES.UPDATE_CUSTOM_LIST, createPayload<IUpdateCustomListPayload>());
export const updateCustomListErrorAction: CreateActionType<ACTION_TYPES.UPDATE_CUSTOM_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_CUSTOM_LIST_ERROR, createPayload<IServerError>());
export const updateCustomListSuccessAction: ActionCreator<ACTION_TYPES.UPDATE_CUSTOM_LIST_SUCCESS, () => TypedAction<ACTION_TYPES.UPDATE_CUSTOM_LIST_SUCCESS>> = createAction(ACTION_TYPES.UPDATE_CUSTOM_LIST_SUCCESS);

export const resetPidCustomListsStateAction: ActionCreator<ACTION_TYPES.PID_CUSTOM_LISTS_RESET, () => TypedAction<ACTION_TYPES.PID_CUSTOM_LISTS_RESET>> = createAction(ACTION_TYPES.PID_CUSTOM_LISTS_RESET);
