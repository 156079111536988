import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'bl-profile-details-page',
  templateUrl: './profile-details-page.component.html',
  styleUrls: ['./profile-details-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ProfileDetailsPageComponent {
}
