import { Pipe, PipeTransform } from '@angular/core';

import { IControlOption } from '@shared/interfaces/forms';

import { PRODUCT_ENTITY_TYPES } from '@shared/constants/data/entity';
import { CDL_RECORDS_TYPES_OPTIONS, RECORDS_TYPES_OPTIONS } from '@shared/constants/data/list-types';

@Pipe({
  name: 'listRecordType'
})
export class ListRecordTypePipe implements PipeTransform {

  transform(id: number, entityType: PRODUCT_ENTITY_TYPES = PRODUCT_ENTITY_TYPES.LIST): string {
    let currentRecord: IControlOption;

    switch (entityType) {
      case PRODUCT_ENTITY_TYPES.CDL: {
        currentRecord = CDL_RECORDS_TYPES_OPTIONS.find((type: IControlOption) => type.value === id);
        break;
      }
      default: {
        currentRecord = RECORDS_TYPES_OPTIONS.find((type: IControlOption) => type.value === id);
      }
    }

    return currentRecord && currentRecord.label || '';
  }

}
