import { SafeHtml } from '@angular/platform-browser';
import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/tos.action';

import { IServerError } from '@shared/interfaces/server-error';
import { IHtmlPageData, ITermsOfUsePageData } from '../../interfaces/terms-of-servise';
import { ITOSPreviousUrl } from '../../interfaces/tos';

import { CORE_PATHS } from '../../constants/core-paths';

export interface TOSState {
  tosStatus: boolean;
  isBrokerLicenseAccepted: boolean;

  isTosPage: boolean;

  generalTermsData: IHtmlPageData<SafeHtml> | null;
  generalTermsDataLoaded: boolean;
  generalTermsDataLoading: boolean;
  generalTermsDataError: IServerError | null;

  termsOfUseData: ITermsOfUsePageData<SafeHtml> | null;
  termsOfUseDataLoaded: boolean;
  termsOfUseDataLoading: boolean;
  termsOfUseDataError: IServerError | null;

  tosAccepting: boolean;
  acceptTOSError: IServerError | null;

  previousUrl: ITOSPreviousUrl;
}

const initialState: TOSState = {
  tosStatus: true,
  isBrokerLicenseAccepted: true,

  isTosPage: false,

  generalTermsData: null,
  generalTermsDataLoaded: false,
  generalTermsDataLoading: false,
  generalTermsDataError: null,

  termsOfUseData: null,
  termsOfUseDataLoaded: false,
  termsOfUseDataLoading: false,
  termsOfUseDataError: null,

  tosAccepting: false,
  acceptTOSError: null,

  previousUrl: null
};

const getGeneralTerms: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  generalTermsDataLoading: true,
  generalTermsDataError: null
});

const getGeneralTermsSuccess: OnReducer<TOSState, ActionType<Payload<any>>> = (state: TOSState, { payload }: Payload<any>) => ({
  ...state,
  generalTermsDataError: null,
  generalTermsDataLoading: false,
  generalTermsData: { ...payload },
  generalTermsDataLoaded: true
});

const getGeneralTermsError: OnReducer<TOSState, ActionType<Payload<IServerError>>> = (state: TOSState, { payload }: Payload<IServerError>) => ({
  ...state,
  generalTermsDataError: { ...payload },
  generalTermsDataLoading: false,
  generalTermsDataLoaded: false,
});

const resetGeneralTerms: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  generalTermsDataLoaded: false,
  generalTermsDataLoading: false,
  generalTermsDataError: null
});

const getTermsOfUse: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  termsOfUseDataLoading: true,
  termsOfUseDataError: null,
});

const getTermsOfUseSuccess: OnReducer<TOSState, ActionType<Payload<any>>> = (state: TOSState, { payload }: Payload<any>) => ({
  ...state,
  termsOfUseDataError: null,
  termsOfUseDataLoading: false,
  termsOfUseData: { ...payload },
  termsOfUseDataLoaded: true,
});

const getTermsOfUseError: OnReducer<TOSState, ActionType<Payload<IServerError>>> = (state: TOSState, { payload }: Payload<IServerError>) => ({
  ...state,
  termsOfUseDataError: { ...payload },
  termsOfUseDataLoading: false,
  termsOfUseDataLoaded: false,
});

const acceptTOS: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  acceptTOSError: null,
  tosAccepting: true,
});

const acceptToUSuccess: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  tosStatus: true,
  isBrokerLicenseAccepted: true
});

const acceptTOSSuccess: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  acceptTOSError: null,
  tosAccepting: false,
  tosStatus: true,
  previousUrl: null,
});

const acceptTOSError: OnReducer<TOSState, ActionType<Payload<IServerError>>> = (state: TOSState, { payload }: Payload<IServerError>) => ({
  ...state,
  acceptTOSError: { ...payload },
  tosAccepting: false,
});

const setTOSStatus: OnReducer<TOSState, ActionType<Payload<any>>> = (state: TOSState, { payload }: Payload<any>) => ({
  ...state,
  tosStatus: payload,
  acceptTOSError: null,
  tosAccepting: false
});

const setToUBrokerStatus: OnReducer<TOSState, ActionType<Payload<any>>> = (state: TOSState, { payload }: Payload<any>) => ({
  ...state,
  isBrokerLicenseAccepted: payload,
  acceptTOSError: null,
  tosAccepting: false
});

const onTosPage: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  isTosPage: true
});

const outTosPage: OnReducer<TOSState, ActionType<any>> = (state: TOSState) => ({
  ...state,
  isTosPage: false
});

const redirectToTos: OnReducer<TOSState, ActionType<Payload<any>>> = (state: TOSState, { payload }: Payload<any>) => ({
  ...state,
  previousUrl: { ...payload }
});

const resetTos: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<TOSState> = createReducer<TOSState>(
  initialState,

  on(actions.getGeneralTermsAction, getGeneralTerms),
  on(actions.getGeneralTermsErrorAction, getGeneralTermsError),
  on(actions.getGeneralTermsSuccessAction, getGeneralTermsSuccess),

  on(actions.resetGeneralTermsAction, resetGeneralTerms),

  on(actions.getTermsOfUseAction, getTermsOfUse),
  on(actions.getTermsOfUseErrorAction, getTermsOfUseError),
  on(actions.getTermsOfUseSuccessAction, getTermsOfUseSuccess),

  on(actions.acceptTOSAction, acceptTOS),
  on(actions.acceptTOSErrorAction, acceptTOSError),
  on(actions.acceptTOSSuccessAction, acceptTOSSuccess),

  on(actions.acceptBrokerTOSAction, acceptTOS),
  on(actions.acceptBrokerTOSErrorAction, acceptTOSError),
  on(actions.acceptBrokerTOSSuccessAction, acceptToUSuccess),

  on(actions.setTOSStatusAction, setTOSStatus),
  on(actions.setToUBrokerStatusAction, setToUBrokerStatus),

  on(actions.onTosPageAction, onTosPage),
  on(actions.outTosPageAction, outTosPage),

  on(actions.redirectToTosAction, redirectToTos),

  on(actions.resetTosAction, resetTos)
);

export const tosStatus: GetFromState<boolean, TOSState> = (state: TOSState): boolean => state.tosStatus;
export const isBrokerLicenseAccepted: GetFromState<boolean, TOSState> = (state: TOSState): boolean => state.isBrokerLicenseAccepted;
export const isTosPage: GetFromState<boolean, TOSState> = (state: TOSState): boolean => state.isTosPage;

export const generalTermsData: GetFromState<IHtmlPageData<SafeHtml> | null, TOSState> = (state: TOSState): IHtmlPageData<SafeHtml> | null => state.generalTermsData;
export const generalTermsDataLoaded: GetFromState<boolean, TOSState> = (state: TOSState): boolean => state.generalTermsDataLoaded;

export const termsOfUseData: GetFromState<ITermsOfUsePageData<SafeHtml> | null, TOSState> = (state: TOSState): ITermsOfUsePageData<SafeHtml> | null => state.termsOfUseData;
export const termsOfUseDataLoaded: GetFromState<boolean, TOSState> = (state: TOSState): boolean => state.termsOfUseDataLoaded;

export const previousUrl: GetFromState<ITOSPreviousUrl, TOSState> = (state: TOSState): ITOSPreviousUrl => state.previousUrl || { url: `/${ CORE_PATHS.DASHBOARD }` };

export function tosReducer(state: TOSState, action: Action): TOSState {
  return reducer(state, action);
}
