import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { notificationReducer, INotificationState } from './notification.reducer';

export interface INotificationModuleState {
  notifications: INotificationState;
}

const notificationsReducer: ActionReducerMap<INotificationModuleState> = {
  notifications: notificationReducer
};

// tslint:disable-next-line:typedef
export const NOTIFICATION_NAME = 'notificationModule';
export const NOTIFICATION_STATE: InjectionToken<ActionReducerMap<INotificationModuleState>> =
  new InjectionToken<ActionReducerMap<INotificationModuleState>>(NOTIFICATION_NAME, {
    factory: () => notificationsReducer
  });

