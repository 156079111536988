import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getIsLoggedIn } from '../store/selectors/auth.selector';

import { CORE_PATHS } from '@core/constants/core-paths';
import { AUTH_PATHS } from '../constants/auth-paths';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private _store: Store<CoreState>,
    private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoggedIn(next);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoggedIn(next);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.isLoggedIn(route);
  }

  private isLoggedIn(next: ActivatedRouteSnapshot | Route): Observable<boolean> {
    return this._store
      .pipe(
        select(getIsLoggedIn),
        take(1),
        map((isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this._router.navigateByUrl(next.data.authGuardRedirect || `${ CORE_PATHS.AUTH }/${ AUTH_PATHS.LOGIN }`);
          }

          return isLoggedIn;
        }),
      );
  }
}
