<div class="wrapper theme--{{ theme }}">

  <div class="target theme--{{ theme }}"
       [class.opened]="isOpened"
       (click)="toggleDropdown()"
       #targetEl>

    <div class="label"
         [class.selected]="selectedOption"
         [class.opened]="isOpened">
      {{ placeholder }}
      <i class="caret-icon icon icon-arrow-subcontainer--down"
         *ngIf="!selectedOption">
      </i>

    </div>

    <span class="selected-option"
          [class.opened]="isOpened">
          {{ selectedOption?.label }}
      <i class="caret-icon icon icon-arrow-subcontainer--down"
         *ngIf="selectedOption"></i>
        </span>
  </div>

  <ul #list
      class="actions-list select-options theme--{{ theme }}"
      *ngIf="!this.isDisabled && isOpened"
      [style.width]="dropdownWidth + 'px'"
      [class.opened]="isOpened">

    <li #listItem
        class="action-item"
        *ngFor="let option of options | orderBy: [optionsOrderName]; let i = index"
        [class.selected]="i === highlightedOptionIndex">

      <button class="action option"
              (click)="selectOption($event, option)">
        {{ option.label }}
        <i class="option__icon icon-checked"
           *ngIf="selectedOption && option.value === selectedOption.value"></i>
      </button>
    </li>
  </ul>

  <input (focus)="onFocus()"
         (blur)="onBlur()"
         (keydown)="onClose($event)"
         class="hidden-select"
         [name]="formControlName || name"
         [attr.data-private]="isDataPrivate ? securityType.REDACT : null">

  <div class="error" [class.opened]="isOpened">
    <span class="error-message">{{ error }}</span>
  </div>

</div>
