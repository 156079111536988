import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { PROFILE_MODULE_FEATURE_NAME } from '../selectors';

import { accountPopUpsReducer, IAccountPopUpsState } from './account-pop-ups.reducer';
import { accountUsersReducer, IAccountUsersState } from './account-users.reducer';
import { accountReducer, IAccountState } from './account.reducer';
import { addUserAccountReducer, IAddUserAccountState } from './add-user-account.reducer';
import { editUserAccountReducer, IEditUserAccountState } from './edit-user-account.reducer';
import { profileReducer, IProfileState } from './profile.reducer';

export interface IProfileModuleState {
  profile: IProfileState;
  account: IAccountState;

  accountUsers: IAccountUsersState;

  addUserAccount: IAddUserAccountState;
  editUserAccount: IEditUserAccountState;

  accountPopUps: IAccountPopUpsState;
}

export const profileModuleState: ActionReducerMap<IProfileModuleState> = {
  profile: profileReducer,
  account: accountReducer,

  accountUsers: accountUsersReducer,

  addUserAccount: addUserAccountReducer,
  editUserAccount: editUserAccountReducer,

  accountPopUps: accountPopUpsReducer,
};

export const profileModulesStateToken: InjectionToken<ActionReducerMap<IProfileModuleState>> = new InjectionToken<ActionReducerMap<IProfileModuleState>>(PROFILE_MODULE_FEATURE_NAME);
