<div class="sync-to-wrapper">
  <div class="sync-to-title">
    {{ isSinglePlatform ? 'Sync this list*' : 'Sync all your lists*' }}
  </div>
  <div class="sync-to-desc">
    {{ isSinglePlatform ? 'Sync this list to your preferred platform using credits - deducts credits each ' +
    'time you sync.' : 'Sync as many lists as you choose unlimited times per year for one low price.' }}
  </div>
  <div class="sync-to-markets-wrapper">
    <div class="markets-section">
      <div class="markets-info">
        40+ platforms to choose from, including
      </div>
      <div class="markets-images">
        <div class="market-image salesforce"></div>
        <div class="market-image marketo"></div>
        <div class="market-image hubspot"></div>
      </div>
      <div class="markets-notes">
        *Requires ability to create a custom object in your selected platform.
      </div>
    </div>
  </div>
  <div class="sync-to-price"
       [class.sync-to-price--gray]="isSinglePlatform"
       [class.sync-to-price--green]="!isSinglePlatform">
    <div class="price-desc" *ngIf="isSinglePlatform">
      Costs one credit per record in your list
    </div>
    <div class="price-desc" *ngIf="!isSinglePlatform">
      <p class="main">Starting at $89/mo</p>
      <p class="secondary">(billed annually)</p>
    </div>
  </div>
  <div class="sync-to-footer">
    <div class="select-platform-wrapper" *ngIf="isSinglePlatform">
      <bl-select placeholder="Choose a platform"
                 trackBy="id"
                 [disabled]="disabled"
                 [options]="platforms"
                 [template]="dropdownOptionLayout"
                 (select)="onPlatformSelect($event)">
      </bl-select>
      <ng-template #dropdownOptionLayout
                   let-name="name"
                   let-logo="logoUrl">
        <div class="platform">
          <img class="platform-image"
               [src]="logo"
               [attr.alt]="logo"/>
          <span class="platform-name">{{ name }}</span>
        </div>
      </ng-template>
    </div>
    <div class="call-us-wrapper" *ngIf="!isSinglePlatform">
      <a href="tel:{{ phoneNumber }}">Call {{ phoneNumber | phone: 'brackets' }} to learn more</a>
    </div>
  </div>
</div>
