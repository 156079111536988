import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { IServerError } from '@shared/interfaces/server-error';

import { UPLOAD_URL } from '../provider/token';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private _http: HttpClient,
              @Inject(UPLOAD_URL) private _url: string) {
  }

  upload<R>(formData: FormData, url?: string): Observable<HttpEvent<Object> | IServerError> {
    return this._http.post(typeof url === 'string'
      ? url
      : this._url, formData, { reportProgress: true, observe: 'events' });
  }
}
