import { InjectionToken } from '@angular/core';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { activeListAppendDataReducer, IActiveListAppendDataState } from '@core/store/reducers/active-list-append-data.reducer';
import { listCreateFromSelectedReducer, IListCreateFromSelectedState } from '@core/store/reducers/list-create-from-selected.reducer';
import { listCreateFromViewReducer, IListCreateFromViewState } from '@core/store/reducers/list-create-from-view.reducer';
import { listCreateReducer, IListCreateState } from '@core/store/reducers/list-create.reducers';
import { customerListLargeDownloadReducer, ListLargeDownloadState } from '@core/store/reducers/list-large-download.reducer';
import { listPurchaseReducer, IListPurchaseState } from '@core/store/reducers/list-purchase.reducer';
import { authReducer, AuthState } from '@modules/auth/store/reducers/auth.reducer';
import { invitationReducer, IInvitationState } from '@modules/auth/store/reducers/invitation.reducer';
import { signUpByInviteReducer, ISignUpByInviteState } from '@modules/auth/store/reducers/sign-up-by-invite.reducer';
import { BID_PROFILE_STATE_NAME, IBidProfileModuleState } from '@modules/bid/store/reducers';
import { CDL_LIST_CUSTOMIZE_MODULE_STATE_NAME, ICDLListCustomizeModuleState } from '@modules/cdl/cdl-list-customize/store/reducers';
import { CDL_LIST_HISTORY_MODULE_STATE_NAME, ICDLListHistoryModuleState } from '@modules/cdl/cdl-list-history/store/reducers';
import {
  CDL_REFRESH_SCHEDULE_MODULE_STATE_NAME,
  ICDLRefreshScheduleModuleState
} from '@modules/cdl/cdl-list-refresh-schedule/store/reducers';
import { COLLEGE_OFFICE_MODULE_STATE_NAME, ICollegeOfficeModuleState } from '@modules/college-office/store/reducers';
import { COLLEGE_STATE_NAME, ICollegeModuleState } from '@modules/college/store/reducers';
import { COUNTRIES_STATE_NAME, ICountriesModuleState } from '@modules/countries/store/reducers';
import { CUSTOM_LIST_STATE_NAME, ICustomListModuleState } from '@modules/custom-list/store/reducers';
import { DASHBOARD_LIST_STATE_NAME, IDashboardListsModuleState } from '@modules/dashboard-lists/store/reducers';
import { DASHBOARD_VIEWS_STATE_NAME, IDashboardViewsModuleState } from '@modules/dashboard-views/store/reducers';
import { DISTRICT_PROFILE_STATE_NAME, IDistrictProfileReducers } from '@modules/district/store/reducers';
import { E_COMMERCE_STATE_NAME, ECommerceState } from '@modules/e-commerce/store/reducers';
import { cartReducer, ICartState } from '@modules/e-commerce/store/reducers/cart.reducer';
import { GEOGRAPHY_CODES_MODULE_STATE_NAME, IGeographyCodesModuleState } from '@modules/geo-codes/store/reducers';
import { GRANT_PROFILE_STATE_NAME, IGrantProfileModuleState } from '@modules/grant/store/reducers';
import { IListCommonModuleState, LIST_COMMON_STATE_NAME } from '@modules/list-common-store/store/reducers';
import { IListModuleState, LIST_STATE_NAME } from '@modules/list/store/reducers';
import { INameAndPidCodesModuleState, NAME_AND_PID_CODES_MODULE_STATE_NAME } from '@modules/name-and-pid-codes/store/reducers';
import { INotificationModuleState, NOTIFICATION_NAME } from '@modules/notifications/store/reducers';
import { IPersonnelCodesModuleState, PERSONNEL_CODES_MODULE_STATE_NAME } from '@modules/personnel-codes/store/reducers';
import { IQuickSearchModuleState, QUICK_SEARCH_STATE_NAME } from '@modules/quick-search/store/reducers';
import { ISchoolProfileReducers, SCHOOL_PROFILE_STATE_NAME } from '@modules/school/store/reducers';
import { ISegmentModuleState, SEGMENT_MODULE_STATE_NAME } from '@modules/segment/store/reducers';
import { pidsFileUploadReducer, IPidsFileUploadSTate } from '@modules/segment/store/reducers/pids-file.reducer';
import { IViewAlertsModuleState, VIEW_ALERTS_STATE_NAME } from '@modules/view-alerts/store/reducers';
import { VIEW_COMMON_STATE_NAME } from '@modules/view-common-store/store/reducers';
import { IViewResultsAlertsState, VIEW_RESULTS_STATE_NAME } from '@modules/view-results-common-store/store/reducers';
import { IViewResultsModuleState, VIEW_RESULTS_STATE_MODULE_NAME } from '@modules/view-results/store/reducers';
import { IViewModuleState, VIEW_STATE_NAME } from '@modules/view/store/reducers';
import { BIDS_ENTITY_STATE_NAME, IBidsEntityModuleState } from '@shared/modules/bids-entity/store/reducers';
import { DOWNLOAD_ENTITY_STATE_NAME, IDownloadEntityModuleState } from '@shared/modules/download-entity/store/reducers';
import { GRANTS_ENTITY_STATE_NAME, IGrantsEntityModuleState } from '@shared/modules/grants-entity/store/reducers';
import { IPersonnelEntityModuleState, PERSONNEL_ENTITY_STATE_NAME } from '@shared/modules/personnel-entity/store/reducers';
import { ISpinnerModuleState, SPINNER_MODULE_FEATURE_NAME } from '@ui/spinner/store/reducers';
import { spinnerReducer, ISpinnerState } from '@ui/spinner/store/reducers/spinner.reducer';
import { ITooltipsModuleState, TOOLTIPS_MODULE_FEATURE_NAME } from '@ui/tooltip/store/reducers';
import { tooltipsReducer, ITooltipsState } from '@ui/tooltip/store/reducers/tooltips.reducer';
import {
  CDL_ORDER_SUMMARY_MODULE_STATE_NAME,
  ICDLOrderSummaryModuleState
} from '../../../modules/cdl/cdl-list-order-summary/store/reducers';
import { CDL_LIST_STATE_NAME, ICDLListModuleState } from '../../../modules/cdl/cdl-list/store/reducers';

import { brokersTOUReducer, IBrokersTOUSTate } from './brokers-tou.reducer';
import { cloudSyncAuthReducer, ICloudSyncAuthState } from './cloud-sync-auth.reducer';
import { commonErrorsReducer, ICommonErrorsState } from './common-errors.reducer';
import { commonPagesReducer, ICommonPagesState } from './common-pages.reducer';
import { downloadReducer, IDownloadState } from './download.reducer';
import { routerHistoryReducer, RouterHistoryState } from './router-history.reducer';
import { RouterStateUrl } from './router.reducer';
import { showEmailReducer, IShowEmailState } from './show-email.reducer';
import { tosReducer, TOSState } from './tos.reducer';


export interface CoreState {
  router: RouterReducerState<RouterStateUrl>;
  routerHistory: RouterHistoryState;
  tos: TOSState;
  auth: AuthState;
  invitation: IInvitationState;
  tooltips: ITooltipsState;
  customerListLargeDownload: ListLargeDownloadState;
  customerListPurchase: IListPurchaseState;
  listCreate: IListCreateState;
  listCreateFromSelected: IListCreateFromSelectedState;
  listCreateFromView: IListCreateFromViewState;
  commonPages: ICommonPagesState;
  download: IDownloadState;
  eCommerceCart: ICartState;
  showEmail: IShowEmailState;
  signUpByInvite: ISignUpByInviteState;
  commonErrors: ICommonErrorsState;
  cloudSyncAuth: ICloudSyncAuthState;
  pidsFile: IPidsFileUploadSTate;
  brokersTermOfUse: IBrokersTOUSTate;
  activeListAppendData: IActiveListAppendDataState;
  spinner: ISpinnerState;


  // feature states
  [BID_PROFILE_STATE_NAME]?: IBidProfileModuleState;
  [COLLEGE_OFFICE_MODULE_STATE_NAME]?: ICollegeOfficeModuleState;
  [COLLEGE_STATE_NAME]?: ICollegeModuleState;
  [COUNTRIES_STATE_NAME]?: ICountriesModuleState;
  [CUSTOM_LIST_STATE_NAME]?: ICustomListModuleState;
  [CDL_LIST_STATE_NAME]?: ICDLListModuleState;
  [CDL_REFRESH_SCHEDULE_MODULE_STATE_NAME]?: ICDLRefreshScheduleModuleState;
  [DASHBOARD_LIST_STATE_NAME]?: IDashboardListsModuleState;
  [DASHBOARD_VIEWS_STATE_NAME]?: IDashboardViewsModuleState;
  [DISTRICT_PROFILE_STATE_NAME]?: IDistrictProfileReducers;
  [E_COMMERCE_STATE_NAME]?: ECommerceState;
  [GEOGRAPHY_CODES_MODULE_STATE_NAME]?: IGeographyCodesModuleState;
  [GRANT_PROFILE_STATE_NAME]?: IGrantProfileModuleState;
  [LIST_STATE_NAME]?: IListModuleState;
  [NAME_AND_PID_CODES_MODULE_STATE_NAME]?: INameAndPidCodesModuleState;
  [NOTIFICATION_NAME]?: INotificationModuleState;
  [PERSONNEL_CODES_MODULE_STATE_NAME]?: IPersonnelCodesModuleState;
  [QUICK_SEARCH_STATE_NAME]?: IQuickSearchModuleState;
  [SCHOOL_PROFILE_STATE_NAME]?: ISchoolProfileReducers;
  [SEGMENT_MODULE_STATE_NAME]?: ISegmentModuleState;
  [VIEW_ALERTS_STATE_NAME]?: IViewAlertsModuleState;
  [VIEW_RESULTS_STATE_NAME]?: IViewResultsAlertsState;
  [VIEW_RESULTS_STATE_MODULE_NAME]?: IViewResultsModuleState;
  [VIEW_STATE_NAME]?: IViewModuleState;
  [TOOLTIPS_MODULE_FEATURE_NAME]?: ITooltipsModuleState;
  [SPINNER_MODULE_FEATURE_NAME]?: ISpinnerModuleState;
  [CDL_ORDER_SUMMARY_MODULE_STATE_NAME]?: ICDLOrderSummaryModuleState;
  [LIST_COMMON_STATE_NAME]?: IListCommonModuleState;
  [VIEW_COMMON_STATE_NAME]?: IViewModuleState;
  [CDL_LIST_CUSTOMIZE_MODULE_STATE_NAME]?: ICDLListCustomizeModuleState;
  [CDL_LIST_HISTORY_MODULE_STATE_NAME]?: ICDLListHistoryModuleState;
  [DOWNLOAD_ENTITY_STATE_NAME]?: IDownloadEntityModuleState;
  [BIDS_ENTITY_STATE_NAME]?: IBidsEntityModuleState;
  [GRANTS_ENTITY_STATE_NAME]?: IGrantsEntityModuleState;
  [PERSONNEL_ENTITY_STATE_NAME]?: IPersonnelEntityModuleState;
}

const CORE_STATE_NAME: string = 'CORE_STATE';

const coreReducer: ActionReducerMap<CoreState> = {
  router: routerReducer,
  routerHistory: routerHistoryReducer,
  tos: tosReducer,
  auth: authReducer,
  invitation: invitationReducer,
  tooltips: tooltipsReducer,
  customerListLargeDownload: customerListLargeDownloadReducer,
  customerListPurchase: listPurchaseReducer,
  listCreate: listCreateReducer,
  listCreateFromSelected: listCreateFromSelectedReducer,
  listCreateFromView: listCreateFromViewReducer,
  commonPages: commonPagesReducer,
  download: downloadReducer,
  eCommerceCart: cartReducer,
  showEmail: showEmailReducer,
  signUpByInvite: signUpByInviteReducer,
  commonErrors: commonErrorsReducer,
  cloudSyncAuth: cloudSyncAuthReducer,
  pidsFile: pidsFileUploadReducer,
  brokersTermOfUse: brokersTOUReducer,
  activeListAppendData: activeListAppendDataReducer,
  spinner: spinnerReducer
};

export const CORE_REDUCERS: InjectionToken<ActionReducerMap<CoreState>> = new InjectionToken<ActionReducerMap<CoreState>>(CORE_STATE_NAME, {
  factory: () => coreReducer
});
