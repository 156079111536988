import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getAccountStatesErrorAction, getAccountStatesSuccessAction } from '../store/actions/account.action';
import { getRoleKey } from '../store/selectors/profile.selector';

import { AccountService } from '../services/account.service';

import { IStateOption } from '@shared/interfaces/forms';
import { IServerError } from '@shared/interfaces/server-error';

import { RolesKeys } from '../constants/roles';

@Injectable({
  providedIn: 'root'
})
export class AccountStatesExistGuard implements CanActivate {

  constructor(private store: Store<CoreState>,
              private service: AccountService) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store
      .pipe(
        select(getRoleKey),
        take(1),
        switchMap((roleKey: string) => {
          return roleKey === RolesKeys.Admin || roleKey === RolesKeys.Owner
            ? this.service.getStates()
              .pipe(
                tap((states: IStateOption[]) => this.store.dispatch(getAccountStatesSuccessAction(states))),
                mapTo(true),
                catchError((error: IServerError) => {
                  this.store.dispatch(getAccountStatesErrorAction(error));
                  return of(false);
                }),
              )
            : of(true);
        }),
      );
  }
}
