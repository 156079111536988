import cardExpiredDate from './card-expired-date';
import cardType from './card-type';
import confirm from './confirm';
import country from './country';
import email from './email';
import max from './max';
import maxLength from './max-length';
import minLength from './min-length';
import password from './password';
import postalCode from './postal-code';
import required from './required';
import validateDependent from './validate-dependent';

export class Validators {
  static email: Function = email;
  static password: Function = password;
  static required: Function = required;
  static validateDependent: Function = validateDependent;
  static confirm: Function = confirm;
  static country: Function = country;
  static minLength: Function = minLength;
  static maxLength: Function = maxLength;
  static postalCode: Function = postalCode;
  static cardType: Function = cardType;
  static cardExpiredDate: Function = cardExpiredDate;
  static max: Function = max;
}
