import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IListData, IListRequest } from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';


enum ACTION_TYPES {
  GET_CUSTOM_LIST = '[CUSTOM LIST]: Get custom list',
  GET_CUSTOM_LIST_ERROR = '[CUSTOM LIST]: Get custom list error',
  GET_CUSTOM_LIST_SUCCESS = '[CUSTOM LIST]: Get custom list success',

  RELOAD_CUSTOM_LIST = '[CUSTOM LIST]: Reload custom list',
  RELOAD_CUSTOM_LIST_ERROR = '[CUSTOM LIST]: Reload custom list error',
  RELOAD_CUSTOM_LIST_SUCCESS = '[CUSTOM LIST]: Reload custom list success',

  DELETE_LIST = '[CUSTOM LIST]: Delete custom list',
  DELETE_LIST_ERROR = '[CUSTOM LIST]: Delete custom list error',
  DELETE_LIST_SUCCESS = '[CUSTOM LIST]: Delete custom list success',

  RENAME_LIST = '[CUSTOM LIST]: Custom list rename list',
  RENAME_LIST_ERROR = '[CUSTOM LIST]: Custom list rename list error',
  RENAME_LIST_SUCCESS = '[CUSTOM LIST]: Custom list rename list success',
  RENAME_LIST_CANCEL = '[CUSTOM LIST]: Custom list rename list cancel',

  APPLY = '[CUSTOM LIST]: Custom list apply changes',
  APPLY_ERROR = '[CUSTOM LIST]: Custom list apply changes error',
  APPLY_SUCCESS = '[CUSTOM LIST]: Custom list apply changes success',

  CHANGES_START = '[CUSTOM LIST]: Changes start',

  UPDATE_CUSTOM_LIST = '[CUSTOM LIST]: Update custom list state',

  SOCKET_CUSTOM_LIST_COUNTS_REFRESHED = '[CUSTOM LIST]: Socket counts refreshed',

  SHOW_APPLY_POP_UP = '[CUSTOM LIST]: Show apply pop up'
}

export const getCustomListAction: CreateActionType<ACTION_TYPES.GET_CUSTOM_LIST, IListRequest> = createAction(ACTION_TYPES.GET_CUSTOM_LIST, createPayload<IListRequest>());
export const getCustomListErrorAction: CreateActionType<ACTION_TYPES.GET_CUSTOM_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.GET_CUSTOM_LIST_ERROR, createPayload<IServerError>());
export const getCustomListSuccessAction: CreateActionType<ACTION_TYPES.GET_CUSTOM_LIST_SUCCESS, IListData> = createAction(ACTION_TYPES.GET_CUSTOM_LIST_SUCCESS, createPayload<IListData>());

export const reloadCustomListAction: ActionCreator<ACTION_TYPES.RELOAD_CUSTOM_LIST, () => TypedAction<ACTION_TYPES.RELOAD_CUSTOM_LIST>> = createAction(ACTION_TYPES.RELOAD_CUSTOM_LIST);
export const reloadCustomListErrorAction: CreateActionType<ACTION_TYPES.RELOAD_CUSTOM_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.RELOAD_CUSTOM_LIST_ERROR, createPayload<IServerError>());
export const reloadCustomListSuccessAction: CreateActionType<ACTION_TYPES.RELOAD_CUSTOM_LIST_SUCCESS, IListData> = createAction(ACTION_TYPES.RELOAD_CUSTOM_LIST_SUCCESS, createPayload<IListData>());

export const deleteCustomListAction: CreateActionType<ACTION_TYPES.DELETE_LIST, IListRequest> = createAction(ACTION_TYPES.DELETE_LIST, createPayload<IListRequest>());
export const deleteCustomListErrorAction: CreateActionType<ACTION_TYPES.DELETE_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.DELETE_LIST_ERROR, createPayload<IServerError>());
export const deleteCustomListSuccessAction: ActionCreator<ACTION_TYPES.DELETE_LIST_SUCCESS, () => TypedAction<ACTION_TYPES.DELETE_LIST_SUCCESS>> = createAction(ACTION_TYPES.DELETE_LIST_SUCCESS);

export const renameCustomListAction: CreateActionType<ACTION_TYPES.RENAME_LIST, string> = createAction(ACTION_TYPES.RENAME_LIST, createPayload<string>()); // list name
export const renameCustomListErrorAction: CreateActionType<ACTION_TYPES.RENAME_LIST_ERROR, IServerError> = createAction(ACTION_TYPES.RENAME_LIST_ERROR, createPayload<IServerError>());
export const renameCustomListSuccessAction: CreateActionType<ACTION_TYPES.RENAME_LIST_SUCCESS, IListData> = createAction(ACTION_TYPES.RENAME_LIST_SUCCESS, createPayload<IListData>());
export const renameCustomListCancelAction: ActionCreator<ACTION_TYPES.RENAME_LIST_CANCEL, () => TypedAction<ACTION_TYPES.RENAME_LIST_CANCEL>> = createAction(ACTION_TYPES.RENAME_LIST_CANCEL);

export const applyCustomListAction: CreateActionType<ACTION_TYPES.APPLY, IListRequest> = createAction(ACTION_TYPES.APPLY, createPayload<IListRequest>());
export const applyCustomListErrorAction: CreateActionType<ACTION_TYPES.APPLY_ERROR, IServerError> = createAction(ACTION_TYPES.APPLY_ERROR, createPayload<IServerError>());
export const applyCustomListSuccessAction: ActionCreator<ACTION_TYPES.APPLY_SUCCESS, () => TypedAction<ACTION_TYPES.APPLY_SUCCESS>> = createAction(ACTION_TYPES.APPLY_SUCCESS);

export const changesCustomListStartAction: ActionCreator<ACTION_TYPES.CHANGES_START, () => TypedAction<ACTION_TYPES.CHANGES_START>> = createAction(ACTION_TYPES.CHANGES_START);

export const updateCustomListStateAction: CreateActionType<ACTION_TYPES.UPDATE_CUSTOM_LIST, Partial<IListData> | IListData> = createAction(ACTION_TYPES.UPDATE_CUSTOM_LIST, createPayload<Partial<IListData> | IListData>());

export const socketCustomListCountsRefreshedAction: ActionCreator<ACTION_TYPES.SOCKET_CUSTOM_LIST_COUNTS_REFRESHED, () => TypedAction<ACTION_TYPES.SOCKET_CUSTOM_LIST_COUNTS_REFRESHED>> = createAction(ACTION_TYPES.SOCKET_CUSTOM_LIST_COUNTS_REFRESHED);

export const showApplyCustomListPopUp: ActionCreator<ACTION_TYPES.SHOW_APPLY_POP_UP, () => TypedAction<ACTION_TYPES.SHOW_APPLY_POP_UP>> = createAction(ACTION_TYPES.SHOW_APPLY_POP_UP);
