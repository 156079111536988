<!-- header -->
<div class="row justify-content-between"
     [class.header--toggle]="isToggle"
     (click)="isToggle ? isOpened = !isOpened : null">

  <h4 class="header-name">{{ data?.name | seatsName: data?.id }}</h4>

  <i *ngIf="isToggle"
     [ngClass]="{ 'icon-carrot-up': isOpened, 'icon-carrot-right': !isOpened }"
     class="icon-arrow">
  </i>
</div><!-- / header -->

<div *ngIf="isSeatLite"
     class="row description">
  <span>Unlimited</span>
</div>

<!-- seat content -->
<div *ngIf="!isSeatLite && !isAddOnComparedStates">

  <div class="row description">
    <span>{{ showCalcProducts }}</span>
  </div>

  <!-- seat states opened -->
  <div *ngIf="isOpened"
       class="scroll-bar">

    <div *ngIf="isSeatState"
         class="row justify-content-between state">
      <span class="content__name">Unassigned:</span>
      <span class="content__value">{{ data?.unassigned }}</span>
    </div>

    <div *ngFor="let state of data?.states"
         class="row justify-content-between state">
      <span class="content__name">{{ state?.name }}:</span>
      <span class="content__value">{{ state?.count || 0 }}</span>
    </div>

  </div><!-- / seat states opened -->

</div><!-- / seat content -->

<!-- seats compared addOns -->
<div *ngIf="!isSeatLite && isAddOnComparedStates">

  <div class="row description">
    <span>{{ showCalcProducts }}</span>
  </div>

  <!-- seat states opened -->
  <div *ngIf="isOpened"
       class="scroll-bar">

    <div *ngFor="let state of data?.statesNational"
         class="row justify-content-between state">
      <span class="content__name">{{ state?.name }}:</span>
      <span class="content__value">{{ state?.count || 0 }}</span>
    </div>

    <div class="row justify-content-between state">
      <span class="content__name">National:</span>
      <span class="content__value">{{ data?.openNational }}</span>
    </div>

    <div class="row justify-content-between state">
      <span class="content__name">Unassigned State:</span>
      <span class="content__value">{{ data?.unassigned }}</span>
    </div>

    <div *ngFor="let state of data?.states"
         class="row justify-content-between state">
      <span class="content__name">{{ state?.name }}:</span>
      <span class="content__value">{{ state?.count || 0 }}</span>
    </div>

  </div><!-- / seat states opened -->

</div><!-- / seat content -->
