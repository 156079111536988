<bl-pop-up-base>
  <div class="wrapper" data-testid="generate-pop-up">

    <div class="title">
      <i class="icon icon-clock-large"></i>
    </div>

    <div class="body">
      {{ data.body }}
    </div>

  </div>
</bl-pop-up-base>
