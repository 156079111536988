export interface IApiPathsConfig {
  apiUrl: string;
  serverUrl: string;
  cyberSourceUrl: string;
}

const apiPathsConfig: IApiPathsConfig = {
  apiUrl: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/dev',
  serverUrl: 'https://build-a-list-test.com',
  cyberSourceUrl: 'https://testsecureacceptance.cybersource.com'
};

export const apiPaths: (data?: IApiPathsConfig) => any = (data: IApiPathsConfig = apiPathsConfig): any => ({
  general: {
    states: `${ data.apiUrl }/api/v1/states`,
  },
  validation: {
    address: `${ data.apiUrl }/api/v1/validate-address`
  },
  auth: {
    firstLogin: `${ data.apiUrl }/api/v1/auth/first-login`,
    login: `${ data.apiUrl }/api/v1/auth/login`,
    refreshToken: `${ data.apiUrl }/api/v1/auth/refresh-token`,
    signUp: `${ data.apiUrl }/api/v1/auth/sign-up`,
    changePassword: `${ data.apiUrl }/api/v1/auth/password/change`,
    changePasswordAfterReset: `${ data.apiUrl }/api/v1/auth/password/verify-reset`,
    resetPassword: `${ data.apiUrl }/api/v1/auth/password/reset`,
  },
  invitation: {
    user: `${ data.apiUrl }/api/v1/invitation`,
  },
  marketView: {
    showEmail: `${ data.apiUrl }/api/v1/show-email`,
    account: {
      users: `${ data.apiUrl }/api/v1/account/users`,
      addUser: `${ data.apiUrl }/api/v1/account/users/add`,
      editUser: `${ data.apiUrl }/api/v1/account/users`,
      seats: `${ data.apiUrl }/api/v1/account/seats`,
      roles: `${ data.apiUrl }/api/v1/account/roles`,
      assignOwner: `${ data.apiUrl }/api/v1/account/assign-owner`,
      resendInvitation: 'resend-invitation',
      assignData: 'assign-data'
    }
  },
  dashboard: {
    lists: `${ data.apiUrl }/api/v1/dashboard`,
    creditsCounter: `${ data.apiUrl }/api/v1/credits`,
    filter: `${ data.apiUrl }/api/v1/filter`,
    largeDownload: `${ data.serverUrl }/download`,
    isAvailable: `${ data.apiUrl }/api/v1/download/is-file-available`,
  },
  list: {
    lists: `${ data.apiUrl }/api/v1/lists`,
    isAvailable: `${ data.apiUrl }/api/v1/lists/download/is-file-available`,
    summary: 'summary',
    duplicate: 'duplicate',
    purchase: 'confirm',
    refresh: 'refresh',
    repurchase: 'repurchase',
    generate: 'generate',
    assign: 'assign',
    appends: 'fields',
    appendsAdditional: 'appends',
    calculate: 'calculate',
    calculateAppend: 'calculate-append',
    activeAppend: 'active-append',
    updatePids: 'update-pids',
    export: 'exports',
    download: `${ data.serverUrl }/lists`,
    downloadPostFix: 'download',
    pids: 'pids'
  },
  customList: {
    main: `${ data.apiUrl }/api/v1/custom-lists`,
    duplicate: 'duplicate',
    apply: 'apply',
    createFromList: 'create-from-list'
  },
  cdlList: {
    main: `${ data.apiUrl }/api/v1/cdl-lists`,
    duplicate: 'duplicate',
    delete: 'delete',
    calculate: 'calculate',
    changeStatus: 'change-status',
    refreshSchedule: 'refresh-schedule',
    cloudSync: 'cloud-sync',
    summary: 'summary',
    recipients: 'recipients',
    syncRecipients: 'sync-recipients',
    countsSummary: 'counts-summary',
    download: 'download',
    refresh: 'refresh',
    appendFields: 'fields',
    outputSettings: 'output-settings',
    outputColumns: 'output-columns',
    move: 'move',
    toggleBulk: 'toggle-bulk',
    history: 'history',
    generate: 'generate'
  },
  view: {
    views: `${ data.apiUrl }/api/v1/views`,
    duplicate: 'duplicate',
    overview: 'overview',
    personnel: 'personnel',
    institutions: 'institutions',
    bids: 'bids',
    grants: 'grants',
    alerts: 'alerts',
    tabs: 'tabs',
    list: 'list'
  },
  viewAlerts: {
    base: `${ data.apiUrl }/api/v1/views`,
    alerts: 'alerts-list',
    tabs: 'alerts/tabs',
  },
  quickSearch: {
    main: `${ data.apiUrl }/api/v1/quick-search`,
    autocomplete: `${ data.apiUrl }/api/v1/quick-search/autocomplete`,
    overview: `${ data.apiUrl }/api/v1/quick-search/overview`,
    personnel: `${ data.apiUrl }/api/v1/quick-search/personnel`,
    institutions: `${ data.apiUrl }/api/v1/quick-search/institutions`,
    bids: `${ data.apiUrl }/api/v1/quick-search/bids`,
    grants: `${ data.apiUrl }/api/v1/quick-search/grants`,
    keywords: 'keywords',
    pids: 'pids',
    geo: 'geo',
  },
  segment: {
    main: `${ data.apiUrl }/api/v1/segments`,
    duplicate: 'duplicate',
    segmentBySuffix: 'segment-by',
    maxNames: 'max-names',
    dedupe: 'dedupe'
  },
  targeting: {
    main: `${ data.apiUrl }/api/v1/targeting`,
    institution: {
      update: `${ data.apiUrl }/api/v1/targeting/institutions`,
      details: {
        school: `${ data.apiUrl }/api/v1/targeting/institutions-detailed/school`,
        district: `${ data.apiUrl }/api/v1/targeting/institutions-detailed/district`,
        other: `${ data.apiUrl }/api/v1/targeting/institutions-detailed/institution`
      }
    },
    geography: {
      place: `${ data.apiUrl }/api/v1/targeting/geo`,
      range: `${ data.apiUrl }/api/v1/targeting/geo/range`,
      states: `${ data.apiUrl }/api/v1/targeting/geo/states`,
      counties: `${ data.apiUrl }/api/v1/targeting/geo/states`,
      citiesTitles: `${ data.apiUrl }/api/v1/targeting/geo/states`,
      cities: `${ data.apiUrl }/api/v1/targeting/geo/states`,
      citiesForZipCode: `${ data.apiUrl }/api/v1/targeting/geo/states`,
      zipCode: `${ data.apiUrl }/api/v1/targeting/geo/cities`,
      scfCode: `${ data.apiUrl }/api/v1/targeting/geo/states`,
      details: `${ data.apiUrl }/api/v1/targeting/geo-detailed`,
      suggestions: `${ data.apiUrl }/api/v1/targeting/geo/suggestions`,
      selectAll: `${ data.apiUrl }/api/v1/targeting/geo/select-all`,
      all: `${ data.apiUrl }/api/v1/targeting/geo/all`,
      multiCodes: `${ data.apiUrl }/api/v1/targeting/geo/multi-upload`,
      dma: 'dma-codes',
      codes: 'codes'
    },
    demographic: {
      main: `${ data.apiUrl }/api/v1/targeting/demographics`
    },
    jobs: {
      main: `${ data.apiUrl }/api/v1/targeting/job-functions`,
      suggestions: `${ data.apiUrl }/api/v1/targeting/job-functions/suggestions`,
      categories: `${ data.apiUrl }/api/v1/targeting/job-functions/categories`,
      categoriesByInstType: `${ data.apiUrl }/api/v1/targeting/job-functions/categories-by-institution-type`,
      alphabet: `${ data.apiUrl }/api/v1/targeting/job-functions/jobs`,
      jobsByCategory: `${ data.apiUrl }/api/v1/targeting/job-functions/categories`,
      jobsByAlphabet: `${ data.apiUrl }/api/v1/targeting/job-functions/jobs-by-title`,
      jobsByDiscipline: `${ data.apiUrl }/api/v1/targeting/job-functions/disciplines`,
      jobsBySubject: `${ data.apiUrl }/api/v1/targeting/job-functions/subjects`,
      details: `${ data.apiUrl }/api/v1/targeting/job-functions-detailed`,
      codes: 'many',
      paginate: 'paginate'
    },
    pids: {
      main: 'pids',
      custom: 'custom-pids',
      customPidNid: 'custom-pid-nid',
      customList: 'custom-list',
      suggestion: 'suggestions',
      only: 'only-pids',
      multi: 'multi',
      upload: 'file',
      paginate: 'paginate'
    },
    grants: {
      main: `${ data.apiUrl }/api/v1/targeting/grants`,
      keywords: `${ data.apiUrl }/api/v1/targeting/grants/keywords`,
    },
    bids: {
      main: `${ data.apiUrl }/api/v1/targeting/bids`,
      keywords: 'keywords',
      groups: 'groups',
      categories: 'categories',
    }
  },
  user: {
    data: `${ data.apiUrl }/api/v1/user/data`,
    acceptTOS: `${ data.apiUrl }/api/v1/user/accept-tos`,
    tosStatus: `${ data.apiUrl }/api/v1/user/tos-status`,
    orderConfirmation: `${ data.apiUrl }/api/v1/order-confirmation`,
    settings: `${ data.apiUrl }/api/v1/user/settings`,
    predictions: `${ data.apiUrl }/api/v1/users/email-autocomplete`
  },
  broker: {
    main: `${ data.apiUrl }/api/v1/user/broker`,
    accept: 'accept-license'
  },
  price: {
    data: `${ data.apiUrl }/api/v1/products`,
  },
  termsOfUse: {
    data: `${ data.apiUrl }/api/v1/pages/terms-of-use`,
    brokers: `${ data.apiUrl }/api/v1/pages/broker/terms-of-use`
  },
  privacyPolicy: {
    data: `${ data.apiUrl }/api/v1/pages/privacy-policy`
  },
  generalTerms: {
    data: `${ data.apiUrl }/api/v1/pages/general-terms`
  },
  eCommerce: {
    cartValidate: `${ data.apiUrl }/api/v1/ecommerce/cart/validate`,
    userBillingInfo: `${ data.apiUrl }/api/v1/ecommerce/user/billing-info`,
    tax: `${ data.apiUrl }/api/v1/ecommerce/cart/tax`,
    promoDetails: `${ data.apiUrl }/api/v1/ecommerce/cart/product/promotion`,
    salesOrder: `${ data.apiUrl }/api/v1/ecommerce/cart/orders/sales`,
    sign: `${ data.apiUrl }/api/v1/ecommerce/sign-request`,
  },
  cyberSource: {
    transaction: `${ data.cyberSourceUrl }/silent/pay`,
    addCart: `${ data.cyberSourceUrl }/silent/token/create`
  },
  institutionProfiles: {
    profile: `${ data.apiUrl }/api/v1/profile`,
    typeSchool: 'school',
    typeDistrict: 'district',
    typeCmo: 'cmo',
    typeStateDepartment: 'state-department',
    typeGrant: 'grant',
    typeBid: 'bid',
    personnel: 'personnel',
    bids: 'bids',
    grants: 'grants',
    schools: 'schools',
    college: 'college',
    campuses: 'campuses',
    collegeOffice: 'college-office',
    alertsHistory: 'events',
    recipients: 'recipients',
    download: 'download'
  },
  cloudSync: {
    main: `${ data.apiUrl }/api/v1/cloudsync`,
    download: `${ data.serverUrl }/cloudsync/download-fields-file`,
    oms: 'oms',
    platforms: 'platforms',
    auth: 'auth',
    check: 'check',
    connections: 'connections',
    customObjects: 'custom-objects',
    solution: 'solution',
    lists: 'lists',
    history: 'history',
    prepopulate: 'prepopulate',
    refreshCustomObjectMetaData: 'reset-metadata'
  },
  countries: {
    main: `${ data.apiUrl }/api/v1/countries`,
    states: 'states'
  }
});
