<div class="header">
  <div class="header-text">{{ data.header }}</div>

  <button (click)="onClose()"
          type="button"
          class="btn-close">
    <i class="icon-x-medium"></i>
  </button>
</div>

<div class="content">
  <h5 class="title">{{ data.title }}</h5>

  <div class="body">
    {{ data.body }}
  </div>

  <div class="row justify-content-center">
    <a class="btn btn--primary"
       href="tel:+{{ phoneNumber }}"
       (click)="onConfirm()">
      {{ data.buttonText }}
    </a>
  </div>
</div>
