import { forwardRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'bl-do-not-show-pop-up-again',
  templateUrl: './do-not-show-pop-up-again.component.html',
  styleUrls: ['./do-not-show-pop-up-again.component.scss'],
  exportAs: 'checkbox',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DoNotShowPopUpAgainComponent),
    multi: true
  }]
})
export class DoNotShowPopUpAgainComponent implements ControlValueAccessor {
  @Input() text: string = `Do not show this message again`;
  @Input() withoutMargin: boolean;

  doNotShowPopUp: boolean = false;

  propagateTouch: Function;
  propagateChange: Function;

  get value(): boolean {
    return this.doNotShowPopUp;
  }

  onChange(event: Event): void {
    const value: boolean = (event.target as HTMLInputElement).checked;

    if (this.propagateTouch) {
      this.propagateTouch();
      this.propagateChange(value);
    }

    this.doNotShowPopUp = value;
  }

  registerOnTouched(fn: Function): void {
    this.propagateTouch = fn;
  }

  registerOnChange(fn: Function): void {
    this.propagateChange = fn;
  }

  writeValue(value?: boolean): void {
    if (value !== void (0)) {
      this.doNotShowPopUp = value;
    }
  }
}
