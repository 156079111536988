import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { CustomEmitter } from '@core/services/custom-emitter';

import { stringToNumberArray } from '@shared/utils/string-to-number-array';

import { IObjectKeysStringAny } from '@shared/interfaces';
import { KEY_CODES } from '@shared/interfaces/keyboard';

import { INPUT_AUTOCOMPLETE } from '@shared/constants/flat-input';
import { MOBILE_KEYWORD_EVENTS } from '@shared/constants/scroll';

interface ISuggestionView {
  text: string;
  description?: string;
  title?: string;
  value?: string;
}

@Component({
  selector: 'bl-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements OnInit, OnDestroy {

  isEdit: boolean = false;
  shouldShowMenu: boolean = false;
  inputValue: string = '';
  selectedSuggestionIndex: number = null;

  @ViewChild('container') containerEl: ElementRef;
  @ViewChild('ul') ulRef: ElementRef;

  @Input() withSearchIcon: boolean;
  @Input() isSearchIconDisabled: boolean = false;
  @Input() name: string;
  @Input() theme: string;
  @Input() isScroll: boolean = false;
  @Input() placeholder: string;

  @Input() shouldSaveValue: IObjectKeysStringAny = null;
  @Input() isAutoFocus: boolean;
  @Input() minLength: number = 2;
  @Input() customError: string;
  @Input() readonly: boolean = false;
  @Input() resetBtn: boolean = false;
  @Input() preventOnEnterWithSingleValue: boolean = false;

  @Input() alwaysEmitEnter: boolean = false;

  @Input() suggestionTemplate: TemplateRef<any>;
  @Input() isSuggestionWithBorders: boolean = true;

  @Input() e2eLabel: string;

  @Input() suggestions: ISuggestionView[] | any[] = [];
  @Input() suggestionsView: ISuggestionView[] | any[] = [];

  @Output() onInput: EventEmitter<string> = new EventEmitter();
  @Output() onEnter: EventEmitter<string> = new EventEmitter();
  @Output() onSelectItem: EventEmitter<any> = new EventEmitter();
  @Output() resetSuggestions: EventEmitter<any> = new EventEmitter();
  @Output() inputFocusInOut: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() onResetValue: EventEmitter<any> = new EventEmitter();

  readonly autoCompleteParams: typeof INPUT_AUTOCOMPLETE = INPUT_AUTOCOMPLETE;

  private _destroyer$: Subject<void> = new Subject<void>();
  private _modelChanged$: Subject<string> = new Subject<string>();

  constructor(private _cdr: ChangeDetectorRef,
              private _customEmitter: CustomEmitter) {
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.containerEl.nativeElement.contains(event.target)) {
      this.shouldShowMenu = false;
      this._cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this._modelChanged$.pipe(takeUntil(this._destroyer$), debounceTime(400), distinctUntilChanged()).subscribe((model: string) => {
      this.inputValue = model;
      this.onInputChange();
    });
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  modelChange(value: string): void {
    this._modelChanged$.next(value);
  }

  onInputChange(): void {
    this.openMenu();
    this.onInput.emit(this.inputValue.trim());
  }

  onByEnter(): void | undefined {

    const singleValueProvided: boolean = Array.from(new Set(stringToNumberArray(this.inputValue))).length === 1;

    if (this.preventOnEnterWithSingleValue && singleValueProvided) {
      return;
    }

    if (this.isEdit) {

      if (typeof this.selectedSuggestionIndex === 'number') {
        this.onSelectItem.emit(this.suggestions[this.selectedSuggestionIndex]);
        this._saveValue();
        this._resetSuggestions();
        this._cdr.detectChanges();
      }

    } else {

      if (this.suggestions.length && !this.alwaysEmitEnter) {
        return;
      }

      if (this._checkLength()) {
        this.onEnter.emit(this.inputValue.trim());
        this._saveValue();
        this._cdr.detectChanges();
      }

    }
  }

  onClickSuggestion(): void {
    this.onSelectItem.emit(this.suggestions[this.selectedSuggestionIndex]);

    this._saveValue();

    this.selectedSuggestionIndex = null;
    this._resetSuggestions();
    this._cdr.detectChanges();
  }

  setSuggestionIndex($event: Event, index: number): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.selectedSuggestionIndex = index;
    this._cdr.detectChanges();
  }


  onKeyboardEvents($event: KeyboardEvent): void | undefined {
    if ($event.code !== KEY_CODES.ArrowUp && $event.code !== KEY_CODES.ArrowDown) {
      return;
    }

    $event.stopPropagation();
    $event.preventDefault();

    if (!this.suggestions.length) {
      return;
    }

    this.isEdit = true;

    if (this.selectedSuggestionIndex === null) {
      this.selectedSuggestionIndex = 0;
      this._cdr.detectChanges();
      return;
    }

    const liElem: any = this.ulRef.nativeElement.querySelector('.selected');

    switch ($event.code) {
      case KEY_CODES.ArrowDown: {
        this.selectedSuggestionIndex = this.selectedSuggestionIndex < this.suggestions.length - 1
          ? ++this.selectedSuggestionIndex : this.selectedSuggestionIndex;

        if (this.isScroll && this.suggestions.length
          && liElem && liElem.offsetTop >= this.ulRef.nativeElement.offsetHeight - liElem.offsetHeight) {
          this.ulRef.nativeElement.scrollTop = this.ulRef.nativeElement.scrollTop + liElem.offsetHeight;
        }

        this._cdr.detectChanges();
        break;
      }

      case KEY_CODES.ArrowUp: {
        this.selectedSuggestionIndex = this.selectedSuggestionIndex > 0 ? --this.selectedSuggestionIndex : this.selectedSuggestionIndex;

        if (this.isScroll && this.suggestions.length && this.ulRef.nativeElement.scrollTop > 0) {
          this.ulRef.nativeElement.scrollTop = this.ulRef.nativeElement.scrollTop - liElem.offsetHeight;
        }
        this._cdr.detectChanges();
        break;
      }
    }
  }

  containerClasses(): { [key: string]: boolean } {
    return {
      wrapper: true,
      [this.theme]: true
    };
  }

  resetVal(): void {
    this.inputValue = '';
    this.onResetValue.emit();
    this._cdr.detectChanges();
  }

  openMenu(): void {
    this.shouldShowMenu = true;
    this._cdr.detectChanges();
  }

  onInputFocus(e: Event): void {
    this.openMenu();
    this.inputFocusInOut.emit(true);
    this._customEmitter.emit<boolean>(MOBILE_KEYWORD_EVENTS.MOBILE_KEYWORD_TRIGGER, true);
  }

  onInputFocusOut(e: Event): void {
    this.inputFocusInOut.emit(false);
    this._customEmitter.emit<boolean>(MOBILE_KEYWORD_EVENTS.MOBILE_KEYWORD_TRIGGER, false);
  }

  private _saveValue(): void {
    if (this.shouldSaveValue && this.suggestionsView[this.selectedSuggestionIndex].value) {
      this.inputValue = this.suggestionsView[this.selectedSuggestionIndex].value;
    } else {
      this.inputValue = '';
    }
  }

  private _checkLength(): boolean {
    return this.inputValue && this.inputValue.trim().length >= this.minLength;
  }

  private _resetSuggestions(): void {
    this.shouldShowMenu = false;
    this.resetSuggestions.emit();
    this.isEdit = false;
    this.selectedSuggestionIndex = null;
    this._cdr.detectChanges();
  }
}
