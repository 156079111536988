import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import { getIsLoggedIn } from '../../../auth/store/selectors/auth.selector';
import * as profileState from '../reducers/profile.reducer';

import { IRole, IUser, IUserTransformed } from '@modules/auth/interfaces/user';
import { IMVEntityAvailable } from '@modules/profile/interfaces/mv-entity-available';
import { IAccountPermissionsForList, ILoggedInAndUser, IProfileTabTitle, IUserPermissions } from '@modules/profile/interfaces/profile';
import { IProfileSetting } from '@modules/profile/interfaces/profile-settings';
import { ICDLLists } from '@shared/interfaces/manage-account-lists';
import { IServerError } from '@shared/interfaces/server-error';

import { selectProfileState } from './';


export const getProfileUser: MemoizedSelector<CoreState, IUser> = createSelector(selectProfileState, profileState.user);

export const getUserName: MemoizedSelector<CoreState, string> = createSelector(getProfileUser, profileState.userName);
export const getProfileUserId: MemoizedSelector<CoreState, number> = createSelector(getProfileUser, profileState.userId);

export const getUserLoaded: MemoizedSelector<CoreState, boolean> = createSelector(getProfileUser, profileState.userIsLoaded);

export const getIsUnlimited: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState, profileState.isUnlimited);

export const getIsAdmin: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState, profileState.isAdmin);
export const getIsAdminsAccount: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState, profileState.isAdminsAccount);
export const getIsBroker: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState, profileState.isBroker);
export const getIsOwnerOfAdminsAccount: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState,
  profileState.isOwnerOfAdminsAccount);

export const getIsFirstAssignAvailable: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState,
  profileState.isFirstAssignAvailable);

export const isTokenExpired: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState, profileState.isTokenExpired);

export const getProfileUpdating: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState, profileState.profileUpdating);
export const getProfileUpdateSuccessMessage: MemoizedSelector<CoreState, string> = createSelector(selectProfileState,
  profileState.profileUpdateSuccessMessage);
export const getUpdatingProfileError: MemoizedSelector<CoreState, IServerError> = createSelector(selectProfileState,
  profileState.updateProfileError);

export const getChangePasswordLoading: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState,
  profileState.changePasswordLoading);
export const getChangePasswordError: MemoizedSelector<CoreState, IServerError> = createSelector(selectProfileState,
  profileState.changePasswordError);
export const getChangePasswordSuccessMessage: MemoizedSelector<CoreState, string> = createSelector(selectProfileState,
  profileState.changePasswordSuccessMessage);

export const getProfileSettings: MemoizedSelector<CoreState, IProfileSetting[]> = createSelector(selectProfileState,
  profileState.profileSettings);
export const getUpdatingProfileSetting: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState,
  profileState.updatingProfileSetting);
export const getRole: MemoizedSelector<CoreState, IRole> = createSelector(selectProfileState, profileState.role);

export const getRoleKey: MemoizedSelector<CoreState, string> = createSelector(getRole, profileState.roleKey);

export const getRoleConfig: MemoizedSelector<CoreState, IProfileTabTitle> = createSelector(getRoleKey, profileState.roleKeyConfig);

export const getIsLoggedInAndUser: MemoizedSelector<CoreState, ILoggedInAndUser> = createSelector(getIsLoggedIn, getProfileUser,
  profileState.isLoggedInAndUser);
export const getUserHasCountryCode: MemoizedSelector<CoreState, string> = createSelector(getProfileUser, profileState.userCountryCode);

export const getTransformedUser: MemoizedSelector<CoreState, IUserTransformed> = createSelector(getProfileUser,
  profileState.transformedUserData);

export const getUserIsHasAddress: MemoizedSelector<CoreState, boolean> = createSelector(getProfileUser, profileState.userIsHasAddress);

export const getCanCheckTOS: MemoizedSelector<CoreState, boolean> = createSelector(
  getIsLoggedIn,
  getUserLoaded,
  getIsAdmin,
  getIsAdminsAccount,
  profileState.canCheckoutTos
);

export const getHasAvailableCloudSync: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState,
  profileState.hasAvailableCloudSync);
export const getShowOms: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState, profileState.showOms);

export const getPermissions: MemoizedSelector<CoreState, string[]> = createSelector(selectProfileState, profileState.permissions);
export const getAccountAvailablePermissions: MemoizedSelector<CoreState, string[]> = createSelector(selectProfileState,
  profileState.accountAvailablePermissions);
export const getUserCompanyName: MemoizedSelector<CoreState, string> = createSelector(getProfileUser, profileState.companyName);

export const getCanManageViews: MemoizedSelector<CoreState, boolean> = createSelector(getPermissions, profileState.canManageViews);
export const getCanManageQuickSearch: MemoizedSelector<CoreState, boolean> = createSelector(getPermissions,
  profileState.canManageQuickSearch);
export const getCanManageGrants: MemoizedSelector<CoreState, boolean> = createSelector(getPermissions, profileState.canManageGrants);
export const getCanManageBids: MemoizedSelector<CoreState, boolean> = createSelector(getPermissions, profileState.canManageBids);

export const getUserPermissions: MemoizedSelector<CoreState, IUserPermissions> = createSelector(getRole, getPermissions,
  getAccountAvailablePermissions, profileState.userPermissions);

export const getMarketViewAvailable: MemoizedSelector<CoreState, IMVEntityAvailable> = createSelector(getUserPermissions,
  profileState.marketViewAvailable);
export const getGrantsAvailable: MemoizedSelector<CoreState, IMVEntityAvailable> = createSelector(getUserPermissions,
  profileState.grantAvailable);
export const getBidsAvailable: MemoizedSelector<CoreState, IMVEntityAvailable> = createSelector(getUserPermissions,
  profileState.bidAvailable);

export const getCDLListPermissions: MemoizedSelector<CoreState, ICDLLists> = createSelector(selectProfileState,
  profileState.cdlListAvailable);
export const getAccountPermissionsForList: MemoizedSelector<CoreState, IAccountPermissionsForList> = createSelector(
  getIsAdminsAccount,
  getIsAdmin,
  getCDLListPermissions,
  profileState.accountPermissionsForList
);

export const getIsIdentifyUserByLogRocket: MemoizedSelector<CoreState, boolean> = createSelector(selectProfileState,
  profileState.isIdentifyUserByLogRocket);
