import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { E2ePipe } from './pipes/e2e.pipe';
import { InstitutionTypesPipe } from './pipes/institution-types.pipe';
import { IsFindWordPipe } from './pipes/isFindWord.pipe';
import { MetersToMilesPipe } from './pipes/meters-to-miles.pipe';
import { OneOrManyPipe } from './pipes/one-or-many.pipe';
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { PlaceTypePipe } from './pipes/place-type.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SignPipe } from './pipes/sign.pipe';
import { ZipCodeMaskPipe } from './pipes/zip-code-mask.pipe';

const _pipes: any = [
  E2ePipe,
  InstitutionTypesPipe,
  IsFindWordPipe,
  MetersToMilesPipe,
  OneOrManyPipe,
  PhonePipe,
  PhoneMaskPipe,
  PlaceTypePipe,
  SignPipe,
  ZipCodeMaskPipe,
  SafeHtmlPipe
];

@NgModule({
  declarations: [
    ..._pipes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ..._pipes
  ]
})
export class SharedPipesModule {
}
