import { SafeHtml } from '@angular/platform-browser';
import { createSelector, MemoizedSelector } from '@ngrx/store';

import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { CoreState } from '../reducers';
import {
  generalTermsData,
  generalTermsDataLoaded,
  isBrokerLicenseAccepted,
  isTosPage,
  previousUrl,
  termsOfUseData,
  termsOfUseDataLoaded,
  tosStatus,
  TOSState
} from '../reducers/tos.reducer';

import { IHtmlPageData, ITermsOfUsePageData } from '@core/interfaces/terms-of-servise';
import { ITOSPreviousUrl } from '@core/interfaces/tos';

export const getTOSState: GetFromStateInSelectors<CoreState, TOSState> = (state: CoreState) => state.tos;
export const getIsTosPage: MemoizedSelector<CoreState, boolean> = createSelector(getTOSState, isTosPage);

export const getTOSStatus: MemoizedSelector<CoreState, boolean> = createSelector(getTOSState, tosStatus);
export const getIsBrokerLicenseAccepted: MemoizedSelector<CoreState, boolean> = createSelector(getTOSState, isBrokerLicenseAccepted);

export const getGeneralTermsData: MemoizedSelector<CoreState, IHtmlPageData<SafeHtml>> = createSelector(getTOSState, generalTermsData);
export const getGeneralTermsDataLoaded: MemoizedSelector<CoreState, boolean> = createSelector(getTOSState, generalTermsDataLoaded);

export const getTermsOfUseData: MemoizedSelector<CoreState, ITermsOfUsePageData<SafeHtml>> = createSelector(getTOSState, termsOfUseData);
export const getTermsOfUseDataLoaded: MemoizedSelector<CoreState, boolean> = createSelector(getTOSState, termsOfUseDataLoaded);

export const getPreviousUrl: MemoizedSelector<CoreState, ITOSPreviousUrl> = createSelector(getTOSState, previousUrl);
