import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GeneralFooterComponent } from './components/general-footer/general-footer.component';
import { StickyElementComponent } from './components/sticky-element/sticky-element.component';


const imports: any[] = [
  CommonModule
];
const declarations: any[] = [
  GeneralFooterComponent,
  StickyElementComponent
];

const exports: any[] = [
  ...declarations
];

@NgModule({
  declarations,
  imports,
  exports
})
export class StickyModule {
}
