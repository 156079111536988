import { createSelector, MemoizedSelector } from '@ngrx/store';

import { getCredits } from '@modules/credits/store/selectors/credits.selector';
import {
  getHasAvailableCloudSync,
  getIsUnlimited,
  getRole
} from '@modules/profile/store/selectors/profile.selector';
import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { CoreState } from '../reducers';
import * as cloudSyncAuth from '../reducers/cloud-sync-auth.reducer';
import { ICloudSyncAuthState } from '../reducers/cloud-sync-auth.reducer';

import { ICloudSyncAuthFields, ICloudSyncPlatform, ICloudSyncPlatforms } from '@core/interfaces/cloud-sync';
import { IServerError } from '@shared/interfaces/server-error';

import { ASSIGN_PLATFORM_TYPE } from '@core/constants/cloud-sync';

export const getCloudSyncAuthState: GetFromStateInSelectors<CoreState, ICloudSyncAuthState> = (state: CoreState) => state.cloudSyncAuth;

export const getPlatforms: MemoizedSelector<CoreState, ICloudSyncPlatforms> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.platforms);
export const getPlatformsAvailable: MemoizedSelector<CoreState, ICloudSyncPlatforms> = createSelector(getPlatforms,
  cloudSyncAuth.platformsAvailable);
export const getPlatformsAuthorized: MemoizedSelector<CoreState, ICloudSyncPlatforms> = createSelector(getPlatforms,
  cloudSyncAuth.platformsAuthorized);

export const getByTypeProductPlatforms: MemoizedSelector<CoreState, ICloudSyncPlatforms> = createSelector(getPlatformsAuthorized,
  getPlatformsAvailable, cloudSyncAuth.availablePlatforms);

export const getPlatformAssignType: MemoizedSelector<CoreState, ASSIGN_PLATFORM_TYPE> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.platformAssignType);

export const getSelectedPlatformId: MemoizedSelector<CoreState, number> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.selectedPlatformId);
export const getSelectedPlatform: MemoizedSelector<CoreState, ICloudSyncPlatform> = createSelector(getPlatforms, getSelectedPlatformId,
  cloudSyncAuth.selectedPlatform);
export const getPlatformFields: MemoizedSelector<CoreState, ICloudSyncAuthFields> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.platformFields);
export const getSandbox: MemoizedSelector<CoreState, boolean> = createSelector(getCloudSyncAuthState, cloudSyncAuth.sandbox);

export const getCurrentPlatformId: MemoizedSelector<CoreState, number> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.currentPlatformId);
export const getCurrentPlatform: MemoizedSelector<CoreState, ICloudSyncPlatform> = createSelector(getPlatforms, getCurrentPlatformId,
  cloudSyncAuth.currentPlatform);

export const getSelectedConnectionId: MemoizedSelector<CoreState, number> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.selectedConnectionId);
export const getConnectionId: MemoizedSelector<CoreState, number> = createSelector(getSelectedPlatform, getSelectedConnectionId,
  cloudSyncAuth.connectionId);

export const getPlatformAuthLoading: MemoizedSelector<CoreState, boolean> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.platformAuthLoading);
export const getPlatformAuthError: MemoizedSelector<CoreState, IServerError> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.platformAuthError);

export const getCurrentListRecords: MemoizedSelector<CoreState, number> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.currentListRecords);
export const getCurrentListId: MemoizedSelector<CoreState, number> = createSelector(getCloudSyncAuthState, cloudSyncAuth.currentListId);
export const getIsCanBuyForCredits: MemoizedSelector<CoreState, boolean> = createSelector(getIsUnlimited, getCredits,
  getCurrentListRecords, cloudSyncAuth.isCanBuyForCredits);

export const getIsShowAssignCloudSyncProduct: MemoizedSelector<CoreState, boolean> = createSelector(getRole,
  getHasAvailableCloudSync, cloudSyncAuth.isShowAssignCloudSyncProduct);

export const getSyncLimitedErrorMsg: MemoizedSelector<CoreState, string> = createSelector(getCloudSyncAuthState,
  cloudSyncAuth.syncLimitedErrorMsg);
