import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IAccountSeat, IAccountSeats } from '../../interfaces/marketview';

@Component({
  selector: 'bl-products-container',
  templateUrl: './products-container.component.html',
  styleUrls: ['./products-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsContainerComponent {
  @Input() seats: IAccountSeats;

  trackByFn(index: number, seat: IAccountSeat): number {
    return seat.id;
  }
}
