export enum FLAT_INPUT_THEME {
  DEFAULT = 'default',
  SIMPLE = 'simple',
  MOBILE = 'mobile',
  MAT_LIKE = 'mat-like'
}

export enum INPUT_AUTOCOMPLETE {
  ON = 'on',
  OFF = 'off'
}
