import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IBrowseEntities,
  ILoadBrowseItemByCityIdPayload,
  ILoadBrowseItemByStateIdPayload,
  ILoadBrowseItemByTypePayload,
  ILoadBrowseItemPayload
} from '../../interfaces/browse';

enum ACTION_TYPES {
  LOAD_STATES = '[Geo browse] Load States',
  RESET_STATES = '[Geo browse] Reset States',
  LOAD_STATES_SUCCESS = '[Geo browse] Load States Success',

  LOAD_COUNTIES = '[Geo browse] Load Counties',
  RESET_COUNTIES = '[Geo browse] Reset Counties',
  LOAD_COUNTIES_SUCCESS = '[Geo browse] Load Counties Success',

  LOAD_CITIES_TITLES = '[Geo browse] Load Cities Titles',
  RESET_CITIES_TITLES = '[Geo browse] Reset Cities Titles',
  LOAD_CITIES_TITLES_SUCCESS = '[Geo browse] Load Cities Titles Success',

  LOAD_CITIES = '[Geo browse] Load Cities',
  RESET_CITIES = '[Geo browse] Reset Cities',
  LOAD_CITIES_SUCCESS = '[Geo browse] Load Cities Success',

  LOAD_CITIES_FOR_ZIP_CODES = '[Geo browse] Load Cities For Zip Codes',
  RESET_CITIES_FOR_ZIP_CODES = '[Geo browse] Reset Cities For Zip Codes',
  LOAD_CITIES_FOR_ZIP_CODES_SUCCESS = '[Geo browse] Load Cities For Zip Codes Success',

  LOAD_ZIP_CODES = '[Geo browse] Load Zip Codes',
  RESET_ZIP_CODES = '[Geo browse] Reset Zip Codes',
  LOAD_ZIP_CODES_SUCCESS = '[Geo browse] Load Zip Codes Success',

  LOAD_DMA_CODES = '[Geo browse] Load DMA Codes',
  RESET_DMA_CODES = '[Geo browse] Reset DMA Codes',
  LOAD_DMA_CODES_SUCCESS = '[Geo browse] Load DMA Codes Success',

  LOAD_SCF_CODES = '[Geo browse] Load SCF Codes',
  RESET_SCF_CODES = '[Geo browse] Reset SCF Codes',
  LOAD_SCF_CODES_SUCCESS = '[Geo browse] Load SCF Codes Success',

  GEO_BROWSE_LOADING_ERROR = '[Geo browse] Loading Error',

  SET_OPENED_CATEGORY_TYPE = '[Geo browse] set opened category type',

  RESET_GEO_BROWSE_STATE = '[Geo browse] Reset state',
}

export const loadStatesAction: CreateActionType<ACTION_TYPES.LOAD_STATES, ILoadBrowseItemByTypePayload> = createAction(
  ACTION_TYPES.LOAD_STATES,
  createPayload<ILoadBrowseItemByTypePayload>()
);
export const resetStatesAction: ActionCreator<ACTION_TYPES.RESET_STATES, () => TypedAction<ACTION_TYPES.RESET_STATES>> = createAction(ACTION_TYPES.RESET_STATES);
export const loadStatesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_STATES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_STATES_SUCCESS, createPayload<IBrowseEntities>());

export const loadCountiesAction: CreateActionType<ACTION_TYPES.LOAD_COUNTIES, ILoadBrowseItemByStateIdPayload> = createAction(ACTION_TYPES.LOAD_COUNTIES,
  createPayload<ILoadBrowseItemByStateIdPayload>());
export const resetCountiesAction: ActionCreator<ACTION_TYPES.RESET_COUNTIES, () => TypedAction<ACTION_TYPES.RESET_COUNTIES>> = createAction(ACTION_TYPES.RESET_COUNTIES);
export const loadCountiesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_COUNTIES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_COUNTIES_SUCCESS,
  createPayload<IBrowseEntities>()
);

export const loadCitiesTitlesAction: CreateActionType<ACTION_TYPES.LOAD_CITIES_TITLES, ILoadBrowseItemByStateIdPayload> = createAction(ACTION_TYPES.LOAD_CITIES_TITLES,
  createPayload<ILoadBrowseItemByStateIdPayload>());
export const resetCitiesTitlesAction: ActionCreator<ACTION_TYPES.RESET_CITIES_TITLES, () => TypedAction<ACTION_TYPES.RESET_CITIES_TITLES>> = createAction(ACTION_TYPES.RESET_CITIES_TITLES);
export const loadCitiesTitlesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_CITIES_TITLES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_CITIES_TITLES_SUCCESS,
  createPayload<IBrowseEntities>()
);

export const loadCitiesAction: CreateActionType<ACTION_TYPES.LOAD_CITIES, ILoadBrowseItemByStateIdPayload> = createAction(ACTION_TYPES.LOAD_CITIES,
  createPayload<ILoadBrowseItemByStateIdPayload>());
export const resetCitiesAction: ActionCreator<ACTION_TYPES.RESET_CITIES, () => TypedAction<ACTION_TYPES.RESET_CITIES>> = createAction(ACTION_TYPES.RESET_CITIES);
export const loadCitiesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_CITIES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_CITIES_SUCCESS, createPayload<IBrowseEntities>());

export const loadCitiesForZipCodesAction: CreateActionType<ACTION_TYPES.LOAD_CITIES_FOR_ZIP_CODES, ILoadBrowseItemByStateIdPayload> = createAction(ACTION_TYPES.LOAD_CITIES_FOR_ZIP_CODES,
  createPayload<ILoadBrowseItemByStateIdPayload>());
export const resetCitiesForZipCodesAction: ActionCreator<ACTION_TYPES.RESET_CITIES_FOR_ZIP_CODES, () => TypedAction<ACTION_TYPES.RESET_CITIES_FOR_ZIP_CODES>> = createAction(ACTION_TYPES.RESET_CITIES_FOR_ZIP_CODES);
export const loadCitiesForZipCodesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_CITIES_FOR_ZIP_CODES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_CITIES_FOR_ZIP_CODES_SUCCESS,
  createPayload<IBrowseEntities>());

export const loadZipCodesAction: CreateActionType<ACTION_TYPES.LOAD_ZIP_CODES, ILoadBrowseItemByCityIdPayload> = createAction(ACTION_TYPES.LOAD_ZIP_CODES,
  createPayload<ILoadBrowseItemByCityIdPayload>());
export const resetZipCodesAction: ActionCreator<ACTION_TYPES.RESET_ZIP_CODES, () => TypedAction<ACTION_TYPES.RESET_ZIP_CODES>> = createAction(ACTION_TYPES.RESET_ZIP_CODES);
export const loadZipCodesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_ZIP_CODES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_ZIP_CODES_SUCCESS,
  createPayload<IBrowseEntities>()
);

export const loadDmaCodesAction: CreateActionType<ACTION_TYPES.LOAD_DMA_CODES, ILoadBrowseItemPayload> = createAction(ACTION_TYPES.LOAD_DMA_CODES, createPayload<ILoadBrowseItemPayload>());
export const resetDmaCodesAction: ActionCreator<ACTION_TYPES.RESET_DMA_CODES, () => TypedAction<ACTION_TYPES.RESET_DMA_CODES>> = createAction(ACTION_TYPES.RESET_DMA_CODES);
export const loadDmaCodesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_DMA_CODES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_DMA_CODES_SUCCESS,
  createPayload<IBrowseEntities>()
);

export const loadSCFCodesAction: CreateActionType<ACTION_TYPES.LOAD_SCF_CODES, ILoadBrowseItemByStateIdPayload> = createAction(ACTION_TYPES.LOAD_SCF_CODES,
  createPayload<ILoadBrowseItemByStateIdPayload>());
export const resetSCFCodesAction: ActionCreator<ACTION_TYPES.RESET_SCF_CODES, () => TypedAction<ACTION_TYPES.RESET_SCF_CODES>> = createAction(ACTION_TYPES.RESET_SCF_CODES);
export const loadSCFCodesSuccessAction: CreateActionType<ACTION_TYPES.LOAD_SCF_CODES_SUCCESS, IBrowseEntities> = createAction(ACTION_TYPES.LOAD_SCF_CODES_SUCCESS,
  createPayload<IBrowseEntities>()
);

export const geoBrowserLoadingErrorAction: CreateActionType<ACTION_TYPES.GEO_BROWSE_LOADING_ERROR, IServerError> = createAction(ACTION_TYPES.GEO_BROWSE_LOADING_ERROR,
  createPayload<IServerError>()
);

export const setOpenedCategoryTypeAction: CreateActionType<ACTION_TYPES.SET_OPENED_CATEGORY_TYPE, number | null> = createAction(ACTION_TYPES.SET_OPENED_CATEGORY_TYPE,
  createPayload<number | null>()
);

export const resetGeoBrowseStateAction: ActionCreator<ACTION_TYPES.RESET_GEO_BROWSE_STATE, () => TypedAction<ACTION_TYPES.RESET_GEO_BROWSE_STATE>> = createAction(ACTION_TYPES.RESET_GEO_BROWSE_STATE);
