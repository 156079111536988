import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { MobileDropDownModule } from './mobile/mobile-drop-down/mobile-drop-down.module';

import { DropDownCloseFooterComponent } from './components/drop-down-close-footer/drop-down-close-footer.component';
import { DropDownPortalComponent } from './components/drop-down-portal/drop-down-portal.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { MobileSortingComponent } from './components/mobile-sorting/mobile-sorting.component';
import { PointsMenuComponent } from './components/points-menu/points-menu.component';
import { SubmenuComponent } from './components/submenu/submenu.component';

import { DropDownContentDirective } from './directives/drop-down-content.directive';
import { DropDownTargetDirective } from './directives/drop-down-target.directive';

import { DropDownService } from './services/drop-down.service';


const imports: any[] = [
  CommonModule,
  MobileDropDownModule,
];

const declarations: any[] = [
  DropDownComponent,
  PointsMenuComponent,
  DropDownPortalComponent,
  DropDownTargetDirective,
  DropDownContentDirective,
  SubmenuComponent,
  MobileSortingComponent,
  DropDownCloseFooterComponent
];
const exports: any[] = [
  MobileDropDownModule,
  ...declarations
];

@NgModule({
  imports,
  declarations,
  exports
})
export class DropDownModule {
  static forRoot(): ModuleWithProviders<DropDownModule> {
    return {
      ngModule: DropDownModule,
      providers: [
        DropDownService,
      ]
    };
  }
}
