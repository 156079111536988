<button *ngIf="type === buttonBackTypes.Close"
        (click)="goBack()"
        [class.color-white]="isWhiteBtn"
        matRipple
        class="btn__close"
        data-testid="back-btn">

  <i class="icon-page-close"></i>
</button>

<button *ngIf="type === buttonBackTypes.Back"
        (click)="goBack()"
        class="btn__close btn__close--back" data-testid="back-btn">

  <i class="icon-back-arrow-secondary"></i><span class="btn__text">Back</span>
</button>
