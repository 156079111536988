import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { select, Action, Store } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap, take, takeWhile, tap, withLatestFrom } from 'rxjs/operators';

import * as listPurchaseActions from '@core/store/actions/list-purchase.actions';
import { go } from '@core/store/actions/router-history.action';
import { CoreState } from '@core/store/reducers';
import * as authActions from '@modules/auth/store/actions/auth.action';
import { getCreditsAction } from '@modules/credits/store/actions';
import { exportDashboardListAction } from '@modules/dashboard-lists/store/actions/dashboard-lists.action';
import { updateListStateAction } from '@modules/list-common-store/store/actions/list.actions';
import { resetListAppendsAction } from '@modules/list/store/actions/list-appends.action';
import { resetListSummaryAction } from '@modules/list/store/actions/list-summary.actions';
import { hideNotificationAction, showNotificationAction } from '@modules/notifications/store/actions/notification.action';
import { getIsUnlimited } from '@modules/profile/store/selectors/profile.selector';
import { ActionWithPayload } from '@shared/interfaces/store';

import { ConfirmPopUpContentComponent } from '@ui/pop-up/components/confirm-pop-up-content/confirm-pop-up-content.component';
import {
  WaitForListPopUpContentComponent
} from '@ui/pop-up/components/wait-for-list-pop-up-content/wait-for-list-pop-up-content.component';

import { ListService } from '@core/services/list.service';
import { StorageService } from '@core/services/storage.service';
import { PopUpService } from '@ui/pop-up/services/pop-up/pop-up.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { ISegmentData } from '@modules/segment/interfaces/segment';
import { IDataListSummary, IExportPercentPayload, IFetchRefreshListData, IRefreshListData, ISummaryList } from '@shared/interfaces/list';
import { IServerError } from '@shared/interfaces/server-error';

import { PopUpConfig } from '@ui/pop-up/models/pop-up-config';
import { ConfirmPopUpData, PopUpText } from '@ui/pop-up/models/pop-up-data';
import { ConfirmPopUpResult } from '@ui/pop-up/models/pop-up-result';

import { CORE_PATHS } from '@core/constants/core-paths';
import { NOTIFICATION_TYPES } from '@core/constants/notifications';
import { SOCKET_EVENTS } from '@core/constants/socket-events';
import { LIST_PATHS } from '@modules/list-common-store/constants/list-paths';
import { HUGE_LIST_SIZE } from '@modules/list/constants/data';
import { EXPORT_LIST_IN_PROGRESS, EXPORT_LIST_IS_READY, POPUP_TIMER } from '@modules/list/constants/list-purchase';
import { LIST_RELATE_TO_PERSONNEL_TYPE } from '@shared/constants/data/list-types';
import { CONFIRM_LIST, DEFAULT_TIME_TEXT, SHORTEST_TIME_TEXT, SMALL_TIME_LIST_TEXT } from '@ui/pop-up/constants/pop-up-data';

import { WebSocketsProvider } from '@modules/websockets';


@Injectable()
export class ListPurchaseEffect {

  constructor(private _actions$: Actions,
              private _store: Store<CoreState>,
              private _ws: WebSocketsProvider,
              private _listService: ListService,
              private _popUpService: PopUpService) {
  }

  purchaseList$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.purchaseListAction),
      switchMap(({ payload: list }: ActionWithPayload<ISummaryList>) => this._listService.purchaseList(list.id)
        .pipe(
          mapTo(listPurchaseActions.purchaseListSuccessAction(list)),
          catchError((error: IServerError) => of(listPurchaseActions.purchaseListErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  confirmPurchaseList$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.confirmPurchaseListAction),
      tap(({ payload: { list } }: ActionWithPayload<IRefreshListData>) => {
        StorageService.doNotShowConfirmListPopUp
          ? this._store.dispatch(listPurchaseActions.purchaseListAction(list))
          : this._popUpService.open(ConfirmPopUpContentComponent, new PopUpConfig<ConfirmPopUpData>(CONFIRM_LIST))
            .afterClose
            .pipe(
              take(1),
              filter(({ answer }: ConfirmPopUpResult) => answer),
            )
            .subscribe((answer: ConfirmPopUpResult) => {
              StorageService.doNotShowConfirmListPopUp = answer.doNotShow;
              this._store.dispatch(listPurchaseActions.purchaseListAction(list));
            });
      })
    ), { dispatch: false });

  rePurchaseList$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.rePurchaseListAction),
      switchMap(({ payload: list }: ActionWithPayload<ISummaryList>) => this._listService.rePurchaseList(list.id)
        .pipe(
          map((_list: ISummaryList) => listPurchaseActions.rePurchaseListSuccessAction(_list)),
          catchError((error: IServerError) => of(listPurchaseActions.rePurchaseListErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  refreshList$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.refreshListAction),
      switchMap(({ payload: { list, withAdditional } }: ActionWithPayload<IRefreshListData>) =>
        this._listService.refreshList(list.id, withAdditional)
          .pipe(
            mapTo(listPurchaseActions.refreshListSuccessAction(list)),
            catchError((error: IServerError) => of(listPurchaseActions.refreshListErrorAction(error)))
          )
      ),
      catchErrorWithErrorType
    ));

  confirmRefreshList$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.confirmRefreshListAction),
      tap(({ payload }: ActionWithPayload<IRefreshListData>) => {
        StorageService.doNotShowRefreshListPopUp
          ? this._store.dispatch(listPurchaseActions.refreshListAction(payload))
          : this._popUpService.open(ConfirmPopUpContentComponent, new PopUpConfig<ConfirmPopUpData>(CONFIRM_LIST))
            .afterClose
            .pipe(
              take(1),
              filter(({ answer }: ConfirmPopUpResult) => answer),
            )
            .subscribe((answer: ConfirmPopUpResult) => {
              StorageService.doNotShowRefreshListPopUp = answer.doNotShow;
              this._store.dispatch(listPurchaseActions.refreshListAction(payload));
              this._store.dispatch(resetListAppendsAction());
            });
      })
    ), { dispatch: false });

  fetchListPurchaseData$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.fetchListPurchaseDataAction),
      switchMap(({ payload: listId }: ActionWithPayload<number>) => this._listService.getListSummaryData(listId)
        .pipe(
          map((listData: IDataListSummary) => listPurchaseActions.fetchListPurchaseDataSuccessAction(listData)),
          catchError((error: IServerError) => of(listPurchaseActions.fetchPurchaseDataErrorAction(error)))
        )
      ),
      catchErrorWithErrorType
    ));

  fetchListSegmentPurchaseData$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.fetchListSegmentPurchaseDataAction),
      switchMap(({ payload }: ActionWithPayload<number>) =>
        this._listService.getListSegmentData(payload)
          .pipe(
            map((data: ISegmentData) => listPurchaseActions.fetchListSegmentPurchaseDataSuccessAction(data)),
            catchError((error: IServerError) => of(listPurchaseActions.fetchListSegmentPurchaseDataErrorAction(error)))
          )
      )
    )
  );

  fetchRefreshData: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.fetchRefreshDataAction),
      switchMap(({ payload: { listId, withAdditional } }: ActionWithPayload<IFetchRefreshListData>) =>
        this._listService.getRefreshData(listId, withAdditional)
          .pipe(
            map((listData: IDataListSummary) => listPurchaseActions.fetchRefreshDataSuccessAction(listData)),
            catchError((error: IServerError) => of(listPurchaseActions.fetchPurchaseDataErrorAction(error)))
          )
      ),
      catchErrorWithErrorType
    ));

  fetchRefreshSegmentData$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.fetchRefreshSegmentDataAction),
      switchMap(({ payload }: { payload: number }) =>
        this._listService.getListSegmentData(payload)
          .pipe(
            map((data: ISegmentData) => listPurchaseActions.fetchRefreshSegmentDataSuccessAction(data)),
            catchError((error: IServerError) => of(listPurchaseActions.fetchRefreshSegmentDataErrorAction(error)))
          )
      )
    )
  );

  navigateAfterConfirmSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(
        listPurchaseActions.purchaseListSuccessAction,
      ),
      map(({ payload }: ActionWithPayload<ISummaryList>) => LIST_RELATE_TO_PERSONNEL_TYPE.includes(payload.recordsType.id)
        ? payload.personsCount
        : payload.institutionsCount
      ),
      tap((count: number) => {
        const popUpText: PopUpText = count >= HUGE_LIST_SIZE ? DEFAULT_TIME_TEXT : SMALL_TIME_LIST_TEXT;
        const config: PopUpConfig<PopUpText> = new PopUpConfig<PopUpText>(popUpText, false, POPUP_TIMER);

        this._popUpService.open(WaitForListPopUpContentComponent, config);
      }),
      withLatestFrom(this._store.pipe(select(getIsUnlimited))),
      switchMap(([count, isUnlimited]: [number, boolean]) => {
        const actions: Action[] = [go(['/', CORE_PATHS.DASHBOARD]), resetListSummaryAction()];

        if (!isUnlimited) {
          actions.unshift(getCreditsAction());
        }
        return actions;
      }),
    ));

  navigateAfterRefreshSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(
        listPurchaseActions.refreshListSuccessAction
      ),
      tap(() => {
        const config: PopUpConfig<PopUpText> = new PopUpConfig<PopUpText>(SHORTEST_TIME_TEXT, false, POPUP_TIMER);
        this._popUpService.open(WaitForListPopUpContentComponent, config);
      }),
      withLatestFrom(this._store.pipe(select(getIsUnlimited))),
      switchMap(([action, isUnlimited]: [Action, boolean]) => {
        const actions: Action[] = [go(['/', CORE_PATHS.DASHBOARD]), resetListSummaryAction()];

        if (!isUnlimited) {
          actions.unshift(getCreditsAction());
        }
        return actions;
      }),
    ));

  afterRePurchaseSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.rePurchaseListSuccessAction),
      switchMap(({ payload: list }: ActionWithPayload<ISummaryList>) => [
        updateListStateAction(list),
        go(['/', CORE_PATHS.LIST, list.id, LIST_PATHS.REFINE], { isCanRemove: true })
      ]),
      catchErrorWithErrorType
    ));

  afterSuccessSignOut$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(authActions.signOutSuccessAction),
      mapTo(listPurchaseActions.resetListPurchaseAction()),
      catchErrorWithErrorType
    ));

  exportList$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.exportListAction),
      switchMap(({ payload: listId }: ActionWithPayload<number>) => this._listService.exportList(listId)
        .pipe(
          map(() => listPurchaseActions.exportListSuccessAction(listId)),
          catchError((error: IServerError) => of(listPurchaseActions.exportListErrorAction(error)))
        )),
      catchErrorWithErrorType
    ));

  exportSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.exportListSuccessAction),
      mergeMap(() => [
        go(`/${ CORE_PATHS.DASHBOARD }`),
        showNotificationAction({
          message: EXPORT_LIST_IN_PROGRESS,
          timeout: 5000,
          type: NOTIFICATION_TYPES.INFO,
          canClose: true
        })]),
      catchErrorWithErrorType
    ));

  exportReady$: Observable<unknown> = createEffect(() => this._actions$
    .pipe(
      ofType(listPurchaseActions.listenExportListSocketAction),
      switchMap(() => this._ws.on<IExportPercentPayload>(SOCKET_EVENTS.EXPORT_LIST_PROCESS)),
      takeWhile((socket: IExportPercentPayload) => !!socket),
      tap(({ listingId, process }: IExportPercentPayload) => {

        this._store.dispatch(listPurchaseActions.calculateListExportAction({ listingId, process }));

        if (process === 100) {
          this._store.dispatch(listPurchaseActions.calculateListExportSuccessAction({ listingId, process }));

          const notificationId: string = `export-list-${ listingId }-is-ready`;

          this._store.dispatch(showNotificationAction({
            id: notificationId,
            message: EXPORT_LIST_IS_READY,
            type: NOTIFICATION_TYPES.SUCCESS,
            canClose: true,
            belowButton: {
              fn: () => {
                this._store.dispatch(exportDashboardListAction({ listId: listingId }));
                this._store.dispatch(hideNotificationAction(notificationId));
              },
              name: 'Download'
            }
          }));
        }
      }),
      catchErrorWithErrorType
    ), { dispatch: false });
}
