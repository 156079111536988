import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import { ICreditsState } from '@modules/credits/store/reducers/credits.reducer';
import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { ICreditsModuleSTate } from '../reducers';

export const CREDITS_MODULE_FEATURE_NAME: string = 'CREDITS_MODULE_STATE';

export const selectCreditsModuleState: GetFromStateInSelectors<CoreState,
  ICreditsModuleSTate> = createFeatureSelector<ICreditsModuleSTate>(CREDITS_MODULE_FEATURE_NAME);
export const selectCreditsState: MemoizedSelector<CoreState, ICreditsState> = createSelector(selectCreditsModuleState,
  (state: ICreditsModuleSTate) => state.credits);
