<bl-pop-up-base (close)="onClose($event)">
  <div class="icon">
    <i [class]="data.iconClass"></i>
  </div>

  <h5 class="title" data-testid="activate-list-pop-up">{{ data.title }}</h5>

  <div class="body" [innerHTML]="data?.body"></div>

  <bl-do-not-show-pop-up-again *ngIf="!data?.hideDoNotShowCheckbox"
                               [(ngModel)]="doNotShow">
  </bl-do-not-show-pop-up-again>

  <div class="buttons">
    <button *ngIf="!data?.shouldCallLink"
            class="btn btn--secondary btn--white"
            (click)="onConfirm($event)"
            data-testid="yes-activate-btn">
      {{ data.buttonText }}
    </button>
    <a *ngIf="data?.shouldCallLink"
       class="btn btn--secondary btn--white"
       href="tel:+{{ phoneNumber }}"
       (click)="onConfirm($event)">
      {{ data.buttonText }}
    </a>
  </div>
</bl-pop-up-base>
