import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/download.action';

import { IDownloadByLink } from '../../interfaces/download-by-link';

export interface IDownloadState {
  url: IDownloadByLink;
}

const initialState: IDownloadState = {
  url: null
};

const downloadFileByLink: OnReducer<IDownloadState, ActionType<Payload<any>>> = (state: IDownloadState, { payload }: Payload<any>) => ({
  ...state,
  url: { ...payload }
});

const reducer: ActionReducer<IDownloadState> = createReducer<IDownloadState>(
  initialState,
  on(actions.downloadFileByLinkAction, downloadFileByLink)
);

export const url: GetFromState<IDownloadByLink, IDownloadState> = (state: IDownloadState): IDownloadByLink => state.url;

export function downloadReducer(state: IDownloadState, action: Action): IDownloadState {
  return reducer(state, action);
}
