import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';

import { IServerError } from '@shared/interfaces/server-error';

import { FORM_VALIDATION_ERRORS } from '@shared/constants/validators/forms-validations-errors';

import { Validators } from '@shared/validators/validators';

@Component({
  selector: 'bl-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordFormComponent implements OnInit, OnChanges {

  @Input() serverError: IServerError = null;
  @Input() loading: boolean = false;

  @Output() onSubmit: EventEmitter<{ email: string }> = new EventEmitter();

  form: FormGroup;

  constructor(private fb: FormBuilder,
              private chdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.form = this.createForm();

    this.chdRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errors: SimpleChange = changes.serverError;
    if (errors && errors.currentValue) {
      Object.entries(this.serverError.errors).forEach(([fieldName, message]: [string, string[]]) => {
        this.form.get(fieldName).setErrors(message);
      });
    }

    this.chdRef.markForCheck();
  }

  createForm(): FormGroup {
    const formErrors: ValidationErrors = FORM_VALIDATION_ERRORS;

    return this.fb.group({
      email: ['', [
        Validators.required(formErrors.email.required),
        Validators.email(formErrors.email.invalid)
      ]]
    });
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    this.onSubmit.emit(this.form.value);
  }
}
