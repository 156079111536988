<div #container
     *ngIf="tooltip.text"
     [@fadeInOut]
     [ngClass]="getPositionClass()"
     [ngStyle]="{ 'max-width': tooltip?.calcWidth ? maxWidth : defaultMaxWidth }"
     class="tooltip tooltip--center">

  <span *ngIf="tooltip?.iconClass">
    <i [class]="tooltip?.iconClass"></i>
    <br/>
  </span>

  <div class="tooltip-message"
       [innerHTML]="tooltip.replaceNextRow
       ? replaceNoWrap(tooltip.text)
       : tooltip.text">
  </div>

  <button *ngIf="shouldShowTooltipFooter"
          type="button"
          (click)="onFooterBtnClick($event)"
          class="tooltip_btn tooltip__dismissable">

    <span *ngIf="tooltip.dismissable">
      <i class="icon-page-close"></i>
      Dismiss
    </span>

    <span *ngIf="tooltip.footerText">
      {{ tooltip.footerText }}
    </span>
  </button>

  <ng-container *ngIf="tooltip.callLink">
    <br/>


    <!--  TODO remove pipe dependency  -->

    <a href="tel:+{{ phoneNumber }}"
       class="btn btn--primary">
      Call {{ phoneNumber | phone: 'brackets' }}
    </a>


  </ng-container>

  <ng-container *ngIf="tooltip.redirectLink">
    <br/>

    <!--  TODO remove router dependency  -->
    <a [routerLink]="tooltip.redirectLink"
       class="btn btn--primary products-link">
      View Products & Users
    </a>

  </ng-container>

</div>
