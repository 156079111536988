import { AbstractControl } from '@angular/forms';

import { ADD_ON_FIELD } from '../constants/add-ons';

// add ons enabled
export const groupAddOnEnabled: (_stateControls?: AbstractControl[],
                                 _availableAddOnIds?: number[],
                                 _userAddOnIds?: number[],
                                 _field?: ADD_ON_FIELD) => AbstractControl[] =
  (_stateControls: AbstractControl[] = [],
   _availableAddOnIds: number[] = [],
   _userAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): AbstractControl[] => {

    return _stateControls.filter((_item: AbstractControl) => {
      const _stateId: any = _item.value.stateId;
      const bid: AbstractControl = _item.get(_field);
      return !_availableAddOnIds.includes(_stateId) && !_userAddOnIds.includes(_stateId) && bid.enabled && !bid.value;
    });
  };

export const groupAddOnAvailableEnabled: (_stateControls?: AbstractControl[],
                                          _availableAddOnIds?: number[],
                                          _field?: ADD_ON_FIELD) => AbstractControl[] =
  (_stateControls: AbstractControl[] = [],
   _availableAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): AbstractControl[] => {
    return _stateControls.filter((_item: AbstractControl) => {
      const bid: AbstractControl = _item.get(_field);
      return _availableAddOnIds.includes(_item.value.stateId) && bid.enabled && !bid.value;
    });
  };

export const groupAddOnOnUserEnabled: (_stateControls?: AbstractControl[],
                                       _availableAddOnIds?: number[],
                                       _userAddOnIds?: number[],
                                       _field?: ADD_ON_FIELD) => AbstractControl[] =
  (_stateControls: AbstractControl[] = [],
   _availableAddOnIds: number[] = [],
   _userAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): AbstractControl[] => {
    return _stateControls.filter((_item: AbstractControl) => {
      const _stateId: any = _item.value.stateId;
      const bid: AbstractControl = _item.get('isBidStateId');
      return !_availableAddOnIds.includes(_stateId) && _userAddOnIds.includes(_stateId) && bid.enabled && !bid.value;
    });
  };

// add ons disabled
export const groupAddOnDisabled: (_stateControls?: AbstractControl[],
                                  _availableAddOnIds?: number[],
                                  _userAddOnIds?: number[],
                                  _field?: ADD_ON_FIELD) => AbstractControl[] =
  (_stateControls: AbstractControl[] = [],
   _availableAddOnIds: number[] = [],
   _userAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): AbstractControl[] => {
    return _stateControls.filter((_item: AbstractControl) => {
      const _stateId: any = _item.value.stateId;
      return !_availableAddOnIds.includes(_stateId) && !_userAddOnIds.includes(_stateId) && _item.get('isBidStateId').disabled;
    });
  };

export const groupAddOnAvailableDisabled: (_stateControls?: AbstractControl[],
                                           _availableAddOnIds?: number[],
                                           _field?: ADD_ON_FIELD) => AbstractControl[] =
  (_stateControls: AbstractControl[] = [],
   _availableAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): AbstractControl[] =>
    _stateControls.filter((_item: AbstractControl) => _availableAddOnIds.includes(_item.value.stateId) && _item.get(_field).disabled);

export const groupAddOnOnUserDisabled: (_stateControls: AbstractControl[],
                                        _availableAddOnIds?: number[],
                                        _userAddOnIds?: number[],
                                        _field?: ADD_ON_FIELD) => AbstractControl[] =
  (_stateControls: AbstractControl[],
   _availableAddOnIds: number[] = [],
   _userAddOnIds: number[] = [],
   _field: ADD_ON_FIELD = ADD_ON_FIELD.BID): AbstractControl[] => {
    return _stateControls.filter((_item: AbstractControl) => {
      const _stateId: any = _item.value.stateId;
      return !_availableAddOnIds.includes(_stateId) && _userAddOnIds.includes(_stateId) && _item.get(_field).disabled;
    });
  };
