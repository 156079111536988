import { AbstractControl, ValidatorFn } from '@angular/forms';

export default function confirm(msg: string, controlNames: string[]): ValidatorFn {

  return (control: AbstractControl) => {
    const message: string = msg || 'Fields do not match';
    const controls: AbstractControl[] = controlNames.map((name: string) => control.root.get(name));

    const isEqual: boolean = controls.reduce((result: boolean, secondControl: AbstractControl) => {
      if (!secondControl) {
        return result;
      }
      return control.value === secondControl.value ? result : false;
    }, true);

    return isEqual ? null : { confirm: message };
  };
}
