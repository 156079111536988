import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { ITooltipsState } from '@ui/tooltip/store/reducers/tooltips.reducer';
import { ITooltipsModuleState, TOOLTIPS_MODULE_FEATURE_NAME } from '../reducers';

export const getTooltipsModuleState: GetFromStateInSelectors<CoreState,
  ITooltipsModuleState> = createFeatureSelector<CoreState, ITooltipsModuleState>(TOOLTIPS_MODULE_FEATURE_NAME);
export const selectTooltipsState: GetFromStateInSelectors<CoreState,
  ITooltipsState> = createSelector(getTooltipsModuleState, (state: ITooltipsModuleState) => state && state.tooltips);
