import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import {
  IAddJobPayload,
  IAddJobReloadPayload,
  IJob,
  IJobSuggestions,
  IJobSuggestionPayload,
  IRemoveJobAndReloadPayload,
  IRemoveJobPayload,
} from '../../interfaces/jobs';

import { IChangeTargetingCriteria, IOnSegmentUpdateDetailedDataControls } from '@shared/modules/targeting-criteria-controls/data';

enum ACTION_TYPES {
  LOAD_SUGGESTIONS = '[Job targeting] job load suggestions',
  LOAD_SUGGESTIONS_ERROR = '[Job targeting] job load suggestions error',
  LOAD_SUGGESTIONS_SUCCESS = '[Job targeting] job load suggestions success',
  LOAD_SUGGESTIONS_RESET = '[Job targeting] job load suggestions reset',

  ADD_JOB = '[Job targeting] Add Job',
  ADD_JOB_AND_RELOAD = '[Job targeting] Add Job And Reload',
  ADD_JOB_ERROR = '[Job targeting] Add Job error',
  ADD_JOB_SUCCESS = '[Job targeting] Add Job success',

  REMOVE_JOB = '[Job targeting] Remove Job',
  REMOVE_JOB_AND_RELOAD = '[Job targeting] Remove Job And Reload',
  REMOVE_JOB_ERROR = '[Job targeting] Remove Job error',
  REMOVE_JOB_SUCCESS = '[Job targeting] Remove Job success',

  UPDATE_JOB = '[Job targeting] Update Job',
  UPDATE_JOB_ERROR = '[Job targeting] Update Job error',
  UPDATE_JOB_SUCCESS = '[Job targeting] Update Job success',

  ON_UPDATE_JOBS_DETAILED_DATA = '[Job targeting] On Update Jobs Detailed data',
  UPDATE_JOBS_DETAILED_DATA = '[Job targeting] Update Jobs DetailedData',
  UPDATE_JOBS_DETAILED_DATA_ERROR = '[Job targeting] Update Jobs DetailedData error',
  UPDATE_JOBS_DETAILED_DATA_SUCCESS = '[Job targeting] Update Jobs DetailedData success',

  TOGGLE_JOBS_INCLUDE = '[Job targeting] Toggle Jobs Include',

  RESET = '[Job targeting] Jobs reset state'
}

export const loadSuggestionsAction: CreateActionType<ACTION_TYPES.LOAD_SUGGESTIONS, IJobSuggestionPayload> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS, createPayload<IJobSuggestionPayload>());
export const loadSuggestionsErrorAction: CreateActionType<ACTION_TYPES.LOAD_SUGGESTIONS_ERROR, IServerError> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS_ERROR, createPayload<IServerError>());
export const loadSuggestionsSuccessAction: CreateActionType<ACTION_TYPES.LOAD_SUGGESTIONS_SUCCESS, IJobSuggestions> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS_SUCCESS, createPayload<IJobSuggestions>());

export const loadSuggestionsResetAction: ActionCreator<ACTION_TYPES.LOAD_SUGGESTIONS_RESET, () => TypedAction<ACTION_TYPES.LOAD_SUGGESTIONS_RESET>> = createAction(ACTION_TYPES.LOAD_SUGGESTIONS_RESET);

export const addJobAction: CreateActionType<ACTION_TYPES.ADD_JOB, IAddJobPayload> = createAction(ACTION_TYPES.ADD_JOB, createPayload<IAddJobPayload>());
export const addJobErrorAction: CreateActionType<ACTION_TYPES.ADD_JOB_ERROR, IServerError> = createAction(ACTION_TYPES.ADD_JOB_ERROR, createPayload<IServerError>());
export const addJobSuccessAction: CreateActionType<ACTION_TYPES.ADD_JOB_SUCCESS, number | null> = createAction(ACTION_TYPES.ADD_JOB_SUCCESS, createPayload<number | null>());
export const addJobAndReloadAction: CreateActionType<ACTION_TYPES.ADD_JOB_AND_RELOAD, IAddJobReloadPayload> = createAction(ACTION_TYPES.ADD_JOB_AND_RELOAD, createPayload<IAddJobReloadPayload>());

export const removeJobAction: CreateActionType<ACTION_TYPES.REMOVE_JOB, IRemoveJobPayload> = createAction(ACTION_TYPES.REMOVE_JOB, createPayload<IRemoveJobPayload>());
export const removeJobErrorAction: CreateActionType<ACTION_TYPES.REMOVE_JOB_ERROR, IServerError> = createAction(ACTION_TYPES.REMOVE_JOB_ERROR, createPayload<IServerError>());
export const removeJobSuccessAction: ActionCreator<ACTION_TYPES.REMOVE_JOB_SUCCESS, () => TypedAction<ACTION_TYPES.REMOVE_JOB_SUCCESS>> = createAction(ACTION_TYPES.REMOVE_JOB_SUCCESS);
export const removeJobAndReloadAction: CreateActionType<ACTION_TYPES.REMOVE_JOB_AND_RELOAD, IRemoveJobAndReloadPayload> = createAction(ACTION_TYPES.REMOVE_JOB_AND_RELOAD, createPayload<IRemoveJobAndReloadPayload>());

export const updateJobAction: CreateActionType<ACTION_TYPES.UPDATE_JOB, IJob> = createAction(ACTION_TYPES.UPDATE_JOB, createPayload<IJob>());
export const updateJobErrorAction: CreateActionType<ACTION_TYPES.UPDATE_JOB_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_JOB_ERROR, createPayload<IServerError>());
export const updateJobSuccessAction: ActionCreator<ACTION_TYPES.UPDATE_JOB_SUCCESS, () => TypedAction<ACTION_TYPES.UPDATE_JOB_SUCCESS>> = createAction(ACTION_TYPES.UPDATE_JOB_SUCCESS);

export const onUpdateJobsDetailedDataAction: CreateActionType<ACTION_TYPES.ON_UPDATE_JOBS_DETAILED_DATA, IOnSegmentUpdateDetailedDataControls> = createAction(ACTION_TYPES.ON_UPDATE_JOBS_DETAILED_DATA,
  createPayload<IOnSegmentUpdateDetailedDataControls>());

export const updateJobsDetailedDataAction: CreateActionType<ACTION_TYPES.UPDATE_JOBS_DETAILED_DATA, IChangeTargetingCriteria> = createAction(ACTION_TYPES.UPDATE_JOBS_DETAILED_DATA, createPayload<IChangeTargetingCriteria>());
export const updateJobsDetailedDataErrorAction: CreateActionType<ACTION_TYPES.UPDATE_JOBS_DETAILED_DATA_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_JOBS_DETAILED_DATA_ERROR, createPayload<IServerError>());
export const updateJobsDetailedDataSuccessAction: ActionCreator<ACTION_TYPES.UPDATE_JOBS_DETAILED_DATA_SUCCESS, () => TypedAction<ACTION_TYPES.UPDATE_JOBS_DETAILED_DATA_SUCCESS>> = createAction(ACTION_TYPES.UPDATE_JOBS_DETAILED_DATA_SUCCESS);

export const toggleJobsIncludeAction: ActionCreator<ACTION_TYPES.TOGGLE_JOBS_INCLUDE, () => TypedAction<ACTION_TYPES.TOGGLE_JOBS_INCLUDE>> = createAction(ACTION_TYPES.TOGGLE_JOBS_INCLUDE);

export const jobsResetStateAction: ActionCreator<ACTION_TYPES.RESET, () => TypedAction<ACTION_TYPES.RESET>> = createAction(ACTION_TYPES.RESET);
