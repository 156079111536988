import { ISeatState, ISeatStates } from '../interfaces/marketview';


export const checkedUnassignedStateIds: (_states?: ISeatStates, _userStateIds?: number[], _availableStateIds?: number[]) => number[] =
  (_states: ISeatStates = [],
  _userStateIds: number[] = [],
  _availableStateIds: number[] = []): number[] => {
  return _states.filter((_item: ISeatState) =>
    typeof _item.stateId === 'number'
    && !_userStateIds.includes(_item.stateId)
    && !_availableStateIds.includes(_item.stateId)
  ).map((_item: ISeatState) => _item.stateId);
};

export const checkedAvailableStatesIds: (_states?: ISeatStates, _availableStateIds?: number[]) => number[] =
  (_states: ISeatStates = [], _availableStateIds: number[] = []): number[] => {
  return _states.filter((_item: ISeatState) =>
    typeof _item.stateId === 'number'
    && _availableStateIds.includes(_item.stateId)
  ).map((_item: ISeatState) => _item.stateId);
};

export const checkedStateIdsOnUser: (_states?: ISeatStates, _userStateIds?: number[], _availableStateIds?: number[]) => number[] =
  (_states: ISeatStates = [],
  _userStateIds: number[] = [],
  _availableStateIds: number[] = []): number[] => {
  return _states.filter((_item: ISeatState) =>
    typeof _item.stateId === 'number'
    && _userStateIds.includes(_item.stateId)
    && !_availableStateIds.includes(_item.stateId)
  ).map((_item: ISeatState) => _item.stateId);
};
