import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PopUpRef } from '../../models/pop-up-ref';

@Component({
  selector: 'bl-download-started-pop-up-content',
  templateUrl: './download-started-pop-up-content.component.html',
  styleUrls: ['./download-started-pop-up-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadStartedPopUpContentComponent {

  constructor(private popUpRef: PopUpRef) {
  }

  onClose(): void {
    this.popUpRef.close();
  }
}
