import { Pipe, PipeTransform } from '@angular/core';

import { metersToMiles } from '@shared/utils/converters';

@Pipe({
  name: 'metersToMiles'
})
export class MetersToMilesPipe implements PipeTransform {

  transform(value: any): any {
    return Math.round(metersToMiles(value));
  }

}
