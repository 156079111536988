import { defaultEnvironment, IEnvironment } from '@env/default-environment';

import { apiPaths, IApiPathsConfig } from '@core/constants/api-paths';

const apiPathsConfig: IApiPathsConfig = {
  apiUrl: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa',
  serverUrl: 'https://qa.build-a-list-test.com',
  cyberSourceUrl: 'https://testsecureacceptance.cybersource.com'
};

export const environment: IEnvironment = {
  ...defaultEnvironment,
  production: true,
  envName: 'qa',
  adminAppUrl: 'https://cms.qa.build-a-list-test.com/main/customers/list',
  frameRegSuccessUrl: 'https://qa.build-a-list-test.com/dashboard',
  enableGA: true,
  enableCaptcha: false,
  enableBizible: false,
  enableLogRocket: false,
  webSockets: {
    url: 'wss://ocba87f8s0.execute-api.us-east-1.amazonaws.com/qa',
    reconnectInterval: 6600000,
    reconnectAttempts: 10,
  },
  api: { ...apiPaths(apiPathsConfig) },
  paymentKeys: {
    merchant_id: 'mdr_phx',
    locale: 'en',
    access_key: '442837396f5a346cb7a5de29d852c335',
    profile_id: '84B773B6-706B-4DDB-82AC-2A386B9118F4',
    override_custom_receipt_page: 'https://qa.build-a-list-test.com/cybersource-redirect-url',
    orderPage_declineResponseURL: 'https://qa.build-a-list-test.com/cybersource-redirect-url'
  },
};
