import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

import { BaseOverlayInfoComponent } from './components/base-overlay-info/base-overlay-info.component';
import { OverlayInfoComponent } from './components/overlay-info/overlay-info.component';

import { OverlayInfoProvider } from './providers/overlay-info-provider.service';

const components: Array<Type<any>> = [
  OverlayInfoComponent,
  BaseOverlayInfoComponent
];

@NgModule({
  imports: [CommonModule, MatRippleModule],
  providers: [OverlayInfoProvider],
  declarations: [
    ...components
  ],
  exports: [BaseOverlayInfoComponent]
})
export class OverlayInfoModule {
}
