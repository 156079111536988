import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthPagesModule } from '@modules/auth-pages/auth-pages.module';
import { BidModule } from '@modules/bid/bid.module';
import { CdlListModule } from '@modules/cdl/cdl-list/cdl-list.module';
import { CollegeOfficeModule } from '@modules/college-office/college-office.module';
import { CollegeModule } from '@modules/college/college.module';
import { CommonPagesModule } from '@modules/common-pages/common-pages.module';
import { CustomListModule } from '@modules/custom-list/custom-list.module';
import { DashboardModule } from '@modules/dashboard/dashboard.module';
import { DistrictModule } from '@modules/district/district.module';
import { ECommerceModule } from '@modules/e-commerce/e-commerce.module';
import { GrantModule } from '@modules/grant/grant.module';
import { CmoModule } from '@modules/institution-cmo/cmo.module';
import { StateDepartmentModule } from '@modules/institution-state-department/state-department.module';
import { ListCreateModule } from '@modules/list-create/list-create.module';
import { ListModule } from '@modules/list/list.module';
import { ProfileModule } from '@modules/profile/profile.module';
import { QuickSearchModule } from '@modules/quick-search/quick-search.module';
import { SchoolModule } from '@modules/school/school.module';
import { ViewModule } from '@modules/view/view.module';

import { NotFoundPageComponent } from '@core/pages/not-found-page/not-found-page.component';
import { ListLargeDownloadPageComponent } from '@modules/list/pages/list-large-download-page/list-large-download-page.component';

import { TermsOfServiceDataGuard } from '@core/guards/terms-of-service-data.guard';

import { AdminAccountGuard } from '@core/guards/admin-account.guard';
import { BidsPermissionsGuard } from '@core/guards/bids-permissions.guard';
import { BrokersTermsOfUseDataGuard } from '@core/guards/brokers-terms-of-use-data.guard';
import { CheckUserDataExistGuard } from '@core/guards/check-user-data.guard';
import { DashboardShowNotificationGuard } from '@core/guards/dashboard-show-notification.guard';
import { GrantsPermissionsGuard } from '@core/guards/grants-permissions.guard';
import { IsNotBrokerGuard } from '@core/guards/is-not-broker.guard';
import { OrderDataGuard } from '@core/guards/order-data.guard';
import { PrivacyPolicyDataGuard } from '@core/guards/privacy-policy-data.guard';
import { QuickSearchPermissionsGuard } from '@core/guards/quick-search-permissions.guard';
import { TermsOfUseDataGuard } from '@core/guards/terms-of-use-data.guard';
import { TosGuard } from '@core/guards/tos.guard';
import { UnacceptedGeneralTermsByBrokerGuard } from '@core/guards/unaccepted-general-terms-by-broker.guard';
import { AuthGuard } from '@modules/auth/guards/auth.guard';
import { CreditsGuard } from '@modules/credits/guards/credits.guard';

import { CORE_PATHS } from '@core/constants/core-paths';


const authGuardRedirect: string = `/${ CORE_PATHS.AUTH }`;
const tosGuardRedirect: string = `/${ CORE_PATHS.TOS }`;

const appRoutes: Routes = [{
  path: CORE_PATHS.EMPTY,
  redirectTo: CORE_PATHS.DASHBOARD,
  pathMatch: CORE_PATHS.FULL
}, {
  path: CORE_PATHS.AUTH,
  loadChildren: () => import('@modules/auth-pages/auth-pages.module').then((m: { AuthPagesModule: AuthPagesModule }) => m.AuthPagesModule),
}, {
  path: CORE_PATHS.ACCOUNT,
  loadChildren: () => import('@modules/profile/profile.module').then((m: { ProfileModule: ProfileModule }) => m.ProfileModule),
  canLoad: [AuthGuard],
  canActivate: [AuthGuard, TosGuard, CreditsGuard, UnacceptedGeneralTermsByBrokerGuard]
}, {
  path: CORE_PATHS.DASHBOARD,
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, DashboardShowNotificationGuard, UnacceptedGeneralTermsByBrokerGuard],
  loadChildren: () => import('@modules/dashboard/dashboard.module').then((m: { DashboardModule: DashboardModule }) => m.DashboardModule),
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.LIST_CREATE,
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  loadChildren: () => import('@modules/list-create/list-create.module')
    .then((m: { ListCreateModule: ListCreateModule }) => m.ListCreateModule),
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.LIST,
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  loadChildren: () => import('@modules/list/list.module').then((m: { ListModule: ListModule }) => m.ListModule),
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.CUSTOM_LIST,
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  loadChildren: () => import('@modules/custom-list/custom-list.module')
    .then((m: { CustomListModule: CustomListModule }) => m.CustomListModule)
}, {
  path: CORE_PATHS.CDL_LIST,
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  loadChildren: () => import('./modules/cdl/cdl-list/cdl-list.module')
    .then((m: { CdlListModule: CdlListModule }) => m.CdlListModule)
}, {
  path: CORE_PATHS.VIEW,
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  loadChildren: () => import('@modules/view/view.module').then((m: { ViewModule: ViewModule }) => m.ViewModule),
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.QUICK_SEARCH,
  canLoad: [AuthGuard, CheckUserDataExistGuard, QuickSearchPermissionsGuard],
  canActivate: [TosGuard, QuickSearchPermissionsGuard, UnacceptedGeneralTermsByBrokerGuard],
  loadChildren: () => import('@modules/quick-search/quick-search.module')
    .then((m: { QuickSearchModule: QuickSearchModule }) => m.QuickSearchModule),
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.SCHOOL,
  loadChildren: () => import('@modules/school/school.module').then((m: { SchoolModule: SchoolModule }) => m.SchoolModule),
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.DISTRICT,
  loadChildren: () => import('@modules/district/district.module').then((m: { DistrictModule: DistrictModule }) => m.DistrictModule),
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.COLLEGE,
  loadChildren: () => import('@modules/college/college.module').then((m: { CollegeModule: CollegeModule }) => m.CollegeModule),
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect }
}, {
  path: CORE_PATHS.COLLEGE_OFFICE,
  loadChildren: () => import('@modules/college-office/college-office.module')
    .then((m: { CollegeOfficeModule: CollegeOfficeModule }) => m.CollegeOfficeModule),
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect }
}, {
  path: CORE_PATHS.CMO,
  loadChildren: () => import('@modules/institution-cmo/cmo.module').then((m: { CmoModule: CmoModule }) => m.CmoModule),
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.STATE_DEPARTMENT,
  loadChildren: () => import('@modules/institution-state-department/state-department.module')
    .then((m: { StateDepartmentModule: StateDepartmentModule }) => m.StateDepartmentModule),
  canLoad: [AuthGuard, CheckUserDataExistGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect, tosGuardRedirect }
}, {
  path: CORE_PATHS.GRANT,
  loadChildren: () => import('@modules/grant/grant.module').then((m: { GrantModule: GrantModule }) => m.GrantModule),
  canLoad: [AuthGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect }
}, {
  path: CORE_PATHS.BID,
  loadChildren: () => import('@modules/bid/bid.module').then((m: { BidModule: BidModule }) => m.BidModule),
  canLoad: [AuthGuard],
  canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
  data: { authGuardRedirect }
}, {
  path: CORE_PATHS.ECOMM,
  canActivate: [AuthGuard, IsNotBrokerGuard],
  loadChildren: () => import('@modules/e-commerce/e-commerce.module').then((m: { ECommerceModule: ECommerceModule }) => m.ECommerceModule),
}, {
  path: CORE_PATHS.COMMON,
  canActivate: [CheckUserDataExistGuard],
  loadChildren: () => import('@modules/common-pages/common-pages.module')
    .then((m: { CommonPagesModule: CommonPagesModule }) => m.CommonPagesModule)
}, {
  path: CORE_PATHS.DOWNLOAD_FROM_EMAIL,
  component: ListLargeDownloadPageComponent
}, {
  path: CORE_PATHS.NOT_FOUND,
  component: NotFoundPageComponent,
  canActivate: [CheckUserDataExistGuard],
}, {
  path: CORE_PATHS.OTHER,
  component: NotFoundPageComponent,
  canActivate: [CheckUserDataExistGuard],
}];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [
    CheckUserDataExistGuard,
    TermsOfServiceDataGuard,
    BrokersTermsOfUseDataGuard,
    TermsOfUseDataGuard,
    PrivacyPolicyDataGuard,
    OrderDataGuard,
    AdminAccountGuard,
    TosGuard,
    QuickSearchPermissionsGuard,
    GrantsPermissionsGuard,
    BidsPermissionsGuard,
    IsNotBrokerGuard,
    UnacceptedGeneralTermsByBrokerGuard
  ]
})
export class AppRoutingModule {
}
