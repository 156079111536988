<ng-content select=".submenu-target"></ng-content>

<div #contentWrap
     [class.float-by-right-side]="outsideTheScreenFromTheRight"
     [class.is--open]="isOpened"
     class="content valign--{{ valign }} align--{{ align }} theme--{{ theme }}">

  <ng-content *ngIf="isOpened" select=".submenu-content"></ng-content>

</div>
