import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverlayComponent } from './components/overlay/overlay.component';

import { OverlayService } from './providers/overlay.service';

@NgModule({
  imports: [CommonModule],
  providers: [OverlayService],
  declarations: [OverlayComponent],
  exports: [OverlayComponent]
})
export class OverlayModule {
}
