import { Inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { interval, BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, share } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';

import { IWebSocketConfig, IWebSocketUpdateConfigValues, IWsMessage, WEB_SOCKET_CONFIG_TOKEN } from '../data';

@Injectable()
export class WebSocketsProvider {

  private _webSocket$: WebSocketSubject<IWsMessage<any>>;
  private _connectedStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _reconnectSub$: Subscription;
  reconnectingTrigger$: Subject<void> = new Subject<void>();

  get status(): Observable<boolean> {
    return this._connectedStatus$
      .asObservable()
      .pipe(
        share(),
        distinctUntilChanged()
      );
  }

  constructor(@Inject(WEB_SOCKET_CONFIG_TOKEN) private _config: IWebSocketConfig) {
  }

  connect(isReconnectByInterval: boolean = false): void {
    if (this._webSocket$) {
      this.disconnect(false);
    }

    this._webSocket$ = new WebSocketSubject({
      url: `${ this._config.url }${ this._config.token ? '?token=' + this._config.token : '' }`,
      openObserver: {
        next: () => {
          this._connectedStatus$.next(true);

          if (isReconnectByInterval) {
            this.reconnectingTrigger$.next();
          }
        }
      },
      closeObserver: { next: () => this._connectedStatus$.next(false) }
    });

    this._webSocket$
      .pipe(
        share(),
        distinctUntilChanged()
      ).subscribe();
  }

  reconnectByInterval(): void {
    const reconnectIntervalObservable$: Observable<number> = interval(this._config.reconnectInterval);

    this._reconnectSub$ = reconnectIntervalObservable$
      .subscribe({
        next: () => this.connect(true)
      });
  }

  updateConfigValues(newConfigValues: IWebSocketUpdateConfigValues): void {
    this._config = { ...this._config, ...newConfigValues } as IWebSocketConfig;
    this.connect();

    if (this._config.reconnectInterval) {
      this.reconnectByInterval();
    }
  }

  disconnect(isRemoveSubReconnectByInterval: boolean = true): void {
    if (this._webSocket$) {
      this._webSocket$.unsubscribe();
      this._webSocket$.complete();
      this._webSocket$ = null;
    }

    if (isRemoveSubReconnectByInterval && this._reconnectSub$) {
      this._reconnectSub$.unsubscribe();
    }
  }

  on<T>(event: string): Observable<T> {
    if (!this._webSocket$) {
      this.connect();
    }

    if (!event && !environment.production) {
      console.log('Subscribe Error incorrect event name or connection is failed ', event);
    }

    return this._webSocket$.pipe(
      filter((message: IWsMessage<T>) => !!event && message.event === event),
      map((message: IWsMessage<T>) => message.data as T)
    );
  }

  send(event: string, data: any = {}): void {
    if (event && this._webSocket$) {
      this._webSocket$.next(<any>JSON.stringify({ event, data }));
    } else {
      if (!environment.production) {
        console.error('Send Error ', event, data);
      }
    }
  }
}
