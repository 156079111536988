<div [formGroup]="formGroup">

  <div class="row">

    <div class="col-12">
      <bl-server-errors [noScroll]="true"
                        [error]="generalError">
      </bl-server-errors>
    </div>

    <div class="col-12 mb-5">
      <div class="select-wrapper" #selectWrapperEl>
        <bl-flat-select formControlName="countryCode"
                        optionsOrderName="label"
                        placeholder="Country*"
                        [isDataPrivate]="true"
                        [theme]="flatInputTheme.MAT_LIKE"
                        [options]="countries"
                        [errors]="formGroup.get('countryCode').errors"
                        [dropdownWidth]="selectWidth"
                        (select)="onSelectCountry()">
        </bl-flat-select>
      </div>
    </div>

    <div class="col-12 mb-5" *ngIf="countryStates.length">
      <div class="select-wrapper">
        <bl-flat-select formControlName="stateCode"
                        placeholder="State/Region*"
                        [isDataPrivate]="true"
                        [options]="countryStates"
                        [errors]="formGroup.get('stateCode').errors"
                        [dropdownWidth]="selectWidth"
                        [theme]="flatInputTheme.MAT_LIKE"
                        (select)="onSelectState()">
        </bl-flat-select>
      </div>
    </div>

    <div class="col-12 mb-5" *ngIf="!countryStates.length">
      <bl-flat-input formControlName="stateCode"
                     placeholder="State/Region*"
                     [isDataPrivate]="true"
                     [errors]="formGroup.get('stateCode').errors"
                     [theme]="flatInputTheme.MAT_LIKE">
      </bl-flat-input>
    </div>

    <div class="col-12 mb-5">
      <bl-flat-input formControlName="postalCode"
                     placeholder="ZIP/Postal Code*"
                     [isDataPrivate]="true"
                     [mask]="zipCodeMask"
                     [errors]="formGroup.get('postalCode').errors"
                     [theme]="flatInputTheme.MAT_LIKE">
      </bl-flat-input>
    </div>

    <div class="col-12 mb-5">
      <bl-flat-input formControlName="city"
                     placeholder="City*"
                     [isDataPrivate]="true"
                     [errors]="formGroup.get('city').errors"
                     [theme]="flatInputTheme.MAT_LIKE">
      </bl-flat-input>
    </div>

    <div class="col-12 mb-5">
      <bl-flat-input formControlName="addressFirst"
                     placeholder="Address 1*"
                     [isDataPrivate]="true"
                     [errors]="formGroup.get('addressFirst').errors"
                     [theme]="flatInputTheme.MAT_LIKE">
      </bl-flat-input>
    </div>

    <div class="col-12 mb-5">
      <bl-flat-input formControlName="addressSecond"
                     placeholder="Address 2"
                     [isDataPrivate]="true"
                     [errors]="formGroup.get('addressSecond').errors"
                     [theme]="flatInputTheme.MAT_LIKE">
      </bl-flat-input>
    </div>

  </div>

</div>
