export function autoscroll(position: number = 0, behavior: 'smooth' | 'auto' = 'smooth'): void {

  if (window.scrollTo) {
    window.scrollTo({
      behavior,
      left: 0,
      top: position
    });
  } else {
    document.body.scrollTop = position;
    document.documentElement.scrollTop = position;
  }
}
