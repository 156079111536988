import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bl-assign-pop-up',
  templateUrl: './assign-pop-up.component.html',
  styleUrls: ['./assign-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignPopUpComponent {
  @Output() close: EventEmitter<Event> = new EventEmitter<any>();
  @Output() confirm: EventEmitter<Event> = new EventEmitter<any>();

  onCloseBtn(event: Event): void {
    this.close.emit(event);
  }

  onConfirm(event: Event): void {
    this.confirm.emit(event);
  }
}
