import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'bl-mobile-table-row',
  templateUrl: './mobile-table-row.component.html',
  styleUrls: ['./mobile-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTableRowComponent {
  @Input() template: TemplateRef<any>;
  @Input() data: any;
}
