import { IInvitationUser } from '../interfaces/invitation';

import { INVITATION_ACTION } from '../constants/invitation';

export class MapInvitation {
  token: string;
  action: INVITATION_ACTION;
  accountName: string;
  email: string;
  newOwnerId: number;

  constructor(invitation: IInvitationUser) {
    const { token, action, email, accountName, newOwnerId }: IInvitationUser = invitation;

    this.token = token;
    this.action = action;
    this.accountName = accountName;
    this.email = email ? email : null;
    this.newOwnerId = newOwnerId ? newOwnerId : null;
  }
}
