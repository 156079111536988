import { AUTH_PATHS } from '@modules/auth/constants/auth-paths';

import { CORE_PATHS } from './core-paths';


export const ROUTES_TO_HIDE_LAYOUT: Array<string> = [
  `${ CORE_PATHS.AUTH }/${ AUTH_PATHS.SIGN_UP_IN_FRAME }`,
  `${ CORE_PATHS.AUTH }/${ AUTH_PATHS.SIGN_UP_IN_FRAME_SUCCESS }`
];

export const ROUTER_TO_SHOW_MOBILE_FOOTER: Array<string> = [
  `${ CORE_PATHS.AUTH }/${ AUTH_PATHS.SIGN_UP }`,
  `${ CORE_PATHS.AUTH }/${ AUTH_PATHS.SIGN_UP_BY_INVITE }`
];
