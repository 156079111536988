import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[blTrimOnBlur]'
})
export class TrimOnBlurDirective {

  constructor(private _el: ElementRef, @Optional() private ngControl: NgControl) {
  }

  @HostListener('blur') onBlur(): void {
    this.ngControl.control.setValue(this._el.nativeElement.value.trim());
  }

}
