import { createSelector, MemoizedSelector } from '@ngrx/store';

import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { CoreState } from '../reducers';
import { url, IDownloadState } from '../reducers/download.reducer';

import { IDownloadByLink } from '@core/interfaces/download-by-link';

export const getDownloadState: GetFromStateInSelectors<CoreState, IDownloadState> = (state: CoreState) => state.download;

export const getDownloadUrl: MemoizedSelector<CoreState, IDownloadByLink> = createSelector(getDownloadState, url);
