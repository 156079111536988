import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { openAddUserPopUpAction } from '../../store/actions/add-user-account.action';
import { openEditUserPopUpAction } from '../../store/actions/edit-user-account.action';
import { getMapAccountUsersAddOns, getSelfAccountDataForEditPopUp } from '../../store/selectors/account-users.selector';
import { getAccountSeatsWithComparedAddOns } from '../../store/selectors/account.selector';
import { getIsFirstAssignAvailable } from '../../store/selectors/profile.selector';

import { StorageService } from '@core/services/storage.service';
import { ViewPointsService } from '@ui/view-points/services/view-points.service';

import { accountUserToEditUser } from '../../utils/map-forms';

import { IAccountSeat, IAccountUser, IEditAccountUser } from '../../interfaces/marketview';

import { WINDOW_POINTS } from '@ui/view-points/constants/view-points';
import { RolesKeys } from '../../constants/roles';


@Component({
  selector: 'bl-products-and-users-container',
  templateUrl: './products-and-users-container.component.html',
  styleUrls: ['./products-and-users-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsAndUsersContainerComponent implements OnInit, OnDestroy {

  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;
  readonly SEARCHABLE_KEYS: string[] = ['firstName', 'lastName', 'fullName'];
  readonly ROLE_KEYS: typeof RolesKeys = RolesKeys;

  private _destroyer$: Subject<void> = new Subject();

  accountSeats$: Observable<IAccountSeat[]> = this._store.pipe(select(getAccountSeatsWithComparedAddOns));
  getMapAccountUsersAddOns$: Observable<IAccountUser[]> = this._store.pipe(select(getMapAccountUsersAddOns));
  isFirstAssignMarketView$: Observable<boolean> = this._store.pipe(select(getIsFirstAssignAvailable));

  isMobile: boolean;
  isShowProducts: boolean = true;
  tableSearch: string = '';

  constructor(private _store: Store<CoreState>,
              private _viewPointsService: ViewPointsService,
              private _cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.isFirstAssignMarketView$
      .pipe(
        takeUntil(this._destroyer$),
        take(1),
        withLatestFrom(this._store.pipe(select(getSelfAccountDataForEditPopUp))),
        filter(([isFirstAssignMarketView, user]: [boolean, any]) => {
          return isFirstAssignMarketView && !StorageService.doNotShowEditSelfPopUp;
        })
      )
      .subscribe(([isFirstAssignMarketView, user]: [boolean, any]) => {
        StorageService.doNotShowEditSelfPopUp = true;
        this._store.dispatch(openEditUserPopUpAction(user as IEditAccountUser));
      });

    this._viewPointsService.listenerOnHideByPoints(this.windowPoints.PHONE, this.windowPoints.TABLET)
      .pipe(takeUntil(this._destroyer$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
        this._cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  changeActiveTab(isShow: boolean): void {
    this.isShowProducts = isShow;
  }

  handlerSearch(value: string): void {
    this.tableSearch = value;
  }

  openAddUserPopUp(): void {
    this._store.dispatch(openAddUserPopUpAction());
  }

  openEditUserPopUp(user: IEditAccountUser): void {
    this._store.dispatch(openEditUserPopUpAction(user));
  }

  openEditUserFromMobile(user: IAccountUser): void {
    const editUserData: IEditAccountUser = accountUserToEditUser(user);
    this.openEditUserPopUp(editUserData);
  }
}
