<bl-drop-down [bodyPosition]="'center'" [theme]="'per-page-dropdown'" #dropDownRef>
  <ng-template blDropDownTarget>

    <span class="limit">{{ limit }} per page</span>&nbsp;
    <i class="icon icon-arrow-subcontainer--{{ dropDownRef.isOpen ? 'up' : 'down' }}"></i>

  </ng-template>

  <ng-template blDropDownContent>

    <ng-container *ngFor="let newLimit of limits">
      <button class="item"
              type="button"
              *ngIf="newLimit?.toString() !== limit?.toString()"
              (click)="onChangeLimit.emit(newLimit)"
              matRipple>
        {{ newLimit }}
      </button>

    </ng-container>

  </ng-template>

</bl-drop-down>
