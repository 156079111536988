import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import {
  accountUsers,
  accountUsersError,
  isHasProductsOrCredits,
  mapAccountUsersAddOns,
  pending,
  selfAccount,
  selfAccountDataForEditPopUp,
  usersWithoutSelf
} from '../reducers/account-users.reducer';

import { IAccountUser, IAccountUsers, IEditAccountUser } from '@modules/profile/interfaces/marketview';
import { IServerError } from '@shared/interfaces/server-error';

import { getIsHasProducts } from './account.selector';
import { selectAccountUsersState } from './index';
import { getIsUnlimited, getProfileUserId } from './profile.selector';


export const getAccountUsers: MemoizedSelector<CoreState, IAccountUsers> = createSelector(selectAccountUsersState, accountUsers);
export const getAccountUsersPending: MemoizedSelector<CoreState, boolean> = createSelector(selectAccountUsersState, pending);
export const getAccountUsersError: MemoizedSelector<CoreState, IServerError> = createSelector(selectAccountUsersState, accountUsersError);

export const getUsersWithoutSelf: MemoizedSelector<CoreState, IAccountUsers> = createSelector(getAccountUsers, getProfileUserId,
  usersWithoutSelf);
export const getSelfAccount: MemoizedSelector<CoreState, IAccountUser> = createSelector(getAccountUsers, getProfileUserId, selfAccount);
export const getIsHasProductsOrCredits: MemoizedSelector<CoreState, boolean> = createSelector(getIsHasProducts, getIsUnlimited,
  getSelfAccount, isHasProductsOrCredits);
export const getSelfAccountDataForEditPopUp: MemoizedSelector<CoreState, IEditAccountUser> = createSelector(getSelfAccount,
  selfAccountDataForEditPopUp);

export const getMapAccountUsersAddOns: MemoizedSelector<CoreState, IAccountUsers> = createSelector(getAccountUsers, mapAccountUsersAddOns);
