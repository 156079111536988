import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BeautyDatePipe } from './pipes/beauty-date.pipe';
import { DateMaskPipe } from './pipes/date-mask.pipe';
import { SToMSDatePipe } from './pipes/s-to-ms-date.pipe';
import { ShortDatePipe } from './pipes/short-date.pipe';

const _pipes: any = [
  DateMaskPipe,
  SToMSDatePipe,
  ShortDatePipe,
  BeautyDatePipe
];

@NgModule({
  declarations: [
    ..._pipes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ..._pipes
  ]
})
export class DatePipesModule {
}
