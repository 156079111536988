<label [attr.data-testid]="name | e2e" class="custom-radio">

  <input type="radio"
         [name]="name"
         (change)="switch($event)"
         [checked]="checked">

  <span class="custom-radio__fake-input">{{ label }}</span>

  <!--TODO return when text be ready-->
  <!--<i *ngIf="description"-->
  <!--class="icon-tooltip"-->
  <!--[blTooltip]="description"></i>-->

</label>
