import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AbsPipe } from './pipes/abs.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { NumberMaskPipe } from './pipes/number-mask.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';


const _pipes: any = [
  ShortNumberPipe,
  NumberMaskPipe,
  DecimalPipe,
  AbsPipe
];

const _providers: any[] = [
  ShortNumberPipe
];

@NgModule({
  declarations: [
    ..._pipes
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ..._pipes
  ],
  providers: [
    ..._providers
  ]
})
export class NumberPipesModule {
}
