import { Component, Inject } from '@angular/core';

import { PopUpText } from '../../models/pop-up-data';

import { POP_UP_DATA } from '../../injection-tokens';

@Component({
  selector: 'bl-wait-for-list-pop-up-content',
  templateUrl: './wait-for-list-pop-up-content.component.html',
  styleUrls: ['./wait-for-list-pop-up-content.component.scss']
})
export class WaitForListPopUpContentComponent {

  constructor(@Inject(POP_UP_DATA) public data: PopUpText) {
  }
}
