import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ZipCodeMaskPipe } from '../../../pipes/shared-pipes/pipes/zip-code-mask.pipe';

import { IControlOption } from '@shared/interfaces/forms';

import { FLAT_INPUT_THEME } from '@shared/constants/flat-input';

@Component({
  selector: 'bl-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AddressFormComponent {
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  @ViewChild('selectWrapperEl') selectWrapperEl: ElementRef;

  @Input() formGroup: FormGroup;
  @Input() countries: IControlOption[];
  @Input() countryStates: IControlOption[];

  @Output() countryChanged: EventEmitter<void> = new EventEmitter<void>();

  zipCodeMask: ZipCodeMaskPipe;

  get generalError(): string {
    return this.formGroup.errors && this.formGroup.errors['general'];
  }

  get selectWidth(): number {
    if (!this.selectWrapperEl) {
      return 0;
    }

    return this.selectWrapperEl.nativeElement.clientWidth;
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  onSelectState(): void {
    this.cdr.detectChanges();
  }

  onSelectCountry(): void {
    this.formGroup.patchValue({ stateCode: '' });
    this.countryChanged.emit();
  }
}
