import { environment } from '@env/environment';

import { RecaptchaSettings } from 'ng-recaptcha';

export const CAPTCHA_URL: string = 'https://www.google.com/recaptcha/api.js';
export const CAPTCHA_KEY: string = '6LcQx4sUAAAAABRm6_eupuPVNPf9qhUbgoLiXSOY';
export const CAPTCHA_KEY_PROD: string = '6LeiDpEUAAAAAFRadWmw1MHlU6S-QQDziqElky6C';

export const CAPTCHA_VALUE: RecaptchaSettings = {
  siteKey: environment.production && environment.envName === 'prod' ? CAPTCHA_KEY_PROD : CAPTCHA_KEY
};
