import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { IListAppendsCalculated } from '@modules/list-appends/interfaces/appends';

import { CORE_PATHS } from '@core/constants/core-paths';
import { LIST_PATHS } from '@modules/list-common-store/constants/list-paths';

@Component({
  selector: 'bl-active-list-appends-success',
  templateUrl: './active-list-appends-success.component.html',
  styleUrls: ['./active-list-appends-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveListAppendsSuccessComponent {

  constructor(public snackBarRef: MatSnackBarRef<ActiveListAppendsSuccessComponent>,
              @Inject(MAT_SNACK_BAR_DATA) public data: IListAppendsCalculated) {
  }

  get redirectUrl(): string[] {
    return [CORE_PATHS.LIST, this.data.listingId.toString(), LIST_PATHS.APPEND];
  }
}
