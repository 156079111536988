import { IListsStatus, IListStatuses } from '../../interfaces/list';
import { ICDLLists } from '../../interfaces/manage-account-lists';

import { LIST_REDIRECT_TO, LIST_STATUS_ID } from './list-statuses';
import { LIST_STATUS_ID_NAME } from './list-types';

export enum CDL_LIST_AVAILABLE_TO_ACTIONS {
  EDIT_INSTITUTIONS = 'editInstitutions',
  EDIT_PERSONNEL = 'editPersonnel',
  EDIT_ADD_ONS = 'editAddOns',
  CUSTOMIZE_OUTPUT = 'customizeOutput',
  VIEW_COUNT_SUMMARY = 'viewCountSummary',
  EXPORT_COUNT_SUMMARY = 'exportCountSummary',
  ACTIVE = 'activate',
  DEACTIVATE = 'deactivate',
  DUPLICATE = 'duplicate',
  CREATE_CUSTOM_LIST = 'createCustomList',
  RENAME = 'rename',
  DELETE = 'delete',
  SYNC = 'sync',
  DOWNLOAD = 'download',
  CREATE_LIST = 'create',
  VIEW_SETTINGS = 'viewSettings'
}

const CDL_LIST_ACTIVE_STATUS: Readonly<IListsStatus> = Object.freeze({
  id: LIST_STATUS_ID.ACTIVE,
  status: LIST_STATUS_ID_NAME.ACTIVE,
  canEdit: false,
  redirectTo: LIST_REDIRECT_TO.SUMMARY,
});

const CDL_LIST_STATUSES_AS_ADMIN: IListsStatus[] = [{
  id: LIST_STATUS_ID.SAVED_FOR_LATER,
  status: LIST_STATUS_ID_NAME.SAVED_FOR_LATER,
  canEdit: true,
  availableTo: [
    CDL_LIST_AVAILABLE_TO_ACTIONS.EDIT_INSTITUTIONS,
    CDL_LIST_AVAILABLE_TO_ACTIONS.EDIT_PERSONNEL,
    CDL_LIST_AVAILABLE_TO_ACTIONS.EDIT_ADD_ONS,
    CDL_LIST_AVAILABLE_TO_ACTIONS.CUSTOMIZE_OUTPUT,
    CDL_LIST_AVAILABLE_TO_ACTIONS.VIEW_COUNT_SUMMARY,
    CDL_LIST_AVAILABLE_TO_ACTIONS.EXPORT_COUNT_SUMMARY,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DUPLICATE,
    CDL_LIST_AVAILABLE_TO_ACTIONS.RENAME,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DELETE,
    CDL_LIST_AVAILABLE_TO_ACTIONS.VIEW_SETTINGS
  ],
  redirectTo: LIST_REDIRECT_TO.CAN_NOT_REDIRECT,
}, {
  id: LIST_STATUS_ID.PROCESSING,
  status: LIST_STATUS_ID_NAME.PROCESSING,
  canEdit: false,
  availableTo: [],
  redirectTo: LIST_REDIRECT_TO.CAN_NOT_REDIRECT,
}, {
  id: LIST_STATUS_ID.STATUS_PROCESSING_WITH_PERCENTS,
  status: LIST_STATUS_ID_NAME.STATUS_PROCESSING_WITH_PERCENTS,
  canEdit: false,
  availableTo: [],
  redirectTo: LIST_REDIRECT_TO.CAN_NOT_REDIRECT,
  shouldCheckStatus: true
}, {
  id: LIST_STATUS_ID.ACTIVE,
  status: LIST_STATUS_ID_NAME.ACTIVE,
  canEdit: false,
  availableTo: [
    CDL_LIST_AVAILABLE_TO_ACTIONS.SYNC,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DOWNLOAD,
    CDL_LIST_AVAILABLE_TO_ACTIONS.CREATE_LIST,
    CDL_LIST_AVAILABLE_TO_ACTIONS.CUSTOMIZE_OUTPUT,
    CDL_LIST_AVAILABLE_TO_ACTIONS.VIEW_COUNT_SUMMARY,
    CDL_LIST_AVAILABLE_TO_ACTIONS.EXPORT_COUNT_SUMMARY,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DEACTIVATE,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DUPLICATE,
    CDL_LIST_AVAILABLE_TO_ACTIONS.CREATE_CUSTOM_LIST,
    CDL_LIST_AVAILABLE_TO_ACTIONS.RENAME,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DELETE,
    CDL_LIST_AVAILABLE_TO_ACTIONS.VIEW_SETTINGS
  ],
  redirectTo: LIST_REDIRECT_TO.SUMMARY,
}, {
  id: LIST_STATUS_ID.EXPIRED,
  status: LIST_STATUS_ID_NAME.EXPIRED,
  canEdit: false,
  availableTo: [
    CDL_LIST_AVAILABLE_TO_ACTIONS.VIEW_COUNT_SUMMARY,
    CDL_LIST_AVAILABLE_TO_ACTIONS.EXPORT_COUNT_SUMMARY,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DUPLICATE,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DELETE
  ],
  redirectTo: LIST_REDIRECT_TO.SUMMARY
}];

const CDL_LIST_STATUSES: IListsStatus[] = [{
  ...CDL_LIST_ACTIVE_STATUS,
  availableTo: [
    CDL_LIST_AVAILABLE_TO_ACTIONS.RENAME,
    CDL_LIST_AVAILABLE_TO_ACTIONS.DELETE,
  ]
}];

export const CDL_LIST_STATUSES_MAP: IListStatuses = CDL_LIST_STATUSES
  .reduce((res: {}, status: IListsStatus) => {
    res[status.id] = status;
    return res;
  }, {});

export const CDL_LIST_STATUSES_FOR_ADMIN_MAP: IListStatuses = CDL_LIST_STATUSES_AS_ADMIN
  .reduce((res: {}, status: IListsStatus) => {
    res[status.id] = status;
    return res;
  }, {});

const cdlListStatuses: (asAdmin: boolean, cdlPermissions: ICDLLists) => IListsStatus[] =
  (asAdmin: boolean, cdlPermissions: ICDLLists): IListsStatus[] => {
  return asAdmin ? CDL_LIST_STATUSES_AS_ADMIN.map((item: IListsStatus) => new MapCDLList(item, cdlPermissions, asAdmin)) :
    CDL_LIST_STATUSES.map((item: IListsStatus) => new MapCDLList(item, cdlPermissions));
};

export const cdlListStatusesMap: (asAdmin: boolean, cdlPermissions: ICDLLists) => IListStatuses =
  (asAdmin: boolean, cdlPermissions: ICDLLists): IListStatuses => {
  return cdlListStatuses(asAdmin, cdlPermissions)
    .reduce((res: {}, status: IListsStatus) => {
      res[status.id] = status;
      return res;
    }, {});
};

export const STATUSES_FOR_SUMMARY: LIST_STATUS_ID[] = [LIST_STATUS_ID.ACTIVE, LIST_STATUS_ID.EXPIRED];

// use for external users
class MapCDLList {
  id: LIST_STATUS_ID;
  status: LIST_STATUS_ID_NAME;
  canEdit: boolean;
  availableTo: string[];

  constructor(item: IListsStatus, cdlPermissions: ICDLLists, isAdmin?: boolean) {
    const { id, status, canEdit, availableTo }: IListsStatus = item;

    this.id = id;
    this.status = status;
    this.canEdit = canEdit;

    if (isAdmin) {
      const canBeActivated: boolean = !availableTo.includes(CDL_LIST_AVAILABLE_TO_ACTIONS.ACTIVE)
        && id === LIST_STATUS_ID.SAVED_FOR_LATER
        && cdlPermissions.canActivate;

      this.availableTo = availableTo;

      if (canBeActivated) {
        this.availableTo.splice(6, 0, CDL_LIST_AVAILABLE_TO_ACTIONS.ACTIVE);
      }
    } else {
      this.availableTo = id === LIST_STATUS_ID.ACTIVE ? mapAvailableActions(cdlPermissions) : [];
    }
  }
}

const mapAvailableActions: (cdlPermissions: ICDLLists) => string[] = (cdlPermissions: ICDLLists): string[] => {
  let result: CDL_LIST_AVAILABLE_TO_ACTIONS[] = [];

  if (cdlPermissions && cdlPermissions.canViewAndCreateLists) {
    result = [CDL_LIST_AVAILABLE_TO_ACTIONS.CREATE_LIST];
  }

  if (cdlPermissions && cdlPermissions.canAccessAllActions) {
    result = [
      CDL_LIST_AVAILABLE_TO_ACTIONS.SYNC,
      CDL_LIST_AVAILABLE_TO_ACTIONS.DOWNLOAD,
      CDL_LIST_AVAILABLE_TO_ACTIONS.CREATE_LIST,
      CDL_LIST_AVAILABLE_TO_ACTIONS.CREATE_CUSTOM_LIST,
      CDL_LIST_AVAILABLE_TO_ACTIONS.VIEW_SETTINGS,
      CDL_LIST_AVAILABLE_TO_ACTIONS.RENAME,
      CDL_LIST_AVAILABLE_TO_ACTIONS.DELETE,
    ];
  }

  return result;
};

export enum CDL_CHANGE_STATUS_ACTION {
  ACTIVATE,
  DEACTIVATE
}

export const DISALLOWED_FOR_EDIT_TYPES: LIST_STATUS_ID[] = [LIST_STATUS_ID.EXPIRED];
