import { createSelector, MemoizedSelector } from '@ngrx/store';

import { CoreState } from '@core/store/reducers';
import { GetFromStateInSelectors } from '@shared/store/types/selector.types';
import { invitationToken, IInvitationState } from '../reducers/invitation.reducer';

import { IInvitationUser } from '@modules/auth/interfaces/invitation';

export const invitationUserState: GetFromStateInSelectors<CoreState, IInvitationState> = (state: CoreState): IInvitationState =>
  state.invitation;
export const getInvitationExistedUser: MemoizedSelector<CoreState, IInvitationUser> = createSelector(invitationUserState, invitationToken);
