import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@env/environment';

import { AppModule } from 'app/app.module';

import { RecaptchaComponent } from 'ng-recaptcha';


if (environment.production) {
  enableProdMode();
}

declare function loadDriftWidget(): void;

// fix for console error when captcha component destroy
RecaptchaComponent.prototype.ngOnDestroy = function (): void {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};

platformBrowserDynamic()
  .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .then(() => loadDriftWidget())
  .catch((err: any) => console.log(err));
