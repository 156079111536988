<div class="owner-cloud-sync-platform-select-popup">
  <div class="close-btn" (click)="onCloseClick()">
    <i class="icon-x-medium"></i>
  </div>
  <div class="pop-up-icon-section"></div>
  <div class="text-section">
    <p class="title">
      You have MDR CloudSync!
    </p>
    <p class="description">
      Select the platform(s) you would like to sync your MDR data to. Note: Once you select a platform it will
      remain for the duration of your subscription. To add additional platforms call (800) 333-8802.
    </p>
  </div>
  <div class="selector-section">

    <bl-select placeholder="Select a platform..."
               trackBy="id"
               theme="white"
               [options]="data.platforms"
               [template]="dropdownOptionLayout"
               [selectedTemplate]="dropdownSelectedLayout"
               (select)="onPlatformSelect($event)">
    </bl-select>

    <ng-template #dropdownOptionLayout
                 let-name="name"
                 let-logo="logoUrl">
      <div class="platform">
        <img class="platform-image"
             [src]="logo"
             [attr.alt]="logo"/>
        <span class="platform-name">{{ name }}</span>
      </div>
    </ng-template>

    <ng-template #dropdownSelectedLayout
                 let-name="name"
                 let-logo="logoUrl">
      <div class="platform">
        <img class="platform-image"
             [src]="logo"
             [attr.alt]="logo"/>
        <span class="platform-name selected">{{ name }}</span>
      </div>
    </ng-template>

  </div>
  <div class="btn-section">
    <button type="button"
            class="btn btn-select-platform"
            [disabled]="isSelectPlatformBtnDisabled"
            (click)="onSelectPlatformClick()">
      Select Platform
    </button>
  </div>
</div>

