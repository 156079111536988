import { Roles } from '../interfaces/marketview';
import { IUserPermissions } from '../interfaces/profile';

import {
  bidsNotAvailable,
  bidsNotSubscription,
  grantsNotAvailable,
  grantsNotSubscription,
  marketViewNotAvailable,
  marketViewNotSubscription,
  PermissionsKeys
} from '@core/constants/permissions';
import { RolesByKey } from '../constants/roles';

// by ticket CCP-174
export class MVEntityAvailable {
  disabled: boolean;
  msg: string;
  redirectLink?: boolean;
  callLink?: boolean;

  constructor(_permissionKey: PermissionsKeys, _data: IUserPermissions, isHidden: boolean = false) {
    const isUser: boolean = _data.role && RolesByKey[_data.role.key] === Roles.User;
    const _isHasSubscription: boolean = _data.accountPermissions.includes(_permissionKey);
    const _isCanManage: boolean = _data.permissions.includes(_permissionKey);

    if (_permissionKey === PermissionsKeys.ManageViews) {
      this.disabled = !_data.permissions.includes(PermissionsKeys.ManageViews) || isHidden;
      this.msg = _isHasSubscription && !_isCanManage || _isHasSubscription && isHidden
        ? marketViewNotAvailable(isUser)
        : marketViewNotSubscription();
      this.callLink = !_isHasSubscription;
      this.redirectLink = _isHasSubscription && !isUser;
    }

    if (_permissionKey === PermissionsKeys.ManageBids) {
      this.disabled = !_data.permissions.includes(PermissionsKeys.ManageBids) || isHidden;
      this.msg = _isHasSubscription && !_isCanManage || _isHasSubscription && isHidden
        ? bidsNotAvailable(isUser)
        : bidsNotSubscription();
      this.callLink = !_isHasSubscription;
      this.redirectLink = _isHasSubscription && !isUser;
    }

    if (_permissionKey === PermissionsKeys.ManageGrants) {
      this.disabled = !_data.permissions.includes(PermissionsKeys.ManageGrants) || isHidden;
      this.msg = _isHasSubscription && !_isCanManage || _isHasSubscription && isHidden
        ? grantsNotAvailable(isUser)
        : grantsNotSubscription();
      this.callLink = !_isHasSubscription;
      this.redirectLink = _isHasSubscription && !isUser;
    }
  }
}
