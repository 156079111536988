<bl-server-errors [error]="tokenError"></bl-server-errors>

<form [formGroup]="form"
      class="form form-content">

  <div class="form-header">
    <p class="form-title">Sign Up</p>
    <p class="form-description">Sign up to join your team and access the education market</p>
  </div>

  <div class="wrapper-input">
    <bl-flat-input formControlName="firstName"
                   type="text"
                   placeholder="First Name*"
                   [errors]="form.get('firstName').errors"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>

  <div class="wrapper-input">
    <bl-flat-input formControlName="lastName"
                   type="text"
                   placeholder="Last Name*"
                   [errors]="form.get('lastName').errors"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>

  <div class="wrapper-input">
    <bl-flat-input formControlName="password"
                   type="password"
                   placeholder="Password*"
                   [isDataPrivate]="true"
                   [blTooltip]="passwordTip"
                   [events]="['focus', 'blur']"
                   [position]="'right'"
                   [leftOffset]="50"
                   [errors]="form.get('password').errors"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>

  <div class="wrapper-input">
    <bl-flat-input formControlName="confirmPassword"
                   type="password"
                   placeholder="Confirm Password*"
                   [isDataPrivate]="true"
                   [errors]="form.get('confirmPassword').errors"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>
  <re-captcha *ngIf="isReCaptcha"
              formControlName="gRecaptchaResponse"
              class="captcha--align">
  </re-captcha>

  <div class="form-footer">
    <button type="submit"
            [blBtnSpinner]="pending"
            [disabled]="form.invalid || pending"
            class="btn btn--primary btn--lg">
      Sign Up
    </button>
  </div>

  <div class="additional-info">
    <p>
      By signing up, you agree to our
      <a [routerLink]="['/', corePaths.COMMON, corePaths.PRIVACY_POLICY]" class="link">Privacy Policy</a> and
      <a [routerLink]="['/', corePaths.COMMON, corePaths.TERMS_OF_USE]" class="link">Terms of Service</a>
    </p>
  </div>

</form>
