import { ModuleWithProviders, NgModule } from '@angular/core';

// services
import { GaService } from './services/ga.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';

// constants
import { GOOGLE_ANALYTICS_STATE } from './constants/google-analytics';

@NgModule({
  providers: [GoogleTagManagerService, GaService]
})
export class GoogleAnalyticsModule {
  static forRoot(state: boolean): ModuleWithProviders<GoogleAnalyticsModule> {
    return {
      ngModule: GoogleAnalyticsModule,
      providers: [{
        provide: GOOGLE_ANALYTICS_STATE,
        useValue: state
      }]
    };
  }
}
