import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/payment-cards.action';
import { getAllBillingInfoSuccessAction } from '../actions/payment.action';

import { ICreditCardInfo, INewCreditCartInfo } from '../../interfaces/e-commerce';

export interface ICreditCardsState {
  creditCards: ICreditCardInfo[];

  selectedCreditCard: ICreditCardInfo | null;

  newCreditCard: INewCreditCartInfo | null;
  newCreditCardSelected: boolean;
}

const initialState: ICreditCardsState = {
  creditCards: [],

  selectedCreditCard: null,

  newCreditCard: null,
  newCreditCardSelected: false,
};

const getCreditCardsSuccess: OnReducer<ICreditCardsState, ActionType<Payload<any>>> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  creditCards: [...payload],
  newCreditCardSelected: !payload.length
});

const addNewCreditCardSuccess: OnReducer<ICreditCardsState, ActionType<Payload<any>>> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  newCreditCard: { ...payload },
  selectedCreditCard: null,
  newCreditCardSelected: true
});

const selectDefaultCreditCard: OnReducer<ICreditCardsState, ActionType<Payload<any>>> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  selectedCreditCard: { ...payload },
  newCreditCardSelected: false
});

const selectNewCreditCard: OnReducer<ICreditCardsState, ActionType<any>> = (state: ICreditCardsState) => ({
  ...state,
  selectedCreditCard: null,
  newCreditCardSelected: true
});

const editCreditCardSuccess: OnReducer<ICreditCardsState, ActionType<Payload<any>>> = (state: ICreditCardsState, { payload }: Payload<any>) => {
  return {
    ...state,
    selectedCreditCard: state.selectedCreditCard.payment_token_identifier === payload.payment_token_identifier
      ? payload
      : { ...state.selectedCreditCard },
    creditCards: state.creditCards.map((creditCard: ICreditCardInfo) =>
      creditCard.payment_token_identifier === payload.payment_token_identifier
        ? payload
        : creditCard)
  };
};

const editNewCreditCardSuccess: OnReducer<ICreditCardsState, ActionType<Payload<any>>> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  newCreditCard: { ...payload }
});

const getAllBillingInfoSuccess: OnReducer<ICreditCardsState, ActionType<Payload<any>>> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  creditCards: [...payload.credit_card_details],
  newCreditCardSelected: !payload.credit_card_details.length
});

const resetCreditCards: OnReducer<any, ActionType<any>> = () => ({
  ...initialState
});

const reducer: ActionReducer<ICreditCardsState> = createReducer<ICreditCardsState>(
  initialState,

  on(actions.getCreditCardsSuccessAction, getCreditCardsSuccess),
  on(actions.addNewCreditCardSuccessAction, addNewCreditCardSuccess),
  on(actions.selectDefaultCreditCardAction, selectDefaultCreditCard),

  on(actions.selectNewCreditCardAction, selectNewCreditCard),
  on(actions.editCreditCardSuccessAction, editCreditCardSuccess),
  on(actions.editNewCreditCardSuccessAction, editNewCreditCardSuccess),

  on(getAllBillingInfoSuccessAction, getAllBillingInfoSuccess),

  on(actions.resetCreditCardsAction, resetCreditCards)
);

export function creditCardsReducer(state: ICreditCardsState, action: Action): ICreditCardsState {
  return reducer(state, action);
}

export const creditCards: GetFromState<ICreditCardInfo[], ICreditCardsState> = (state: ICreditCardsState): ICreditCardInfo[] => state && state.creditCards;
export const selectedCreditCard: GetFromState<ICreditCardInfo, ICreditCardsState> = (state: ICreditCardsState): ICreditCardInfo => state && state.selectedCreditCard;

export const newCreditCard: GetFromState<INewCreditCartInfo, ICreditCardsState> = (state: ICreditCardsState): INewCreditCartInfo => state && state.newCreditCard;
export const newCreditCardSelected: GetFromState<boolean, ICreditCardsState> = (state: ICreditCardsState): boolean => state && state.newCreditCardSelected;
