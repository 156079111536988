<bl-sticky-popup>
  <div class="modal-header" data-testid="edit-user-modal" popupHeader>

    <h3 class="title" data-testid="pop-up-title">
      {{ data.title }}
    </h3>

    <button type="button"
            class="btn"
            (click)="closeSelf()"
            data-testid="close-edit-user-btn"
            matRipple>
      <i class="icon-page-close"></i>
    </button>

  </div>

  <div class="modal-content" popupContent>
    <bl-server-errors [error]="(error$ | async)?.message"></bl-server-errors>

    <form *ngIf="form"
          [formGroup]="form">

      <!--  User firstName, lastName, email  -->
      <div class="user">
        <span class="user-name">{{ data?.user?.firstName }} {{ data?.user?.lastName }}</span>
        <span class="user-email">{{ data?.user?.email }}</span>

        <!-- RESEND INVITATION -->
        <button *ngIf="isInvitedUser"
                type="button"
                class="btn-resend"
                [disabled]="isDisableInviteUser"
                (click)="!isDisableInviteUser ? resendInvitation() : null">
          <i class="icon-refresh--circle pending-icon"
             [class.pending]="pendingInvitation$ | async">
          </i>
          <span class="btn-resend--text">Resend invitation</span>
        </button><!-- / RESEND INVITATION -->
      </div><!--  User firstName, lastName, email  -->

      <!-- SEAT -->
      <bl-rounded-select formControlName="seatId"
                         [options]="baseSeats$ | async"
                         [class.disabled]="isDisabledUser"
                         [blTooltip]="isDisabledUser ? isDisabledUserText : null"
                         [positionFixedAndCalc]="true"
                         (select)="seatId !== $event ? selectSeat($event) : null"
                         placeholder="Seat"
                         class="select-wrapper select-seat"
                         data-testid="seat-drop-down">
      </bl-rounded-select><!-- SEAT -->

      <!--  NATIONAL ADD ONS  -->
      <div *ngIf="isNationalSeat"
           formGroupName="nationalAddOns"
           class="checkbox-wrapper">

        <div [blTooltip]="isNationalGrantAddOn?.disabled && !isDisabledUser ? canNotManageGrantsText : null"
             [positionFixedAndCalc]="true"
             [tooltipClass]="tooltipFigure"
             [topOffset]="-6"
             [iconClass]="bidIcon"
             [textSize]="14"
             [callLink]="true"
             [class.disabled]="isDisabledUser"
             class="checkbox">

          <bl-custom-checkbox formControlName="isNationalGrantAddOn"
                              [tooltipText]="isDisabledUser ? isDisabledUserText : null"
                              [tooltipIsCalPosition]="true"
                              data-testid="grants">
            <span class="checkbox-label">{{ checkBoxGrantName }}</span>
          </bl-custom-checkbox>
        </div>

        <div [blTooltip]="isNationalBidAddOn?.disabled && !isDisabledUser ? canNotManageBidsText : null"
             [positionFixedAndCalc]="true"
             [tooltipClass]="tooltipFigure"
             [topOffset]="-6"
             [iconClass]="grantIcon"
             [textSize]="14"
             [callLink]="true"
             [class.disabled]="isDisabledUser"
             class="checkbox">

          <bl-custom-checkbox formControlName="isNationalBidAddOn"
                              [tooltipText]="isDisabledUser ? isDisabledUserText : null"
                              [tooltipIsCalPosition]="true"
                              data-testid="bid">
            <span class="checkbox-label">{{ checkBoxBidName }}</span>
          </bl-custom-checkbox>
        </div>
      </div><!--  / NATIONAL ADD ONS  -->

      <!-- STATES -->
      <div *ngIf="!!states"
           formArrayName="stateIds"
           data-testid="select-state-btn">

        <div *ngFor="let state of states.controls; let i = index; trackBy: trackByIndex;"
             class="select-item"
             data-testid="states-drop-down">

          <div [formGroupName]="i"
               class="select-wrapper">

            <div class="select-state-wrapper row">
              <bl-rounded-select formControlName="stateId"
                                 unselectedLabel="Select one..."
                                 [errors]="state.errors"
                                 [options]="uncheckedStates$ | async"
                                 [alternativeOptions]="data?.allStates"
                                 [isShowAdditionalListItems]="isShowAvailableState$ | async"
                                 [isCreateNewItems]="canCreateNewState$ | async"
                                 [checkSelectedOneOption]="!isShowAvailable"
                                 [class.disabled]="!(isHasStates$ | async) && isSelectedOneState || isDisabledUser"
                                 [blTooltip]="!(isHasStates$ | async) && isSelectedOneState && !isDisabledUser
                              ? oneSeatMsg : isDisabledUser ? isDisabledUserText : null"
                                 [positionFixedAndCalc]="true"
                                 placeholder="State"
                                 (select)="selectCurrentState(i, $event)"
                                 (onClickCreateBtn)="onSetShowAvailableStates()"
                                 class="theme--state-seat col-11"
                                 data-testid="select-state">
              </bl-rounded-select>

              <button *ngIf="isShowRemoveStateBtn"
                      type="button"
                      class="btn-icon-close col-1"
                      (click)="removeState(i)">
                <i class="icon-page-close"></i>
              </button>
            </div>

            <!-- BID STATE ADD ON -->
            <div class="checkbox-wrapper">
              <div [blTooltip]="state.get('isBidStateId')?.disabled && !isDisabledUser ? canNotManageBidsText : null"
                   [positionFixedAndCalc]="true"
                   [tooltipClass]="tooltipFigure"
                   [topOffset]="-6"
                   [iconClass]="bidIcon"
                   [textSize]="14"
                   [callLink]="true"
                   [class.disabled]="isDisabledUser"
                   class="checkbox">

                <bl-custom-checkbox formControlName="isBidStateId"
                                    [tooltipText]="isDisabledUser ? isDisabledUserText : null"
                                    [tooltipIsCalPosition]="true"
                                    (change)="selectStateAddOn($event)"
                                    data-testid="bid">
                  <span class="checkbox-label">{{ checkBoxBidName }}</span>
                </bl-custom-checkbox>
              </div><!-- / BID STATE ADD ON -->

              <!-- GRANT STATE ADD ON -->
              <div
                [blTooltip]="state.get('isGrantStateId')?.disabled && !isDisabledUser ? canNotManageGrantsText : null"
                [positionFixedAndCalc]="true"
                [tooltipClass]="tooltipFigure"
                [topOffset]="-6"
                [iconClass]="grantIcon"
                [textSize]="14"
                [callLink]="true"
                [class.disabled]="isDisabledUser"
                class="checkbox">

                <bl-custom-checkbox formControlName="isGrantStateId"
                                    [tooltipText]="isDisabledUser ? isDisabledUserText : null"
                                    [tooltipIsCalPosition]="true"
                                    (change)="selectStateAddOn($event)"
                                    data-testid="grants">
                  <span class="checkbox-label">{{ checkBoxGrantName }}</span>
                </bl-custom-checkbox>
              </div><!-- / GRANT STATE ADD ON -->
            </div>

          </div>

        </div>

        <!-- ADD STATE -->
        <button *ngIf="shouldShowAddState && (isShowAddState$ | async) && !isDisabledUser"
                type="button"
                class="btn--add-state"
                (click)="addState()"
                data-testid="add-plus-state-btn">
          + Add another state
        </button><!-- / ADD STATE -->
      </div><!-- STATES -->

      <!-- PERMISSIONS -->
      <div class="select-wrapper" data-testid="permission-drop-down">
        <bl-rounded-select formControlName="roleId"
                           [options]="rolesOptions"
                           (select)="changePermissions()"
                           [class.disabled]="!(getCanChangePermissions$ | async) || isDisabledUser"
                           [blTooltip]="isDisabledUser ? isDisabledUserText : null"
                           [positionFixedAndCalc]="true"
                           defaultLabel="Account Owner"
                           placeholder="Admin Permissions">
        </bl-rounded-select>
      </div><!-- / PERMISSIONS -->

      <br/>

      <ng-container *ngIf="data?.user?.accessLists?.length">
        <span class="label">List Access</span>

        <bl-radio *ngFor="let item of data.user.accessLists; trackBy: trackByIndex"
                  formControlName="showAccountLists"
                  [label]="item.name"
                  [value]="item.value"
                  [isDisabled]="isDisabledUser"
                  className="user-account">
        </bl-radio>

        <br/>
      </ng-container>

      <span class="label">Credits Access</span>

      <bl-radio formControlName="isAllocated"
                label="Access to all unallocated credits"
                className="user-account"
                [value]="false"
                [isDisabled]="isDisabledUser">
      </bl-radio>

      <bl-radio formControlName="isAllocated"
                label="Access to allocated credits only"
                className="user-account"
                [value]="true"
                [isDisabled]="isDisabledUser">
      </bl-radio>

      <bl-slider *ngIf="isAllocated"
                 formControlName="allocatedCredits"
                 [functionalMax]="creditsInfo?.accountCredits - 1"
                 [hideMinLabel]="true"
                 [value]="data?.user?.allocatedCredits"
                 [min]="0"
                 [max]="creditsInfo?.accountCredits"
                 [disable]="isDisabledUser"
                 class="slider-credits">
      </bl-slider>

      <ng-container *ngIf="data?.asAdmin && data?.cdlLists?.hasActiveLists && data?.user?.accessCDLLists?.length">
        <span class="label">Strategic Data Access</span>

        <bl-radio *ngFor="let item of data.user.accessCDLLists; trackBy: trackByIndex"
                  formControlName="accessCDLLists"
                  [label]="item.name"
                  [value]="item.value"
                  className="user-account">
        </bl-radio>

        <br/>
      </ng-container>
    </form>
  </div>

  <div class="modal-footer" popupFooter>
    <bl-drop-down *ngIf="!data?.isEditSelf || !isDisabledUser"
                  [zIndex]="10"
                  [topOffset]="-90"
                  [leftOffset]="isDisabledUser ? -70 : -20"
                  bodyPosition="left">

      <ng-template blDropDownTarget>
        <div matRipple class="dots-wrapper">
          <i class="icon-menu dots"></i>
        </div>
      </ng-template>

      <ng-template blDropDownContent>
        <ng-container [ngTemplateOutlet]="menuItems"></ng-container>
      </ng-template>
    </bl-drop-down>

    <button *ngIf="!isDisabledUser"
            type="button"
            [blBtnSpinner]="pending$ | async"
            [disabled]="form.invalid"
            (click)="handleSubmit()"
            class="btn btn--primary"
            data-testid="save-edit-user-btn">
      Save
    </button>
  </div>

</bl-sticky-popup>

<ng-template #menuItems>
  <ul class="actions-list">
    <li *ngIf="!data?.isEditSelf" class="action-item">
      <div class="btn-remove--tooltip"
           [blTooltip]="(getCanDeleteUser$ | async) ? null : canNotRemoveAnybody"
           [positionFixedAndCalc]="true">

        <button *ngIf="!data?.isEditSelf"
                type="button"
                [class.remove-disable]="getIsDisableDeleteBtn$ | async"
                (click)="removeUser()"
                class="action"
                data-testid="delete-user-btn"
                matRipple>
          {{ isInvitedUser ? 'Revoke Invitation' : 'Delete User...' }}
        </button>
      </div>
    </li>

    <li *ngIf="!isDisabledUser" class="action-item">
      <button type="button"
              class="action"
              [disabled]="isDisabledAssignData()"
              (click)="assignUserData()"
              data-testid="assign-data-btn"
              matRipple>
        Assign Data...
      </button>
    </li>
  </ul>
</ng-template>
