import {
  ChangeDetectionStrategy,
  Component,
  OnInit
} from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { getInvitationExistedUser } from '../../../auth/store/selectors/invitation.selector';
import { openAssignOwnerByInvitationPopUpAction } from '../../store/actions/account-pop-ups.action';
import { getRoleKey } from '../../store/selectors/profile.selector';

import { IInvitationUser } from '../../../auth/interfaces/invitation';

import { INVITATION_ACTION } from '../../../auth/constants/invitation';
import { RolesKeys } from '../../constants/roles';

// utils

// store

// interfaces

@Component({
  selector: 'bl-products-and-users-page',
  templateUrl: './products-and-users-page.component.html',
  styleUrls: ['./products-and-users-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsAndUsersPageComponent implements OnInit {

  readonly rolesKeys: typeof RolesKeys = RolesKeys;

  roleKey$: Observable<string> = this.store.pipe(select(getRoleKey));
  getInvitationExistedUser$: Observable<IInvitationUser> = this.store.pipe(select(getInvitationExistedUser));

  constructor(private store: Store<CoreState>) {
  }

  ngOnInit(): void {
    this.getInvitationExistedUser$
      .pipe(
        take(1),
        filter((invitation: IInvitationUser) => invitation
          && INVITATION_ACTION.OWNER_KEEP_SEPARATE.toString() === invitation.action.toString()
        )
      )
      .subscribe((invitation: IInvitationUser) => this.store.dispatch(openAssignOwnerByInvitationPopUpAction(invitation)));
  }
}
