import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverlayModule } from '@ui/overlay/overlay.module';

import { MobilePortalComponent } from './components/mobile-protal/mobile-portal.component';

import { MobileDropDownContentDirective } from './directives/mobile-drop-down-content.directive';
import { MobileDropDownTargetDirective } from './directives/mobile-drop-down-target.directive';
import { MobileDropDownDirective } from './directives/mobile-drop-down.directive';

import { MobileDropDownProviderService } from './providers/mobile-drop-down-provider.service';


const declarations: any[] = [
  MobileDropDownDirective,
  MobileDropDownTargetDirective,
  MobileDropDownContentDirective,
  MobilePortalComponent
];

const providers: any[] = [
  MobileDropDownProviderService,
];

@NgModule({
  imports: [
    CommonModule,
    OverlayModule
  ],
  declarations: [
    ...declarations
  ],
  providers,
  exports: [
    ...declarations,
  ]
})
export class MobileDropDownModule {
}
