import { createAction } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { IChangePasswordData } from '../../../auth/interfaces/formsActionsData';
import {
  ILoginResponse,
  IRole,
  ISuccessMessageResponse,
  IUser,
  IUserData
} from '../../../auth/interfaces/user';
import { IUpdateProfilePermissionsPayload } from '../../interfaces/profile';
import { IProfileSetting, IProfileSettings } from '../../interfaces/profile-settings';

enum ACTION_TYPES {
  GET_PROFILE = '[PROFILE] Get',
  GET_PROFILE_SUCCESS = '[PROFILE] Get Success',
  GET_PROFILE_ERROR = '[PROFILE] Get Error',

  UPDATE_IS_FIRST_ASSIGN_AVAILABLE = '[PROFILE] Update is First Assign Available',

  SET_PROFILE = '[PROFILE] Set Profile',

  SET_IS_BROKER_STATUS = '[PROFILE] Set is Broker',

  UPDATE_PROFILE = '[PROFILE] Update',
  UPDATE_PROFILE_SUCCESS = '[PROFILE] Update Success',
  UPDATE_PROFILE_ERROR = '[PROFILE] Update Error',

  UPDATE_PROFILE_AND_BILLING_INFO = '[PROFILE] Update profile and billing info',
  UPDATE_PROFILE_AND_BILLING_INFO_SUCCESS = '[PROFILE] Update profile and billing info Success',
  UPDATE_PROFILE_AND_BILLING_INFO_ERROR = '[PROFILE] Update profile and billing info Error',

  UPDATE_PROFILE_ROLE = '[PROFILE] Update Role',

  UPDATE_PROFILE_PERMISSION = '[PROFILE] Update permissions',

  CHANGE_PASSWORD = '[PROFILE] Change Password',
  CHANGE_PASSWORD_SUCCESS = '[PROFILE] Change Password Success',
  CHANGE_PASSWORD_ERROR = '[PROFILE] Change Password Error',

  RESET_PASSWORD_STATE = '[PROFILE] Reset Password State',

  GET_PROFILE_SETTINGS = '[PROFILE] Get Profile Settings',
  GET_PROFILE_SETTINGS_SUCCESS = '[PROFILE] Get Profile Settings Success',
  GET_PROFILE_SETTINGS_ERROR = '[PROFILE] Get Profile Settings Error',

  UPDATE_PROFILE_SETTINGS = '[PROFILE] Update Profile Settings',
  UPDATE_PROFILE_SETTINGS_SUCCESS = '[PROFILE] Update Profile Settings Success',
  UPDATE_PROFILE_SETTINGS_ERROR = '[PROFILE] Update Profile Settings Error',

  RESET_PROFILE_SETTINGS = '[PROFILE] Reset Profile Settings',

  CLEAR_PROFILE = '[PROFILE] Clear Profile',
  RESET_ERRORS = '[PROFILE] Reset Profile Errors',

  SET_IDENTIFY_USER_BY_LOG_ROCKET = '[PROFILE] set identify user with log rocket'
}

export const getProfileAction: ActionCreator<ACTION_TYPES.GET_PROFILE, () => TypedAction<ACTION_TYPES.GET_PROFILE>> = createAction(ACTION_TYPES.GET_PROFILE);
export const getProfileSuccessAction: CreateActionType<ACTION_TYPES.GET_PROFILE_SUCCESS, IUserData> = createAction(ACTION_TYPES.GET_PROFILE_SUCCESS, createPayload<IUserData>());
export const getProfileErrorAction: CreateActionType<ACTION_TYPES.GET_PROFILE_ERROR, IServerError> = createAction(ACTION_TYPES.GET_PROFILE_ERROR, createPayload<IServerError>());

export const updateIsFirstAssignAvailableAction: CreateActionType<ACTION_TYPES.UPDATE_IS_FIRST_ASSIGN_AVAILABLE, boolean> = createAction(ACTION_TYPES.UPDATE_IS_FIRST_ASSIGN_AVAILABLE,
  createPayload<boolean>());

export const setProfileAction: CreateActionType<ACTION_TYPES.SET_PROFILE, ILoginResponse> = createAction(ACTION_TYPES.SET_PROFILE, createPayload<ILoginResponse>());

export const updateProfileAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE, IUser> = createAction(ACTION_TYPES.UPDATE_PROFILE, createPayload<IUser>());
export const updateProfileErrorAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_PROFILE_ERROR, createPayload<IServerError>());
export const updateProfileSuccessAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_SUCCESS, IUserData> = createAction(ACTION_TYPES.UPDATE_PROFILE_SUCCESS, createPayload<IUserData>());

export const updateProfileAndBillingInfoAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_AND_BILLING_INFO, IUser> = createAction(ACTION_TYPES.UPDATE_PROFILE_AND_BILLING_INFO,
  createPayload<IUser>());
export const updateProfileAndBillingInfoErrorAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_AND_BILLING_INFO_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_PROFILE_AND_BILLING_INFO_ERROR,
  createPayload<IServerError>());
export const updateProfileAndBillingInfoSuccessAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_AND_BILLING_INFO_SUCCESS, IUserData> = createAction(ACTION_TYPES.UPDATE_PROFILE_AND_BILLING_INFO_SUCCESS,
  createPayload<IUserData>());

export const setIsBrokerAction: CreateActionType<ACTION_TYPES.SET_IS_BROKER_STATUS, boolean> = createAction(ACTION_TYPES.SET_IS_BROKER_STATUS, createPayload<boolean>());
export const updateProfileRoleAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_ROLE, IRole> = createAction(ACTION_TYPES.UPDATE_PROFILE_ROLE, createPayload<IRole>());

export const changeProfilePermissionAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_PERMISSION, IUpdateProfilePermissionsPayload> = createAction(
  ACTION_TYPES.UPDATE_PROFILE_PERMISSION,
  createPayload<IUpdateProfilePermissionsPayload>()
);

export const changePasswordAction: CreateActionType<ACTION_TYPES.CHANGE_PASSWORD, IChangePasswordData> = createAction(ACTION_TYPES.CHANGE_PASSWORD, createPayload<IChangePasswordData>());
export const changePasswordErrorAction: CreateActionType<ACTION_TYPES.CHANGE_PASSWORD_ERROR, IServerError> = createAction(ACTION_TYPES.CHANGE_PASSWORD_ERROR, createPayload<IServerError>());
export const changePasswordSuccessAction: CreateActionType<ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, ISuccessMessageResponse> = createAction(ACTION_TYPES.CHANGE_PASSWORD_SUCCESS, createPayload<ISuccessMessageResponse>());

export const resetPasswordStateAction: ActionCreator<ACTION_TYPES.RESET_PASSWORD_STATE, () => TypedAction<ACTION_TYPES.RESET_PASSWORD_STATE>> = createAction(ACTION_TYPES.RESET_PASSWORD_STATE);

export const getProfileSettingsAction: ActionCreator<ACTION_TYPES.GET_PROFILE_SETTINGS, () => TypedAction<ACTION_TYPES.GET_PROFILE_SETTINGS>> = createAction(ACTION_TYPES.GET_PROFILE_SETTINGS);
export const getProfileSettingsErrorAction: CreateActionType<ACTION_TYPES.GET_PROFILE_SETTINGS_ERROR, IServerError> = createAction(ACTION_TYPES.GET_PROFILE_SETTINGS_ERROR, createPayload<IServerError>());
export const getProfileSettingsSuccessAction: CreateActionType<ACTION_TYPES.GET_PROFILE_SETTINGS_SUCCESS, IProfileSettings> = createAction(ACTION_TYPES.GET_PROFILE_SETTINGS_SUCCESS, createPayload<IProfileSettings>());

export const resetProfileSettingsAction: ActionCreator<ACTION_TYPES.RESET_PROFILE_SETTINGS, () => TypedAction<ACTION_TYPES.RESET_PROFILE_SETTINGS>> = createAction(ACTION_TYPES.RESET_PROFILE_SETTINGS);

export const updateProfileSettingsAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_SETTINGS, IProfileSetting> = createAction(ACTION_TYPES.UPDATE_PROFILE_SETTINGS, createPayload<IProfileSetting>());
export const updateProfileSettingsErrorAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_SETTINGS_ERROR, IServerError> = createAction(ACTION_TYPES.UPDATE_PROFILE_SETTINGS_ERROR, createPayload<IServerError>());
export const updateProfileSettingsSuccessAction: CreateActionType<ACTION_TYPES.UPDATE_PROFILE_SETTINGS_SUCCESS, IProfileSetting> = createAction(ACTION_TYPES.UPDATE_PROFILE_SETTINGS_SUCCESS,
  createPayload<IProfileSetting>());

export const clearProfileAction: ActionCreator<ACTION_TYPES.CLEAR_PROFILE, () => TypedAction<ACTION_TYPES.CLEAR_PROFILE>> = createAction(ACTION_TYPES.CLEAR_PROFILE);

export const resetErrorsAction: ActionCreator<ACTION_TYPES.RESET_ERRORS, () => TypedAction<ACTION_TYPES.RESET_ERRORS>> = createAction(ACTION_TYPES.RESET_ERRORS);

export const setIdentifyUserByLocRocketAction: CreateActionType<ACTION_TYPES.SET_IDENTIFY_USER_BY_LOG_ROCKET, boolean> = createAction(ACTION_TYPES.SET_IDENTIFY_USER_BY_LOG_ROCKET, createPayload<boolean>());
