import { milesToMeters } from '@shared/utils/converters';

import { IBrowseEntity } from '../interfaces/browse';
import { ICoordinates, IGeoSuggestionBackEnd } from '../interfaces/geography';

import { DEFAULT_AREA_RADIUS, DEFAULT_COUNTRIES, GeoEntitiesTypes, STATE_ABBREVIATIONS } from '../constants/geography';

export class Place {

  static TYPE_NAMES: { [key: string]: string } = {
    [GeoEntitiesTypes.City]: 'City',
    [GeoEntitiesTypes.County]: 'County',
    [GeoEntitiesTypes.State]: 'State',
    [GeoEntitiesTypes.Country]: 'Country',
    [GeoEntitiesTypes.ZipCode]: 'Zip Code',
    [GeoEntitiesTypes.DMACode]: 'DMA Code',
    [GeoEntitiesTypes.SCFCode]: 'SCF Code',
    [GeoEntitiesTypes.ZipRange]: 'Zip Codes Range',
    [GeoEntitiesTypes.SCFRange]: 'SCF Codes Range',
  };

  type: GeoEntitiesTypes;
  include: boolean;
  locationName?: string;
  coordinates?: ICoordinates;
  radius?: number;
  cityId?: number;
  countyId?: number;
  stateId?: number;
  zipId?: number;
  scfId?: number;
  country?: string = '';
  isCustom?: boolean;
  lowValue?: string;
  highValue?: string;
  zipCodes?: Array<Partial<Place>>;
  dmaCodeId?: number;

  id?: number;

  constructor(suggestion: IGeoSuggestionBackEnd, include: boolean = true) {
    if (suggestion.isRange) {
      return Place.fromRange(suggestion, include);
    }

    const { location, type, id, name }: IGeoSuggestionBackEnd = suggestion;

    this.include = include;
    this.type = type;

    if (location.lat && location.lng) {
      this.coordinates = location;
    }

    if (type === GeoEntitiesTypes.DMACode) {
      this.dmaCodeId = id;
    }

    this.locationName = type === GeoEntitiesTypes.DMACode ? name : Place.getLocationNameFromSuggestion(suggestion);
    this.isCustom = type === GeoEntitiesTypes.City;

    if (this.isCustom) {
      this.radius = Math.round(milesToMeters(DEFAULT_AREA_RADIUS));
    }

    this.setAddressComponents(suggestion);
  }

  static getLocationNameFromSuggestion(suggestion: IGeoSuggestionBackEnd): string {
    const { type, name, stateAbbreviation }: IGeoSuggestionBackEnd = suggestion;
    return Place.getLocationName(type, name, stateAbbreviation);
  }

  static getRangeLocationName(suggestion: IGeoSuggestionBackEnd): string {
    return `${ suggestion.lowValue }-${ suggestion.highValue }`;
  }

  static getLocationName(type: number, name: string, state?: string): string {
    const countyRegExp: RegExp = new RegExp(/county/, 'i');

    switch (type) {
      case GeoEntitiesTypes.State:
        return `${ name }, USA`;
      case GeoEntitiesTypes.County:
        const countyName: string = countyRegExp.test(name) ? name : `${ name } County`;
        return `${ countyName }, ${ STATE_ABBREVIATIONS[state] || state }`;
      case GeoEntitiesTypes.City:
        return `${ name }, ${ STATE_ABBREVIATIONS[state] || state }`;
      case GeoEntitiesTypes.ZipCode:
        return `${ name }`;
      case GeoEntitiesTypes.DMACode:
        return `${ name }`;
      case GeoEntitiesTypes.SCFCode:
        return `${ name }`;
    }
  }

  static fromRange(suggestion: IGeoSuggestionBackEnd, include: boolean): Place {
    const { type, lowValue, highValue }: IGeoSuggestionBackEnd = suggestion;
    return { include, type, lowValue, highValue } as Place;
  }

  static fromBrowse(selected: { [key: string]: IBrowseEntity }, entity: IBrowseEntity, type: number): Place {
    const newPlace: Partial<Place> = {};
    const { city, county, state, zip_code, scf_code }: { [key: string]: IBrowseEntity } = selected;

    newPlace.include = true;
    newPlace.type = type;

    if (entity.location && entity.location.lat && entity.location.lng) {
      newPlace.coordinates = entity.location;
    }

    newPlace.locationName = Place.getLocationName(type, entity.name, state.name);
    newPlace.isCustom = newPlace.type === GeoEntitiesTypes.City;

    if (newPlace.isCustom) {
      newPlace.radius = Math.round(milesToMeters(DEFAULT_AREA_RADIUS));
    }

    newPlace.country = DEFAULT_COUNTRIES[0].name;
    newPlace.stateId = state.id;

    switch (type) {
      case GeoEntitiesTypes.City:
        newPlace.cityId = city.id;
        break;
      case GeoEntitiesTypes.County:
        newPlace.countyId = county.id;
        break;
      case GeoEntitiesTypes.ZipCode:
        newPlace.zipId = zip_code.id;
        break;
      case GeoEntitiesTypes.SCFCode:
        newPlace.scfId = scf_code.id;
        break;
    }

    return newPlace as Place;
  }

  static fromBrowseDma(entity: IBrowseEntity, type: number): Place {
    const newPlace: Partial<Place> = {};

    newPlace.include = true;
    newPlace.type = type;
    newPlace.dmaCodeId = entity.id;
    newPlace.locationName = entity.name;
    newPlace.isCustom = false;

    return newPlace as Place;
  }

  setAddressComponents(suggestion: IGeoSuggestionBackEnd): void {
    const { type, id, stateId, countyId, cityId }: IGeoSuggestionBackEnd = suggestion;

    switch (type) {
      case GeoEntitiesTypes.City:
        this.stateId = stateId;
        this.countyId = countyId;
        this.cityId = +id;
        break;
      case GeoEntitiesTypes.County:
        this.stateId = stateId;
        this.countyId = +id;
        break;
      case GeoEntitiesTypes.State:
        this.stateId = +id;
        break;
      case GeoEntitiesTypes.ZipCode:
        this.stateId = stateId;
        this.countyId = countyId;
        this.cityId = cityId;
        this.zipId = +id;
        break;
      case GeoEntitiesTypes.SCFCode:
        this.scfId = +id;
    }

    this.country = 'USA';
  }
}
