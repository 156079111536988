<div #content
     (mouseleave)="params.toggleOnHover && !params.isPreventClose ? close() : null"
     class="drop-down__body"
     [ngStyle]="bodyStyle"
     [ngClass]="bodyClass"
     data-testid="drop-down-body">

  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>

<span class="arrow-container"
      *ngIf="!params.withoutArrow"
      [ngClass]="arrowClass"
      [class.arrow-container--big]="params.isBigArrow">
  <span class="arrow arrow--outer"
        [ngStyle]="outerArrowStyle"></span>
  <span class="arrow arrow--inner"
        [ngStyle]="innerArrowStyle"></span>
</span>
