import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { closeMenuMobileAction } from '@core/store/actions/common-pages.action';
import { CoreState } from '@core/store/reducers';
import { getIsOpenMenuMobile } from '@core/store/selectors/common-pages.selector';
import { signOutAction } from '../../../auth/store/actions/auth.action';

import { ViewPointsService } from '@ui/view-points/services/view-points.service';

import { WINDOW_POINTS } from '@ui/view-points/constants/view-points';

@Component({
  selector: 'bl-nav-content-container',
  templateUrl: './nav-content-container.component.html',
  styleUrls: ['./nav-content-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavContentContainerComponent implements AfterViewInit, OnDestroy {

  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;

  private destroyer$: Subject<void> = new Subject();

  isOpen: boolean;
  isMobile: boolean;

  isOpenMenuMobile$: Observable<boolean> = this.store.pipe(select(getIsOpenMenuMobile));

  constructor(private store: Store<CoreState>,
              private cdr: ChangeDetectorRef,
              private viewPointsService: ViewPointsService) {
  }

  ngAfterViewInit(): void {
    this._subscribeOpen();
    this._subscribeWindowResize();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  closeMenuMobile(): void {
    this.store.dispatch(closeMenuMobileAction());
  }

  logout(): void {
    this.closeMenuMobile();
    this.store.dispatch(signOutAction());
  }

  private _subscribeOpen(): void {
    this.isOpenMenuMobile$
      .pipe(
        takeUntil(this.destroyer$)
      ).subscribe((isOpen: boolean) => {
      this.isOpen = isOpen;
      this.cdr.markForCheck();
    });
  }

  private _subscribeWindowResize(): void {
    this.viewPointsService.listenerOnHideByPoints(this.windowPoints.PHONE, this.windowPoints.TABLET)
      .pipe(
        takeUntil(this.destroyer$)
      ).subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;

      if (!isMobile) {
        this.closeMenuMobile();
      }
      this.cdr.markForCheck();
    });
  }

}
