import { ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';

import { OverlayTargetComponent } from '../components/overlay-target/overlay-target.component';

import { DocumentRef } from '@core/refs/document-ref.service';
import { PortalService } from '@core/services/portal.service';
import { OverlayService } from '../../overlay/providers/overlay.service';

import { IOverlayTarget, IOverlayTargetParams } from '../interfaces';

import { DEFAULT_OVERLAY_TARGET_PARAMS, OVERLAY_TARGET, OVERLAY_TARGET_PARAMS } from '../models';

import { CustomInjector } from '@core/custom-injector';
import { OverlayTargetRef } from './overlay-targets-ref';

@Injectable({ providedIn: 'root' })
export class OverlayTargetProvider {
  constructor(private _appRef: ApplicationRef,
              private _document: DocumentRef,
              private _portalService: PortalService,
              private _componentFactoryResolver: ComponentFactoryResolver,
              private _overlayService: OverlayService,
              private _injector: Injector) {
  }

  public createOverlayTargetRef(overlayTargetData: IOverlayTarget, destroyOnHide: boolean = true): OverlayTargetRef {
    if (!overlayTargetData) {
      throw new Error('you should provide overlayTargetData before it will be run');
    }

    const injector: Injector = this._createInjector<IOverlayTargetParams>(overlayTargetData.target, overlayTargetData.params);
    const componentRef: ComponentRef<OverlayTargetComponent> = this._portalService
      .createComponent<OverlayTargetComponent>(OverlayTargetComponent, injector);

    return new OverlayTargetRef(
      componentRef,
      () => this._show(componentRef),
      () => this._hide(componentRef, destroyOnHide)
    );
  }

  private _show(componentRef: ComponentRef<OverlayTargetComponent>): void {
    this._overlayService.show({ closeOnSelf: false });
    this._portalService.show<OverlayTargetComponent>(componentRef);
    componentRef.instance.isOpen = true;
    componentRef.instance.position();
    componentRef.instance.setTargetIndex(1000000);
  }

  private _hide(componentRef: ComponentRef<OverlayTargetComponent>, destroyOnHide: boolean = true): void {
    this._portalService.hide<OverlayTargetComponent>(componentRef, destroyOnHide);

    if (!destroyOnHide) {
      componentRef.instance.isOpen = false;
      componentRef.instance.setTargetIndex(null);
    }
    this._overlayService.hide();
  }

  private _createInjector<P>(target: ElementRef, params: P): Injector {
    const tokens: WeakMap<object, any> = new WeakMap()
      .set(OVERLAY_TARGET, target)
      .set(OVERLAY_TARGET_PARAMS, { ...DEFAULT_OVERLAY_TARGET_PARAMS, ...params });

    return new CustomInjector(this._injector, tokens);
  }
}
