import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bl-users-header',
  templateUrl: './users-header.component.html',
  styleUrls: ['./users-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersHeaderComponent {
  @Output() onChangeInput: EventEmitter<string> = new EventEmitter();
  @Output() onClickAddUser: EventEmitter<any> = new EventEmitter();

  handleInput(value: string): void {
    this.onChangeInput.emit(value);
  }

  openAddUserPopUp(): void {
    this.onClickAddUser.emit();
  }

}
