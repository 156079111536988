import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SideNavContainerComponent } from './containers/side-nav-container/side-nav-container.component';

import { SideNavContentComponent } from './components/side-nav-content/side-nav-content.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';

const declarations: any[] = [
  SideNavContainerComponent,
  SideNavComponent,
  SideNavContentComponent
];

@NgModule({
  declarations: [
    declarations
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...declarations
  ]
})
export class SidenavModule {
}
