import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { timer, BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { CoreState } from '@core/store/reducers';
import { addTooltipAction, removeTooltipAction } from '../store/actions/tooltips.action';
import { getLastIdTooltip, getTooltips } from '../store/selectors/tooltips.selector';

import { ITooltip } from '@core/interfaces/tooltip';
import { TooltipPositions } from '@shared/interfaces/tooltipPositions';

@Directive({
  selector: '[blTooltip]'
})
export class TooltipDirective implements OnDestroy, OnInit {

  @Input() blTooltip: string;
  @Input() position: 'top' | 'bottom' | 'left' | 'right' = TooltipPositions.BOTTOM;
  @Input() textAlign: 'center' | 'left' | 'right' = 'center';
  @Input() textSize: number = 12;
  @Input() leftOffset: number = 0;
  @Input() topOffset: number = 0;
  @Input() events: string[];
  @Input() withoutDelay: boolean = false;
  @Input() closeTimer: number;
  @Input() closeDelay: number;
  @Input() dismissable: boolean = false;
  @Input() footerText: string;
  @Input() tooltipClass: string;
  @Input() iconClass: string;
  @Input() preventClose: boolean = false;
  @Input() isTooltipDisabled: boolean = false;
  @Input() noEvents: boolean = false;
  @Input() closeByClickBtn: boolean = false;
  @Input() calcWidth: boolean = false;
  @Input() callLink: boolean = false;
  @Input() positionFixedAndCalc: boolean = false;
  @Input() replaceNextRow: boolean = false;
  @Input() redirectLink: string;

  @Input() get isOpen(): boolean {
    return this._isOpen;
  }

  set isOpen(state: boolean) {
    this._isOpen = state;
    this._shouldOpen$.next(this._isOpen);
    this.onToggle.emit(state);

    if (state) {
      this.onAfterOpen.emit();
    }

    if (this.closeTimer && state) {
      timer(this.closeTimer).subscribe(() => this.isOpen = false);
    }
  }

  @Output() onToggle: EventEmitter<boolean> = new EventEmitter();
  @Output() onDismiss: EventEmitter<any> = new EventEmitter();
  @Output() onNext: EventEmitter<any> = new EventEmitter();
  @Output() onAfterClose: EventEmitter<any> = new EventEmitter();
  @Output() onAfterOpen: EventEmitter<any> = new EventEmitter();
  @Output() onAfterCloseContent: EventEmitter<any> = new EventEmitter();


  private _shouldOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isTooltipOnHover: boolean = false;
  private _destroyer$: Subject<void> = new Subject();
  private _isAlive: boolean = true;
  private _isOpen: boolean = false;
  private _id: number;

  lastIdTooltip$: Observable<number> = this._store.pipe(select(getLastIdTooltip));
  tooltips$: Observable<ITooltip[]> = this._store.pipe(select(getTooltips));

  constructor(private _elementRef: ElementRef,
              private _store: Store<CoreState>) {
  }

  ngOnInit(): void {
    this.events = this.noEvents ? [] : this.events || ['hover'];

    this.lastIdTooltip$
      .pipe(
        takeUntil(this._destroyer$)
      ).subscribe((lastId: number) => this._id = lastId);

    this._shouldOpen$
      .pipe(
        takeUntil(this._destroyer$),
        distinctUntilChanged(),
        debounce((isOpen: boolean) => {
          if (this.closeDelay && !isOpen) {
            return timer(this.closeDelay);
          }

          if (!this.withoutDelay && isOpen) {
            return timer(1000);
          }

          return timer(0);
        })
      ).subscribe((state: boolean) => {
      state && this._isAlive ? this.showTooltip() : this.destroy();
    });
  }

  @HostListener('focus')
  onFocus(): void {
    if (this.events.includes('focus')) {
      this.isOpen = true;
    }
  }

  @HostListener('blur')
  onBlur(): void {
    if (this.events.includes('blur')) {
      this.isOpen = false;
    }
  }

  @HostListener('click')
  onClick(): void {
    if (this.events.includes('click')) {
      this.isOpen = !this.isOpen;
    }
  }

  @HostListener('mouseover')
  onMouseOver(): void {
    if (this.events.includes('hover')) {
      this.isOpen = true;
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.events.includes('hover')) {
      setTimeout(() => this.isOpen = false, 100);
    }
  }

  @HostListener('touchend')
  onTouch(): void {
    if (this.events.includes('hover')) {
      this.isOpen = !this.isOpen;
    }
  }

  showTooltip(): void {
    if (!this.isTooltipDisabled) {
      const {
        textSize,
        textAlign,
        position,
        leftOffset,
        topOffset,
        dismissable,
        footerText,
        tooltipClass,
        iconClass,
        preventClose,
        closeByClickBtn,
        calcWidth,
        callLink,
        positionFixedAndCalc,
        replaceNextRow,
        redirectLink
      }: TooltipDirective = this;

      const tooltip: ITooltip = {
        clientRect: this._elementRef.nativeElement.getBoundingClientRect(),
        text: this.blTooltip,
        onDismiss: () => this.onDismiss.emit(),
        onNext: () => this.onNext.emit(),
        onAfterCloseContent: () => this.onAfterCloseContent.emit(),
        textSize,
        textAlign,
        position,
        leftOffset,
        topOffset,
        dismissable,
        footerText,
        tooltipClass,
        iconClass,
        preventClose,
        closeByClickBtn,
        calcWidth,
        callLink,
        positionFixedAndCalc,
        replaceNextRow,
        redirectLink
      };

      this._isTooltipOnHover = false;
      this._store.dispatch(addTooltipAction(tooltip));
    }
  }

  destroy(): void {
    if (!this._id) {
      return;
    }

    this.tooltips$
      .pipe(
        takeUntil(this._destroyer$)
      ).subscribe((tooltips: ITooltip[]) => {
      const tooltip: ITooltip = tooltips.find((t: ITooltip) => t.id === this._id);
      if (tooltip && tooltip.isOnHover) {
        this._isTooltipOnHover = true;
      }
    });

    if (this._isTooltipOnHover) {
      return;
    }

    if (this.onAfterClose) {
      this.onAfterClose.emit();
    }

    this._store.dispatch(removeTooltipAction(this._id));
  }

  ngOnDestroy(): void {
    this.destroy();

    this._isAlive = false;

    this._destroyer$.next();
    this._destroyer$.complete();
  }
}
