<bl-header [user]="user$ | async"
           [isTokenExpired]="isTokenExpired$ | async"
           [globalError]="globalError$ | async"
           [isShowBillingDetails]="!(isBroker$ | async) && (userIsHasAddress$ | async)"
           [isLoggedIn]="isLoggedIn$ | async"
           [isOwnerOfAdminsAccount]="isOwnerOfAdminsAccount$ | async"
           (onLogout)="logout()"
           (onGoToAdminPanel)="goToAdminPanel()"
           (onOpenMenuMobile)="openMenuMobile()">
</bl-header>
