import { AfterViewInit, Directive, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[blScrollMeasure]'
})
export class ScrollMeasureDirective implements OnInit, AfterViewInit {

  @Output() scrollWidth: EventEmitter<number> = new EventEmitter();

  constructor(
    private element: ElementRef,
    private render: Renderer2) {
  }

  ngOnInit(): void {
    this.render.setStyle(this.element.nativeElement, 'width', 100);
    this.render.setStyle(this.element.nativeElement, 'height', 100);
    this.render.setStyle(this.element.nativeElement, 'overflow', 'scroll');
    this.render.setStyle(this.element.nativeElement, 'position', 'fixed');
    this.render.setStyle(this.element.nativeElement, 'z-index', -9999);
    this.render.setStyle(this.element.nativeElement, 'visibility', 'hidden');
  }

  ngAfterViewInit(): void {
    const scrollWidth: number = this.element.nativeElement.offsetWidth - this.element.nativeElement.clientWidth;
    this.scrollWidth.emit(scrollWidth);
  }

}
