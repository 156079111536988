export enum ACCOUNT_MESSAGES {
  CHANGE_SUCCESS = 'Saved!',
  CREDITS_IS_UP_TO_DATE = 'Your credits are up-to-date!',
  USER_REMOVED = 'User successfully deleted',
  INVITED_USER_REMOVED = 'Invitation revoked',
  USER_REMOVED_AND_DATA_ASSIGNED = 'User deleted and data assigned',
  USER_CAN_NOT_REMOVE = 'Only Account Owners can delete other admins',
  ONE_STATE_MSG = 'The state cannot be changed because there are no available state seats.',
  FORGOT_PASSWORD_MESSAGE = 'We sent a reset password link to the email address you sign in with. Use that link to log back in.',
  ADDRESS_WAS_CHANGED = 'Your address has been auto-corrected'
}
