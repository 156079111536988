import { createReducer, on, Action, ActionReducer, ActionType } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

import { Payload } from '@shared/interfaces/store';
import { GetFromState } from '@shared/store/types/reducer.types';
import * as actions from '../actions/spinner.action';


export interface ISpinnerState {
  showSpinner: boolean;
}

const initialState: ISpinnerState = {
  showSpinner: false
};

const setSpinner: OnReducer<ISpinnerState, ActionType<Payload<any>>> = (state: ISpinnerState, { payload }: Payload<any>) => ({
  ...state,
  showSpinner: payload
});

const reducer: ActionReducer<ISpinnerState> = createReducer<ISpinnerState>(
  initialState,

  on(actions.setSpinnerAction, setSpinner),
);

export const isShowSpinner: GetFromState<boolean, ISpinnerState> = (state: ISpinnerState): boolean => state && state.showSpinner;

export function spinnerReducer(state: ISpinnerState, action: Action): ISpinnerState {
  return reducer(state, action);
}
