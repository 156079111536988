import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { WindowRef } from '@core/refs/window-ref.service';
import { LayoutService } from '@core/services/layout.service';

@Component({
  selector: 'bl-mobile-tabs-nav-item',
  templateUrl: './mobile-tabs-nav-item.component.html',
  styleUrls: ['./mobile-tabs-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTabsNavItemComponent implements AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('navTooltipRef', { read: ElementRef }) navTooltipRef: ElementRef;
  @ViewChild('navTooltipWrapperRef', { read: ElementRef }) navTooltipWrapperRef: ElementRef;

  @Input() count: number;
  @Input() title: number | string;

  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipIsOpen: boolean = false;
  @Input() tooltipOffsetTop: number = 0;

  private destroyer$: Subject<void> = new Subject();

  constructor(
    private elementRef: ElementRef,
    private window: WindowRef,
    private changeDetectionRef: ChangeDetectorRef,
    private layoutService: LayoutService,
    private zone: NgZone
  ) {
  }

  ngAfterViewInit(): void {
    if (this.tooltipTemplate && this.tooltipIsOpen) {
      this.initTooltip();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tooltipIsOpen && changes.tooltipIsOpen.currentValue && !changes.tooltipIsOpen.firstChange && this.tooltipTemplate) {
      this.initTooltip();
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  initTooltip(): void {
    this.zone.runOutsideAngular(() => {

      this.navTooltipWrapperRef.nativeElement.style.minWidth = this.elementRef.nativeElement.offsetWidth + 'px';

      this.layoutService.layoutHasBeenChanged
        .pipe(
          takeUntil(this.destroyer$),
          debounceTime(0)
        ).subscribe(() => {
        this.calculateTooltipPosition();
      });
      this.calculateTooltipPosition();

    });
  }

  calculateTooltipPosition(): void {
    const { bottom, left }: ClientRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.navTooltipWrapperRef.nativeElement.style.top = bottom + 'px';
    this.navTooltipWrapperRef.nativeElement.style.left = left + 'px';
    if (this.window.nativeElement.pageYOffset - this.tooltipOffsetTop < 0) {
      this.navTooltipRef.nativeElement.style.bottom = 0 + 'px';
    } else {
      this.navTooltipRef.nativeElement.style.bottom = -this.tooltipOffsetTop + this.window.nativeElement.pageYOffset + 'px';
    }


    this.zone.run(() => {
      this.changeDetectionRef.markForCheck();
    });
  }
}
