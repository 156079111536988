import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverlayModule } from '../overlay/overlay.module';

import { OverlayTargetComponent } from './components/overlay-target/overlay-target.component';

import { OverlayTargetProvider } from './providers/overlay-target-provider.service';

const components: any[] = [OverlayTargetComponent];

@NgModule({
  imports: [
    CommonModule,
    OverlayModule
  ],
  declarations: [
    ...components,
  ],
  providers: [OverlayTargetProvider],
})
export class OverlayTargetModule {
}
