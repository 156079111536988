import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { signOutAction } from '@modules/auth/store/actions/auth.action';
import { setCommonErrorAction, setPermissionsErrorAction } from '../store/actions/common-errors.action';
import { CoreState } from '../store/reducers';

import { StorageService } from '../services/storage.service';

import {
  CATCH_API_ERROR_SEARCH_CUSTOM_OBJECT,
  CATCH_API_ERROR_SOLUTION,
  SCRIBE_ERRORS
} from '@modules/list-cloud-sync/constants';
import { AVALARA_ERROR, COMMON_ERROR, COMMON_ERROR_PARTIAL } from '@shared/constants/error-message';
import { CLOUD_SYNC_AUTH_MSG } from '../constants/cloud-sync';
import { CORE_PATHS } from '../constants/core-paths';
import { ERROR_MSG } from '../constants/error-page';

export interface IHttpErrorWithLogIdResponse extends HttpErrorResponse {
  logId: number | string;
}

@Injectable()
export class AppErrorInterceptor implements HttpInterceptor {

  constructor(private _injector: Injector,
              private _store: Store<CoreState>) {
  }

  router: Router = this._injector.get(Router);

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(req)
      .pipe(
        catchError((error: HttpErrorResponse | IHttpErrorWithLogIdResponse) => {
          switch (error && error.status) {
            case 401:
              if (error.error && error.error.message !== CLOUD_SYNC_AUTH_MSG.NOT_AUTHORIZE_ERROR &&
                (error.error && error.error.message !== ERROR_MSG.TOKEN_EXPIRED ||
                  error.error && error.error.message === ERROR_MSG.TOKEN_EXPIRED && !StorageService.remember)) {
                this._store.dispatch(signOutAction());
              }
              break;
            case 403: {
              if (error.error && error.error.message) {
                this._store.dispatch(setPermissionsErrorAction({ message: error.error.message }));
              }
              break;
            }
            case 404:
              if (error && error.url && error.url.indexOf(CATCH_API_ERROR_SEARCH_CUSTOM_OBJECT) === -1 &&
                error.url.indexOf(CATCH_API_ERROR_SOLUTION) === -1 &&
                error.error && error.error.message !== SCRIBE_ERRORS.NOT_FOUND) {
                this.router.navigate(['/', CORE_PATHS.NOT_FOUND]);
              }
              break;
            case 500: {
              // TODO Remove this check when API will be ready;
              const logId: number | string = (error.error as IHttpErrorWithLogIdResponse).logId;
              const msg: string = req.url.includes('ecommerce')
                ? AVALARA_ERROR
                : typeof logId !== 'undefined'
                  ? `${ COMMON_ERROR_PARTIAL[0] } Log error id ${ logId }. ${ COMMON_ERROR_PARTIAL[1] }`
                  : COMMON_ERROR;

              if (msg) {
                this._store.dispatch(setCommonErrorAction(msg));
              }
              break;
            }
            case 502:
              this.router.navigate(['/', CORE_PATHS.COMMON, CORE_PATHS.BAD_GATEWAY]);
              break;
            case 503:
              this.router.navigate(['/', CORE_PATHS.COMMON, CORE_PATHS.SERVICE_UNAVAILABLE]);
              break;
          }

          return throwError(error);
        })
      );
  }
}
