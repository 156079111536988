export enum CDL_LIST_PATHS {
  ID = ':id',
  SEGMENT_ID = ':segmentId',
  NAME_AND_PID = 'institutions',
  APPEND = 'append',
  PURCHASE = 'purchase',
  ORDER_SUMMARY = 'order-summary',
  ORDER_SUMMARY_COUNTS = 'counts',
  INSTITUTIONS = 'institutions',
  PERSONNEL = 'personnel',
  GEOGRAPHY = 'geography',
  SUMMARY = 'summary',
  LAYOUT = 'layout',
  SETTINGS = 'settings',
  CUSTOMIZE = 'customize',
  ADD_ONS = 'add-ons',
  HISTORY = 'history',
  DOWNLOAD = 'download',
}
