export const HIDE_RESPONSE_FOR_URLS: string[] = [
  'auth/login',
  'auth/first-login',
  'auth/refresh-token',
  'auth/sign-up',
  'auth/password/change',
  'auth/password/verify-reset',
  'auth/password/reset',
  'validate-address',
  'ecommerce/user/billing-info',
  'ecommerce/cart/validate',
  'ecommerce/cart/tax',
  'ecommerce/cart/orders/sales',
  'ecommerce/sign-request',
  'user/data'
];

export enum SECURITY_TYPE {
  REDACT = 'redact',
  LIPSUM = 'lipsum'
}
