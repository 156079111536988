<div class="tabs-list">

  <div class="tabs-headers"
       [style.justifyContent]="justifyContent">

    <div class="tab-header-wrapper"
         *ngFor="let tab of tabs"
         [ngClass]="{ 'disabled': tab.isDisabled, 'active': tab.isActive }"
         (click)="selectTab(tab)">

      <ng-container *ngIf="tab.headerTemplate">
        <ng-container [ngTemplateOutlet]="tab.headerTemplate"></ng-container>
      </ng-container>

      <ng-container *ngIf="!tab.headerTemplate">
        <div class="tab-header"
             [style.width]="tab.width + 'px'"
             [style.height]="tab.height + 'px'"
             [style.lineHeight]="tab.height + 'px'"
             [style.fontSize]="tab.fontSize + 'px'">
          {{ tab.title }}
        </div>
      </ng-container>

    </div>
  </div>

  <div class="tabs-content">
    <ng-container [ngTemplateOutlet]="selectedTab.contentTemplate"></ng-container>
  </div>

</div>
