import { Pipe, PipeTransform } from '@angular/core';

import { PRODUCT_ENTITY_TYPES, PRODUCT_ENTITY_TYPES_NAMES } from '@shared/constants/data/entity';

@Pipe({
  name: 'blProductEntityTypeName'
})
export class ProductEntityTypeNamePipe implements PipeTransform {
  transform(entityType: PRODUCT_ENTITY_TYPES): string {
    return !entityType || !PRODUCT_ENTITY_TYPES_NAMES[entityType] ? '' : PRODUCT_ENTITY_TYPES_NAMES[entityType];
  }
}
