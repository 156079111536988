import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { StorageService } from '@core/services/storage.service';

import { ERROR_MSG } from '@core/constants/error-page';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  static applyCredentials<T>(req: HttpRequest<T>, token: string = StorageService.token): HttpRequest<T> {
    const headers: { Authorization: string } = {
      'Authorization': token || '',
      // 'Content-Type': 'application/json' COMMENTED BT TASK BL-5103
    };

    return req.clone({
      setHeaders: headers
    });
  }

  static isAuthTokenError(error: any): boolean {
    return (error instanceof HttpErrorResponse && error.error.message === ERROR_MSG.TOKEN_EXPIRED);
  }

  static isAuthError(error: any): boolean {
    return (error instanceof HttpErrorResponse && error.status === 401);
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.excludeAuth)) {
      return next.handle(req);
    }

    return next.handle(AuthInterceptor.applyCredentials(req))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          //  if is "Unauthorized" error
          if (AuthInterceptor.isAuthError(error)) {
            if (!environment.production) {
              console.log('wrong or expired token without remember flag');
            }

            return throwError(error);
          }

          // TODO handle status 403

          // else throw error;
          if (!environment.production) {
            console.log('unexpected http error');
          }

          return throwError(error);
        })
      );
  }
}
