<form [formGroup]="form" (ngSubmit)="submit()">

  <div class="form panel form-content">

    <ng-container [formGroupName]="'credentials'">

      <div class="input-wrapper">
        <bl-flat-input formControlName="email"
                       [errors]="credentials.get('email').errors"
                       type="email"
                       placeholder="Email Address">
        </bl-flat-input>
      </div>

      <div class="input-wrapper input-wrapper--margin-none">
        <bl-flat-input formControlName="password"
                       [isDataPrivate]="true"
                       [errors]="credentials.get('password').errors"
                       type="password"
                       placeholder="Password">
        </bl-flat-input>

        <div class="forgot-password__section">
          <a [routerLink]="['/', corePaths.AUTH, authPaths.FORGOT_PASSWORD]" class="forgot-password__link">Forgot
            Password?</a>
        </div>
      </div>

    </ng-container>

    <div *ngIf="isShowFooter">

      <div class="form-footer">
        <button [blBtnSpinner]="loading"
                class="btn btn--primary btn--lg">
          Sign In
        </button>
      </div>
    </div>
  </div>

  <ng-content></ng-content>

</form>
