import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { getCanManageBids } from '@modules/profile/store/selectors/profile.selector';
import { go } from '../store/actions/router-history.action';
import { CoreState } from '../store/reducers';

@Injectable()
export class BidsPermissionsGuard implements CanActivate, CanLoad {

  private _next(): Observable<boolean> | Promise<boolean> | boolean {
    return this._store
      .pipe(
        select(getCanManageBids),
        take(1),
        tap((canManageBids: boolean) => {
          if (!canManageBids) {
            this._store.dispatch(go('/'));
          }
        })
      );
  }

  constructor(private _store: Store<CoreState>) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this._next();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this._next();
  }
}

