<div class="row sub-header__nav">
  <div class="col-2"></div>

  <div class="col-8 sub-header__nav__title">
    {{ title }}
  </div>

  <div class="col-2 sub-header__nav__close">

    <a *ngIf="!externalRedirect"
       [routerLink]="!disabled ? url : null"
       class="close-btn">
      <i class="icon-page-close"></i>
    </a>

    <a *ngIf="externalRedirect"
       type="button"
       class="close-btn"
       (click)="!disabled ? redirectTo() : null">
      <i class="icon-page-close"></i>
    </a>
  </div>
</div>
