import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { environment } from '@env/environment';

import { timer, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { ActionWithPayload } from '@shared/interfaces/store';
import * as actions from '../actions/download.action';
import { downloadFileByLinkAction } from '../actions/download.action';
import { CoreState } from '../reducers';

import { StorageService } from '../../services/storage.service';

import { catchErrorWithErrorType } from '@shared/utils/error-handlers';

import { ILinks } from '../../interfaces/download-by-link';


@Injectable()
export class DownloadEffects {

  constructor(private _actions$: Actions, private _store: Store<CoreState>) {
  }

  downloadFileByLinks$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(actions.downloadFileByLinksAction),
      switchMap(({ payload: links }: ActionWithPayload<ILinks>) => timer(0, 1000)
        .pipe(
          take(links.length),
          map((index: number) => actions.downloadFileByLinkAction({ link: links[index] })),
        )
      ),
      catchErrorWithErrorType
    )
  );

  downloadOrderSummaryCounts$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(actions.downloadOrderSummaryCountsAction),
      map(({ payload }: ActionWithPayload<number>) => downloadFileByLinkAction({
          link: `${ environment.api.cdlList.main }/${ payload }/${ environment.api.cdlList.countsSummary }/${ environment.api.cdlList.download }?token=${ StorageService.token.replace('Bearer ', '') }`
        })
      ),
      catchErrorWithErrorType
    )
  );
}
