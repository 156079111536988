<form [formGroup]="form"
      data-testid="change-pass-window">

  <h4 class="sub-form-wrapper__header">Change Password</h4>

  <bl-server-errors [error]="serverError?.message"></bl-server-errors>

  <div class="mb-5">
    <bl-flat-input formControlName="oldPassword"
                   type="password"
                   placeholder="Current Password*"
                   [errors]="form.get('oldPassword').errors"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>

  <div class="mb-5">
    <bl-flat-input formControlName="newPassword"
                   type="password"
                   placeholder="New Password*"
                   [errors]="form.get('newPassword').errors"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>

  <div class="mb-5">
    <bl-flat-input formControlName="confirmPassword"
                   type="password"
                   placeholder="Confirm New Password*"
                   [errors]="form.get('confirmPassword').errors"
                   [theme]="flatInputTheme.MAT_LIKE">
    </bl-flat-input>
  </div>

</form>
