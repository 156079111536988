<bl-credit-meter [isMobile]="isMobile"
                 [isBroker]="isBroker$ | async"
                 [isUnlimitedUser]="isUnlimitedUser$ | async"
                 [userCredits]="userCredits$ | async"
                 [pending]="isCreditsPending$ | async"
                 [showTooltipOnInit]="showTooltipOnInit$ | async"
                 (onRefresh)="refreshUserCredits()"
                 (onGoTo)="goTo.emit()"
                 data-testid="refresh-credits-btn">
</bl-credit-meter>
