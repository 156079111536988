import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { CoreState } from '@core/store/reducers';
import {
  getIsAdminsAccount,
  getIsBroker,
  getRoleConfig,
  getUserIsHasAddress
} from '../../store/selectors/profile.selector';

import { IProfileTabTitle } from '../../interfaces/profile';

import { PROFILE_PATHS } from '../../constants/profile-route-paths';

@Component({
  selector: 'bl-profile-tabs-container',
  templateUrl: './profile-tabs-container.component.html',
  styleUrls: ['./profile-tabs-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileTabsContainerComponent {
  readonly profileRouterPaths: typeof PROFILE_PATHS = PROFILE_PATHS;

  roleConfig$: Observable<IProfileTabTitle> = this.store.pipe(select(getRoleConfig));
  isAdminsAccount$: Observable<boolean> = this.store.pipe(select(getIsAdminsAccount));
  userIsHasAddress$: Observable<boolean> = this.store.pipe(select(getUserIsHasAddress));
  isBroker$: Observable<boolean> = this.store.pipe(select(getIsBroker));

  constructor(private store: Store<CoreState>) {
  }
}
