import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { environment } from '@env/environment';

import { DocumentRef } from '@core/refs/document-ref.service';

import { appendExternalScriptToBody, IScriptParamsTypes } from '@shared/utils/append-external-script-to-body';
import { getGoogleTagManagerScript } from '../utils/get-google-tag-manager-script';

import { GOOGLE_ANALYTICS_STATE } from '../constants/google-analytics';

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService {
  private _renderer: Renderer2;

  constructor(@Inject(GOOGLE_ANALYTICS_STATE) private _state: boolean,
              private _rendererFactory: RendererFactory2,
              private _documentRef: DocumentRef) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  initGTagManager(): void {
    if (!this._state || !!this._documentRef.nativeElement.getElementById('gTag-script')) {
      return;
    }

    const gTagManagerScriptParams: Iterable<readonly [string, IScriptParamsTypes]> = [['id', 'gTag-script'], ['innerHTML', getGoogleTagManagerScript()]];
    appendExternalScriptToBody(this._documentRef, gTagManagerScriptParams);
  }

  initGTagManagerScript(): void {
    if (!this._state || !!this._documentRef.nativeElement.getElementById('gTag-noscript')) {
      return;
    }

    const gTagManagerScriptParams: Iterable<readonly [string, IScriptParamsTypes]> = [['id', 'gTag-script']];
    appendExternalScriptToBody(this._documentRef, gTagManagerScriptParams, 'noscript');

    const iframeScriptParams: Iterable<readonly [string, IScriptParamsTypes]> = [['src', environment.gTagManagerBodyUrl], ['width', '0'], ['height', '0']];
    const iframeAppendedElement: Element = appendExternalScriptToBody(this._documentRef, iframeScriptParams, 'iframe');
    this._renderer.setStyle(iframeAppendedElement, 'display', 'none');
    this._renderer.setStyle(iframeAppendedElement, 'visibility', 'hidden');
  }
}
