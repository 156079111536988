import { ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';

import { fromEvent } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { OverlayComponent } from '../components/overlay/overlay.component';

import { PortalService } from '@core/services/portal.service';

import { CustomInjector } from '@core/custom-injector';

export interface IOverlayParams {
  onCLick?: () => any;
  closeOnSelf?: boolean;
}

export interface IOverlayRef {
  element: OverlayComponent;
  detach: () => void;
}

export const DEFAULT_OVERLAY_PARAMS: { onCLick: () => null; closeOnSelf: boolean } = {
  onCLick: () => null,
  closeOnSelf: true
};

@Injectable()
export class OverlayService {
  private _ref: IOverlayRef;

  constructor(private portalService: PortalService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private injector: Injector) {
  }

  show(params: IOverlayParams = DEFAULT_OVERLAY_PARAMS): void {

    const _params: { onCLick: (() => null) | (() => any); closeOnSelf: boolean } = { ...DEFAULT_OVERLAY_PARAMS, ...params };
    const injector: CustomInjector = this.createInjector(_params);
    const componentRef: ComponentRef<OverlayComponent> = this.componentFactoryResolver
      .resolveComponentFactory(OverlayComponent)
      .create(injector);

    const detachFunction: () => void = this.portalService.attachComponentRef(componentRef);

    fromEvent(componentRef.instance.elementRef.nativeElement, 'click')
      .pipe(takeWhile(() => !!this._ref))
      .subscribe(() => {
        if (_params.closeOnSelf) {
          this.hide();
        }
        if (_params.onCLick) {
          _params.onCLick();
        }
      });

    this._ref = {
      element: componentRef.instance,
      detach: detachFunction
    };
  }

  hide(): void {
    if (this._ref) {
      this._ref.detach();
      this._ref = null;
    }
  }

  private createInjector(params: IOverlayParams): CustomInjector {
    const tokens: WeakMap<object, any> = new WeakMap();
    // You can add your'e token if need in future
    // For example: tokens.set('TOKEN_NAME', params['someParamsName']);

    return new CustomInjector(this.injector, tokens);
  }
}
