import { IMyDate } from 'mydatepicker';

export function milesToMeters(value: number): number {
  return value * 1604.344;
}

export function metersToMiles(value: number): number {
  return value / 1604.344;
}

export function dateToMyDate(_date: number | Date): IMyDate {
  const date: Date = _date instanceof Date ? _date : new Date(_date);

  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

export function roundDateToDay(_date: number | Date): number {
  const date: Date = _date instanceof Date ? _date : new Date(_date);

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  ).getTime();
}


export function formatExpDate(value: string, withZero: boolean = false, fullYear: boolean = false): string {
  const date: Date = new Date(value);
  const year: number = fullYear ? date.getFullYear() : date.getFullYear() % 100;

  let month: number | string = date.getMonth() + 1;
  month = `${ withZero && month < 10 ? '0' : '' }${ month }`;

  return `${ month }/${ year }`;
}

export function fullCurrentTime(_date: number | Date): string {
  const now: Date = _date instanceof Date ? _date : new Date(_date);

  const day: number = now.getDate();
  const month: number = now.getMonth() + 1;
  const year: number = now.getFullYear();
  const hour: number = now.getHours();
  const min: number = now.getMinutes();
  const sec: number = now.getSeconds();


  return `${ day }-${ month }-${ year } ${ hour }:${ min }:${ sec }`;
}
