import { createAction } from '@ngrx/store';

import { CreateActionType } from '@shared/store/types/action.types';
import { createPayload } from '@shared/utils/store';

import { IServerError } from '@shared/interfaces/server-error';
import { IShowEmailPayload } from '../../interfaces';

enum ACTION_TYPES {
  GET_PAYMENT_EMAIL_BY_PERSONNEL_ID = '[DISTRICT PERSONNEL PAYMENT EMAIL] get',
  GET_PAYMENT_EMAIL_BY_PERSONNEL_ID_ERROR = '[DISTRICT PERSONNEL PAYMENT EMAIL] get error',
  GET_PAYMENT_EMAIL_BY_PERSONNEL_ID_SUCCESS = '[DISTRICT PERSONNEL PAYMENT EMAIL] get success'
}

export const getPaymentEmailByPersonnelIdAction: CreateActionType<ACTION_TYPES.GET_PAYMENT_EMAIL_BY_PERSONNEL_ID, number> = createAction(ACTION_TYPES.GET_PAYMENT_EMAIL_BY_PERSONNEL_ID, createPayload<number>());
export const getPaymentEmailByPersonnelIdErrorAction: CreateActionType<ACTION_TYPES.GET_PAYMENT_EMAIL_BY_PERSONNEL_ID_ERROR, IServerError> = createAction(ACTION_TYPES.GET_PAYMENT_EMAIL_BY_PERSONNEL_ID_ERROR,
  createPayload<IServerError>());
export const getPaymentEmailByPersonnelIdSuccessAction: CreateActionType<ACTION_TYPES.GET_PAYMENT_EMAIL_BY_PERSONNEL_ID_SUCCESS, IShowEmailPayload> = createAction(ACTION_TYPES.GET_PAYMENT_EMAIL_BY_PERSONNEL_ID_SUCCESS,
  createPayload<IShowEmailPayload>());
