import { Pipe, PipeTransform } from '@angular/core';

import { SEPARATES } from '@shared/constants/data/separates';

@Pipe({
  name: 'lowerCaseBySeparator'
})
export class LowerCaseBySeparatorPipe implements PipeTransform {

  transform(value: string, separate: SEPARATES = SEPARATES.HYPHEN): string {
    if (!value || typeof value !== 'string') {
      return '';
    }

    return value
      .replace(/\s/g, separate)
      .toLowerCase();
  }

}
