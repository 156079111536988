import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  navigateAction,
  navigateBackAction,
  navigateByUrlAction
} from '@core/store/actions/router-history.action';
import { CoreState } from '@core/store/reducers';

import { GoBack } from '@core/constants/backButton';

@Component({
  selector: 'bl-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoBackComponent {

  @Input() type: GoBack = GoBack.Close;
  @Input() url: string | string[];
  @Input() isWhiteBtn: boolean;

  buttonBackTypes: typeof GoBack = GoBack;

  constructor(private store: Store<CoreState>) {
  }

  goBack(): void {
    if (this.url) {
      if (typeof this.url === 'string') {
        this.store.dispatch(navigateByUrlAction(this.url));
      } else {
        this.store.dispatch(navigateAction({ url: this.url }));
      }
    } else {
      this.store.dispatch(navigateBackAction());
    }
  }
}
