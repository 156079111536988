import { IDropDownParams } from '../interfaces/drop-down';

export const DEFAULT_DROP_DOWN_PARAMS: IDropDownParams = {
  bodyPosition: 'left',
  leftOffset: 0,
  topOffset: 0,
  rightOffset: 0,
  toggleOnHover: false,
  rightSpacing: 0,
  withoutArrow: true // commented by ticket BL-4940, BL-4236
};

export const CORRECT_DROP_DOWN_ARROW_LEFT_POS: number = 5;
export const BIG_DROP_DOWN_ARROW_OFFSET: number = 7;
