<div class="snack-bar__wrapper">
  {{ data?.message }}

  <a *ngIf="data?.canCall"
     class="btn btn--call"
     href="tel:+{{ phoneNumber }}"
     (click)="close()">
    CALL
  </a>
</div>
